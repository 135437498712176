import React, { Component, useEffect, useState } from "react";
import {
  View,
  FlatList,
  Modal,
  TouchableOpacity,
  Animated,
  Easing,
  ActivityIndicator,
  Image,
} from "react-native";
import Text from "../../Component/Text/MyText";
import { FontSecondaryColor, MainColor } from "../../UI/Color";
import { HeightDimen, fontDimen } from "../../UI/Dimensions";
import { MainFlowHeaderContainerNoFeedback } from "../../Component/Header/MainFlowHeader";
import { OrderHistoryCard } from "../../Component/Card/OrderHistoryCard";
import walletAPICaller from "../../API/WalletAPICaller";
import myData from "../../Data/MyData";
import MyToast from "../../Component/Toast/MyToast";
import { strings } from "../../Language/Language";
import LottieView from "lottie-react-native";
import { TextButton } from "../../Component/Button/TextButton";
import { DateModal } from "../../Component/Modal/DateModal";

const RewardModal = (props) => {
  const { isShow, source, message, onConfirmPress } = props;
  const [progress, setProgress] = useState(new Animated.Value(0));
  useEffect(() => {
    Animated.timing(progress, {
      toValue: 1,
      duration: 4000,
      easing: Easing.linear,
      useNativeDriver: true,
    }).start();
  }, []);
  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={isShow}
      statusBarTranslucent
    >
      <View
        style={{
          flex: 1,
          backgroundColor: "rgba(0,0,0,0.5)",
          justifyContent: "center",
        }}
      >
        <View
          style={{
            flex: 0.4,
            backgroundColor: MainColor,
            padding: HeightDimen(15),
            justifyContent: "center",
            alignItems: "center",
            borderRadius: HeightDimen(15),
            marginHorizontal: HeightDimen(40),
          }}
        >
          <LottieView
            source={source}
            progress={progress}
            style={{ height: HeightDimen(150), alignSelf: "center" }}
          />
          <Text
            style={{
              textAlign: "center",
              fontSize: fontDimen(20),
              color: FontSecondaryColor,
              marginTop: HeightDimen(20),
              fontWeight: "bold",
            }}
          >
            {message}
          </Text>
          <Text
            style={{
              textAlign: "center",
              fontSize: fontDimen(16),
              color: FontSecondaryColor,
            }}
          >
            {"You Have Claimed Your Reward"}
          </Text>
          <View
            style={{
              marginTop: HeightDimen(30),
              justifyContent: "center",
              flexDirection: "row",
            }}
          >
            <TextButton onPress={onConfirmPress} label={"GOT IT"} size={2.5} />
          </View>
        </View>
      </View>
    </Modal>
  );
};

class RewardScreen extends Component {
  state = {
    rewardList: [],
    isShow: false,
    isLoading: false,

    selectedDate: strings.pick_date,
    showDatePicker: false,
  };

  async componentDidMount() {
    await this.set_date();
    await this.getRewardList();
  }
  set_date = async () => {
    let dateToday = new Date();
    let day = dateToday.getDate();
    let month = dateToday.getMonth() + 1;
    let returnDate =
      dateToday.getFullYear() +
      "-" +
      (month < 10 ? "0" : "") +
      month +
      "-" +
      (day < 10 ? "0" : "") +
      day;
    this.setState({ selectedDate: returnDate });
  };
  getRewardList = async () => {
    this.setState({ isLoading: true });

    await walletAPICaller
      .getRewards(myData.authToken, this.state.selectedDate)
      .then((res) => {
        this.setState({ rewardList: res.data.response.list });
      })
      .catch((err) => {
        if (err.response.status == 514) {
          this.props.expiredToken_logout();
        } else {
          MyToast.popToast(err.response.data.msg);
        }
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };
  onRewardPress = async (id) => {
    this.setState({ isLoading: true });
    await walletAPICaller
      .claimReward(myData.authToken, id)
      .then((res) => {
        this.setState({ isShow: true });
      })
      .catch((err) => {
        MyToast.popToast(err.response.data.msg);
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };
  onBackPressed() {
    this.props.navigation.goBack();
  }
  close_date_modal = async (date) => {
    await this.setState({
      selectedDate: date,
    });
    this.onToggle_datePicker();
    await this.getRewardList();
  };

  onToggle_datePicker = () => {
    this.setState({ showDatePicker: !this.state.showDatePicker });
  };
  render() {
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: "white",
        }}
      >
        {this.state.isShow && (
          <RewardModal
            isShow={this.state.isShow}
            source={require("../../Resources/LottieIcons/rewards.json")}
            message={"Congratulation"}
            onConfirmPress={() => {
              this.setState({ isShow: false });
              this.getRewardList();
            }}
          />
        )}
        <DateModal
          isShow={this.state.showDatePicker}
          label={strings.pick_date}
          onClose={this.close_date_modal}
        />
        <MainFlowHeaderContainerNoFeedback
          title={strings.reward}
          onBackPress={() => this.onBackPressed()}
        >
          <View
            style={{
              flex: 1,
              paddingLeft: HeightDimen(20),
              paddingRight: HeightDimen(20),
            }}
          >
            <TouchableOpacity
              disabled={this.state.isLoading}
              onPress={() => this.onToggle_datePicker()}
              style={{
                flexDirection: "row",
                alignItems: "center",
                width: "50%",
                marginBottom: HeightDimen(15),
              }}
            >
              <Text
                style={{
                  fontSize: fontDimen(12),
                }}
              >
                {strings.date + ": "}
              </Text>
              <Text
                style={{
                  fontSize: fontDimen(12),
                }}
              >
                {this.state.selectedDate}
              </Text>
              <Image
                style={{
                  width: "auto",
                  height: HeightDimen(10),
                  aspectRatio: 1 / 1,
                  padding: HeightDimen(3),
                  marginLeft: HeightDimen(5),
                }}
                source={require("../../Resources/Images/icon_down.png")}
              />
            </TouchableOpacity>
            {!this.state.isLoading ? (
              <FlatList
                onRefresh={() => this.getRewardList()}
                refreshing={this.state.isLoading}
                numColumns={1}
                data={this.state.rewardList}
                renderItem={({ item, index }) => (
                  <TouchableOpacity
                    onPress={() => {
                      if (item.status == "done") {
                        MyToast.popToast("Reward was already claimed.");
                      } else {
                        this.onRewardPress(item.id);
                      }
                    }}
                  >
                    <OrderHistoryCard
                      title={item.label}
                      message={item.rewarded_at}
                      price={item.amount}
                      status={
                        item.status == "done" ? "Claimed" : "Ready to claim"
                      }
                      source={require("../../Resources/Images/reward.png")}
                    />
                  </TouchableOpacity>
                )}
                keyExtractor={(item) => String(item.id)}
              />
            ) : (
              <ActivityIndicator
                style={{ marginTop: HeightDimen(20) }}
                size="small"
                color={MainColor}
              />
            )}
          </View>
        </MainFlowHeaderContainerNoFeedback>
      </View>
    );
  }
}

export default RewardScreen;
