import React, { Component } from 'react'
import { View, TouchableOpacity, Image, ScrollView } from 'react-native'
import Text from '../../Component/Text/MyText'
import { FontSecondaryColor } from '../../UI/Color'
import { WidthDimen, HeightDimen, fontDimen } from '../../UI/Dimensions'
import { LoginFlowHeaderContainer } from '../../Component/Header/LoginFlowHeader'
import { NormalTextInput } from '../../Component/Input/NormalTextInput'
import PasswordInput from '../../Component/Input/PasswordInput'
import { TextButton } from '../../Component/Button/TextButton'
import APICaller from '../../API/APICaller'
import Mydata from '../../Data/MyData'
import OTPRouteData from '../../Data/OTPRouteData'
import MyToast from '../../Component/Toast/MyToast'
import { strings } from '../../Language/Language'
import storageData from '../../Data/StorageData'
import { PhoneTextInput } from '../../Component/Input/PhoneTextInput'
import {
  alphaNumericRegex,
  debounce,
  generateRandom,
  phoneRegex,
  resetNavigationStack,
  useDebounce,
} from '../../Utils/Common'

import { CountryCodeModal } from '../../Component/Modal/CountryCodeModal'
import { RecoverTextInput } from '../../Component'
import { Camera } from 'expo-camera'

class SignUpScreen extends Component {
  state = {
    isLoading: false,
    recommendUsername: '',
    fullname: '',
    username: '',
    password: '',
    confirmPassword: '',
    phone: '',
    email: '',
    errorMessage: '',
    code: '+60',
    isSignUpEnable: true,
    isVerifyUserLoading: false,
    isCountryModal: false,
    selectedCountry: '',
    countryList: [
      {
        country_id: '1',
        name: 'Malaysia',
        code: 'MYR',
        icon_url:
          'https://xxyyzz112233.s3.ap-southeast-1.amazonaws.com/country/MYR-flag.png',
      },
      {
        country_id: '2',
        name: 'Thailand',
        code: 'THB',
        icon_url:
          'https://xxyyzz112233.s3.ap-southeast-1.amazonaws.com/country/THB-flag.png',
      },
    ],
    selectedCountry: {},
    isVerified: false,
    stepList: [1, 2, 3, 4, 5],
    isRecommendUsernameVerified: false,
    isRecommendUsernameLoading: false,
    isRecommendedUsernameError: false,
    otp: '',
    otpUniqueKey: '',
    isOtpVerified: false,
    isOtpLoading: false,
    isOtpError: false,
    isPasswordError: false,
    isPasswordVerified: false,
    isConfirmPasswordError: false,
    isConfirmPasswordVerified: false,
    passErrorMsg: '',
    confirmPassErrorMsg: '',
  }

  async componentDidMount() {
    this.setCountryInfo()
    // await this.getCountryList();
  }
  // getCountryList = async () => {
  //   await APICaller.getCountryListNoAuth()
  //     .then((res) => {
  //       this.setState({ countryList: res.response.list });
  //     })
  //     .catch((err) => {
  //       MyToast.popToast(err.response.data.msg);
  //     })
  //     .finally(() => {});
  // };
  setCountryInfo() {
    if (Mydata?.ipLocation?.country_name?.toLowerCase() == 'thailand') {
      this.setState({
        code: '+66',
        selectedCountry: {
          code: 'THB',
          icon_url:
            'https://suncity.s3.ap-southeast-1.amazonaws.com/country/THB-flag.png',
          name: 'Thailand',
          country_id: '2',
        },
      })
    } else {
      this.setState({
        code: '+60',
        selectedCountry: {
          code: 'MYR',
          icon_url:
            'https://suncity.s3.ap-southeast-1.amazonaws.com/country/MYR-flag.png',
          name: 'Malaysia',
          country_id: '1',
        },
      })
    }
  }
  onBackPressed = () => {
    this.props.navigation.goBack()
  }

  onSignUp = async () => {
    if (
      this.state.username != '' &&
      this.state.phone != '' &&
      this.state.confirmPassword != '' &&
      this.state.password != '' &&
      this.state.otpUniqueKey != '' &&
      this.state.selectedCountry?.country_id
    ) {
      if (
        this.state.confirmPassword == this.state.password &&
        this.state.password.length >= 8
      ) {
        this.setState({ isLoading: true })

        await APICaller.signUp(
          this.state.username,
          this.state.password,
          this.state.phone,
          this.state.code,
          Mydata.receommender_name,
          this.state?.selectedCountry?.country_id,
          this.state.fullname,
          this.state.otpUniqueKey,
          this.state.otp
        )
          .then((res) => {
            Mydata.userToken = res.response.userToken
            Mydata.authToken = res.response.authToken
            OTPRouteData.set_flow_signup()
            storageData.set_username_add_password(
              this.state.username,
              this.state.password,
              res.response.authSession
            )
            Mydata.stepList = this.state.stepList
            debugger
            Mydata.setUp(res)
            this.setState({ isLoading: false })
            this.props.isLogin_Ready()
            // if (this.state?.selectedCountry?.code == 'THB') {
            //   this.props.isLogin_Ready_set_pin()
            // } else {
            //   if (this.props.isFromInfo) {
            //     this.props.navigation.navigate('OTPs', {
            //       phoneNumber: this.state.phone,
            //       code: this.state.code,
            //     })
            //   } else
            //     this.props.navigation.navigate('OTP', {
            //       phoneNumber: this.state.phone,
            //       code: this.state.code,
            //     })
            // }
          })
          .catch((err) => {
            // console.log(err.response.data.msg);
            this.setState({ isLoading: false })
            MyToast.popToast(err.response.data.msg)
          })
      } else {
        MyToast.popToast(strings.t('invalid_password'))
      }
    } else {
      MyToast.popToast(strings.t('invalid_data_input'))
    }
  }
  onLogin = () => {
    if (this.props.isFromInfo) this.props.isInfo_Ready()
    else resetNavigationStack(this.props.navigation, 'Login')
  }

  handle_email = (email_txt) => {
    this.setState({ email: email_txt })
  }

  handle_password = (password_txt) => {
    this.setState({
      isPasswordError: false,
      isPasswordVerified: false,
      passErrorMsg: '',
    })
    debugger
    if (password_txt.length < 8) {
      if (/\s/g.test(password_txt)) return
      this.setState({
        isPasswordError: true,
        passErrorMsg: strings.t('signup_password_info'),
        password: password_txt,
      })
      return
    }
    if (!alphaNumericRegex(password_txt)) {
      if (/\s/g.test(password_txt)) return
      this.setState({
        isPasswordError: true,
        passErrorMsg: strings.t('signup_new_password_error_msg'),
        password: password_txt,
      })

      return
    }

    if (this.state.confirmPassword) {
      if (this.state.confirmPassword !== password_txt) {
        this.setState({
          isConfirmPasswordError: true,
          isConfirmPasswordVerified: false,
          confirmPassErrorMsg: strings.t(
            'signup_password_confirm_passwors_error_msg'
          ),
        })
      } else if (this.state.confirmPassword === password_txt) {
        this.setState({
          isConfirmPasswordError: false,
          isConfirmPasswordVerified: true,
          confirmPassErrorMsg: '',
        })
      }
    }
    this.setState({
      password: password_txt,
      passErrorMsg: '',
      isPasswordError: false,
      isPasswordVerified: true,
    })
  }

  handle_confirm_password = (confirm_password_txt) => {
    this.setState({
      isConfirmPasswordError: false,
      isConfirmPasswordVerified: false,
      confirmPassErrorMsg: '',
    })
    if (!alphaNumericRegex(confirm_password_txt)) {
      if (/\s/g.test(confirm_password_txt)) return
      this.setState({
        isConfirmPasswordError: true,
        confirmPassErrorMsg: strings.t('signup_new_password_error_msg'),
        confirmPassword: confirm_password_txt,
      })
      return
    }
    if (confirm_password_txt !== this.state.password) {
      this.setState({
        isConfirmPasswordError: true,
        confirmPassErrorMsg: strings.t(
          'signup_password_confirm_passwors_error_msg'
        ),
        confirmPassword: confirm_password_txt,
      })
      return
    }
    this.setState({
      confirmPassword: confirm_password_txt,
      confirmPassErrorMsg: '',
      isConfirmPasswordError: false,
      isConfirmPasswordVerified: true,
    })
  }
  handle_username = (username_txt) => {
    this.setState({
      username: username_txt,
      isSignUpEnable: true,
      isVerified: false,
      isError: false,
    })
    this.debounceUsername()
  }

  handle_phoneNumber = (phone) => {
    if (phone == '') this.setState({ otpUniqueKey: '' })
    this.setState({ phone: phone })
  }
  onSelectCode(code) {
    this.setState({
      code: code,
    })
  }
  verifyUsername = async () => {
    if (this.state.username) {
      this.setState({ isVerifyUserLoading: true })
      await APICaller.checkUsername(this.state.username?.trim())
        .then((res) => {
          MyToast.popToast(res.msg)
          this.setState({ isSignUpEnable: false, isVerified: true })
        })
        .catch((err) => {
          this.setState({ isError: true })
          MyToast.popToast(err.response.data.msg)
        })
        .finally(() => {
          this.setState({ isVerifyUserLoading: false })
        })
    } else {
      MyToast.popToast(strings.t('please_enter_username'))
    }
  }
  onNextPressed = async () => {
    if (this.state.username != '') {
      await this.onValidateUser(this.state.username)
    } else {
      MyToast.popToast(strings.t('invalid_data_input'))
    }
  }

  onRecommendedUserVerification = async () => {
    debugger
    if (this.state.recommendUsername) {
      this.setState({ isRecommendUsernameLoading: true })
      await APICaller.validateUser(this.state.recommendUsername?.trim())
        .then((res) => {
          Mydata.receommender_name = this.state.recommendUsername?.trim()
          this.setState({ isRecommendUsernameVerified: true })
        })
        .catch((err) => {
          this.setState({ isRecommendedUsernameError: true })
          MyToast.popToast(err.response.data.msg)
        })
        .finally(() => {
          this.setState({ isRecommendUsernameLoading: false })
        })
    } else {
      MyToast.popToast(strings.t('invalid_data_input'))
    }
  }
  onResendOTP = async () => {
    if (this.state.phone) {
      let otpPrefix = generateRandom(4)
      this.setState({ otpPrefix: otpPrefix })
      await APICaller.requestRegisterOtp(
        this.state.phone,
        this.state.code,
        otpPrefix
      )
        .then((res) => {
          this.setState({ otpUniqueKey: res.response.unique_key })
          MyToast.popToast(strings.t('otp_code_resended'))
        })
        .catch((err) => {
          console.log(err)
          MyToast.popToast(err.response.data.msg)
        })
    } else {
      MyToast.popToast(strings.t('please_enter_phone_number'))
    }
  }
  onVerifyPhoneOTP = async () => {
    debugger
    if (!this.state.otpUniqueKey) {
      MyToast.popToast(strings.t('please_enter_phone_number'))
      return
    }
    this.setState({ isOtpLoading: true })
    await APICaller.verifyRegisterOTP(
      this.state.otpUniqueKey,
      this.state.otp,
      this.state.otpPrefix
    )
      .then((res) => {
        this.setState({ isOtpVerified: true })
      })
      .catch((err) => {
        this.setState({ isOtpError: true })
        MyToast.popToast(err?.response?.data?.msg)
        //console.log(err)
      })
      .finally(() => {
        this.setState({ isOtpLoading: false })
      })
  }
  handle_otp = (otp) => {
    const numericText = otp?.replace(/[^0-9]/g, '')
    this.setState({
      otp: numericText,
      isOtpVerified: false,
      isOtpError: false,
    })
  }
  enabledSignupButton = () => {
    if (
      this.state.username != '' &&
      this.state.phone != '' &&
      this.state.otpUniqueKey != '' &&
      this.state.isOtpVerified &&
      this.state.isRecommendUsernameVerified &&
      this.state.isVerified &&
      this.state.isPasswordVerified &&
      this.state.isConfirmPasswordVerified
    )
      return false
    else return true
  }

  debounceRecomendedUsername = debounce(() => {
    this.onRecommendedUserVerification()
  }, 2000)
  debounceUsername = debounce(() => {
    this.verifyUsername()
  }, 2000)
  onSetRecomendedUserName = () => {
    this.setState({ recommendUsername: Mydata.receommender_name })
    this.debounceRecomendedUsername()
  }
  async onPressQRScanner() {
    const status = await Camera.requestCameraPermissionsAsync()

    if (status.status === 'granted') {
      this.props.navigation.navigate('QRCode', {
        onGoBack: (data) => {
          this.onSetRecomendedUserName(data)
        },
      })
    } else {
      MyToast.popToast(strings.t('please_allow_camera_permission'))
    }
    debugger
  }
  render() {
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: 'white',
        }}
      >
        <LoginFlowHeaderContainer
          onBackPress={() => this.onBackPressed()}
          mainText={strings.t('signUp_text')}
          subText={''}
          wantStepper
          stepValue={2}
          stepList={this.state.stepList}
          info
        >
          <CountryCodeModal
            isShow={this.state.isCountryModal}
            list={this.state.countryList}
            onPress={(item) => {
              this.setState({
                selectedCountry: item,
                isCountryModal: false,
                stepList: item?.code == 'THB' ? [1, 2, 3, 4] : [1, 2, 3, 4, 5],
              })
            }}
            onBackPress={() => {
              this.setState({ isCountryModal: false })
            }}
          />
          <ScrollView
            style={{
              flex: 1,
              paddingLeft: HeightDimen(30),
              paddingRight: HeightDimen(30),
              marginBottom: HeightDimen(20),
            }}
            contentContainerStyle={{ flexGrow: 1 }}
          >
            <View
              style={{
                flex: 1,
                marginTop: HeightDimen(25),
              }}
            >
              <NormalTextInput
                placeholder={strings.t('recommended_user')}
                marginBottom={HeightDimen(10)}
                type={'username'}
                onChangeText={(val) => {
                  this.setState({
                    recommendUsername: val,
                    isRecommendUsernameVerified: false,
                    isRecommendedUsernameError: false,
                  })
                  this.debounceRecomendedUsername()
                }}
                btnLabel={
                  !this.state.isRecommendUsernameVerified ? 'Verify' : ''
                }
                btnPress={() => {
                  this.onRecommendedUserVerification()
                }}
                iconPress={() => this.onPressQRScanner()}
                iconSrc={require('../../Resources/Images/Footer/icon_qr.png')}
                isVerified={this.state.isRecommendUsernameVerified}
                isError={this.state.isRecommendedUsernameError}
                isLoading={this.state.isRecommendUsernameLoading}
                value={this.state.recommendUsername}
              />

              <NormalTextInput
                placeholder={strings.t('username_text')}
                marginBottom={HeightDimen(10)}
                type={'username'}
                onChangeText={this.handle_username}
                btnLabel={!this.state.isVerified ? 'Verify' : ''}
                btnPress={() => {
                  this.verifyUsername()
                }}
                isVerified={this.state.isVerified}
                isError={this.state.isError}
                isLoading={this.state.isVerifyUserLoading}
              />
              <Text
                style={{
                  fontSize: fontDimen(10),
                  marginBottom: HeightDimen(10),
                  color: 'red',
                }}
              >
                {strings.t('signup_username_info')}
              </Text>

              <PasswordInput
                placeholder={strings.t('password_text')}
                marginBottom={HeightDimen(10)}
                onChangeText={this.handle_password}
                isVerified={this.state.isPasswordVerified}
                isError={this.state.isPasswordError}
                value={this.state.password}
              />
              {this.state.passErrorMsg && (
                <Text
                  style={{
                    fontSize: fontDimen(10),
                    marginBottom: HeightDimen(10),
                    color: 'red',
                  }}
                >
                  {this.state.passErrorMsg}
                </Text>
              )}

              <PasswordInput
                placeholder={strings.t('confirm_password')}
                marginBottom={HeightDimen(10)}
                onChangeText={this.handle_confirm_password}
                isVerified={this.state.isConfirmPasswordVerified}
                isError={this.state.isConfirmPasswordError}
                value={this.state.confirmPassword}
              />
              {this.state.confirmPassErrorMsg && (
                <Text
                  style={{
                    fontSize: fontDimen(10),
                    marginBottom: HeightDimen(10),
                    color: 'red',
                  }}
                >
                  {this.state.confirmPassErrorMsg}
                </Text>
              )}
              <TouchableOpacity
                onPress={() => {
                  this.setState({ isCountryModal: true })
                }}
                disabled={this.state.countryList.length > 0 ? false : true}
                style={{
                  width: '100%',
                  height: HeightDimen(50),
                  backgroundColor: '#EFF2F4',
                  borderRadius: HeightDimen(10),
                  flexDirection: 'row',
                  alignItems: 'center',
                  padding: HeightDimen(15),
                  marginBottom: HeightDimen(10),
                }}
              >
                <Image
                  source={
                    this.state?.selectedCountry?.icon_url
                      ? { uri: this.state?.selectedCountry?.icon_url }
                      : require('../../Resources/Images/default_country_icon.png')
                  }
                  style={{ height: 15, width: 15 }}
                />
                {this.state?.selectedCountry?.icon_url && (
                  <Text
                    style={{
                      fontSize: fontDimen(15),
                      color: '#C7C7CD',
                      marginLeft: HeightDimen(10),
                    }}
                  >
                    {this.state?.selectedCountry?.code}
                  </Text>
                )}

                <Text
                  style={{
                    fontSize: fontDimen(15),
                    color: '#C7C7CD',
                    marginLeft: HeightDimen(15),
                  }}
                >
                  {this.state?.selectedCountry?.name
                    ? this.state.selectedCountry?.name
                    : 'Select Country'}
                </Text>
              </TouchableOpacity>

              <PhoneTextInput
                placeholder={strings.t('phone_number')}
                bottom={true}
                top={true}
                existingCode={this.state.code}
                value={this.state.phone}
                marginBottom={HeightDimen(10)}
                onOtpPress={() => this.onResendOTP()}
                onSelectCodePress={(val) => this.onSelectCode(val)}
                isNumber={true}
                onChangeText={this.handle_phoneNumber}
                isCounter
                isValidation={this.state.phone ? false : true}
              />
              <NormalTextInput
                title={`${
                  this.state.otpPrefix ? `${this.state.otpPrefix} -` : ''
                }`}
                hideImage
                placeholder={strings.t('otp_text')}
                marginBottom={HeightDimen(10)}
                type={'username'}
                onChangeText={this.handle_otp}
                btnLabel={!this.state.isOtpVerified ? 'Verify' : ''}
                btnPress={() => {
                  this.onVerifyPhoneOTP()
                }}
                isVerified={this.state.isOtpVerified}
                isError={this.state.isOtpError}
                isLoading={this.state.isOtpLoading}
                isNumber
                value={this.state.otp}
              />
              <Text
                style={{
                  fontSize: fontDimen(10),
                  marginBottom: HeightDimen(10),
                  color: 'red',
                }}
              >
                {strings.t('otp_verification_text')}
              </Text>
              <View
                style={{
                  flexDirection: 'row',
                  marginTop: HeightDimen(40),
                  alignItems: 'center',
                }}
              >
                <TextButton
                  label={strings.t('signUp_text')}
                  size={2.5}
                  onPress={() => this.onSignUp()}
                  isLoading={this.state.isLoading}
                  isDisable={this.enabledSignupButton()}
                />

                <TouchableOpacity
                  onPress={() => this.onLogin()}
                  style={{
                    marginLeft: WidthDimen(30),
                  }}
                  disabled={this.state.isLoading}
                >
                  <Text
                    style={{
                      fontSize: fontDimen(15),
                      color: FontSecondaryColor,
                    }}
                  >
                    {strings.t('login_text')}
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          </ScrollView>
        </LoginFlowHeaderContainer>
      </View>
    )
  }
}

export default SignUpScreen
