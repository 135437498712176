import {check, request, PERMISSIONS, RESULTS} from 'react-native-permissions';
import { Platform } from "react-native";
 
export const check_camera_permission = async () => {

    var granted = false;

    await request( Platform.OS === 'ios' ? PERMISSIONS.IOS.CAMERA : PERMISSIONS.ANDROID.CAMERA).then((result) => {
        switch (result) {
            // case RESULTS.UNAVAILABLE:
            //     break;
            // case RESULTS.DENIED:
            //     break;
            case RESULTS.GRANTED:
                granted = true;
                break;
            default:
                break;
            // case RESULTS.BLOCKED:
            //     break;
        }
    }).catch((error) => {
    
    });

    return granted;
    
}



