import React, { Component } from "react";
import { View, TouchableOpacity, Modal, Image, Platform } from "react-native";
import Text from "../Text/MyText";

import {
  MainColor,
  SecondaryColor,
  FontMainColor,
  FontSecondaryColor,
  FontThirdColor,
} from "../../UI/Color";
import {
  WidthDimen,
  HeightDimen,
  fontDimen,
  GetWidth,
  GetHeight,
} from "../../UI/Dimensions";

import { ImageButton } from "../../Component/Button/ImageButton";
import { strings } from "../../Language/Language";
import { MainFlowHeader } from "../Header/MainFlowHeader";
import { NoBackHeader, NoBackHeaderContainer } from "../Header/NoBackHeader";
import MyData from "../../Data/MyData";
import { TextButton } from "../Button/TextButton";

const WithdrawSuccModal = (props) => {
  const { isShow, bankList, amount, onClose } = props;

  //console.log(bankList)
  var bank_name = "";

  for (let i = 0; i < bankList.length; i++) {
    if (bankList[i].bank_id == MyData.bankId) {
      bank_name = bankList[i].bank_name;
      break;
    }
  }
  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={isShow}
      statusBarTranslucent
    >
      <View
        style={{
          flex: 1,
          backgroundColor: "white",
        }}
      >
        <NoBackHeaderContainer title={strings.withdraw}>
          <View
            style={{
              flex: 1,
              paddingLeft: HeightDimen(20),
              paddingRight: HeightDimen(20),
            }}
          >
            <View
              style={{
                height: HeightDimen(50),
                borderBottomWidth: HeightDimen(1),
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <Text
                style={{
                  fontSize: fontDimen(15),
                  color: "black",
                  width: WidthDimen(130),
                }}
              >
                {strings.bank}
              </Text>
              <Text
                style={{
                  fontSize: fontDimen(15),
                  color: "black",
                  flex: 1,
                }}
              >
                {bank_name}
              </Text>
            </View>
            <View
              style={{
                height: HeightDimen(50),
                borderBottomWidth: HeightDimen(1),
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <Text
                style={{
                  fontSize: fontDimen(15),
                  color: "black",
                  width: WidthDimen(130),
                }}
              >
                {strings.account_name}
              </Text>
              <Text
                style={{
                  fontSize: fontDimen(15),
                  color: "black",
                  flex: 1,
                }}
              >
                {MyData.bankName}
              </Text>
            </View>
            <View
              style={{
                height: HeightDimen(50),
                borderBottomWidth: HeightDimen(1),
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <Text
                style={{
                  fontSize: fontDimen(15),
                  color: "black",
                  width: WidthDimen(130),
                }}
              >
                {strings.account_no}
              </Text>
              <Text
                style={{
                  fontSize: fontDimen(15),
                  color: "black",
                  flex: 1,
                }}
              >
                {MyData.bankAccountNo}
              </Text>
            </View>

            <View
              style={{
                height: HeightDimen(50),
                borderBottomWidth: HeightDimen(1),
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <Text
                style={{
                  fontSize: fontDimen(15),
                  color: "black",
                  width: WidthDimen(130),
                }}
              >
                {strings.amount_RM} ({MyData.country_code})
              </Text>
              <Text
                style={{
                  fontSize: fontDimen(15),
                  color: "black",
                  flex: 1,
                }}
              >
                {amount}
              </Text>
            </View>

            <View
              style={{
                height: HeightDimen(50),
                borderBottomWidth: HeightDimen(1),
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <Text
                style={{
                  fontSize: fontDimen(15),
                  color: "black",
                  width: WidthDimen(130),
                }}
              >
                {strings.status}
              </Text>
              <Text
                style={{
                  fontSize: fontDimen(15),
                  color: "black",
                  flex: 1,
                }}
              >
                {strings.succ_submission_pending_review}
              </Text>
            </View>
            <View
              style={{
                marginTop: HeightDimen(40),
              }}
            >
              <TextButton size={5} label={strings.back} onPress={onClose} />
            </View>
          </View>
        </NoBackHeaderContainer>
      </View>
    </Modal>
  );
};

export { WithdrawSuccModal };
