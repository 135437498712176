import React, { Component } from "react";
import { View, TouchableOpacity, ActivityIndicator } from "react-native";
import Text from "../Text/MyText";

import {
  MainColor,
  SecondaryColor,
  FontMainColor,
  FontSecondaryColor,
  FontThirdColor,
} from "../../UI/Color";
import {
  WidthDimen,
  HeightDimen,
  fontDimen,
  GetWidth,
  GetHeight,
} from "../../UI/Dimensions";

const TextButton = (props) => {
  const {
    onPress,
    label,
    size,
    whiteBtn,
    isLoading,
    blackBtn,
    marginLeft,
    isDisable,
  } = props;

  return (
    <TouchableOpacity
      onPress={onPress}
      style={{
        width: size == 5 ? "100%" : "auto",
        height: HeightDimen(50),
        aspectRatio: size == 5 ? null : size / 1,
        justifyContent: "center",
        alignItems: "center",
        marginLeft: marginLeft || 0,
        backgroundColor: isDisable
          ? "#EFF2F4"
          : whiteBtn
          ? "white"
          : blackBtn
          ? MainColor
          : SecondaryColor,
        borderRadius: HeightDimen(10),
      }}
      disabled={isLoading || isDisable}
    >
      {isLoading ? (
        <ActivityIndicator
          size="small"
          color={whiteBtn ? SecondaryColor : FontMainColor}
        />
      ) : (
        <Text
          style={{
            color: whiteBtn || blackBtn ? SecondaryColor : FontMainColor,
            fontSize: fontDimen(15),
          }}>
          {label}
        </Text>
      )}
    </TouchableOpacity>
  );
};

export { TextButton };
