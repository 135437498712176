import React, { Component } from 'react'
import {
  View,
  TouchableOpacity,
  Image,
  ActivityIndicator,
  Text,
  ScrollView,
} from 'react-native'

import { HeightDimen, fontDimen } from '../../UI/Dimensions'
import { strings } from '../../Language/Language'
import { MainFlowHeaderContainer } from '../../Component/Header/MainFlowHeader'

import {
  FontMainColor,
  FontSecondaryColor,
  MainColor,
  SecondaryColor,
} from '../../UI/Color'
import walletAPICaller from '../../API/WalletAPICaller'
import MyData from '../../Data/MyData'
import { numberWithCommas } from '../../Utils/Common'
import MyToast from '../../Component/Toast/MyToast'

import { SuccessModal } from '../../Component/Modal/SuccessModal'

class WithdrawUSDTReceiptScreen extends Component {
  state = {
    isLoading: false,
    withdrawUsdtObj: this.props?.route?.params?.data,
    isSuccessModal: false,
  }

  constructor(props) {
    super(props)
  }

  handleBackButtonClick() {
    this.onBackPressed()
    return true
  }

  onBackPressed = () => {
    this.props.route.params.onGoBack()
    this.props.navigation.goBack()
  }

  renderStatusIcon() {
    return (
      <View
        style={{
          ...styles.statusIconInnerContainer,
          backgroundColor: 'green',
        }}
      >
        <Image
          source={require('../../Resources/Images/icon_tick.png')}
          style={{
            height: HeightDimen(40),
            aspectRatio: 1 / 1,
            resizeMode: 'contain',
            tintColor: 'white',
          }}
        />
      </View>
    )
  }
  renderTitleDescription(title, desc, isBackground) {
    return (
      <View
        style={{
          justifyContent: 'space-between',
          flexDirection: 'row',
          marginBottom: HeightDimen(10),
          flex: 1,
        }}
      >
        <Text
          style={{
            fontSize: fontDimen(15),
            color: FontSecondaryColor,
            flex: 0.7,
          }}
        >
          {title}
        </Text>
        <Text
          style={{
            fontSize: fontDimen(15),
            color: 'black',
            textAlign: 'right',
            flex: 1,
          }}
        >
          {desc ? desc : 'N/A'}
        </Text>
      </View>
    )
  }
  onConfirm = async () => {
    this.setState({ isLoading: true })
    await walletAPICaller
      .onRequestWithdrawUsdt(
        MyData.authToken,
        this.state.withdrawUsdtObj?.withdraw_network,
        this.state.withdrawUsdtObj?.withdraw_address,
        this.state.withdrawUsdtObj?.withdraw_amount
      )
      .then((res) => {
        this.setState({
          isLoading: false,
          isSuccessModal: true,
        })
      })
      .catch((err) => {
        this.setState({ isLoading: false })
        if (err.response.status == 514) {
          this.props.expiredToken_logout()
        } else {
          MyToast.popToast(err.response.data.msg)
        }
      })
  }

  render() {
    return (
      <View style={styles.container}>
        {this.state.isSuccessModal && (
          <SuccessModal
            isShow={this.state.isSuccessModal}
            isSuccess={true}
            message={strings.t('withdraw_usdt_success')}
            onConfirmPress={() => {
              this.setState({ isSuccessModal: false })
              this.onBackPressed()
            }}
          />
        )}
        <MainFlowHeaderContainer
          title={strings.t('withdraw_usdt_Receipt')}
          onBackPress={() => this.onBackPressed()}
          bgImage={require('../../Resources/Images/reciept_background.png')}
        >
          <ScrollView style={{ flex: 1 }} bounces={false}>
            <View style={styles.innerContainer}>
              <View style={styles.recieptContainer}>
                {/* <View style={styles.statusIconContainer}>
                  {this.renderStatusIcon()}
                </View> */}
                <View
                  style={{
                    alignItems: 'center',
                    marginBottom: HeightDimen(30),
                    marginTop: HeightDimen(20),
                  }}
                >
                  <Text
                    style={{
                      fontWeight: 'bold',
                      color: MainColor,
                      fontSize: fontDimen(22),
                    }}
                  >
                    {strings.t('withdraw')} USDT
                  </Text>
                  <Text
                    style={{
                      color: FontSecondaryColor,
                      fontSize: fontDimen(16),
                      marginTop: HeightDimen(10),
                    }}
                  >
                    {strings.t('total_amount')}
                  </Text>
                  <View
                    style={{
                      flexDirection: 'row',
                      alignItems: 'flex-end',
                    }}
                  >
                    <Text
                      style={{
                        fontSize: fontDimen(13),
                        color: 'black',
                        marginBottom: HeightDimen(5),
                      }}
                    >
                      {this.state?.withdrawUsdtObj?.withdraw_currency}
                    </Text>
                    <Text
                      style={{
                        fontSize: fontDimen(30),
                        color: 'black',
                        marginLeft: HeightDimen(8),
                        fontWeight: 'bold',
                      }}
                    >
                      {numberWithCommas(
                        Number(
                          this.state?.withdrawUsdtObj?.withdraw_amount
                        ).toFixed(2)
                      )}
                    </Text>
                  </View>
                </View>
                {this.renderTitleDescription(
                  strings.t('withdraw_currency'),
                  `${this.state.withdrawUsdtObj?.withdraw_currency}`
                )}
                {this.renderTitleDescription(
                  strings.t('withdraw_network'),
                  this.state.withdrawUsdtObj?.withdraw_network
                )}
                {this.renderTitleDescription(
                  strings.t('address'),
                  this.state.withdrawUsdtObj?.withdraw_address
                )}
                {this.renderTitleDescription(
                  strings.t('origin_amount'),
                  `${this.state.withdrawUsdtObj?.converted_amount} ${this.state.withdrawUsdtObj?.converted_amount_currency}`
                )}
                {this.renderTitleDescription(
                  strings.t('conversion_rate'),
                  this.state.withdrawUsdtObj?.usdt_conversion_rate
                )}

                {this.renderTitleDescription(
                  strings.t('amount_RM'),
                  `${this.state?.withdrawUsdtObj?.withdraw_amount} ${this.state?.withdrawUsdtObj?.withdraw_currency}`
                )}
                {this.renderTitleDescription(
                  strings.t('fees'),

                  `${this.state.withdrawUsdtObj?.fees} ${this.state.withdrawUsdtObj?.withdraw_currency}`
                )}

                {this.renderTitleDescription(
                  strings.t('amount_received'),
                  `${this.state.withdrawUsdtObj?.amount_received} ${this.state.withdrawUsdtObj?.withdraw_currency}`
                )}
              </View>
              <TouchableOpacity
                onPress={() => this.onConfirm()}
                style={styles.button}
              >
                {!this.state.isLoading ? (
                  <Text
                    style={{ color: FontMainColor, fontSize: fontDimen(15) }}
                  >
                    {strings.t('confirm')}
                  </Text>
                ) : (
                  <ActivityIndicator size="small" color={FontMainColor} />
                )}
              </TouchableOpacity>
            </View>
          </ScrollView>
        </MainFlowHeaderContainer>
      </View>
    )
  }
}
const styles = {
  container: {
    flex: 1,
    backgroundColor: 'white',
  },
  innerContainer: {
    flex: 1,
    paddingHorizontal: HeightDimen(5),
    justifyContent: 'center',
    marginTop: HeightDimen(90),
  },
  statusIconContainer: {
    position: 'absolute',
    alignItems: 'center',
    alignSelf: 'center',
    top: -30,
  },
  statusIconInnerContainer: {
    height: 80,
    width: 80,
    borderRadius: 40,
    backgroundColor: SecondaryColor,
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 5,
    borderColor: 'white',
  },
  button: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 7,
    marginTop: HeightDimen(10),
    marginHorizontal: HeightDimen(10),
    backgroundColor: 'white',
    height: HeightDimen(50),
  },
  recieptContainer: {
    padding: HeightDimen(10),
    backgroundColor: 'white',
    marginHorizontal: HeightDimen(10),
    borderRadius: HeightDimen(10),
    marginBottom: HeightDimen(10),
  },
}
export default WithdrawUSDTReceiptScreen
