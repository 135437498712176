import React, { Component } from "react";
import { View, TouchableOpacity, FlatList } from "react-native";
import Text from "../Text/MyText";
import { FontMainColor } from "../../UI/Color";
import { HeightDimen, fontDimen } from "../../UI/Dimensions";

class NumberPad extends Component {
  state = {
    keypad: [
      { id: 1, val: "1" },
      { id: 2, val: "2" },
      { id: 3, val: "3" },
      { id: 4, val: "4" },
      { id: 5, val: "5" },
      { id: 6, val: "6" },
      { id: 7, val: "7" },
      { id: 8, val: "8" },
      { id: 9, val: "9" },
      { id: 10, val: "" },
      { id: 11, val: "0" },
      { id: 12, val: "X" },
    ],
  };

  shouldComponentUpdate(prevState) {
    return false;
  }
  onPress_number = num => {
    if (num !== "") this.props.onPressSelect(num);
  };

  render() {
    console.log("number pad");
    return (
      <View
        style={{
          alignItems: "center",
          backgroundColor: "#F2F3F7",
          padding: HeightDimen(25),
        }}>
        <FlatList
          style={{ flexGrow: 0 }}
          numColumns={3}
          data={this.state.keypad}
          scrollEnabled={false}
          renderItem={({ item, index }) => (
            <TouchableOpacity
              disabled={this.props.isLoading}
              onPress={() => this.onPress_number(item.val)}
              style={{
                width: "auto",
                height: HeightDimen(50),
                aspectRatio: 2 / 1.1,
                backgroundColor: "white",
                justifyContent: "center",
                alignItems: "center",
                margin: HeightDimen(3),
                borderRadius: HeightDimen(7),
                shadowColor: "#000",
                shadowOffset: {
                  width: 0,
                  height: 2,
                },
                shadowOpacity: 0.25,
                shadowRadius: 3.84,

                elevation: 5,
              }}>
              <Text
                style={{
                  color: FontMainColor,
                  fontSize: fontDimen(20),
                }}>
                {item.val}
              </Text>
            </TouchableOpacity>
          )}
          keyExtractor={item => String(item.id)}
        />
      </View>
    );
  }
}

export default NumberPad;
