import React, { Component } from 'react'
import {
  View,
  Text,
  ActivityIndicator,
  TouchableOpacity,
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  Image,
} from 'react-native'
import { fontDimen, HeightDimen } from '../../UI/Dimensions'
import { strings } from '../../Language/Language'
import aPICaller from '../../API/APICaller'
import MyData from '../../Data/MyData'

import MyToast from '../../Component/Toast/MyToast'
import { MainColor, SecondaryColor } from '../../UI/Color'

import {
  LoginFlowHeaderContainer,
  NormalTextInput,
  TextButton,
} from '../../Component'

import storageData from '../../Data/StorageData'
import { ConfirmCancelModal } from '../../Component/Modal/ConfirmCancelModal'
import { DisplayInput } from '../../Component/Input/DisplayInput'
class GoogleAuthenticateScreen extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      isAuthActivate: MyData.temp_json_object?.response?.user
        ?.google_auth_secret
        ? true
        : false,
      showAuthForm: false,
      googleAuthQRCodeUrl: '',
      googleAuthSecret: '',
      isConfirmationModal: false,
      code: '',
    }
  }

  onBackPressed = () => {
    this.props.navigation.goBack()
  }
  async onGnerateGoogleAuthQrcode() {
    this.setState({ isLoading: true })
    await aPICaller
      .onGenerateGoogleAuthQrcode(MyData.authToken)
      .then((res) => {
        const baseModel = res?.data?.response
        this.setState({
          googleAuthQRCodeUrl: baseModel?.googleAuthQRCodeUrl,
          googleAuthSecret: baseModel?.googleAuthSecret,
          showAuthForm: true,
        })
      })
      .catch((err) => {
        if (err.response.status == 514) {
          this.props.expiredToken_logout()
        } else {
          MyToast.popToast(err.response.data.msg)
        }
      })
      .finally(() => {
        this.setState({ isLoading: false })
      })
  }
  onSaveGooogleAuthSecret = async () => {
    this.setState({ isLoading: true })
    await aPICaller
      .onSaveGoogleAuthSecret(
        MyData.authToken,
        this.state.googleAuthSecret,
        this.state.code
      )
      .then((res) => {
        this.onPress_logout()
      })
      .catch((err) => {
        if (err.response.status == 514) {
          this.props.expiredToken_logout()
        } else {
          MyToast.popToast(err.response.data.msg)
        }
      })
      .finally(() => {
        this.setState({ isLoading: false })
      })
  }
  onRemoveGooogleAuthSecret = async () => {
    this.setState({ isLoading: true })
    await aPICaller
      .onRemoveGoogleAuthSecret(MyData.authToken, this.state.code)
      .then((res) => {
        this.onPress_logout()
      })
      .catch((err) => {
        if (err.response.status == 514) {
          this.props.expiredToken_logout()
        } else {
          MyToast.popToast(err.response.data.msg)
        }
      })
      .finally(() => {
        this.setState({ isLoading: false })
      })
  }
  // shareQRCode = async () => {
  //   logMessageAnalytics(AnalyticEvents.QRShareClick);
  //   if (Platform.OS === "android" && !(await this.hasAndroidPermission())) {
  //     return;
  //   }
  //   this.viewShot.capture().then((uri) => {
  //     const options = {
  //       message: decodeURIComponent(MyData.refLink),
  //       url: uri,
  //       type: "image/png",
  //     };
  //     onShare(options);
  //   });
  // };

  renderActivateGoogleAuth() {
    return (
      <View style={{ alignItems: 'center' }}>
        {!this.state.isAuthActivate && (
          <>
            <Text
              style={{
                fontSize: fontDimen(15),
                textAlign: 'center',
                marginBottom: HeightDimen(15),
              }}
            >
              {strings.t('scan_google_auth_msg')}
            </Text>
            <View style={styles.qrContainer}>
              <Image
                style={styles.qrImage}
                source={{
                  uri: this.state.googleAuthQRCodeUrl.replace(
                    'https://',
                    'http://'
                  ),
                }}
              />
            </View>
            <DisplayInput
              title={strings.t('secret_key')}
              borderRadius={HeightDimen(10)}
              value={this.state.googleAuthSecret}
            />
          </>
        )}
        <NormalTextInput
          placeholder={strings.t('enter_verification_code')}
          bottom={true}
          hideImage
          isNumber
          onChangeText={(val) => this.setState({ code: val })}
        />

        <View style={styles.buttonContainer}>
          <TextButton
            label={
              this.state.isAuthActivate
                ? strings.t('in_activate')
                : strings.t('activate')
            }
            size={5}
            onPress={() => {
              this.state.isAuthActivate
                ? this.onRemoveGooogleAuthSecret()
                : this.onSaveGooogleAuthSecret()
            }}
            isLoading={this.state.isLoading}
          />
        </View>
      </View>
    )
  }
  onPress_logout = () => {
    MyData.clear_all_data()
    storageData.clear_storage()
    this.props.isLogout_Ready()
  }
  render() {
    return (
      <View style={styles.container}>
        <ConfirmCancelModal
          isShow={this.state.isConfirmationModal}
          textResource={
            this.state.isAuthActivate
              ? strings.t('reset_google_auth_msg')
              : strings.t('activate_google_auth_msg')
          }
          onConfirmPress={() => {
            this.setState({ isConfirmationModal: false })
            this.onGnerateGoogleAuthQrcode()
          }}
          onBackPress={() => {
            this.setState({ isConfirmationModal: false })
          }}
        />

        <LoginFlowHeaderContainer
          onBackPress={() => this.onBackPressed()}
          mainText={strings.t('google_auth')}
          subText={strings.t('register_google_auth_verification_msg')}
        >
          <ScrollView
            style={{ flex: 1 }}
            contentContainerStyle={{
              flexGrow: 1,
            }}
          >
            <View style={styles.innerContainer}>
              {this.state.showAuthForm ? (
                this.renderActivateGoogleAuth()
              ) : (
                <TouchableOpacity
                  onPress={() => this.setState({ isConfirmationModal: true })}
                  style={{
                    ...styles.activateButton,
                    backgroundColor: this.state.isAuthActivate
                      ? 'green'
                      : SecondaryColor,
                  }}
                >
                  <Text
                    style={{
                      fontSize: fontDimen(16),
                      textAlign: 'center',
                      color: 'white',
                      fontWeight: 'bold',
                    }}
                  >
                    {this.state.isAuthActivate
                      ? strings.t('inactivate_google_auth')
                      : strings.t('activate_google_auth')}
                  </Text>
                  {this.state.isLoading && (
                    <ActivityIndicator
                      size="small"
                      color={MainColor}
                      style={{ marginTop: HeightDimen(20) }}
                    />
                  )}
                </TouchableOpacity>
              )}
            </View>
          </ScrollView>
        </LoginFlowHeaderContainer>
      </View>
    )
  }
}
const styles = {
  container: {
    flex: 1,
    backgroundColor: 'white',
  },
  innerContainer: {
    flex: 1,
    paddingHorizontal: HeightDimen(10),
    paddingTop: HeightDimen(10),
    alignItems: 'center',
    justifyContent: 'center',
  },
  qrContainer: {
    borderRadius: HeightDimen(20),
    overflow: 'hidden',
    borderWidth: HeightDimen(4),
    borderColor: SecondaryColor,
    marginBottom: HeightDimen(10),
    padding: HeightDimen(5),
  },
  qrImage: {
    width: 'auto',
    height: HeightDimen(230),
    aspectRatio: 1 / 1,
    resizeMode: 'contain',
  },
  buttonContainer: {
    marginTop: HeightDimen(30),
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
  },
  activateButton: {
    height: 180,
    width: 180,
    borderRadius: 90,
    backgroundColor: SecondaryColor,
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
  },
}
export default GoogleAuthenticateScreen
