import React, { Component } from 'react'
import { View, Text, Image, StyleSheet } from 'react-native'

import walletAPICaller from '../../API/WalletAPICaller'
import { TextButton } from '../../Component/Button/TextButton'
import { MainFlowHeaderContainerNoFeedback } from '../../Component/Header/MainFlowHeader'
import { TopUpModal } from '../../Component/Modal/TopUpModal'
import MyToast from '../../Component/Toast/MyToast'
import MyData from '../../Data/MyData'
import { strings } from '../../Language/Language'
import { FontMainColor, SecondaryColor } from '../../UI/Color'
import { fontDimen, HeightDimen } from '../../UI/Dimensions'

class PromotionDetailScreen extends Component {
  constructor(props) {
    super(props)
    this.state = {
      promoDetail: props.route.params.promoDetail,
      topupList: [],
      isLoading: false,
      show_top_up_modal: false,
    }
  }

  onBackPressed = () => {
    this.props.route.params.onGoBack()
    this.props.navigation.goBack()
  }

  onPress_InstantTopUp = () => {
    this.setState({
      show_top_up_modal: false,
    })
    this.props.navigation.navigate('InstantTopUp', {
      promoCode: this.state.promoDetail.promo_code,
      onGoBack: (data) => {},
    })
  }
  onPress_UsdtTopUp = () => {
    this.setState({
      show_top_up_modal: false,
    })
    this.props.navigation.navigate('UsdtTopUp', {
      promoCode: this.state.promoDetail.promo_code,
      onGoBack: (data) => {},
    })
  }

  onPress_BankTopUp = () => {
    this.setState({
      show_top_up_modal: false,
    })
    this.props.navigation.navigate('TopUp', {
      promoCode: this.state.promoDetail.promo_code,
      onGoBack: (data) => {},
    })
  }
  getTopupMethod = async () => {
    this.setState({ isLoading: true })
    await walletAPICaller
      .getTopupMethods(MyData.authToken)
      .then((res) => {
        this.setState({
          topupList: res.response.list,
          show_top_up_modal: true,
        })
      })
      .catch((err) => {
        if (err.response.status == 514) {
          this.props.expiredToken_logout()
        } else {
          MyToast.popToast(err.response.data.msg)
        }
      })
      .finally(() => {
        this.setState({ isLoading: false })
      })
  }

  renderTitle = (title, description) => {
    return (
      <View
        style={{
          marginTop: HeightDimen(10),
        }}
      >
        <View style={styles.titleContainer}>
          <Text style={styles.title}>{title}</Text>
          <Text style={{ ...styles.title, fontWeight: 'normal' }}>
            {description}
          </Text>
        </View>
        <View style={styles.line} />
      </View>
    )
  }
  onCancel_modal = () => {
    this.setState({
      show_top_up_modal: false,
    })
  }
  render() {
    const {
      promo_name,
      promo_code,
      promo_balance,
      promo_type,
      start_datetime,
      promo_title_en,
      promo_terms_en,
    } = this.state.promoDetail
    return (
      <View style={styles.container}>
        {this.state.topupList.length > 0 && (
          <TopUpModal
            isShow={this.state.show_top_up_modal}
            topupList={this.state.topupList}
            onPressInstantTopUp={() => this.onPress_InstantTopUp()}
            onPressBankTopUp={() => this.onPress_BankTopUp()}
            onPressUsdtTopUp={() => this.onPress_UsdtTopUp()}
            onBackPress={() => this.onCancel_modal()}
          />
        )}
        <MainFlowHeaderContainerNoFeedback
          title={strings.t('promotion')}
          onBackPress={() => this.onBackPressed()}
        >
          <View style={styles.innercontainer}>
            <View style={styles.imageContainer}>
              <Image
                source={{ uri: this.state.promoDetail.promo_image_url }}
                style={styles.banner}
              />
            </View>

            <View style={styles.detailContainer}>
              <Text style={styles.promo}>{promo_code}</Text>
              <Text
                style={[
                  styles.promo,
                  {
                    fontSize: fontDimen(20),
                    marginBottom: HeightDimen(5),
                  },
                ]}
              >
                {promo_title_en}
              </Text>
              <Text style={styles.term}>{promo_terms_en}</Text>
              {this.renderTitle('Promo Name', promo_name)}
              {this.renderTitle('Promo Balance', promo_balance)}
              {this.renderTitle('Promo Type', promo_type)}
              {this.renderTitle('Date Time', start_datetime)}
              <View style={styles.button}>
                <TextButton
                  label={strings.t('use_now')}
                  size={5}
                  onPress={() => {
                    this.getTopupMethod()
                  }}
                  isLoading={this.state.isLoading}
                />
              </View>
            </View>
          </View>
        </MainFlowHeaderContainerNoFeedback>
      </View>
    )
  }
}

export default PromotionDetailScreen
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
  },
  innercontainer: {
    flex: 1,
    marginTop: HeightDimen(-30),
    borderRadius: HeightDimen(20),
    overflow: 'hidden',
  },
  promo: {
    fontSize: fontDimen(30),
    textAlign: 'center',
    color: SecondaryColor,
    marginTop: HeightDimen(10),
    fontWeight: 'bold',
  },
  button: {
    flex: 1,
    justifyContent: 'flex-end',
  },
  banner: {
    width: '100%',
    height: HeightDimen(180),

    resizeMode: 'stretch',
  },
  imageContainer: {
    backgroundColor: SecondaryColor,
    width: '100%',
    height: HeightDimen(180),
  },
  term: {
    fontSize: fontDimen(16),
    textAlign: 'center',
    color: FontMainColor,
    marginBottom: HeightDimen(30),
  },
  detailContainer: {
    flex: 1,
    paddingBottom: HeightDimen(40),
    paddingHorizontal: HeightDimen(50),
  },
  line: {
    borderWidth: HeightDimen(1),
    borderColor: '#EFF2F4',
    marginTop: HeightDimen(10),
  },
  title: {
    fontSize: fontDimen(14),
    fontWeight: 'bold',
    color: FontMainColor,
  },
  titleContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
})
