import { handleNewDesignVersionCheck } from '../Utils/Common'

class MyData {
  authToken = ''
  userToken = ''
  temp_json_object = ''
  bankName = ''
  bankAccountNo = ''
  bankId = ''
  member_username = ''
  memner_nickname = ''
  avatar = ' '
  qrCode = ''
  refLink = ''
  userPin = ''
  maintainance_json = ''
  api_url = ''
  receommender_name = ''
  directLine = ''
  country_name = ''
  country_url = ''
  country_list = []
  member_level = ''
  last_login_ip = ''
  want_amplitude_event = false
  s3_url = ''
  notifCount = 0
  want_amplitude_event = false
  s3_url = ''
  notifCount = 0
  coupon_code = null
  member_vip_level = ''
  wallet_country = ''
  vip_max_withdraw_amount = ''
  vip_max_withdraw_count = ''
  country_id = ''
  ipLocation = {}
  s3Info = {}
  stepList = []
  user_api_url = ''
  isfirst_time_login = ''
  publicIp = ''
  popupImage = []
  country_code = 'MYR'
  // only for testing
  expired_token =
    'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2MDc2NzEyMzIsImlzcyI6IiIsImV4cCI6MTYwNzc1NzYzMiwidXNlclRva2VuIjoiMGVsWk10STRUZmJjeHFibFhUUnNPQ3NRTHBMZU1ISEllYVVqbW1kRVdhS29GUWdPd1UxT2REQ2lHRXRYSWtGOUFqMW5EUzdHdlJ1U3FNbmZyU0lyT094T3YxalU1cWlPN2V2emJEVnJFZXpQMzlyeHV0QWdRZ1g3TDRoR2t5aUY5dkUzcnptMU9TaVFHRGNwSUlNOW1BZklpZFRuNnNKZjVJRm9DcG8zdGtoRnI2QzBYUnhJTzR2cTZDb1d0NEpUYVl3MkFiUlQifQ.a0Bx3dPiVexWNMPsJOM3NLaEfzBZHQYJFmpM47HVu_w'
  isMobile = false
  setUp = async (jsonFile) => {
    //console.log(jsonFile)

    this.member_username = jsonFile?.response?.user?.member_username
    this.temp_json_object = jsonFile
    this.authToken = jsonFile?.response?.authToken
    this.userToken = jsonFile?.response?.user?.member_unique_key
    this.bankName = jsonFile?.response?.user?.bank_account_name
    this.bankAccountNo = jsonFile?.response?.user?.bank_account_number
    this.bankId = jsonFile?.response?.user.bank_id
    this.memner_nickname = jsonFile?.response?.user?.member_nickname
    this.avatar = jsonFile?.response?.user?.member_avatar_url
    this.qrCode = jsonFile?.response?.user?.member_qrcode_url
    this.refLink = jsonFile?.response?.refLink
    this.directLine = jsonFile?.response?.user?.member_directline
    this.member_level = jsonFile?.response?.user?.member_level
    this.country_name = jsonFile?.response?.user?.name
    this.country_url = jsonFile?.response?.user?.country_url
    this.country_code = jsonFile?.response?.user?.country_code
    this.wallet_country = jsonFile?.response?.user?.country_code
    this.country_id = jsonFile?.response?.user?.country_id
    this.member_vip_level = jsonFile?.response?.user?.vip_level_id
    this.vip_max_withdraw_amount =
      jsonFile?.response?.user?.vip_max_withdraw_amount
    this.vip_max_withdraw_count = jsonFile.response.user?.vip_max_withdraw_count
    this.isfirst_time_login = jsonFile?.response?.user?.first_time_login
    this.coupon_code = ''
    this.games_list = []
  }

  setUserPin = async (pin) => {
    //console.log(pin)
    this.userPin = pin
  }

  setApiAndS3Url = async (apiUrl, s3Url) => {
    //console.log(pin)
    //  this.s3Info = info;

    this.api_url = apiUrl
    this.s3_url = s3Url
    //  this.user_api_url = userApiUrl;
  }
  setIsAmplitudeEvents = async () => {
    //console.log(pin)
    this.want_amplitude_event = await handleNewDesignVersionCheck()
  }
  setNotifCount = (count) => {
    this.notifCount = count
  }
  setIsAmplitudeEvents = async () => {
    //console.log(pin)
    this.want_amplitude_event = await handleNewDesignVersionCheck()
  }
  setIpLocation = (country) => {
    this.ipLocation = country
  }
  clear_all_data = () => {
    this.authToken = ''
    this.userToken = ''
    this.temp_json_object = ''
    this.receommender_name = ''
    this.bankName = ''
    this.bankAccountNo = ''
    this.bankId = ''
    this.member_username = ''
    this.userPin = ''
    this.memner_nickname = ''
    this.avatar = ''
    this.qrCode = ''
  }
  clear_coupon_data = () => {
    this.coupon_code = null
  }
  setS3CountryURL = () => {
    if (this.country_code.toLowerCase() == 'thb')
      this.api_url = this.s3Info?.production_url
    else this.api_url = this.s3Info?.production_url
  }
}

export default new MyData()
