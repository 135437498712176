import React, { Component } from 'react'
import { View } from 'react-native'
import { HeightDimen } from '../../UI/Dimensions'
import { LoginFlowHeaderContainer } from '../../Component/Header/LoginFlowHeader'
import { TextButton } from '../../Component/Button/TextButton'
import APICaller from '../../API/APICaller'
import MyToast from '../../Component/Toast/MyToast'
import { strings } from '../../Language/Language'
import { PhoneTextInput } from '../../Component/Input/PhoneTextInput'
import { generateRandom, phoneRegex } from '../../Utils/Common'
import { RecoverTextInput } from '../../Component/Input/RecoverTextInput'

class RecoverUsernameScreen extends Component {
  state = {
    isLoading: false,
    phone: '',
    otp: '',
    code: '+60',
    otpPrefix: '',
  }

  handle_phone = (phone) => {
    this.setState({ phone: phone })
  }
  handle_otp = (otp) => {
    this.setState({ otp: otp })
  }
  onBackPressed = () => {
    this.props.navigation.goBack()
  }
  onGetForgotPasswordOTP = async () => {
    const { phone, code } = this.state
    if (phone != '') {
      let otpPrefix = generateRandom(4)
      this.setState({ otpPrefix: otpPrefix })
      await APICaller.GetForgotPasswordOTP(phone, code, otpPrefix)
        .then((res) => {
          MyToast.popToast(strings.t('otp_code_resended'))
        })
        .catch((err) => {
          MyToast.popToast(err.response.data.msg)
        })
    } else {
      MyToast.popToast(strings.t('please_enter_phone_number'))
    }
  }
  onNextPressed = async () => {
    const { phone, code, otp } = this.state
    if (phone != '' && otp != '') {
      this.setState({ isLoading: true })
      await APICaller.recoverUsername(phone, code, otp)
        .then((res) => {
          this.props.navigation.navigate('RecoverUsers', {
            userList: res.response.list,
          })
        })
        .catch((err) => {
          MyToast.popToast(err.response.data.msg)
        })
        .finally(() => {
          this.setState({ isLoading: false })
        })
    } else {
      MyToast.popToast(strings.t('please_enter_phone_number'))
    }
  }
  onSelectCode(code) {
    this.setState({
      code: code,
    })
  }

  render() {
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: 'white',
        }}
      >
        <LoginFlowHeaderContainer
          onBackPress={() => this.onBackPressed()}
          mainText={strings.t('recover_username')}
          subText={strings.t('please_enter_phone_number_to_recover_username')}
        >
          <View
            style={{
              flex: 1,
              paddingLeft: HeightDimen(30),
              paddingRight: HeightDimen(30),
              paddingBottom: HeightDimen(30),
            }}
          >
            <View
              style={{
                marginTop: HeightDimen(40),
              }}
            >
              <PhoneTextInput
                placeholder={strings.t('phone_number')}
                top={true}
                value={this.state.phone}
                onChangeText={this.handle_phone}
                onOtpPress={() => this.onGetForgotPasswordOTP()}
                onSelectCodePress={(val) => this.onSelectCode(val)}
                isNumber={true}
                isCounter
                isValidation={this.state.phone ? false : true}
              />
              <View style={{ height: HeightDimen(3) }}></View>
              <RecoverTextInput
                title={`${strings.t('otp_text')} ${
                  this.state.otpPrefix ? `  ${this.state.otpPrefix} -` : ''
                }`}
                placeholder={strings.t('otp_text')}
                bottom={true}
                isNumber={true}
                onChangeText={this.handle_otp}
              />
            </View>

            <View
              style={{
                width: '100%',
                alignItems: 'center',
                marginTop: HeightDimen(20),
              }}
            >
              <TextButton
                label={strings.t('next')}
                size={5}
                onPress={() => this.onNextPressed()}
                isLoading={this.state.isLoading}
              />
            </View>
          </View>
        </LoginFlowHeaderContainer>
      </View>
    )
  }
}

export default RecoverUsernameScreen
