import React, { Component } from 'react'
import { View, Text, Animated, Easing } from 'react-native'

import { NoBackHeaderContainerNoFeedback } from '../../Component/Header/NoBackHeader'
import { strings } from '../../Language/Language'
import LottieView from 'lottie-react-native'
import { fontDimen, HeightDimen } from '../../UI/Dimensions'
import { TextButton } from '../../Component/Button/TextButton'
import { MainColor } from '../../UI/Color'
import { onShare, takeScreenShot } from '../../Utils/Common'
import ViewShot, { captureRef } from 'react-native-view-shot'
import MyData from '../../Data/MyData'

class PaymentSuccessScreen extends Component {
  state = {
    progress: new Animated.Value(0),
  }
  componentDidMount() {
    Animated.timing(this.state.progress, {
      toValue: 1,
      duration: 2500,
      easing: Easing.linear,
      useNativeDriver: true,
    }).start(() => {
      this.animation.pause()
    })
  }
  onShareScreenShot = async () => {
    this.refs.viewShot.capture().then((uri) => {
      const options = {
        message: decodeURIComponent(MyData.refLink),
        url: uri,
        type: 'image/png',
      }
      debugger
      onShare(uri, options)
    })
  }
  render() {
    const { transferAmount, transferMessage, isSuccess, onGoBack } =
      this.props.route.params
    return (
      <ViewShot ref="viewShot" style={{ flex: 1, backgroundColor: 'white' }}>
        <NoBackHeaderContainerNoFeedback
          title={strings.t('wallet')}
          withIcon
          iconSRC={require('../../Resources/Images/share.png')}
          iconOnPressed={() => this.onShareScreenShot()}
        >
          <View
            style={{
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center',
              paddingLeft: HeightDimen(30),
              paddingRight: HeightDimen(30),
            }}
          >
            <LottieView
              ref={(ref) => (this.animation = ref)}
              source={
                isSuccess
                  ? require('../../Resources/LottieIcons/success.json')
                  : require('../../Resources/LottieIcons/error.json')
              }
              autoPlay
              loop
              progress={this.state.progress}
              style={{ height: HeightDimen(150), alignSelf: 'center' }}
            />
            <Text
              style={{
                textAlign: 'center',
                fontSize: fontDimen(16),
                color: MainColor,
                marginTop: HeightDimen(20),
              }}
            >
              {transferMessage}
            </Text>
            {transferAmount && (
              <Text
                style={{
                  textAlign: 'center',
                  fontSize: fontDimen(20),
                  color: MainColor,
                  marginTop: HeightDimen(20),
                  fontWeight: 'bold',
                }}
              >
                {`${transferAmount} ${MyData.country_code}`}
              </Text>
            )}
            <View
              style={{
                marginTop: HeightDimen(30),
                justifyContent: 'center',
                flexDirection: 'row',
                width: '100%',
              }}
            >
              <TextButton
                onPress={() => {
                  if (isSuccess) {
                    onGoBack && onGoBack()
                    onGoBack && this.props?.navigation?.goBack()
                  }
                  this.props?.navigation?.goBack()
                }}
                label={strings.t('confirm')}
                size={5}
                blackBtn
              />
            </View>
          </View>
        </NoBackHeaderContainerNoFeedback>
      </ViewShot>
    )
  }
}

export default PaymentSuccessScreen
