import React, { Component } from 'react'
import { View, TouchableOpacity, Image } from 'react-native'
import Text from '../../Text/MyText'

import {
  MainColor,
  SecondaryColor,
  FontMainColor,
  FontSecondaryColor,
} from '../../../UI/Color'
import { HeightDimen, fontDimen } from '../../../UI/Dimensions'
import { strings } from '../../../Language/Language'
import MyData from '../../../Data/MyData'
import { numberWithCommas } from '../../../Utils/Common'

const AllWalletHistoryCard = (props) => {
  const { item, onPress } = props
  const colorPicker = () => {
    let color = MainColor
    if (item.transaction_type === 'commission') {
      color = SecondaryColor
    } else if (
      (item.transaction_type === 'gameOut' && item.transaction_amount < 0) ||
      (item.transaction_type === 'gameIn' && item.transaction_amount < 0) ||
      item.transaction_type === 'withdraw' ||
      item.transaction_flow_type === 'out'
    ) {
      color = 'red'
    } else if (item.product_name === 'Interest') {
      color = 'green'
    }
    return color
  }

  const getFromCurrencyFlag = (from) => {
    const arrUrl = MyData.country_list.filter((i) => i.code == from)
    if (arrUrl?.length > 0) {
      return arrUrl[0]?.icon_url
    }
  }

  return (
    <TouchableOpacity
      onPress={onPress}
      style={{
        width: '100%',
        height: HeightDimen(60),
        backgroundColor: 'rgba(237, 181, 61, 0.1)',
        borderRadius: HeightDimen(10),
        marginBottom: HeightDimen(10),
        flexDirection: 'row',
        padding: HeightDimen(10),
      }}
    >
      {item?.transaction_type == 'conversion' ? (
        <Image
          source={{ uri: getFromCurrencyFlag(item?.transaction_exchange_from) }}
          style={{
            width: 'auto',
            height: 'auto',
            aspectRatio: 1 / 1,
            margin: -HeightDimen(1),
            borderRadius: HeightDimen(2),
          }}
        />
      ) : (
        <Image
          source={
            (item.transaction_type === 'transfer' &&
              item.transaction_money_type.toLowerCase() === 'interest') ||
            (item.transaction_type === 'mutual' &&
              item.transaction_money_type.toLowerCase() === 'interest')
              ? require('../../../Resources/Images/Wallet/icon_interest.png')
              : {
                  uri:
                    item.transaction_type === 'transfer' &&
                    item.transaction_flow_type === 'out'
                      ? item.transfer_fromto_member_avatar_url
                      : item.transaction_type === 'transfer' &&
                        item.transaction_flow_type === 'in'
                      ? item.transfer_fromto_member_avatar_url
                      : item.transaction_type === 'transfer' &&
                        item.transaction_money_type === 'interest'
                      ? require('../../../Resources/Images/Wallet/icon_interest.png')
                      : item.product_image_url,
                }
          }
          style={{
            width: 'auto',
            height: 'auto',
            resizeMode: 'cover',
            aspectRatio: 1 / 1,
            margin: -HeightDimen(1),
            borderRadius: HeightDimen(2),
          }}
        />
      )}
      <View
        style={{
          flex: 1,
          justifyContent: 'center',
          marginRight: HeightDimen(10),
          marginLeft: HeightDimen(10),
        }}
      >
        <Text
          numberOfLines={1}
          style={{
            fontSize: fontDimen(14),
            color: FontMainColor,
            marginBottom: HeightDimen(5),
          }}
        >
          {item.transaction_type === 'gameIn'
            ? strings.t('enter_game')
            : item.transaction_type === 'gameOut'
            ? strings.t('exit_game')
            : item.transaction_type.toLowerCase() === 'transfer' &&
              item.transaction_money_type.toLowerCase() === 'money'
            ? item.transfer_fromto_member_name
            : item.transaction_type.toLowerCase() === 'interest' &&
              (item.transaction_money_type.toLowerCase() === 'interest' ||
                item.transaction_money_type.toLowerCase() === 'money')
            ? strings.t('interest')
            : item.product_name}
        </Text>
        <Text
          style={{
            fontSize: fontDimen(10),
            color: FontSecondaryColor,
          }}
        >
          {item.transaction_created_datetime}
        </Text>
      </View>
      <View
        style={{
          justifyContent: 'center',
          alignItems: 'flex-end',
        }}
      >
        <Text
          style={{
            fontSize: fontDimen(15),
            color: colorPicker(),

            marginBottom: HeightDimen(5),
          }}
        >
          {numberWithCommas(Number(item.transaction_amount).toFixed(2))}
        </Text>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Text
            style={{
              fontSize: fontDimen(13),

              color: colorPicker(),
              marginRight: HeightDimen(5),
            }}
          >
            {item.transaction_money_type == 'money'
              ? MyData.country_code
              : item.transaction_money_type == 'coin'
              ? strings.t('coin')
              : ''}
          </Text>
          {/* {item?.transaction_type == 'conversion' &&
            <Image
              style={{
                height: HeightDimen(17),
                width: "auto",
                aspectRatio: 1 / 1,
                resizeMode: "contain",
                tintColor: colorPicker(),
              }}
              source={
                getFromCurrencyFlag(item?.transaction_exchange_from)
              }
            />} */}

          <Image
            style={{
              height: HeightDimen(17),
              width: 'auto',
              aspectRatio: 1 / 1,
              resizeMode: 'contain',
              tintColor: colorPicker(),
            }}
            source={
              item.transaction_money_type == 'money'
                ? require('../../../Resources/Images/History/icon_money.png')
                : item.transaction_money_type == 'coin'
                ? require('../../../Resources/Images/History/icon_coin.png')
                : require('../../../Resources/Images/History/icon_interest.png')
            }
          />
        </View>
      </View>
    </TouchableOpacity>
  )
}

export { AllWalletHistoryCard }
