import axios from "axios";
import { apiUrls } from "./APIurl";

import MyData from "../Data/MyData";

class ChatCaller {
  getMessageList = async (authToken, key) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.getChatMessageList}`,
          {
            data: {
              memberUniqueKey: key,
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  sendChatMessage = async (authToken, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.sendChatMessage}`,
          {
            data: payload,
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  chatList = async (authToken, key, message) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.getChatList}`,
          {
            data: {},
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
}

const chatCaller = new ChatCaller();
export default chatCaller;
