import React, { Component } from "react";
import { View, FlatList } from "react-native";
import { HeightDimen } from "../../UI/Dimensions";
import { MainFlowHeaderContainerNoFeedback } from "../../Component/Header/MainFlowHeader";
import { OrderHistoryCard } from "../../Component/Card/OrderHistoryCard";

class OrderHistoryScreen extends Component {
  state = {
    chat_arr: [
      {
        id: 1,
        title: "Instant Top Up",
        message: "Some Important Message",
        price: "20.00",
        status: "Failed",
      },
      {
        id: 2,
        title: "Instant Top Up",
        message: "Some Important Message",
        price: "20.00",
        status: "Failed",
      },
    ],
  };

  onBackPressed = () => {
    this.props.navigation.goBack();
  };

  render() {
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: "white",
        }}
      >
        <MainFlowHeaderContainerNoFeedback
          title={"Order History"}
          onBackPress={() => this.onBackPressed()}
        >
          <View
            style={{
              flex: 1,
              paddingLeft: HeightDimen(30),
              paddingRight: HeightDimen(30),
            }}
          >
            <FlatList
              numColumns={1}
              data={this.state.chat_arr}
              renderItem={({ item, index }) => (
                <OrderHistoryCard
                  title={item.title}
                  message={item.message}
                  price={item.price}
                  status={item.status}
                />
              )}
              keyExtractor={(item) => String(item.id)}
            />
          </View>
        </MainFlowHeaderContainerNoFeedback>
      </View>
    );
  }
}

export default OrderHistoryScreen;
