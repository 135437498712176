import { createElement, useState } from 'react'
import { SecondaryColor } from '../../UI/Color'
import { HeightDimen } from '../../UI/Dimensions'

const WebDatePicker = (props) => {
  const getDate = () => {
    var date = new Date().getDate()
    var month = new Date().getMonth() + 1
    var year = new Date().getFullYear()
    var final_date =
      year +
      '-' +
      (month < 10 ? '0' : '') +
      month +
      '-' +
      (date < 10 ? '0' : '') +
      date
    return final_date
  }

  const [date, setDate] = useState(getDate())
  const set_date = (val) => {
    props.setDate(val)
  }

  return createElement('input', {
    type: 'date',
    value: date,

    onChange: (event) => {
      setDate(event.target.value)
      set_date(event.target.value)
    },
    style: {
      width: '100%',
      height: HeightDimen(50),
      borderWidth: HeightDimen(2),
      borderColor: SecondaryColor,
      borderRadius: HeightDimen(10),
      justifyContent: 'center',
      alignItems: 'center',
    },
  })
}
export { WebDatePicker }
