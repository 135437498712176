import axios from "axios";
import MyData from "../Data/MyData";
import { apiUrls } from "./APIurl";

class ContactCaller {
  getFriendList = async (authToken) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${MyData.api_url}${apiUrls.friends}`, {
          headers: {
            Authorization: authToken,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  addFriend = async (authToken, key) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.addFriend}`,
          {
            data: {
              friendKey: key,
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  deleteFriend = async (authToken, key) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.deleteFriend}`,
          {
            data: {
              friendKey: key,
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  editFriend = async (authToken, name, key) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.editFriend}`,
          {
            data: {
              friendKey: key,
              name: name,
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  searchFriend = async (authToken, name) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.searchFriend}`,
          {
            data: {
              search: name,
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
}
const contactCaller = new ContactCaller();
export default contactCaller;
