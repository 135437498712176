import React, { Component } from 'react'
import {
  View,
  ActivityIndicator,
  TouchableOpacity,
  Modal,
  FlatList,
  Text,
  StyleSheet,
} from 'react-native'
import { MainColor, FontMainColor, SecondaryColor } from '../../UI/Color'
import { HeightDimen, fontDimen } from '../../UI/Dimensions'
import { MainFlowHeaderContainer } from '../../Component/Header/MainFlowHeader'
import { TextButton } from '../../Component/Button/TextButton'
import MyToast from '../../Component/Toast/MyToast'
import MyData from '../../Data/MyData'
import { strings } from '../../Language/Language'
import BankAPICaller from '../../API/BankAPICaller'
import { BankCard } from '../../Component/Card/BankCard'
import { NormalTextInput } from '../../Component/Input/NormalTextInput'

import aPICaller from '../../API/APICaller'
import { Image } from 'react-native'

class RealnamVerificationScreen extends Component {
  state = {
    verificationStatus: false,
    isLoading: false,
    selectedAccount: strings.account_name,
    withdrawBankModal: false,
    realName: '',
    isVerifyAccountLoading: false,
    selectedBankAccountId: '',
    bankName: '',
  }

  async componentDidMount() {
    await this.onGetVerificationStatus()
  }

  onGetVerificationStatus = async () => {
    this.setState({ isLoading: true })

    await aPICaller
      .onGetRealnameVerficationStatus()
      .then(async (res) => {
        if (res?.response?.status == 'false') {
          await this.getBankAccounts()
        }
        this.setState({ verificationStatus: res?.response?.status })
      })
      .catch((err) => {
        if (err.response.status == '514') {
          this.props.expiredToken_logout()
        } else {
          MyToast.popToast(err.response.data.msg)
        }
      })
      .finally(() => {
        this.setState({ isLoading: false })
      })
  }

  getBankAccounts = async () => {
    await BankAPICaller.getBankAccounts(MyData.authToken)
      .then((res) => {
        this.setState({
          bankAccountList: res.response.accounts,
        })
      })
      .catch((err) => {
        if (err.response.status == '514') {
          this.props.expiredToken_logout()
        } else {
          MyToast.popToast(err.response.data.msg)
        }
      })
  }
  withdrawBankAccountModal() {
    return (
      <Modal
        animationType="slide"
        transparent={true}
        visible={this.state.withdrawBankModal}
        statusBarTranslucent
        onRequestClose={() => this.setState({ withdrawBankModal: false })}
      >
        <View
          style={{
            flex: 1,
            backgroundColor: 'rgba(0,0,0,0.5)',
            justifyContent: 'flex-end',
          }}
        >
          <View
            style={{
              backgroundColor: 'white',
              flex: 0.5,
              paddingHorizontal: HeightDimen(10),
              borderTopLeftRadius: HeightDimen(10),
              borderTopRightRadius: HeightDimen(10),
              borderWidth: 3,
              borderColor: SecondaryColor,
            }}
          >
            <View
              style={{
                height: HeightDimen(50),
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Text
                style={{
                  fontSize: fontDimen(15),
                  fontWeight: 'bold',
                }}
              >
                {'Bank Accounts'}
              </Text>
            </View>
            <FlatList
              numColumns={1}
              data={this.state.bankAccountList}
              renderItem={({ item, index }) => (
                <BankCard
                  title={item.account_name}
                  description={item.bank_name}
                  accountNo={'*' + item.account_number.substr(-4, 4)}
                  bankLogo={item.bank_icon_url}
                  onPress={() => this.onSelectAccount(item)}
                />
              )}
              keyExtractor={(item) => String(item.id)}
            />
          </View>
        </View>
      </Modal>
    )
  }
  onSelectAccount(account) {
    this.setState({
      selectedAccount: account.account_name,
      selectedBankAccountId: account.id,
      bankName: account.bank_name,
      withdrawBankModal: false,
    })
  }

  onBackPressed = () => {
    this.props.navigation.goBack()
  }

  onRequestRealNameCertification = async () => {
    if (this.state.selectedBankAccountId && this.state.realName) {
      this.setState({ isVerifyAccountLoading: true })
      await aPICaller
        .onRequestRealNameCertification(
          this.state.selectedBankAccountId,
          this.state.realName
        )
        .then(async (res) => {
          MyToast.popToast(res.data.msg)
          await this.onGetVerificationStatus()
        })
        .catch((err) => {
          if (err.response.status == '514') {
            this.props.expiredToken_logout()
          } else {
            MyToast.popToast(err.response.data.msg)
          }
        })
        .finally(() => {
          this.setState({ isVerifyAccountLoading: false })
        })
    } else {
      MyToast.popToast(strings.invalid_data_input)
    }
  }

  render() {
    return (
      <View style={styles.container}>
        <MainFlowHeaderContainer
          title={strings.real_name_verification}
          onBackPress={() => this.onBackPressed()}
        >
          <View style={styles.innerContainer}>
            {!this.state.isLoading ? (
              <>
                {this.state.verificationStatus == 'true' ||
                this.state.verificationStatus == 'pending' ? (
                  <View
                    style={{
                      flex: 1,
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Image
                      source={
                        this.state.verificationStatus == 'pending'
                          ? require('../../Resources/Images/pending.png')
                          : require('../../Resources/Images/succ_tick.png')
                      }
                      style={{
                        ...styles.image,
                        tintColor:
                          this.state.verificationStatus == 'pending'
                            ? SecondaryColor
                            : '',
                      }}
                    />
                    <Text style={styles.label}>Verification Status</Text>
                    <Text
                      style={{
                        fontSize: fontDimen(25),
                        color: MainColor,
                        letterSpacing: 2,
                      }}
                    >
                      {this.state.verificationStatus == 'pending'
                        ? 'Pending'
                        : 'Verified'}
                    </Text>
                  </View>
                ) : (
                  <View>
                    <TouchableOpacity
                      onPress={() => this.setState({ withdrawBankModal: true })}
                      style={styles.selectInput}
                    >
                      <Text
                        numberOfLines={1}
                        style={{
                          flex: 1,
                          fontSize: fontDimen(15),
                          color: this.state.selectedBankAccountId
                            ? FontMainColor
                            : '#B8BABD',
                        }}
                      >
                        {this.state.selectedAccount}
                        {this.state.bankName
                          ? `   (${this.state.bankName})`
                          : ''}
                      </Text>
                    </TouchableOpacity>
                    <NormalTextInput
                      placeholder={strings.enter_real_name}
                      marginBottom={HeightDimen(20)}
                      hideImage={true}
                      onChangeText={(val) => this.setState({ realName: val })}
                    />

                    <Text style={styles.noteLabel}>
                      {strings.real_name_notes}
                    </Text>
                    <View
                      style={{
                        marginTop: HeightDimen(30),
                      }}
                    >
                      <TextButton
                        label={strings.confirm}
                        size={5}
                        onPress={() => this.onRequestRealNameCertification()}
                        isLoading={this.state.isVerifyAccountLoading}
                      />
                    </View>
                  </View>
                )}
              </>
            ) : (
              <ActivityIndicator
                size="small"
                color={SecondaryColor}
                style={{ marginTop: HeightDimen(20) }}
              />
            )}
          </View>
          {this.withdrawBankAccountModal()}
        </MainFlowHeaderContainer>
      </View>
    )
  }
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
  },
  innerContainer: {
    flex: 1,
    paddingHorizontal: HeightDimen(30),
    paddingTop: HeightDimen(20),
  },
  image: {
    width: 'auto',
    height: HeightDimen(180),
    aspectRatio: 496 / 425,
    resizeMode: 'contain',
  },
  selectInput: {
    width: '100%',
    height: HeightDimen(50),
    backgroundColor: '#EFF2F4',
    borderRadius: HeightDimen(10),
    flexDirection: 'row',
    alignItems: 'center',
    padding: HeightDimen(15),
    marginBottom: HeightDimen(10),
  },
  noteLabel: {
    fontSize: fontDimen(16),
    fontWeight: 'bold',
    color: FontMainColor,
  },
  label: {
    marginTop: HeightDimen(10),
    fontSize: fontDimen(26),
    color: SecondaryColor,
    fontWeight: 'bold',
    letterSpacing: 2,
  },
})
export default RealnamVerificationScreen
