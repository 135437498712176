import React, { Component } from "react";
import { View, Image, TextInput, Platform } from "react-native";
import Text from "../Text/MyText";

import {
  MainColor,
  SecondaryColor,
  FontMainColor,
  FontSecondaryColor,
  FontThirdColor,
} from "../../UI/Color";
import {
  WidthDimen,
  HeightDimen,
  fontDimen,
  GetWidth,
  GetHeight,
} from "../../UI/Dimensions";

const SearchBar = (props) => {
  const { onChangeText, placeholder, marginBottom } = props;

  return (
    <View
      style={{
        width: "100%",
        height: HeightDimen(50),
        backgroundColor: "#EFF2F4",
        borderRadius: HeightDimen(10),
        flexDirection: "row",
        alignItems: "center",
        padding: HeightDimen(15),
        marginBottom: marginBottom || 0,
      }}
    >
      <Image
        source={require("../../Resources/Images/icon_search.png")}
        style={{
          width: "auto",
          height: HeightDimen(15),
          aspectRatio: 1 / 1,
          resizeMode: "contain",
          marginRight: WidthDimen(10),
        }}
      />

      <TextInput
        placeholder={placeholder}
        onChangeText={onChangeText}
        placeholderTextColor={"#C7C7CD"}
        style={{
          flex: 1,
          fontSize: fontDimen(15),

          height: HeightDimen(50),
        }}
      />
    </View>
  );
};

export { SearchBar };
