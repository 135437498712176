import React, { Component } from 'react'
import {
  View,
  FlatList,
  ActivityIndicator,
  Animated,
  Image,
} from 'react-native'
import leaderBoardCaller from '../../API/LeaderBoardCaller'
import { GameCard } from '../../Component/Card/GameCard'
import { GameBackgroundHeaderContainer } from '../../Component/Header/BackgroundGameHeader'
import { GameSearchBar } from '../../Component/SearchBar/GameSearchBar'
import MyToast from '../../Component/Toast/MyToast'
import MyData from '../../Data/MyData'
import { MainColor } from '../../UI/Color'
import { HeightDimen } from '../../UI/Dimensions'

import { languageSelectedName } from '../../Utils/Common'

class FunHouseGameScreen extends Component {
  state = {
    gameList: [],
    tempGameList: [],
    isLoading: true,
    scaleValue: new Animated.Value(0),
    title: this.props.route.params.title,
    logo: this.props.route.params.logo,
    productId: this.props.route.params.productId,
  }

  async componentDidMount() {
    this.get_Game()
  }
  onBackPressed = () => {
    this.props.route.params.onGoBack()
    this.props.navigation.goBack()
  }
  animationImage = () => {
    Animated.timing(this.state.scaleValue, {
      duration: 500,
      toValue: 1,
      useNativeDriver: false,
    }).start()
  }

  get_Game = async () => {
    this.setState({ isLoading: true })
    await leaderBoardCaller
      .getFunHouseGameList(MyData.authToken, this.state.productId)
      .then((res) => {
        this.setState({
          gameList: res.response.gameList,
          tempGameList: res.response.gameList,
          isLoading: false,
        })
        this.animationImage()
      })
      .catch((err) => {
        if (err.response.status == 514) {
          this.props.expiredToken_logout()
        } else {
          MyToast.popToast(err.response.data.msg)
        }
      })
      .finally(() => {
        this.setState({
          isLoading: false,
        })
      })
  }

  on_Refresh = async () => {
    this.setState({ isLoading: true })
    await this.close_game()
    await this.get_Game()
  }

  close_game = async () => {
    await leaderBoardCaller
      .closeGame(MyData.authToken)
      .then((res) => {
        MyToast.popToast('Funhouse Close Game')
      })
      .catch((err) => {
        MyToast.popToast(err?.response?.data?.msg)
      })
      .finally(() => {})
  }

  onSearch = (search) => {
    const { gameList } = this.state
    let filterList = gameList.filter((o) => {
      return (
        o.game_name &&
        o.game_name
          .toUpperCase()
          .replace(/ /g, '')
          .includes(search.toUpperCase().replace(/ /g, ''))
      )
    })
    this.setState({
      tempGameList: filterList,
    })
    if (!search) this.searchTextAnimation()
  }

  searchTextAnimation() {
    Animated.timing(this.state.scaleValue, {
      duration: 100,
      toValue: 0.6,
      useNativeDriver: false,
    }).start(() => {
      Animated.timing(this.state.scaleValue, {
        duration: 50,
        toValue: 1,
        useNativeDriver: false,
      }).start()
    })
  }

  start_Game = async (id, gameName) => {
    this.setState({ isLoading: true })
    await leaderBoardCaller
      .launchFunHouseGame(MyData.authToken, id, this.state.productId)
      .then((res) => {
        this.props.navigation.navigate('LaunchGame', {
          url: res.response.game.url,
          title: gameName,
          onGoBack: (data) => {
            this.on_Refresh(data)
          },
        })
      })
      .catch((err) => {
        if (err.response.status == 514) {
          this.props.expiredToken_logout()
        } else {
          MyToast.popToast(err.response.data.msg)
        }
      })
      .finally(() => {
        this.setState({ isLoading: false })
      })
  }

  renderItemCard = ({ item }) => {
    return (
      <GameCard
        image={`https://assets.funhouse888.com/gameassets/${item.id}/rec_325.png`}
        name={item.game_name}
        transform={{ transform: [{ scale: this.state.scaleValue }] }}
        onPress={() => this.start_Game(item.id, item.game_name)}
      />
    )
  }
  render() {
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: MainColor,
        }}
      >
        <GameBackgroundHeaderContainer
          source={require('../../Resources/Images/background.jpg')}
          title={''}
          hideBackButton={this.state.isLoading}
          onBackPress={() => this.onBackPressed()}
        >
          {this.state.isLoading ? (
            <View>
              <ActivityIndicator size="small" color={'white'} />
            </View>
          ) : (
            <View
              style={{
                flex: 1,
              }}
            >
              <Image
                style={{
                  width: HeightDimen(120),
                  height: HeightDimen(120),
                  resizeMode: 'contain',
                  borderRadius: HeightDimen(10),
                  alignSelf: 'center',
                  marginBottom: HeightDimen(10),
                }}
                source={{ uri: this.state.logo }}
              />
              <View style={{ marginHorizontal: HeightDimen(15) }}>
                <GameSearchBar
                  placeholder={'Search'}
                  onChangeText={(val) => this.onSearch(val)}
                />
              </View>
              <FlatList
                numColumns={3}
                style={{
                  marginTop: HeightDimen(10),
                  paddingHorizontal: HeightDimen(15),
                }}
                contentContainerStyle={{
                  width: '100%',
                }}
                data={this.state.tempGameList}
                onRefresh={() => this.get_Game()}
                refreshing={this.state.isLoading}
                renderItem={this.renderItemCard}
                keyExtractor={(item) => String(item.id)}
              />
            </View>
          )}
        </GameBackgroundHeaderContainer>
      </View>
    )
  }
}

export default FunHouseGameScreen
