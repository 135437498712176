import React, { useState, Component } from 'react'
import {
  View,
  Image,
  TouchableOpacity,
  TextInput,
  Platform,
  Keyboard,
  TouchableWithoutFeedback,
  ActivityIndicator,
} from 'react-native'
import Text from '../Text/MyText'

import {
  MainColor,
  SecondaryColor,
  FontMainColor,
  FontSecondaryColor,
  FontThirdColor,
} from '../../UI/Color'
import {
  WidthDimen,
  HeightDimen,
  fontDimen,
  GetWidth,
  GetHeight,
} from '../../UI/Dimensions'
import { CounterButton } from '../Button/CounterButton'
import { strings } from '../../Language/Language'

const RecoverTextInput = React.forwardRef((props, forwardedRef) => {
  const [ishide, setHide] = useState(true)

  const onPress_show_hide_password = () => {
    if (ishide) {
      setHide(false)
    } else {
      setHide(true)
    }
  }
  const {
    placeholder,
    onChangeText,
    title,
    top,
    bottom,
    isSecure,
    isNumber,
    value,
    isEdit,
    isMax,
    onMaxPress,
    isCounter,
    onOtpPress,
    marginBottom,
    iconSrc,
    iconPress,
    onBlur,
    selection,
    textAlign,
    onFocus,
  } = props

  return (
    <View
      style={{
        width: '100%',
        height: HeightDimen(50),
        backgroundColor: '#EFF2F4',
        borderTopRightRadius: top ? HeightDimen(10) : 0,
        borderTopLeftRadius: top ? HeightDimen(10) : 0,
        borderBottomRightRadius: bottom ? HeightDimen(10) : 0,
        borderBottomLeftRadius: bottom ? HeightDimen(10) : 0,
        flexDirection: 'row',
        alignItems: 'center',
        padding: HeightDimen(15),
        marginBottom: marginBottom ? HeightDimen(5) : 0,
      }}
    >
      <Text
        style={{
          fontSize: fontDimen(15),
          color: MainColor,
          marginRight: HeightDimen(10),
        }}
      >
        {title}
      </Text>

      <View
        style={{
          flex: 1,
        }}
      >
        <TextInput
          ref={forwardedRef}
          editable={isEdit}
          placeholderTextColor={'#C7C7CD'}
          placeholder={placeholder}
          onChangeText={onChangeText}
          secureTextEntry={isSecure ? ishide : false}
          textContentType={'oneTimeCode'}
          keyboardType={isNumber ? 'decimal-pad' : 'default'}
          style={{
            fontSize: fontDimen(15),
            // height: Platform.OS == 'android' ? HeightDimen(50) : HeightDimen(30)
            color: FontMainColor,
            height: HeightDimen(50),
            outlineStyle: 'none',
          }}
          onBlur={onBlur}
          value={value}
          selection={selection}
          textAlign={textAlign}
          onFocus={onFocus}
        />
      </View>
      {isMax && (
        <TouchableOpacity onPress={onMaxPress}>
          <Text>{strings.t('max')}</Text>
        </TouchableOpacity>
      )}
      {iconSrc && (
        <TouchableOpacity onPress={iconPress}>
          <Image
            source={iconSrc}
            style={{
              width: 'auto',
              height: HeightDimen(25),
              aspectRatio: 1 / 1,
              resizeMode: 'contain',

              tintColor: SecondaryColor,
            }}
          />
        </TouchableOpacity>
      )}
      {isCounter && <CounterButton onPress={onOtpPress} />}
      {isSecure && (
        <TouchableOpacity onPress={() => onPress_show_hide_password()}>
          <Image
            source={
              ishide
                ? require('../../Resources/Images/hide-eye.png')
                : require('../../Resources/Images/show-eye.png')
            }
            style={{
              width: 'auto',
              height: HeightDimen(22),
              aspectRatio: 1 / 1,
              resizeMode: 'contain',
              marginLeft: WidthDimen(10),
              tintColor: FontSecondaryColor,
            }}
          />
        </TouchableOpacity>
      )}
    </View>
  )
})

export { RecoverTextInput }
