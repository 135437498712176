import React, { Component } from "react";
import { View, TouchableOpacity, ActivityIndicator, Image } from "react-native";
import Text from "../../Component/Text/MyText";
import { MainColor, SecondaryColor } from "../../UI/Color";
import { HeightDimen, fontDimen } from "../../UI/Dimensions";
import { MainFlowHeaderContainer } from "../../Component/Header/NewDesign/MainFlowHeader";
import { ProfileCard } from "../../Component/Card/ProfileCard";
import interestCaller from "../../API/InterestCaller";
import MyToast from "../../Component/Toast/MyToast";
import { ConfirmCancelModal } from "../../Component/Modal/ConfirmCancelModal";
import MyData from "../../Data/MyData";
import { strings } from "../../Language/Language";
import { EnterTransactionPinModal } from "../../Component/Modal/EnterTransactionPinModal";

import { numberWithCommas } from "../../Utils/Common";

class InterestDetailsScreen extends Component {
  state = {
    amount: "",
    balance: "",
    createdDate: "",
    key: "",
    name: "",
    toShowData: "",

    duration: "",

    selection: 1,
    isCancelLoading: false,
    showModal: false,

    refreshPrevious: false,
    showPinModal: false,
    monthNames: [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ],
  };

  componentDidMount() {
    let temp = this.props.route.params.value;
    // console.log(temp?.interest_account_created_datetime)

    var dateArray = temp.interest_account_created_datetime.split(" ");
    var year = dateArray[0].split("-");
    var time = dateArray[1].split(":");
    var createDate = new Date(
      year[0],
      year[1] - 1,
      year[2],
      time[0],
      time[1],
      time[2]
    );
    // createDate.setHours(0,0,0,0)
    var currentData = new Date();

    var seconds = Math.floor((currentData - createDate) / 1000);
    var minutes = Math.floor(seconds / 60);
    var hours = Math.floor(minutes / 60);
    var days = Math.floor(hours / 24);

    var temp_date =
      createDate.getDate() +
      "-" +
      this.state.monthNames[createDate.getMonth()] +
      "-" +
      createDate.getFullYear() +
      ", ";
    if (createDate.getHours() < 12)
      temp_date =
        temp_date +
        createDate.getHours() +
        ":" +
        createDate.getMinutes() +
        "am";
    else if (createDate.getHours() == 12 || createDate.getHours() == 0)
      temp_date =
        temp_date +
        createDate.getHours() +
        ":" +
        createDate.getMinutes() +
        "pm";
    else if (createDate.getHours() > 12)
      temp_date =
        temp_date +
        (createDate.getHours() - 12) +
        ":" +
        createDate.getMinutes() +
        "pm";

    var countdown = Math.round(temp.countdownHour / 60);

    this.setState({
      amount: temp.interest_account_amount,
      balance: temp.interest_account_balance,
      createdDate: temp.interest_account_created_datetime,
      key: temp.interest_account_key,
      name: strings.t("interest") + " " + temp.interest_account_id,
      duration: countdown + " " + strings.t("hours"),
      // toShowData: temp_date,
    });

    this.momentFormatDate();
  }

  momentFormatDate = () => {
    const moment = require("moment");
    let temp = this.props.route.params.value;
    let tempmoment = moment(temp?.interest_account_created_datetime);

    let formatDate = `${tempmoment
      .format("D-MMM-YYYY")
      .toUpperCase()}, ${tempmoment.format("LT")}`;
    console.log(formatDate);
    this.setState({
      toShowData: formatDate,
    });
  };

  momentFormatDate = () => {
    const moment = require("moment");
    let temp = this.props.route.params.value;
    let tempmoment = moment(temp?.interest_account_created_datetime);

    let formatDate = `${tempmoment
      .format("D-MMM-YYYY")
      .toUpperCase()}, ${tempmoment.format("LT")}`;
    console.log(formatDate);
    this.setState({
      toShowData: formatDate,
    });
  };

  onPress_forgot_pin = () => {
    this.setState({ showPinModal: false });
    this.props.navigation.navigate("RecoverPin");
  };

  onSuccEnterPin = () => {
    this.setState({ showPinModal: false });
    this.go_remove();
  };

  onBackModal = () => {
    this.setState({ showPinModal: false });
  };

  onSwitch = (val) => {
    if (this.state.selection != val) {
      this.setState({ selection: val });
    }
  };

  onBackPressed = () => {
    this.props.route.params.onGoBackRefresh(this.state.refreshPrevious);
    this.props.navigation.goBack();
  };

  remove_account = () => {
    this.setState({ showModal: true });
  };

  on_cancel_modal = () => {
    this.setState({ showModal: false });
  };

  on_confrim_remove = async () => {
    this.setState({ showModal: false });
    this.setState({ showPinModal: true });
  };

  go_remove = async () => {
    this.setState({ isCancelLoading: true });
    await interestCaller
      .cancelInterestAccount(MyData.authToken, this.state.key)
      .then((res) => {
        this.props.route.params.onGoBack();
        this.props.navigation.goBack();
      })
      .catch((err) => {
        //console.log(err);

        this.setState({ isCancelLoading: false });
        if (err.response.status == 514) {
          this.props.expiredToken_logout();
        } else {
          MyToast.popToast(err.response.data.msg);
        }
      });
  };

  go_partial_withdraw = () => {
    this.props.navigation.navigate("PartialWithdraw", {
      value: this.state.key,
      onGoBack: (data) => {
        this.refresh(data);
      },
    });
  };

  refresh = (data) => {
    MyToast.popToast(strings.t("withdraw_succ"));
    let temp_balance = this.state.balance;
    let remaining = parseFloat(temp_balance) - parseFloat(data);
    this.setState({
      balance: remaining.toFixed(2),
      refreshPrevious: true,
    });
  };

  render() {
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: "white",
        }}
      >
        <ConfirmCancelModal
          isShow={this.state.showModal}
          onConfirmPress={() => this.on_confrim_remove()}
          onBackPress={() => this.on_cancel_modal()}
          textResource={
            strings.t("confirm_to_remove_and_withdraw") +
            " " +
            this.state.name +
            "?"
          }
        />
        <EnterTransactionPinModal
          isShow={this.state.showPinModal}
          onPressForgotPin={() => this.onPress_forgot_pin()}
          onSuccEnterPin={() => this.onSuccEnterPin()}
          onBackModal={() => this.onBackModal()}
        />
        <MainFlowHeaderContainer
          title={this.state.name}
          onBackPress={() => this.onBackPressed()}
        >
          <View
            style={{
              flex: 1,
              paddingTop: HeightDimen(15),
              paddingLeft: HeightDimen(30),
              paddingRight: HeightDimen(30),
            }}
          >
            <View
              style={{
                flexDirection: "row",
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  flex: 1,
                  // alignItems: 'center',
                  // backgroundColor: 'green'
                }}
              >
                <Image
                  source={require("../../Resources/Images/icon_nextP.png")}
                  style={{
                    width: 18,
                    height: 18,
                    aspectRatio: 1 / 1,
                    resizeMode: "contain",
                  }}
                />
                <Text
                  style={{
                    fontSize: fontDimen(11),
                    color: SecondaryColor,
                    marginLeft: HeightDimen(5),
                    alignSelf: "center",
                  }}
                >
                  {this.state.toShowData}
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Image
                  source={require("../../Resources/Images/clock-regular.png")}
                  style={{
                    width: 20,
                    height: 20,
                    aspectRatio: 1 / 1,
                    resizeMode: "contain",
                    tintColor: SecondaryColor,
                  }}
                />
                {/* <Icon name='clock-o' size={20} color={SecondaryColor} /> */}
                <Text
                  style={{
                    fontSize: fontDimen(11),
                    color: SecondaryColor,
                    marginLeft: HeightDimen(8),
                  }}
                >
                  {this.state.duration}
                </Text>
              </View>
            </View>

            <Text
              style={{
                fontSize: fontDimen(35),
                color: SecondaryColor,
                fontWeight: "bold",
                marginTop: HeightDimen(40),
              }}
            >
              {MyData.country_code}{" "}
              {numberWithCommas(Number(this.state.amount).toFixed(2))}
            </Text>
            <Text
              style={{
                fontSize: fontDimen(15),
                color: SecondaryColor,
                fontWeight: "bold",
                marginBottom: HeightDimen(40),
              }}
            >
              {strings.t("balance_RM") +
                MyData.country_code +
                " " +
                numberWithCommas(Number(this.state.balance).toFixed(2))}
            </Text>
            <TouchableOpacity
              onPress={() => this.remove_account()}
              style={{
                alignItems: "flex-end",
                marginBottom: HeightDimen(20),
              }}
              disabled={this.state.isCancelLoading}
            >
              {this.state.isCancelLoading ? (
                <ActivityIndicator size="small" color={MainColor} />
              ) : (
                <Text
                  style={{
                    fontSize: fontDimen(14),
                    color: "red",

                    textDecorationLine: "underline",
                  }}
                >
                  {strings.t("remove")}
                </Text>
              )}
            </TouchableOpacity>
            <ProfileCard
              src={require("../../Resources/Images/Wallet/icon_history.png")}
              label={strings.t("partial_withdraw")}
              onPress={() => this.go_partial_withdraw()}
            />
            {/* <View
                            style={{
                                flexDirection: 'row',
                                justifyContent: 'center',
                                marginTop: HeightDimen(20)
                            }}
                        >
                            <TouchableOpacity
                                style={{
                                    width: 'auto',
                                    height: HeightDimen(50),
                                    aspectRatio: 2.5 / 1,
                                    backgroundColor: this.state.selection == 1 ? SecondaryColor : 'rgba(237, 181, 61, 0.1)',
                                    marginRight: HeightDimen(10),
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                                onPress={() => this.onSwitch(1)}
                            >
                                <Text
                                    style={{
                                        fontSize: fontDimen(13),
                                        color: this.state.selection == 1 ? 'white' : FontMainColor,
                                    }}
                                >{'All Transaction'}
                                </Text> 

                            </TouchableOpacity>

                            <TouchableOpacity
                                style={{
                                    width: 'auto',
                                    height: HeightDimen(50),
                                    aspectRatio: 2.5 / 1,
                                    backgroundColor: this.state.selection == 2 ? SecondaryColor : 'rgba(237, 181, 61, 0.1)',
                                    marginLeft: HeightDimen(10),
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                                onPress={() => this.onSwitch(2)}
                            >
                                <Text
                                    style={{
                                        fontSize: fontDimen(13),
                                        color: this.state.selection == 2 ? 'white' : FontMainColor,
                                    }}
                                >Top Up History
                                </Text> 

                            </TouchableOpacity>
                            
                        </View> */}
          </View>
        </MainFlowHeaderContainer>
      </View>
    );
  }
}

export default InterestDetailsScreen;
