import React, { Component } from 'react'
import {
  View,
  FlatList,
  ActivityIndicator,
  Animated,
  Dimensions,
  Image,
  Platform,
  Linking,
} from 'react-native'
import leaderBoardCaller from '../../API/LeaderBoardCaller'
import { BorderGameCard } from '../../Component/Card/BorderGameCard'
import { GameCard } from '../../Component/Card/GameCard'
import { GameBackgroundHeaderContainer } from '../../Component/Header/BackgroundGameHeader'
import { GameSearchBar } from '../../Component/SearchBar/GameSearchBar'
import MyToast from '../../Component/Toast/MyToast'
import MyData from '../../Data/MyData'
import { MainColor } from '../../UI/Color'
import { HeightDimen } from '../../UI/Dimensions'

import { languageSelectedName } from '../../Utils/Common'
const isPortrait = () => {
  const dim = Dimensions.get('screen')
  return dim.height >= dim.width
}

class PlayTechGameScreen extends Component {
  state = {
    gameList: [],
    tempGameList: [],
    isLoading: true,
    orientation: '',
    scaleValue: new Animated.Value(0),
    title: this.props.route.params.title,
    logo: this.props.route.params.logo,
    productId: this.props.route.params.productId,
  }

  async componentDidMount() {
    const { navigation } = this.props
    this.get_PlayTech_Game()
  }
  onBackPressed = () => {
    this.props.route.params.onGoBack()
    this.props.navigation.goBack()
  }
  anumationImage = () => {
    Animated.timing(this.state.scaleValue, {
      duration: 500,
      toValue: 1,
      useNativeDriver: false,
    }).start()
  }
  get_PlayTech_Game = async () => {
    await leaderBoardCaller
      .getPlayTechGameList(MyData.authToken)
      .then((res) => {
        this.setState({
          gameList: res.response.gameList,
          tempGameList: res.response.gameList,
          isLoading: false,
        })
        this.anumationImage()
      })
      .catch((err) => {
        if (err.response.status == 514) {
          this.props.expiredToken_logout()
        } else {
          MyToast.popToast(err.response.data.msg)
        }
      })
  }
  on_Refresh = () => {
    this.setState({ isLoading: true })
    this.get_PlayTech_Game()
  }

  onSearch = (search) => {
    const { gameList } = this.state
    let filterList = gameList.filter((o) => {
      return (
        o.en_name &&
        o.en_name
          .toUpperCase()
          .replace(/ /g, '')
          .includes(search.toUpperCase().replace(/ /g, ''))
      )
    })
    this.setState({
      tempGameList: filterList,
    })
    if (!search) this.searchTextAnimation()
  }

  searchTextAnimation() {
    Animated.timing(this.state.scaleValue, {
      duration: 100,
      toValue: 0.6,
      useNativeDriver: false,
    }).start(() => {
      Animated.timing(this.state.scaleValue, {
        duration: 50,
        toValue: 1,
        useNativeDriver: false,
      }).start()
    })
  }

  start_PlayTech_Game = async (id, gameName) => {
    this.setState({ isLoading: true })
    await leaderBoardCaller
      .launchPlayTechGame(MyData.authToken, id, this.state.productId)
      .then((res) => {
        if (Platform.OS == 'ios') {
          Linking.openURL(res.response.game.Url)
        } else {
          this.props.navigation.navigate('PlayTechLaunchGame', {
            url: res.response.game.Url,
            title: gameName,
            onGoBack: (data) => {
              this.on_Refresh(data)
            },
          })
        }
      })
      .catch((err) => {
        if (err.response.status == 514) {
          this.props.expiredToken_logout()
        } else {
          MyToast.popToast(err.response.data.msg)
        }
      })
      .finally(() => {
        this.setState({ isLoading: false })
      })
  }

  renderItemCard = ({ item }) => {
    return (
      <BorderGameCard
        image={item.image_url}
        name={languageSelectedName(item.en_name, item.cn_name)}
        transform={{ transform: [{ scale: this.state.scaleValue }] }}
        onPress={() => {
          this.start_PlayTech_Game(item.code, item.en_name)
        }}
      />
    )
  }
  _onLayout = (event) => {
    const { height, width } = event.nativeEvent.layout
    if (height >= width) {
      this.on_Refresh()
    }
  }
  render() {
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: MainColor,
        }}
        onLayout={this._onLayout}
      >
        <GameBackgroundHeaderContainer
          source={require('../../Resources/Images/background.jpg')}
          title={''}
          onBackPress={() => this.onBackPressed()}
        >
          {this.state.isLoading ? (
            <View>
              <ActivityIndicator size="small" color={'white'} />
            </View>
          ) : (
            <View
              style={{
                flex: 1,
                marginTop: HeightDimen(-15),
              }}
            >
              <Image
                style={{
                  width: HeightDimen(120),
                  height: HeightDimen(120),
                  resizeMode: 'contain',
                  borderRadius: HeightDimen(10),
                  alignSelf: 'center',
                  marginBottom: HeightDimen(10),
                }}
                source={{ uri: this.state.logo }}
              />

              <View style={{ marginHorizontal: HeightDimen(15) }}>
                <GameSearchBar
                  placeholder={'Search'}
                  onChangeText={(val) => this.onSearch(val)}
                />
              </View>

              <FlatList
                numColumns={3}
                style={{
                  marginTop: HeightDimen(10),
                  paddingHorizontal: HeightDimen(10),
                }}
                contentContainerStyle={{
                  width: '100%',
                }}
                data={this.state.tempGameList}
                onRefresh={() => this.on_Refresh()}
                refreshing={this.state.isLoading}
                renderItem={this.renderItemCard}
                keyExtractor={(item) => String(item.code)}
              />
            </View>
          )}
        </GameBackgroundHeaderContainer>
      </View>
    )
  }
}

export default PlayTechGameScreen
