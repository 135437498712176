import React, { Component } from 'react'
import { View, ActivityIndicator } from 'react-native'
import aPICaller from '../../API/APICaller'
import { MainFlowHeaderContainerNoFeedback } from '../../Component/Header/MainFlowHeader'
import { strings } from '../../Language/Language'
import { MainColor } from '../../UI/Color'
import { HeightDimen } from '../../UI/Dimensions'

class ContactUsScreen extends Component {
  state = {
    url: '',
    isLoading: false,
  }

  async componentDidMount() {
    this.setState({ isLoading: true })
    await aPICaller
      .getWebURL()
      .then((res) => {
        this.setState({
          url: res.response.url,
        })
      })
      .catch((err) => {
        if (err.response.status == 514) {
          this.props.expiredToken_logout()
        } else {
          MyToast.popToast(err.response.data.msg)
        }
      })
      .finally(() => {
        this.setState({ isLoading: false })
      })
  }
  onBackPressed = () => {
    this.props.navigation.goBack()
  }
  render() {
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: 'white',
        }}
      >
        <MainFlowHeaderContainerNoFeedback
          title={strings.t('contacts')}
          onBackPress={() => this.onBackPressed()}
        >
          {this.state.isLoading ? (
            <View>
              <ActivityIndicator
                size="small"
                color={MainColor}
                style={{ marginTop: HeightDimen(20) }}
              />
            </View>
          ) : (
            <View style={{ flex: 1 }}>
              <iframe
                src={`${this.state.url}/Support`}
                height={'100%'}
                width={'100%'}
                frameBorder="0"
              />
            </View>
          )}
        </MainFlowHeaderContainerNoFeedback>
      </View>
    )
  }
}

export default ContactUsScreen
