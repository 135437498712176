import React, { Component } from 'react'
import { View } from 'react-native'
import { WidthDimen, HeightDimen } from '../../UI/Dimensions'
import { LoginFlowHeaderContainer } from '../../Component/Header/LoginFlowHeader'
import { TextButton } from '../../Component/Button/TextButton'
import NumberPad from '../../Component/NumberPad/NumberPad'
import { OTPCard } from '../../Component/Card/OTPCard'
import Mydata from '../../Data/MyData'
import MyToast from '../../Component/Toast/MyToast'
import { strings } from '../../Language/Language'
import pinAPICaller from '../../API/PinAPICaller'

import storageData from '../../Data/StorageData'

class CreatePinSecondScreen extends Component {
  state = {
    prevOTP: '',
    OTP: '',
    isLoading: false,
  }

  componentDidMount() {
    let temp = this.props.route.params.value
    this.setState({ prevOTP: temp })
  }

  onBackPressed = () => {
    Mydata.clear_all_data()
    this.props.navigation.goBack()
  }

  onEnterOTP = (num) => {
    let temp_OTP = this.state.OTP

    if (num === 'X') {
      if (temp_OTP.length > 0) {
        temp_OTP = temp_OTP.slice(0, -1)
        this.setState({ OTP: temp_OTP })
      }
    } else {
      if (temp_OTP.length < 6) {
        temp_OTP = temp_OTP + num
        this.setState({ OTP: temp_OTP })
      }
    }
  }

  onNextPressed = async () => {
    if (this.state.OTP.length == 6) {
      if (this.state.prevOTP == this.state.OTP) {
        this.createPin()
        //this.props.isLogin_Ready();
      } else {
        MyToast.popToast(strings.t('pin_dont_match'))
      }
    }
  }

  createPin = async () => {
    this.setState({ isLoading: true })
    // console.log(this.state.prevOTP)
    // console.log(Mydata.authToken)
    await pinAPICaller
      .setPin(Mydata.authToken, this.state.prevOTP, this.state.OTP)
      .then((res) => {
        Mydata.setUp(res)
        this.setState({ isLoading: false })
        Mydata.setUserPin(this.state.OTP)
        storageData.set_auth_session(res?.response?.authSession)
        this.props.isLogin_Ready()
      })
      .catch((err) => {
        console.log(err)
        this.setState({ isLoading: false })
        MyToast.popToast(err.response.data.msg)
      })
  }

  render_otp_input_box = () => {
    return (
      <View
        style={{
          justifyContent: 'center',
          flexDirection: 'row',
          marginBottom: HeightDimen(40),
        }}
      >
        <OTPCard value={this.state.OTP} num={0} />
        <OTPCard marginLeft={WidthDimen(10)} value={this.state.OTP} num={1} />
        <OTPCard marginLeft={WidthDimen(10)} value={this.state.OTP} num={2} />
        <OTPCard marginLeft={WidthDimen(10)} value={this.state.OTP} num={3} />
        <OTPCard marginLeft={WidthDimen(10)} value={this.state.OTP} num={4} />
        <OTPCard marginLeft={WidthDimen(10)} value={this.state.OTP} num={5} />
      </View>
    )
  }

  render() {
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: 'white',
        }}
      >
        <LoginFlowHeaderContainer
          onBackPress={() => this.onBackPressed()}
          mainText={strings.t('create_pin')}
          subText={strings.t('please_enter_pin_again')}
          wantStepper
          stepValue={Mydata.stepList.length == 4 ? 4 : 5}
          stepList={Mydata.stepList}
        >
          <View
            style={{
              flex: 1,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <this.render_otp_input_box />
            <View
              style={{
                width: '100%',
                paddingLeft: HeightDimen(30),
                paddingRight: HeightDimen(30),
              }}
            >
              <TextButton
                label={strings.t('confirm')}
                size={5}
                onPress={() => this.onNextPressed()}
                isLoading={this.state.isLoading}
              />
            </View>
          </View>
          <NumberPad onPressSelect={this.onEnterOTP} />
        </LoginFlowHeaderContainer>
      </View>
    )
  }
}

export default CreatePinSecondScreen
