import axios from 'axios'
import { apiUrls } from './APIurl'
import storageData from '../Data/StorageData'
import MyData from '../Data/MyData'

class WalletAPICaller {
  getWalletBalance = async (authToken) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.walletBalance}`,
          {
            data: {
              language: storageData.get_selected_language(),
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  getWalletBalanceCurrency = async (authToken) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.allWallet}`,
          {
            data: {},
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  loadBalance = async (authToken) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${MyData.api_url}${apiUrls.loadBalance}`, {
          headers: {
            Authorization: authToken,
          },
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  getTopupMethods = async (authToken) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${MyData.api_url}${apiUrls.getTopupMethod}`, {
          headers: {
            Authorization: authToken,
          },
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  uploadReceiptTopUp = async (base64, authToken, topUpvalue, bankId) => {
    let temp = ''
    // return await ImgToBase64.getBase64String(uri)
    //   .then((base64String) => {
    //     temp = base64String;
    //console.log(temp);
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.uploadReceiptImage}`,
          {
            data: {
              imageData: base64,
              language: storageData.get_selected_language(),
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data.return.imgUrl)
        })
        .catch((error) => {
          reject(error)
        })
    })
    // })
    // .catch((err) => {
    //   reject(err);
    // });
  }
  uploadMultiReceiptTopUp = async (base64, authToken) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.topUpReceiptBatch}`,
          {
            data: {
              imageDataArr: base64,
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
    // });
  }
  // uploadTopUpValue = async (authToken, topupAmount, url, bankId, name) => {
  //   return new Promise((resolve, reject) => {
  //     axios
  //       .post(
  //         `${MyData.api_url}${apiUrls.uploadTopUp}`,
  //         {
  //           data: {
  //             topUpBankId: bankId,
  //             topUpOwnerName: name,
  //             topUpAmount: topupAmount,
  //             imageUrl: url,
  //           },
  //         },
  //         {
  //           headers: {
  //             Authorization: authToken,
  //           },
  //         }
  //       )
  //       .then((response) => {
  //         resolve(response);
  //       })
  //       .catch((error) => {
  //         reject(error);
  //       });
  //   });
  // };
  uploadTopUpValue = async (
    authToken,
    topupAmount,
    url,
    bankId,
    name,
    promo_code
  ) => {
    const payload = {
      data: {
        topUpBankId: bankId,
        topUpOwnerName: name,
        topUpAmount: topupAmount,
        imageUrls: url,
        couponCode: promo_code,
      },
    }
    console.log('@PAYLOAD', payload)
    return new Promise((resolve, reject) => {
      fetch(`${MyData.api_url}${apiUrls.uploadTopUp}`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: authToken,
        },
        body: JSON.stringify(payload),
      })
        .then(async (response) => {
          const res = await response.json()

          if (response.status == 200) {
            resolve(res)
          } else {
            reject({ message: res?.msg, status: response?.status })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  // withdrawValue = async (authToken, withvalue, withdrawAccountId) => {
  //   return new Promise((resolve, reject) => {
  //     axios
  //       .post(
  //         `${MyData.api_url}${apiUrls.requestWithdraw}`,
  //         {
  //           data: {
  //             withdrawAmount: withvalue,
  //             withdrawBankAccountId: withdrawAccountId,
  //             language: storageData.get_selected_language(),
  //           },
  //         },
  //         {
  //           headers: {
  //             Authorization: authToken,
  //           },
  //         },
  //       )
  //       .then((response) => {
  //         resolve(response);
  //       })
  //       .catch((error) => {
  //         reject(error);
  //       });
  //   });
  // };
  withdrawValue = async (authToken, withvalue, withdrawAccountId) => {
    const payload = {
      data: {
        withdrawAmount: withvalue,
        withdrawBankAccountId: withdrawAccountId,
        language: storageData.get_selected_language(),
      },
    }
    return new Promise((resolve, reject) => {
      fetch(`${MyData.api_url}${apiUrls.requestWithdraw}`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: authToken,
        },
        body: JSON.stringify(payload),
      })
        .then(async (response) => {
          const res = await response.json()

          if (response.status == 200) {
            resolve(res)
          } else {
            reject({ message: res.msg, status: response?.status })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  previewWithdrawUsdt = async (authToken, amount, withdrawAccountId) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.previewithdrawUsdt}`,
          {
            data: {
              withdrawAmount: amount,
              withdrawBankAccountId: withdrawAccountId,
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  getAvailableWithdraw = async (authToken) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.availableWithdraw}`,
          {
            data: {
              language: storageData.get_selected_language(),
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  getTopUpHistory = async (authToken) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.topUpHistory}`,
          {
            data: {
              language: storageData.get_selected_language(),
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  getWithdrawHistory = async (authToken) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.withdrawHistory}`,
          {
            data: {
              language: storageData.get_selected_language(),
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          //console.log("ererre")
          reject(error)
        })
    })
  }

  getWalletTransaction = async (authToken, fromDates, toDates) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.walletTransaction}`,
          {
            data: {
              fromDate: fromDates,
              toDate: toDates,
              language: storageData.get_selected_language(),
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  allWalletTransaction = async (authToken, date, type, endDate, page) => {
    // console.log(authToken)
    // console.log(date, endDate, type)
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.allWalletTransaction}`,
          {
            data: {
              fromDate: date,
              toDate: endDate,
              type: type,

              language: storageData.get_selected_language(),
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  onCancelTopUp = async (authToken, id) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.cancelTopUp}`,
          {
            data: {
              topupId: id,
              language: storageData.get_selected_language(),
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  onCancelWithdraw = async (authToken, id) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.cancelWithdraw}`,
          {
            data: {
              transactionId: id,
              language: storageData.get_selected_language(),
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  onCheckTopUp = async (authToken) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.checkTopup}`,
          {
            data: {},
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  onCheckWithdraw = async (authToken) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.checkWithdraw}`,
          {
            data: {},
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  checkPromoValidity = async (authToken, promoCode) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.promoValidity}`,
          {
            data: {
              key: MyData.temp_json_object.response.user.member_unique_key,
              promo: promoCode,
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  onCurrencyPreview = async (authToken, amount, origin) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.currencyPreview}`,
          {
            data: {
              amount: amount,
              origin: origin,
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  onRequestTopupUsdt = async (
    authToken,
    amount,
    bankId,
    receipt,
    coupon_code
  ) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.requestTopupUsdt}`,
          {
            data: {
              topUpAmount: amount,
              topUpBankId: bankId,
              receipt: receipt,
              couponCode: coupon_code,
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  onGetCurrencyPlatform = async (authToken) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${MyData.api_url}${apiUrls.getPlatform}`, {
          headers: {
            Authorization: authToken,
          },
        })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  getRewards = async (authToken, date) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.rewardList}`,
          {
            data: {
              date: date,
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  claimReward = async (authToken, id) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.rewardClaim}`,
          {
            data: {
              reward_id: id,
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  onGetLatestTransactions = async (authToken) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${MyData.api_url}${apiUrls.latestTransactionList}`, {
          headers: {
            Authorization: authToken,
          },
        })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  onGetProfileData = async (authToken) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${MyData.api_url}${apiUrls.getProfileData}`, {
          headers: {
            Authorization: authToken,
          },
        })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  onPreviewWithdrawUSDT = async (authToken, network, address, amount) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.previewWithdrawUSDT}`,
          {
            data: {
              withdrawNetwork: network,
              withdrawAddress: address,
              withdrawAmount: amount,
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  onRequestWithdrawUsdt = async (authToken, network, address, amount) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.submitWithdrawUSDT}`,
          {
            data: {
              withdrawNetwork: network,
              withdrawAddress: address,
              withdrawAmount: amount,
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  onGetAvailableBalance = async (authToken) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.getUSDTAvailableBalance}`,
          {
            data: {},
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  onPreviewRate = async (authToken, amount, origin) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.currencyPreviewRate}`,
          {
            data: { amount: amount, origin: origin },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}

const walletAPICaller = new WalletAPICaller()
export default walletAPICaller
