import { View, Text, TextInput, StyleSheet } from 'react-native'
import { HeightDimen, fontDimen } from '../../UI/Dimensions'

const OutlineInput = (props) => {
  const { title, value, marginTop } = props

  return (
    <View
      style={{
        marginTop: marginTop,
      }}
    >
      <View style={styles.labelContainer}>
        <Text style={styles.label}>{title}</Text>
      </View>
      <View style={styles.inputContainer}>
        <TextInput
          placeholder={title}
          value={value}
          editable={false}
          style={{
            outlineStyle: 'none',
            fontSize: fontDimen(15),
            color: '#0ac5cf',
            height: HeightDimen(50),
          }}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  labelContainer: {
    backgroundColor: '#EFF2F4',
    alignSelf: 'flex-start',
    paddingHorizontal: 3,
    marginStart: 10,
    zIndex: 1,
    position: 'absolute',
    top: -10,
    borderRadius: HeightDimen(2),
  },
  label: {
    color: '#6c6c6c',
    fontSize: fontDimen(14),
  },
  inputContainer: {
    borderWidth: 1,
    borderRadius: HeightDimen(4),
    borderColor: '#6c6c6c',
    zIndex: 0,
    justifyContent: 'center',
    backgroundColor: '#EFF2F4',
    width: '100%',
    height: HeightDimen(50),
    paddingLeft: HeightDimen(5),
  },
})

export { OutlineInput }
