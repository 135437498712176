import React, { Component } from "react";
import {
  View,
  TouchableOpacity,
  FlatList,
  Image,
  ActivityIndicator,
} from "react-native";

import Text from "../../Component/Text/MyText";

import {
  MainColor,
  SecondaryColor,
  FontMainColor,
  FontSecondaryColor,
} from "../../UI/Color";
import {
  WidthDimen,
  HeightDimen,
  fontDimen,
  GetWidth,
  GetHeight,
} from "../../UI/Dimensions";

import {
  MainFlowHeader,
  MainFlowHeaderContainer,
} from "../../Component/Header/NewDesign/MainFlowHeader";
import BankAPICaller from "../../API/BankAPICaller";
import MyData from "../../Data/MyData";
import MyToast from "../../Component/Toast/MyToast";
import { strings } from "../../Language/Language";
import { AddNewFriendCard } from "../../Component/Card/AddNewFriendCard";
import { SearchBar } from "../../Component/SearchBar/SearchBar";
import { BankCard } from "../../Component/Card/BankCard";
import { RequestOTPModal } from "../../Component";

class BankAccScreen extends Component {
  state = {
    isLoading: true,
    bankList: [],
    bankAccountId: "",
    bankAccountName: "",
    bankAccountNumber: "",
    showBankName: "",
    tempBankAccountList: [],
    iscurrentAccount: false,
    bankAccountList: [],
    verifyModalShow: false,
  };

  async componentDidMount() {
    await this.getBankList();
    await this.getBankAccounts();
    ///  this.getCurrentBankDetails();
  }

  // getCurrentBankDetails = () => {
  //   if (MyData.bankAccountNo != null) {
  //     var temp_name = "";

  //     for (let i = 0; i < this.state.bankList.length; i++) {
  //       if (this.state.bankList[i].bank_id == MyData.bankId) {
  //         temp_name = this.state.bankList[i].bank_name;
  //         break;
  //       }
  //     }

  //     this.setState({
  //       bankAccountId: MyData.bankId,
  //       bankAccountName: MyData.bankName,
  //       bankAccountNumber: MyData.bankAccountNo,
  //       iscurrentAccount: true,
  //       showBankName: temp_name,
  //     });
  //   }
  // };

  getBankList = async () => {
    this.setState({
      isLoading: true,
    });
    await BankAPICaller.getAvailableBank(MyData.authToken)
      .then((res) => {
        var temp = [];
        res.response.bankList.map((d) => temp.push(d));
        this.setState({
          isLoading: false,
          bankList: temp,
        });
      })
      .catch((err) => {
        if (err.response.status == "514") {
          this.props.expiredToken_logout();
        } else {
          MyToast.popToast(err.response.data.msg);
        }
      });
  };
  getBankAccounts = async () => {
    this.setState({
      isLoading: true,
    });
    await BankAPICaller.getBankAccounts(MyData.authToken)
      .then((res) => {
        this.setState({
          isLoading: false,
          bankAccountList: res.response.accounts,
          tempBankAccountList: res.response.accounts,
        });
      })
      .catch((err) => {
        if (err.response.status == "514") {
          this.props.expiredToken_logout();
        } else {
          MyToast.popToast(err.response.data.msg);
        }
      })
      .finally(() => {
        this.setState({
          isLoading: false,
        });
      });
  };
  checkUserVerification = async () => {
    this.setState({
      isLoading: true,
    });
    await BankAPICaller.checkUserVerification(MyData.authToken)
      .then((res) => {
        this.setState({
          isLoading: false,
        });
        this.props.navigation.navigate("AddBank", {
          value: this.state.bankList,
          onGoBack: (data) => {
            this.onRefresh(data);
            this?.props?.route?.params?.onGoBack();
          },
        });
      })
      .catch((err) => {
        if (err.response.status == "514") {
          this.props.expiredToken_logout();
        } else if (err.response.status == "302") {
          this.setState({ verifyModalShow: true });
        } else {
          MyToast.popToast(err.response.data.msg);
        }
      })
      .finally(() => {
        this.setState({
          isLoading: false,
        });
      });
  };

  onBackPressed = () => {
    this?.props?.route?.params?.onGoBack();
    this.props.navigation.goBack();
  };

  onPress_AddBank = async () => {
    if (MyData.temp_json_object?.response?.user?.wallet_pin_status == "true") {
      if (MyData.country_code == "THB") {
        await this.checkUserVerification();
      } else {
        this.props.navigation.navigate("AddBank", {
          value: this.state.bankList,
          onGoBack: (data) => {
            this.onRefresh(data);
            this?.props?.route?.params?.onGoBack();
          },
        });
      }
    } else {
      this.props.navigation.navigate("CreatePinVerification", {
        page: "",
        onGoBack: (data) => {
          //.refresh(data)
        },
      });
    }
  };

  onPress_EditBank = (selectedBank) => {
    this.props.navigation.navigate("EditBank", {
      value: this.state.bankList,
      bank: selectedBank,
      onGoBack: (data) => {
        this.onRefresh(data);
        this?.props?.route?.params?.onGoBack();
      },
    });
  };

  onAddBankSuccess = async (data) => {
    // if (data) {
    //   var temp_name = "";
    //   for (let i = 0; i < this.state.bankList.length; i++) {
    //     if (this.state.bankList[i].bank_id == MyData.bankId) {
    //       temp_name = this.state.bankList[i].bank_name;
    //       break;
    //     }
    //   }
    //   this.setState({
    //     bankAccountId: MyData.bankId,
    //     bankAccountName: MyData.bankName,
    //     bankAccountNumber: MyData.bankAccountNo,
    //     iscurrentAccount: true,
    //     showBankName: temp_name,
    //   });
    //   MyToast.popToast(strings.add_back_account_succ);
    // }

    await this.getBankAccounts();
  };

  async onRefresh() {
    await this.getBankAccounts();
  }

  getbankName(bankId) {
    const bank = this.state.bankList.find((item) => {
      return item.bank_id === bankId;
    });
    if (bank) return bank;
  }
  onSearchBank(val) {
    const { bankAccountList } = this.state;
    let tempBankAccount = bankAccountList.filter((o) => {
      return (
        o.account_name
          .toUpperCase()
          .replace(/ /g, "")
          .includes(val.toUpperCase().replace(/ /g, "")) ||
        o.account_number
          .toUpperCase()
          .replace(/ /g, "")
          .includes(val.toUpperCase().replace(/ /g, ""))
      );
    });
    this.setState({ tempBankAccountList: tempBankAccount });
  }
  onVerificationSuccess() {
    this.onCloseModal();
    this.props.navigation.navigate("AddBank", {
      value: this.state.bankList,
      onGoBack: (data) => {
        this.onRefresh(data);
        this?.props?.route?.params?.onGoBack();
      },
    });
  }
  onCloseModal() {
    this.setState({ verifyModalShow: false });
  }
  render() {
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: "white",
        }}
      >
        {this.state.verifyModalShow && (
          <RequestOTPModal
            isShow={this.state.verifyModalShow}
            onSuccessVerificaion={() => this.onVerificationSuccess()}
            onCloseModal={() => this.onCloseModal()}
          />
        )}
        <MainFlowHeaderContainer
          title={strings.t("bank_account")}
          onBackPress={() => this.onBackPressed()}
        >
          <View
            style={{
              flex: 1,
              paddingLeft: HeightDimen(20),
              paddingRight: HeightDimen(20),
              paddingTop: HeightDimen(20),
            }}
          >
            <AddNewFriendCard
              name={strings.t("add_bank_account")}
              src={require("../../Resources/Images/icon_plus.png")}
              onPress={() => this.onPress_AddBank()}
            />
            <SearchBar
              placeholder={strings.t("bank_search_bank")}
              onChangeText={(val) => this.onSearchBank(val)}
            />

            {this.state.isLoading ? (
              <View style={{ marginTop: HeightDimen(15) }}>
                <ActivityIndicator size="small" color={MainColor} />
              </View>
            ) : (
              <FlatList
                onRefresh={() => this.onRefresh()}
                refreshing={this.state.isLoading}
                data={this.state.tempBankAccountList}
                renderItem={({ item, index }) => (
                  <BankCard
                    title={item.account_name}
                    description={this.getbankName(item.bank_id).bank_name}
                    accountNo={"*" + item.account_number.substr(-4, 4)}
                    onPress={() => this.onPress_EditBank(item)}
                    wantIcon
                  />
                )}
                keyExtractor={(item) => String(item.id)}
              />
            )}
          </View>
        </MainFlowHeaderContainer>
      </View>
    );
  }
}

export default BankAccScreen;
