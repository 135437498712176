import React, { Component } from 'react'
import {
  View,
  Image,
  ActivityIndicator,
  BackHandler,
  ScrollView,
  RefreshControl,
  Linking,
  Modal,
  Platform,
  TextInput,
  TouchableOpacity,
} from 'react-native'
import Text from '../../Component/Text/MyText'
import { MainColor, SecondaryColor } from '../../UI/Color'
import { HeightDimen, fontDimen, WidthDimen } from '../../UI/Dimensions'
import { TextButton } from '../../Component/Button/TextButton'
import { DisplayInput } from '../../Component/Input/DisplayInput'
import MyToast from '../../Component/Toast/MyToast'
import productAPICaller from '../../API/ProductAPICaller'
import walletAPICaller from '../../API/WalletAPICaller'
import MyData from '../../Data/MyData'
import { strings } from '../../Language/Language'
import { AgreeCheckBox } from '../../Component/CheckBox/AgreeCheckBox'
import { GameDetailsHeaderContainer } from '../../Component/Header/GameDetailsHeader'
import PasswordInput from '../../Component/Input/PasswordInput'
import {
  isValidDeelinkURL,
  normalizeDecimalValue,
  numberWithCommas,
  product_images,
} from '../../Utils/Common'
import { TransactionModal } from '../../Component/Modal/TransactionModal'
import { TopUpModal } from '../../Component/Modal/NewDesign/TopUpModal'
import { RewardModal } from '../../Component/Modal/RewardSuccessModal'
import { Line } from '../../Component/Line/Line'
import { IconTextCard } from '../../Component/Card/NewDesign/IconTextCard'
import { OutlineInput } from '../../Component/Input/OutlineInput'
import { InfoTextModal } from '../../Component'

class GameDetailsScreen extends Component {
  state = {
    title: '',
    productID: '',
    desc: '',
    image_url: '',
    amount: '',
    isLoading: false,
    isLoadingCredit: false,
    isInGame: false,
    isShowButton: true,
    availableData: {},
    termStatus: false,
    termText: '',
    isValidDeepLink: false,
    hideBackButton: false,
    newPassword: '',
    isChangePasswordLoading: false,
    isPasswordChangeModal: false,
    isChangePasswordLoading: false,
    megaUrl: '',
    isTransactionModal: false,
    rewardSuccessModal: false,
    isGameCloseFromScreen: false,
    rewardAmount: '',
    isCoinSpray: false,
    isCashout: false,
    rewardList: [],
    isRewardForceClose: false,
    maxAmountList: ['100', '500', '1000', '2000', '4000', '5000'],
    topupList: [],
    show_top_up_modal: false,
    localImage: null,
    hasViewOptions: false, // for sport mobile/web
    showViewOptionModal: false,
    isPullCreditLoading: false,
    walletType: 'Cash',
    coin_entitled: 'false',
    agreementModalShow: false,
  }

  constructor(props) {
    super(props)
    this.handleBackButtonClick = this.handleBackButtonClick.bind(this)
    this.inputRef = React.createRef()
  }

  async componentDidMount() {
    // logScreenViewAnalytics(screenNames.GameDetail);
    BackHandler.addEventListener(
      'hardwareBackPress',
      this.handleBackButtonClick
    )

    let temp = this.props.route.params.value
    let temp2 = this.props.route.params.isInGame
    console.log(temp)

    let localProdImage = this.getImageUrl(temp.product_name)

    this.setState({
      title: temp.product_name,
      productID: temp.product_id,
      image_url: temp.product_image_url,
      desc: temp.product_desc,
      isInGame: temp2,
      termStatus: temp.termStatus,
      termText: temp.termText,
      isShowButton: !temp.termStatus,
      localImage: temp?.product_coin_entitled == 'false' ? localProdImage : '',
      coin_entitled: temp?.product_coin_entitled,
    })

    this.getAvailableCredit(temp.product_id, 'false', this.state.walletType)
    await this.getTopupMethod()
  }
  getImageUrl = (name) => {
    // let image = product_images.find((item) => {
    //   return item?.name?.toLowerCase() == name?.toLowerCase()
    // })?.path
    // if (image) return image
    // else
    return ''
  }

  getImageUrl = (name) => {
    // let image = product_images.find((item) => {
    //   return item?.name?.toLowerCase() == name?.toLowerCase()
    // })?.path
    // if (image) return image
    // else
    return ''
  }

  getTopupMethod = async () => {
    await walletAPICaller
      .getTopupMethods(MyData.authToken)
      .then((res) => {
        this.setState({ topupList: res.response.list })
      })
      .catch((err) => {
        if (err.response.status == 514) {
          this.props.expiredToken_logout()
        } else {
          MyToast.popToast(err.response.data.msg)
        }
      })
  }

  onPress_InstantTopUp = () => {
    // logMessageAnalytics(AnalyticEvents.GameDetailInstantTopupClick);
    this.setState({
      show_top_up_modal: false,
    })
    this.props.navigation.navigate('InstantTopUp', {
      onGoBack: (data) => {
        this.on_Refresh(data)
      },
    })
  }
  onPress_UsdtTopUp = () => {
    // logMessageAnalytics(AnalyticEvents.GameDetailUSDTClick);
    this.setState({
      show_top_up_modal: false,
    })
    this.props.navigation.navigate('UsdtTopUp', {
      onGoBack: (data) => {
        this.on_Refresh()
      },
    })
  }
  onPress_BankTopUp = () => {
    // logMessageAnalytics(AnalyticEvents.GameDetailBankTopupClick);
    this.setState({
      show_top_up_modal: false,
    })
    this.props.navigation.navigate('TopUp', {
      onGoBack: (data) => {
        this.on_Refresh(data)
      },
    })
  }

  onCancel_modal = () => {
    // logMessageAnalytics(AnalyticEvents.GameDetailTopupCloseModal);
    this.setState({
      show_top_up_modal: false,
    })
  }

  componentWillUnmount() {
    BackHandler.removeEventListener(
      'hardwareBackPress',
      this.handleBackButtonClick
    )
  }

  handleBackButtonClick() {
    if (this.state.hideBackButton == false) {
      this.onBackPressed()
    }
    return true
  }

  checkAgree = (val) => {
    this.setState({ isShowButton: val })
  }

  getAvailableCredit = async (id, pullCredit = 'false', walletType) => {
    this.setState({ isLoadingCredit: pullCredit == 'true' ? false : true })
    await productAPICaller
      .getAvailableCredit(MyData.authToken, id, pullCredit, walletType)
      .then(async (res) => {
        debugger
        //   logMessageAnalytics(AnalyticEvents.AvailableCreditSuccess);
        const isGameStatus = res.response.inGameStatus ? true : false
        let isValidLink = false

        if (
          this.state.title === 'LION' ||
          this.state.title === 'PUSSY' ||
          this.state.title === 'EVO888' ||
          this.state.title === '918KISS' ||
          this.state.title === 'MGC'
        ) {
          isValidLink = await isValidDeelinkURL(this.state.title)
        }

        this.setState({
          isLoadingCredit: false,
          amount: res.response.avaiableTransfer,
          availableData: res.response,
          isInGame: isGameStatus,
          megaUrl: res.response.megaURL,
          isValidDeepLink: isValidLink,
          hasViewOptions: res?.response?.hasViewOptions,
        })
      })
      .catch((err) => {
        // logMessageAnalytics(
        //   AnalyticEvents.AvailableCreditSuccess,
        //   err.response.data.msg
        // );
        this.setState({ isLoadingCredit: false })
        console.log(err)
        if (err.response.status == 514) {
          this.props.expiredToken_logout()
        } else {
          MyToast.popToast(err.response.data.msg)
        }
      })
  }
  onPress_topUp = () => {
    this.props.navigation.navigate('TopupWithdraw', {
      selectionType: 1,
      onGoBack: (data) => {
        // this.refresh(data)
      },
    })
  }
  checkTopUp = async () => {
    this.setState({ isLoading: true })
    await walletAPICaller
      .onCheckTopUp(MyData.authToken)
      .then((res) => {
        if (res.status == 200) {
          this.onPress_topUp()
        } else if (res.status == 201) {
          this.props.navigation.navigate('TopUpDetailsScreen', {
            bankList: [],
            selectedTopUpDetails: res.data.response.info,
            onGoBack: (data) => {
              this.on_Refresh(data)
            },
          })
        }
      })
      .catch((err) => {
        if (err.response.status == '514') {
          this.props.expiredToken_logout()
        } else {
          MyToast.popToast(err.response.data.msg)
        }
      })
      .finally(() => {
        this.setState({ isLoading: false })
      })
  }

  handle_amount = (val) => {
    debugger
    console.log(this.inputRef)
    let value = normalizeDecimalValue(val)
    if (Number(this.state.availableData.avaiableTransfer) >= Number(value)) {
      //this.inputRef?.current?.setSelection(value?.length, value?.length)
      this.setState({
        amount: value,
      })
    } else {
      // this.inputRef?.current?.setSelection(
      //   this.state.availableData.avaiableTransfer.toString()?.length,
      //   this.state.availableData.avaiableTransfer.toString()?.length
      // )

      this.setState({
        amount: this.state.availableData.avaiableTransfer.toString(),
      })
    }
  }

  onBackPressed = () => {
    //logEventWithScreenName(eventMessages.BackButton, screenNames.GameDetail);
    this.props.route.params?.onGoBack()
    this.props.navigation.goBack()
  }

  onStartPressed = () => {
    this.start_game()
  }

  close_game_and_back = async () => {
    this.setState({
      isLoading: true,
      isCashout: true,
      isTransactionModal: true,
    })
    await productAPICaller
      .closeGame(MyData.authToken, this.state.productID)
      .then(async (res) => {
        this.on_Refresh('true')
        //  logMessageAnalytics(AnalyticEvents.CloseGameSuccess);
        this.setState({ rewardList: res.response.rewards, isLoading: false })
        MyToast.popToast(strings.t('game_closed'))
        if (res.response.rewards.length > 0) {
          const rewardAmount = res.response.rewards.reduce((acc, obj) => {
            return acc + Number(obj.amount)
          }, 0)

          setTimeout(() => {
            this.setState({
              isCashout: false,
              isCoinSpray: true,
              rewardAmount: rewardAmount,
            })
          }, 5000)
          setTimeout(() => {
            this.setState({
              isTransactionModal: false,
              isCoinSpray: false,
              rewardSuccessModal: this.state.isRewardForceClose ? false : true,
              isRewardForceClose: false,
            })
          }, 10000)
        } else {
          setTimeout(() => {
            this.setState({
              isTransactionModal: false,
            })
          }, 1500)
          this.onBackPressed()
        }
      })
      .catch((err) => {
        // logMessageAnalytics(AnalyticEvents.CloseGameFailure, err?.message);

        this.setState({ isLoading: false, isTransactionModal: false })
      })
      .finally(async () => {})
  }

  close_game = async () => {
    this.setState({
      isLoadingCredit: true,
      isTransactionModal: true,
      isCashout: true,
    })
    await productAPICaller
      .closeGame(MyData.authToken, this.state.productID)
      .then((res) => {
        // logMessageAnalytics(AnalyticEvents.CloseGameSuccess);
        this.setState({
          rewardList: res.response.rewards,
          isLoading: false,
          isLoadingCredit: false,
          hideBackButton: false,
        })
        if (res.response.rewards.length > 0) {
          const rewardAmount = res.response.rewards.reduce((acc, obj) => {
            return acc + Number(obj.amount)
          }, 0)
          setTimeout(() => {
            this.setState({
              isCashout: false,
              isCoinSpray: true,
              rewardAmount: rewardAmount,
            })
          }, 5000)
          setTimeout(() => {
            this.setState({
              isTransactionModal: false,
              isCoinSpray: false,
              rewardSuccessModal: this.state.isRewardForceClose ? false : true,
              isRewardForceClose: false,
            })
          }, 10000)
        } else {
          setTimeout(() => {
            this.setState({
              isTransactionModal: false,
            })
          }, 1500)
        }
      })
      .catch((err) => {
        // logMessageAnalytics(AnalyticEvents.CloseGameFailure, err?.message);
        this.setState({ hideBackButton: false, isTransactionModal: false })
        MyToast.popToast(err?.message)
      })
  }

  start_game = async (viewType) => {
    this.setState({
      isLoading: true,
      hideBackButton: true,
      showViewOptionModal: false,
    })
    await productAPICaller
      .launchGame(
        MyData.authToken,
        this.state.productID,
        this.state.amount,
        this.state.walletType,
        viewType
      )
      .then(async (res) => {
        // logMessageAnalytics(AnalyticEvents.LaunchGameSuccess);
        this.on_Refresh()
        this.setState({ isLoading: false, hideBackButton: false })
        if (!res?.response?.gameDetails?.Url) {
          if (this.state.desc === 'XE88') {
            this.props.navigation.navigate('Xe88GameDetails', {
              title: this.state.title,
              logo: this.state.image_url,
              productId: this.state.productID,
              onGoBack: (data) => {
                this.refresh(data)
              },
            })
          } else if (this.state.desc === 'PT Slot') {
            this.props.navigation.navigate('PlayTechGame', {
              title: this.state.title,
              logo: this.state.image_url,
              productId: this.state.productID,
              onGoBack: (data) => {
                this.refresh(data)
              },
            })
          } else if (this.state.desc === 'vPower') {
            this.props.navigation.navigate('VPowerGame', {
              title: this.state.title,
              logo: this.state.image_url,
              onGoBack: (data) => {
                this.refresh(data)
              },
            })
          } else if (this.state.desc === 'Joker') {
            this.props.navigation.navigate('JokerGame', {
              title: this.state.title,
              logo: this.state.image_url,
              productId: this.state.productID,
              onGoBack: (data) => {
                this.refresh(data)
              },
            })
          } else if (this.state.desc === 'BOLABOLA') {
            Linking.openURL(res.response.gameDetails.Url)
          } else if (this.state.desc === 'PP Slot') {
            if (MyData.country_code == 'THB') {
              this.props.navigation.navigate('SexyPragamaticGame', {
                title: this.state.title,
                logo: this.state.image_url,
                productId: this.state.productID,
                onGoBack: (data) => {
                  this.refresh(data)
                },
              })
              return
            }

            this.props.navigation.navigate('PragmaticGame', {
              title: this.state.title,
              logo: this.state.image_url,
              productId: this.state.productID,
              onGoBack: (data) => {
                this.refresh(data)
              },
            })
          } else if (this.state.desc === '100PLUS') {
            this.props.navigation.navigate('Plus100Game', {
              title: this.state.title,
              logo: this.state.image_url,

              productId: this.state.productID,
              onGoBack: (data) => {
                this.refresh(data)
              },
            })
          } else if (this.state.desc === 'Fun House') {
            this.props.navigation.navigate('FunHouseGame', {
              title: this.state.title,
              logo: this.state.image_url,
              productId: this.state.productID,
              onGoBack: (data) => {
                this.refresh(data)
              },
            })
          } else if (this.state.desc === 'PEGASUS') {
            this.props.navigation.navigate('PGSGame', {
              title: this.state.title,
              logo: this.state.image_url,
              productId: this.state.productID,
              onGoBack: (data) => {
                this.refresh(data)
              },
            })
          } else if (this.state.desc === 'KINGKONG') {
            this.props.navigation.navigate('KingKongGame', {
              title: this.state.title,
              logo: this.state.image_url,
              productId: this.state.productID,
              onGoBack: (data) => {
                this.refresh(data)
              },
            })
          } else if (this.state.desc === 'JDBFISH') {
            this.props.navigation.navigate('JDBFishGame', {
              title: this.state.title,
              logo: this.state.image_url,
              productId: this.state.productID,
              onGoBack: (data) => {
                this.refresh(data)
              },
            })
          } else if (this.state.desc === 'SPADEFISH') {
            this.props.navigation.navigate('SpadeFishGame', {
              title: this.state.title,
              logo: this.state.image_url,
              productId: this.state.productID,
              onGoBack: (data) => {
                this.refresh(data)
              },
            })
          } else if (this.state.desc === 'YLFISH') {
            this.props.navigation.navigate('YLFishGame', {
              title: this.state.title,
              logo: this.state.image_url,
              productId: this.state.productID,
              onGoBack: (data) => {
                this.refresh(data)
              },
            })
          } else if (this.state.desc === 'RT') {
            this.props.navigation.navigate('SexyRedTigerGame', {
              title: this.state.title,
              logo: this.state.image_url,
              productId: this.state.productID,
              onGoBack: (data) => {
                this.refresh(data)
              },
            })
          } else if (this.state.desc === 'PG') {
            this.props.navigation.navigate('SexyPGGame', {
              title: this.state.title,
              logo: this.state.image_url,
              productId: this.state.productID,
              onGoBack: (data) => {
                this.refresh(data)
              },
            })
          } else if (this.state.desc === 'JILI') {
            this.props.navigation.navigate('SexyJiliGame', {
              title: this.state.title,
              logo: this.state.image_url,
              productId: this.state.productID,
              onGoBack: (data) => {
                this.refresh(data)
              },
            })
          } else if (this.state.desc === 'KINGMAKER') {
            this.props.navigation.navigate('KingMakerGame', {
              title: this.state.title,
              logo: this.state.image_url,
              productId: this.state.productID,
              onGoBack: (data) => {
                this.refresh(data)
              },
            })
          } else if (this.state.desc === 'Next Spin') {
            this.props.navigation.navigate('NextSpinGame', {
              title: this.state.title,
              logo: this.state.image_url,
              productId: this.state.productID,
              onGoBack: (data) => {
                this.refresh(data)
              },
            })
          } else if (this.state.desc === 'Ae slot') {
            this.props.navigation.navigate('AESlotGame', {
              title: this.state.title,
              logo: this.state.image_url,
              productId: this.state.productID,
              onGoBack: (data) => {
                this.refresh(data)
              },
            })
          } else if (this.state.desc === 'CROWDPLAY') {
            this.props.navigation.navigate('CrowdPlayGame', {
              title: this.state.title,
              logo: this.state.image_url,
              productId: this.state.productID,
              onGoBack: (data) => {
                this.refresh(data)
              },
            })
          } else if (this.state.desc === 'AE Slot') {
            //for thb
            this.props.navigation.navigate('AESexyGame', {
              title: this.state.title,
              logo: this.state.image_url,
              productId: this.state.productID,
              onGoBack: (data) => {
                this.refresh(data)
              },
            })
          } else if (
            Platform.OS == 'ios' &&
            this.state.desc.toLowerCase() === 'playtech'
          ) {
            Linking.openURL(res.response.gameDetails.Url)
          } else {
            this.props.navigation.navigate('LaunchGame', {
              url: res.response.gameDetails.Url,
              title: this.state.title,
              onGoBack: (data) => {
                this.refresh(data)
              },
            })
          }
        } else if (
          Platform.OS == 'ios' &&
          this.state.desc.toLowerCase() === 'playtech'
        ) {
          Linking.openURL(res.response.gameDetails.Url)
        } else {
          this.props.navigation.navigate('LaunchGame', {
            url: res.response.gameDetails.Url,
            title: this.state.title,
            onGoBack: (data) => {
              this.refresh(data)
            },
          })
        }
      })
      .catch((err) => {
        // logMessageAnalytics(AnalyticEvents.LaunchGameFailure, err?.message);
        this.setState({ isLoading: false, hideBackButton: false })
        console.log(err)
        if (err.status == 514) {
          this.props.expiredToken_logout()
        } else {
          MyToast.popToast(err?.message)
        }
      })
  }

  transfer_Mega_Game_Balance = async () => {
    this.setState({ isLoading: true, hideBackButton: true })
    await productAPICaller
      .launchGame(
        MyData.authToken,
        this.state.productID,
        this.state.amount,
        this.state.walletType
      )
      .then((res) => {
        // logMessageAnalytics(
        //   AnalyticEvents.GameDetailMegaTransferBalanceSuccess,
        //   {
        //     Amount: this.state.amount,
        //   }
        // );
        this.setState({ isLoading: false, hideBackButton: false })
        this.getAvailableCredit(
          this.state.productID,
          'false',
          this.state.walletType
        )
        MyToast.popToast(strings.t('balance_transfer'))
      })
      .catch((err) => {
        // logMessageAnalytics(
        //   AnalyticEvents.GameDetailMegaTransferBalanceFailed,
        //   err?.message
        // );
        this.setState({ isLoading: false, hideBackButton: false })
        if (err?.status == 514) {
          this.props.expiredToken_logout()
        } else {
          MyToast.popToast(err?.message)
        }
      })
  }

  refresh = async (data) => {
    this.setState({ hideBackButton: true, isGameCloseFromScreen: true })
    await this.close_game()
    await this.getAvailableCredit(
      this.state.productID,
      'true',
      this.state.walletType
    )
  }

  go_game_transaction = () => {
    // logMessageAnalytics(AnalyticEvents.GameDetailGameHistory, {
    //   Game: this.state.desc,
    // });
    this.props.navigation.navigate('GameDetailsTransactionScreen', {
      product_id: this.state.productID,
      producy_desc: this.state.desc,
    })
  }
  onChangePassword = async () => {
    this.setState({ isChangePasswordLoading: true })
    await productAPICaller
      .GameChangePasswrd(
        MyData.authToken,
        this.state.productID,
        this.state.newPassword
      )
      .then((res) => {
        // logMessageAnalytics(
        //   AnalyticEvents.GameDetailGameChangePasswordSuccess,
        //   {
        //     Game: this.state.desc,
        //     NewPassword: this.state.newPassword,
        //   }
        // );
        this.setState({ isPasswordChangeModal: false })
        MyToast.popToast(strings.t('congratulations_changed_password'))
        this.on_Refresh()
      })
      .catch((err) => {
        // logMessageAnalytics(
        //   AnalyticEvents.GameDetailGameChangePasswordFailure,
        //   err.response.data.msg
        // );
        this.setState({ isLoading: false, hideBackButton: false })
        if (err.response.status == 514) {
          this.props.expiredToken_logout()
        } else {
          MyToast.popToast(err.response.data.msg)
        }
      })
      .finally(() => {
        this.setState({ isChangePasswordLoading: false })
      })
  }
  onPullOutCredit = async () => {
    this.setState({ isPullCreditLoading: true })
    await productAPICaller
      .pullOutCreditByProduct(MyData.authToken, this.state.productID)
      .then((res) => {
        // logMessageAnalytics(AnalyticEvents.GameDetailPullOutCreditSuccess, {
        //   Product: this.state.desc,
        // });
        MyToast.popToast(res?.msg)
      })
      .catch((err) => {
        // logMessageAnalytics(
        //   AnalyticEvents.GameDetailPullOutCreditFailed,
        //   err.response.data.msg
        // );
        this.setState({ isLoading: false })
        if (err.response.status == 514) {
          this.props.expiredToken_logout()
        } else {
          MyToast.popToast(err.response.data.msg)
        }
      })
      .finally(() => {
        this.setState({ isPullCreditLoading: false })
      })
  }
  lionKingGameStart = async (isDownload) => {
    this.setState({ isLoading: true, hideBackButton: true })
    await productAPICaller
      .launchGame(
        MyData.authToken,
        this.state.productID,
        this.state.amount,
        this.state.walletType
      )
      .then((res) => {
        // logMessageAnalytics(
        //   AnalyticEvents.GameDetailDeepLinkGameLaunchSuccess,
        //   {
        //     Game: this.state.title,
        //   }
        // );
        this.on_Refresh()
        if (!isDownload) {
          Linking.openURL(`jqk://${res.response.gameDetails.Token}`)
        } else {
          this.props.navigation.navigate('LaunchGame', {
            url: res.response.gameDetails.Url,
            title: this.state.title,
            onGoBack: (data) => {
              this.refresh(data)
            },
          })
        }
        this.setState({ isLoading: false, hideBackButton: false })
      })
      .catch((err) => {
        // logMessageAnalytics(
        //   AnalyticEvents.GameDetailDeepLinkGameLaunchFailure,
        //   err?.message
        // );
        this.setState({ isLoading: false, hideBackButton: false })
        if (err.status == 514) {
          this.props.expiredToken_logout()
        } else {
          MyToast.popToast(err?.message)
        }
      })
  }
  deepLinkGamesStart = async (isDownload) => {
    this.setState({ isLoading: true, hideBackButton: true })
    await productAPICaller
      .launchGame(
        MyData.authToken,
        this.state.productID,
        this.state.amount,
        this.state.walletType
      )
      .then(async (res) => {
        // logMessageAnalytics(
        //   AnalyticEvents.GameDetailDeepLinkGameLaunchSuccess,
        //   {
        //     Game: this.state.title,
        //   }
        // );
        this.on_Refresh()
        if (!isDownload) {
          const link = res.response.gameDetails.link
          if (this.state.title === 'PUSSY') {
            this.props.navigation.navigate('LaunchGame', {
              url: link,
              title: this.state.title,
              onGoBack: (data) => {
                this.refresh(data)
              },
            })
          } else {
            Linking.openURL(
              `${Platform.OS === 'ios' ? link.ios : link.android}`
            )
          }
        } else {
          Linking.openURL(res.response.gameDetails.downloadUrl)
        }
        this.setState({ isLoading: false, hideBackButton: false })
      })
      .catch((err) => {
        // logMessageAnalytics(
        //   AnalyticEvents.GameDetailDeepLinkGameLaunchFailure,
        //   err?.message
        // );
        this.setState({ isLoading: false, hideBackButton: false })
        if (err?.status == 514) {
          this.props.expiredToken_logout()
        } else {
          MyToast.popToast(err?.message)
        }
      })
  }
  on_Refresh = async (pullCredit = 'false') => {
    await this.getAvailableCredit(
      this.state.productID,
      pullCredit,
      this.state.walletType
    )
  }
  onPress_qr_Topup = () => {
    // logMessageAnalytics(AnalyticEvents.GameDetailQRTopupClick);
    this.setState({
      show_top_up_modal: false,
    })
    this.props.navigation.navigate('QRTopup', {
      onGoBack: (data) => {
        this.on_Refresh(data)
      },
    })
  }
  renderChangePasswordModal = () => {
    return (
      <Modal
        animationType="slide"
        transparent={true}
        visible={this.state.isPasswordChangeModal}
        statusBarTranslucent
      >
        <View
          style={{
            flex: 1,
            backgroundColor: 'rgba(0,0,0,0.5)',
            justifyContent: 'center',
          }}
        >
          <View
            style={{
              backgroundColor: 'white',
              alignItems: 'center',
              marginHorizontal: HeightDimen(15),
              flex: 0.25,
              borderRadius: HeightDimen(10),
              padding: HeightDimen(20),
            }}
          >
            <Text
              style={{
                fontSize: fontDimen(25),
                marginBottom: HeightDimen(20),
                fontWeight: 'bold',
              }}
            >
              Change Password
            </Text>

            <PasswordInput
              placeholder={strings.t('password_changed')}
              marginBottom={HeightDimen(20)}
              onChangeText={(val) => this.setState({ newPassword: val })}
              // value={this.state.password}
            />

            {!this.state.isChangePasswordLoading ? (
              <View
                style={{
                  justifyContent: 'center',
                  flexDirection: 'row',
                }}
              >
                <TextButton
                  onPress={() => {
                    this.onChangePassword()
                  }}
                  label={strings.t('confirm')}
                  size={2.5}
                />
                <View
                  style={{
                    marginLeft: WidthDimen(10),
                  }}
                >
                  <TextButton
                    onPress={() =>
                      this.setState({ isPasswordChangeModal: false })
                    }
                    blackBtn={true}
                    label={strings.t('cancel')}
                    size={2.5}
                  />
                </View>
              </View>
            ) : (
              <View style={{ marginTop: HeightDimen(25) }}>
                <ActivityIndicator size="small" color={MainColor} />
              </View>
            )}
          </View>
        </View>
      </Modal>
    )
  }

  renderMaxAmountButton() {
    return this.state.maxAmountList.map((item) => (
      <TouchableOpacity
        onPress={() => {
          if (Number(this.state.availableData.avaiableTransfer) >= Number(item))
            this.setState({ amount: `${item}.00` })
          else return
        }}
        style={styles.maxButton}
      >
        <Text style={styles.maxButtonLabel}>{item}</Text>
      </TouchableOpacity>
    ))
  }
  renderViewOptionModal = () => {
    return (
      <Modal
        animationType="slide"
        transparent={true}
        visible={this.state.showViewOptionModal}
        statusBarTranslucent
        onRequestClose={() =>
          this.setState({
            showViewOptionModal: false,
          })
        }
      >
        <View
          style={{
            flex: 1,
            backgroundColor: 'rgba(0,0,0,0.5)',
            justifyContent: 'center',
          }}
        >
          <View
            style={{
              backgroundColor: 'white',
              marginHorizontal: HeightDimen(15),
              borderRadius: HeightDimen(10),
              padding: HeightDimen(20),
            }}
          >
            <View
              style={{
                height: HeightDimen(50),
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Text
                style={{
                  fontSize: fontDimen(15),
                  fontWeight: 'bold',
                }}
              >
                {strings.t('type')}
              </Text>
            </View>
            <View
              style={{
                padding: HeightDimen(20),
              }}
            >
              <TouchableOpacity
                onPress={() => this.start_game('mobile')}
                style={{
                  width: '100%',
                }}
              >
                <Text style={{ fontSize: fontDimen(16), textAlign: 'center' }}>
                  Mobile
                </Text>
              </TouchableOpacity>
              <View
                style={{
                  height: HeightDimen(1),
                  backgroundColor: '#EFF2F4',
                  marginVertical: HeightDimen(10),
                }}
              ></View>
              <TouchableOpacity
                onPress={() => this.start_game('web')}
                style={{
                  width: '100%',
                }}
              >
                <Text style={{ fontSize: fontDimen(16), textAlign: 'center' }}>
                  Web
                </Text>
              </TouchableOpacity>
            </View>
            <TextButton
              label={strings.t('cancel')}
              size={5}
              onPress={() => {
                this.setState({ showViewOptionModal: false })
              }}
            />
          </View>
        </View>
      </Modal>
    )
  }

  onChangeBonusChips = async () => {
    this.setState({
      hideBackButton: true,
    })
    let type = this.state.walletType == 'bonusChip' ? 'Cash' : 'bonusChip'
    await productAPICaller
      .changeBonusChips(MyData.authToken, type, this.state.productID)
      .then((res) => {
        // logMessageAnalytics(AnalyticEvents.BonusChipsSuccess);
        this.setState({
          hideBackButton: false,
          walletType: type,
        })
        this.getAvailableCredit(this.state.productID, 'false', type)
      })
      .catch((err) => {
        // logMessageAnalytics(
        //   AnalyticEvents.BonusChipsFailure,
        //   err.response.data.msg
        // );
        if (err.response.status == 514) {
          this.props.expiredToken_logout()
        } else {
          MyToast.popToast(err.response.data.msg)
        }
      })
  }

  onFocus = () => {
    debugger
    console.log(this.inputRef)
    // this.inputRef?.current?.setSelection(
    //   this.state.amount?.length,
    //   this.state.amount?.length
    // )
  }
  render() {
    let slowLoading = this.state.title == 'LION' || this.state.title == 'MEGA'
    if (this.state.isLoadingCredit && slowLoading) {
      return (
        <View style={styles.container}>
          {this.state.isTransactionModal && (
            <TransactionModal
              isShow={this.state.isTransactionModal}
              title={strings.t('cashout')}
              isCoinSpray={this.state.isCoinSpray}
              isCashOut={this.state.isCashout}
              onCoinSprayPress={() => {
                this.setState({
                  isCoinSpray: false,
                  isTransactionModal: false,
                  rewardSuccessModal: true,
                })
              }}
            />
          )}
          {this.state.rewardSuccessModal && (
            <RewardModal
              isShow={this.state.rewardSuccessModal}
              source={require('../../Resources/LottieIcons/gift.json')}
              amount={this.state.rewardAmount}
              buttonText={'OK'}
              onConfirmPress={() => {
                if (!this.state.isGameCloseFromScreen) {
                  this.onBackPressed()
                }
                this.setState({
                  rewardSuccessModal: false,
                  isGameCloseFromScreen: false,
                  isRewardForceClose: true,
                })
              }}
            />
          )}

          <GameDetailsHeaderContainer
            title={this.state.title}
            onBackPress={() => this.onBackPressed()}
            // toShowText={
            //   this.state.walletType == 'bonusChip'
            //     ? strings.t('cash_wallet')
            //     : strings.t('bonus_chips')
            // }
            // toShow={true}
            //toShowImage
            //toShowImageUrl={require("../../Resources/Images/transaction.png")}
            //toShowPress={() => this.go_game_transaction()}
            hideBackButton={this.state.hideBackButton}
          >
            <ScrollView
            // refreshControl={
            //   !this.state.isLoadingCredit && (
            //     <RefreshControl
            //       refreshing={false}
            //       onRefresh={this.on_Refresh}
            //     />
            //   )
            // }
            >
              <ActivityIndicator
                size="small"
                color={MainColor}
                style={{ paddingTop: HeightDimen(30) }}
              />
            </ScrollView>
          </GameDetailsHeaderContainer>
        </View>
      )
    }
    return (
      <View style={styles.container}>
        {this.state.topupList.length > 0 && (
          <TopUpModal
            isShow={this.state.show_top_up_modal}
            topupList={this.state.topupList}
            onPressInstantTopUp={() => this.onPress_InstantTopUp()}
            onPressBankTopUp={() => this.onPress_BankTopUp()}
            onPressUsdtTopUp={() => this.onPress_UsdtTopUp()}
            onBackPress={() => this.onCancel_modal()}
            onPressQRTopup={() => this.onPress_qr_Topup()}
          />
        )}
        {this.state.isTransactionModal && (
          <TransactionModal
            isShow={this.state.isTransactionModal}
            title={strings.t('cashout')}
            isCoinSpray={this.state.isCoinSpray}
            isCashOut={this.state.isCashout}
            onCoinSprayPress={() => {
              this.setState({
                isCoinSpray: false,
                isTransactionModal: false,
                rewardSuccessModal: true,
              })
            }}
          />
        )}
        <InfoTextModal
          isShow={this.state.agreementModalShow}
          text={this.state.termText}
          onCloseModal={() => {
            this.setState({
              agreementModalShow: false,
            })
          }}
        />
        {this.state.rewardSuccessModal && (
          <RewardModal
            isShow={this.state.rewardSuccessModal}
            source={require('../../Resources/LottieIcons/gift.json')}
            amount={`${MyData.country_code}(${this.state.rewardAmount})`}
            buttonText={'OK'}
            onConfirmPress={() => {
              if (!this.state.isGameCloseFromScreen) {
                this.onBackPressed()
              }
              this.setState({
                rewardSuccessModal: false,
                isGameCloseFromScreen: false,
                isRewardForceClose: true,
              })
            }}
          />
        )}
        <GameDetailsHeaderContainer
          title={this.state.title}
          onBackPress={() => this.onBackPressed()}
          // toShowText={
          //   this.state.walletType == 'bonusChip'
          //     ? strings.t('cash_wallet')
          //     : strings.t('bonus_chips')
          // }
          // toShow
          // toShowPress={() => this.onChangeBonusChips()}
          hideBackButton={this.state.hideBackButton}
        >
          <ScrollView
            contentContainerStyle={{ flexGrow: 1 }}
            directionalLockEnabled
            contentInset={{ top: -5 }}
            refreshControl={
              // !this.state.isLoadingCredit && (
              <RefreshControl
                refreshing={this.state.isLoadingCredit}
                onRefresh={this.on_Refresh}
              />
              // )
            }
            style={styles.scroll}
          >
            <View style={styles.innerContainer}>
              <View
                style={{
                  paddingTop: HeightDimen(15),
                  // width: 200,
                  alignSelf: 'center',
                  // backgroundColor: "red",
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {this.state.localImage ? (
                  <Image
                    style={styles.gameLogo}
                    source={this.state.localImage}
                  />
                ) : (
                  <Image
                    style={styles.gameLogo}
                    source={{ uri: this.state.image_url }}
                  />
                )}
                {this.state.coin_entitled == 'true' && (
                  <Image
                    source={require('../../Resources/Images/product_images/coin.png')}
                    style={{
                      position: 'absolute',
                      resizeMode: 'contain',
                      height: HeightDimen(25),
                      width: HeightDimen(25),
                      top: HeightDimen(7),
                      left: -2,
                    }}
                  />
                )}
              </View>
              {/* <Text
                    style={{
                      fontSize: fontDimen(15),
                      textAlign: "center",
                      color: FontMainColor,
                      marginTop: HeightDimen(10),
                    }}
                  >
                    {this.state.desc}
                  </Text> */}

              <View style={styles.availableCashContainer}>
                <Text style={styles.availableCashLabel}>
                  {strings.t('available_transfer')}
                </Text>
                <Text style={styles.availableCashAmountLabel}>
                  {numberWithCommas(
                    Number(this.state.availableData.avaiableTransfer).toFixed(2)
                  )}
                </Text>
              </View>
              <Line marginTop={HeightDimen(8)} />
              {this.state.availableData.coinEntitle == true ||
                (this.state.availableData.coinEntitle == 'true' && (
                  <View
                    style={{
                      flexDirection: 'row',
                      marginVertical: HeightDimen(6),
                    }}
                  >
                    <Text style={styles.availableCashLabel}>
                      {strings.t('available_coin')}
                    </Text>
                    <Text style={styles.availableCashAmountLabel}>
                      {this.state.availableData.coinAvailable}
                    </Text>
                  </View>
                ))}
              {/* {this.state.availableData.coinEntitle == true || this.state.availableData.coinEntitle == "true" && <View style={{ width: '100%', height: HeightDimen(1), backgroundColor: SecondaryColor }} />} */}
              <View
                style={{
                  flexDirection: 'row',
                  marginVertical: HeightDimen(6),
                }}
              >
                <Text
                  style={{ ...styles.availableCashLabel, ...styles.cashLabel }}
                >
                  {strings.t('total')}
                </Text>
                <Text style={styles.cashLabel}>
                  {this.state.availableData.coinEntitle == true ||
                  this.state.availableData.coinEntitle == 'true'
                    ? Number(
                        +this.state.availableData.coinAvailable +
                          +this.state.amount
                      ).toFixed(2)
                    : numberWithCommas(Number(this.state.amount).toFixed(2))}
                </Text>
              </View>
              <View style={styles.cashContainer}>
                <Text style={styles.cashLabel}>
                  {this.state.walletType == 'bonusChip'
                    ? strings.t('bonus')
                    : strings.t('cash')}{' '}
                  ({MyData.country_code})
                </Text>
                <TouchableOpacity
                  onPress={() => {
                    this.setState({
                      amount: this.state.availableData.avaiableTransfer,
                    })
                  }}
                >
                  <Text style={styles.cashMaxButton}>{strings.t('max')}</Text>
                </TouchableOpacity>
              </View>
              <View style={styles.textInputContainer}>
                <TextInput
                  ref={this.inputRef}
                  placeholder="0.00"
                  keyboardType="numeric"
                  placeholderTextColor={SecondaryColor}
                  style={styles.textInput}
                  onChangeText={this.handle_amount}
                  value={this.state.amount}
                  onFocus={this.onFocus}
                  mode
                  // selection={this.state.selection}
                />
              </View>
              <View style={styles.maxButtonContainer}>
                {this.renderMaxAmountButton()}
              </View>
              {this.state.availableData.showBonus && (
                <View style={{ marginTop: HeightDimen(15) }}>
                  <OutlineInput
                    title={strings.t('looked_amount')}
                    value={this.state?.availableData?.bonus?.lockedAmount}
                  />
                  <OutlineInput
                    marginTop={HeightDimen(15)}
                    title={strings.t('current_tunrover_stake')}
                    value={this.state?.availableData?.bonus?.stakeDisplay}
                  />
                  <Text
                    style={{
                      ...styles.cashLabel,
                      color: '#e7999f',

                      alignSelf: 'flex-end',
                      marginTop: HeightDimen(5),
                    }}
                  >
                    {strings.t('turnover_stake_remarks')}
                  </Text>
                </View>
              )}
              <View
                style={{
                  flexDirection: 'row',
                  marginTop: HeightDimen(20),
                }}
              >
                <Text style={styles.availableCashLabel}>
                  {this.state.availableData.ptsCopyWriting}
                </Text>
                <View
                  style={{
                    flex: 1,
                    flexDirection: 'row',
                  }}
                >
                  <Text style={styles.cashLabel}>
                    {strings.t('commission') + ': '}
                  </Text>
                  <Text style={styles.availableCashAmountLabel}>
                    {this.state.availableData.selfCommission}
                  </Text>
                </View>
              </View>
              <Line marginTop={HeightDimen(8)} />
              <View
                style={{
                  flexDirection: 'row',
                  paddingTop: HeightDimen(10),
                  paddingBottom: HeightDimen(5),
                }}
              >
                <View
                  style={{
                    flexDirection: 'row',
                    flex: 1,
                  }}
                >
                  <Text style={styles.cashLabel}>
                    {strings.t('today_stake') + ': '}
                    <Text style={styles.availableCashAmountLabel}>
                      {numberWithCommas(
                        Number(this.state.availableData.todayStake).toFixed(2)
                      )}
                    </Text>
                  </Text>
                </View>
                <View
                  style={{
                    flexDirection: 'row',
                    flex: 1,
                  }}
                >
                  <Text style={styles.cashLabel}>
                    {strings.t('yesterday_stake') + ': '}
                    <Text style={styles.availableCashAmountLabel}>
                      {numberWithCommas(
                        Number(this.state.availableData.yesterdayStake).toFixed(
                          2
                        )
                      )}
                    </Text>
                  </Text>
                </View>
              </View>
              <Line marginTop={HeightDimen(8)} />

              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  marginVertical: HeightDimen(15),
                }}
              >
                <IconTextCard
                  src={require('../../Resources/Images/Profile/icon_topup.png')}
                  name={strings.t('top_up')}
                  onPress={() => this.checkTopUp()}
                  marginRight={HeightDimen(10)}
                />
                <IconTextCard
                  src={require('../../Resources/Images/Profile/icon_history.png')}
                  name={strings.t('game_transaction')}
                  onPress={() => this.go_game_transaction()}
                />
              </View>
              {this.state.title === 'MEGA' && (
                <View style={{ marginTop: 10 }}>
                  <DisplayInput
                    title={strings.t('username_text')}
                    value={this.state.availableData.username}
                  />
                  <DisplayInput
                    isChangePassword
                    title={strings.t('new_password')}
                    value={this.state.availableData.password}
                    onPress={() =>
                      this.setState({ isPasswordChangeModal: true })
                    }
                  />
                </View>
              )}
              {Platform.OS === 'ios' && this.state.title === '918KISS' && (
                <View style={{ marginTop: 10 }}>
                  <DisplayInput
                    title={strings.t('username_text')}
                    value={this.state.availableData.username}
                  />
                  <DisplayInput
                    title={strings.t('new_password')}
                    value={this.state.availableData.password}
                  />
                </View>
              )}
              {this.state.termStatus && !this.state.isInGame && (
                <View style={styles.checkAgreementContainer}>
                  <TouchableOpacity
                    onPress={() => this.setState({ agreementModalShow: true })}
                  >
                    <Text style={styles.agreementLabel}>
                      {strings.t('user_agreement')}
                    </Text>
                  </TouchableOpacity>

                  <AgreeCheckBox
                    value={this.state.isShowButton}
                    onCheck={this.checkAgree}
                    label={strings.t('i_have_read_and_agree')}
                  />
                </View>
              )}

              {this.state.isInGame && (
                <View style={styles.downloadButton}>
                  <TextButton
                    label={strings.t('close_game')}
                    size={5}
                    onPress={() => {
                      // logMessageAnalytics(
                      //   AnalyticEvents.GameDetailCloseGameClick
                      // );
                      this.close_game_and_back()
                    }}
                    isLoading={this.state.isLoading}
                  />
                </View>
              )}
              {this.state.title === 'LION' && !this.state.isInGame && (
                <View style={styles.downloadButton}>
                  <TextButton
                    label={
                      this.state.isValidDeepLink
                        ? strings.t('game_details_launch')
                        : strings.t('game_details_download')
                    }
                    isLoading={this.state.isLoading}
                    size={5}
                    onPress={() => {
                      // logEventWithGameName(
                      //   AnalyticEvents.GameDetailLaunchGame,
                      //   this.state.title
                      // );
                      Linking.canOpenURL('jqk://').then((supported) => {
                        if (supported) {
                          this.lionKingGameStart(false)
                        } else {
                          this.lionKingGameStart(true)
                        }
                      })
                    }}
                  />
                </View>
              )}

              {(this.state.title === 'EVO888' ||
                this.state.title === 'PUSSY') &&
                !this.state.isInGame && (
                  <View style={styles.downloadButton}>
                    <TextButton
                      label={
                        this.state.isValidDeepLink
                          ? strings.t('game_details_launch')
                          : strings.t('game_details_download')
                      }
                      isLoading={this.state.isLoading}
                      size={5}
                      onPress={async () => {
                        // logEventWithGameName(
                        //   AnalyticEvents.GameDetailLaunchGame,
                        //   this.state.title
                        // );
                        if (this.state.isValidDeepLink) {
                          this.deepLinkGamesStart(false)
                        } else {
                          this.deepLinkGamesStart(true)
                        }
                      }}
                    />
                  </View>
                )}
              {this.state.title === 'MGC' && !this.state.isInGame && (
                <View
                  style={{
                    flex: 1,
                  }}
                >
                  {!this.state.isLoading ? (
                    <View
                      style={{
                        flex: 1,
                        alignItems: 'flex-end',
                        justifyContent: 'center',
                        flexDirection: 'row',
                      }}
                    >
                      <TextButton
                        label={
                          this.state.isValidDeepLink
                            ? strings.t('game_details_launch')
                            : strings.t('game_details_download')
                        }
                        isLoading={this.state.isLoading}
                        size={Platform.OS === 'ios' ? 2.5 : 5}
                        onPress={() => {
                          // logEventWithGameName(
                          //   AnalyticEvents.GameDetailLaunchGame,
                          //   this.state.title
                          // );
                          if (this.state.isValidDeepLink) {
                            this.deepLinkGamesStart(false)
                          } else {
                            this.deepLinkGamesStart(true)
                          }
                        }}
                      />
                      {Platform.OS === 'ios' && (
                        <View style={{ marginLeft: HeightDimen(10) }}>
                          <TextButton
                            label={'Launch'}
                            isLoading={this.state.isLoading}
                            size={2.5}
                            onPress={() => {
                              // logEventWithGameName(
                              //   AnalyticEvents.GameDetailLaunchGame,
                              //   this.state.title
                              // );
                              this.deepLinkGamesStart(false)
                            }}
                          />
                        </View>
                      )}
                    </View>
                  ) : (
                    <View
                      style={{
                        flex: 1,
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <ActivityIndicator size="small" color={MainColor} />
                    </View>
                  )}
                </View>
              )}
              {this.state.title === '918KISS' && !this.state.isInGame && (
                <View
                  style={{
                    flex: 1,
                  }}
                >
                  {!this.state.isLoading ? (
                    <View
                      style={{
                        flex: 1,
                        alignItems: 'flex-end',
                        justifyContent: 'center',
                        flexDirection: 'row',
                      }}
                    >
                      <TextButton
                        label={
                          this.state.isValidDeepLink ? 'Launch' : 'Download'
                        }
                        isLoading={this.state.isLoading}
                        size={Platform.OS === 'ios' ? 2.5 : 5}
                        onPress={() => {
                          // logEventWithGameName(
                          //   AnalyticEvents.GameDetailLaunchGame,
                          //   this.state.title
                          // );
                          if (this.state.isValidDeepLink) {
                            this.deepLinkGamesStart(false)
                          } else {
                            this.deepLinkGamesStart(true)
                          }
                        }}
                      />
                      {Platform.OS === 'ios' && (
                        <View style={{ marginLeft: HeightDimen(10) }}>
                          <TextButton
                            label={'Launch'}
                            isLoading={this.state.isLoading}
                            size={2.5}
                            onPress={() => {
                              // logEventWithGameName(
                              //   AnalyticEvents.GameDetailLaunchGame,
                              //   this.state.title
                              // );
                              this.deepLinkGamesStart(false)
                            }}
                          />
                        </View>
                      )}
                    </View>
                  ) : (
                    <View
                      style={{
                        flex: 1,
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <ActivityIndicator size="small" color={MainColor} />
                    </View>
                  )}
                </View>
              )}
              {/* {this.state.title === "918KISS" && !this.state.isInGame && (
                <View
                  style={{
                    flex: 1,
                  }}
                >
                  {!this.state.isLoading ? (
                    <View
                      style={{
                        flex: 1,
                        alignItems: "flex-end",
                        justifyContent: "center",
                        flexDirection: "row",
                      }}
                    >
                      <TextButton
                        label={
                          this.state.isValidDeepLink
                            ? strings.game_details_launch
                            : strings.game_details_download
                        }
                        isLoading={this.state.isLoading}
                        size={Platform.OS === "ios" ? 2.5 : 5}
                        onPress={() => {
                          logEventWithGameName(
                            AnalyticEvents.GameDetailLaunchGame,
                            this.state.title
                          );
                          if (this.state.isValidDeepLink) {
                            this.deepLinkGamesStart(false);
                          } else {
                            this.deepLinkGamesStart(true);
                          }
                        }}
                      />
                      {Platform.OS === "ios" && (
                        <View style={{ marginLeft: HeightDimen(10) }}>
                          <TextButton
                            label={strings.transfer}
                            isLoading={this.state.isLoading}
                            size={2.5}
                            onPress={() => this.transfer_Mega_Game_Balance()}
                          />
                        </View>
                      )}
                    </View>
                  ) : (
                    <View
                      style={{
                        flex: 1,
                        alignItems: "center",
                        justifyContent: "flex-end",
                      }}
                    >
                      <ActivityIndicator size="small" color={MainColor} />
                    </View>
                  )}
                </View>
              )} */}
              {this.state.title === 'MEGA' && !this.state.isInGame && (
                <View
                  style={{
                    flex: 1,
                    marginTop: HeightDimen(10),
                  }}
                >
                  {!this.state.isLoading ? (
                    <View
                      style={{
                        flex: 1,
                        alignItems: 'flex-end',
                        justifyContent: 'center',
                        flexDirection: 'row',
                      }}
                    >
                      <TextButton
                        label={strings.t('game_details_download')}
                        isLoading={this.state.isLoading}
                        size={2.5}
                        onPress={() => {
                          // logEventWithGameName(
                          //   AnalyticEvents.GameDetailLaunchGame,
                          //   this.state.title
                          // );
                          Linking.canOpenURL(this.state.megaUrl).then(
                            (supported) => {
                              if (supported) {
                                return Linking.openURL(this.state.megaUrl)
                              } else {
                                MyToast.popToast('deeplink not valid')
                              }
                            }
                          )
                        }}
                      />
                      <View style={{ marginLeft: HeightDimen(10) }}>
                        <TextButton
                          label={strings.t('transfer')}
                          isLoading={this.state.isLoading}
                          size={2.5}
                          onPress={() => this.transfer_Mega_Game_Balance()}
                        />
                      </View>
                    </View>
                  ) : (
                    <View
                      style={{
                        flex: 1,
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <ActivityIndicator size="small" color={MainColor} />
                    </View>
                  )}
                </View>
              )}
              {this.state.title !== 'LION' &&
                this.state.title !== 'MEGA' &&
                this.state.title !== 'PUSSY' &&
                this.state.title !== 'EVO888' &&
                this.state.title !== '918KISS' &&
                this.state.title !== 'MGC' &&
                !this.state.isInGame &&
                this.state.isShowButton && (
                  <View style={styles.downloadButton}>
                    <TextButton
                      label={strings.t('start')}
                      size={5}
                      onPress={() => {
                        // logEventWithGameName(
                        //   AnalyticEvents.GameDetailLaunchGame,
                        //   this.state.title
                        // );
                        if (this.state.hasViewOptions) {
                          this.setState({ showViewOptionModal: true })
                        } else {
                          this.onStartPressed()
                        }
                      }}
                      isLoading={this.state.isLoading}
                    />
                  </View>
                )}
              {!this.state.isInGame && (
                <View style={styles.downloadButton}>
                  <TextButton
                    label={strings.t('pull_out_credit')}
                    size={5}
                    blackBtn
                    onPress={() => {
                      // logEventWithGameName(
                      //   AnalyticEvents.GameDetailPullOutCreditClick,
                      //   this.state.title
                      // );
                      this.onPullOutCredit()
                    }}
                    isLoading={this.state.isPullCreditLoading}
                  />
                </View>
              )}
            </View>

            {/* )} */}
          </ScrollView>
          {/* {this.state.isLoadingCredit && (
            <View
              style={{
                height: "100%",
                width: " 100%",
                position: "absolute",
                backgroundColor: SecondaryColor + "20",
              }}>
              <ActivityIndicator
                style={{
                  position: "absolute",
                  alignSelf: "center",
                  top: HeightDimen(30),
                }}
                size="small"
                color={MainColor}
              />
            </View>
          )} */}
        </GameDetailsHeaderContainer>
        {this.renderChangePasswordModal()}
        {this.renderViewOptionModal()}
      </View>
    )
  }
  // }
}
const styles = {
  container: {
    flex: 1,
    backgroundColor: 'white',
  },
  innerContainer: {
    flex: 1,
    paddingBottom: HeightDimen(30),
  },
  scroll: {
    paddingHorizontal: HeightDimen(20),
    // paddingRight: HeightDimen(20),
    paddingTop: HeightDimen(20),
  },
  gameLogo: {
    width: HeightDimen(180),
    height: HeightDimen(140),
    resizeMode: 'contain',
    //borderRadius: HeightDimen(10),
    alignSelf: 'center',
  },
  textInputContainer: {
    flexDirection: 'row',
    flex: 1,
    marginTop: HeightDimen(10),
    alignItems: 'center',
    justifyContent: 'center',
  },
  textInput: {
    fontSize: fontDimen(30),
    color: SecondaryColor,
    borderBottomColor: SecondaryColor,
    borderBottomWidth: HeightDimen(2),
    textAlign: 'center',
  },
  maxButtonContainer: {
    flexDirection: 'row',
    marginTop: HeightDimen(20),
    alignItems: 'center',
    justifyContent: 'center',
  },
  maxButton: {
    backgroundColor: SecondaryColor,
    padding: HeightDimen(5),
    borderRadius: HeightDimen(5),
    marginRight: HeightDimen(5),
  },
  maxButtonLabel: {
    color: 'white',
    fontSize: fontDimen(12),
    textAlign: 'center',
  },
  cashContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: HeightDimen(6),
  },
  cashLabel: {
    fontSize: fontDimen(12),
    fontWeight: 'bold',
  },
  cashMaxButton: {
    color: SecondaryColor,
    fontSize: fontDimen(16),
    fontWeight: 'bold',
  },
  availableCashContainer: {
    flexDirection: 'row',
    marginTop: HeightDimen(30),
  },
  availableCashLabel: {
    fontSize: fontDimen(12),
    flex: 1,
    fontWeight: 'bold',
  },
  availableCashAmountLabel: {
    fontSize: fontDimen(12),
  },
  checkAgreementContainer: {
    marginTop: HeightDimen(20),
  },
  agreementLabel: {
    fontSize: fontDimen(16),
    marginBottom: HeightDimen(10),
    color: MainColor,
    textDecorationLine: 'underline',
    textAlign: 'center',
  },
  downloadButton: {
    flex: 1,
    justifyContent: 'flex-end',
    marginTop: HeightDimen(10),
  },
}
export default GameDetailsScreen
