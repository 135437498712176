import React, { Component } from 'react'
import {
  View,
  FlatList,
  TouchableOpacity,
  ActivityIndicator,
} from 'react-native'
import leaderBoardCaller from '../../API/LeaderBoardCaller'
import { MainFlowHeaderContainerNoFeedback } from '../../Component'
import { AnouncementCard } from '../../Component/Card/AnouncementCard'
import { MainLeaderBoardCard } from '../../Component/Card/LeaderBoard/MainLeaderBoardCard'
import MyToast from '../../Component/Toast/MyToast'
import MyData from '../../Data/MyData'
import { strings } from '../../Language/Language'
import { MainColor } from '../../UI/Color'
import { HeightDimen } from '../../UI/Dimensions'

class LeaderScreen extends Component {
  state = {
    leaderBoardArr: [],
    isLoading: true,
  }

  async componentDidMount() {
    this.get_Leader_Board()
  }

  get_Leader_Board = async () => {
    await leaderBoardCaller
      .getLeaderBoard(MyData.authToken)
      .then((res) => {
        this.setState({
          leaderBoardArr: res.response.leaderboards,
          isLoading: false,
        })
      })
      .catch((err) => {
        //console.log(err);
        if (err.response.status == 514) {
          this.props.expiredToken_logout()
        } else {
          MyToast.popToast(err.response.data.msg)
        }
      })
  }
  on_Refresh = () => {
    this.setState({ isLoading: true })
    this.get_Leader_Board()
  }
  onPressLeaderBoard = (index) => {
    this.props.navigation.navigate('LeaderBoard', {
      value: this.state.leaderBoardArr[index].leaderboard_id,
    })
  }

  renderList = () => {
    return this.state.isLoading ? (
      <View>
        <ActivityIndicator size="small" color={MainColor} />
      </View>
    ) : (
      <FlatList
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
        data={this.state.leaderBoardArr}
        onRefresh={() => this.on_Refresh()}
        refreshing={this.state.isLoading}
        style={{
          marginHorizontal: HeightDimen(20),
        }}
        renderItem={({ item, index }) => (
          // <MainLeaderBoardCard
          //   onPress={() => this.onPressLeaderBoard(index)}
          //   label={item.leaderboard_name}
          //   image={item.leaderboard_banner_url}
          // />

          <AnouncementCard
            onPress={() => {
              this.onPressLeaderBoard(index)
            }}
            label={item.leaderboard_name}
            thumbnail={item.leaderboard_banner_url}
          />
        )}
        keyExtractor={(item, index) => String(index)}
      />
    )
  }

  onBackPressed = () => {
    this.props.navigation.goBack()
  }

  render() {
    return (
      <View
        style={{
          flex: 1,
        }}
      >
        {this.props.noHeader ? (
          this.renderList()
        ) : (
          <MainFlowHeaderContainerNoFeedback
            title={strings.t('leaderboard')}
            onBackPress={() => this.onBackPressed()}
          >
            <View style={{ paddingTop: HeightDimen(20) }}>
              {this.renderList()}
            </View>
          </MainFlowHeaderContainerNoFeedback>
        )}
      </View>
    )
  }
}

export default LeaderScreen
