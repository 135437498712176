import React, { Component, useState, useEffect } from 'react'
import {
  View,
  Modal,
  Image,
  TouchableOpacity,
  Animated,
  Easing,
} from 'react-native'
import Text from '../Text/MyText'
import { HeightDimen, fontDimen } from '../../UI/Dimensions'
import { MainColor, SecondaryColor } from '../../UI/Color'
import LottieView from 'lottie-react-native'
const TransactionModal = (props) => {
  const { isShow, title, isCoinSpray, isCashOut, onCoinSprayPress } = props
  const [progress, setProgress] = useState(new Animated.Value(0))
  useEffect(() => {
    Animated.timing(progress, {
      toValue: 1,
      duration: 5000,
      easing: Easing.linear,
      useNativeDriver: true,
    }).start()
  }, [])
  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={isShow}
      statusBarTranslucent
    >
      {isCoinSpray ? (
        <View
          style={{
            flex: 1,
            backgroundColor: 'rgba(0,0,0,0.5)',
            justifyContent: 'center',
          }}
        >
          <TouchableOpacity
            onPress={onCoinSprayPress}
            style={{
              flex: 0.4,
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: HeightDimen(15),
              marginHorizontal: HeightDimen(40),

              overflow: 'hidden',
            }}
          >
            <Image
              style={{
                flex: 1,
              }}
              source={require('../../Resources/Images/gif/coin-spray.gif')}
            />
          </TouchableOpacity>
        </View>
      ) : isCashOut ? (
        <View
          style={{
            flex: 1,
            backgroundColor: MainColor,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <LottieView
            source={require('../../Resources/LottieIcons/rocket.json')}
            progress={progress}
            autoPlay
            style={{ height: HeightDimen(100), width: 50, alignSelf: 'center' }}
          />
          <Text
            style={{
              fontSize: fontDimen(30),
              color: 'white',
              textAlign: 'center',
              fontWeight: 'bold',
              marginTop: HeightDimen(20),
            }}
          >
            {title}
          </Text>
        </View>
      ) : (
        <View />
      )}
    </Modal>
  )
}

export { TransactionModal }
