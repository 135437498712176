import React, { Component } from "react";
import {
  View,
  TouchableOpacity,
  Modal,
  Image,
  StatusBar,
  Platform,
} from "react-native";

import { WidthDimen, HeightDimen } from "../../UI/Dimensions";

import { ImageButton } from "../../Component/Button/ImageButton";
import { strings } from "../../Language/Language";

const TopUpModal = (props) => {
  const {
    isShow,
    onPressInstantTopUp,
    onPressBankTopUp,
    onBackPress,
    onPressUsdtTopUp,
    topupList,
  } = props;
  const topupButtonDisable = (name) => {
    const data = topupList.find((item) => {
      return item.code === name;
    });
    return data.value;
  };
  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={isShow}
      statusBarTranslucent
    >
      <StatusBar translucent backgroundColor="transparent" />
      <View
        style={{
          flex: 1,
          backgroundColor: "rgba(0,0,0,0.5)",
        }}
      >
        <View
          style={{
            height: HeightDimen(60),
            justifyContent: "center",
            marginTop:
              Platform.OS === "ios" ? HeightDimen(40) : HeightDimen(30),
          }}
        >
          <View>
            <TouchableOpacity
              style={{
                paddingLeft: WidthDimen(20),
              }}
              onPress={onBackPress}
            >
              <Image
                source={require("../../Resources/Images/icon_back.png")}
                style={{
                  width: "auto",
                  height: HeightDimen(15),
                  aspectRatio: 19 / 13,
                  resizeMode: "contain",
                }}
              />
            </TouchableOpacity>
          </View>
        </View>

        <View
          style={{
            width: "100%",
            marginBottom: HeightDimen(80),
            flex: 1,
            justifyContent: "flex-end",
            paddingLeft: HeightDimen(30),
            paddingRight: HeightDimen(30),
          }}
        >
          <ImageButton
            label={strings.instant_top_up}
            onPress={onPressInstantTopUp}
            disable={
              topupButtonDisable("topup_instant") === "active" ? false : true
            }
            src={require("../../Resources/Images/Wallet/icon_instant.png")}
          />
          <View style={{ height: HeightDimen(15) }}></View>
          <ImageButton
            label={strings.top_up_bank_transfer}
            onPress={onPressBankTopUp}
            disable={
              topupButtonDisable("topup_manual") === "active" ? false : true
            }
            src={require("../../Resources/Images/Wallet/icon_bank.png")}
          />
          <View style={{ height: HeightDimen(15) }}></View>
          <ImageButton
            label={strings.usdt_top_up}
            onPress={onPressUsdtTopUp}
            disable={
              topupButtonDisable("topup_usdt") === "active" ? false : true
            }
            src={require("../../Resources/Images/tether-usdt.png")}
          />
        </View>
      </View>
    </Modal>
  );
};

export { TopUpModal };
