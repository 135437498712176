import React, { useState, useRef, useEffect } from 'react'
import { Text, TouchableOpacity } from 'react-native'
import { MainColor, SecondaryColor } from '../../UI/Color'
import { fontDimen, HeightDimen } from '../../UI/Dimensions'

function CounterButton(props) {
  const [count, setCount] = useState(0)
  //useRef gives us an object to store things between re-renders
  let interval

  useEffect(() => {
    debugger
    clearInterval(interval)
    setCount(0)
    return () => {
      clearInterval(interval)
    }
  }, [props.value]) // Pass in empty array to run effect only once!
  const counterStart = () => {
    interval = setInterval(() => {
      setCount((prev) => {
        if (prev === 1) clearInterval(interval)
        return prev - 1
      })
    }, 1000)
  }
  return (
    <TouchableOpacity
      style={{
        backgroundColor: SecondaryColor,
        borderRadius: 5,
        paddingHorizontal: 6,
        justifyContent: 'center',
        height: HeightDimen(30),
        alignItems: 'center',
      }}
      disabled={count === 0 ? false : true}
      onPress={() => {
        if (!props.isValidation) {
          setCount(120)
          counterStart()
        }
        props.onPress()
      }}
    >
      <Text style={{ color: MainColor, fontSize: fontDimen(11) }}>
        {count === 0 ? 'Get OTP' : count}
      </Text>
    </TouchableOpacity>
  )
}
export { CounterButton }
