export const screenNames = {
  Splash: "Splash",
  Login: "Login",
  SignUp: "SignUp",
  Maintenanace: "Maintenance",
  Dashboard: "Dashboard",
  GameDetail: "Game Detail",
  LaunchGame: "Launch Game",
  Friend: "Friend",
  QrCode: "QR Code",
  Transaction: "Transaction",
  History: "History",
  Profile: "Profile",
  Settings: "Settings",
  FunhouseGameDetail: "Funhouse Game Detail",
  Xe88GameDetail: "XE88 Game Detail",
  VpowerGameDetail: "Vpower Game Detail",
  PragmaticGameDetail: "Pragmatic Game Detail",
  pluss100GameDetail: "100 Plus Game Detail",
  PlaytechGameDetail: "PlayTech Game Detail",
  JokerGameDetail: "Joker Game Detail",
  PGSGameDetail: "PGS Game Detail",
  Leatherboard: "Leatherboard",
  Anouncement: "Anouncement",
  WalletTransaction: "Wallet Transaction",
  GameTransaction: "Game Transaction",
  WalletDetailTransaction: "Wallet Detail Transaction",
  WalletAdditionalDetailTransaction: "Wallet Additional Detail Transaction",
  GameDetailTransaction: "Game Detail Transaction",
  QRModal: "QR",
  Avatar: "Avatar",
  Addbank: "Add bank Account",
  Editbank: "Edit bank Account",
  InstantTop: "Instant Topup",
  QRTopup: "QR Topup",
  BankTopup: "Bank Topup",
  QRTransferBalance: "QR Transfer Balance",
  ManualTransferBalance: "Manual Transfer Balance",
  TransferQRScan: "Transfer QR Scan",
  TopupUSDT: "Topup USDT",
  TopupUSDTReciept: "Topup USDT Receipt",
  Withdraw: "Withdraw",
  TopupWithdrawTransaction: "TopUp Transaction",
  WithdrawTransactionDetail: "Withdraw Transaction Detail",
  TopupTransactionDetail: "Topup Transaction Detail",
  ChangeLoginPassword: "Change Login Password",
  ChangeNickname: "Change Nickname",
  ChangePhoneNo: "Change Phone no",
  ChangeTransactionPin: "Change Transaction Pin",
  MultiAccountLogin: "Multi Account Login",
  Language: "Language",
  Interest: "Interest",
  CreateInterest: "Create Interest",
  InterestDetail: "Create Detail",
  InterestHistory: "Interest History",
  PartialWithdraw: "Partial Withdraw",
  QRSignUp: "QR SignUp",
  SignUpBarcodeScan: "SignUp Barcode Scan",
  Verification: "Login Verification",
  ContactUS: "Contact US",
  AccountChangePassword: "Account Change Password",
  ForgotUIDPassword: "Forgot UID Password",
  OTP: "OTP",
  RecoverPassword: "Recover Password",
  RecoverUsername: "Recover Username",
  RecoverAccountUsers: "Recover Account Users",
  RecoverPasswordSuccess: "Recover Password Success ",
  ChangePinOTP: "Change OTP",
  CreatePin: "Create PIN",
  RecoverPin: "Recover PIN",
  CreateSecondPin: "Create Second PIN",
  ChangePINSucess: "Change PIN Success",
  Notification: "Notification",
  CountrySignUp: "Country SignUp",
  KingKingGameDetail: "KingKing Game Detail",
  JDBFishGameDetail: "JDB Fish Game Detail",
  SpadeFishGameDetail: "Spade Fish Game Detail",
  YLFishGameDetail: "JDB Fish Game Detail",
  YLFishGameDetail: "Spade Fish Game Detail",
  NextSpinGameDetail: "Next Spin Game Detail",
  CrowdPlayGameDetail: "Crowd Play Game Detail",
  AESlotGameDetail: "AE Slot Game Detail",
  Chat: "Chat",
  CustomerService: "Customer Service",
  CouponListScreen: "Coupon List Screen",
  CouponListScreen: "Coupon List Screen",
  CouponEgamesDetails: "Coupon EGames Details",
  CouponVIPDetails: "Coupon Vip Details",
  AESexyGameDetail: "AE Sexy Game Detail",
  LKYSlotGameDetail: "LKYSlot Game Detail",
  LKYPoints: "LKY Points",
  Gift: "Gift",
  bankAccount: "Bank Account",
  requestOtpVerification: "Request Verification OTP",
  TransactionRequestOtp: "Transaction Request OTP",
  WithdrawUSDT: "Withdraw USDT",
  WithdrawUSDTReciept: "Withdraw USDT Receipt",
  GoogleVerificationCode: "Google Verification Code",
  GoogleAuthVerification: "Google Auth Verification",
  ChangeAppIcon: "Change App Icon",
  RealNameVerification: "Real Name Verification",
};
