import React, { Component } from 'react'
import {
  View,
  ActivityIndicator,
  Keyboard,
  TouchableOpacity,
  Modal,
  FlatList,
  Image,
  Platform,
  ScrollView,
} from 'react-native'
import Text from '../../Component/Text/MyText'
import { MainColor, FontMainColor, FontSecondaryColor } from '../../UI/Color'
import { HeightDimen, fontDimen, WidthDimen } from '../../UI/Dimensions'
import { MainFlowHeaderContainer } from '../../Component/Header/MainFlowHeader'
import { RecoverTextInput } from '../../Component/Input/RecoverTextInput'
import { TextButton } from '../../Component/Button/TextButton'
import MyToast from '../../Component/Toast/MyToast'
import walletAPICaller from '../../API/WalletAPICaller'
import MyData from '../../Data/MyData'
import { strings } from '../../Language/Language'
import { EnterTransactionPinModal } from '../../Component/Modal/EnterTransactionPinModal'
import { AgreeCheckBox } from '../../Component/CheckBox/AgreeCheckBox'
import { WithdrawSuccModal } from '../../Component/Modal/WithdrawSuccModal'
import BankAPICaller from '../../API/BankAPICaller'

import { BankCard } from '../../Component/Card/BankCard'
import { NormalTextInput } from '../../Component/Input/NormalTextInput'

import { EmptyRecordCard, NumberBulletsText } from '../../Component'
import { normalizeDecimalValue, numberWithCommas } from '../../Utils/Common'

class WithdrawScreen extends Component {
  state = {
    amount: '0.00',
    isLoading: false,
    showPinModal: false,
    isCheck: false,
    showSuccModal: false,
    isGetBankListLoading: true,
    bankList: [],
    showErrorText: false,
    selectedAccount: strings.t('account_name'),
    bankAccountList: [],
    withdrawAmount: '',
    withdrawBankModal: false,
    selectedBankAccountId: '',
    bankName: '',
    bankType: '',
    isPreviewWithdrawUsdt: false,
    previewUsdt: {},
  }
  constructor(props) {
    super(props)

    this.inputRef = React.createRef()
  }
  async componentDidMount() {
    await this.getBankList()
    await this.getBankAccounts()
  }

  getBankList = async () => {
    this.setState({
      isLoading: true,
    })
    await BankAPICaller.getAvailableBank(MyData.authToken)
      .then(async (res) => {
        var temp = []
        res.response.bankList.map((d) => temp.push(d))

        this.setState({
          //isGetBankListLoading: false,
          bankList: temp,
        })
        this.getAvailableWithdraw()
      })
      .catch((err) => {
        if (err.response.status == '514') {
          this.props.expiredToken_logout()
        } else {
          MyToast.popToast(err.response.data.msg)
        }
        //console.log(err);
      })
  }
  getBankAccounts = async () => {
    this.setState({
      isLoading: true,
    })
    await BankAPICaller.getBankAccounts(MyData.authToken)
      .then((res) => {
        this.setState({
          bankAccountList: res.response.accounts,
        })
      })
      .catch((err) => {
        if (err.response.status == '514') {
          this.props.expiredToken_logout()
        } else {
          MyToast.popToast(err.response.data.msg)
        }
      })
      .finally(() => {
        this.setState({
          isLoading: false,
        })
      })
  }

  onPress_forgot_pin = () => {
    this.setState({ showPinModal: false })
    this.props.navigation.navigate('RecoverPin')
  }

  onSuccEnterPin = () => {
    this.setState({ showPinModal: false })
    if (this.state.bankType == 'crypto') {
      if (MyData.country_code == 'THB') {
        if (this.state.amount >= 100) {
          this.props.navigation.navigate('TransactionOTP', {
            isPreviewWithdraw: true,
            bankList: this.state.bankList,
            transferAmount: this.state.amount,
            selectedBankAccountId: this.state.selectedBankAccountId,
            onGoBack: () => {
              this.props.navigation.goBack()
              this.props.route.params.onGoBack()
            },
          })
        } else {
          MyToast.popToast('Minimum withdraw amount is 100')
          return
        }
      } else {
        this.previewWithdrawUsdt()
      }
    } else {
      this.on_validate_withraw()
    }
  }

  onBackModal = () => {
    this.setState({ showPinModal: false })
    this.setState({ showPickModal: false })
  }

  onBackPressed = () => {
    this.props.route.params.onGoBack()
    this.props.navigation.goBack()
  }

  handle_amount = (val) => {
    let value = normalizeDecimalValue(val)
    debugger
    if (
      Number(value) <= Number(this.state.withdrawAmount.availableWithdrawAmount)
    ) {
      // this.inputRef.current.setSelection(value?.length, value?.length)
      this.setState({
        amount: value,
      })
    } else {
      // this.inputRef.current.setSelection(
      //   this.state.withdrawAmount.availableWithdrawAmount?.length,
      //   this.state.withdrawAmount.availableWithdrawAmount?.length
      // )
      this.setState({
        amount: this.state.withdrawAmount.availableWithdrawAmount,
      })
    }
  }

  onNextPressed = async () => {
    if (this.state.amount != '' && this.state.selectedAccount) {
      Keyboard.dismiss()
      this.setState({ showPinModal: true })
    } else {
      MyToast.popToast(strings.t('invalid_data_input'))
    }
  }

  checkAgree = (val) => {
    this.setState({ isCheck: val })
  }

  getAvailableWithdraw = async () => {
    await walletAPICaller
      .getAvailableWithdraw(MyData.authToken)
      .then((res) => {
        this.setState({
          isGetBankListLoading: false,
          withdrawAmount: res.response,
        })
      })
      .catch((err) => {
        if (err.response.status == '514') {
          this.props.expiredToken_logout()
        } else if (err.response.status == '303') {
          this.setState({
            isGetBankListLoading: false,
            showErrorText: true,
          })
        }
      })
  }

  previewWithdrawUsdt = async () => {
    this.setState({ isLoading: true })
    await walletAPICaller
      .previewWithdrawUsdt(
        MyData.authToken,
        this.state.amount,
        this.state.selectedBankAccountId
      )
      .then((res) => {
        this.setState({
          isLoading: false,
          isPreviewWithdrawUsdt: true,
          previewUsdt: res.data.response,
        })
      })
      .catch((err) => {
        if (err.response.status == '514') {
          this.props.expiredToken_logout()
        } else {
          MyToast.popToast(err.response.data.msg)
        }
        this.setState({
          isLoading: false,
        })
      })
  }

  on_withdraw = async () => {
    this.setState({ isLoading: true, isPreviewWithdrawUsdt: false })

    await walletAPICaller
      .withdrawValue(
        MyData.authToken,
        this.state.amount,
        this.state.selectedBankAccountId
      )
      .then((res) => {
        this.setState({ isLoading: false })

        this.props.navigation.navigate('WithdrawHistoryDetailsScreen', {
          bankList: this.state.bankList,
          selectedTopUpDetails: res?.response?.info,
          onGoBack: () => {
            this.props.navigation.goBack()
            this.props.route.params.onGoBack()
          },
        })
      })
      .catch((err) => {
        this.setState({ isLoading: false })
        if (err?.status == 514) {
          this.props.expiredToken_logout()
        } else {
          MyToast.popToast(err?.message)
        }
      })
  }

  onRefresh = async () => {
    await this.getBankList()
    await this.getBankAccounts()
  }

  withdrawBankAccountModal() {
    return (
      <Modal
        animationType="slide"
        transparent={true}
        visible={this.state.withdrawBankModal}
        statusBarTranslucent
        onRequestClose={() => this.setState({ withdrawBankModal: false })}
      >
        <View
          style={{
            flex: 1,
            backgroundColor: 'rgba(0,0,0,0.5)',
          }}
        >
          <View
            style={{
              height: HeightDimen(60),
              justifyContent: 'center',
              marginTop:
                Platform.OS === 'ios' ? HeightDimen(40) : HeightDimen(30),
            }}
          >
            <TouchableOpacity
              style={{
                paddingLeft: WidthDimen(20),
              }}
              onPress={() => {
                this.setState({ withdrawBankModal: false })
              }}
            >
              <Image
                source={require('../../Resources/Images/icon_back.png')}
                style={{
                  width: 'auto',
                  height: HeightDimen(15),
                  aspectRatio: 19 / 13,
                  resizeMode: 'contain',
                }}
              />
            </TouchableOpacity>
          </View>
          <View
            style={{
              flex: 1,
              justifyContent: 'flex-end',
            }}
          >
            <View
              style={{
                backgroundColor: 'white',
                flex: 0.5,
                paddingBottom: HeightDimen(10),
              }}
            >
              <View
                style={{
                  height: HeightDimen(50),
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Text
                  style={{
                    fontSize: fontDimen(15),
                    fontWeight: 'bold',
                  }}
                >
                  {'Bank Accounts'}
                </Text>
              </View>
              <FlatList
                style={{
                  flex: 1,
                  paddingHorizontal: HeightDimen(10),
                }}
                contentContainerStyle={{ flexGrow: 1 }}
                numColumns={1}
                ListEmptyComponent={
                  <EmptyRecordCard
                    wantButton
                    btnLabel={strings.t('add_account')}
                    onPress={() => {
                      this.setState({ withdrawBankModal: false })
                      this.props.navigation.navigate('BankAcc', {
                        onGoBack: (data) => {
                          this.onRefresh()
                        },
                      })
                    }}
                  />
                }
                data={this.state.bankAccountList}
                renderItem={({ item, index }) => (
                  <BankCard
                    title={item.account_name}
                    description={this.getbankName(item.bank_id).bank_name}
                    accountNo={'*' + item.account_number.substr(-4, 4)}
                    onPress={() => this.onSelectAccount(item)}
                  />
                )}
                keyExtractor={(item) => String(item.id)}
              />
            </View>
          </View>
        </View>
      </Modal>
    )
  }
  renderTitleDescription(title, description) {
    if (description)
      return (
        <View
          style={{
            flexDirection: 'row',
            marginBottom: HeightDimen(15),
          }}
        >
          <Text
            style={{
              fontSize: fontDimen(15),
              color: FontSecondaryColor,
              width: WidthDimen(110),
            }}
          >
            {title}
          </Text>
          <Text
            style={{
              fontSize: fontDimen(15),
              color: FontMainColor,
              flex: 1,
            }}
          >
            {description}
          </Text>
        </View>
      )
  }
  previewithdrawUsdtModal() {
    return (
      <Modal
        animationType="slide"
        transparent={true}
        visible={this.state.isPreviewWithdrawUsdt}
        statusBarTranslucent
      >
        <View
          style={{
            flex: 1,
            backgroundColor: 'rgba(0,0,0,0.5)',
            justifyContent: 'center',
          }}
        >
          <View
            style={{
              backgroundColor: 'white',
              marginHorizontal: HeightDimen(20),
              borderRadius: HeightDimen(10),
              paddingHorizontal: HeightDimen(15),
            }}
          >
            <View
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: HeightDimen(15),
              }}
            >
              <Text
                style={{
                  fontSize: fontDimen(20),
                  fontWeight: 'bold',
                }}
              >
                {'Withdraw USDT'}
              </Text>
            </View>

            <Text
              style={{
                fontSize: fontDimen(30),
                marginTop: HeightDimen(20),
                textAlign: 'center',
                color: 'green',
              }}
            >
              {this.state.previewUsdt.converted_amount}
            </Text>
            <View
              style={{
                width: '100%',
                borderBottomWidth: HeightDimen(1),
                marginTop: HeightDimen(15),
              }}
            ></View>
            <Text
              style={{
                fontSize: fontDimen(20),
                marginBottom: HeightDimen(15),
                marginTop: HeightDimen(15),
                textAlign: 'center',
                color: FontSecondaryColor,
              }}
            >
              {this.state.previewUsdt.converted_amount_currency}
            </Text>
            {this.renderTitleDescription(
              'Amount',
              this.state.previewUsdt.original_amount
            )}
            {this.renderTitleDescription(
              'Currency',
              this.state.previewUsdt.original_amount_currency
            )}
            {this.renderTitleDescription(
              'Description',
              this.state.previewUsdt.desc
            )}
            {this.renderTitleDescription(
              strings.t('date'),
              this.state.previewUsdt.date
            )}

            <View
              style={{
                width: '100%',
                justifyContent: 'center',
                marginTop: HeightDimen(20),
                flexDirection: 'row',
                marginBottom: HeightDimen(10),
              }}
            >
              <TextButton
                label={strings.t('confirm')}
                size={2.5}
                onPress={() => this.on_validate_withraw()}
                isLoading={this.state.isLoading}
              />
              <View style={{ marginLeft: HeightDimen(15) }}>
                <TextButton
                  label={strings.t('cancel')}
                  size={2.5}
                  onPress={() => {
                    this.setState({ isPreviewWithdrawUsdt: false })
                  }}
                  isLoading={this.state.isLoading}
                  blackBtn
                />
              </View>
            </View>
          </View>
        </View>
      </Modal>
    )
  }

  close_succ_modal = () => {
    this.setState({
      showSuccModal: false,
    })
    this.props.route.params.onGoBack(true)
    this.props.navigation.goBack()
  }
  getbankName(bankId) {
    return this.state.bankList.find((item) => {
      return item.bank_id === bankId
    })
  }
  onSelectAccount(account) {
    MyData.bankId = account.bank_id
    MyData.bankName = account.account_name
    MyData.bankAccountNo = account.account_number

    const bank = this.getbankName(account.bank_id)
    this.setState({
      selectedAccount: account.account_name,
      selectedBankAccountId: account.id,
      bankName: bank.bank_name,
      bankType: account.bank_type,
      withdrawBankModal: false,
    })
  }
  on_validate_withraw = () => {
    if (MyData.country_code == 'THB') {
      if (this.state.amount >= 100) {
        this.props.navigation.navigate('TransactionOTP', {
          isWithdraw: true,
          bankList: this.state.bankList,
          transferAmount: this.state.amount,
          selectedBankAccountId: this.state.selectedBankAccountId,
          onGoBack: () => {
            this.props.navigation.goBack()
            this.props.route.params.onGoBack()
          },
        })
      } else {
        MyToast.popToast('Minimum withdraw amount is 100')
        return
      }
    } else {
      this.on_withdraw()
    }
  }
  onFocus = () => {
    // this.inputRef.current.setSelection(
    //   this.state.amount?.length,
    //   this.state.amount?.length
    // )
  }
  render() {
    return (
      <ScrollView
        style={{
          flex: 1,
        }}
      >
        <WithdrawSuccModal
          isShow={this.state.showSuccModal}
          bankList={this.state.bankList}
          amount={this.state.amount}
          onClose={() => this.close_succ_modal()}
        />

        <EnterTransactionPinModal
          isShow={this.state.showPinModal}
          onPressForgotPin={() => this.onPress_forgot_pin()}
          onSuccEnterPin={() => this.onSuccEnterPin()}
          onBackModal={() => this.onBackModal()}
        />

        <View
          style={{
            flex: 1,

            paddingHorizontal: HeightDimen(15),
            paddingVertical: HeightDimen(20),
          }}
        >
          {this.state.isGetBankListLoading ? (
            <View>
              <ActivityIndicator size="small" color={MainColor} />
            </View>
          ) : (
            <View>
              {!this.state.showErrorText ? (
                <View>
                  <View>
                    <TouchableOpacity
                      onPress={() => this.setState({ withdrawBankModal: true })}
                      style={{
                        width: '100%',
                        height: HeightDimen(50),
                        backgroundColor: '#EFF2F4',
                        borderRadius: HeightDimen(10),
                        flexDirection: 'row',
                        alignItems: 'center',
                        padding: HeightDimen(15),
                        marginBottom: HeightDimen(10),
                      }}
                    >
                      <Text
                        numberOfLines={1}
                        style={{
                          flex: 1,
                          fontSize: fontDimen(15),
                          color: this.state.selectedBankAccountId
                            ? FontMainColor
                            : '#B8BABD',
                        }}
                      >
                        {this.state.selectedAccount}
                      </Text>
                    </TouchableOpacity>
                    <RecoverTextInput
                      ref={this.inputRef}
                      title={
                        strings.t('withdraw_RM') + ` (${MyData.country_code})`
                      }
                      placeholder={'0.00'}
                      top={true}
                      isNumber={true}
                      onChangeText={this.handle_amount}
                      value={this.state.amount}
                      onFocus={this.onFocus}
                      isMax
                      onMaxPress={() => {
                        if (
                          Number(
                            this.state.withdrawAmount.availableWithdrawAmount
                          ) <= Number(this.state.withdrawAmount.maxWithdraw)
                        )
                          this.setState({
                            amount: `${this.state.withdrawAmount.availableWithdrawAmount}`,
                          })
                      }}
                    />
                    <View
                      style={{
                        marginTop: HeightDimen(10),
                      }}
                    />
                    {this.state.selectedBankAccountId ? (
                      <NormalTextInput
                        title={strings.t('bank')}
                        hideImage
                        isEdit={false}
                        value={this.state.bankName}
                      />
                    ) : (
                      <View />
                    )}
                    <View
                      style={{
                        marginTop: HeightDimen(20),
                      }}
                    >
                      <Text
                        style={{
                          fontSize: fontDimen(13),
                          color: MainColor,
                        }}
                      >
                        <Text
                          style={{
                            fontWeight: 'bold',
                          }}
                        >
                          {strings.t('minimum_withdraw')}
                        </Text>

                        {`${MyData.country_code} ` +
                          numberWithCommas(
                            Number(
                              this.state.withdrawAmount?.minWithdraw
                            ).toFixed(2)
                          )}
                      </Text>
                      <Text
                        style={{
                          fontSize: fontDimen(13),
                          color: MainColor,
                        }}
                      >
                        <Text
                          style={{
                            fontWeight: 'bold',
                          }}
                        >
                          {strings.t('maximum_withdraw')}
                        </Text>
                        {`${MyData.country_code} ${numberWithCommas(
                          Number(
                            this.state.withdrawAmount?.maxWithdraw
                          ).toFixed(2)
                        )} `}
                      </Text>
                      <Text
                        style={{
                          fontSize: fontDimen(13),
                          color: MainColor,
                        }}
                      >
                        <Text
                          style={{
                            fontWeight: 'bold',
                          }}
                        >
                          {strings.t('limitPerDay')}
                        </Text>
                        {this.state.withdrawAmount.withdrawLimit == -1
                          ? strings.t('unlimited')
                          : this.state.withdrawAmount.withdrawLimit}
                        {/* {MyData?.vip_max_withdraw_count
                            ? MyData?.vip_max_withdraw_count
                            : 3} */}
                      </Text>
                      <Text
                        style={{
                          fontSize: fontDimen(13),
                          color: MainColor,
                          marginBottom: HeightDimen(15),
                        }}
                      >
                        <Text
                          style={{
                            fontWeight: 'bold',
                          }}
                        >
                          {strings.t('withdraw_balance')}
                        </Text>
                        {`${MyData.country_code} ` +
                          this.state.withdrawAmount.availableWithdrawAmount}
                      </Text>
                      <NumberBulletsText num={1} />
                      <Text
                        style={{
                          fontSize: fontDimen(13),
                          color: MainColor,
                          marginBottom: HeightDimen(10),
                          fontWeight: 'bold',
                        }}
                      >
                        {strings.t('withdraw_policy')}
                      </Text>
                      <AgreeCheckBox
                        value={this.state.isCheck}
                        onCheck={this.checkAgree}
                        label={strings.t('i_have_read_and_agree')}
                      />
                    </View>
                  </View>
                  <View
                    style={{
                      width: '100%',
                      alignItems: 'center',
                      marginTop: HeightDimen(40),
                    }}
                  >
                    {this.state.isCheck && (
                      <TextButton
                        label={strings.t('next')}
                        size={5}
                        onPress={() => this.onNextPressed()}
                        isLoading={this.state.isLoading}
                      />
                    )}
                  </View>
                </View>
              ) : (
                <Text
                  style={{
                    fontSize: fontDimen(15),
                    color: this.state.selection == 2 ? 'white' : FontMainColor,
                  }}
                >
                  {strings.t('no_bank_account_added')}
                </Text>
              )}
            </View>
          )}
          {this.withdrawBankAccountModal()}
          {this.previewithdrawUsdtModal()}
        </View>
      </ScrollView>
    )
  }
}

export default WithdrawScreen
