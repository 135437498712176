import React from 'react'
import { View, TouchableOpacity, Image } from 'react-native'
import Text from '../Text/MyText'
import {
  SecondaryColor,
  FontSecondaryColor,
  MainColor,
  GILROY_BOLD,
} from '../../UI/Color'
import { HeightDimen, fontDimen } from '../../UI/Dimensions'

const CoinCard = (props) => {
  const {
    src,
    title,
    balance,
    marginTop,
    marginLeft,
    isDisable,
    onPress,
    vipLogo,
  } = props

  return (
    <TouchableOpacity
      onPress={onPress}
      disabled={isDisable}
      style={{
        flex: 1,
        padding: HeightDimen(10),
        borderRadius: HeightDimen(10),
        backgroundColor: '#e2e2e2',
        marginTop: marginTop || 0,
        marginLeft: marginLeft || 0,
      }}
    >
      <View
        style={{
          flexDirection: 'row',
          marginBottom: HeightDimen(5),
        }}
      >
        <Text
          style={{
            color: MainColor,
            fontSize: fontDimen(13),
            fontWeight: 'bold',
            fontFamily: GILROY_BOLD,
          }}
        >
          {title}
        </Text>
        <View
          style={{
            flex: 1,
            alignItems: 'flex-end',
            marginLeft: HeightDimen(10),
          }}
        >
          {src ? (
            <Image
              source={src}
              style={{
                width: 'auto',
                height: HeightDimen(22),
                aspectRatio: 1 / 1,
                resizeMode: 'contain',
                //   tintColor: SecondaryColor,
              }}
            />
          ) : (
            <View style={{ flexDirection: 'row' }}>
              <Text
                style={{
                  color: FontSecondaryColor,
                  fontSize: fontDimen(12),
                  fontWeight: 'bold',
                  fontFamily: GILROY_BOLD,
                }}
              >
                {balance}
              </Text>
              {vipLogo ? (
                <Image
                  source={vipLogo}
                  style={{
                    width: 'auto',
                    height: HeightDimen(25),
                    aspectRatio: 1 / 1,
                    marginLeft: 5,
                    marginTop: -3,
                  }}
                />
              ) : (
                <></>
              )}
            </View>
          )}
        </View>
      </View>
      {src ? (
        <View
          style={{
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Text
            style={{
              color: FontSecondaryColor,
              fontSize: fontDimen(12),
              fontWeight: 'bold',
              fontFamily: GILROY_BOLD,
            }}
          >
            {balance}
          </Text>
          {vipLogo ? (
            <Image
              source={vipLogo}
              style={{
                width: 'auto',
                height: HeightDimen(25),
                aspectRatio: 1 / 1,
                // marginLeft: 5,
                marginTop: -3,
              }}
            />
          ) : (
            <></>
          )}
        </View>
      ) : (
        <></>
      )}
    </TouchableOpacity>
  )
}

export { CoinCard }
