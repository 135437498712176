import React, { Component } from "react";
import { View, TouchableOpacity, Image } from "react-native";
import Text from "../../Text/MyText";

import {
  MainColor,
  SecondaryColor,
  FontMainColor,
  FontSecondaryColor,
  FontThirdColor,
} from "../../../UI/Color";
import {
  WidthDimen,
  HeightDimen,
  fontDimen,
  GetWidth,
  GetHeight,
} from "../../../UI/Dimensions";
import { strings } from "../../../Language/Language";

const TopCard = (props) => {
  const { rank, username, amount, image } = props;

  return (
    <View
      style={{
        alignItems: "center",
        marginTop: HeightDimen(25),
      }}
    >
      <View
        style={{
          width: "auto",
          height: HeightDimen(90),
          aspectRatio: 1 / 1,
          borderRadius: 9999,
          borderWidth: HeightDimen(3),
          borderColor: SecondaryColor,
          backgroundColor: SecondaryColor,
          marginTop: HeightDimen(30),
          overflow: "hidden",
          zIndex: 8888,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Image
          style={{
            width: "auto",
            height: HeightDimen(90),
            aspectRatio: 1 / 1,
            resizeMode: "contain",
          }}
          source={{ uri: image }}
        />
      </View>
      <View
        style={{
          width: "auto",
          height: HeightDimen(70),
          aspectRatio: 3.5 / 1,
          backgroundColor: "rgba(237, 181, 61, 0.1)",
          borderRadius: HeightDimen(10),
          marginTop: -HeightDimen(15),
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text
          style={{
            fontSize: fontDimen(15),
            fontWeight: "bold",
          }}
        >
          {username}
        </Text>
        <Text
          style={{
            fontSize: fontDimen(15),
          }}
        >
          {amount}
        </Text>
      </View>
      <Image
        style={{
          width: "auto",
          height: HeightDimen(60),
          aspectRatio: 120 / 67,
          resizeMode: "contain",
          position: "absolute",
          zIndex: 9999,
        }}
        source={
          rank == 1
            ? require("../../../Resources/Images/LeaderBoard/top1.png")
            : rank == 2
            ? require("../../../Resources/Images/LeaderBoard/top2.png")
            : require("../../../Resources/Images/LeaderBoard/top3.png")
        }
      />
    </View>
  );
};

export { TopCard };
