import React, { Component, useEffect, useState } from 'react'
import { Animated, TouchableOpacity, Text, View, Image } from 'react-native'
import { fontDimen, HeightDimen } from '../../UI/Dimensions'

const GameCard = (props) => {
  const { image, transform, onPress, name } = props

  return (
    <Animated.View
      style={[
        {
          //aspectRatio: 1 / 1.5,
          flexBasis: '33.33%',
          overflow: 'hidden',
          justifyContent: 'center',
          alignItems: 'center',
        },
        transform,
      ]}
    >
      <TouchableOpacity
        onPress={onPress}
        style={{
          overflow: 'hidden',
          width: '95%',
        }}
      >
        <Image
          resizeMode={'contain'}
          style={{
            aspectRatio: 1 / 1,
            resizeMode: 'contain',
          }}
          source={{
            uri: image,
            priority: FastImage.priority.high,
          }}
        />
        <View style={{ height: HeightDimen(60) }}>
          <Text
            style={{
              fontSize: fontDimen(15),
              fontWeight: 'bold',
              color: 'white',
              textAlign: 'center',
            }}
          >
            {name}
          </Text>
        </View>
      </TouchableOpacity>
    </Animated.View>
  )
}

export { GameCard }
