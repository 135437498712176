import React, { Component } from 'react'
import { View, BackHandler, Dimensions, Alert } from 'react-native'
import { HeightDimen } from '../../UI/Dimensions'
import { ConfirmBackModal } from '../../Component/Modal/ConfirmBackModal'
import { LaunchGameHeaderContainer } from '../../Component/Header/LaunchGameHeader'
import { handleOrientation } from '../../Utils/Common'
import { logScreenViewAnalytics, screenNames } from '../../Utils/Analytics'
const isPortrait = () => {
  const dim = Dimensions.get('screen')
  return dim.height >= dim.width
}

const isLandscape = () => {
  const dim = Dimensions.get('screen')
  return dim.width >= dim.height
}

class LaunchGameScreen extends Component {
  state = {
    url: '',
    title: '',
    showModal: false,

    pressCount: 0,
    isloading: true,

    landscape: isLandscape(),
  }

  constructor(props) {
    super(props)
    this.handleBackButtonClick = this.handleBackButtonClick.bind(this)

    // Dimensions.addEventListener('change', () => {
    //     // this.setState({
    //     //   orientation: isPortrait() ? 'portrait' : 'landscape'
    //     // });
    //     //console.log('asdsa')
    //   });
    this.handleOrientationChange = this.handleOrientationChange.bind(this)
    this.dimensionEventListner = Dimensions.addEventListener(
      'change',
      this.handleOrientationChange
    )
  }

  async componentDidMount() {
    // logScreenViewAnalytics(screenNames.LaunchGame)
    BackHandler.addEventListener(
      'hardwareBackPress',
      this.handleBackButtonClick
    )
    // Dimensions.addEventListener("change", this.handleOrientationChange);

    await handleOrientation(true)
    let temp = this.props.route.params.value
    this.setState({
      title: this.props.route.params.title,
      url: this.props.route.params.url,
    })
  }

  async componentWillUnmount() {
    BackHandler.removeEventListener(
      'hardwareBackPress',
      this.handleBackButtonClick
    )
    this.dimensionEventListner.remove()
    // Dimensions.removeEventListener("change", this.handleOrientationChange);
    await handleOrientation(false)
  }

  handleOrientationChange() {
    console.log(isLandscape())
    this.setState({
      landscape: isLandscape(),
    })
    return true
  }

  handleBackButtonClick() {
    this.onBackPressed()
    return true
  }

  onBackPressed = () => {
    this.setState({ showModal: true })
  }

  onCancelModal = () => {
    this.setState({ showModal: false })
  }

  onConfirmModal = async (value) => {
    this.setState({ showModal: false })
    await this.props.route.params.onGoBack()
    if (value)
      this.props.navigation.replace(value, {
        onGoBack: (data) => {},
      })
    else this.props.navigation.goBack()
  }
  onWebView_BackPressed = () => {
    console.log(this.state.pressCount)
    if (this.state.pressCount > 1 && this.state.isloading == false) {
      this.webView.goBack()
      this.state.pressCount = this.state.pressCount - 2
    }
  }

  render() {
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: 'white',
        }}
      >
        <ConfirmBackModal
          isShow={this.state.showModal}
          onPress={(val) => this.onConfirmModal(val)}
          onBackPress={() => this.onCancelModal()}
          onCloseGamePress={() => this.onConfirmModal()}
        />
        <LaunchGameHeaderContainer
          title={this.state.title}
          onBackPress={() => this.onWebView_BackPressed()}
          toShowImage={true}
          toShowImageUrl={require('../../Resources/Images/icon_x.png')}
          toShowPress={() => this.onBackPressed()}
          landscape={this.state.landscape}
        >
          <View
            style={{
              flex: 1,
              marginTop: -HeightDimen(30),
            }}
          >
            {/* <WebView
              incognito={true}
              ref={(webView) => (this.webView = webView)}
              onNavigationStateChange={(navState) => {
                this.state.isloading = navState.loading
                if (navState.loading == false) {
                  this.state.pressCount = this.state.pressCount + 1
                }
              }}
              source={{ uri: this.state.url }}
              mixedContentMode="compatibility"
              allowsInlineMediaPlayback={true}
              style={{
                flex: 1,
              }}
            /> */}
            <iframe
              src={this.state.url}
              height={'100%'}
              width={'100%'}
              frameBorder="0"
            />
          </View>
        </LaunchGameHeaderContainer>
      </View>
    )
  }
}

export default LaunchGameScreen
