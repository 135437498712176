// import Toast from 'react-native-simple-toast'

import { Toast } from 'native-base'

class MyToast {
  popToast = (text) => {
    Toast.show({
      title: text,
      placement: 'top',
      zIndex: 99999,
    })
  }
}

export default new MyToast()
