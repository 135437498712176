import axios from "axios";
import MyData from "../Data/MyData";
import { apiUrls } from "./APIurl";

class NotificationCaller {
  getNotifications = async (authToken, isGrouped = false) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.notificationList}`,
          {
            data: {
              type: "all",
              page: 1,
              length: 20,
              is_grouped: isGrouped,
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  };

  markNotificationAsRead = async authToken => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.notificationMarkAllasRead}`,
          {},
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  };

  getNotificationCount = async authToken => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.notificationCount}`,
          {
            data: {
              type: 0,
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
  isNotificationViewed = async (authToken, notiKey) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.notificationViewed}`,
          {
            data: {
              notiKey: notiKey,
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
  notificationDetail = async (authToken, notiKey) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.notificationDetail}`,
          {
            data: {
              notiKey: notiKey,
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

const notificationCaller = new NotificationCaller();
export default notificationCaller;
