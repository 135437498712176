import React, { Component } from "react";
import { View, Text, FlatList } from "react-native";
import { MainColor, SecondaryColor } from "../../UI/Color";
import { fontDimen, HeightDimen } from "../../UI/Dimensions";
import MIcon from "react-native-vector-icons/MaterialIcons";
import { useState } from "react";
import { useEffect } from "react";

const Stepper = (props) => {
  const [stepList, setStepList] = useState([1, 2, 3, 4, 5]);
  useEffect(() => {
    if (props?.list) setStepList(props?.list);
  });
  return (
    <FlatList
      showsHorizontalScrollIndicator={false}
      showsVerticalScrollIndicator={false}
      horizontal={true}
      style={styles.container}
      contentContainerStyle={{
        justifyContent: "center",
        flex: 1,
      }}
      data={stepList}
      renderItem={({ item, index }) => (
        <View style={{ alignItems: "center", flexDirection: "row" }}>
          {index > 0 && (
            <View
              style={{
                borderStyle: "dashed",
                borderWidth: HeightDimen(1),
                borderRadius: HeightDimen(1),
                width: 20,
                borderColor: SecondaryColor,
              }}
            ></View>
          )}
          <View
            style={{
              ...styles.button,
              backgroundColor: props.step >= item ? SecondaryColor : "white",
            }}
          >
            {props.step > item ? (
              <MIcon
                name={"check"}
                size={20}
                color={props.step >= item ? "white" : SecondaryColor}
              />
            ) : (
              <Text
                style={{
                  ...styles.label,
                  color: props.step >= item ? "white" : SecondaryColor,
                }}
              >
                {item}
              </Text>
            )}
          </View>
        </View>
      )}
      keyExtractor={(item) => String(item)}
    />
  );
};
const styles = {
  container: {
    flexGrow: 0,
    marginBottom: 10,
  },
  button: {
    backgroundColor: SecondaryColor,
    height: 40,
    borderRadius: 20,
    width: 40,
    alignItems: "center",
    justifyContent: "center",
    borderColor: SecondaryColor,
    borderWidth: 3,
  },
  label: {
    color: MainColor,
    fontWeight: "bold",
    fontSize: fontDimen(16),
  },
};
export { Stepper };
