import React, { Component } from 'react'
import {
  View,
  TouchableOpacity,
  Modal,
  Image,
  Platform,
  ActivityIndicator,
} from 'react-native'
import Text from '../Text/MyText'
import { FontMainColor, MainColor, SecondaryColor } from '../../UI/Color'
import { WidthDimen, HeightDimen, fontDimen } from '../../UI/Dimensions'
import { strings } from '../../Language/Language'
import { OTPCard } from '../Card/OTPCard'
import NumberPad from '../NumberPad/NumberPad'
import MyData from '../../Data/MyData'
import MyToast from '../Toast/MyToast'
import { NormalTextInput } from '../Input/NormalTextInput'

import aPICaller from '../../API/APICaller'

class EnterTransactionPinModal extends Component {
  state = {
    OTP: '',
    isLoading: false,
  }

  onEnterOTP = async (num) => {
    let temp_OTP = this.state.OTP

    if (num === 'X') {
      if (temp_OTP.length > 0) {
        temp_OTP = temp_OTP.slice(0, -1)
        this.setState({ OTP: temp_OTP })
      }
    } else {
      if (temp_OTP.length < 6) {
        temp_OTP = `${temp_OTP}${num}`
        this.setState({ OTP: temp_OTP })
        if (temp_OTP.length == 6) {
          await this.onCheckWalletPin(temp_OTP)
        }

        // else if (temp_OTP.length == 6) {
        //   MyToast.popToast(strings.invalid_transaction_pin);
        // }
      }
    }
  }

  onCheckWalletPin = async (pin) => {
    this.setState({ isLoading: true })
    debugger
    await aPICaller
      .onCheckWalletPin(pin)
      .then(async (res) => {
        debugger
        this.setState({ OTP: '' })
        this.props.onSuccEnterPin()
      })
      .catch((err) => {
        MyToast.popToast(err.response.data.msg)
      })
      .finally(() => {
        this.setState({ isLoading: false })
      })
  }
  onBackPressed = () => {
    this.setState({ OTP: '' })
    this.props.onBackModal()
  }

  onForgotPin = () => {
    this.setState({ OTP: '' })
    this.props.onPressForgotPin()
  }

  render() {
    const { isShow } = this.props

    return (
      <Modal
        animationType="slide"
        transparent={true}
        visible={isShow}
        statusBarTranslucent
      >
        <View
          style={{
            flex: 1,
            backgroundColor: 'rgba(0,0,0,0.5)',
          }}
        >
          <View
            style={{
              height: HeightDimen(60),
              justifyContent: 'center',
              alignItems: 'flex-start',
              marginTop:
                Platform.OS === 'ios' ? HeightDimen(40) : HeightDimen(30),
            }}
          >
            <View>
              <TouchableOpacity
                style={{
                  paddingLeft: WidthDimen(20),
                }}
                onPress={() => this.onBackPressed()}
              >
                <Image
                  source={require('../../Resources/Images/icon_back.png')}
                  style={{
                    width: 'auto',
                    height: HeightDimen(15),
                    aspectRatio: 1 / 1,
                    resizeMode: 'contain',
                  }}
                />
              </TouchableOpacity>
            </View>
          </View>
          <View
            style={{
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <View
              style={{
                flexWrap: 'wrap',
              }}
            >
              <View
                style={{
                  overflow: 'hidden',
                  backgroundColor: 'white',
                  borderRadius: HeightDimen(10),
                  alignItems: 'center',
                }}
              >
                <Text
                  style={{
                    fontSize: fontDimen(20),
                    textAlign: 'center',
                    marginBottom: HeightDimen(20),
                    marginTop: HeightDimen(30),
                  }}
                >
                  {strings.t('please_enter_pin')}
                </Text>
                {/* {this.render_otp_input_box()} */}
                <View style={{ marginHorizontal: HeightDimen(30) }}>
                  <NormalTextInput
                    placeholder={strings.t('pin')}
                    secure
                    value={this.state.OTP}
                    hideImage
                    isEdit={false}
                    textAlign={'center'}
                  />
                </View>
                <TouchableOpacity
                  onPress={() => this.onForgotPin()}
                  style={{
                    marginTop: HeightDimen(30),
                    marginBottom: HeightDimen(30),
                  }}
                  //disabled={this.state.isLoading}
                >
                  <Text
                    style={{
                      fontSize: fontDimen(12),
                      color: FontMainColor,
                    }}
                  >
                    {strings.t('forgot_pin')}
                  </Text>
                </TouchableOpacity>

                {this.state.isLoading && (
                  <ActivityIndicator
                    color={SecondaryColor}
                    size={'small'}
                    style={{ marginBottom: HeightDimen(10) }}
                  />
                )}
                <NumberPad
                  onPressSelect={this.onEnterOTP}
                  isLoading={this.state.isLoading}
                />
              </View>
            </View>
          </View>
        </View>
      </Modal>
    )
  }

  render_otp_input_box = () => {
    return (
      <View
        style={{
          justifyContent: 'center',
          flexDirection: 'row',

          marginLeft: -HeightDimen(10),
        }}
      >
        <OTPCard value={this.state.OTP} num={0} />
        <OTPCard marginLeft={WidthDimen(10)} value={this.state.OTP} num={1} />
        <OTPCard marginLeft={WidthDimen(10)} value={this.state.OTP} num={2} />
        <OTPCard marginLeft={WidthDimen(10)} value={this.state.OTP} num={3} />
        <OTPCard marginLeft={WidthDimen(10)} value={this.state.OTP} num={4} />
        <OTPCard marginLeft={WidthDimen(10)} value={this.state.OTP} num={5} />
      </View>
    )
  }
}

export { EnterTransactionPinModal }
