import React, { Component } from 'react'
import {
  View,
  FlatList,
  TouchableOpacity,
  ActivityIndicator,
  Image,
} from 'react-native'
import Text from '../../Component/Text/MyText'
import { MainColor, SecondaryColor } from '../../UI/Color'
import { HeightDimen, fontDimen } from '../../UI/Dimensions'
import { MainFlowHeaderContainerNoFeedback } from '../../Component/Header/MainFlowHeader'
import MyToast from '../../Component/Toast/MyToast'
import { strings } from '../../Language/Language'
import { BottomSelectionModal } from '../../Component/Modal/BottomSelectionModal'
import walletAPICaller from '../../API/WalletAPICaller'
import MyData from '../../Data/MyData'
import { AllWalletHistoryCard } from '../../Component/Card/History/AllWalletHistoryCard'

import MonthDateModal from '../../Component/Modal/MonthDateModal'
import ReactDatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
class AllWalletTransactionScreen extends Component {
  state = {
    selectedFilter: strings.t('all'),
    selectedFilterIndex: 0,
    filterList: [
      { id: 1, value: 'all', name: strings.t('all') },
      { id: 2, value: 'transfer', name: strings.t('transfer') },
      { id: 3, value: 'interestTransfer', name: strings.t('interest') },
      { id: 4, value: 'topup', name: strings.t('top_up') },
      { id: 5, value: 'withdraw', name: strings.t('withdraw') },
      { id: 6, value: 'commission', name: strings.t('commission') },
      { id: 7, value: 'refund', name: strings.t('refund') },
      { id: 8, value: 'penalty', name: strings.t('penalty') },
      { id: 9, value: 'reward', name: strings.t('reward') },
    ],
    showBottomModal: false,

    selectedDate: strings.t('pick_date'),
    datelabel: null,
    endDate: null,
    rawDate: new Date(),
    page: 1,
    showDatePicker: false,
    isLoading: false,
    toShowArray: [],
  }

  async componentDidMount() {
    //logScreenViewAnalytics(screenNames.WalletTransaction);

    await this.set_date()
    await this.onGet_allWalletTransaction(
      this.state.selectedDate,
      'all',
      this.state.endDate,
      this.state.page
    )
  }

  getDateFormat = () => {
    let dateToday = new Date()
    let day = dateToday.getDate()
    let month = dateToday.getMonth() + 1

    let date =
      dateToday.getFullYear() +
      '-' +
      (month < 10 ? '0' : '') +
      month +
      '-' +
      (day < 10 ? '0' : '') +
      day

    return date
  }

  set_date = async () => {
    let dateToday = new Date()
    let day = dateToday.getDate()
    let month = dateToday.getMonth() + 1
    let returnDate = this.getDateFormat()
    let end_date = new Date(
      dateToday.getFullYear(),
      dateToday.getMonth() + 1,
      0
    )
    let end_day = end_date.getDate()
    let returnEndDate =
      dateToday.getFullYear() +
      '-' +
      (month < 10 ? '0' : '') +
      month +
      '-' +
      (end_day < 10 ? '0' : '') +
      end_day

    this.setState({
      selectedDate: returnDate,
      endDate: returnEndDate,
      datelabel:
        (month < 10 ? '0' : '') + month + '-' + dateToday.getFullYear(),
    })
  }

  onBackPressed = () => {
    // logEventWithScreenName(
    //   eventMessages.BackButton,
    //   screenNames.WalletTransaction
    // );
    this.props.navigation.goBack()
  }

  onToggle_BottomModal = () => {
    this.setState({ showBottomModal: !this.state.showBottomModal })
  }

  onToggle_datePicker = () => {
    this.setState({ showDatePicker: !this.state.showDatePicker })
  }

  onSelectFilter = (index) => {
    // logEventWithFilterName(
    //   AnalyticEvents.WalletTransactionsTypeFilter,
    //   this.state.filterList[index].name
    // );
    this.onToggle_BottomModal()
    this.setState({
      selectedFilterIndex: index,
      selectedFilter: this.state.filterList[index].name,
      page: 1,
    })
    if (this.state.selectedDate != strings.t('pick_date')) {
      this.onGet_allWalletTransaction(
        this.state.selectedDate,
        this.state.filterList[index].value,
        this.state.endDate,
        1
      )
    }
  }

  close_date_modal = (date, end_date, date_label, rawDate) => {
    this.setState({
      selectedDate: date,
      endDate: end_date,
      datelabel: date_label,
      rawDate: rawDate,
      page: 1,
    })
    debugger
    //logEventWithFilterName(AnalyticEvents.WalletTransactionsDateFilter, date);
    this.onToggle_datePicker()
    this.onGet_allWalletTransaction(
      date,
      this.state.filterList[this.state.selectedFilterIndex].value,
      end_date,
      1
    )
  }

  onGet_allWalletTransaction = async (date, value, end_date, page) => {
    this.setState({ isLoading: true })
    await walletAPICaller
      .allWalletTransaction(MyData.authToken, date, value, end_date, page)
      .then((res) => {
        console.log(res.response.transactions)
        //logMessageAnalytics(AnalyticEvents.WalletTransactionsSuccess);
        let tempArr = res.response.transactions
        if (tempArr.length == 0) {
          MyToast.popToast(strings.t('no_transaction_for_dates'))
        }

        this.setState({
          isLoading: false,
          toShowArray: tempArr,
        })
      })
      .catch((err) => {
        console.log(err)
        //  logMessageAnalytics(AnalyticEvents.WalletTransactionsFailure);
        this.setState({ isLoading: false })
        if (err.response.status == 514) {
          this.props.expiredToken_logout()
        } else {
          MyToast.popToast(err.response.data.msg)
        }
      })
  }

  onGet_MoreWalletTransaction = async (date, value, end_date, page) => {
    if (page > 2) this.setState({ loadMore: true })
    await walletAPICaller
      .allWalletTransaction(MyData.authToken, date, value, end_date, page)
      .then((res) => {
        //logMessageAnalytics(AnalyticEvents.WalletTransactionsSuccess);
        let tempArr = res.response.transactions
        let mergeArr = [...this.state.toShowArray, ...tempArr]
        if (tempArr.length == 0 && page > 2) {
          MyToast.popToast(strings.t('no_more_transaction_for_dates'))
        }

        this.setState({
          loadMore: false,
          toShowArray: mergeArr,
        })
      })
      .catch((err) => {
        // logMessageAnalytics(AnalyticEvents.WalletTransactionsFailure);
        console.log(err)
        this.setState({ loadMore: false })
        if (err.response.status == 514) {
          this.props.expiredToken_logout()
        } else {
          MyToast.popToast(err.response.data.msg)
        }
      })
  }

  onPress_transaction = (index) => {
    //   logMessageAnalytics(AnalyticEvents.WalletTransactionClick);
    this.props.navigation.navigate('AllWalletTransactionDetail', {
      data: this.state.toShowArray[index],
    })
  }

  loadMore = async () => {
    let newpage = this.state.page + 1
    this.setState({ page: newpage })
    this.onGet_MoreWalletTransaction(
      this.state.selectedDate,
      this.state.filterList[this.state.selectedFilterIndex].value,
      this.state.endDate,
      newpage
    )
  }
  renderMonthYearPicker = () => {
    return (
      <MonthDateModal
        isShow={this.state.showDatePicker}
        label={strings.t('pick_date')}
        onClose={this.close_date_modal}
        rawDate={this.state.rawDate}
      />
    )
  }
  render() {
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: 'white',
        }}
      >
        <BottomSelectionModal
          isShow={this.state.showBottomModal}
          filterList={this.state.filterList}
          onSelect={this.onSelectFilter}
        />

        {/* {this.renderMonthYearPicker()} */}

        <MainFlowHeaderContainerNoFeedback
          title={strings.t('wallet_transaction')}
          onBackPress={() => this.onBackPressed()}
        >
          <View
            style={{
              flex: 1,
              marginTop: HeightDimen(10),
            }}
          >
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                width: '20%',
                zIndex: 9999,
                position: 'absolute',
                right: 20,
                justifyContent: 'flex-end',
              }}
            >
              <Text style={styles.date}>{strings.t('date') + ': '}</Text>
              <MonthDateModal
                isShow={this.state.showDatePicker}
                label={strings.t('pick_date')}
                onClose={this.close_date_modal}
                rawDate={this.state.rawDate}
              />
            </View>
            <View
              style={{
                flexDirection: 'row',
                paddingLeft: HeightDimen(20),
                paddingRight: HeightDimen(20),
              }}
            >
              <TouchableOpacity
                onPress={() => this.onToggle_BottomModal()}
                disabled={this.state.isLoading}
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  width: '30%',
                }}
              >
                <Text style={styles.date}>{strings.t('type') + ': '}</Text>
                <Text style={styles.dateLabel}>
                  {this.state.selectedFilter}
                </Text>
                <Image
                  style={{
                    width: 'auto',
                    height: HeightDimen(10),
                    aspectRatio: 1 / 1,
                    padding: HeightDimen(3),
                    marginLeft: HeightDimen(5),
                  }}
                  source={require('../../Resources/Images/icon_down.png')}
                />
              </TouchableOpacity>
            </View>
            {this.state.loadMore && (
              <View
                style={{
                  position: 'absolute',
                  bottom: HeightDimen(40),
                  alignSelf: 'center',
                }}
              >
                <ActivityIndicator size="small" color={MainColor} />
              </View>
            )}
            <View
              style={{
                flex: 1,
                marginTop: HeightDimen(15),
              }}
            >
              {this.state.isLoading ? (
                <View>
                  <ActivityIndicator size="small" color={MainColor} />
                </View>
              ) : (
                <View
                  style={{
                    flex: 1,
                  }}
                >
                  {this.state.toShowArray.length > 0 && (
                    <View
                      style={{
                        flex: 1,
                      }}
                    >
                      <FlatList
                        style={{
                          paddingLeft: HeightDimen(15),
                          paddingRight: HeightDimen(15),
                        }}
                        onRefresh={() => {
                          this.setState({ page: 1 })
                          this.onGet_allWalletTransaction(
                            this.state.selectedDate,
                            this.state.selectedFilter.toLowerCase(),
                            this.state.endDate,
                            1
                          )
                        }}
                        refreshing={this.state.isLoading}
                        initialNumToRender={20}
                        //  onEndReached={this.loadMore}
                        numColumns={1}
                        data={this.state.toShowArray}
                        renderItem={({ item, index }) => (
                          <AllWalletHistoryCard
                            item={item}
                            onPress={() => this.onPress_transaction(index)}
                          />
                        )}
                        keyExtractor={(item) => String(item.transaction_id)}
                      />
                    </View>
                  )}
                </View>
              )}
            </View>
          </View>
        </MainFlowHeaderContainerNoFeedback>
      </View>
    )
  }
}

const styles = {
  date: {
    fontSize: fontDimen(12),
    fontWeight: 'bold',
  },
  dateLabel: {
    fontSize: fontDimen(12),
    fontWeight: 'bold',
    color: SecondaryColor,
    marginLeft: HeightDimen(5),
  },
}

export default AllWalletTransactionScreen
