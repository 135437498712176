import React, { Component } from "react";
import {
  Animated,
  TouchableOpacity,
  Image,
  View,
  Platform,
} from "react-native";
import { fontDimen, HeightDimen } from "../../UI/Dimensions";
import Text from "../../Component/Text/MyText";
import {
  FontMainColor,
  FontSecondaryColor,
  SecondaryColor,
} from "../../UI/Color";
const NotificationCard = props => {
  const {
    title,
    message,
    onPress,
    isViewed,
    newDesign,
    item,
    cardSection,
    image_url,
  } = props;

  if (cardSection) {
    const moment = require("moment");
    return (
      <View
        style={{
          width: "100%",
          backgroundColor: "#fbf2e1",
          padding: HeightDimen(10),
          flexDirection: "row",
        }}>
        <Text
          numberOfLines={1}
          style={{
            color: "#c2a756",
            marginBottom: HeightDimen(5),
            fontWeight: "bold",
          }}>
          {moment(title).format("DD-MMM-YYYY")}
        </Text>
      </View>
    );
  }

  if (newDesign) {
    return (
      <TouchableOpacity
        onPress={onPress}
        style={{
          // width: "100%",
          backgroundColor: isViewed == "0" ? "#ededed" : "transparent",
          // borderRadius: HeightDimen(10),
          padding: HeightDimen(10),
          paddingHorizontal: HeightDimen(20),
          marginHorizontal: HeightDimen(5),
          flexDirection: "row",
        }}>
        <Image
          style={{
            height: HeightDimen(45),
            borderRadius: 10,
            aspectRatio: 1 / 1,
            marginRight: 12,
          }}
          source={{ uri: image_url }}
        />
        <View
          style={{
            flex: 1,
            justifyContent: "space-between",
          }}>
          <Text
            numberOfLines={1}
            style={{
              fontSize: fontDimen(14),
              color: FontMainColor,
              marginBottom: HeightDimen(5),
            }}>
            {title}
          </Text>
          <Text
            style={{
              fontSize: fontDimen(10),
              color: FontSecondaryColor,
            }}>
            {message}
          </Text>
        </View>
      </TouchableOpacity>
    );
  }

  return (
    <TouchableOpacity
      onPress={onPress}
      style={{
        width: "100%",
        backgroundColor: "#fef6eb",
        borderRadius: HeightDimen(10),
        padding: HeightDimen(10),
        marginBottom: HeightDimen(10),
      }}>
      <View
        style={{
          flex: 1,
          justifyContent: "space-between",
          flexDirection: "row",
        }}>
        <Text
          numberOfLines={1}
          style={{
            fontSize: fontDimen(14),
            color: FontMainColor,
            marginBottom: HeightDimen(5),
          }}>
          {title}
        </Text>
        <Text
          numberOfLines={1}
          style={{
            fontSize: fontDimen(10),
            color: FontSecondaryColor,
          }}>
          {props?.data_time}
        </Text>
      </View>
      <Text
        style={{
          fontSize: fontDimen(10),
          color: FontSecondaryColor,
        }}>
        {message}
      </Text>
    </TouchableOpacity>
  );
};

export { NotificationCard };
