import React, { Component } from "react";
import { View, Text, TextInput, TouchableOpacity, Image } from "react-native";
import { FontSecondaryColor, SecondaryColor } from "../../UI/Color";
import { fontDimen, HeightDimen } from "../../UI/Dimensions";
import { MainFlowHeaderContainer } from "../../Component/Header/NewDesign/MainFlowHeader";
import { strings } from "../../Language/Language";
import MyData from "../../Data/MyData";
import MyToast from "../../Component/Toast/MyToast";
import { TextButton } from "../../Component/Button/TextButton";
import avatarCaller from "../../API/AvatarCaller";
import { CountryCodeModal } from "../../Component/Modal/CountryCodeModal";
import currencyExchangeCaller from "../../API/CurrencyExchangeAPICaller";
import { DropdownTextInput } from "../../Component/Input/DropdownTextInput";
import walletAPICaller from "../../API/WalletAPICaller";
import { normalizeDecimalValue } from "../../Utils/Common";

class CurrencyExchangeScreen extends Component {
  state = {
    isLoading: true,
    countryList: [],
    toCode: "THB",
    fromCode: "MYR",
    isCountryModal: false,
    isFromSelectCode: true,
    fromAmount: "",
    toAmount: "0.00",
    rate: "0.00",
    allWallets: [],
    toBalance: "0.00",
    fromBalance: "0.00",
    availableBalance: MyData?.temp_json_object?.response?.user?.walletBalance,
  };
  constructor(props) {
    super(props);

    this.inputRef = React.createRef();
  }
  async componentDidMount() {
    await this.getCountryList();
    await this.getAllWallet();
  }

  onBackPress() {
    this.props.route.params.onGoBack();
    this.props.navigation.goBack();
  }
  getCountryList = async () => {
    this.setState({ isLoading: true });
    await avatarCaller
      .getCountryList(MyData.authToken)
      .then((res) => {
        this.setState({ countryList: res.response.list });
      })
      .catch((err) => {
        if (err.response.status == "514") {
          this.props.expiredToken_logout();
        } else {
          MyToast.popToast(err.response.data.msg);
        }
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  getAllWallet = async () => {
    const { fromCode, toCode } = this.state;
    this.setState({ isLoading: true });
    await walletAPICaller
      .getWalletBalanceCurrency(MyData.authToken)
      .then((res) => {
        let fromBalance = "0.00";
        let toBalance = "0.00";

        res?.response.list.forEach((i) => {
          if (i.code == fromCode) fromBalance = i.wallet_balance;
          if (i.code == toCode) toBalance = i.wallet_balance;
        });
        console.log(fromBalance, toBalance);
        this.setState({
          allWallets: res.response.list,
          fromBalance,
          toBalance,
        });
      })
      .catch((err) => {
        if (err.response.status == "514") {
          this.props.expiredToken_logout();
        } else {
          MyToast.popToast(err.response.data.msg);
        }
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  currencyPreview = async (from, to, amount) => {
    if (this.state.fromAmount) {
      this.setState({ isLoading: true });
      await currencyExchangeCaller
        .currencyPreview(MyData.authToken, from, to, amount)
        .then((res) => {
          this.setState({
            toAmount: Number(res.response.amount).toFixed(2),
            rate: res.response.rate,
          });
        })
        .catch((err) => {
          if (err.response.status == "514") {
            this.props.expiredToken_logout();
          } else {
            MyToast.popToast(err.response.data.msg);
          }
        })
        .finally(() => {
          this.setState({ isLoading: false });
        });
    }
  };
  currencyExchange = async () => {
    if (this.state.fromAmount) {
      this.setState({ isLoading: true });
      await currencyExchangeCaller
        .currencyExchange(
          MyData.authToken,
          this.state.fromCode,
          this.state.toCode,
          this.state.fromAmount
        )
        .then((res) => {
          this.onBackPress();
          MyToast.popToast(strings.t("currency_exchange_successfully"));
        })
        .catch((err) => {
          if (err.response.status == "514") {
            this.props.expiredToken_logout();
          } else {
            MyToast.popToast(err.response.data.msg);
          }
        })
        .finally(() => {
          this.setState({ isLoading: false });
        });
    } else {
      MyToast.popToast(strings.t("please_enter_amount"));
    }
  };

  onReverseCurrency = async () => {
    const { fromCode, toCode, fromAmount } = this.state;
    this.setState({ fromCode: toCode, toCode: fromCode });
    await this.currencyPreview(toCode, fromCode, fromAmount);
    await this.getAllWallet();
  };
  onChangeAmount = (val) => {
    debugger;
    // if (Number(val) <= Number(this.state.availableBalance))
    //   this.setState({ fromAmount: val });
    // else return;
    let value = normalizeDecimalValue(val);
    if (Number(value) <= Number(this.state.availableBalance)) {
      // this.inputRef.current.setSelection(value?.length, value?.length)
      this.setState({
        fromAmount: value,
      });
    } else {
      // this.inputRef.current.setSelection(
      //   this.state.availableBalance?.length,
      //   this.state.availableBalance?.length
      // )
      this.setState({
        fromAmount: this.state.availableBalance,
      });
    }
  };
  onFocus = () => {
    this.inputRef.current.setSelection(
      this.state.fromAmount?.length,
      this.state.fromAmount?.length
    );
  };

  render() {
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: "white",
        }}
      >
        <MainFlowHeaderContainer
          title={strings.t("currency_exchange")}
          onBackPress={() => this.onBackPress()}
        >
          <CountryCodeModal
            isShow={this.state.isCountryModal}
            list={this.state.countryList}
            onBackPress={() => {
              this.setState({ isCountryModal: false });
            }}
            onPress={(item) => {
              if (this.state.isFromSelectCode) {
                if (this.state.toCode != item.code) {
                  this.setState({ fromCode: item.code });
                  this.currencyPreview(
                    this.state.fromCode,
                    item.code,
                    this.state.fromAmount
                  );
                } else {
                  MyToast.popToast(strings.t("please_select_other_currency"));
                }
              } else {
                if (this.state.fromCode != item.code) {
                  this.setState({ toCode: item.code });
                  this.currencyPreview(
                    this.state.fromCode,
                    item.code,
                    this.state.fromAmount
                  );
                } else {
                  MyToast.popToast(strings.t("please_select_other_currency"));
                }
              }
              this.setState({ isCountryModal: false });
            }}
          />
          <View
            style={{
              flex: 1,
              paddingHorizontal: HeightDimen(20),
              paddingTop: HeightDimen(20),
            }}
          >
            <DropdownTextInput
              ref={this.inputRef}
              ddlLabel={this.state.fromCode}
              value={this.state.fromAmount}
              isNumber
              onChangeText={(val) => this.onChangeAmount(val)}
              placeholder={strings.t("send_currency_exactly")}
              onFocus={this.onFocus}
              onBlur={() => {
                this.currencyPreview(
                  this.state.fromCode,
                  this.state.toCode,
                  this.state.fromAmount
                );
                this.getAllWallet();
              }}
              ddlPress={() => {
                this.setState({
                  isFromSelectCode: true,
                  isCountryModal: true,
                });
              }}
            />
            <View
              style={{ alignItems: "center", marginVertical: HeightDimen(10) }}
            >
              <View
                style={{
                  borderColor: "#C7C7CD",
                  borderWidth: 1,
                  width: 1,
                  height: HeightDimen(20),
                }}
              />
              <Text
                style={{
                  color: SecondaryColor,
                  fontSize: fontDimen(16),
                  marginVertical: HeightDimen(3),
                  fontWeight: "bold",
                }}
              >
                TO
              </Text>

              <View
                style={{
                  borderColor: "#C7C7CD",
                  borderWidth: 1,
                  width: 1,
                  height: HeightDimen(20),
                }}
              />
            </View>
            <DropdownTextInput
              ddlLabel={this.state.toCode}
              isEdit={false}
              placeholder={strings.t("receipt_get")}
              value={this.state.toAmount.toString()}
              ddlPress={() => {
                this.setState({
                  isFromSelectCode: false,
                  isCountryModal: true,
                });
              }}
            />
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                marginTop: HeightDimen(20),
              }}
            >
              <Text
                style={{
                  color: FontSecondaryColor,
                  fontSize: fontDimen(17),
                  textAlign: "center",
                }}
              >
                {strings.t("exchange_rate")}
              </Text>
              <TouchableOpacity onPress={this.onReverseCurrency}>
                <Image
                  source={require("../../Resources/Images/refresh.png")}
                  style={styles.refreshIcon}
                />
              </TouchableOpacity>
            </View>
            <Text
              style={{
                color: SecondaryColor,
                fontSize: fontDimen(18),
                fontWeight: "bold",
                textAlign: "center",
                marginTop: HeightDimen(10),
              }}
            >
              {this.state.rate}
            </Text>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: HeightDimen(20),
              }}
            >
              <Text
                style={{
                  color: FontSecondaryColor,
                  fontSize: fontDimen(17),

                  textAlign: "center",
                }}
              >
                {this.state.fromCode}
              </Text>
              <Text
                style={{
                  color: FontSecondaryColor,
                  fontSize: fontDimen(17),
                  textAlign: "center",
                }}
              >
                {this.state.toCode}
              </Text>
            </View>

            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Text
                style={{
                  color: SecondaryColor,
                  fontSize: fontDimen(18),
                  fontWeight: "bold",
                  textAlign: "center",
                  marginTop: HeightDimen(10),
                }}
              >
                {this.state.fromBalance}
              </Text>
              <Text
                style={{
                  color: SecondaryColor,
                  fontSize: fontDimen(18),
                  fontWeight: "bold",
                  textAlign: "center",
                  marginTop: HeightDimen(10),
                }}
              >
                {this.state.toBalance}
              </Text>
            </View>

            <View
              style={{
                width: "100%",
                alignItems: "center",
                marginTop: HeightDimen(20),
              }}
            >
              <GradientTextButton
                label={strings.t("confirm")}
                gradColor={["#ffee8d", "#f7c56e"]}
                onPress={() => this.currencyExchange()}
                size={"100%"}
                isLoading={this.state.isLoading}
              />
            </View>
          </View>
        </MainFlowHeaderContainer>
      </View>
    );
  }
}
const styles = {
  refreshIcon: {
    width: "auto",
    height: HeightDimen(20),
    aspectRatio: 1 / 1,
    tintColor: SecondaryColor,
    marginLeft: HeightDimen(10),
  },
};
export default CurrencyExchangeScreen;
