// import 'react-native-gesture-handler'
import React, { Component } from 'react'
import { View, Text, Modal, StyleSheet, LogBox, Dimensions } from 'react-native'
import { NavigationContainer } from '@react-navigation/native'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
// import LottieView from 'lottie-react-native'
import SplashScreen from '../Screen/Splash/SplashScreen'
import LoginScreen from '../Screen/Login/LoginScreen'
import HomeScreen from '../Screen/Home/HomeScreen'
import SignUpScreen from '../Screen/Login/SignUpScreen'
import RecoverPasswordScreen from '../Screen/Login/RecoverPasswordScreen'
import OTPScreen from '../Screen/Login/OTPScreen'
import SuccessfulChangePasswordScreen from '../Screen/Login/SuccessfulChangePasswordScreen'
import ChangePasswordScreen from '../Screen/Login/ChangePasswordScreen'
// import EditInfoScreen from '../Screen/Profile/EditInfoScreen'
import GameDetailsScreen from '../Screen/Discover/GameDetailsScreen'
// import WalletScreen from '../Screen/Payment/WalletScreen'
import TransactionScreen from '../Screen/Payment/TransactionScreen'
import OrderHistoryScreen from '../Screen/Payment/OrderHistoryScreen'
import BankAccScreen from '../Screen/Payment/BacnkAccScreen'
import AddBankScreen from '../Screen/Payment/AddBankScreen'
import SettingScreen from '../Screen/Setting/SettingScreen'
// import EquiqmentManagementScreen from '../Screen/Setting/EquiqmentManagementScreen'
import QRCodeScreen from '../Screen/Login/QRCodeScreen'
import TopUpScreen from '../Screen/Payment/TopUpScreen'
import CameraScreen from '../Screen/Payment/CameraScreen'
import WithdrawScreen from '../Screen/Payment/WithdrawScreen'
import EditBankAccScreen from '../Screen/Payment/EditBankAccScreen'
// import AllBalanceScreen from '../Screen/Payment/AllBalanceScreen'
import InterestScreen from '../Screen/Interest/InterestScreen'
import CreateInterestScreen from '../Screen/Interest/CreateInterestScreen'
import InterestDetailsScreen from '../Screen/Interest/InterestDetailsScreen'
import PartialWithdrawScreen from '../Screen/Interest/PartialWithdrawScreen'
import AboutScreen from '../Screen/Setting/AboutScreen'
import ChangeLoginPasswordScreen from '../Screen/Profile/ChangeLoginPasswordScreen'
import LanguageScreen from '../Screen/Setting/LanguageScreen'
import AboutPhoneScreen from '../Screen/Setting/AboutPhoneScreen'
import SuccChangeLoginPassword from '../Screen/Profile/SuccChangeLoginPassword'
import LaunchGameScreen from '../Screen/Discover/LaunchGameScreen'
import CreatePinScreen from '../Screen/Pin/CreatePinScreen'
import CreatePinSecondScreen from '../Screen/Pin/CreatePinSecondScreen'
import ChangePinScreen from '../Screen/Profile/ChangePinScreen'
import SuccChangePinScreen from '../Screen/Profile/SuccChangePinScreen'
import RecoverPinScreen from '../Screen/Pin/RecoverPinScreen'
import ChangePinOTPScreen from '../Screen/Pin/ChangePinOTPScreen'
import SuccChangePinOTPScreen from '../Screen/Pin/SuccChangePinOTPScreen'
import TransferCameraScreen from '../Screen/Payment/TransferCameraScreen'
import TransferAutoScreen from '../Screen/Payment/TransferAutoScreen'
import TransferManualScreen from '../Screen/Payment/TransferManualScreen'
// import MyToast from '../Component/Toast/MyToast'
import ChangeNickNameScreen from '../Screen/Profile/ChangeNickNameScreen'
import LeaderBoardScreen from '../Screen/Discover/LeaderBoardScreen'
import CoinHistoryScreen from '../Screen/Coin/CoinHistoryScreen'
import InterestHistoryScreen from '../Screen/Interest/InterestHistoryScreen'
// import { activateKeepAwake } from '@sayem314/react-native-keep-awake'
import CustomerServiceScreen from '../Screen/Chat/CustomerServiceScreen'
import AllWalletTransactionScreen from '../Screen/History/AllWalletTransactionScreen'
import AllGameTransactionScreen from '../Screen/History/AllGameTransactionScreen'
import AllWalletTransactionDetailScreen from '../Screen/History/WalletTransaction/AllWalletTransactionDetailScreen'
import AllWalletTransactionAdditionalDetailScreen from '../Screen/History/WalletTransaction/AllWalletTransactionAdditionalDetailScreen'
import GameDetailsTransactionScreen from '../Screen/Discover/GameDetailsTransactionScreen'
import TopUpHistoryDetailsScreen from '../Screen/Payment/TopUpHistoryDetailsScreen'
import WithdrawHistoryDetailsScreen from '../Screen/Payment/WithdrawHistoryDetailsScreen'
import InstantTopUpScreen from '../Screen/Payment/InstantTopUpScreen'
import ContactUsScreen from '../Screen/Login/ContactUsScreen'
import PaymentSuccessScreen from '../Screen/Payment/PaymentSuccessScreen'
import VerificationScreen from '../Screen/Login/VerificationScreen'
import Xe88GameDetailsScreen from '../Screen/Discover/Xe88GameDetailsScreen'
import VPowerGameScreen from '../Screen/Discover/VPowerGameScreen'
import AnouncementDetailScreen from '../Screen/Discover/AnouncementDetailScreen'
import PlayTechGameScreen from '../Screen/Discover/PlayTechGameScreen'
import PlayTechLaunchGameScreen from '../Screen/Discover/PlayTechLaunchGameScreen'
import JokerGameScreen from '../Screen/Discover/JokerGameScreen'
import UsdtTopupScreen from '../Screen/Payment/UsdtTopupScreen'
import MaintenanceScreen from '../Screen/Splash/MaintenanceScreen'
// import CodePush from 'react-native-code-push'
// import { TextButton } from '../Component/Button/TextButton'
// import { FontMainColor, MainColor } from '../UI/Color'
// import { fontDimen, HeightDimen } from '../UI/Dimensions'
// import { strings } from '../Language/Language'
import { Box, NativeBaseProvider, Progress } from 'native-base'
// import { getFcmToken, handleOrientation } from '../Utils/Common'
import AsyncStorage from '@react-native-async-storage/async-storage'
import NotificationScreen from '../Screen/Notification/Notifications'
import PromotionDetailScreen from '../Screen/Discover/PromotionDetailScreen'
import ChangePhoneNumberScreen from '../Screen/Profile/ChangePhoneNumberScreen'
import RecoverUsernameScreen from '../Screen/Login/RecoverUsernameScreen'
import RecoverUsersScreen from '../Screen/Login/RecoverUsersScreen'
import MultiAccountLoginScreen from '../Screen/Setting/MultiAccountLoginScreen'
import ForgotUIDPasswordScreen from '../Screen/Login/ForgotUIDPasswordScreen'
import PragmaticGameScreen from '../Screen/Discover/PragmaticGameScreen'
import PlaytechSlotGameScreen from '../Screen/Discover/PlaytechSlotGameScreen'
import UsdtReceiptScreen from '../Screen/Payment/UsdtReceiptScreen'
import RewardScreen from '../Screen/Payment/RewardScreen'
import QRSignUpScreen from '../Screen/Login/QRSignUpScreen'
import FunHouseGameScreen from '../Screen/Discover/FunHouseGameScreen'
import CurrencyExchangeScreen from '../Screen/Payment/CurrencyExchangeScreen'
import Plus100GameScreen from '../Screen/Discover/Plus100GameScreen'
import QRTopUpScreen from '../Screen/Payment/QRTopupScreen'
import PGSGameScreen from '../Screen/Discover/PGSGameScreen'
import CountrySignUpScreen from '../Screen/Login/CountrySignUpScreen'
// import { AnalyticEvents, logMessageAnalytics } from '../Utils/Analytics'
import KingKongGameScreen from '../Screen/Discover/KingKongGameScreen'
import LeaderScreen from '../Screen/Discover/LeaderScreen'
import JDBFishGameScreen from '../Screen/Discover/JDBFishGameScreen'
import SpadeFishGameScreen from '../Screen/Discover/SpadeFishGameScreen'
import YLFishGameScreen from '../Screen/Discover/YLFishGameScreen'
import ContactChatScreen from '../Screen/ContactChat/ContactChatScreen'
// import MessagesScreen from '../Screen/ContactChat/MessagesScreen'
// import SexyPragmaticGameScreen from '../Screen/Discover/SexyPragmaticGameList'
// import SexyRedTigerGameScreen from '../Screen/Discover/SexyRedTigerGameList'
// import SexyPGGameScreen from '../Screen/Discover/SexyPGGamelList'
// import SexyJiliGameScreen from '../Screen/Discover/SexyJiliGameList'
import CouponListScreen from '../Screen/Payment/CouponListScreen'
// import KingMakerGameListScreen from '../Screen/Discover/KingmakerGameList'
import VIPScreen from '../Screen/Profile/VIPScreen'
//import CouponEgamesScreen from '../Screen/Coupon/CouponEgamesScreen'
import CouponVipScreen from '../Screen/Coupon/CouponVIPScreen'
// import NextSpinGameScreen from '../Screen/Discover/NextSpinGameScreen'
import AESlotGameScreen from '../Screen/Discover/AESlotGameScreen'
import TransactionOTPScreen from '../Screen/Payment/TransactionOTPScreen'
// import CrowdPlayGameScreen from '../Screen/Discover/CrowdPlayGameScreen'
// import { configureAxiosDefaults } from '../API/axiosDefault'
import BannerDetailScreen from '../Screen/Discover/BannerDetailScreen'
import AESexyGameScreen from '../Screen/Discover/AESexyGameScreen'
import linking from './Linking'
import GiftDetailScreen from '../Screen/Discover/GiftDetailScreen'
// import LKYSlotGameScreen from '../Screen/Discover/LKYSlotGameScreen'
import LuckytownCoinScreen from '../Screen/Discover/LuckytownCoinScreen'
import WithdrawUSDTScreen from '../Screen/Payment/WithdrawUSDTScreen'
import WithdrawUSDTReceiptScreen from '../Screen/Payment/WithdrawUSDTReceiptScreen'
import LuckyCoinRedmeeScreen from '../Screen/Discover/LuckyCoinRedmeeScreen'
import GoogleAuthenticateScreen from '../Screen/Setting/GoogleAuthenticateScreen'
import GoogleAuthVerificationScreen from '../Screen/Login/GoogleAuthVerificationScreen'
// import ChangeAppIcon from '../Screen/Setting/ChangeAppIcon'
// import { enableScreens } from 'react-native-screens'
// import messaging from '@react-native-firebase/messaging'
import RealnamVerificationScreen from '../Screen/Setting/RealnameVerificationScreen'
import NotificationDetailScreen from '../Screen/Notification/NotificationDetailScreen'
import MyToast from '../Component/Toast/MyToast'
import { requestUserPermission } from '../../firebaseConfig'
import { HeightDimen } from '../UI/Dimensions'
import { MainColor } from '../UI/Color'
import MyData from '../Data/MyData'
import CreatePinVerificationScreen from '../Screen/Pin/CreatePinVerificationScreen'
import TopUpWithdrawScreen from '../Screen/Payment/TopupWithdrawScreen'

import { useFonts } from 'expo-font'
const Stack = createNativeStackNavigator()

class App extends Component {
  state = {
    isLoading: true,
    isInfo: true,
    isLogin: false,
    isCreatePin: true,
    installingUpdate: false,
    isUpdateModal: false,
    isMaintenance: false,
    notificaition: '',
    progress: 0,
  }

  constructor(props) {
    super(props)
    this.homeRef = React.createRef()
    this.navigationRef = React.createRef()
    //activateKeepAwake()
    //  enableScreens()
  }

  async componentDidMount() {
    // await this.configureUXCam();
    // await configureAxiosDefaults();
    // LogBox.ignoreAllLogs()
    // await handleOrientation(false)
    // CodePush.notifyAppReady()
    // const update = await CodePush.checkForUpdate()
    // if (update) {
    //   logMessageAnalytics(AnalyticEvents.UpdateAppModalOpen)
    //   this.setState({
    //     isUpdateModal: true,
    //   })
    // } else {
    //   this.setState({
    //     isUpdateModal: false,
    //   })
    // }
    // await this.registerServiceWorker()

    debugger
    await requestUserPermission()
  }

  codePushStatusDidChange = (syncStatus) => {
    switch (syncStatus) {
      case CodePush.SyncStatus.CHECKING_FOR_UPDATE:
        this.setState({
          syncMessage: 'Checking for update.',
          installingUpdate: true,
        })
        logMessageAnalytics(AnalyticEvents.CodePushCheckingForUpdate)
        break

      case CodePush.SyncStatus.DOWNLOADING_PACKAGE:
        this.setState({
          syncMessage: 'Downloading package.',
          installingUpdate: true,
        })
        logMessageAnalytics(AnalyticEvents.CodePushDownloadPackage)
        break

      case CodePush.SyncStatus.AWAITING_USER_ACTION:
        this.setState({
          syncMessage: 'Awaiting user action.',
          installingUpdate: true,
        })
        logMessageAnalytics(AnalyticEvents.CodePushAwaitingUserAction)
        break

      case CodePush.SyncStatus.INSTALLING_UPDATE:
        this.setState({
          syncMessage: 'Installing update.',
          installingUpdate: true,
        })
        logMessageAnalytics(AnalyticEvents.CodePushInstallingupdate)
        break

      case CodePush.SyncStatus.UP_TO_DATE:
        this.setState({
          syncMessage: 'App is updated.',
          installingUpdate: false,
          isUpdateModal: false,
        })
        logMessageAnalytics(AnalyticEvents.CodePushAppUpdated)
        break

      case CodePush.SyncStatus.UPDATE_IGNORED:
        this.setState({
          syncMessage: 'Update cancelled by user.',
          installingUpdate: false,

          isUpdateModal: false,
        })
        logMessageAnalytics(AnalyticEvents.CodePushUpdatedCancel)
        break
      case CodePush.SyncStatus.UPDATE_INSTALLED:
        this.setState({
          syncMessage: 'Update installed and will be applied on restart.',
          installingUpdate: false,
          isUpdateModal: false,
        })
        logMessageAnalytics(
          AnalyticEvents.CodePushUpdatedInstallAndAppliedOnRestart
        )
        break

      case CodePush.SyncStatus.UNKNOWN_ERROR:
        this.setState({
          syncMessage: 'An unknown error occurred.',
          isUpdateModal: false,
          installingUpdate: false,
        })
        logMessageAnalytics(AnalyticEvents.CodePushUnknownError)
        break
    }
  } /** Update pops a confirmation dialog, and then immediately reboots the app */
  codePushDownloadDidProgress = (event) => {
    this.setState({
      progress: (event.receivedBytes / event.totalBytes).toFixed(2) * 100,
    })
  }
  async sync() {
    CodePush.sync(
      { installMode: CodePush.InstallMode.IMMEDIATE, updateDialog: false },

      this.codePushStatusDidChange,
      this.codePushDownloadDidProgress
    )
    CodePush.allowRestart()
  }
  codePushDownloadDidProgress = (event) => {
    this.setState({
      progress: (event.receivedBytes / event.totalBytes).toFixed(2) * 100,
    })
  }
  // renderUpdateNotificationModal() {
  //   return (
  //     <Modal
  //       animationType={'slide'}
  //       transparent={true}
  //       visible={this.state.isUpdateModal}
  //       statusBarTranslucent
  //       onRequestClose={() => {}}
  //     >
  //       <View
  //         style={{
  //           flex: 1,
  //           backgroundColor: 'rgba(0,0,0,0.5)',
  //           justifyContent: 'center',
  //           alignItems: 'center',
  //         }}
  //       >
  //         <View
  //           style={{
  //             backgroundColor: 'white',
  //             padding: HeightDimen(20),
  //             marginHorizontal: HeightDimen(20),
  //             borderRadius: HeightDimen(5),
  //           }}
  //         >
  //           <Text
  //             style={{
  //               fontSize: fontDimen(20),
  //               textAlign: 'center',
  //               fontWeight: 'bold',
  //               color: FontMainColor,
  //             }}
  //           >
  //             {strings.newVersionTitle}
  //           </Text>
  //           <LottieView
  //             source={require('../Resources/LottieIcons/download.json')}
  //             // progress={progress}
  //             style={{ height: HeightDimen(100), alignSelf: 'center' }}
  //             autoPlay
  //             loop
  //           />

  //           <Text
  //             style={{
  //               fontSize: fontDimen(17),
  //               textAlign: 'center',
  //               marginTop: HeightDimen(10),
  //               color: FontMainColor,
  //             }}
  //           >
  //             {strings.versionDescription}
  //           </Text>
  //           <View
  //             style={{
  //               marginTop: HeightDimen(40),
  //               // justifyContent: "center",
  //               // flexDirection: "row",
  //             }}
  //           >
  //             {!this.state.installingUpdate ? (
  //               <TextButton
  //                 onPress={() => this.sync()}
  //                 label={strings.update_now}
  //                 size={5}
  //               />
  //             ) : (
  //               <>
  //                 <Text
  //                   style={{
  //                     fontSize: fontDimen(14),
  //                     textAlign: 'center',
  //                     marginBottom: HeightDimen(10),
  //                     color: FontMainColor,
  //                   }}
  //                 >
  //                   {this.state.progress.toFixed(1)}%
  //                 </Text>
  //                 <Progress colorScheme="light" value={this.state.progress} />
  //               </>
  //               // <ActivityIndicator size="large" color={MainColor} />
  //             )}
  //           </View>
  //         </View>
  //       </View>
  //     </Modal>
  //   )
  // }
  isLoading_Ready = (loginState) => {
    if (loginState == false) {
      this.setState({
        isLoading: false,
        isLogin: false,
        isCreatePin: false,
      })
    } else {
      this.setState({
        isLoading: false,
        isInfo: false,
        isCreatePin: false,
        isLogin: true,
      })
    }
  }

  isLogin_Ready = () => {
    this.setState({ isCreatePin: false })
    this.setState({ isLogin: true })

    //this.setState({isCreatePin : true});
  }

  isLogin_Ready_set_pin = () => {
    this.setState({ isCreatePin: true })
  }

  isLogin_Ready_info = () => {
    this.setState({ isLogin: true })
    this.setState({ isInfo: !this.state.isInfo })
    // this.setState({ isCreatePin: true });
  }

  isInfo_Ready = () => {
    this.setState({ isInfo: !this.state.isInfo })
    this.setState({ isCreatePin: false })
  }

  isLogout_Ready = () => {
    this.setState({ isLogin: false })
  }
  emptyAuthSessionLogout = () => {
    this.setState({ isLogin: false })

    MyToast.popToast('Please log in first.')
  }

  isRegisterNontification = async () => {
    await this.registerNotificationListners()
  }
  isCreatePin_Ready = () => {}

  expiredToken_logout = () => {
    this.setState({ isLogin: false })
    MyToast.popToast('Expired session, please log in again.')
  }
  isMaintenance_Ready = (status) => {
    this.setState({ isMaintenance: status, isLoading: false })
  }

  render() {
    if (this.state.isLoading) {
      return (
        <SplashScreen
          isLoading_Ready={this.isLoading_Ready}
          isMaintenance_Ready={this.isMaintenance_Ready}
          // isReady_PushNotification={this.isRegisterNontification}
        />
      )
    } else {
      return (
        <NativeBaseProvider>
          <NavigationContainer ref={this.navigationRef} linking={linking}>
            <Box
              style={{
                height: '100%',
                width: '100%',
                backgroundColor: MainColor,
              }}
            >
              <Box
                style={{
                  height: '100%',
                  width: MyData.isMobile ? '100%' : '60%',
                  alignSelf: 'center',
                }}
              >
                {this.state.isMaintenance ? (
                  <Stack.Navigator screenOptions={{ headerShown: false }}>
                    <Stack.Screen name="Maintenance">
                      {(props) => <MaintenanceScreen {...props} />}
                    </Stack.Screen>
                    <Stack.Screen name="ContactUs">
                      {(props) => <ContactUsScreen {...props} />}
                    </Stack.Screen>
                    {/* <Stack.Screen name="Info">
                  {(props) => (
                    <InfoScreen {...props} isInfo_Ready={this.isInfo_Ready} />
                  )}
                </Stack.Screen>
                <Stack.Screen name="QRCodes">
                  {(props) => <QRCodeScreen {...props} isFromInfo={true} />}
                </Stack.Screen>
                <Stack.Screen name="OTPs">
                  {(props) => (
                    <OTPScreen
                      {...props}
                      isLogin_Ready_info={this.isLogin_Ready_info}
                      isFromInfo={true}
                    />
                  )}
                </Stack.Screen>
                <Stack.Screen name="QRSignUps">
                  {(props) => <QRSignUpScreen {...props} isFromInfo={true} />}
                </Stack.Screen>
                <Stack.Screen name="SignUps">
                  {(props) => (
                    <SignUpScreen
                      {...props}
                      isInfo_Ready={this.isInfo_Ready}
                      isFromInfo={true}
                    />
                  )}
                </Stack.Screen>
                <Stack.Screen name="ContactUs">
                  {(props) => <ContactUsScreen {...props} />}
                </Stack.Screen> */}
                  </Stack.Navigator>
                ) : this.state.isCreatePin ? (
                  <Stack.Navigator screenOptions={{ headerShown: false }}>
                    <Stack.Screen name="CreatePin">
                      {(props) => <CreatePinScreen {...props} />}
                    </Stack.Screen>
                    <Stack.Screen name="CreatePinSecond">
                      {(props) => (
                        <CreatePinSecondScreen
                          {...props}
                          isLogin_Ready={this.isLogin_Ready}
                        />
                      )}
                    </Stack.Screen>
                  </Stack.Navigator>
                ) : !this.state.isLogin ? (
                  <Stack.Navigator screenOptions={{ headerShown: false }}>
                    <Stack.Screen name="Login">
                      {(props) => (
                        <LoginScreen
                          {...props}
                          isLogin_Ready={this.isLogin_Ready}
                          isLogin_Ready_set_pin={this.isLogin_Ready_set_pin}
                          isMaintenance_Ready={this.isMaintenance_Ready}
                          //  isReady_PushNotification={this.isRegisterNontification}
                        />
                      )}
                    </Stack.Screen>

                    <Stack.Screen name="QRCode">
                      {(props) => <QRCodeScreen {...props} />}
                    </Stack.Screen>

                    <Stack.Screen name="QRSignUp">
                      {(props) => <QRSignUpScreen {...props} />}
                    </Stack.Screen>

                    <Stack.Screen name="CountrySignUp">
                      {(props) => <CountrySignUpScreen {...props} />}
                    </Stack.Screen>
                    <Stack.Screen name="SignUp">
                      {(props) => (
                        <SignUpScreen
                          {...props}
                          isLogin_Ready_set_pin={this.isLogin_Ready_set_pin}
                          isLogin_Ready={this.isLogin_Ready}
                        />
                      )}
                    </Stack.Screen>
                    <Stack.Screen name="Recover">
                      {(props) => <RecoverPasswordScreen {...props} />}
                    </Stack.Screen>
                    <Stack.Screen name="RecoverUsername">
                      {(props) => <RecoverUsernameScreen {...props} />}
                    </Stack.Screen>
                    <Stack.Screen name="ForgotUIDPassword">
                      {(props) => <ForgotUIDPasswordScreen {...props} />}
                    </Stack.Screen>
                    <Stack.Screen name="RecoverUsers">
                      {(props) => (
                        <RecoverUsersScreen
                          {...props}
                          isLogout_Ready={this.isLogout_Ready}
                        />
                      )}
                    </Stack.Screen>
                    <Stack.Screen name="Change">
                      {(props) => <ChangePasswordScreen {...props} />}
                    </Stack.Screen>
                    <Stack.Screen name="OTP">
                      {(props) => (
                        <OTPScreen
                          {...props}
                          isLogin_Ready={this.isLogin_Ready}
                          isLogin_Ready_info={this.isLogin_Ready_info}
                          isLogin_Ready_set_pin={this.isLogin_Ready_set_pin}
                        />
                      )}
                    </Stack.Screen>
                    <Stack.Screen name="ContactUs">
                      {(props) => <ContactUsScreen {...props} />}
                    </Stack.Screen>
                    <Stack.Screen name="Succ">
                      {(props) => <SuccessfulChangePasswordScreen {...props} />}
                    </Stack.Screen>
                    <Stack.Screen name="Verification">
                      {(props) => (
                        <VerificationScreen
                          {...props}
                          isLogin_Ready={this.isLogin_Ready}
                          isLogin_Ready_set_pin={this.isLogin_Ready_set_pin}
                        />
                      )}
                    </Stack.Screen>
                    <Stack.Screen name="GoogleAuthVerification">
                      {(props) => (
                        <GoogleAuthVerificationScreen
                          {...props}
                          isLogin_Ready={this.isLogin_Ready}
                          isLogin_Ready_set_pin={this.isLogin_Ready_set_pin}
                          isMaintenance_Ready={this.isMaintenance_Ready}
                        />
                      )}
                    </Stack.Screen>
                    <Stack.Screen name="OTPs">
                      {(props) => (
                        <OTPScreen
                          {...props}
                          isLogin_Ready={this.isLogin_Ready}
                          isLogin_Ready_info={this.isLogin_Ready_info}
                          isFromInfo={true}
                        />
                      )}
                    </Stack.Screen>
                    <Stack.Screen name="Maintenance">
                      {(props) => <MaintenanceScreen {...props} />}
                    </Stack.Screen>
                  </Stack.Navigator>
                ) : (
                  <Stack.Navigator screenOptions={{ headerShown: false }}>
                    <Stack.Screen name="Home">
                      {(props) => (
                        <HomeScreen
                          {...props}
                          ref={this.homeRef}
                          expiredToken_logout={this.expiredToken_logout}
                          empty_auth_session_logout={
                            this.emptyAuthSessionLogout
                          }
                        />
                      )}
                    </Stack.Screen>

                    <Stack.Screen name="LeaderBoard">
                      {(props) => (
                        <LeaderBoardScreen
                          {...props}
                          expiredToken_logout={this.expiredToken_logout}
                        />
                      )}
                    </Stack.Screen>
                    <Stack.Screen name="CoinHistory">
                      {(props) => (
                        <CoinHistoryScreen
                          {...props}
                          expiredToken_logout={this.expiredToken_logout}
                        />
                      )}
                    </Stack.Screen>
                    <Stack.Screen name="InterestHistory">
                      {(props) => (
                        <InterestHistoryScreen
                          {...props}
                          expiredToken_logout={this.expiredToken_logout}
                        />
                      )}
                    </Stack.Screen>
                    <Stack.Screen name="EditInfo">
                      {(props) => <EditInfoScreen {...props} />}
                    </Stack.Screen>
                    <Stack.Screen
                      name="GameDetails"
                      options={{ gestureEnabled: false }}
                    >
                      {(props) => (
                        <GameDetailsScreen
                          {...props}
                          expiredToken_logout={this.expiredToken_logout}
                        />
                      )}
                    </Stack.Screen>
                    <Stack.Screen name="Wallet">
                      {(props) => (
                        <WalletScreen
                          {...props}
                          expiredToken_logout={this.expiredToken_logout}
                        />
                      )}
                    </Stack.Screen>
                    <Stack.Screen name="Transaction">
                      {(props) => (
                        <TransactionScreen
                          {...props}
                          expiredToken_logout={this.expiredToken_logout}
                        />
                      )}
                    </Stack.Screen>
                    <Stack.Screen name="OrderHistory">
                      {(props) => <OrderHistoryScreen {...props} />}
                    </Stack.Screen>
                    <Stack.Screen name="BankAcc">
                      {(props) => (
                        <BankAccScreen
                          {...props}
                          expiredToken_logout={this.expiredToken_logout}
                        />
                      )}
                    </Stack.Screen>
                    <Stack.Screen name="AddBank">
                      {(props) => (
                        <AddBankScreen
                          {...props}
                          expiredToken_logout={this.expiredToken_logout}
                        />
                      )}
                    </Stack.Screen>
                    <Stack.Screen name="Setting">
                      {(props) => (
                        <SettingScreen
                          {...props}
                          isLogout_Ready={this.isLogout_Ready}
                        />
                      )}
                    </Stack.Screen>
                    <Stack.Screen name="Equiqment">
                      {(props) => <EquiqmentManagementScreen {...props} />}
                    </Stack.Screen>
                    <Stack.Screen name="TopUp">
                      {(props) => (
                        <TopUpScreen
                          {...props}
                          expiredToken_logout={this.expiredToken_logout}
                        />
                      )}
                    </Stack.Screen>
                    <Stack.Screen name="Camera">
                      {(props) => <CameraScreen {...props} />}
                    </Stack.Screen>
                    <Stack.Screen name="Withdraw">
                      {(props) => (
                        <WithdrawScreen
                          {...props}
                          expiredToken_logout={this.expiredToken_logout}
                        />
                      )}
                    </Stack.Screen>
                    <Stack.Screen name="EditBank">
                      {(props) => (
                        <EditBankAccScreen
                          {...props}
                          expiredToken_logout={this.expiredToken_logout}
                        />
                      )}
                    </Stack.Screen>
                    <Stack.Screen name="AllBalance">
                      {(props) => <AllBalanceScreen {...props} />}
                    </Stack.Screen>
                    <Stack.Screen name="Interest">
                      {(props) => (
                        <InterestScreen
                          {...props}
                          expiredToken_logout={this.expiredToken_logout}
                        />
                      )}
                    </Stack.Screen>
                    <Stack.Screen name="CreateInterest">
                      {(props) => (
                        <CreateInterestScreen
                          {...props}
                          expiredToken_logout={this.expiredToken_logout}
                        />
                      )}
                    </Stack.Screen>
                    <Stack.Screen name="InterestDetails">
                      {(props) => (
                        <InterestDetailsScreen
                          {...props}
                          expiredToken_logout={this.expiredToken_logout}
                        />
                      )}
                    </Stack.Screen>
                    <Stack.Screen name="PartialWithdraw">
                      {(props) => (
                        <PartialWithdrawScreen
                          {...props}
                          expiredToken_logout={this.expiredToken_logout}
                        />
                      )}
                    </Stack.Screen>
                    <Stack.Screen name="About">
                      {(props) => <AboutScreen {...props} />}
                    </Stack.Screen>
                    <Stack.Screen name="ChangeLoginPassword">
                      {(props) => <ChangeLoginPasswordScreen {...props} />}
                    </Stack.Screen>

                    <Stack.Screen name="ChangePin">
                      {(props) => <ChangePinScreen {...props} />}
                    </Stack.Screen>
                    <Stack.Screen name="SuccChangePin">
                      {(props) => <SuccChangePinScreen {...props} />}
                    </Stack.Screen>
                    <Stack.Screen name="RecoverPin">
                      {(props) => <RecoverPinScreen {...props} />}
                    </Stack.Screen>
                    <Stack.Screen name="ChangePinOTP">
                      {(props) => <ChangePinOTPScreen {...props} />}
                    </Stack.Screen>
                    <Stack.Screen name="SuccChangePinOTP">
                      {(props) => <SuccChangePinOTPScreen {...props} />}
                    </Stack.Screen>

                    <Stack.Screen name="Language">
                      {(props) => <LanguageScreen {...props} />}
                    </Stack.Screen>
                    <Stack.Screen name="AboutPhone">
                      {(props) => <AboutPhoneScreen {...props} />}
                    </Stack.Screen>
                    <Stack.Screen name="SuccChangeLoginPassword">
                      {(props) => <SuccChangeLoginPassword {...props} />}
                    </Stack.Screen>
                    <Stack.Screen
                      name="LaunchGame"
                      options={{ gestureEnabled: false }}
                    >
                      {(props) => <LaunchGameScreen {...props} />}
                    </Stack.Screen>

                    <Stack.Screen name="TransferCamera">
                      {(props) => <TransferCameraScreen {...props} />}
                    </Stack.Screen>
                    <Stack.Screen name="TransferAuto">
                      {(props) => (
                        <TransferAutoScreen
                          {...props}
                          expiredToken_logout={this.expiredToken_logout}
                        />
                      )}
                    </Stack.Screen>
                    <Stack.Screen name="TransferManual">
                      {(props) => (
                        <TransferManualScreen
                          {...props}
                          expiredToken_logout={this.expiredToken_logout}
                        />
                      )}
                    </Stack.Screen>

                    <Stack.Screen name="ChangeNickName">
                      {(props) => (
                        <ChangeNickNameScreen
                          {...props}
                          expiredToken_logout={this.expiredToken_logout}
                        />
                      )}
                    </Stack.Screen>
                    <Stack.Screen name="CustomerService">
                      {(props) => <CustomerServiceScreen {...props} />}
                    </Stack.Screen>

                    <Stack.Screen name="AllWalletTransaction">
                      {(props) => (
                        <AllWalletTransactionScreen
                          {...props}
                          expiredToken_logout={this.expiredToken_logout}
                        />
                      )}
                    </Stack.Screen>
                    <Stack.Screen name="AllGameTransaction">
                      {(props) => (
                        <AllGameTransactionScreen
                          {...props}
                          expiredToken_logout={this.expiredToken_logout}
                        />
                      )}
                    </Stack.Screen>

                    <Stack.Screen name="AllWalletTransactionDetail">
                      {(props) => (
                        <AllWalletTransactionDetailScreen {...props} />
                      )}
                    </Stack.Screen>
                    <Stack.Screen name="AllWalletTransactionAdditionalDetail">
                      {(props) => (
                        <AllWalletTransactionAdditionalDetailScreen
                          {...props}
                        />
                      )}
                    </Stack.Screen>
                    <Stack.Screen name="GameDetailsTransactionScreen">
                      {(props) => <GameDetailsTransactionScreen {...props} />}
                    </Stack.Screen>

                    <Stack.Screen name="TopUpDetailsScreen">
                      {(props) => (
                        <TopUpHistoryDetailsScreen
                          {...props}
                          expiredToken_logout={this.expiredToken_logout}
                        />
                      )}
                    </Stack.Screen>

                    <Stack.Screen name="WithdrawHistoryDetailsScreen">
                      {(props) => (
                        <WithdrawHistoryDetailsScreen
                          {...props}
                          expiredToken_logout={this.expiredToken_logout}
                        />
                      )}
                    </Stack.Screen>

                    <Stack.Screen name="InstantTopUp">
                      {(props) => <InstantTopUpScreen {...props} />}
                    </Stack.Screen>
                    <Stack.Screen name="Coupon">
                      {(props) => <CouponListScreen {...props} />}
                    </Stack.Screen>
                    <Stack.Screen name="CouponEgames">
                      {(props) => <CouponEgamesScreen {...props} />}
                    </Stack.Screen>
                    <Stack.Screen name="CouponVip">
                      {(props) => <CouponVipScreen {...props} />}
                    </Stack.Screen>
                    <Stack.Screen name="AnouncementScreen">
                      {(props) => (
                        <AnouncementScreen
                          {...props}
                          expiredToken_logout={this.expiredToken_logout}
                        />
                      )}
                    </Stack.Screen>
                    <Stack.Screen name="PaymentSuccessScreen">
                      {(props) => <PaymentSuccessScreen {...props} />}
                    </Stack.Screen>
                    <Stack.Screen name="Xe88GameDetails">
                      {(props) => <Xe88GameDetailsScreen {...props} />}
                    </Stack.Screen>
                    <Stack.Screen name="VPowerGame">
                      {(props) => <VPowerGameScreen {...props} />}
                    </Stack.Screen>
                    <Stack.Screen name="PragmaticGame">
                      {(props) => <PragmaticGameScreen {...props} />}
                    </Stack.Screen>
                    <Stack.Screen name="AnouncementDetail">
                      {(props) => <AnouncementDetailScreen {...props} />}
                    </Stack.Screen>
                    <Stack.Screen name="PlayTechGame">
                      {(props) => <PlayTechGameScreen {...props} />}
                    </Stack.Screen>
                    <Stack.Screen name="PlayTechLaunchGame">
                      {(props) => <PlayTechLaunchGameScreen {...props} />}
                    </Stack.Screen>
                    <Stack.Screen name="PlayTechSlotGame">
                      {(props) => <PlaytechSlotGameScreen {...props} />}
                    </Stack.Screen>
                    <Stack.Screen name="JokerGame">
                      {(props) => <JokerGameScreen {...props} />}
                    </Stack.Screen>
                    <Stack.Screen name="SexyPragamaticGame">
                      {(props) => <SexyPragmaticGameScreen {...props} />}
                    </Stack.Screen>
                    <Stack.Screen name="SexyRedTigerGame">
                      {(props) => <SexyRedTigerGameScreen {...props} />}
                    </Stack.Screen>
                    <Stack.Screen name="SexyPGGame">
                      {(props) => <SexyPGGameScreen {...props} />}
                    </Stack.Screen>
                    <Stack.Screen name="SexyJiliGame">
                      {(props) => <SexyJiliGameScreen {...props} />}
                    </Stack.Screen>
                    <Stack.Screen name="KingMakerGame">
                      {(props) => <KingMakerGameListScreen {...props} />}
                    </Stack.Screen>
                    <Stack.Screen name="UsdtTopUp">
                      {(props) => (
                        <UsdtTopupScreen
                          {...props}
                          expiredToken_logout={this.expiredToken_logout}
                        />
                      )}
                    </Stack.Screen>
                    <Stack.Screen name="Notification">
                      {(props) => <NotificationScreen {...props} />}
                    </Stack.Screen>
                    <Stack.Screen name="PromotionDetail">
                      {(props) => (
                        <PromotionDetailScreen
                          {...props}
                          expiredToken_logout={this.expiredToken_logout}
                        />
                      )}
                    </Stack.Screen>
                    <Stack.Screen name="ChangePhoneNumber">
                      {(props) => (
                        <ChangePhoneNumberScreen
                          {...props}
                          expiredToken_logout={this.expiredToken_logout}
                        />
                      )}
                    </Stack.Screen>
                    <Stack.Screen name="MultiLogin">
                      {(props) => <MultiAccountLoginScreen {...props} />}
                    </Stack.Screen>
                    <Stack.Screen name="MultiLoginOTP">
                      {(props) => (
                        <OTPScreen
                          {...props}
                          isLogin_Ready={this.isLogin_Ready}
                          isLogin_Ready_info={this.isLogin_Ready_info}
                          isLogin_Ready_set_pin={this.isLogin_Ready_set_pin}
                        />
                      )}
                    </Stack.Screen>
                    <Stack.Screen name="MultiLoginVerification">
                      {(props) => (
                        <VerificationScreen
                          {...props}
                          isLogin_Ready={this.isLogin_Ready}
                          isLogin_Ready_set_pin={this.isLogin_Ready_set_pin}
                        />
                      )}
                    </Stack.Screen>
                    <Stack.Screen name="UsdtReceipt">
                      {(props) => (
                        <UsdtReceiptScreen
                          {...props}
                          expiredToken_logout={this.expiredToken_logout}
                        />
                      )}
                    </Stack.Screen>
                    <Stack.Screen name="Reward">
                      {(props) => (
                        <RewardScreen
                          {...props}
                          expiredToken_logout={this.expiredToken_logout}
                        />
                      )}
                    </Stack.Screen>
                    <Stack.Screen name="FunHouseGame">
                      {(props) => (
                        <FunHouseGameScreen
                          {...props}
                          expiredToken_logout={this.expiredToken_logout}
                        />
                      )}
                    </Stack.Screen>
                    <Stack.Screen name="CurrencyExchange">
                      {(props) => (
                        <CurrencyExchangeScreen
                          {...props}
                          expiredToken_logout={this.expiredToken_logout}
                        />
                      )}
                    </Stack.Screen>
                    <Stack.Screen name="Plus100Game">
                      {(props) => (
                        <Plus100GameScreen
                          {...props}
                          expiredToken_logout={this.expiredToken_logout}
                        />
                      )}
                    </Stack.Screen>
                    <Stack.Screen name="QRTopup">
                      {(props) => (
                        <QRTopUpScreen
                          {...props}
                          expiredToken_logout={this.expiredToken_logout}
                        />
                      )}
                    </Stack.Screen>
                    <Stack.Screen name="PGSGame">
                      {(props) => (
                        <PGSGameScreen
                          {...props}
                          expiredToken_logout={this.expiredToken_logout}
                        />
                      )}
                    </Stack.Screen>

                    <Stack.Screen name="KingKongGame">
                      {(props) => (
                        <KingKongGameScreen
                          {...props}
                          expiredToken_logout={this.expiredToken_logout}
                        />
                      )}
                    </Stack.Screen>
                    <Stack.Screen name="Leader">
                      {(props) => (
                        <LeaderScreen
                          {...props}
                          expiredToken_logout={this.expiredToken_logout}
                        />
                      )}
                    </Stack.Screen>
                    <Stack.Screen name="JDBFishGame">
                      {(props) => (
                        <JDBFishGameScreen
                          {...props}
                          expiredToken_logout={this.expiredToken_logout}
                        />
                      )}
                    </Stack.Screen>
                    <Stack.Screen name="SpadeFishGame">
                      {(props) => (
                        <SpadeFishGameScreen
                          {...props}
                          expiredToken_logout={this.expiredToken_logout}
                        />
                      )}
                    </Stack.Screen>
                    <Stack.Screen name="YLFishGame">
                      {(props) => (
                        <YLFishGameScreen
                          {...props}
                          expiredToken_logout={this.expiredToken_logout}
                        />
                      )}
                    </Stack.Screen>
                    <Stack.Screen name="ContactChat">
                      {(props) => (
                        <ContactChatScreen
                          {...props}
                          expiredToken_logout={this.expiredToken_logout}
                        />
                      )}
                    </Stack.Screen>
                    <Stack.Screen name="Messages">
                      {(props) => (
                        <MessagesScreen
                          {...props}
                          expiredToken_logout={this.expiredToken_logout}
                        />
                      )}
                    </Stack.Screen>
                    <Stack.Screen name="vip">
                      {(props) => (
                        <VIPScreen
                          {...props}
                          expiredToken_logout={this.expiredToken_logout}
                        />
                      )}
                    </Stack.Screen>
                    <Stack.Screen name="NextSpinGame">
                      {(props) => (
                        <NextSpinGameScreen
                          {...props}
                          expiredToken_logout={this.expiredToken_logout}
                        />
                      )}
                    </Stack.Screen>
                    <Stack.Screen name="AESlotGame">
                      {(props) => (
                        <AESlotGameScreen
                          {...props}
                          expiredToken_logout={this.expiredToken_logout}
                        />
                      )}
                    </Stack.Screen>
                    <Stack.Screen name="CrowdPlayGame">
                      {(props) => (
                        <CrowdPlayGameScreen
                          {...props}
                          expiredToken_logout={this.expiredToken_logout}
                        />
                      )}
                    </Stack.Screen>
                    <Stack.Screen name="BannerDetail">
                      {(props) => (
                        <BannerDetailScreen
                          {...props}
                          expiredToken_logout={this.expiredToken_logout}
                        />
                      )}
                    </Stack.Screen>
                    <Stack.Screen name="AESexyGame">
                      {(props) => (
                        <AESexyGameScreen
                          {...props}
                          expiredToken_logout={this.expiredToken_logout}
                        />
                      )}
                    </Stack.Screen>
                    <Stack.Screen name="TransactionOTP">
                      {(props) => (
                        <TransactionOTPScreen
                          {...props}
                          expiredToken_logout={this.expiredToken_logout}
                        />
                      )}
                    </Stack.Screen>
                    <Stack.Screen name="GiftDetail">
                      {(props) => (
                        <GiftDetailScreen
                          {...props}
                          expiredToken_logout={this.expiredToken_logout}
                        />
                      )}
                    </Stack.Screen>
                    <Stack.Screen name="LKYSlot">
                      {(props) => (
                        <LKYSlotGameScreen
                          {...props}
                          expiredToken_logout={this.expiredToken_logout}
                        />
                      )}
                    </Stack.Screen>
                    <Stack.Screen name="LuckytownCoin">
                      {(props) => (
                        <LuckytownCoinScreen
                          {...props}
                          expiredToken_logout={this.expiredToken_logout}
                        />
                      )}
                    </Stack.Screen>
                    <Stack.Screen name="WithdrawUsdt">
                      {(props) => (
                        <WithdrawUSDTScreen
                          {...props}
                          expiredToken_logout={this.expiredToken_logout}
                        />
                      )}
                    </Stack.Screen>
                    <Stack.Screen name="WithdrawUsdtReceipt">
                      {(props) => (
                        <WithdrawUSDTReceiptScreen
                          {...props}
                          expiredToken_logout={this.expiredToken_logout}
                        />
                      )}
                    </Stack.Screen>
                    <Stack.Screen name="LKYRedmee">
                      {(props) => (
                        <LuckyCoinRedmeeScreen
                          {...props}
                          expiredToken_logout={this.expiredToken_logout}
                        />
                      )}
                    </Stack.Screen>
                    <Stack.Screen name="GoogleAuthenticate">
                      {(props) => (
                        <GoogleAuthenticateScreen
                          {...props}
                          expiredToken_logout={this.expiredToken_logout}
                          isLogout_Ready={this.isLogout_Ready}
                        />
                      )}
                    </Stack.Screen>
                    <Stack.Screen name="AppIcon">
                      {(props) => (
                        <ChangeAppIcon
                          {...props}
                          expiredToken_logout={this.expiredToken_logout}
                          isLogout_Ready={this.isLogout_Ready}
                        />
                      )}
                    </Stack.Screen>
                    <Stack.Screen name="RealnameVerification">
                      {(props) => (
                        <RealnamVerificationScreen
                          {...props}
                          expiredToken_logout={this.expiredToken_logout}
                          isLogout_Ready={this.isLogout_Ready}
                        />
                      )}
                    </Stack.Screen>
                    <Stack.Screen name="NotificationDetail">
                      {(props) => (
                        <NotificationDetailScreen
                          {...props}
                          expiredToken_logout={this.expiredToken_logout}
                          isLogout_Ready={this.isLogout_Ready}
                        />
                      )}
                    </Stack.Screen>
                    <Stack.Screen name="GoogleAuthKeyVerification">
                      {(props) => (
                        <GoogleAuthVerificationScreen
                          {...props}
                          isLogin_Ready={this.isLogin_Ready}
                          isLogin_Ready_set_pin={this.isLogin_Ready_set_pin}
                          isMaintenance_Ready={this.isMaintenance_Ready}
                        />
                      )}
                    </Stack.Screen>
                    <Stack.Screen name="BonusDetail">
                      {(props) => (
                        <BannerDetailScreen
                          {...props}
                          expiredToken_logout={this.expiredToken_logout}
                        />
                      )}
                    </Stack.Screen>

                    <Stack.Screen name="CreatePinVerification">
                      {(props) => (
                        <CreatePinVerificationScreen
                          {...props}
                          expiredToken_logout={this.expiredToken_logout}
                        />
                      )}
                    </Stack.Screen>

                    <Stack.Screen name="TopupWithdraw">
                      {(props) => (
                        <TopUpWithdrawScreen
                          {...props}
                          expiredToken_logout={this.expiredToken_logout}
                        />
                      )}
                    </Stack.Screen>
                  </Stack.Navigator>
                )}
                {/* </View> */}
              </Box>
            </Box>
            {/* {this.renderUpdateNotificationModal()} */}
          </NavigationContainer>
        </NativeBaseProvider>
      )
    }
  }
}
const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 22,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalView: {
    margin: 20,
    backgroundColor: 'white',
    borderRadius: 20,
    alignItems: 'center',
    justifyContent: 'center',
    padding: 10,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    flex: 0.3,
    width: '95%',
  },
})

export default App
