import { View, Text } from "react-native";
import * as React from "react";
import { MainColor } from "../../UI/Color";
import { fontDimen, HeightDimen } from "../../UI/Dimensions";

const NumberBulletsText = ({ num }) => {
  return (
    <View
      style={{
        borderRadius: 12,
        height: HeightDimen(25),
        width: HeightDimen(25),
        backgroundColor: "white",
        justifyContent: "center",
        alignItems: "center",
        borderWidth: HeightDimen(1),
        borderColor: MainColor,
        marginBottom: HeightDimen(5),
      }}>
      <Text style={{ fontSize: fontDimen(12), fontWeight: "bold" }}>{num}</Text>
    </View>
  );
};
export { NumberBulletsText };
