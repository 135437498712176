import React, { Component } from "react";
import {
  View,
  FlatList,
  ActivityIndicator,
  Animated,
  Image,
} from "react-native";
import leaderBoardCaller from "../../API/LeaderBoardCaller";
import { GameCard } from "../../Component/Card/GameCard";
import { GameBackgroundHeaderContainer } from "../../Component/Header/BackgroundGameHeader";
import { GameSearchBar } from "../../Component/SearchBar/GameSearchBar";
import MyToast from "../../Component/Toast/MyToast";
import MyData from "../../Data/MyData";
import { MainColor } from "../../UI/Color";
import { HeightDimen } from "../../UI/Dimensions";
import {
  AnalyticEvents,
  eventMessages,
  logEventWithScreenName,
  logMessageAnalytics,
  logScreenViewAnalytics,
  screenNames,
} from "../../Utils/Analytics";
import { languageSelectedName } from "../../Utils/Common";

class AESexyGameScreen extends Component {
  state = {
    gameList: [],
    tempGameList: [],
    isLoading: true,
    scaleValue: new Animated.Value(0),
    title: this.props.route.params.title,
    logo: this.props.route.params.logo,
    productId: this.props.route.params.productId,
  };

  async componentDidMount() {
    logScreenViewAnalytics(screenNames.AESexyGameDetail);
    this.get_Game();
  }
  onBackPressed = () => {
    logEventWithScreenName(
      eventMessages.BackButton,
      screenNames.AESexyGameDetail
    );
    this.props.route.params.onGoBack();
    this.props.navigation.goBack();
  };
  animationImage = () => {
    Animated.timing(this.state.scaleValue, {
      duration: 500,
      toValue: 1,
      useNativeDriver: false,
    }).start();
  };

  get_Game = async () => {
    await leaderBoardCaller
      .getAeSexyGameList(MyData.authToken, this.state.productId)
      .then(res => {
        logMessageAnalytics(AnalyticEvents.AESexyGameSuccess);
        this.setState({
          gameList: res.response.gameList,
          tempGameList: res.response.gameList,
        });
        this.animationImage();
      })
      .catch(err => {
        logMessageAnalytics(
          AnalyticEvents.AESexyGameFailure,
          err?.response?.data?.msg
        );
        if (err.response.status == 514) {
          this.props.expiredToken_logout();
        } else {
          MyToast.popToast(err.response.data.msg);
        }
      })
      .finally(() => {
        this.setState({
          isLoading: false,
        });
      });
  };

  on_Refresh = () => {
    this.setState({ isLoading: true });
    this.get_Game();
  };

  onSearch = search => {
    const { gameList } = this.state;
    let filterList = gameList.filter(o => {
      return (
        o.en_name &&
        o.en_name
          .toUpperCase()
          .replace(/ /g, "")
          .includes(search.toUpperCase().replace(/ /g, ""))
      );
    });
    this.setState({
      tempGameList: filterList,
    });
    if (!search) this.searchTextAnimation();
  };

  searchTextAnimation() {
    Animated.timing(this.state.scaleValue, {
      duration: 100,
      toValue: 0.6,
      useNativeDriver: false,
    }).start(() => {
      Animated.timing(this.state.scaleValue, {
        duration: 50,
        toValue: 1,
        useNativeDriver: false,
      }).start();
    });
  }

  start_Game = async (gameName, code, category) => {
    this.setState({ isLoading: true });
    await leaderBoardCaller
      .launchAeSexyGame(MyData.authToken, code, this.state.productId, category)
      .then(res => {
        logMessageAnalytics(AnalyticEvents.AESexyLaunchSuccess);
        this.props.navigation.navigate("LaunchGame", {
          url: res?.response?.gameUrl,
          title: gameName,
          onGoBack: data => {
            this.on_Refresh(data);
          },
        });
      })
      .catch(err => {
        logMessageAnalytics(
          AnalyticEvents.AESexyLaunchFailure,
          err?.response?.data?.msg
        );
        if (err?.response?.status == 514) {
          this.props.expiredToken_logout();
        } else {
          MyToast.popToast(err?.response?.data?.msg);
        }
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  renderItemCard = ({ item }) => {
    return (
      <GameCard
        image={item.image_url}
        name={languageSelectedName(item.en_name, item.cn_name)}
        transform={{ transform: [{ scale: this.state.scaleValue }] }}
        onPress={() => this.start_Game(item.en_name, item.code, item.category)}
      />
    );
  };
  render() {
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: MainColor,
        }}>
        <GameBackgroundHeaderContainer
          source={require("../../Resources/Images/background.jpg")}
          title={""}
          onBackPress={() => this.onBackPressed()}>
          {this.state.isLoading ? (
            <View>
              <ActivityIndicator size="small" color={"white"} />
            </View>
          ) : (
            <View
              style={{
                flex: 1,
              }}>
              <Image
                style={{
                  width: HeightDimen(120),
                  height: HeightDimen(120),
                  resizeMode: "contain",
                  borderRadius: HeightDimen(10),
                  alignSelf: "center",
                  marginBottom: HeightDimen(10),
                }}
                source={{ uri: this.state.logo }}
              />
              <View style={{ marginHorizontal: HeightDimen(15) }}>
                <GameSearchBar
                  placeholder={"Search"}
                  onChangeText={val => this.onSearch(val)}
                />
              </View>
              <FlatList
                numColumns={3}
                style={{
                  marginTop: HeightDimen(10),
                  paddingHorizontal: HeightDimen(15),
                }}
                contentContainerStyle={{
                  width: "100%",
                }}
                data={this.state.tempGameList}
                onRefresh={() => this.on_Refresh()}
                refreshing={this.state.isLoading}
                renderItem={this.renderItemCard}
                keyExtractor={(item, index) => String(index)}
              />
            </View>
          )}
        </GameBackgroundHeaderContainer>
      </View>
    );
  }
}

export default AESexyGameScreen;
