class ProductData {
  comingSoonGames = []
  bannerList = []
  productCategory = []
  setComingSoonGames = (games) => {
    this.comingSoonGames = games
  }
}

export default new ProductData()
