import React, { useState, useEffect } from 'react'
import { View, StyleSheet, TouchableOpacity } from 'react-native'
import { MainFlowHeaderContainer } from '../../Component/Header/MainFlowHeader'
import chatCaller from '../../API/ChatAPICaller'
import MyData from '../../Data/MyData'
import MyToast from '../../Component/Toast/MyToast'
import Icon from 'react-native-vector-icons/MaterialIcons'
import { SecondaryColor } from '../../UI/Color'

import { strings } from '../../Language/Language'
import { io } from 'socket.io-client'
import { GiftedChat, Send, Composer } from 'react-native-gifted-chat'
const ContactChatScreen = (props) => {
  const [messages, setMessages] = useState([])
  const [selectedFriend, setSelectedFriend] = useState(null)

  useEffect(() => {
    configureSocketIO()
    setSelectedFriend(props.route.params?.selectedFriend)
    getMessageList(props.route.params?.selectedFriend)
  }, [])

  const configureSocketIO = () => {
    const socket = io('https://ws.lkyhub.xyz/')
    socket.connect()
    socket.on('new-chat-message', (data) => {
      if (
        data?.receiverUserId == MyData?.userToken &&
        data?.senderId == props.route.params?.selectedFriend?.member_unique_key
      ) {
        setMessages((previousMessages) =>
          GiftedChat.append(previousMessages, data?.messages)
        )
      }
    })
  }
  const getMessageList = async (friend) => {
    await chatCaller
      .getMessageList(MyData.authToken, friend.member_unique_key)
      .then((res) => {
        if (res?.response?.messages.length > 0) {
          setMessages(() => GiftedChat.append([], res?.response?.messages))
        }
      })
      .catch((err) => {
        if (err.response.status == 514) {
          this.props.expiredToken_logout()
        } else {
          MyToast.popToast(err?.response?.data?.msg)
        }
      })
  }

  const onSend = async (messages = []) => {
    const socket = io('https://ws.lkyhub.xyz/')
    setMessages((previousMessages) =>
      GiftedChat.append(previousMessages, messages)
    )
    socket.emit('new-chat-message', {
      messages,
      receiverUserId: selectedFriend?.member_unique_key,
      senderId: MyData?.userToken,
    })

    try {
      let payload = {
        receiverUserUniqueKey: selectedFriend?.member_unique_key,
        receiverUserType: 'member',
        message: messages[0]?.text,
        messageType: 'text',
      }
      await chatCaller.sendChatMessage(MyData.authToken, payload)
    } catch (error) {
      console.error(error)
    }
  }
  const onBackPress = () => {
    props.route.params.onGoBack()
    props.navigation.goBack()
  }
  // const renderSend = (sendProps) => {
  //   return (
  //     <Send {...sendProps}>
  //       <View
  //         style={{
  //           height: "100%",
  //           justifyContent: "center",
  //           marginRight: 10,
  //         }}
  //       >
  //         <Icon name={"send"} size={25} color={SecondaryColor} />
  //       </View>
  //     </Send>
  //   );
  // };
  const renderGiftedChatList = () => {
    return (
      <GiftedChat
        minComposerHeight={40}
        messages={messages}
        onSend={(messages) => onSend(messages)}
        minInputToolbarHeight={60}
        showAvatarForEveryMessage={true}
        renderComposer={(props) => (
          <Composer {...props} placeholder={strings.t('type_a_message')} />
        )}
        renderSend={(props) => {
          return (
            <Send {...props}>
              <View
                style={{
                  height: 50,
                  justifyContent: 'center',
                  marginRight: 10,
                  alignItems: 'center',
                }}
              >
                <Icon name={'send'} size={25} color={SecondaryColor} />
              </View>
            </Send>
          )
        }}
        user={{
          _id: MyData.userToken,
          name: MyData.member_username,
          avatar: MyData.avatar,
        }}
      />
    )
  }

  return (
    <View style={styles.container}>
      <MainFlowHeaderContainer
        title={selectedFriend?.member_username}
        onBackPress={() => onBackPress()}
      >
        <View style={{ flex: 1 }}>{renderGiftedChatList()}</View>
      </MainFlowHeaderContainer>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
})
export default ContactChatScreen
