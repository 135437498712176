import React, { Component } from 'react'
import {
  View,
  Keyboard,
  TouchableOpacity,
  Image,
  Text,
  ScrollView,
} from 'react-native'
import { WidthDimen, HeightDimen, fontDimen } from '../../UI/Dimensions'
import { LoginFlowHeaderContainer } from '../../Component/Header/LoginFlowHeader'
import { TextButton } from '../../Component/Button/TextButton'
import NumberPad from '../../Component/NumberPad/NumberPad'
import { OTPCard } from '../../Component/Card/OTPCard'
import APICaller from '../../API/APICaller'
import OTPRouteData from '../../Data/OTPRouteData'
import Mydata from '../../Data/MyData'
import MyToast from '../../Component/Toast/MyToast'
import { strings } from '../../Language/Language'
import { generateRandom, phoneRegex } from '../../Utils/Common'
import { PhoneTextInput } from '../../Component/Input/PhoneTextInput'
import storageData from '../../Data/StorageData'
import { FontSecondaryColor, SecondaryColor } from '../../UI/Color'

class OTPScreen extends Component {
  state = {
    OTP: '',
    isLoading: false,
    phoneNumber: '',
    showNumberPad: true,
    code: '',
    existingCode: '',
    otpPrefix: '',
  }

  componentDidMount() {
    this.setState({
      phoneNumber: this.props.route.params.phoneNumber,
      code: this.props.route.params.code,
    })
    //console.log(this.props.route.params.phoneNumber );

    this.keyboardDidShowListener = Keyboard.addListener(
      'keyboardDidShow',
      this._keyboardDidShow
    )
    this.keyboardDidHideListener = Keyboard.addListener(
      'keyboardDidHide',
      this._keyboardDidHide
    )
  }

  componentWillUnmount() {
    this.keyboardDidShowListener.remove()
    this.keyboardDidHideListener.remove()
  }

  handle_phoneNumber = (phone) => {
    this.setState({ phoneNumber: phone })
  }

  _keyboardDidShow = () => {
    this.setState({
      showNumberPad: false,
    })
  }

  _keyboardDidHide = () => {
    this.setState({
      showNumberPad: true,
    })
  }

  onBackPressed = () => {
    Mydata.clear_all_data()
    this.props.navigation.goBack()
  }

  onEnterOTP = (num) => {
    let temp_OTP = this.state.OTP

    if (num === 'X') {
      if (temp_OTP.length > 0) {
        temp_OTP = temp_OTP.slice(0, -1)
        this.setState({ OTP: temp_OTP })
      }
    } else {
      if (temp_OTP.length < 6) {
        temp_OTP = temp_OTP + num
        this.setState({ OTP: temp_OTP })
      }
    }
  }

  onResendOTP = async () => {
    if (this.state.phoneNumber) {
      let otpPrefix = generateRandom(4)
      this.setState({ otpPrefix: otpPrefix })
      await APICaller.SendRegisterOTP(
        Mydata.authToken,
        this.state.phoneNumber,
        this.state.code,
        otpPrefix
      )
        .then((res) => {
          MyToast.popToast(strings.t('otp_code_resended'))
        })
        .catch((err) => {
          console.log(err)
          MyToast.popToast(err.response.data.msg)
        })
    } else {
      MyToast.popToast(strings.t('please_enter_phone_number'))
    }
  }

  onNextPressed = async () => {
    if (OTPRouteData.isFlow_SignUp) {
      if (this.state.OTP.length == 6) {
        this.setState({ isLoading: true })
        await APICaller.sendOTP(Mydata.userToken, this.state.OTP)
          .then((res) => {
            this.setState({ isLoading: false })

            Mydata.setUp(res)
            OTPRouteData.set_clear_all()
            if (this.props.isFromInfo) {
              this.props?.isLogin_Ready_info()
            } else {
              this.props.isLogin_Ready_set_pin()
            }
          })
          .catch((err) => {
            this.setState({ isLoading: false })

            MyToast.popToast(err?.response?.data?.msg)
            //console.log(err)
          })
          .finally(() => {
            this.setState({ isLoading: false })
          })
      }
    } else {
      if (this.state.OTP.length == 6) {
        this.setState({ isLoading: true })
        await APICaller.sendOTP(Mydata.userToken, this.state.OTP)
          .then(async (res) => {
            this.setState({ isLoading: false })

            //console.log(res)
            Mydata.setUp(res)
            OTPRouteData.set_clear_all()
            if (res.response.user.pin_status == 'false') {
              this.props.isLogin_Ready_set_pin()
            } else {
              Mydata.setUserPin(res?.response?.user?.wallet_pin)
              this.props.isLogin_Ready()
            }
            if (this.props.route.params.isMultiLogin) {
              await storageData.store_multi_account_data(
                this.props.route.params.username,
                this.props.route.params.password,
                res.response.user.member_avatar_url,
                res.response.user.member_login_token
              )
            }
          })
          .catch((err) => {
            MyToast.popToast(strings.t('invalid_otp_code'))
            //console.log(err)
            this.setState({ isLoading: false })
          })
          .finally(() => {
            this.setState({ isLoading: false })
          })
      }
    }
  }

  hide_keyboard = () => {
    Keyboard.dismiss()
  }

  render_otp_input_box = () => {
    return (
      <View
        style={{
          justifyContent: 'center',
          flexDirection: 'row',
          marginBottom: HeightDimen(40),
        }}
      >
        <OTPCard
          value={this.state.OTP}
          num={0}
          onPress={() => this.hide_keyboard()}
          enablePress={!this.state.showNumberPad}
        />
        <OTPCard
          marginLeft={WidthDimen(10)}
          value={this.state.OTP}
          num={1}
          onPress={() => this.hide_keyboard()}
          enablePress={!this.state.showNumberPad}
        />
        <OTPCard
          marginLeft={WidthDimen(10)}
          value={this.state.OTP}
          num={2}
          onPress={() => this.hide_keyboard()}
          enablePress={!this.state.showNumberPad}
        />
        <OTPCard
          marginLeft={WidthDimen(10)}
          value={this.state.OTP}
          num={3}
          onPress={() => this.hide_keyboard()}
          enablePress={!this.state.showNumberPad}
        />
        <OTPCard
          marginLeft={WidthDimen(10)}
          value={this.state.OTP}
          num={4}
          onPress={() => this.hide_keyboard()}
          enablePress={!this.state.showNumberPad}
        />
        <OTPCard
          marginLeft={WidthDimen(10)}
          value={this.state.OTP}
          num={5}
          onPress={() => this.hide_keyboard()}
          enablePress={!this.state.showNumberPad}
        />
      </View>
    )
  }
  onSelectCode(code) {
    this.setState({
      code: code,
    })
  }
  render() {
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: 'white',
        }}
      >
        <LoginFlowHeaderContainer
          onBackPress={() => this.onBackPressed()}
          mainText={strings.t('request_otp')}
          subText={strings.t('please_enter_otp')}
          wantStepper
          stepValue={3}
          stepList={Mydata.stepList}
        >
          <ScrollView
            style={{
              flex: 1,
              // alignItems: "center",
            }}
          >
            <View
              style={{
                marginLeft: HeightDimen(20),
                marginRight: HeightDimen(20),
                marginTop: HeightDimen(20),
              }}
            >
              <PhoneTextInput
                placeholder={strings.t('phone_number')}
                bottom={true}
                top={true}
                existingCode={this.state.code}
                value={this.state.phoneNumber}
                marginBottom={HeightDimen(10)}
                onOtpPress={() => this.onResendOTP()}
                onSelectCodePress={(val) => this.onSelectCode(val)}
                isNumber={true}
                onChangeText={this.handle_phoneNumber}
                isCounter
                isValidation={this.state.phoneNumber ? false : true}
              />
              {this.state.otpPrefix && (
                <View
                  style={{
                    flexDirection: 'row',
                    marginBottom: HeightDimen(7),
                    alignSelf: 'flex-end',
                    marginRight: 5,
                  }}
                >
                  <Text
                    style={{
                      color: FontSecondaryColor,
                      marginBottom: HeightDimen(10),
                      fontSize: fontDimen(14),
                    }}
                  >
                    {strings.t('otp_prefix')}:
                  </Text>
                  <Text
                    style={{
                      color: SecondaryColor,
                      fontSize: fontDimen(14),
                      fontWeight: 'bold',
                      marginLeft: 5,
                    }}
                  >
                    {this.state.otpPrefix}
                  </Text>
                </View>
              )}
            </View>

            <this.render_otp_input_box />
            <View
              style={{
                width: '100%',
                paddingLeft: HeightDimen(30),
                paddingRight: HeightDimen(30),
              }}
            >
              <TextButton
                label={strings.t('proceed')}
                size={5}
                onPress={() => this.onNextPressed()}
                isLoading={this.state.isLoading}
              />
            </View>
            <TouchableOpacity
              style={{
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'row',
                marginVertical: HeightDimen(20),
              }}
              onPress={() => this.props.navigation.navigate('ContactUs')}
            >
              <Image
                source={require('../../Resources/Images/support.png')}
                style={{
                  width: 'auto',
                  height: HeightDimen(25),
                  aspectRatio: 1 / 1,
                  resizeMode: 'contain',
                  tintColor: SecondaryColor,
                }}
              />
              <Text
                style={{
                  fontSize: fontDimen(15),
                  color: SecondaryColor,
                  marginLeft: HeightDimen(7),
                }}
              >
                {strings.t('contactUs')}
              </Text>
            </TouchableOpacity>
          </ScrollView>
          {this.state.showNumberPad && (
            <NumberPad onPressSelect={this.onEnterOTP} />
          )}
        </LoginFlowHeaderContainer>
      </View>
    )
  }
}

export default OTPScreen
