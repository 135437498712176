import React, { useEffect, useRef, useState } from 'react'
import { View, Animated, Modal, Text, Easing } from 'react-native'
import LottieView from 'lottie-react-native'
import { fontDimen, HeightDimen, WidthDimen } from '../../UI/Dimensions'
import { TextButton } from '../../Component/Button/TextButton'
import { strings } from '../../Language/Language'
import { MainColor, SecondaryColor } from '../../UI/Color'
import MyData from '../../Data/MyData'

const SuccessModal = (props) => {
  const {
    isShow,
    onConfirmPress,
    isSuccess,
    message,
    transferAmount,
    isLoading,
  } = props

  const [progress] = useState(new Animated.Value(0))
  const animationRef = useRef(null)
  useEffect(() => {
    Animated.timing(progress, {
      toValue: 1,
      duration: 2500,
      easing: Easing.linear,
      useNativeDriver: true,
    }).start(() => {
      debugger
      animationRef?.current?.pause()
    })
  }, [])
  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={isShow}
      statusBarTranslucent
    >
      <View
        style={{
          flex: 1,
          backgroundColor: 'rgba(0,0,0,0.5)',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <View
          style={{
            flex: 1,
            backgroundColor: MainColor,
            padding: HeightDimen(20),

            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: HeightDimen(15),
            marginHorizontal: HeightDimen(40),
          }}
        >
          <LottieView
            ref={animationRef}
            source={
              isSuccess
                ? require('../../Resources/LottieIcons/success.json')
                : require('../../Resources/LottieIcons/error.json')
            }
            //   progress={progress}
            autoPlay
            loop
            progress={progress}
            style={{
              height: HeightDimen(50),
              alignSelf: 'center',
            }}
            //  loop
          />
          <Text
            style={{
              textAlign: 'center',
              fontSize: fontDimen(15),
              color: 'white',
            }}
          >
            {message}
          </Text>
          {transferAmount && (
            <Text
              style={{
                textAlign: 'center',
                fontSize: fontDimen(18),
                color: 'white',
                marginTop: HeightDimen(20),
                fontWeight: 'bold',
              }}
            >
              {`${transferAmount} ${MyData.country_code}`}
            </Text>
          )}
          <View
            style={{
              marginTop: HeightDimen(30),
              justifyContent: 'center',
              flexDirection: 'row',
              marginBottom: HeightDimen(20),
            }}
          >
            <TextButton
              onPress={onConfirmPress}
              label={strings.t('got_it')}
              size={4}
              isLoading={isLoading}
            />
          </View>
        </View>
      </View>
    </Modal>
  )
}

export { SuccessModal }
