import React, { Component } from 'react'
import {
  View,
  FlatList,
  ActivityIndicator,
  Modal,
  Image,
  TouchableOpacity,
  StatusBar,
  Platform,
} from 'react-native'
import { MainColor } from '../../UI/Color'
import { HeightDimen, WidthDimen } from '../../UI/Dimensions'
import anouncementCaller from '../../API/AnouncementAPICaller'
import MyData from '../../Data/MyData'
import { AnouncementCard } from '../../Component/Card/AnouncementCard'
import storageData from '../../Data/StorageData'
import MyToast from '../../Component/Toast/MyToast'

class AnouncementScreen extends Component {
  state = {
    anouncementList: [],
    isLoading: true,
    isModalShow: false,
  }

  async componentDidMount() {
    this.get_Anouncement_list()
  }

  get_Anouncement_list = async () => {
    await anouncementCaller
      .getAnouncement(MyData.authToken)
      .then((res) => {
        this.setState({
          anouncementList: res.response.list,
          isLoading: false,
        })
      })
      .catch((err) => {
        if (err.response.status == 514) {
          this.props.expiredToken_logout()
        } else {
          MyToast.popToast(err.response.data.msg)
        }
      })
  }
  onBackPressed = () => {
    this.props.navigation.goBack()
  }
  on_Refresh = () => {
    this.setState({ isLoading: true })
    this.get_Anouncement_list()
  }
  renderCoverImageModal = () => {
    return (
      <Modal
        animationType="fade"
        transparent={true}
        visible={this.state.isModalShow}
        statusBarTranslucent
      >
        <View
          style={{
            flex: 1,
            backgroundColor: 'rgba(0,0,0,0.8)',
          }}
        >
          <TouchableOpacity
            style={{
              paddingLeft: WidthDimen(20),
              marginTop:
                Platform.OS === 'ios' ? WidthDimen(45) : WidthDimen(40),
            }}
            onPress={() => this.setState({ isModalShow: false })}
          >
            <Image
              source={require('../../Resources/Images/icon_back.png')}
              style={{
                width: 'auto',
                height: HeightDimen(15),
                aspectRatio: 19 / 13,
                resizeMode: 'contain',
              }}
            />
          </TouchableOpacity>
          <View
            style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}
          >
            <Image
              source={{ uri: this.state.selectedImage }}
              style={{
                resizeMode: 'contain',
                height: '90%',
                width: '90%',
              }}
            />
          </View>
        </View>
      </Modal>
    )
  }
  render() {
    return (
      <View
        style={{
          flex: 1,
        }}
      >
        {this.state.isLoading ? (
          <View>
            <ActivityIndicator size="small" color={MainColor} />
          </View>
        ) : (
          <FlatList
            onRefresh={() => this.on_Refresh()}
            refreshing={this.state.isLoading}
            data={this.state.anouncementList}
            contentContainerStyle={{ paddingHorizontal: HeightDimen(15) }}
            keyExtractor={(key) => key.announcement_id}
            renderItem={({ item }) => {
              let title = ''
              let desc = ''
              const selectedLang = storageData.get_selected_language()
              if (selectedLang === 'EN') {
                title = item.announcement_en_title
                desc = item.announcement_en
              } else if (selectedLang === 'BM') {
                title = item.announcement_bm_title
                desc = item.announcement_bm
              } else if (selectedLang === 'CH') {
                title = item.announcement_cn_title
                desc = item.announcement_cn
              }

              return (
                <AnouncementCard
                  onPress={() => {
                    if (item.announcement_link) {
                      this.props.navigation.navigate('AnouncementDetail', {
                        Url: item.announcement_link,
                        onGoBack: (data) => {
                          this.on_Refresh(data)
                        },
                      })
                    } else {
                      this.setState({
                        selectedImage: item.announcement_cover_image,
                        isModalShow: true,
                      })
                    }
                  }}
                  title={title}
                  description={desc}
                  thumbnail={item.announcement_thumbnail_image}
                />
              )
            }}
          />
        )}

        {this.renderCoverImageModal()}
      </View>
    )
  }
}

export default AnouncementScreen
