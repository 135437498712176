import axios from 'axios'
import { apiUrls } from './APIurl'
import storageData from '../Data/StorageData'
import MyData from '../Data/MyData'

class ProductAPICaller {
  getProductList = async (authToken) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.getProductList}`,
          {
            data: {
              language: storageData.get_selected_language(),
              country: MyData.country_id || '1',
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  // launchGame = async (authToken, productID, amount) => {
  //   return new Promise((resolve, reject) => {
  //     axios
  //       .post(
  //         `${MyData.api_url}${apiUrls.launchGame}`,
  //         {
  //           data: {
  //             productId: productID,
  //             amountTransfer: amount,
  //             language: storageData.get_selected_language(),
  //           },
  //         },
  //         {
  //           headers: {
  //             Authorization: authToken,
  //           },
  //         }
  //       )
  //       .then((response) => {
  //         resolve(response.data);
  //       })
  //       .catch((error) => {
  //         reject(error);
  //       });
  //   });
  // };
  launchGame = async (
    authToken,
    productID,
    amount,
    walletType,
    viewType = ''
  ) => {
    const payload = {
      data: {
        productId: productID,
        amountTransfer: amount,
        language: storageData.get_selected_language(),
        viewType: viewType,
        walletType: walletType,
      },
    }
    return new Promise((resolve, reject) => {
      fetch(`${MyData.api_url}${apiUrls.launchGame}`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: authToken,
        },
        body: JSON.stringify(payload),
      })
        .then(async (response) => {
          const res = await response.json()
          if (response.status == 200) {
            resolve(res)
          } else {
            reject({ message: res?.msg, status: response?.status })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  getAvailableCredit = async (authToken, productID, pullCredit, walletType) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.getAvailableCredit}`,
          {
            data: {
              productId: productID,
              pullCredit: pullCredit,
              walletType: walletType,
              language: storageData.get_selected_language(),
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  getBetHistory = async (authToken, fromDates, toDates) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.getBetHistory}`,
          {
            data: {
              fromDate: fromDates,
              toDate: toDates,
              language: storageData.get_selected_language(),
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  // closeGame = async (authToken, id) => {
  //   return new Promise((resolve, reject) => {
  //     axios
  //       .post(
  //         `${MyData.api_url}${apiUrls.closeGame}`,
  //         {
  //           data: {
  //             productId: id,
  //             language: storageData.get_selected_language(),
  //           },
  //         },
  //         {
  //           headers: {
  //             Authorization: authToken,
  //           },
  //         }
  //       )
  //       .then((response) => {
  //         resolve(response.data);
  //       })
  //       .catch((error) => {
  //         reject(error);
  //       });
  //   });
  // };
  closeGame = async (authToken, id) => {
    const payload = {
      data: {
        productId: id,
        language: storageData.get_selected_language(),
      },
    }
    return new Promise((resolve, reject) => {
      fetch(`${MyData.api_url}${apiUrls.closeGame}`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: authToken,
        },
        body: JSON.stringify(payload),
      })
        .then(async (response) => {
          const res = await response.json()

          if (response?.status == 200) {
            resolve(res)
          } else {
            reject({ message: res?.msg, status: response?.status })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  allGameTransaction = async (authToken, date, type, end_date, page) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.allGameTransaction}`,
          {
            data: {
              fromDate: date,
              toDate: end_date,
              productId: type,
              language: storageData.get_selected_language(),
              page: page,
              pageSize: 15,
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          //console.log(response)
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  GameChangePasswrd = async (authToken, prodId, password) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.gameChangePassword}`,
          {
            data: {
              productId: prodId,
              password: password,
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          //console.log(response)
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  pullOutCreditByProduct = async (authToken, prodId) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.pullOutCreditByProduct}`,
          {
            data: {
              productId: prodId,
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          //console.log(response)
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  changeBonusChips = async (authToken, walletType, productID) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.getAvailableCredit}`,
          {
            data: {
              walletType: walletType || 'Cash',
              productId: productID,
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}

const productAPICaller = new ProductAPICaller()
export default productAPICaller
