import React, { Component } from 'react'
import { View, StatusBar, StyleSheet } from 'react-native'
import { HeightDimen } from '../../UI/Dimensions'
import { LoginFlowHeaderContainer } from '../../Component/Header/LoginFlowHeader'
import { NormalTextInput } from '../../Component/Input/NormalTextInput'
import PasswordInput from '../../Component/Input/PasswordInput'
import { TextButton } from '../../Component/Button/TextButton'
import APICaller from '../../API/APICaller'
import MyToast from '../../Component/Toast/MyToast'
import { strings } from '../../Language/Language'
import storageData from '../../Data/StorageData'
import MyData from '../../Data/MyData'

class MultiAccountLoginScreen extends Component {
  state = {
    username: '',
    password: '',
    isLoading: false,
  }

  onLogin = async () => {
    const { username, password } = this.state
    if (username != '' && password != '') {
      this.setState({ isLoading: true })
      await APICaller.multi_account_Login(username, password)
        .then(async (res) => {
          this.setState({ isLoading: false })

          MyToast.popToast('User added successfully')
          await storageData.store_multi_account_data(
            res.response.user.member_username,
            this.state.password,
            res.response.user.member_avatar_url,
            res.response.user.member_login_token
          )
          this.props.route.params.onGoBack()
          this.props.navigation.goBack()
        })
        .catch((err) => {
          this.setState({ isLoading: false })

          if (err.response.status == 302) {
            MyData.userToken = err.response.data.response.userToken
            MyData.authToken = err.response.data.response.authToken
            this.props.navigation.navigate('MultiLoginOTP', {
              phoneNumber: err.response.data.response.userPhone,
              code: err.response.data.response.countryCode,
              username: this.state.username,
              password: this.state.password,
              isMultiLogin: true,
            })
          } else if (err.response.status == 303) {
            MyData.userToken = err.response.data.response.userToken
            this.props.navigation.navigate('MultiLoginVerification', {
              phoneNumber: err.response.data.response.userPhone,
              username: this.state.username,
              password: this.state.password,
              isMultiLogin: true,
            })
          } else if (err.response.status == 304) {
            this.props.navigation.navigate('GoogleAuthKeyVerification', {
              username: username,
              password: password,
              isSwitchAccount: true,
            })
          } else {
            MyToast.popToast(err.response.data.msg)
          }
        })
    }
  }

  handle_username = (username_txt) => {
    this.setState({ username: username_txt })
  }

  handle_password = (password_txt) => {
    this.setState({ password: password_txt })
  }

  onBackPressed = () => {
    this.props.navigation.goBack()
  }
  render() {
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: 'white',
        }}
      >
        <StatusBar translucent backgroundColor="transparent" />

        <LoginFlowHeaderContainer
          onBackPress={() => this.onBackPressed()}
          mainText={strings.t('authenticate')}
          subText={strings.t('please_signin_to_continue')}
        >
          <View
            style={{
              flex: 1,
              paddingLeft: HeightDimen(30),
              paddingRight: HeightDimen(30),
              paddingBottom: HeightDimen(30),
            }}
          >
            <View
              style={{
                flex: 1,
                justifyContent: 'center',
              }}
            >
              <NormalTextInput
                placeholder={strings.t('username_text')}
                marginBottom={HeightDimen(20)}
                onChangeText={this.handle_username}
                type={'username'}
                value={this.state.username}
              />
              <PasswordInput
                placeholder={strings.t('password_text')}
                marginBottom={HeightDimen(20)}
                onChangeText={this.handle_password}
                value={this.state.password}
              />

              <View
                style={{
                  flexDirection: 'row',
                  marginTop: HeightDimen(40),
                  alignItems: 'center',
                }}
              >
                <TextButton
                  label={strings.t('login_text')}
                  size={2.5}
                  onPress={() => this.onLogin()}
                  isLoading={this.state.isLoading}
                />
              </View>
            </View>
          </View>
        </LoginFlowHeaderContainer>
      </View>
    )
  }
}
const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 22,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
})
export default MultiAccountLoginScreen
