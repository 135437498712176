import React, { Component } from "react";
import { View } from "react-native";
import Text from "../../Component/Text/MyText";
import { FontSecondaryColor } from "../../UI/Color";
import { HeightDimen, fontDimen } from "../../UI/Dimensions";
import { LoginFlowHeaderContainer } from "../../Component/Header/LoginFlowHeader";
import { TextButton } from "../../Component/Button/TextButton";
import { RecoverTextInput } from "../../Component/Input/RecoverTextInput";
import MyData from "../../Data/MyData";
import APICaller from "../../API/APICaller";
import MyToast from "../../Component/Toast/MyToast";
import { strings } from "../../Language/Language";
import {
  AnalyticEvents,
  eventMessages,
  logEventWithScreenName,
  logMessageAnalytics,
  logScreenViewAnalytics,
  screenNames,
} from "../../Utils/Analytics";
import { generateRandom } from "../../Utils/Common";

class ChangePasswordScreen extends Component {
  state = {
    // phoneNumber: '******* 3222',
    // email: '*******@gmail.com',
    // selected: true
    otp: "",
    new_pass: "",
    confirm_pass: "",

    text: "",
    isLoading: false,
    otpPrefix: "",
  };

  componentDidMount() {
    logScreenViewAnalytics(screenNames.ChangeLoginPassword);
  }
  handle_otp = otp_txt => {
    this.state.otp = otp_txt;
  };

  handle_new_pass = new_pass_txt => {
    this.state.new_pass = new_pass_txt;
  };

  handle_confirm_pass = confirm_pass_txt => {
    this.state.confirm_pass = confirm_pass_txt;
  };

  onBackPressed = () => {
    logEventWithScreenName(
      eventMessages.BackButton,
      screenNames.ChangeLoginPassword
    );
    MyData.clear_all_data();
    this.props.navigation.goBack();
  };

  onNextPressed = async () => {
    if (
      this.state.otp != "" &&
      this.state.new_pass != "" &&
      this.state.confirm_pass != ""
    ) {
      if (
        this.state.new_pass == this.state.confirm_pass &&
        this.state.new_pass.length >= 8
      ) {
        this.setState({ isLoading: true });
        await APICaller.change_passwordNoAuth(
          MyData.userToken,
          this.state.otp,
          "",
          this.state.new_pass,
          this.state.confirm_pass
        )
          .then(res => {
            logMessageAnalytics(
              AnalyticEvents.ChangeAccountPasswordNoAuthSuccess
            );
            this.props.navigation.navigate("Succ");
            this.setState({ isLoading: false });
          })
          .catch(err => {
            logMessageAnalytics(
              AnalyticEvents.ChangeAccountPasswordNoAuthFailure
            );
            this.setState({ isLoading: false });
            MyToast.popToast(err.response.data.msg);
          });
      } else {
        logMessageAnalytics(
          AnalyticEvents.ChangeAccountPasswordNoAuthFailure,
          strings.invalid_password
        );
        MyToast.popToast(strings.invalid_password);
      }
    } else {
      logMessageAnalytics(
        AnalyticEvents.ChangeAccountPasswordNoAuthFailure,
        strings.invalid_data_input
      );
      MyToast.popToast(strings.invalid_data_input);
    }
  };

  onSendOTP = async () => {
    const { username, phone, code } = this.props.route.params;
    if (phone != "") {
      let otpPrefix = generateRandom(4);
      this.setState({ otpPrefix: otpPrefix });
      await APICaller.recoverPassword(phone, code, username, otpPrefix)
        .then(res => {
          logMessageAnalytics(
            AnalyticEvents.ChangeAccountPasswordSendOTPSuccess
          );
        })
        .catch(err => {
          logMessageAnalytics(
            AnalyticEvents.ChangeAccountPasswordSendOTPFailure,
            err.response.data.msg
          );
          MyToast.popToast(strings.invalid_phone_number);
        });
    } else {
      logMessageAnalytics(
        AnalyticEvents.ChangeAccountPasswordSendOTPFailure,
        strings.please_enter_phone_number
      );
      MyToast.popToast(strings.please_enter_phone_number);
    }
  };
  render() {
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: "white",
        }}>
        <LoginFlowHeaderContainer
          onBackPress={() => this.onBackPressed()}
          mainText={strings.change_login_password}
          subText={strings.select_one_of_the_cre}>
          <View
            style={{
              flex: 1,
              paddingLeft: HeightDimen(30),
              paddingRight: HeightDimen(30),
              paddingBottom: HeightDimen(30),
            }}>
            <View
              style={{
                marginTop: HeightDimen(40),
              }}>
              <RecoverTextInput
                title={`${strings.otp_text} ${
                  this.state.otpPrefix ? `  ${this.state.otpPrefix} -` : ""
                }`}
                placeholder={strings.otp_text}
                top={true}
                onChangeText={this.handle_otp}
                isCounter
                onOtpPress={() => this.onSendOTP()}
              />
              <View style={{ height: HeightDimen(3) }}></View>
              <RecoverTextInput
                title={strings.new_password}
                placeholder={strings.new_password}
                isSecure={true}
                onChangeText={this.handle_new_pass}
              />
              <View style={{ height: HeightDimen(3) }}></View>
              <RecoverTextInput
                title={strings.confirm_password}
                placeholder={strings.confirm_password}
                bottom={true}
                isSecure={true}
                onChangeText={this.handle_confirm_pass}
              />
            </View>

            <View
              style={{
                width: "100%",
                alignItems: "center",
                marginTop: HeightDimen(40),
              }}>
              <TextButton
                label={strings.next}
                size={5}
                onPress={() => this.onNextPressed()}
                isLoading={this.state.isLoading}
              />
            </View>
            <View
              style={{
                marginTop: HeightDimen(10),
              }}>
              <Text
                style={{
                  color: FontSecondaryColor,
                  fontSize: fontDimen(15),
                  marginTop: HeightDimen(10),
                }}>
                {this.state.text}
              </Text>
            </View>
          </View>
        </LoginFlowHeaderContainer>
      </View>
    );
  }
}

export default ChangePasswordScreen;
