import React, { Component } from 'react'
import { Animated, TouchableOpacity, Image, Text, View } from 'react-native'
import { fontDimen, HeightDimen } from '../../UI/Dimensions'

const BorderGameCard = (props) => {
  const { image, transform, onPress, name } = props

  return (
    <Animated.View
      style={[
        {
          aspectRatio: 1 / 1.25,
          flexBasis: '33.33%',
          overflow: 'hidden',
          justifyContent: 'center',
          alignItems: 'center',
        },
        transform,
      ]}
    >
      <TouchableOpacity
        onPress={onPress}
        style={{
          borderRadius: HeightDimen(10),
          overflow: 'hidden',
          width: '95%',
          borderWidth: HeightDimen(2),
          borderColor: 'white',
        }}
      >
        <Image
          style={{
            width: 'auto',
            height: 'auto',
            aspectRatio: 2 / 1.5,
            resizeMode: 'contain',
          }}
          source={{ uri: image }}
        />
      </TouchableOpacity>
      <View style={{ height: HeightDimen(55), marginTop: 5 }}>
        <Text
          style={{
            fontSize: fontDimen(15),
            fontWeight: 'bold',
            color: 'white',
            textAlign: 'center',
          }}
        >
          {name}
        </Text>
      </View>
    </Animated.View>
  )
}

export { BorderGameCard }
