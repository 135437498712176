import React, { Component } from 'react'
import {
  TouchableOpacity,
  View,
  Image,
  Text,
  ActivityIndicator,
} from 'react-native'
import { fontDimen, HeightDimen, WidthDimen } from '../../UI/Dimensions'
import { MainFlowHeaderContainer } from '../../Component/Header/MainFlowHeader'
import MyData from '../../Data/MyData'
import { strings } from '../../Language/Language'
// import { launchImageLibrary } from 'react-native-image-picker'
import MyToast from '../../Component/Toast/MyToast'
import aPICaller from '../../API/APICaller'
import { FontMainColor, SecondaryColor } from '../../UI/Color'
import { StackActions } from '@react-navigation/native'
import { Camera, CameraType } from 'expo-camera'
import { BarCodeScanner } from 'expo-barcode-scanner'
import QrScanner from 'qr-scanner'
import * as ImagePicker from 'expo-image-picker'

class QRCodeScreen extends Component {
  state = {
    isLoading: false,
    isFocused: true,
  }

  onBackPressed = () => {
    this.props.navigation.goBack()
  }
  componentWillUnmount() {
    this.setState({ isFocused: false })
  }
  onRegisterAsGuest = () => {}

  onBarCodeRead = async (e) => {
    this.setState({ isLoading: true })
    debugger
    if (e.data != null && e.data != '{') {
      var temp = e.data
      var index = temp.indexOf('ref')

      if (index < 0) {
        temp = await aPICaller.onGetDomainURL(temp)
        index = temp.indexOf('ref')
      }
      if (index >= 0) {
        MyData.receommender_name = temp.substring(index + 4)
        // if (this.props.isFromInfo) this.props.navigation.navigate("SignUps");
        // else this.props.navigation.navigate("SignUp");

        if (this.state.isFocused) {
          this?.props?.route?.params?.onGoBack()
          this.props.navigation.goBack()
        }
        this.setState({ isFocused: false })
      }
    }
    this.setState({ isLoading: false })
  }
  onPress_pickFromLibrary = async () => {
    const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync()
    if (status === 'granted') {
      let result = await ImagePicker.launchImageLibraryAsync({
        allowsEditing: false,
        quality: 1,
        base64: false,
      })
      if (!result.canceled) {
        this.read_qr_code(result?.assets[0]?.uri)
      }
    }
  }

  read_qr_code = async (image_path) => {
    this.setState({ isLoading: true })
    if (image_path) {
      try {
        let dataNeeded = await QrScanner.scanImage(image_path)

        if (dataNeeded) {
          var temp = dataNeeded
          var index = temp.indexOf('ref')

          if (index < 0) {
            temp = await aPICaller.onGetDomainURL(temp)
            index = temp.indexOf('ref')
          }
          if (index >= 0) {
            MyData.receommender_name = temp.substring(index + 4)
            //this.props.navigation.navigate("CountrySignUp");
            // if (this.props.isFromInfo)
            //   this.props.navigation.navigate("SignUps");
            // this.props.navigation.navigate("SignUp");

            if (this.state.isFocused) {
              this?.props?.route?.params?.onGoBack()
              this.props.navigation.goBack()
            }
            this.setState({ isFocused: false })
          } else {
            MyToast.popToast(strings.t('qr_code_error'))
          }
        } else {
          MyToast.popToast(strings.t('qr_code_error'))
        }
      } catch (error) {
        // if there this no QR code
        MyToast.popToast(strings.t('qr_code_error'))
      } finally {
        this.setState({ isLoading: false })
      }
    }
  }

  render() {
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: 'white',
        }}
      >
        <MainFlowHeaderContainer
          title={strings.t('new_account')}
          onBackPress={() => this.onBackPressed()}
          toShow={true}
          toShowText={strings.t('photo_library')}
          toShowPress={() => this.onPress_pickFromLibrary()}
        >
          <View
            style={{
              flex: 1,
              justifyContent: 'flex-end',
              //paddingTop: HeightDimen(20),
            }}
          >
            <View
              style={{
                // marginTop: -HeightDimen(31),
                flex: 1,
                //   borderTopLeftRadius: HeightDimen(20),
                // borderTopRightRadius: HeightDimen(20),
                overflow: 'hidden',
              }}
            >
              {/* <RNCamera
                ref={(ref) => {
                  this.camera = ref;
                }}
                style={{
                  flex: 1,
                }}
                type={RNCamera.Constants.Type.back}
                flashMode={RNCamera.Constants.FlashMode.off}
                captureAudio={false}
                // onBarCodeRead={({ barcodes }) => {
                //     console.log(barcodes.data)
                //     if(barcodes[0].data != null && barcodes[0].data[0] != '{') {
                //         //console.log(barcodes[0].data);
                //         //MyData.receommender_name = barcodes[0].data;
                //         var temp = barcodes[0].data;
                //         var index = temp.indexOf('ref');
                //         if(index >= 0) {
                //             MyData.receommender_name = temp.substring(index + 4);
                //             if(this.props.isFromInfo) this.props.navigation.navigate('SignUps');
                //             else this.props.navigation.navigate('SignUp');
                //         }
                //     }

                // }}
                onBarCodeRead={this.onBarCodeRead}
              /> */}
              <Camera
                ref={(ref) => {
                  this.camera = ref
                }}
                style={{
                  flex: 1,
                }}
                type={this.state.isMobile ? CameraType.back : CameraType.front}
                captureAudio={false}
                // onGoogleVisionBarcodesDetected={({ barcodes }) => {
                //     if(barcodes[0].data != null && barcodes[0].data[0] != '{') {
                //         //console.log(barcodes[0].data);

                //         var temp = barcodes[0].data;
                //         var index = temp.indexOf('ref');
                //         if(index >= 0) {
                //             var userName = temp.substring(index + 4);
                //             this.props.navigation.navigate('TransferAuto', {
                //                 value: userName,
                //                 onGoBack: (data) => {
                //                     this.refresh(data)
                //                 }
                //             });
                //         }
                //     }

                // }}
                barCodeScannerSettings={{
                  barCodeTypes: [BarCodeScanner.Constants.BarCodeType.qr],
                }}
                onBarCodeRead={!this.state.isLoading && this.onBarCodeRead}
              />
              <View
                style={{
                  width: '100%',
                  height: '100%',
                  position: 'absolute',
                }}
              >
                <View
                  style={{
                    flex: 1,
                    backgroundColor: 'rgba(0,0,0,0.5)',
                  }}
                ></View>
                <View
                  style={{
                    width: '100%',
                    flexDirection: 'row',
                  }}
                >
                  <View
                    style={{
                      backgroundColor: 'rgba(0,0,0,0.5)',
                      flex: 1,
                    }}
                  ></View>

                  <View
                    style={{
                      width: 'auto',
                      height: HeightDimen(250),
                      aspectRatio: 1 / 1,
                      borderWidth: HeightDimen(5),
                      borderColor: 'white',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {this.state.isLoading && (
                      <ActivityIndicator size="large" color={SecondaryColor} />
                    )}
                  </View>
                  <View
                    style={{
                      backgroundColor: 'rgba(0,0,0,0.5)',
                      flex: 1,
                    }}
                  ></View>
                </View>
                <View
                  style={{
                    flex: 1,
                    backgroundColor: 'rgba(0,0,0,0.5)',
                  }}
                ></View>
              </View>

              {/* <TouchableOpacity
                onPress={() => this.onRegisterAsGuest()}
                style={{
                  width: "100%",
                  height: HeightDimen(50),
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: SecondaryColor,
                  borderRadius: HeightDimen(10),
                  flexDirection: "row",
                }}
              >
                <Image
                  source={require("../../Resources/Images/icon_btnprofile.png")}
                  style={{
                    width: "auto",
                    height: HeightDimen(30),
                    aspectRatio: 1 / 1,
                    resizeMode: "contain",
                    marginRight: WidthDimen(40),
                  }}
                />

                <Text
                  style={{
                    color: FontMainColor,
                    fontSize: fontDimen(15),
                  }}
                >
                  {"Register as a visitor"}
                </Text>
              </TouchableOpacity> */}
            </View>
          </View>
        </MainFlowHeaderContainer>
      </View>
    )
  }
}

export default QRCodeScreen
