import React, { Component } from 'react'
import {
  View,
  TouchableOpacity,
  Modal,
  StatusBar,
  FlatList,
  Image,
} from 'react-native'
import { SecondaryColor } from '../../UI/Color'
import { HeightDimen } from '../../UI/Dimensions'
import { TextButton } from '../Button/TextButton'
import { strings } from '../../Language/Language'
import MyData from '../../Data/MyData'
import avatarCaller from '../../API/AvatarCaller'
import { MainFlowHeaderContainer } from '../Header/MainFlowHeader'

class AvatarModal extends Component {
  state = {
    avatarList: [],
    isLoading: true,
    selectedIndex: 0,
  }

  async componentDidMount() {
    this.get_avatarList()
  }

  get_avatarList = async () => {
    await avatarCaller
      .getAvatarList(MyData.authToken)
      .then((res) => {
        this.setState({
          isLoading: false,
          avatarList: res.response.avatarList,
        })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  update_Selection = (index) => {
    this.setState({
      selectedIndex: index,
    })
  }

  update_avatar = async () => {
    this.setState({ isLoading: true })
    await avatarCaller
      .updateAvatar(
        MyData.authToken,
        this.state.avatarList[this.state.selectedIndex].avatar_id
      )
      .then((res) => {
        MyData.qrCode = res.response.user.member_qrcode_url
        this.setState({ isLoading: false })
        MyData.avatar =
          this.state.avatarList[this.state.selectedIndex].avatar_url
        this.props.updateAvatar(true)
      })
      .catch((err) => {
        //console.log(err);
        this.setState({ isLoading: false })
        this.props.updateAvatar(false)
      })
  }

  render() {
    const { isShow } = this.props
    return (
      <Modal
        animationType="slide"
        transparent={true}
        visible={isShow}
        statusBarTranslucent
        onRequestClose={() => this.props.toggleAvatar()}
      >
        <StatusBar translucent backgroundColor="transparent" />

        <View
          style={{
            flex: 1,
          }}
        >
          <MainFlowHeaderContainer
            onBackPress={() => this.props.toggleAvatar()}
          >
            <View
              style={{
                flex: 1,
                paddingBottom: HeightDimen(30),
                // alignItems: 'center'
              }}
            >
              <FlatList
                style={{
                  flex: 1,
                  marginTop: HeightDimen(30),
                  marginBottom: HeightDimen(30),
                }}
                contentContainerStyle={{
                  alignItems: 'center',
                }}
                numColumns={4}
                data={this.state.avatarList}
                renderItem={({ item, index }) => (
                  <TouchableOpacity
                    onPress={() => this.update_Selection(index)}
                    disabled={this.state.isLoading}
                    style={{
                      margin: HeightDimen(10),
                      borderWidth:
                        index == this.state.selectedIndex ? HeightDimen(4) : 0,
                      borderColor: SecondaryColor,
                      overflow: 'hidden',
                      borderRadius: HeightDimen(9999),
                    }}
                    //onPress={() => this.onSelect(index)}
                  >
                    <Image
                      style={{
                        width: 'auto',
                        height: HeightDimen(65),
                        aspectRatio: 1 / 1,
                        borderRadius: HeightDimen(9999),
                      }}
                      source={{
                        uri: item.avatar_url,
                      }}
                    />
                  </TouchableOpacity>
                )}
                keyExtractor={(item) => String(item.avatar_id)}
              />
              <View style={{ marginHorizontal: HeightDimen(20) }}>
                <TextButton
                  label={strings.change_avatar}
                  size={5}
                  onPress={() => this.update_avatar()}
                  isLoading={this.state.isLoading}
                />
              </View>
            </View>
          </MainFlowHeaderContainer>
        </View>
      </Modal>
    )
  }
}

export { AvatarModal }
