import React, { Component } from 'react'
import {
  View,
  TouchableOpacity,
  ActivityIndicator,
  Image,
  FlatList,
} from 'react-native'
import Text from '../../Component/Text/MyText'
import { MainColor, SecondaryColor } from '../../UI/Color'
import { HeightDimen, fontDimen } from '../../UI/Dimensions'
import { MainFlowHeaderContainerNoFeedback } from '../../Component/Header/NewDesign/MainFlowHeader'
import MyToast from '../../Component/Toast/MyToast'
import { strings } from '../../Language/Language'
import { BottomSelectionModal } from '../../Component/Modal/BottomSelectionModal'
import productAPICaller from '../../API/ProductAPICaller'
import MyData from '../../Data/MyData'
import { AllWalletHistoryCard } from '../../Component/Card/History/NewDesign/AllWalletHistoryCard'
import { Line } from '../../Component/Line/Line'
import { numberWithCommas } from '../../Utils/Common'

import MonthDateModal from '../../Component/Modal/MonthDateModal'
const moment = require('moment')
class AllGameTransactionScreen extends Component {
  state = {
    selectedFilter: strings.t('all'),
    selectedFilterIndex: 0,
    filterList: [],
    showBottomModal: false,
    selectedDate: strings.t('pick_date'),
    showDatePicker: false,
    isGetProductLoading: true,
    isLoading: false,
    toShowArray: [],
    datelabel: null,
    endDate: null,
    page: 1,
    rawDate: null,
    loadMore: false,
  }

  async componentDidMount() {
    /// logScreenViewAnalytics(screenNames.GameTransaction);
    await this.set_date()
    this.get_productList()
    await this.get_GameTransaction(
      this.state.selectedDate,
      '',
      this.state.endDate,
      this.state.page
    )
  }

  getDateFormat = () => {
    let date = null
    let dateToday = new Date()
    let day = dateToday.getDate()
    let month = dateToday.getMonth() + 1

    date = '2023' + '-' + '12' + '-' + '01'

    return date
  }

  set_date = async () => {
    let dateToday = new Date()
    let day = dateToday.getDate()
    let month = dateToday.getMonth() + 1
    let returnDate = this.getDateFormat()
    let end_date = new Date(
      dateToday.getFullYear(),
      dateToday.getMonth() + 1,
      0
    )
    let end_day = end_date.getDate()
    let returnEndDate = this.getDateFormat()

    this.setState({
      selectedDate: returnDate,
      endDate: returnEndDate,
      datelabel:
        (month < 10 ? '0' : '') + month + '-' + dateToday.getFullYear(),
    })
  }

  get_productList = async () => {
    await productAPICaller
      .getProductList(MyData.authToken)
      .then((res) => {
        // logMessageAnalytics(AnalyticEvents.GameTransactionsProductSuccess);
        let tempArr = []
        tempArr.push({ id: 0, value: '', name: strings.t('all') })

        for (let item in res.response.products) {
          tempArr.push({
            id: res.response.products[item].product_id,
            value: res.response.products[item].product_id,
            name: res.response.products[item].product_desc,
          })
        }

        this.setState({
          isGetProductLoading: false,
          filterList: tempArr,
        })
      })
      .catch((err) => {
        //  logMessageAnalytics(AnalyticEvents.GameTransactionsProductFailure);
        if (err.response.status == 514) {
          this.props.expiredToken_logout()
        } else {
          MyToast.popToast(err.response.data.msg)
        }
      })
  }

  onBackPressed = () => {
    // logEventWithScreenName(
    //   eventMessages.BackButton,
    //   screenNames.GameTransaction
    // );
    this.props.navigation.goBack()
  }

  onToggle_BottomModal = () => {
    this.setState({ showBottomModal: !this.state.showBottomModal })
  }

  onToggle_datePicker = () => {
    this.setState({ showDatePicker: !this.state.showDatePicker })
  }

  onSelectFilter = (index) => {
    // logEventWithFilterName(
    //   AnalyticEvents.GameTransactionsTypeFilter,
    //   this.state.filterList[index].name
    // );
    this.onToggle_BottomModal()
    this.setState({
      selectedFilterIndex: index,
      selectedFilter: this.state.filterList[index].name,
      page: 1,
    })
    if (this.state.selectedDate != strings.t('pick_date')) {
      this.get_GameTransaction(
        this.state.selectedDate,
        this.state.filterList[index].value,
        this.state.endDate,
        1
      )
    }
  }

  close_date_modal = (date, end_date, date_label, rawDate) => {
    this.setState({
      selectedDate: date,
      endDate: end_date,
      datelabel: date_label,
      page: 1,
      rawDate: rawDate,
    })
    // logEventWithFilterName(AnalyticEvents.GameTransactionsDateFilter, date);
    this.onToggle_datePicker()
    this.get_GameTransaction(
      date,
      this.state.filterList[this.state.selectedFilterIndex].value,
      end_date,
      1
    )
  }

  get_GameTransaction = async (date, value, end_date, page) => {
    this.setState({ isLoading: true })
    // console.log(date, end_date, value);
    await productAPICaller
      .allGameTransaction(MyData.authToken, date, value, end_date, page)
      .then((res) => {
        let tempArr = res.response.transactions
        // console.log(tempArr)
        if (tempArr.length == 0) {
          MyToast.popToast(strings.t('no_transaction_for_dates'))
        }
        this.setState({
          isLoading: false,
          toShowArray: tempArr,
        })
      })
      .catch((err) => {
        console.log(err)
        this.setState({ isLoading: false })
        if (err.response.status == 514) {
          this.props.expiredToken_logout()
        } else {
          MyToast.popToast(err.response.data.msg)
        }
      })
  }

  get_MoreGameTransaction = async (date, value, end_date, page) => {
    this.setState({ loadMore: true })

    await productAPICaller
      .allGameTransaction(MyData.authToken, date, value, end_date, page)
      .then((res) => {
        // logMessageAnalytics(AnalyticEvents.GameTransactionsSuccess);

        let tempArr = res.response.transactions
        let mergeArr = [...this.state.toShowArray, ...tempArr].sort((a, b) => {
          return b?.transaction_id - a?.transaction_id
        })

        if (tempArr.length == 0) {
          MyToast.popToast(strings.t('no_more_transaction_for_dates'))
          this.setState({
            page: page - 1,
          })
        }
        this.setState({
          loadMore: false,
          toShowArray: mergeArr,
        })
      })
      .catch((err) => {
        //  logMessageAnalytics(AnalyticEvents.GameTransactionsFailure);
        console.log(err)
        this.setState({ loadMore: false })
        if (err.response.status == 514) {
          this.props.expiredToken_logout()
        } else {
          MyToast.popToast(err.response.data.msg)
        }
      })
  }

  loadMore = async () => {
    let newpage = this.state.page + 1
    this.setState({ page: newpage })
    await this.get_MoreGameTransaction(
      this.state.selectedDate,
      this.state.filterList[this.state.selectedFilterIndex].value,
      this.state.endDate,
      newpage
    )
  }
  renderMonthYearPicker = () => {
    return (
      <MonthDateModal
        isShow={this.state.showDatePicker}
        label={strings.t('pick_date')}
        onClose={this.close_date_modal}
        rawDate={this.state.rawDate}
      />
    )
  }
  render() {
    return (
      <View style={styles.container}>
        <BottomSelectionModal
          isShow={this.state.showBottomModal}
          filterList={this.state.filterList}
          onSelect={this.onSelectFilter}
        />
        {/* {this.renderMonthYearPicker()} */}
        <MainFlowHeaderContainerNoFeedback
          title={strings.t('game_transaction')}
          onBackPress={() => this.onBackPressed()}
        >
          {this.state.isGetProductLoading ? (
            <View>
              <ActivityIndicator
                size="small"
                color={MainColor}
                style={{ marginTop: HeightDimen(20) }}
              />
            </View>
          ) : (
            <View style={styles.innerContainer}>
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  width: '30%',
                  zIndex: 9999,
                  position: 'absolute',
                  right: 20,
                  justifyContent: 'flex-end',
                }}
              >
                <Text style={styles.date}>{strings.t('date') + ': '}</Text>
                <MonthDateModal
                  isShow={this.state.showDatePicker}
                  label={strings.t('pick_date')}
                  onClose={this.close_date_modal}
                  rawDate={this.state.rawDate}
                />
              </View>
              <View style={styles.filter}>
                <TouchableOpacity
                  onPress={() => this.onToggle_BottomModal()}
                  disabled={this.state.isLoading}
                  style={styles.dateContainer}
                >
                  <Text style={styles.date}>{strings.t('type') + ': '}</Text>
                  <Text numberOfLines={1} style={styles.dateLabel}>
                    {this.state.selectedFilter.length > 15
                      ? this.state.selectedFilter.substring(0, 15 - 3) + '...'
                      : this.state.selectedFilter}
                  </Text>
                  <Image
                    style={styles.icon}
                    source={require('../../Resources/Images/icon_down.png')}
                  />
                </TouchableOpacity>

                {/* <TouchableOpacity
                  disabled={this.state.isLoading}
                  onPress={() => this.onToggle_datePicker()}
                  style={styles.dateContainer}
                >
                  <Text style={styles.date}>{strings.t('date') + ': '}</Text>
                  <Text style={styles.dateLabel}>{this.state.datelabel}</Text>
                  <Image
                    style={styles.icon}
                    source={require('../../Resources/Images/icon_down.png')}
                  />
                </TouchableOpacity> */}
              </View>
              {this.state.loadMore && (
                <View
                  style={{
                    position: 'absolute',
                    bottom: HeightDimen(40),
                    alignSelf: 'center',
                  }}
                >
                  <ActivityIndicator size="small" color={MainColor} />
                </View>
              )}
              {this.state.isLoading ? (
                <View>
                  <ActivityIndicator size="small" color={MainColor} />
                </View>
              ) : (
                <View style={styles.listContainer}>
                  {this.state.toShowArray.length > 0 && (
                    <FlatList
                      onRefresh={() => {
                        this.setState({ page: 1 })
                        this.get_GameTransaction(
                          this.state.selectedDate,
                          this.state.selectedFilter.toLowerCase(),
                          this.state.endDate,
                          1
                        )
                      }}
                      onEndReachedThreshold={0.2}
                      onEndReached={this.loadMore}
                      refreshing={this.state.isLoading}
                      numColumns={1}
                      data={this.state.toShowArray}
                      renderItem={({ item, index }) => (
                        <View>
                          <AllWalletHistoryCard
                            disabled
                            url={item.product_image_url}
                            title={
                              item.transaction_type === 'gameIn'
                                ? strings.t('enter_game')
                                : item.transaction_type === 'gameOut'
                                ? strings.t('exit_game')
                                : item.transaction_remarks
                            }
                            date={moment(
                              item.transaction_created_datetime
                            ).format('DD-MM-YYYY hh:mm:ss A')}
                            bet={
                              item.transaction_type == 'gameIn' ||
                              item.transaction_type == 'gameOut'
                                ? item.product_name
                                : `${strings.bet}: ${Number(
                                    item.game_transaction_valid_stake
                                  ).toFixed(2)}`
                            }
                            win={
                              item.transaction_type == 'gameIn' ||
                              item.transaction_type == 'gameOut'
                                ? `${MyData.country_code} ${numberWithCommas(
                                    Number(
                                      item.transaction_wallet_amount
                                    ).toFixed(2)
                                  )}`
                                : `${strings.t('win_')}: ${numberWithCommas(
                                    Number(
                                      item.game_transaction_winloss
                                    ).toFixed(2)
                                  )}`
                            }
                          />
                          <Line />
                        </View>
                      )}
                      keyExtractor={(item, index) => String(index)}
                    />
                  )}
                </View>
              )}
            </View>
          )}
        </MainFlowHeaderContainerNoFeedback>
      </View>
    )
  }
}
const styles = {
  container: {
    flex: 1,
    backgroundColor: 'white',
  },
  innerContainer: {
    flex: 1,
    paddingHorizontal: HeightDimen(20),
    marginTop: HeightDimen(10),
  },
  dateContainer: {
    flexDirection: 'row',

    alignItems: 'center',
  },
  date: {
    fontSize: fontDimen(12),
    fontWeight: 'bold',
  },
  dateLabel: {
    fontSize: fontDimen(12),
    fontWeight: 'bold',
    color: SecondaryColor,
    marginLeft: HeightDimen(5),
  },
  icon: {
    width: 'auto',
    height: HeightDimen(10),
    aspectRatio: 1 / 1,
    padding: HeightDimen(3),
    marginLeft: HeightDimen(5),
    tintColor: SecondaryColor,
  },
  listContainer: {
    flex: 1,
    marginTop: HeightDimen(20),
  },
  filter: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}
export default AllGameTransactionScreen
