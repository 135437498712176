import React, { Component } from 'react'
import {
  View,
  Text,
  Image,
  FlatList,
  StyleSheet,
  ActivityIndicator,
  TouchableOpacity,
} from 'react-native'
import { strings } from '../../Language/Language'
import { MainFlowHeaderContainerNoFeedback } from '../../Component/Header/MainFlowHeader'
import {
  FontMainColor,
  FontSecondaryColor,
  MainColor,
  SecondaryColor,
} from '../../UI/Color'
import { fontDimen, HeightDimen } from '../../UI/Dimensions'
import leaderBoardCaller from '../../API/LeaderBoardCaller'
import MyData from '../../Data/MyData'

class LuckytownCoinScreen extends Component {
  state = {
    isLoading: false,
    lkytransactionList: [],
    totalBalance: '',
  }
  async componentDidMount() {
    await this.get_lk_point_transaction()
  }
  get_lk_point_transaction = async () => {
    this.setState({
      isLoading: true,
    })
    await leaderBoardCaller
      .getLKYPointTransaction(MyData.authToken)
      .then((res) => {
        this.setState({
          lkytransactionList: res?.response.transactions,
          totalBalance: res?.response?.totalBalance,
        })
      })
      .catch((err) => {
        if (err.response.status == 514) {
          this.props.expiredToken_logout()
        } else {
          MyToast.popToast(err.response.data.msg)
        }
      })
      .finally(() => {
        this.setState({
          isLoading: false,
        })
      })
  }

  onBackPressed = () => {
    this.props.navigation.goBack()
  }
  _renderItem = (item) => {
    return (
      <View style={styles.itemContainer}>
        <View
          style={{
            marginHorizontal: HeightDimen(12),
            flex: 1,
          }}
        >
          <Text style={styles.label}>{item?.transaction_remark}</Text>
          <Text style={styles.msgPreview}>
            {item?.transaction_created_datetime}
          </Text>
        </View>
        <Text style={{ ...styles.label, color: MainColor }}>
          {item?.transaction_amount}
        </Text>
      </View>
    )
  }
  render() {
    return (
      <MainFlowHeaderContainerNoFeedback
        title={strings.t('coin')}
        onBackPress={() => this.onBackPressed()}
      >
        <View
          style={{
            flex: 1,
          }}
        >
          <View style={styles.banner}>
            <View style={{ marginTop: HeightDimen(20) }}>
              <Text
                style={{
                  ...styles.label,
                  color: 'white',
                  fontSize: fontDimen(16),
                }}
              >
                {strings.t('luckytown_points')}
              </Text>
              <Text
                style={{
                  ...styles.label,
                  color: 'white',
                  fontSize: fontDimen(14),
                }}
              >
                {strings.t('you_have_earned')}
              </Text>
            </View>
            <Image
              source={require('../../Resources/Images/coins.png')}
              style={styles.bannerLogo}
            />
          </View>
          {!this.state.isLoading ? (
            <>
              <View style={styles.coinHeader}>
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',

                    justifyContent: 'space-between',
                  }}
                >
                  <Image
                    source={require('../../Resources/Images/coin.png')}
                    style={{ ...styles.bannerLogo, height: 35 }}
                  />
                  <View style={{ marginLeft: HeightDimen(10) }}>
                    <Text
                      style={{
                        color: 'white',
                        fontSize: fontDimen(22),
                        fontWeight: 'bold',
                      }}
                    >
                      {this.state.totalBalance}
                    </Text>
                    <Text
                      style={{
                        color: 'white',
                        fontSize: fontDimen(15),
                      }}
                    >
                      Points
                    </Text>
                  </View>
                </View>
                <TouchableOpacity
                  onPress={() => this.props.navigation.navigate('LKYRedmee')}
                  style={{
                    backgroundColor: SecondaryColor,
                    padding: HeightDimen(8),
                    borderRadius: HeightDimen(5),
                  }}
                >
                  <Text
                    style={{
                      fontSize: fontDimen(14),
                    }}
                  >
                    {strings.t('redeem')}
                  </Text>
                </TouchableOpacity>
              </View>
              <Text
                style={{
                  fontSize: fontDimen(22),
                  fontWeight: 'bold',
                  marginTop: 10,
                  marginLeft: 10,
                }}
              >
                {strings.t('point_history')}
              </Text>
              <FlatList
                numColumns={1}
                style={{ marginTop: HeightDimen(5) }}
                onRefresh={() => this.get_lk_point_transaction()}
                refreshing={this.state.isLoading}
                data={this.state.lkytransactionList}
                renderItem={({ item }) => this._renderItem(item)}
                keyExtractor={(item, index) => String(index)}
              />
            </>
          ) : (
            <View
              style={{
                flex: 1,
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <ActivityIndicator size="small" color={SecondaryColor} />
            </View>
          )}
        </View>
      </MainFlowHeaderContainerNoFeedback>
    )
  }
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  itemContainer: {
    padding: HeightDimen(12),
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderColor: SecondaryColor,
    borderBottomWidth: 1,
  },
  label: {
    fontSize: fontDimen(14),
    color: MainColor,
    fontWeight: 'bold',
  },
  msgPreview: {
    fontSize: fontDimen(12),
    color: FontMainColor,
    marginTop: HeightDimen(5),
  },
  itemSeparator: {
    margin: HeightDimen(2),
    height: HeightDimen(1),
    width: '90%',
    alignSelf: 'center',
    backgroundColor: SecondaryColor,
  },
  banner: {
    height: 120,
    backgroundColor: SecondaryColor,
    paddingHorizontal: HeightDimen(20),
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  bannerLogo: {
    height: 70,
    resizeMode: 'contain',
    width: 'auto',
    aspectRatio: 1 / 1,
  },
  coinHeader: {
    backgroundColor: MainColor,
    marginTop: -40,
    padding: HeightDimen(10),
    marginHorizontal: HeightDimen(20),
    borderRadius: HeightDimen(5),
    elevation: 2,
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: HeightDimen(15),
    justifyContent: 'space-between',
  },
})
export default LuckytownCoinScreen
