import AsyncStorage from '@react-native-async-storage/async-storage'
import axios from 'axios'
import React, { Component } from 'react'
import { View, Image, StatusBar } from 'react-native'
import aPICaller from '../../API/APICaller'
import MyData from '../../Data/MyData'
import storageData from '../../Data/StorageData'
import { strings } from '../../Language/Language'
import { MainColor } from '../../UI/Color'
import { HeightDimen } from '../../UI/Dimensions'
import * as Font from 'expo-font'
class SplashScreen extends Component {
  async componentDidMount() {
    //await MyData.setIsAmplitudeEvents();
    //  await startupAnalytics();
    // logScreenViewAnalytics(screenNames.Splash);
    //await this.get_language_from_s3()
    await this.loadFont()
    debugger
    var isMobile =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    if (isMobile) {
      MyData.isMobile = true
    } else {
      MyData.isMobile = false
    }
    await this.get_username_password()
    await this.get_api_url()
  }
  async loadFont() {
    await Font.loadAsync({
      'Race-Sport': require('../../Resources/fonts/Race-Sport.ttf'),
      'FZDaHei-B02S': require('../../Resources/fonts/FZDaHei-B02S.ttf'),
      'Gilroy-ExtraBold': require('../../Resources/fonts/Gilroy-ExtraBold.ttf'),
      'Gilroy-SemiBold': require('../../Resources/fonts/Gilroy-SemiBold.ttf'),
      Pattaya: require('../../Resources/fonts/Pattaya.ttf'),
    })
    this.setState({ fontsLoaded: true })
  }

  get_api_url = async () => {
    debugger
    if (storageData?.api_url && storageData?.s3_url) {
      await this.get_language()
      await MyData.setApiAndS3Url(storageData.api_url, storageData.s3_url)
      await this.login_user()
    } else {
      await this.get_data_from_s3()
      await this.login_user()
    }
  }
  get_language_from_s3 = async () => {
    await aPICaller
      .getS3Languages()
      .then((res) => {
        // strings.setContent(Object.assign({}, strings.getContent(), res))
      })
      .catch((err) => {
        // logMessageAnalytics(AnalyticEvents.S3ServerURLFailure);
      })
  }
  get_language = async () => {
    debugger
    await storageData.get_language()
    strings.locale = storageData.selected_language
  }

  get_username_password = async () => {
    await storageData.get_api_url()
    await this.get_language()
    await storageData.get_username_add_password()
  }

  login_user = async () => {
    if (storageData.saved_authSession != '') {
      await aPICaller
        .login(storageData.saved_userName, storageData.saved_password)
        .then(async (res) => {
          debugger
          console.log('TOKEN AUTH', res?.response.user)
          // await this.props.isReady_PushNotification()
          MyData.popupImage = res?.response?.popUpImage || []
          await MyData.setUp(res)
          await MyData.setUserPin(res.response.user.wallet_pin)
          //    MyData.setS3CountryURL();
          await storageData.store_multi_account_data(
            storageData.saved_userName,
            storageData.saved_password,
            res.response.user.member_avatar_url,
            res.response.user.member_login_token
          )
          this.props.isLoading_Ready(true)
        })
        .catch(async (err) => {
          debugger
          if (err?.response?.status == 300) {
            MyData.maintainance_json = err?.response?.data?.response
            this.props.isMaintenance_Ready(true)
          } else if (
            err?.response?.status == 302 ||
            err?.response?.status == 303 ||
            err?.response?.status == 304 ||
            err?.response?.status == 301
          ) {
            this.props.isLoading_Ready(false)
          }
          //  else if (
          //   err?.response?.status == 400 ||
          //   err?.response?.status == 404
          // ) {
          //   await this.get_data_from_s3(false);
          // }
          // else if (err.response.status == 304) {
          //   this.props.isGooglAuth_Ready(true);
          //   this.props.isLoading_Ready(false);
          // }
          else {
            await this.get_data_from_s3(false)
          }
        })
    } else {
      this.setupDeviceLanguage()
      this.props.isLoading_Ready(true)
    }
  }
  async setupDeviceLanguage() {
    debugger
    const browserLang = navigator.language || navigator.userLanguage
    // const supportedLanguages = ['en', 'ch', 'bm', 'th', 'vt', 'id', 'my', 'kh']
    const supportedLanguages = Object.keys(strings.translations)

    // Extract the primary language part (e.g., "en" from "en-US")
    const primaryLanguage = browserLang.split('-')[0]

    // Check if the detected language is supported, otherwise fallback to default
    if (supportedLanguages.includes(primaryLanguage)) {
      storageData.set_language(primaryLanguage)
    } else {
      storageData.set_language(strings.defaultLocale)
    }
  }
  get_data_from_s3 = async (isLogin = true) => {
    const api_url = {
      api_url: 'https://tlky.xyz/Member/',
      s3_url: 'https://xxyyzz112233.s3.ap-southeast-1.amazonaws.com',
    }
    await storageData.set_api_url(api_url)
    await MyData.setApiAndS3Url(api_url?.api_url, api_url.s3_url)
    // await aPICaller
    //   .getApiURL()
    //   .then(async (res) => {
    //     debugger
    //     const api_url = {
    //       api_url: res?.staging_url,
    //       s3_url: res?.prod_s3_url,
    //     }
    //     await storageData.set_api_url(api_url)
    //     await MyData.setApiAndS3Url(res?.staging_url, res.prod_s3_url)
    //     if (res.staging_maintenance === 'true') {
    //       MyData.maintainance_json = res
    //       this.props.isMaintenance_Ready(true)
    //     } else {
    //       if (isLogin) await this.login_user()
    //       else this.props.isLoading_Ready(false)
    //     }
    //   })
    //   .catch((err) => {
    //     this.props.isLoading_Ready(false)
    //   })
  }

  render() {
    return (
      <View
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#141414',
        }}
      >
        <StatusBar translucent backgroundColor={MainColor} />
        <Image
          //  resizeMode="contain"
          source={require('../../Resources/Images/Splash.gif')}
          style={{
            width: '75%',
            height: '100%',
          }}
        />
      </View>
    )
  }
}

export default SplashScreen
