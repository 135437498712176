import React, { Component } from 'react'
import { View, Image, FlatList, Text, TouchableOpacity } from 'react-native'
import { HeightDimen, fontDimen } from '../../UI/Dimensions'
import { MainFlowHeaderContainerNoFeedback } from '../../Component/Header/MainFlowHeader'
import { strings } from '../../Language/Language'
import { EmptyRecordCard } from '../../Component'
import { SecondaryColor } from '../../UI/Color'
import MyData from '../../Data/MyData'
import storageData from '../../Data/StorageData'

class VIPScreen extends Component {
  state = {
    selectedVip: {},
    vipTab: [
      {
        id: 0,
        logo: require('../../Resources/Images/vip/vip0.png'),
        grayLogo: require('../../Resources/Images/vip/vip-g0.png'),
        vip: [
          {
            a: strings.t('bonus_monthly'),
            b: '-',
          },
          {
            a: strings.t('birthday_bonus'),
            b: '-',
          },
          {
            a: strings.t('withdraw_per_day'),
            b: '3',
          },
          {
            a: strings.t('exclusive_domain'),
            b: '-',
          },
          {
            a: strings.t('deposit_priority'),
            b: '-',
          },
          {
            a: strings.t('festival_gift'),
            b: '-',
          },
          {
            a: strings.t('exclusive_customer_service'),
            b: '-',
          },
          {
            a: strings.t('free_travel'),
            b: '-',
          },
        ],
      },
      {
        id: 1,
        logo: require('../../Resources/Images/vip/vip1.png'),
        grayLogo: require('../../Resources/Images/vip/vip-g1.png'),
        vip: [
          {
            a: strings.t('bonus_monthly'),
            b: '-',
          },
          {
            a: strings.t('birthday_bonus'),
            b: '68',
          },
          {
            a: strings.t('withdraw_per_day'),
            b: '3',
          },
          {
            a: strings.t('exclusive_domain'),
            b: '✔',
          },
          {
            a: strings.t('deposit_priority'),
            b: '-',
          },
          {
            a: strings.t('festival_gift'),
            b: '-',
          },
          {
            a: strings.t('exclusive_customer_service'),
            b: '-',
          },
          {
            a: strings.t('free_travel'),
            b: '-',
          },
        ],
      },

      {
        id: 2,
        logo: require('../../Resources/Images/vip/vip2.png'),
        grayLogo: require('../../Resources/Images/vip/vip-g2.png'),
        vip: [
          {
            a: strings.t('bonus_monthly'),
            b: '68',
          },
          {
            a: strings.t('birthday_bonus'),
            b: '68',
          },
          {
            a: strings.t('withdraw_per_day'),
            b: '3',
          },
          {
            a: strings.t('exclusive_domain'),
            b: '✔',
          },
          {
            a: strings.t('deposit_priority'),
            b: '-',
          },
          {
            a: strings.t('festival_gift'),
            b: '-',
          },
          {
            a: strings.t('exclusive_customer_service'),
            b: '-',
          },
          {
            a: strings.t('free_travel'),
            b: '-',
          },
        ],
      },
      {
        id: 3,
        logo: require('../../Resources/Images/vip/vip3.png'),
        grayLogo: require('../../Resources/Images/vip/vip-g3.png'),
        vip: [
          {
            a: strings.t('bonus_monthly'),
            b: `308`,
          },
          {
            a: strings.t('birthday_bonus'),
            b: '308',
          },
          {
            a: strings.t('withdraw_per_day'),
            b: '5',
          },
          {
            a: strings.t('exclusive_domain'),
            b: '✔',
          },
          {
            a: strings.t('deposit_priority'),
            b: '-',
          },
          {
            a: strings.t('festival_gift'),
            b: '-',
          },
          {
            a: strings.t('exclusive_customer_service'),
            b: '-',
          },
          {
            a: strings.t('free_travel'),
            b: '-',
          },
        ],
      },
      {
        id: 4,
        logo: require('../../Resources/Images/vip/vip4.png'),
        grayLogo: require('../../Resources/Images/vip/vip-g4.png'),
        vip: [
          {
            a: strings.t('bonus_monthly'),
            b: `228(${strings.t('weekly')})`,
          },
          {
            a: strings.t('birthday_bonus'),
            b: '538',
          },
          {
            a: strings.t('withdraw_per_day'),
            b: '5',
          },
          {
            a: strings.t('exclusive_domain'),
            b: '✔',
          },
          {
            a: strings.t('deposit_priority'),
            b: '✔',
          },
          {
            a: strings.t('festival_gift'),
            b: '-',
          },
          {
            a: strings.t('exclusive_customer_service'),
            b: '-',
          },
          {
            a: strings.t('free_travel'),
            b: '-',
          },
        ],
      },
      {
        id: 5,
        logo: require('../../Resources/Images/vip/vip5.png'),
        grayLogo: require('../../Resources/Images/vip/vip-g5.png'),
        vip: [
          {
            a: strings.t('bonus_monthly'),
            b: `2288(${strings.t('weekly')})`,
          },
          {
            a: strings.t('birthday_bonus'),
            b: '1088',
          },
          {
            a: strings.t('withdraw_per_day'),
            b: '10',
          },
          {
            a: strings.t('exclusive_domain'),
            b: '✔',
          },
          {
            a: strings.t('deposit_priority'),
            b: '✔',
          },
          {
            a: strings.t('festival_gift'),
            b: '✔',
          },
          {
            a: strings.t('exclusive_customer_service'),
            b: '✔',
          },
          {
            a: strings.t('free_travel'),
            b: '-',
          },
        ],
      },
      {
        id: 6,
        logo: require('../../Resources/Images/vip/vip6.png'),
        grayLogo: require('../../Resources/Images/vip/vip-g6.png'),
        vip: [
          {
            a: strings.t('bonus_monthly'),
            b: `4688(${strings.t('weekly')})`,
          },
          {
            a: strings.t('birthday_bonus'),
            b: '4688',
          },
          {
            a: strings.t('withdraw_per_day'),
            b: '20',
          },
          {
            a: strings.t('exclusive_domain'),
            b: '✔',
          },
          {
            a: strings.t('deposit_priority'),
            b: '✔',
          },
          {
            a: strings.t('festival_gift'),
            b: '✔',
          },
          {
            a: strings.t('exclusive_customer_service'),
            b: '✔',
          },
          {
            a: strings.t('free_travel'),
            b: '✔',
          },
        ],
      },
    ],
    myrVipTab: [
      {
        id: 0,
        logo: require('../../Resources/Images/vip/vip0.png'),
        grayLogo: require('../../Resources/Images/vip/vip-g0.png'),
        vip: [
          {
            a: strings.t('bonus_monthly'),
            b: '-',
          },
          {
            a: strings.t('birthday_bonus'),
            b: '-',
          },
          {
            a: strings.t('withdraw_per_day'),
            b: '3',
          },
          {
            a: strings.t('exclusive_domain'),
            b: '-',
          },
          {
            a: strings.t('deposit_priority'),
            b: '-',
          },
          {
            a: strings.t('festival_gift'),
            b: '-',
          },
          {
            a: strings.t('exclusive_customer_service'),
            b: '-',
          },
          {
            a: strings.t('free_travel'),
            b: '-',
          },
        ],
      },
      {
        id: 1,
        logo: require('../../Resources/Images/vip/vip1.png'),
        grayLogo: require('../../Resources/Images/vip/vip-g1.png'),
        vip: [
          {
            a: strings.t('bonus_monthly'),
            b: '-',
          },
          {
            a: strings.t('birthday_bonus'),
            b: '8',
          },
          {
            a: strings.t('withdraw_per_day'),
            b: '3',
          },
          {
            a: strings.t('exclusive_domain'),
            b: '-',
          },
          {
            a: strings.t('deposit_priority'),
            b: '-',
          },
          {
            a: strings.t('festival_gift'),
            b: '-',
          },
          {
            a: strings.t('exclusive_customer_service'),
            b: '-',
          },
          {
            a: strings.t('free_travel'),
            b: '-',
          },
        ],
      },
      {
        id: 2,
        logo: require('../../Resources/Images/vip/vip2.png'),
        grayLogo: require('../../Resources/Images/vip/vip-g2.png'),
        vip: [
          {
            a: strings.t('bonus_monthly'),
            b: '8',
          },
          {
            a: strings.t('birthday_bonus'),
            b: '8',
          },
          {
            a: strings.t('withdraw_per_day'),
            b: '3',
          },
          {
            a: strings.t('exclusive_domain'),
            b: '✔',
          },
          {
            a: strings.t('deposit_priority'),
            b: '-',
          },
          {
            a: strings.t('festival_gift'),
            b: '-',
          },
          {
            a: strings.t('exclusive_customer_service'),
            b: '-',
          },
          {
            a: strings.t('free_travel'),
            b: '-',
          },
        ],
      },

      {
        id: 3,
        logo: require('../../Resources/Images/vip/vip3.png'),
        grayLogo: require('../../Resources/Images/vip/vip-g3.png'),
        vip: [
          {
            a: strings.t('bonus_monthly'),
            b: '38',
          },
          {
            a: strings.t('birthday_bonus'),
            b: '38',
          },
          {
            a: strings.t('withdraw_per_day'),
            b: '5',
          },
          {
            a: strings.t('exclusive_domain'),
            b: '✔',
          },
          {
            a: strings.t('deposit_priority'),
            b: '-',
          },
          {
            a: strings.t('festival_gift'),
            b: '-',
          },
          {
            a: strings.t('exclusive_customer_service'),
            b: '-',
          },
          {
            a: strings.t('free_travel'),
            b: '-',
          },
        ],
      },
      {
        id: 4,
        logo: require('../../Resources/Images/vip/vip4.png'),
        grayLogo: require('../../Resources/Images/vip/vip-g4.png'),
        vip: [
          {
            a: strings.t('bonus_monthly'),
            b: `28(${strings.t('weekly')})`,
          },
          {
            a: strings.t('birthday_bonus'),
            b: '68',
          },
          {
            a: strings.t('withdraw_per_day'),
            b: '5',
          },
          {
            a: strings.t('exclusive_domain'),
            b: '✔',
          },
          {
            a: strings.t('deposit_priority'),
            b: '✔',
          },
          {
            a: strings.t('festival_gift'),
            b: '-',
          },
          {
            a: strings.t('exclusive_customer_service'),
            b: '-',
          },
          {
            a: strings.t('free_travel'),
            b: '-',
          },
        ],
      },
      {
        id: 5,
        logo: require('../../Resources/Images/vip/vip5.png'),
        grayLogo: require('../../Resources/Images/vip/vip-g5.png'),
        vip: [
          {
            a: strings.t('bonus_monthly'),
            b: `288(${strings.t('weekly')})`,
          },
          {
            a: strings.t('birthday_bonus'),
            b: '128',
          },
          {
            a: strings.t('withdraw_per_day'),
            b: '10',
          },
          {
            a: strings.t('exclusive_domain'),
            b: '✔',
          },
          {
            a: strings.t('deposit_priority'),
            b: '✔',
          },
          {
            a: strings.t('festival_gift'),
            b: '✔',
          },
          {
            a: strings.t('exclusive_customer_service'),
            b: '✔',
          },
          {
            a: strings.t('free_travel'),
            b: '-',
          },
        ],
      },
      {
        id: 6,
        logo: require('../../Resources/Images/vip/vip6.png'),
        grayLogo: require('../../Resources/Images/vip/vip-g6.png'),
        vip: [
          {
            a: strings.t('bonus_monthly'),
            b: `588(${strings.t('weekly')})`,
          },
          {
            a: strings.t('birthday_bonus'),
            b: '588',
          },
          {
            a: strings.t('withdraw_per_day'),
            b: '20',
          },
          {
            a: strings.t('exclusive_domain'),
            b: '✔',
          },
          {
            a: strings.t('deposit_priority'),
            b: '✔',
          },
          {
            a: strings.t('festival_gift'),
            b: '✔',
          },
          {
            a: strings.t('exclusive_customer_service'),
            b: '✔',
          },
          {
            a: strings.t('free_travel'),
            b: '✔',
          },
        ],
      },
    ],
  }
  onBackPressed = () => {
    this.props.navigation.goBack()
  }
  componentDidMount() {
    const vip =
      MyData.country_code == 'THB'
        ? this.state.vipTab.find((item) => {
            return item?.id == MyData.member_vip_level
          })
        : this.state.myrVipTab.find((item) => {
            return item?.id == MyData.member_vip_level
          })
    if (vip) this.setState({ selectedVip: vip })
    else this.setState({ selectedVip: this.state?.vipTab[0] })
  }
  render() {
    return (
      <View
        style={{
          flex: 1,
        }}
      >
        <MainFlowHeaderContainerNoFeedback
          title={strings.t('profile_vip')}
          bgImage={require('../../Resources/Images/Coupon/coupon_bg.png')}
          onBackPress={() => this.onBackPressed()}
        >
          <View
            style={{
              flex: 1,
              justifyContent: 'center',
              paddingHorizontal: HeightDimen(15),
            }}
          >
            <View style={{ flex: 1 }}>
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'center',
                }}
              >
                <Image
                  resizeMode="contain"
                  source={
                    storageData.selected_language == 'bm'
                      ? require('../../Resources/Images/vip/vip_bm_logo.png')
                      : storageData.selected_language == 'th'
                      ? require('../../Resources/Images/vip/vip_thai_logo.png')
                      : storageData.selected_language == 'ch'
                      ? require('../../Resources/Images/vip/vip_ch_logo.png')
                      : require('../../Resources/Images/vip/vip_eng_logo.png')
                  }
                  style={styles.vipLogo}
                />
                {/* <Image
                  resizeMode="contain"
                  source={require("../../Resources/Images/vip/vip.png")}
                  style={[styles.vipLogo, { marginLeft: HeightDimen(20) }]}
                /> */}
              </View>

              <FlatList
                showsHorizontalScrollIndicator={false}
                showsVerticalScrollIndicator={false}
                horizontal={true}
                data={
                  MyData.country_code == 'THB'
                    ? this?.state?.vipTab
                    : this.state.myrVipTab
                }
                contentContainerStyle={{
                  flexGrow: 0,
                  marginVertical: HeightDimen(20),
                  justifyContent: 'center',

                  height: HeightDimen(80),
                }}
                renderItem={({ item, index }) => (
                  <TouchableOpacity
                    key={index}
                    onPress={() => {
                      this.setState({ selectedVip: item })
                    }}
                  >
                    <Image
                      resizeMode="contain"
                      source={
                        this.state?.selectedVip?.id == item?.id
                          ? item.logo
                          : item.grayLogo
                      }
                      style={{
                        ...styles.vipLogo,
                        height: HeightDimen(60),
                        aspectRatio: 1 / 1,
                      }}
                    />
                  </TouchableOpacity>
                )}
                keyExtractor={(item) => String(item.id)}
              />

              <Text
                style={{
                  textAlign: 'center',
                  fontSize: fontDimen(40),
                  color: SecondaryColor,
                  fontWeight: 'bold',
                  marginVertical: HeightDimen(15),
                }}
              >
                VIP {this.state?.selectedVip?.id}
              </Text>

              <FlatList
                numColumns={1}
                data={this.state?.selectedVip?.vip}
                ListEmptyComponent={<EmptyRecordCard />}
                renderItem={({ item, index }) => {
                  return (
                    <View
                      style={[
                        styles.tableContainer,
                        { borderTopWidth: index == 0 ? HeightDimen(2) : 0 },
                      ]}
                    >
                      <View style={styles.labelBorder}></View>
                      <Text style={styles.label}>{item?.a}</Text>
                      <View style={styles.labelBorder}></View>
                      <Text style={styles.label}>{item?.b}</Text>
                      <View style={styles.labelBorder}></View>
                    </View>
                  )
                }}
                keyExtractor={(item, index) => String(index)}
              />
            </View>
          </View>
        </MainFlowHeaderContainerNoFeedback>
      </View>
    )
  }
}
const styles = {
  container: {
    flex: 1,
  },

  tableContainer: {
    flexDirection: 'row',
    height: HeightDimen(50),
    borderBottomWidth: HeightDimen(2),

    borderColor: SecondaryColor,
    alignItems: 'center',
  },
  label: {
    flex: 1,
    textAlign: 'center',
    fontSize: fontDimen(12),
    color: SecondaryColor,
  },
  labelBorder: {
    borderWidth: HeightDimen(1),
    height: '100%',
    borderColor: SecondaryColor,
  },
  vipLogo: {
    height: HeightDimen(155),
    width: 'auto',
    aspectRatio: 3 / 1,
  },
}
export default VIPScreen
