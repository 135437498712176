import React, { Component } from 'react'
import {
  View,
  Modal,
  FlatList,
  Text,
  TouchableOpacity,
  ScrollView,
  Image,
} from 'react-native'
import { fontDimen, HeightDimen, WidthDimen } from '../../UI/Dimensions'
import { strings } from '../../Language/Language'
import MyData from '../../Data/MyData'
import walletAPICaller from '../../API/WalletAPICaller'
import MyToast from '../../Component/Toast/MyToast'
import { RecoverTextInput } from '../../Component/Input/RecoverTextInput'
import { TextButton } from '../../Component/Button/TextButton'
import { FontMainColor, MainColor, SecondaryColor } from '../../UI/Color'

import { debounce } from '../../Utils/Common'

class UsdtTopUpScreen extends Component {
  state = {
    amount: '',
    isLoading: false,
    platformList: [],
    selectedPlatform: strings.t('select_platrform'),
    promo_code: MyData.coupon_code,
    isPromoEdit: MyData.coupon_code ? false : true,
    conversionRate: '',
    depositAmount: '',
  }

  async componentDidMount() {
    await walletAPICaller
      .onGetCurrencyPlatform(MyData.authToken)
      .then((res) => {
        this.setState({
          platformList: res?.data?.response?.listWithIcon,
          selectedPlatform: res?.data?.response?.listWithIcon[0]?.name,
          conversionRate: res?.data?.response?.rate,
        })
      })
      .catch((err) => {
        if (err.response.status == 514) {
          this.props.expiredToken_logout()
        } else {
          MyToast.popToast(err.response.data.msg)
        }
      })
  }
  onCurrencyPreview = async () => {
    if (this.state.amount) {
      this.setState({ isLoading: true })
      const platform =
        this.state.selectedPlatform == strings.t('select_platrform')
          ? ''
          : this.state.selectedPlatform
      await walletAPICaller
        .onCurrencyPreview(MyData.authToken, this.state.amount, platform)
        .then((res) => {
          this.props.navigation.navigate('UsdtReceipt', {
            data: res.data.response,
            onGoBack: (data) => {
              this.onBackPressed(data)
            },
          })
        })
        .catch((err) => {
          if (err.response.status == 514) {
            this.props.expiredToken_logout()
          } else {
            MyToast.popToast(err.response.data.msg)
          }
        })
        .finally(() => {
          this.setState({
            isLoading: false,
          })
        })
    } else {
      MyToast.popToast(strings.t('please_enter_amount'))
    }
  }

  onBackPressed = () => {
    if (this.props.route.params?.backToRedeem) {
      this.props.navigation.pop(2)
      return
    }
    this.props.route.params?.onGoBack()
    this.props.navigation?.goBack()
  }
  handle_amount = (val) => {
    this.setState({ amount: val })
    this.debounceDepositAmount()
  }
  debounceDepositAmount = debounce(() => {
    this.onCurrencyPreviewChange()
  }, 1000)
  handle_promo = (val) => {
    this.setState({ promo_code: val })
  }

  onCurrencyPreviewChange = async () => {
    await walletAPICaller
      .onCurrencyPreview(
        MyData.authToken,
        this.state.amount,
        this.state.selectedPlatform
      )
      .then((res) => {
        this.setState({
          depositAmount: res?.data?.response?.amount,
        })
      })
      .catch((err) => {
        if (err.response.status == 514) {
          this.props.expiredToken_logout()
        } else {
          MyToast.popToast(err.response.data.msg)
        }
      })
  }

  render() {
    return (
      <ScrollView
        style={{
          flex: 1,
        }}
      >
        <View
          style={{
            flex: 1,
            marginTop: HeightDimen(10),
            paddingHorizontal: HeightDimen(15),
          }}
        >
          <Text
            style={{
              ...styles.label,
              textAlign: 'left',
              fontSize: fontDimen(14),
            }}
          >
            {strings.t('select_deposit_method')}
          </Text>
          <FlatList
            showsHorizontalScrollIndicator={false}
            showsVerticalScrollIndicator={false}
            horizontal={true}
            data={this.state.platformList}
            style={{
              flexGrow: 0,
              marginVertical: HeightDimen(7),
            }}
            renderItem={({ item, index }) => {
              return (
                <TouchableOpacity
                  style={{
                    ...styles.container,
                    backgroundColor:
                      item.name == this.state.selectedPlatform
                        ? SecondaryColor
                        : '#ededed',
                  }}
                  onPress={() => {
                    this.setState({
                      selectedPlatform: item.name,
                    })
                  }}
                >
                  <Image
                    source={{ uri: item.icon }}
                    style={{
                      ...styles.image,
                    }}
                  />
                  <Text
                    style={{
                      ...styles.label,
                      color:
                        item.name == this.state.selectedPlatform
                          ? 'white'
                          : MainColor,
                    }}
                  >
                    {item?.name}
                  </Text>
                </TouchableOpacity>
              )
            }}
            keyExtractor={(item, i) => String(i)}
          />

          <RecoverTextInput
            title={strings.t('amount_usdt')}
            placeholder={'0.00'}
            top={true}
            isNumber={true}
            onChangeText={this.handle_amount}
            value={this.state.amount}
            marginBottom={HeightDimen(10)}
          />
          <RecoverTextInput
            title={`${strings.t('deposit_RM')} ${strings.t('amount_RM')} (${
              MyData.country_code
            })`}
            placeholder={'0.00'}
            bottom={true}
            isNumber={true}
            value={this.state.depositAmount?.toString()}
            marginBottom={HeightDimen(10)}
            isEdit={false}
          />
          {/* <RecoverTextInput
            title={strings.t('promo_code}
            placeholder={"lucky"}
            onChangeText={this.handle_promo}
            value={this.state.promo_code}
            bottom={true}
            isEdit={this.state.isPromoEdit}
            marginBottom={HeightDimen(10)}
          /> */}
          <Text
            style={{
              fontSize: fontDimen(13),
              color: MainColor,
              fontWeight: 'bold',
            }}
          >
            {'USDT (TRC20)'}
          </Text>
          <Text
            style={{
              fontSize: fontDimen(13),
              color: MainColor,
              fontWeight: 'bold',
            }}
          >
            {strings.t('conversion_rate')}: {this.state.conversionRate}
          </Text>
          <View
            style={{
              marginTop: HeightDimen(30),
            }}
          >
            <TextButton
              label={strings.t('next')}
              size={5}
              onPress={() => this.onCurrencyPreview()}
              isLoading={this.state.isLoading}
            />
          </View>
        </View>
      </ScrollView>
    )
  }
}

const styles = {
  container: {
    height: HeightDimen(80),
    padding: HeightDimen(5),
    borderRadius: HeightDimen(10),
    alignItems: 'center',
    justifyContent: 'center',
    width: HeightDimen(80),
    backgroundColor: SecondaryColor,
    marginRight: HeightDimen(7),
    borderWidth: HeightDimen(2),
    borderColor: SecondaryColor,
  },
  image: {
    width: 'auto',
    height: HeightDimen(40),
    aspectRatio: 1.5 / 1,
    resizeMode: 'contain',
  },
  label: {
    color: MainColor,
    fontSize: fontDimen(10),
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: HeightDimen(3),
  },
}
export default UsdtTopUpScreen
