import React, { Component } from "react";
import {
  View,
  TouchableOpacity,
  Keyboard,
  ActivityIndicator,
} from "react-native";
import Text from "../../Component/Text/MyText";
import { MainColor, FontMainColor } from "../../UI/Color";
import { HeightDimen, fontDimen } from "../../UI/Dimensions";
import { LoginFlowHeaderContainer } from "../../Component/Header/LoginFlowHeader";
import { NormalTextInput } from "../../Component/Input/NormalTextInput";
import { TextButton } from "../../Component/Button/TextButton";
import { PickBankModal } from "../../Component/Modal/PickBankModal";
import MyToast from "../../Component/Toast/MyToast";
import BankAPICaller from "../../API/BankAPICaller";
import MyData from "../../Data/MyData";
import { strings } from "../../Language/Language";
import { AgreeCheckBox } from "../../Component/CheckBox/AgreeCheckBox";
import { EnterTransactionPinModal } from "../../Component/Modal/EnterTransactionPinModal";
import { ConfirmCancelModal } from "../../Component/Modal/ConfirmCancelModal";

import { letterRegex, numberRegex } from "../../Utils/Common";

class EditBankAccScreen extends Component {
  state = {
    accName: "",
    accNo: "",
    isLoading: false,
    bankList: [],
    selectedBank: strings.t("bank"),
    selectedBankIndex: -1,
    showPickModal: false,
    isDeleteLoading: false,
    isCheck: false,
    showPinModal: false,
    isDeleteModalShow: false,
  };

  componentDidMount() {
    var temp_name = "";
    var temp_index = -1;
    for (let i = 0; i < this.props.route.params.value.length; i++) {
      if (
        this.props.route.params.value[i].bank_id ==
        this.props.route.params.bank.bank_id
      ) {
        temp_name = this.props.route.params.value[i].bank_name;
        temp_index = i;
        break;
      }
    }
    // this.setState({
    //   bankList: this.props.route.params.value,
    //   selectedBank: temp_name,
    //   selectedBankIndex: temp_index,
    //   accName: MyData.bankName,
    //   accNo: MyData.bankAccountNo,
    // });

    this.setState({
      bankList: this.props.route.params.value,
      selectedBank: temp_name,
      selectedBankIndex: temp_index,
      accName: this.props.route.params.bank.account_name,
      accNo: this.props.route.params.bank.account_number,
    });
  }

  onBackPressed = () => {
    this.props.navigation.goBack();
  };

  handle_accName = (val) => {
    if (letterRegex(val)) {
      let findDigit = val?.match(/\d+/);
      if (findDigit) {
        if (findDigit[0]?.length <= 5) this.setState({ accName: val });
        else return;
      } else this.setState({ accName: val });
    } else return;
  };

  handle_accNo = (val) => {
    if (numberRegex(val)) this.setState({ accNo: val });
    else return;
  };
  onSubmit = async () => {
    if (
      this.state.accName != "" &&
      this.state.accNo != "" &&
      this.state.selectedBankIndex >= 0
    ) {
      Keyboard.dismiss();
      this.setState({ showPinModal: true });
    } else {
      MyToast.popToast(strings.t("invalid_data_input"));
    }
  };

  onSubmitChange = async () => {
    this.setState({ isLoading: true });
    await BankAPICaller.updateBankAccounts(
      MyData.authToken,
      this.props.route.params.bank.id,
      this.state.bankList[this.state.selectedBankIndex].bank_id,
      this.state.accNo,
      this.state.accName
    )
      .then((res) => {
        //console.log(res);

        // MyData.bankId = this.state.bankList[
        //   this.state.selectedBankIndex
        // ].bank_id;
        // MyData.bankName = this.state.accName;
        // MyData.bankAccountNo = this.state.accNo;
        MyToast.popToast(strings.t("add_back_account_succ"));
        this.setState({ isLoading: false });
        this.props.route.params.onGoBack(true);
        this.props.navigation.goBack();
      })
      .catch((err) => {
        //console.log(err);
        this.setState({ isLoading: false });
        if (err.response.status == "514") {
          this.props.expiredToken_logout();
        } else {
          MyToast.popToast(err.response.data.msg);
        }
      });
  };

  onBackModal = () => {
    this.setState({ showPickModal: false });
  };

  onSelectBank = (index, id) => {
    this.setState({
      selectedBankIndex: index,
      selectedBank: this.state.bankList[index].bank_name,
      showPickModal: false,
    });
  };

  onShowModal = () => {
    this.setState({
      showPickModal: true,
    });
  };

  checkAgree = (val) => {
    this.setState({ isCheck: val });
  };

  onPress_forgot_pin = () => {
    this.setState({ showPinModal: false });
    this.props.navigation.navigate("RecoverPin");
  };

  onSuccEnterPin = () => {
    this.setState({ showPinModal: false });
    this.onSubmitChange();
  };

  onBackModal = () => {
    this.setState({ showPinModal: false });
  };
  async remove_bank_account() {
    this.setState({ isDeleteLoading: true });
    await BankAPICaller.deleteBankAccounts(
      MyData.authToken,
      this.props.route.params.bank.id
    )
      .then((res) => {
        this.setState({ isDeleteLoading: false });
        this.props.route.params.onGoBack(true);
        this.props.navigation.goBack();
      })
      .catch((err) => {
        this.setState({ isDeleteLoading: false });
        if (err.response.status == "514") {
          this.props.expiredToken_logout();
        } else {
          MyToast.popToast(err.response.data.msg);
        }
      });
  }
  render() {
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: "white",
        }}
      >
        <EnterTransactionPinModal
          isShow={this.state.showPinModal}
          onPressForgotPin={() => this.onPress_forgot_pin()}
          onSuccEnterPin={() => this.onSuccEnterPin()}
          onBackModal={() => this.onBackModal()}
        />
        <ConfirmCancelModal
          isShow={this.state.isDeleteModalShow}
          onConfirmPress={() => this.remove_bank_account()}
          onBackPress={() => this.setState({ isDeleteModalShow: false })}
          textResource={strings.t("confrim_to_remove_back_account")}
        />
        <PickBankModal
          isShow={this.state.showPickModal}
          list={this.state.bankList}
          onBackPressed={() => this.onBackModal()}
          onPress={this.onSelectBank}
        />
        <LoginFlowHeaderContainer
          onBackPress={() => this.onBackPressed()}
          mainText={strings.t("edit_bank_account")}
          subText={strings.t("welcom_text")}
        >
          <View
            style={{
              flex: 1,
              paddingLeft: HeightDimen(30),
              paddingRight: HeightDimen(30),
            }}
          >
            <TouchableOpacity
              onPress={() => this.setState({ isDeleteModalShow: true })}
              style={{
                alignItems: "flex-end",
                marginTop: HeightDimen(20),
              }}
              disabled={this.state.isDeleteLoading}
            >
              {this.state.isDeleteLoading ? (
                <ActivityIndicator size="small" color={MainColor} />
              ) : (
                <Text
                  style={{
                    fontSize: fontDimen(14),
                    color: "red",

                    textDecorationLine: "underline",
                  }}
                >
                  {strings.t("remove")}
                </Text>
              )}
            </TouchableOpacity>
            <View
              style={{
                marginTop: HeightDimen(10),
              }}
            >
              <TouchableOpacity
                onPress={() => this.onShowModal()}
                style={{
                  width: "100%",
                  height: HeightDimen(50),
                  backgroundColor: "#EFF2F4",
                  borderRadius: HeightDimen(10),
                  flexDirection: "row",
                  alignItems: "center",
                  padding: HeightDimen(15),
                  marginBottom: HeightDimen(20),
                }}
              >
                <Text
                  numberOfLines={1}
                  style={{
                    flex: 1,
                    fontSize: fontDimen(15),
                    color:
                      this.state.selectedBankIndex >= 0
                        ? FontMainColor
                        : "#B8BABD",
                  }}
                >
                  {this.state.selectedBank}
                </Text>
              </TouchableOpacity>
              {/* <NormalTextInput
                                placeholder={'Bank'}
                                marginBottom={HeightDimen(20)}
                                hideImage={true}
                                onChangeText={this.handle_bank}
                            /> */}

              <NormalTextInput
                placeholder={strings.t("account_name")}
                marginBottom={HeightDimen(20)}
                hideImage={true}
                onChangeText={this.handle_accName}
                value={this.state.accName}
              />
              <NormalTextInput
                placeholder={strings.t("account_no")}
                marginBottom={HeightDimen(20)}
                hideImage={true}
                isNumber={true}
                onChangeText={this.handle_accNo}
                value={this.state.accNo}
              />
            </View>
            <View
              style={{
                marginTop: HeightDimen(30),
              }}
            >
              <GradientTextButton
                label={strings.t("next")}
                gradColor={["#ffee8d", "#f7c56e"]}
                onPress={() => this.onSubmit()}
                size={"100%"}
                isLoading={this.state.isLoading}
              />
            </View>
          </View>
        </LoginFlowHeaderContainer>
      </View>
    );
  }
}

export default EditBankAccScreen;
