import React, { Component } from 'react'
import { View, Modal, Image, Text, TouchableOpacity } from 'react-native'
import { fontDimen, HeightDimen } from '../../UI/Dimensions'
import { LoginFlowHeaderContainer } from '../../Component/Header/LoginFlowHeader'
import { TextButton } from '../../Component/Button/TextButton'
import APICaller from '../../API/APICaller'
import Mydata from '../../Data/MyData'
import MyToast from '../../Component/Toast/MyToast'
import { strings } from '../../Language/Language'
import { RecoverTextInput } from '../../Component/Input/RecoverTextInput'
import {
  generateRandom,
  getDeviceInfo,
  resetNavigationStack,
} from '../../Utils/Common'
import storageData from '../../Data/StorageData'
import { FontSecondaryColor, SecondaryColor } from '../../UI/Color'

class Verificaion extends Component {
  state = {
    isLoading: false,
    phoneNo: '',
    deviceInfo: '',
    otp: '',
    deviceName: '',
    isModalShow: false,
    otpPrefix: '',
  }

  async componentDidMount() {
    const deviceInfoData = await getDeviceInfo()
    const deviceName = navigator.userAgentData?.brands[1]?.brand
    const deviceInfo = navigator.userAgent

    debugger
    this.setState({
      deviceName,
      deviceInfo,
      phoneNo: this.props.route.params.phoneNumber,
    })
  }
  handle_otp = (otp) => {
    this.setState({ otp: otp })
  }

  onBackPressed = () => {
    this.props.navigation.goBack()
  }

  onGetOTP = async () => {
    let otpPrefix = generateRandom(4)
    this.setState({ otpPrefix: otpPrefix })
    await APICaller.GetOTP(otpPrefix)
      .then((res) => {
        // Mydata.userToken = res.response.userToken;
      })
      .catch((err) => {
        MyToast.popToast(err.response.data.msg)
      })
  }
  onModalShow = (res) => {
    this.setState({ isModalShow: true })
    setTimeout(() => {
      this.setState({ isModalShow: false })
      if (res.pin_status == 'false') {
        this.props.isLogin_Ready_set_pin()
      } else {
        Mydata.setUserPin(res.wallet_pin)
        if (this.props.route.params.isMultiLogin)
          resetNavigationStack(this.props.navigation, 'Home')
        else this.props.isLogin_Ready()
      }
    }, 5000)
  }
  onOTPBindConfirm = async () => {
    if (this.state.otp) {
      this.setState({ isLoading: true })
      await APICaller.verification(this.state.otp)
        .then(async (res) => {
          Mydata.setUp(res)
          //console.log(res)
          await storageData.set_username_add_password(
            this.props.route.params.username,
            this.props.route.params.password,
            res?.response?.authSession
          )
          if (this.props.route.params.isMultiLogin) {
            await storageData.store_multi_account_data(
              this.props.route.params.username,
              this.props.route.params.password,
              res.response.user.member_avatar_url,
              res.response.user.member_login_token
            )
          }
          this.onModalShow(res.response.user)
        })
        .catch((err) => {
          MyToast.popToast(err.response.data.msg)
        })
        .finally(() => {
          this.setState({ isLoading: false })
        })
    } else {
      MyToast.popToast(strings.t('enter_otp'))
    }
  }
  renderSuccessModal = () => {
    return (
      <Modal
        animationType="slide"
        transparent={true}
        visible={this.state.isModalShow}
        statusBarTranslucent
      >
        <View
          style={{
            flex: 1,
            backgroundColor: 'rgba(0,0,0,0.5)',
            justifyContent: 'center',
          }}
        >
          <View
            style={{
              backgroundColor: 'white',
              padding: HeightDimen(15),
              justifyContent: 'center',
              alignItems: 'center',
              marginHorizontal: HeightDimen(30),
              flex: 0.2,
              borderRadius: HeightDimen(10),
            }}
          >
            <Image
              source={require('../../Resources/Images/succ_tick.png')}
              resizeMode="contain"
              style={{ height: '70%', width: '50%' }}
            />
            <Text
              style={{
                fontSize: fontDimen(18),
                fontWeight: 'bold',
                textAlign: 'center',
                width: '100%',
              }}
            >
              OTP bind successfully
            </Text>
          </View>
        </View>
      </Modal>
    )
  }
  render() {
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: 'white',
        }}
      >
        <LoginFlowHeaderContainer
          onBackPress={() => this.onBackPressed()}
          mainText={strings.t('verification')}
          subText={strings.t('register_device_text')}
        >
          <View
            style={{
              flex: 1,
              paddingLeft: HeightDimen(30),
              paddingRight: HeightDimen(30),
              paddingBottom: HeightDimen(30),
            }}
          >
            <View
              style={{
                marginTop: HeightDimen(40),
              }}
            >
              <RecoverTextInput
                title={strings.t('device_name')}
                placeholder={strings.t('device_name')}
                top={true}
                isEdit={false}
                value={this.state.deviceName}
              />
              <View style={{ height: HeightDimen(3) }}></View>
              <RecoverTextInput
                title={strings.t('device_info')}
                placeholder={strings.t('device_info')}
                isEdit={false}
                value={this.state.deviceInfo}
              />
              <View style={{ height: HeightDimen(3) }}></View>
              <RecoverTextInput
                title={strings.t('phone_number')}
                placeholder={strings.t('phone_number')}
                bottom={true}
                isEdit={false}
                value={this.state.phoneNo}
              />
              <View style={{ height: HeightDimen(3) }}></View>
              <RecoverTextInput
                title={`${strings.t('otp_text')} ${
                  this.state.otpPrefix ? `  ${this.state.otpPrefix} -` : ''
                }`}
                placeholder={strings.t('otp_text')}
                bottom={true}
                isCounter
                isNumber={true}
                onChangeText={this.handle_otp}
                onOtpPress={() => this.onGetOTP()}
              />
            </View>

            <View
              style={{
                width: '100%',
                alignItems: 'center',
                marginTop: HeightDimen(20),
              }}
            >
              <TextButton
                label={strings.t('confirm')}
                size={5}
                onPress={() => this.onOTPBindConfirm()}
                isLoading={this.state.isLoading}
              />
            </View>
            <TouchableOpacity
              style={{
                flex: 1,
                justifyContent: 'center',
                alignItems: 'flex-end',
                flexDirection: 'row',
              }}
              onPress={() => this.props.navigation.navigate('ContactUs')}
            >
              <Image
                source={require('../../Resources/Images/support.png')}
                style={{
                  width: 'auto',
                  height: HeightDimen(25),
                  aspectRatio: 1 / 1,
                  resizeMode: 'contain',
                  tintColor: SecondaryColor,
                }}
              />
              <Text
                style={{
                  fontSize: fontDimen(16),
                  color: SecondaryColor,
                  marginLeft: HeightDimen(7),
                }}
              >
                {strings.t('contactUs')}
              </Text>
            </TouchableOpacity>
          </View>
        </LoginFlowHeaderContainer>
        {this.renderSuccessModal()}
      </View>
    )
  }
}

export default Verificaion
