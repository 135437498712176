import React, { Component } from "react";
import { View, ScrollView, Modal } from "react-native";
import * as RNLocalize from "react-native-localize";
import Text from "../../Component/Text/MyText";
import { FontMainColor, PrimaryColor } from "../../UI/Color";
import { HeightDimen, fontDimen } from "../../UI/Dimensions";
import { MainFlowHeaderContainer } from "../../Component/Header/NewDesign/MainFlowHeader";
import { SettingCard } from "../../Component/Card/SettingCard";
import { TextButton } from "../../Component/Button/TextButton";
import MyData from "../../Data/MyData";
import { strings } from "../../Language/Language";
import storageData from "../../Data/StorageData";
import { getSelectedLanguage, resetNavigationStack } from "../../Utils/Common";
import aPICaller from "../../API/APICaller";
import MyToast from "../../Component/Toast/MyToast";
import { MultiAccountModal } from "../../Component/Modal/MultiAccountModal";

//import CodePush from "react-native-code-push";
import { ConfirmCancelModal } from "../../Component/Modal/ConfirmCancelModal";
import { GradientTextButton } from "../../Component";

class SettingScreen extends Component {
  state = {
    show_UID: false,
    timezone: "",
    isSwitchAccountModal: false,
    LoginUsersList: [],
    isLoading: false,
    cacheModal: false,
    showCancelModal: false,
  };

  async componentDidMount() {
    await this.onRefreshLoginList();
    var date = new Date();
    var offsetInHours = date.getTimezoneOffset() / 60;
    offsetInHours = offsetInHours * -1;
    this.setState({
      timezone:
        "UST/GMT " +
        (offsetInHours < 0 ? offsetInHours : "+" + offsetInHours) +
        ":00 " +
        RNLocalize.getTimeZone(),
    });
    this.props.navigation.addListener("focus", () => {
      //console.log('aaa');
      this.setState({});
    });
  }

  constructor(props) {
    super(props);
  }

  onToggle_UID = () => {
    if (this.state.show_UID) {
      this.setState({ show_UID: false });
    } else {
      this.setState({ show_UID: true });
    }
  };

  async onRefreshLoginList() {
    await storageData.get_multiLogin_List();
    storageData.LoginList.forEach((item, i) => {
      if (item.username == MyData.member_username) {
        storageData.LoginList.splice(i, 1);
        storageData.LoginList.unshift(item);
      }
    });
    debugger;
    this.setState({ LoginUsersList: storageData.LoginList });
  }
  onBackPressed = () => {
    this.props?.route?.params?.onGoBack();
    this.props?.navigation?.goBack();
  };

  onPress_ChangePass = () => {
    this.props.navigation.navigate("ChangeLoginPassword");
  };

  onPress_ChangePin = () => {
    this.props.navigation.navigate("ChangePin");
  };

  onPress_changeNickName = () => {
    this.props.navigation.navigate("ChangeNickName", {
      onGoBack: (data) => {
        this.refresh(data);
      },
    });
  };
  onPress_changePhoneNo = () => {
    this.props.navigation.navigate("ChangePhoneNumber", {
      onGoBack: (data) => {
        this.refresh(data);
      },
    });
  };

  onPress_language = () => {
    this.props.navigation.navigate("Language");
  };

  onPress_aboutPhone = () => {
    this.props.navigation.navigate("AboutPhone");
  };

  onPress_about = () => {
    this.props.navigation.navigate("About");
  };
  onPress_googleAuthenticate = () => {
    this.props.navigation.navigate("GoogleAuthenticate");
  };
  onPress_Realname_Verification = () => {
    this.props.navigation.navigate("RealnameVerification");
  };

  onPress_logout = () => {
    MyData.clear_all_data();
    storageData.clear_storage();
    this.props.isLogout_Ready();
  };
  onPress_Clear_Cache = async () => {
    MyData.clear_all_data();
    await storageData.clear_app_storage();
    this.setState({ cacheModal: false });
    //CodePush.clearUpdates();
    // CodePush.restartApp();
  };

  onPress_pull_out_credit = async () => {
    this.setState({ isLoading: true });
    await aPICaller
      .onPullOutCredit(MyData.authToken)
      .then((res) => {
        MyToast.popToast(res?.data?.msg);
        this.toggle_cancal_modal();
      })
      .catch((err) => {
        MyToast.popToast(err.response.data.msg);
      })
      .finally(() => {
        this.setState({
          isLoading: false,
        });
      });
  };
  renderClearCacheModal() {
    return (
      <Modal
        animationType={"slide"}
        transparent={true}
        visible={this.state.cacheModal}
        statusBarTranslucent
        onRequestClose={() => {
          this.setState({ cacheModal: false });
        }}
      >
        <View
          style={{
            flex: 1,
            backgroundColor: "rgba(0,0,0,0.5)",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <View
            style={{
              backgroundColor: "white",
              padding: HeightDimen(20),
              marginHorizontal: HeightDimen(20),
              borderRadius: HeightDimen(5),
            }}
          >
            <Text
              style={{
                fontSize: fontDimen(20),
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              {strings.t("reset_app_title")}
            </Text>

            <Text
              style={{
                fontSize: fontDimen(17),
                textAlign: "center",
                marginTop: HeightDimen(10),
              }}
            >
              {strings.t("reset_app_description")}
            </Text>
            <View
              style={{
                marginTop: HeightDimen(40),
                justifyContent: "center",
                flexDirection: "row",
              }}
            >
              <TextButton
                onPress={() => this.setState({ cacheModal: false })}
                label={"No"}
                blackBtn
                size={2.5}
              />
              <TextButton
                onPress={() => {
                  this.onPress_Clear_Cache();
                }}
                label={"Yes"}
                size={2.5}
                marginLeft={HeightDimen(10)}
              />
            </View>
          </View>
        </View>
      </Modal>
    );
  }
  refresh = (data) => {
    this.setState({});
  };

  onSelectLogin = async (username, password, token) => {
    if (username != "" && token != "") {
      this.setState({ isLoading: true });
      await aPICaller
        .verify_Login_token(username, token)
        .then(async (res) => {
          this.setState({ isLoading: false, isSwitchAccountModal: false });
          MyData.setUp(res);
          await storageData.set_username_add_password(
            username,
            password,
            res.response.authSession
          );
          MyData.setUserPin(res.response.user.wallet_pin);
          resetNavigationStack(this.props.navigation, "Home");
        })
        .catch((err) => {
          this.setState({ isLoading: false, isSwitchAccountModal: false });
          if (err?.response?.status == 304) {
            this.props.navigation.navigate("GoogleAuthKeyVerification", {
              username: username,
              password: password,
              isSwitchAccount: true,
            });
          } else MyToast.popToast(err.response.data.msg);
        });
    }
  };
  onAddAccount() {
    this.props.navigation.navigate("MultiLogin", {
      onGoBack: (data) => {
        this.onRefreshLoginList(data);
      },
    });
    this.setState({ isSwitchAccountModal: false });
  }
  onDeleteUser = async (username, token) => {
    const existingList = storageData.LoginList.filter((item) => {
      return item.username !== username && item.token !== token;
    });
    await storageData.updateMulitLogin(existingList);

    this.onRefreshLoginList();
  };
  toggle_cancal_modal = () => {
    this.setState({ showCancelModal: !this.state.showCancelModal });
  };
  render() {
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: "white",
        }}
      >
        <ConfirmCancelModal
          isShow={this.state.showCancelModal}
          textResource={strings.t("pull_out_credit_message")}
          onConfirmPress={() => this.onPress_pull_out_credit()}
          isLoading={this.state.isLoading}
          onBackPress={() => {
            this.toggle_cancal_modal();
          }}
        />
        <MainFlowHeaderContainer
          title={strings.t("setting")}
          onBackPress={() => this.onBackPressed()}
        >
          <MultiAccountModal
            isShow={this.state.isSwitchAccountModal}
            isLoading={this.state.isLoading}
            loginUserList={this.state.LoginUsersList}
            onAddAccountPress={() => this.onAddAccount()}
            onDeleteUserPress={(username, token) =>
              this.onDeleteUser(username, token)
            }
            onSelectLoginPress={(username, password, token) =>
              this.onSelectLogin(username, password, token)
            }
            onCloseModalPress={() => {
              this.setState({ isSwitchAccountModal: false });
            }}
          />
          <ScrollView>
            <View
              style={{
                flex: 1,
                paddingLeft: HeightDimen(30),
                paddingRight: HeightDimen(30),
                paddingTop: HeightDimen(20),
              }}
            >
              {MyData.directLine === "0" && (
                <View>
                  <SettingCard
                    label={strings.t("change_login_password")}
                    onPress={() => this.onPress_ChangePass()}
                  />
                  <View style={{ height: HeightDimen(3) }}></View>
                  <SettingCard
                    label={strings.t("change_payment_pin")}
                    onPress={() => this.onPress_ChangePin()}
                  />
                  <View style={{ height: HeightDimen(3) }}></View>
                  <SettingCard
                    label={strings.t("change_nickname")}
                    onPress={() => this.onPress_changeNickName()}
                  />
                  <View style={{ height: HeightDimen(3) }}></View>
                  <SettingCard
                    label={strings.t("change_phone_no")}
                    onPress={() => this.onPress_changePhoneNo()}
                  />

                  <View style={{ height: HeightDimen(3) }}></View>
                  <SettingCard
                    label={strings.t("manage_account")}
                    onPress={() => {
                      this.setState({ isSwitchAccountModal: true });
                    }}
                  />
                  <View style={{ height: HeightDimen(3) }}></View>
                  <SettingCard
                    label={strings.t("pull_out_all_credit")}
                    onPress={() => this.toggle_cancal_modal()}
                  />
                  <View style={{ height: HeightDimen(3) }}></View>
                  <SettingCard
                    label={strings.t("google_auth")}
                    onPress={() => this.onPress_googleAuthenticate()}
                  />
                  <View style={{ height: HeightDimen(3) }}></View>
                  <SettingCard
                    label={strings.t("real_name_verification")}
                    onPress={() => this.onPress_Realname_Verification()}
                  />
                </View>
              )}

              <View
                style={{
                  marginTop: HeightDimen(20),
                }}
              >
                <SettingCard
                  label={getSelectedLanguage()}
                  onPress={() => this.onPress_language()}
                />
                {/* <View style={{height: HeightDimen(3)}}></View>
                            <SettingCard
                                label={strings.t('clear_cache}
                                onPress={() => this.onPress_clearCache()}
                            /> */}
                {/* <View style={{ height: HeightDimen(3) }}></View>
                <SettingCard
                  label={strings.t('about_phone')}
                  onPress={() => this.onPress_aboutPhone()}
                /> */}
                <View style={{ height: HeightDimen(3) }}></View>
                <SettingCard
                  label={strings.t("about")}
                  onPress={() => this.onPress_about()}
                />

                {/* <View style={{ height: HeightDimen(3) }}></View>
                <SettingCard
                  label={strings.t('change_app_icon')}
                  onPress={() => {
                    this.props.navigation.navigate('AppIcon')
                  }}
                /> */}

                <View
                  style={{
                    width: "100%",
                    height: HeightDimen(50),
                    backgroundColor: PrimaryColor,
                    flexDirection: "row",
                    padding: HeightDimen(15),
                    alignItems: "center",
                    marginTop: HeightDimen(3),
                  }}
                >
                  <View
                    style={{
                      marginRight: HeightDimen(15),
                    }}
                  >
                    <Text
                      style={{
                        fontSize: fontDimen(15),
                        color: FontMainColor,
                      }}
                    >
                      {strings.t("time_zone")}
                    </Text>
                  </View>
                  <Text
                    style={{
                      fontSize: fontDimen(12),
                      color: FontMainColor,
                      flex: 1,
                    }}
                  >
                    {this.state.timezone}
                  </Text>
                </View>
              </View>

              <View
                style={{
                  marginTop: HeightDimen(50),
                  marginBottom: HeightDimen(20),
                }}
              >
                <GradientTextButton
                  label={strings.t("sign_out")}
                  gradColor={["#ffee8d", "#f7c56e"]}
                  onPress={() => this.onPress_logout()}
                  size={"100%"}
                />
              </View>

              {/* <View
                style={{
                  marginTop: HeightDimen(20),
                  marginBottom: HeightDimen(20),
                }}
              >
                <TextButton
                  label={strings.t('reset_app')}
                  blackBtn
                  size={5}
                  onPress={() => this.setState({ cacheModal: true })}
                />
              </View> */}
            </View>
            {this.renderClearCacheModal()}
          </ScrollView>
        </MainFlowHeaderContainer>
      </View>
    );
  }
}

export default SettingScreen;
