import React, { Component } from 'react'
import { View, Modal } from 'react-native'
import { WidthDimen, HeightDimen } from '../../UI/Dimensions'
import { TextButton } from '../../Component/Button/TextButton'
import { strings } from '../../Language/Language'
import { ProfileCard } from '../Card/NewDesign/ProfileCard'
import { Line } from '../Line/Line'

const ConfirmBackModal = (props) => {
  const { isShow, onPress, onBackPress, onCloseGamePress } = props

  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={isShow}
      statusBarTranslucent
    >
      <View
        style={{
          flex: 1,
          backgroundColor: 'rgba(0,0,0,0.5)',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <View
          style={{
            backgroundColor: 'white',
            padding: HeightDimen(20),
            marginHorizontal: HeightDimen(20),
            borderRadius: HeightDimen(10),
          }}
        >
          {/* <Text
            style={{
              fontSize: fontDimen(20),
              color: "red",
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            {strings.back}
          </Text>
          <Text
            style={{
              fontSize: fontDimen(15),
              color: FontMainColor,
              textAlign: "center",
            }}
          >
            {strings.confirm_go_back}
          </Text> */}
          <ProfileCard
            src={require('../../Resources/Images/Chat/icon_5.png')}
            label={strings.t('game_detail')}
            hideShowNext
            onPress={() => onPress('')}
          />
          <Line marginTop={HeightDimen(8)} />
          <ProfileCard
            src={require('../../Resources/Images/Footer/icon_1.png')}
            label={strings.t('home')}
            hideShowNext
            onPress={() => onPress('Home')}
          />
          <Line marginTop={HeightDimen(8)} />
          <ProfileCard
            src={require('../../Resources/Images/Footer/cash_icon.png')}
            label={strings.t('top_up')}
            hideShowNext
            onPress={() => onPress('TopUp')}
          />
          <Line marginTop={HeightDimen(8)} />
          <ProfileCard
            src={require('../../Resources/Images/Footer/icon_2.png')}
            label={strings.t('withdraw')}
            hideShowNext
            onPress={() => onPress('Withdraw')}
          />
          <Line marginTop={HeightDimen(8)} />
          <ProfileCard
            src={require('../../Resources/Images/Footer/report_icon.png')}
            label={strings.t('statment')}
            hideShowNext
            onPress={() => onPress('AllGameTransaction')}
          />
          <Line marginTop={HeightDimen(8)} />
          <View
            style={{
              marginTop: HeightDimen(40),
              justifyContent: 'center',
              flexDirection: 'row',
            }}
          >
            <TextButton
              onPress={onBackPress}
              label={strings.t('cancel')}
              size={2.5}
            />
            <View
              style={{
                marginLeft: WidthDimen(10),
              }}
            >
              <TextButton
                onPress={onCloseGamePress}
                label={strings.t('close_game')}
                blackBtn={true}
                size={2.5}
              />
            </View>
          </View>
        </View>
      </View>
    </Modal>
  )
}

export { ConfirmBackModal }
