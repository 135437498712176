import * as React from 'react'
import {
  Modal,
  View,
  FlatList,
  TouchableOpacity,
  Image,
  TextInput,
} from 'react-native'
import { FontMainColor } from '../../UI/Color'
import { fontDimen, HeightDimen } from '../../UI/Dimensions'
import Text from '../Text/MyText'
//import DatePicker from "react-native-date-picker";
import { TextButton } from '../../Component/Button/TextButton'

class DateModal extends React.Component {
  state = {
    date: null,
  }

  componentDidMount() {
    this.setState({ date: new Date() })
  }

  handleDate = (val) => {
    this.setState({
      date: val,
    })
  }

  set_date = () => {
    let day = this.state.date.getDate()
    let month = this.state.date.getMonth() + 1
    let year = this.state.date.getFullYear()

    let returnDate =
      year +
      '-' +
      (month < 10 ? '0' : '') +
      month +
      '-' +
      (day < 10 ? '0' : '') +
      day

    let end_date = new Date(year, month, 0)
    let end_day = end_date.getDate()
    let monthDate = (month < 10 ? '0' : '') + month + '-' + year
    let returnEndDate =
      year +
      '-' +
      (month < 10 ? '0' : '') +
      month +
      '-' +
      (end_day < 10 ? '0' : '') +
      end_day

    this.props.onClose(returnDate, returnEndDate, monthDate)
  }

  render() {
    const { isShow, label } = this.props

    return (
      <Modal animationType="slide" transparent={true} visible={isShow}>
        <View
          style={{
            flex: 1,
            backgroundColor: 'rgba(0,0,0,0.5)',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <View
            style={{
              backgroundColor: 'white',
              borderRadius: HeightDimen(10),
              padding: HeightDimen(20),
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Text
              style={{
                fontSize: fontDimen(20),
                color: FontMainColor,
                textAlign: 'center',
              }}
            >
              {label}
            </Text>
            <DatePicker
              date={this.state.date}
              mode={'date'}
              androidVariant={'iosClone'}
              onDateChange={this.handleDate}
              style={{
                marginBottom: HeightDimen(10),
              }}
              textColor={FontMainColor}
              theme="light"
              //maximumDate={new Date()}
              //onDateChange={setDate}
            />
            <TextButton
              size={4}
              label={'Select'}
              onPress={() => this.set_date()}
            />
          </View>
        </View>
      </Modal>
    )
  }
}

export { DateModal }
