import React, { Component } from 'react'
import { View, StatusBar } from 'react-native'
import { WidthDimen, HeightDimen } from '../../UI/Dimensions'
import { LoginFlowHeaderContainer } from '../../Component/Header/LoginFlowHeader'
import { TextButton } from '../../Component/Button/TextButton'
import NumberPad from '../../Component/NumberPad/NumberPad'
import { OTPCard } from '../../Component/Card/OTPCard'
import Mydata from '../../Data/MyData'
import { strings } from '../../Language/Language'

class CreatePinScreen extends Component {
  state = {
    OTP: '',
    isLoading: false,
  }

  componentDidMount() {}
  onBackPressed = () => {
    Mydata.clear_all_data()
    this.props.navigation.goBack()
  }

  onEnterOTP = (num) => {
    let temp_OTP = this.state.OTP

    if (num === 'X') {
      if (temp_OTP.length > 0) {
        temp_OTP = temp_OTP.slice(0, -1)
        this.setState({ OTP: temp_OTP })
      }
    } else {
      if (temp_OTP.length < 6) {
        temp_OTP = temp_OTP + num
        this.setState({ OTP: temp_OTP })
      }
    }
  }

  onNextPressed = async () => {
    if (this.state.OTP.length == 6) {
      this.props.navigation.navigate('CreatePinSecond', {
        value: this.state.OTP,
      })
    }
  }

  render_otp_input_box = () => {
    return (
      <View
        style={{
          justifyContent: 'center',
          flexDirection: 'row',
          marginBottom: HeightDimen(40),
        }}
      >
        <OTPCard value={this.state.OTP} num={0} />
        <OTPCard marginLeft={WidthDimen(10)} value={this.state.OTP} num={1} />
        <OTPCard marginLeft={WidthDimen(10)} value={this.state.OTP} num={2} />
        <OTPCard marginLeft={WidthDimen(10)} value={this.state.OTP} num={3} />
        <OTPCard marginLeft={WidthDimen(10)} value={this.state.OTP} num={4} />
        <OTPCard marginLeft={WidthDimen(10)} value={this.state.OTP} num={5} />
      </View>
    )
  }

  render() {
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: 'white',
        }}
      >
        <StatusBar translucent backgroundColor="transparent" />
        <LoginFlowHeaderContainer
          onBackPress={() => this.onBackPressed()}
          mainText={strings.t('create_pin')}
          subText={strings.t('creat_pin_description')}
          hideBack={true}
          wantStepper
          stepValue={Mydata.stepList.length == 4 ? 3 : 4}
          stepList={Mydata.stepList}
        >
          <View
            style={{
              flex: 1,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <this.render_otp_input_box />
            <View
              style={{
                width: '100%',
                paddingLeft: HeightDimen(30),
                paddingRight: HeightDimen(30),
              }}
            >
              <TextButton
                label={strings.t('next')}
                size={5}
                onPress={() => this.onNextPressed()}
                isLoading={this.state.isLoading}
              />
            </View>
          </View>
          <NumberPad onPressSelect={this.onEnterOTP} />
        </LoginFlowHeaderContainer>
      </View>
    )
  }
}

export default CreatePinScreen
