import React, { useEffect } from "react";
import { View, TouchableOpacity } from "react-native";
import { SecondaryColor } from "../../UI/Color";
import { WidthDimen, HeightDimen } from "../../UI/Dimensions";

const OTPCard = (props) => {
  const { marginLeft, value, num, onPress, enablePress } = props;
  console.log("OTP Input");
  // useEffect(() => {}, [value]);
  return (
    <TouchableOpacity
      disabled={enablePress ? false : true}
      onPress={onPress}
      style={{
        width: "auto",
        height: HeightDimen(50),
        aspectRatio: 1 / 1.3,
        backgroundColor: "#EFF2F4",
        borderRadius: HeightDimen(10),
        justifyContent: "center",
        alignItems: "center",
        marginLeft: marginLeft || WidthDimen(10),
      }}
    >
      <View
        style={{
          width: "auto",
          height: HeightDimen(10),
          aspectRatio: 1 / 1,
          backgroundColor: value.length > num ? SecondaryColor : null,
          borderRadius: 9999,
        }}
      ></View>
    </TouchableOpacity>
  );
};

export { OTPCard };
