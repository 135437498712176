import React, { Component } from 'react'
import { View, Image, Text, TouchableOpacity, ScrollView } from 'react-native'
import {
  FontSecondaryColor,
  SecondaryColor,
  FontMainColor,
} from '../../UI/Color'
import { HeightDimen, fontDimen, WidthDimen } from '../../UI/Dimensions'
import { strings } from '../../Language/Language'
import MyData from '../../Data/MyData'
import { MainFlowHeaderContainerNoFeedback } from '../../Component/Header/MainFlowHeader'
import walletAPICaller from '../../API/WalletAPICaller'
import MyToast from '../../Component/Toast/MyToast'
import { RecoverTextInput } from '../../Component/Input/RecoverTextInput'
import { TextButton } from '../../Component/Button/TextButton'
import { SuccessModal } from '../../Component/Modal/SuccessModal'
import Clipboard from '@react-native-clipboard/clipboard'

class UsdtReceiptScreen extends Component {
  state = {
    amount: '',
    orignalAmount: '',
    qrCode: '',
    receipt: '',
    accountNo: '',
    accountName: '',
    dateTime: '',

    rate: '',
    bankId: '',
    isLoading: false,
    isSuccessModal: false,
  }
  componentDidMount() {
    const routeParam = this.props.route.params.data

    this.setState({
      amount: routeParam.amount,
      rate: routeParam.rate,
      qrCode: routeParam.bank ? routeParam.bank.topup_bank_icon_url : '',
      accountNo: routeParam.bank
        ? routeParam.bank.topup_bank_account_number
        : '',
      accountName: routeParam.bank
        ? routeParam.bank.topup_bank_account_name
        : '',
      dateTime: routeParam.bank
        ? routeParam.bank.topup_bank_created_datetime
        : '',

      bankId: routeParam.bank ? routeParam.bank.topup_bank_id : '',
      orignalAmount: routeParam.original,
    })
  }
  onRequestTopupUsdt = async () => {
    const { amount, bankId, receipt } = this.state
    if (receipt) {
      this.setState({ isLoading: true })
      await walletAPICaller
        .onRequestTopupUsdt(
          MyData.authToken,
          amount,
          bankId,
          receipt,
          MyData.coupon_code
        )
        .then((res) => {
          this.setState({ isSuccessModal: true })
        })
        .catch((err) => {
          if (err.response.status == 514) {
            this.props.expiredToken_logout()
          } else {
            MyToast.popToast(err.response.data.msg)
          }
        })
        .finally(() => {
          this.setState({
            isLoading: false,
          })
        })
    } else {
      MyToast.popToast(strings.t('please_enter_txid'))
    }
  }

  onBackPressed = () => {
    this.props.navigation.goBack()
  }
  handle_receipt = (val) => {
    this.setState({ receipt: val })
  }
  onPressCopy = (value) => {
    Clipboard.setString(decodeURIComponent(value))
    MyToast.popToast(strings.t('copied'))
  }
  renderTitle = (title, value, copyIcon) => {
    return (
      <View
        style={{
          flexDirection: 'row',
          marginBottom: HeightDimen(15),
        }}
      >
        <Text
          style={{
            fontSize: fontDimen(15),
            color: FontSecondaryColor,
            width: WidthDimen(110),
          }}
        >
          {title}
        </Text>
        <Text
          style={{
            fontSize: fontDimen(15),
            color: FontMainColor,
            flex: 1,
          }}
        >
          {value}
        </Text>
        {copyIcon && (
          <TouchableOpacity
            onPress={() => this.onPressCopy(value)}
            style={{
              width: 'auto',
              height: HeightDimen(25),
              aspectRatio: 1 / 1,
              backgroundColor: SecondaryColor,
              borderRadius: HeightDimen(7),
              justifyContent: 'center',
              alignItems: 'center',
              marginLeft: 5,
            }}
          >
            <Image
              source={require('../../Resources/Images/icon_copy.png')}
              style={{
                width: 'auto',
                height: HeightDimen(15),
                aspectRatio: 1 / 1,
                resizeMode: 'contain',
                tintColor: 'white',
              }}
            />
          </TouchableOpacity>
        )}
      </View>
    )
  }
  render() {
    const {
      amount,
      rate,
      qrCode,
      accountNo,
      accountName,
      dateTime,

      orignalAmount,
    } = this.state
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: 'white',
        }}
      >
        {this.state.isSuccessModal && (
          <SuccessModal
            isShow={this.state.isSuccessModal}
            isSuccess={true}
            message={strings.t('request_topup_success')}
            onConfirmPress={() => {
              this.setState({ isSuccessModal: false })
              this.onBackPressed()
              this.props.route.params.onGoBack()
            }}
          />
        )}
        <MainFlowHeaderContainerNoFeedback
          title={strings.t('topUpUSDTReceipt')}
          onBackPress={() => this.onBackPressed()}
        >
          <View
            style={{
              flex: 1,
              marginTop: HeightDimen(20),
              paddingHorizontal: HeightDimen(30),
              paddingBottom: HeightDimen(20),
              marginTop: HeightDimen(20),
            }}
          >
            <View style={{ alignItems: 'center' }}>
              <View
                style={{
                  padding: HeightDimen(5),
                  backgroundColor: SecondaryColor,
                }}
              >
                <Image
                  style={{
                    width: 'auto',
                    height: HeightDimen(200),
                    aspectRatio: 1 / 1,
                    resizeMode: 'contain',
                  }}
                  source={{ uri: qrCode }}
                />
              </View>
            </View>
            <View style={{ marginTop: HeightDimen(15) }}>
              <RecoverTextInput
                title={strings.t('txid')}
                placeholder={strings.t('enter_txid')}
                top={true}
                bottom={true}
                onChangeText={this.handle_receipt}
                value={this.state.receipt}
              />
            </View>
            <View
              style={{
                marginLeft: HeightDimen(5),
                marginTop: HeightDimen(15),
              }}
            >
              {this.renderTitle(strings.t('details'), accountNo, true)}
              {this.renderTitle(
                `${strings.t('amount_RM')} (${MyData.country_code})`,
                Number(amount).toFixed(2)
              )}
              {this.renderTitle(
                strings.t('amount_usdt'),
                Number(orignalAmount).toFixed(2)
              )}
              {this.renderTitle(strings.t('rate'), rate)}
              {this.renderTitle(strings.t('address'), accountName)}
              {this.renderTitle(strings.t('date_time'), dateTime)}
            </View>
            <View
              style={{
                flex: 1,
                justifyContent: 'flex-end',
                alignItems: 'flex-end',
              }}
            >
              <TextButton
                label={strings.t('confirm')}
                size={5}
                onPress={() => this.onRequestTopupUsdt()}
                isLoading={this.state.isLoading}
              />
            </View>
          </View>
        </MainFlowHeaderContainerNoFeedback>
      </View>
    )
  }
}

export default UsdtReceiptScreen
