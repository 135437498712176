import React, { Component } from 'react'
import {
  View,
  TouchableOpacity,
  Modal,
  Image,
  FlatList,
  Platform,
} from 'react-native'
import Text from '../Text/MyText'

import {
  FontMainColor,
  FontSecondaryColor,
  SecondaryColor,
} from '../../UI/Color'
import { WidthDimen, HeightDimen, fontDimen } from '../../UI/Dimensions'

import { strings } from '../../Language/Language'

class PickBankModal extends React.Component {
  onPressSelect = (index) => {
    this.props.onPress(index)
  }

  render() {
    console.log(list)
    const { isShow, list, onBackPressed } = this.props

    return (
      <Modal
        animationType="slide"
        transparent={true}
        visible={isShow}
        statusBarTranslucent
      >
        <View
          style={{
            flex: 1,
            backgroundColor: 'rgba(0,0,0,0.5)',
          }}
        >
          <View
            style={{
              height: HeightDimen(60),
              justifyContent: 'center',
              marginTop:
                Platform.OS === 'ios' ? HeightDimen(40) : HeightDimen(30),
            }}
          >
            <View>
              <TouchableOpacity
                style={{
                  paddingLeft: WidthDimen(20),
                  width: 100,
                }}
                onPress={onBackPressed}
              >
                <Image
                  source={require('../../Resources/Images/icon_back.png')}
                  style={{
                    width: 'auto',
                    height: HeightDimen(15),
                    aspectRatio: 19 / 13,
                    resizeMode: 'contain',
                  }}
                />
              </TouchableOpacity>
            </View>
          </View>
          <View
            style={{
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <View
              style={{
                backgroundColor: 'white',
                flex: 0.7,
                alignItems: 'center',
                borderRadius: HeightDimen(10),
                borderWidth: 3,
                borderColor: SecondaryColor,
                marginHorizontal: HeightDimen(10),
              }}
            >
              <Text
                style={{
                  fontSize: fontDimen(15),
                  color: FontSecondaryColor,
                  marginVertical: HeightDimen(25),
                }}
              >
                {strings.pick_bank}
              </Text>

              <FlatList
                numColumns={1}
                data={list}
                style={{ paddingHorizontal: HeightDimen(20) }}
                renderItem={({ item, index }) => (
                  <View>
                    <TouchableOpacity
                      onPress={() => this.onPressSelect(index)}
                      style={{
                        paddingTop: HeightDimen(15),
                        paddingBottom: HeightDimen(15),
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <Image
                        source={{ uri: item.bank_icon_url }}
                        style={{ height: 30, width: 50 }}
                        resizeMode="contain"
                      />
                      <Text
                        style={{
                          fontSize: fontDimen(15),
                          color: FontMainColor,
                          marginLeft: HeightDimen(10),
                          width: '85%',
                        }}
                      >
                        {item.bank_name}
                      </Text>
                    </TouchableOpacity>
                    <View
                      style={{
                        width: '95%',
                        height: HeightDimen(1),
                        backgroundColor: '#EFF2F4',
                      }}
                    ></View>
                  </View>
                )}
                keyExtractor={(item) => String(item.bank_id)}
              />
            </View>
          </View>
        </View>
      </Modal>
    )
  }
}

export { PickBankModal }
