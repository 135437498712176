import React from 'react'
import { TouchableOpacity, Image } from 'react-native'
import { SecondaryColor } from '../../UI/Color'
import { HeightDimen, WidthDimen } from '../../UI/Dimensions'
const AnouncementCard = (props) => {
  const { title, description, thumbnail, onPress } = props

  return (
    <TouchableOpacity
      onPress={onPress}
      style={{
        width: '100%',
        height: HeightDimen(180),
        backgroundColor: 'rgba(237, 181, 61, 0.1)',
        marginBottom: HeightDimen(15),
        borderWidth: WidthDimen(3),
        borderRadius: HeightDimen(10),
        borderColor: SecondaryColor,
        elevation: 8,
      }}
    >
      <Image
        style={{
          width: '100%',
          height: '100%',
          borderRadius: HeightDimen(7),
        }}
        source={{ uri: thumbnail }}
        resizeMode="stretch"
      />
    </TouchableOpacity>
  )
}

export { AnouncementCard }
