import React, { Component } from 'react'
import { View, Image, StatusBar, BackHandler } from 'react-native'
import Text from '../../Component/Text/MyText'

import { MainColor } from '../../UI/Color'
import { WidthDimen, HeightDimen, fontDimen } from '../../UI/Dimensions'

import { TextButton } from '../../Component/Button/TextButton'

import { strings } from '../../Language/Language'
import MyData from '../../Data/MyData'
import storageData from '../../Data/StorageData'

class MaitenanaceScreen extends Component {
  getMaintenanceTitle = () => {
    const lang = storageData.get_selected_language()
    if (lang === 'EN') {
      return MyData.maintainance_json.en_title
    } else if (lang === 'BM') {
      return MyData.maintainance_json.bm_title
    } else if (lang === 'CH') {
      return MyData.maintainance_json.cn_title
    }
  }
  getMaintenanceDesc = () => {
    const lang = storageData.get_selected_language()
    if (lang === 'EN') {
      return MyData.maintainance_json.en_text
    } else if (lang === 'BM') {
      return MyData.maintainance_json.bm_text
    } else if (lang === 'CH') {
      return MyData.maintainance_json.cn_text
    }
  }
  render() {
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: MainColor,
        }}
      >
        <StatusBar translucent backgroundColor="transparent" />
        <Image
          source={require('../../Resources/Images/info_background.png')}
          style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
          }}
        />
        <View
          style={{
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            padding: HeightDimen(30),
          }}
        >
          <Image
            source={require('../../Resources/Images/info_logo_new.png')}
            style={{
              width: 'auto',
              height: HeightDimen(350),
              aspectRatio: 1 / 1,
              resizeMode: 'contain',
            }}
          />
          <View style={{ marginTop: HeightDimen(20) }}>
            <Text
              style={{
                color: 'white',
                textAlign: 'center',
                fontSize: fontDimen(20),
                fontWeight: 'bold',
              }}
            >
              {this.getMaintenanceTitle()}
            </Text>
            <Text
              style={{
                color: 'white',
                textAlign: 'center',
                fontSize: fontDimen(16),
                marginTop: HeightDimen(10),
              }}
            >
              {this.getMaintenanceDesc()}
            </Text>
          </View>
        </View>
        <View
          style={{
            flex: 0.2,
            justifyContent: 'center',
            flexDirection: 'row',
          }}
        >
          <TextButton
            onPress={() => this.props.navigation.navigate('ContactUs')}
            label={strings.t('contactUs')}
            size={2.5}
          />
          <View
            style={{
              marginLeft: WidthDimen(10),
            }}
          >
            <TextButton
              onPress={() => {
                BackHandler.exitApp()
              }}
              label={strings.t('app_close')}
              whiteBtn={true}
              size={2.5}
            />
          </View>
        </View>
      </View>
    )
  }
}

export default MaitenanaceScreen
