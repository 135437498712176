import React, { Component } from 'react'
import {
  View,
  TouchableOpacity,
  Modal,
  Image,
  Platform,
  StatusBar,
} from 'react-native'
import Text from '../Text/MyText'
import { WidthDimen, HeightDimen, fontDimen } from '../../UI/Dimensions'
import { ImageButton } from '../../Component/Button/ImageButton'
import { strings } from '../../Language/Language'
import { ProfileCard } from '../Card/NewDesign/ProfileCard'
import { TextButton } from '../Button/TextButton'
import { Line } from '../Line/Line'

const UploadTopUpModal = (props) => {
  const { isShow, onPressCamera, onPressLocal, onBackPress } = props

  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={isShow}
      statusBarTranslucent
    >
      <StatusBar translucent backgroundColor="transparent" />
      <View
        style={{
          flex: 1,
          backgroundColor: 'rgba(0,0,0,0.5)',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <View
          style={{
            justifyContent: 'center',
            padding: HeightDimen(20),
            marginHorizontal: HeightDimen(15),
            backgroundColor: 'white',
            borderRadius: HeightDimen(10),
          }}
        >
          <ProfileCard
            label={strings.t('take_picture')}
            onPress={onPressCamera}
            src={require('../../Resources/Images/icon_camera.png')}
          />
          <Line marginTop={HeightDimen(8)} />
          <ProfileCard
            label={strings.t('select_from_gallery')}
            onPress={onPressLocal}
            src={require('../../Resources/Images/icon_gallery.png')}
          />

          <View
            style={{
              width: '100%',
              alignItems: 'center',
              marginTop: HeightDimen(30),
            }}
          >
            <TextButton
              label={strings.t('cancel')}
              size={2.5}
              onPress={onBackPress}
              blackBtn
            />
          </View>
        </View>
      </View>
    </Modal>
  )
}

export { UploadTopUpModal }
