import React, { Component } from "react";
import { View, Image, TouchableOpacity } from "react-native";
import Text from "../Text/MyText";

import { SecondaryColor } from "../../UI/Color";
import { HeightDimen, fontDimen } from "../../UI/Dimensions";

const PlayTechLaunchGameHeaderContainer = (props) => {
  const { onBackPress, title, toShowPress, toShowImageUrl } = props;
  return (
    <View
      style={{
        position: "absolute",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        top: HeightDimen(25),
      }}
    >
      {/* <TouchableOpacity
        onPress={onBackPress}
        style={{
          justifyContent: "center",
          borderWidth: HeightDimen(2),
          borderColor: SecondaryColor,
          borderRadius: 9999,
          padding: HeightDimen(8),
          backgroundColor: SecondaryColor,
          marginLeft: HeightDimen(15),
        }}
      >
        <Image
          source={require("../../Resources/Images/icon_back.png")}
          style={{
            width: "auto",
            height: HeightDimen(12),
            aspectRatio: 1 / 1,
            resizeMode: "contain",
            tintColor: "white",
          }}
        />
      </TouchableOpacity> */}
      <View>
        <Text
          style={{
            fontSize: fontDimen(18),
            color: SecondaryColor,
            fontWeight: "bold",
          }}
        >
          {title}
        </Text>
      </View>
      {/* <TouchableOpacity
        style={{
          justifyContent: "center",
          borderWidth: HeightDimen(2),
          borderColor: SecondaryColor,
          borderRadius: 9999,
          padding: HeightDimen(8),
          backgroundColor: SecondaryColor,
          marginRight: HeightDimen(15),
        }}
        onPress={toShowPress}
      >
        <Image
          source={toShowImageUrl}
          style={{
            width: "auto",
            height: HeightDimen(12),
            aspectRatio: 1 / 1,
            resizeMode: "contain",
            tintColor: "white",
          }}
        />
      </TouchableOpacity> */}
    </View>
  );
};

export { PlayTechLaunchGameHeaderContainer };
