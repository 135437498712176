import React, { Component } from "react";
import {
  View,
  FlatList,
  ActivityIndicator,
  Modal,
  Image,
  TouchableOpacity,
  StatusBar,
  Platform,
} from "react-native";
import { MainColor } from "../../UI/Color";
import { HeightDimen, WidthDimen } from "../../UI/Dimensions";
import anouncementCaller from "../../API/AnouncementAPICaller";
import MyData from "../../Data/MyData";
import { AnouncementCard } from "../../Component/Card/AnouncementCard";
import storageData from "../../Data/StorageData";
import MyToast from "../../Component/Toast/MyToast";

class InstructionScreen extends Component {
  state = {
    anouncementList: [],
    isLoading: true,
    isModalShow: false,
  };

  async componentDidMount() {
    await this.get_instruction_list();
  }

  get_instruction_list = async () => {
    await anouncementCaller
      .getInstruction(MyData.authToken)
      .then((res) => {
        debugger;
        this.setState({
          anouncementList: res.response.list,
        });
      })
      .catch((err) => {
        if (err.response.status == 514) {
          this.props.expiredToken_logout();
        } else {
          MyToast.popToast(err.response.data.msg);
        }
      })
      .finally(() => {
        this.setState({
          isLoading: false,
        });
      });
  };
  onBackPressed = () => {
    this.props.navigation.goBack();
  };
  on_Refresh = () => {
    this.setState({ isLoading: true });
    this.get_instruction_list();
  };
  renderCoverImageModal = () => {
    return (
      <Modal
        animationType="fade"
        transparent={true}
        visible={this.state.isModalShow}
        statusBarTranslucent
      >
        <View
          style={{
            flex: 1,
            backgroundColor: "rgba(0,0,0,0.8)",
          }}
        >
          <TouchableOpacity
            style={{
              paddingLeft: WidthDimen(20),
              marginTop:
                Platform.OS === "ios" ? WidthDimen(45) : WidthDimen(40),
            }}
            onPress={() => this.setState({ isModalShow: false })}
          >
            <Image
              source={require("../../Resources/Images/icon_back.png")}
              style={{
                width: "auto",
                height: HeightDimen(15),
                aspectRatio: 19 / 13,
                resizeMode: "contain",
              }}
            />
          </TouchableOpacity>
          <View
            style={{ flex: 1, alignItems: "center", justifyContent: "center" }}
          >
            <Image
              source={{ uri: this.state.selectedImage }}
              style={{
                resizeMode: "contain",
                height: "90%",
                width: "90%",
              }}
            />
          </View>
        </View>
      </Modal>
    );
  };
  render() {
    return (
      <View
        style={{
          flex: 1,
        }}
      >
        {this.state.isLoading ? (
          <View>
            <ActivityIndicator size="small" color={MainColor} />
          </View>
        ) : (
          <FlatList
            onRefresh={() => this.on_Refresh()}
            refreshing={this.state.isLoading}
            data={this.state.anouncementList}
            contentContainerStyle={{ paddingHorizontal: HeightDimen(10) }}
            keyExtractor={(key) => key.announcement_id}
            numColumns={2}
            renderItem={({ item }) => {
              return (
                <TouchableOpacity
                  onPress={() => {
                    if (item.instruction_link) {
                      this.props.navigation.navigate("AnouncementDetail", {
                        Url: item.instruction_link,
                        onGoBack: (data) => {
                          this.on_Refresh(data);
                        },
                      });
                    } else {
                      this.setState({
                        selectedImage: item.instruction_cover_image,
                        isModalShow: true,
                      });
                    }
                  }}
                  style={{
                    width: "48%",
                    marginLeft: HeightDimen(5),
                  }}
                >
                  <Image
                    style={{
                      width: "auto",
                      height: "auto",
                      aspectRatio: 2 / 1.1,
                      resizeMode: "contain",
                      //top: 2,
                    }}
                    source={{ uri: item?.instruction_thumbnail_image }}
                  />
                </TouchableOpacity>
              );
            }}
          />
        )}

        {this.renderCoverImageModal()}
      </View>
    );
  }
}

export default InstructionScreen;
