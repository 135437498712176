import React, { Component } from 'react'
import { View, Modal } from 'react-native'
import Text from '../Text/MyText'
import { MainColor, FontMainColor } from '../../UI/Color'
import { HeightDimen, fontDimen } from '../../UI/Dimensions'
import { TextButton } from '../Button/TextButton'
import { strings } from '../../Language/Language'

const InfoTextModal = (props) => {
  const { isShow, onCloseModal, text } = props

  return (
    <Modal
      animationType={'slide'}
      transparent={true}
      statusBarTranslucent
      visible={isShow}
      onRequestClose={onCloseModal}
    >
      <View
        style={{
          flex: 1,
          backgroundColor: 'rgba(0,0,0,0.5)',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <View
          style={{
            backgroundColor: 'white',
            padding: HeightDimen(20),
            marginHorizontal: HeightDimen(20),
            borderRadius: HeightDimen(5),
          }}
        >
          <Text
            style={{
              fontSize: fontDimen(20),
              textAlign: 'center',
              fontWeight: 'bold',
              color: MainColor,
            }}
          >
            {strings.t('user_agreement')}
          </Text>
          <Text
            style={{
              fontSize: fontDimen(14),
              marginTop: HeightDimen(10),
              color: FontMainColor,
            }}
          >
            {text}
          </Text>
          <View
            style={{
              marginTop: HeightDimen(40),
            }}
          >
            <TextButton
              onPress={onCloseModal}
              label={strings.t('confirm')}
              size={5}
            />
          </View>
        </View>
      </View>
    </Modal>
  )
}

export { InfoTextModal }
