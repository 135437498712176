import axios from "axios";
import MyData from "../Data/MyData";
import { apiUrls } from "./APIurl";

class AnouncementCaller {
  getAnouncement = async (authToken) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${MyData.api_url}${apiUrls.getAnouncement}`, {
          headers: {
            Authorization: authToken,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getPromotionList = async (authToken) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${MyData.api_url}${apiUrls.promoList}`, {
          headers: {
            Authorization: authToken,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getInstruction = async (authToken) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${MyData.api_url}${apiUrls.getInstruction}`, {
          headers: {
            Authorization: authToken,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getInfoAnouncement = async (authToken) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${MyData.api_url}${apiUrls.getInfoAnouncement}`, {
          headers: {
            Authorization: authToken,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
}

const anouncementCaller = new AnouncementCaller();
export default anouncementCaller;
