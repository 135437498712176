import axios from "axios";
import { apiUrls } from "./APIurl";
import storageData from "../Data/StorageData";
import MyData from "../Data/MyData";

class PinAPICaller {
  setPin = (authToken, pin, conPin) => {
    console.log(authToken);
    console.log(pin);
    console.log(conPin);
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.setPin}`,
          {
            data: {
              newPin: pin,
              confirmPin: conPin,
              language: storageData.get_selected_language(),
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  changePin = (token, otp, oldpin, newpin, newconfirmpin) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${MyData.api_url}${apiUrls.changePin}`, {
          data: {
            userToken: token,
            pinOtp: otp,
            oldPin: oldpin,
            newPin: newpin,
            confirmPin: newconfirmpin,
            language: storageData.get_selected_language(),
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  recoverPin = (phoneNumber, code, authToken) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.recoverPin}`,
          {
            data: {
              phone: phoneNumber,
              countryCode: code,
              language: storageData.get_selected_language(),
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  GetWalletOTP = (authToken) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${MyData.api_url}${apiUrls.getWalletOTP}`, {
          headers: {
            Authorization: authToken,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
}

const pinAPICaller = new PinAPICaller();
export default pinAPICaller;
