import React, { Component } from "react";
import {
  View,
  TouchableOpacity,
  Image,
  Switch,
  ActivityIndicator,
} from "react-native";
import Text from "../Text/MyText";
import {
  SecondaryColor,
  FontMainColor,
  MainColor,
  PrimaryColor,
} from "../../UI/Color";
import { HeightDimen, fontDimen } from "../../UI/Dimensions";

const SettingCard = props => {
  const {
    top,
    bottom,
    label,
    onPress,
    isToggle,
    toggleState,
    onToggle,
    isLoading,
  } = props;

  return (
    <TouchableOpacity
      onPress={onPress}
      disabled={isLoading}
      style={{
        width: "100%",
        height: HeightDimen(50),
        backgroundColor: PrimaryColor,
        borderTopRightRadius: top ? HeightDimen(10) : 0,
        borderTopLeftRadius: top ? HeightDimen(10) : 0,

        borderBottomRightRadius: bottom ? HeightDimen(10) : 0,
        borderBottomLeftRadius: bottom ? HeightDimen(10) : 0,

        flexDirection: "row",
        padding: HeightDimen(15),
        alignItems: "center",
      }}>
      <View
        style={{
          flex: 1,
          marginRight: HeightDimen(15),
        }}>
        <Text
          style={{
            fontSize: fontDimen(15),
            color: FontMainColor,
          }}>
          {label}
        </Text>
      </View>

      {isToggle ? (
        <Switch
          onValueChange={onToggle}
          value={toggleState}
          trackColor={{ false: "#767577", true: SecondaryColor }}
          //thumbColor={ onToggle ? "#f5dd4b" : "#f4f3f4"}
        />
      ) : isLoading ? (
        <ActivityIndicator size={"small"} color={MainColor} />
      ) : (
        <Image
          source={require("../../Resources/Images/icon_nextP.png")}
          style={{
            width: "auto",
            height: HeightDimen(20),
            aspectRatio: 16 / 27,
            resizeMode: "contain",
          }}
        />
      )}
    </TouchableOpacity>
  );
};

export { SettingCard };
