import React from 'react'
import { View, TouchableOpacity, Image } from 'react-native'
import Text from '../Text/MyText'
import { SecondaryColor, FontMainColor } from '../../UI/Color'
import { WidthDimen, HeightDimen, fontDimen } from '../../UI/Dimensions'

const FriendCard = (props) => {
  const {
    top,
    bottom,
    hideShowNext,
    onPress,
    name,
    image,
    onDeletePress,
    disabled,
  } = props

  return (
    <TouchableOpacity
      onPress={onPress}
      disabled={disabled}
      style={{
        width: '100%',
        backgroundColor: disabled ? '#ededed' : 'rgba(237, 181, 61, 0.1)',
        borderTopRightRadius: top ? HeightDimen(10) : 0,
        borderTopLeftRadius: top ? HeightDimen(10) : 0,
        borderBottomRightRadius: bottom ? HeightDimen(10) : 0,
        borderBottomLeftRadius: bottom ? HeightDimen(10) : 0,
        flexDirection: 'row',
        padding: HeightDimen(10),
        alignItems: 'center',
        marginBottom: HeightDimen(5),
      }}
    >
      <View
        style={{
          width: 'auto',
          height: HeightDimen(40),
          aspectRatio: 1 / 1,
          borderRadius: 9999,
          borderWidth: HeightDimen(3),
          borderColor: SecondaryColor,
          backgroundColor: 'white',
          zIndex: 99,
          overflow: 'hidden',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Image
          style={{
            width: 'auto',
            height: HeightDimen(40),
            aspectRatio: 1 / 1,
            resizeMode: 'contain',
          }}
          source={{
            uri: image,
          }}
        />
      </View>
      <View
        style={{
          flex: 1,
          marginLeft: HeightDimen(15),
          marginRight: HeightDimen(15),
        }}
      >
        <Text
          style={{
            fontSize: fontDimen(15),
            color: FontMainColor,
          }}
        >
          {name}
        </Text>
      </View>
      {hideShowNext ? (
        <TouchableOpacity
          disabled={disabled}
          onPress={onDeletePress}
          style={{
            width: WidthDimen(40),
            alignItems: 'flex-end',
          }}
        >
          <Image
            source={require('../../Resources/Images/bin.png')}
            style={{
              width: 'auto',
              height: HeightDimen(40),
              aspectRatio: 16 / 27,
              resizeMode: 'contain',
              tintColor: disabled ? 'grey' : SecondaryColor,
            }}
          />
        </TouchableOpacity>
      ) : (
        <Image
          source={require('../../Resources/Images/icon_nextP.png')}
          style={{
            width: 'auto',
            height: HeightDimen(20),
            aspectRatio: 16 / 27,
            resizeMode: 'contain',
          }}
        />
      )}
    </TouchableOpacity>
  )
}

export { FriendCard }
