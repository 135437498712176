import React, { Component } from "react";
import {
  View,
  Image,
  TouchableOpacity,
  TouchableWithoutFeedback,
  Keyboard,
  ImageBackground,
  Modal,
  KeyboardAvoidingView,
  Platform,
} from "react-native";
import Text from "../Text/MyText";

import {
  MainColor,
  SecondaryColor,
  FontMainColor,
  FontSecondaryColor,
  FontThirdColor,
  HeaderColor,
} from "../../UI/Color";
import {
  WidthDimen,
  HeightDimen,
  fontDimen,
  GetWidth,
  GetHeight,
} from "../../UI/Dimensions";
import { Children } from "react";
import { Stepper } from "../Input/Stepper";
import MIcon from "react-native-vector-icons/MaterialCommunityIcons";
import { strings } from "../../Language/Language";
import { useState } from "react";
import { TextButton } from "../Button/TextButton";
import { GradientHeader } from "./NewDesign/GradientHeader";

const LoginFlowHeader = (props) => {
  const { onBackPress, mainText, subText, hideBack } = props;

  return (
    <View
      style={{
        width: "100%",

        backgroundColor: MainColor,
      }}
    >
      <View
        style={{
          height: Platform.OS === "ios" ? HeightDimen(35) : HeightDimen(25),
        }}
      ></View>
      <View
        style={{
          height: HeightDimen(55),
          justifyContent: "center",
          paddingLeft: WidthDimen(20),
        }}
      >
        {hideBack ? (
          <></>
        ) : (
          <TouchableOpacity onPress={onBackPress}>
            <Image
              source={require("../../Resources/Images/icon_back.png")}
              style={{
                width: "auto",
                height: HeightDimen(15),
                aspectRatio: 19 / 13,
                resizeMode: "contain",
              }}
            />
          </TouchableOpacity>
        )}
      </View>
      <View
        style={{
          width: "100%",
          paddingLeft: HeightDimen(30),
          paddingRight: HeightDimen(30),
          paddingTop: HeightDimen(30),
          backgroundColor: "white",
          borderTopLeftRadius: HeightDimen(20),
          borderTopRightRadius: HeightDimen(20),
        }}
      >
        <Text
          style={{
            color: FontMainColor,
            fontSize: fontDimen(25),
          }}
        >
          {mainText}
        </Text>

        <Text
          style={{
            color: FontSecondaryColor,
            fontSize: fontDimen(15),
            marginTop: HeightDimen(10),
          }}
        >
          {subText}
        </Text>
      </View>
    </View>
  );
};

const LoginFlowHeaderContainer = (props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const {
    onBackPress,
    mainText,
    subText,
    hideBack,
    children,
    wantStepper,
    stepValue,
    stepList,
    info,
  } = props;
  const renderSigUpInfoModal = () => {
    return (
      <Modal
        animationType={"slide"}
        transparent={true}
        statusBarTranslucent
        visible={modalOpen}
        onRequestClose={() => setModalOpen(false)}
      >
        <View
          style={{
            flex: 1,
            backgroundColor: "rgba(0,0,0,0.5)",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <View
            style={{
              backgroundColor: "white",
              padding: HeightDimen(20),
              marginHorizontal: HeightDimen(20),
              borderRadius: HeightDimen(5),
            }}
          >
            <Text
              style={{
                fontSize: fontDimen(20),
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              {strings.t("reminder")}
            </Text>

            <Text
              style={{
                fontSize: fontDimen(14),

                marginTop: HeightDimen(10),
              }}
            >
              1. {strings.t("signup_username_info")}
            </Text>
            <Text
              style={{
                fontSize: fontDimen(14),

                marginTop: HeightDimen(10),
              }}
            >
              2. {strings.t("signup_password_info")}
            </Text>
            <View
              style={{
                marginTop: HeightDimen(40),
              }}
            >
              <TextButton
                onPress={() => setModalOpen(false)}
                label={strings.t("cancel")}
                size={5}
              />
            </View>
          </View>
        </View>
      </Modal>
    );
  };

  return (
    <View
      style={{
        width: "100%",
        flex: 1,
        backgroundColor: SecondaryColor,
      }}
    >
      <View
        style={{
          height: Platform.OS === "ios" ? HeightDimen(35) : HeightDimen(25),
          backgroundColor: HeaderColor,
        }}
      ></View>
      <View
        style={{
          width: "100%",
          height: HeightDimen(55),
          justifyContent: "center",
          backgroundColor: HeaderColor,
          //paddingLeft: WidthDimen(20),
        }}
      >
        <GradientHeader />
        {hideBack ? (
          <></>
        ) : (
          <TouchableOpacity
            onPress={onBackPress}
            style={{
              paddingLeft: WidthDimen(20),
              width: 100,
            }}
          >
            <Image
              source={require("../../Resources/Images/icon_back.png")}
              style={{
                width: "auto",
                height: HeightDimen(15),
                aspectRatio: 1 / 1,
                resizeMode: "contain",
                tintColor: MainColor,
              }}
            />
          </TouchableOpacity>
        )}
      </View>

      <View
        style={{
          width: "100%",
          backgroundColor: "white",
          flex: 1,
        }}
      >
        <KeyboardAvoidingView
          behavior={"padding"}
          style={{ flex: 1 }}
          keyboardVerticalOffset={Platform.OS == "ios" ? 20 : HeightDimen(-200)}
        >
          <View
            style={{
              paddingHorizontal: HeightDimen(30),
              paddingTop: HeightDimen(30),
            }}
          >
            {/* {wantStepper && <Stepper step={stepValue} list={stepList} />} */}
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <Text
                style={{
                  color: FontMainColor,
                  fontSize: fontDimen(25),
                  // paddingLeft: HeightDimen(30),
                  // paddingRight: HeightDimen(30),
                }}
              >
                {mainText}
              </Text>
              {info && (
                <TouchableOpacity
                  style={{ marginLeft: HeightDimen(10) }}
                  onPress={() => setModalOpen(true)}
                >
                  <MIcon name={"progress-question"} size={18} />
                </TouchableOpacity>
              )}
            </View>
            <Text
              style={{
                color: FontSecondaryColor,
                fontSize: fontDimen(15),
                marginTop: HeightDimen(10),
                // paddingLeft: HeightDimen(30),
                // paddingRight: HeightDimen(30),
              }}
            >
              {subText}
            </Text>
          </View>
          {/* <TouchableWithoutFeedback onPress={Keyboard.dismiss} accessible={false}> */}
          <View
            style={{
              flex: 1,
            }}
          >
            {children}
          </View>
          {/* </TouchableWithoutFeedback> */}
        </KeyboardAvoidingView>

        {renderSigUpInfoModal()}
      </View>
    </View>
  );
};

const LoginFlowHeaderContainerNoFeedback = (props) => {
  const { onBackPress, mainText, subText, hideBack, children } = props;

  return (
    <View
      style={{
        width: "100%",
        flex: 1,
        backgroundColor: MainColor,
      }}
    >
      <View
        style={{
          height: Platform.OS === "ios" ? HeightDimen(35) : HeightDimen(25),
        }}
      ></View>
      <View
        style={{
          height: HeightDimen(55),
          justifyContent: "center",
          paddingLeft: WidthDimen(20),
        }}
      >
        {hideBack ? (
          <></>
        ) : (
          <TouchableOpacity onPress={onBackPress}>
            <Image
              source={require("../../Resources/Images/icon_back.png")}
              style={{
                width: "auto",
                height: HeightDimen(15),
                aspectRatio: 19 / 13,
                resizeMode: "contain",
              }}
            />
          </TouchableOpacity>
        )}
      </View>
      <View
        style={{
          width: "100%",
          paddingTop: HeightDimen(30),
          backgroundColor: "white",
          borderTopLeftRadius: HeightDimen(20),
          borderTopRightRadius: HeightDimen(20),
          flex: 1,
        }}
      >
        <Text
          style={{
            color: FontMainColor,
            fontSize: fontDimen(25),
            paddingLeft: HeightDimen(30),
            paddingRight: HeightDimen(30),
          }}
        >
          {mainText}
        </Text>
        <Text
          style={{
            color: FontSecondaryColor,
            fontSize: fontDimen(15),
            marginTop: HeightDimen(10),
            paddingLeft: HeightDimen(30),
            paddingRight: HeightDimen(30),
          }}
        >
          {subText}
        </Text>

        <View
          style={{
            flex: 1,
          }}
        >
          {children}
        </View>
      </View>
    </View>
  );
};
const styles = {
  backgroundImage: {
    width: "100%",

    flex: 1,
  },
};
export {
  LoginFlowHeader,
  LoginFlowHeaderContainer,
  LoginFlowHeaderContainerNoFeedback,
};
