import React, { Component } from 'react'
import { View, Modal, Text } from 'react-native'
import { fontDimen, HeightDimen, WidthDimen } from '../../UI/Dimensions'
import { LoginFlowHeaderContainer } from '../../Component/Header/LoginFlowHeader'
import { TextButton } from '../../Component/Button/TextButton'
import APICaller from '../../API/APICaller'
import MyToast from '../../Component/Toast/MyToast'
import { strings } from '../../Language/Language'
import { PhoneTextInput } from '../../Component/Input/PhoneTextInput'
import { phoneRegex } from '../../Utils/Common'
import { RecoverTextInput } from '../../Component/Input/RecoverTextInput'
import { WithdrawSuccModal } from '../../Component/Modal/WithdrawSuccModal'
import transferAPICaller from '../../API/TransferAPICaller'
import { RewardModal } from '../../Component/Modal/RewardSuccessModal'
import walletAPICaller from '../../API/WalletAPICaller'
import MyData from '../../Data/MyData'
import { FontMainColor, FontSecondaryColor } from '../../UI/Color'

class TransactionOTPScreen extends Component {
  state = {
    isLoading: false,
    showSuccModal: false,
    otp: '',
    transactionProcessModal: false,
    isPreviewWithdrawUsdt: false,
    previewUsdt: null,
  }

  handle_phone = (phone) => {
    if (phoneRegex(phone)) this.setState({ phone: phone })
    else return
  }
  handle_otp = (otp) => {
    this.setState({ otp: otp })
  }
  onBackPressed = () => {
    this.props?.navigation?.goBack()
  }
  renderTitleDescription(title, description) {
    if (description)
      return (
        <View
          style={{
            flexDirection: 'row',
            marginBottom: HeightDimen(15),
          }}
        >
          <Text
            style={{
              fontSize: fontDimen(15),
              color: FontSecondaryColor,
              width: WidthDimen(110),
            }}
          >
            {title}
          </Text>
          <Text
            style={{
              fontSize: fontDimen(15),
              color: FontMainColor,
              flex: 1,
            }}
          >
            {description}
          </Text>
        </View>
      )
  }
  previewithdrawUsdtModal() {
    return (
      <Modal
        animationType="slide"
        transparent={true}
        visible={this.state.isPreviewWithdrawUsdt}
        statusBarTranslucent
      >
        <View
          style={{
            flex: 1,
            backgroundColor: 'rgba(0,0,0,0.5)',
            justifyContent: 'center',
          }}
        >
          <View
            style={{
              backgroundColor: 'white',
              marginHorizontal: HeightDimen(20),
              borderRadius: HeightDimen(10),
              paddingHorizontal: HeightDimen(15),
            }}
          >
            <View
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: HeightDimen(15),
              }}
            >
              <Text
                style={{
                  fontSize: fontDimen(20),
                  fontWeight: 'bold',
                }}
              >
                {'Withdraw USDT'}
              </Text>
            </View>

            <Text
              style={{
                fontSize: fontDimen(30),
                marginTop: HeightDimen(20),
                textAlign: 'center',
                color: 'green',
              }}
            >
              {this.state.previewUsdt?.converted_amount}
            </Text>
            <View
              style={{
                width: '100%',
                borderBottomWidth: HeightDimen(1),
                marginTop: HeightDimen(15),
              }}
            ></View>
            <Text
              style={{
                fontSize: fontDimen(20),
                marginBottom: HeightDimen(15),
                marginTop: HeightDimen(15),
                textAlign: 'center',
                color: FontSecondaryColor,
              }}
            >
              {this.state.previewUsdt?.converted_amount_currency}
            </Text>
            {this.renderTitleDescription(
              'Amount',
              this.state.previewUsdt?.original_amount
            )}
            {this.renderTitleDescription(
              'Currency',
              this.state.previewUsdt?.original_amount_currency
            )}
            {this.renderTitleDescription(
              'Description',
              this.state.previewUsdt?.desc
            )}
            {this.renderTitleDescription(
              strings.t('date'),
              this.state.previewUsdt?.date
            )}

            <View
              style={{
                width: '100%',
                justifyContent: 'center',
                marginTop: HeightDimen(20),
                flexDirection: 'row',
                marginBottom: HeightDimen(10),
              }}
            >
              <TextButton
                label={strings.t('confirm')}
                size={2.5}
                onPress={() => this.on_withdraw()}
                isLoading={this.state.isLoading}
              />
              <View style={{ marginLeft: HeightDimen(15) }}>
                <TextButton
                  label={strings.t('cancel')}
                  size={2.5}
                  onPress={() => {
                    this.setState({ isPreviewWithdrawUsdt: false })
                  }}
                  blackBtn
                />
              </View>
            </View>
          </View>
        </View>
      </Modal>
    )
  }
  onGetTransactionOTP = async () => {
    await APICaller.getTransactionOTP()
      .then((res) => {
        MyToast.popToast(strings.t('otp_code_resended'))
      })
      .catch((err) => {
        MyToast.popToast(err.response.data.msg)
      })
  }
  on_transfer = async () => {
    const { transferAmount, transferId, onGoBack } = this.props.route.params
    await transferAPICaller
      .transferMoney(MyData.authToken, transferId, transferAmount)
      .then((res) => {
        this.setState({
          isLoading: false,
          transationProcessModal: false,
        })
        if (onGoBack) onGoBack()
        this.props.navigation.navigate('PaymentSuccessScreen', {
          isSuccess: true,
          transferMessage: res.msg,
          transferAmount: res.response.amount,
          onGoBack: () => {
            this.props?.navigation?.goBack()
          },
        })
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        })
        if (err.status == 514) {
          this.props.expiredToken_logout()
        } else {
          this.props.navigation.navigate('PaymentSuccessScreen', {
            isSuccess: false,
            transferMessage: err?.message,
            transationProcessModal: false,
          })
        }
      })
  }
  on_withdraw = async () => {
    const { selectedBankAccountId, transferAmount, bankList } =
      this.props.route.params
    this.setState({
      isLoading: true,
    })
    await walletAPICaller
      .withdrawValue(MyData.authToken, transferAmount, selectedBankAccountId)
      .then((res) => {
        this.setState({ isLoading: false })
        this.props.navigation.navigate('WithdrawHistoryDetailsScreen', {
          bankList: bankList,
          selectedTopUpDetails: res?.response?.info,
          onGoBack: () => {
            this.props.navigation.goBack()
            this.props.route.params.onGoBack()
          },
        })
      })
      .catch((err) => {
        this.setState({ isLoading: false })
        if (err.status == 514) {
          this.props.expiredToken_logout()
        } else {
          MyToast.popToast(err?.message)
        }
      })
  }
  previewWithdrawUsdt = async () => {
    this.setState({
      isLoading: true,
    })

    const { selectedBankAccountId, transferAmount } = this.props.route.params
    await walletAPICaller
      .previewWithdrawUsdt(
        MyData.authToken,
        transferAmount,
        selectedBankAccountId
      )
      .then((res) => {
        this.setState({
          isLoading: false,
          isPreviewWithdrawUsdt: true,
          previewUsdt: res?.data?.response,
        })
      })
      .catch((err) => {
        if (err.response.status == '514') {
          this.props.expiredToken_logout()
        } else {
          MyToast.popToast(err.response.data.msg)
        }
        this.setState({
          isLoading: false,
        })
      })
  }

  onVerifyTransactionOTP = async () => {
    const { otp } = this.state
    const { isTransfer, isWithdraw, isPreviewWithdraw } =
      this.props.route.params
    if (otp != '') {
      this.setState({
        isLoading: true,
      })
      await APICaller.verifyTransactionOTP(otp)
        .then(async (res) => {
          if (isTransfer) {
            await this.on_transfer()
          }
          if (isWithdraw) {
            await this.on_withdraw()
          }
          if (isPreviewWithdraw) {
            await this.previewWithdrawUsdt()
          }
        })
        .catch((err) => {
          MyToast.popToast(err.response.data.msg)
        })
        .finally(() => {
          this.setState({ isLoading: false })
        })
    } else {
      MyToast.popToast(strings.t('please_enter_otp'))
    }
  }
  onSelectCode(code) {
    this.setState({
      code: code,
    })
  }
  close_succ_modal = () => {
    this.setState({
      showSuccModal: false,
    })

    this.props?.navigation?.goBack()
    this.props?.route?.params?.onGoBack()
  }
  render() {
    const { bankList, amount, isWithdraw } = this.props?.route?.params
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: 'white',
        }}
      >
        <LoginFlowHeaderContainer
          onBackPress={() => this.onBackPressed()}
          mainText={strings.t('request_otp')}
          subText={strings.t('please_enter_otp')}
        >
          {isWithdraw && (
            <WithdrawSuccModal
              isShow={this.state.showSuccModal}
              bankList={bankList}
              amount={amount}
              onClose={() => this.close_succ_modal()}
            />
          )}

          <View
            style={{
              flex: 1,
              paddingHorizontal: HeightDimen(30),
              paddingVertical: HeightDimen(30),
            }}
          >
            <RecoverTextInput
              title={strings.t('otp_text')}
              placeholder={strings.t('otp_text')}
              bottom={true}
              isNumber={true}
              onChangeText={this.handle_otp}
              isCounter
              onOtpPress={() => this.onGetTransactionOTP()}
            />

            <View
              style={{
                width: '100%',
                alignItems: 'center',
                marginTop: HeightDimen(20),
              }}
            >
              <TextButton
                label={strings.t('next')}
                size={5}
                onPress={() => this.onVerifyTransactionOTP()}
                isLoading={this.state.isLoading}
              />
            </View>
          </View>
          {this.previewithdrawUsdtModal()}
        </LoginFlowHeaderContainer>
      </View>
    )
  }
}

export default TransactionOTPScreen
