import React from "react";
import { View, Image } from "react-native";
import Text from "../Text/MyText";
import { HeightDimen, fontDimen } from "../../UI/Dimensions";
import { FontSecondaryColor, SecondaryColor } from "../../UI/Color";
import { TextButton } from "../Button/TextButton";

const EmptyRecordCard = props => {
  return (
    <View
      style={{
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
      }}>
      <Image
        style={{
          height: HeightDimen(60),
          width: "auto",
          aspectRatio: 1 / 1,
          tintColor: SecondaryColor,
          resizeMode: "contain",
        }}
        source={require("../../Resources/Images/no_record.png")}
      />
      <Text
        style={{
          fontSize: fontDimen(16),
          color: FontSecondaryColor,
          fontWeight: "bold",
          marginVertical: HeightDimen(15),
        }}>
        {"No Record Found"}
      </Text>
      {props.wantButton && (
        <TextButton
          label={props?.btnLabel}
          size={2.5}
          onPress={props.onPress}
        />
      )}
    </View>
  );
};

export { EmptyRecordCard };
