import React, { Component } from "react";
import {
  View,
  TouchableOpacity,
  Modal,
  Image,
  StatusBar,
  Platform,
  FlatList,
} from "react-native";
import Text from "../Text/MyText";

import {
  WidthDimen,
  HeightDimen,
  fontDimen,
  GetWidth,
  GetHeight,
} from "../../UI/Dimensions";

import { strings } from "../../Language/Language";
import { TextSelection } from "../Selection/TextSelection";
import aPICaller from "../../API/APICaller";
import MyData from "../../Data/MyData";
import MyToast from "../Toast/MyToast";
import storageData from "../../Data/StorageData";

const LanguageModal = (props) => {
  const { isShow, filterList, selection, onPress, onRefresh } = props;
  const onLanguageChange = async (lang) => {
    await aPICaller
      .changeLanguage(MyData.authToken, lang)
      .then(async (res) => {
        MyToast.popToast("Status: " + res?.data?.msg);
      })
      .catch((err) => {
        MyToast.popToast(err.response.data.msg);
      });
  };
  const onLanguagePress = async (item, index) => {
    onPress(item, index);
    if (storageData.saved_authSession)
      await onLanguageChange(item?.language_code);
    onRefresh();
  };
  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={isShow}
      statusBarTranslucent
    >
      <StatusBar translucent backgroundColor="transparent" />
      <View
        style={{
          flex: 1,
          backgroundColor: "rgba(0,0,0,0.5)",
          justifyContent: "flex-end",
        }}
      >
        <View
          style={{
            backgroundColor: "white",
          }}
        >
          <View
            style={{
              height: HeightDimen(50),
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text
              style={{
                fontSize: fontDimen(15),
                fontWeight: "bold",
              }}
            >
              {strings.t("type")}
            </Text>
          </View>
          <FlatList
            style={{
              flexGrow: 0,
              height: HeightDimen(320),
            }}
            numColumns={1}
            data={filterList}
            renderItem={({ item, index }) => (
              <>
                <TextSelection
                  label={item.language_display_name}
                  id={index + 1}
                  current_selection={selection}
                  onPress={() => onLanguagePress(item, index + 1)}
                />
                <View style={{ height: HeightDimen(3) }}></View>
              </>
            )}
            keyExtractor={(item) => String(item.id)}
          />
        </View>
      </View>
    </Modal>
  );
};

export { LanguageModal };
