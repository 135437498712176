import React, { Component } from "react";
import {
  View,
  Keyboard,
  TouchableOpacity,
  TextInput,
  Platform,
} from "react-native";
import Text from "../../Component/Text/MyText";
import { HeightDimen, fontDimen } from "../../UI/Dimensions";
import { MainFlowHeaderContainer } from "../../Component/Header/NewDesign/MainFlowHeader";
import { RecoverTextInput } from "../../Component/Input/RecoverTextInput";
import { TextButton } from "../../Component/Button/TextButton";
import MyToast from "../../Component/Toast/MyToast";
import MyData from "../../Data/MyData";
import { strings } from "../../Language/Language";
import { EnterTransactionPinModal } from "../../Component/Modal/EnterTransactionPinModal";
import transferAPICaller from "../../API/TransferAPICaller";
import { SuccessModal } from "../../Component/Modal/SuccessModal";
import { SecondaryColor } from "../../UI/Color";
import { Line } from "../../Component/Line/Line";

import { RewardModal } from "../../Component/Modal/RewardSuccessModal";
import { check_camera_permission } from "../../Permission/Permission";
import bankAPICaller from "../../API/BankAPICaller";
import { GradientTextButton, RequestOTPModal } from "../../Component";
import { StackActions } from "@react-navigation/native";
import { normalizeDecimalValue } from "../../Utils/Common";

class TransferManualScreen extends Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    let friendName = props?.route?.params?.userName
      ? props?.route?.params?.userName
      : "";
    this.state = {
      amount: "",
      username: friendName,
      isLoading: false,
      showPinModal: false,
      isUserName: props.route?.params?.userName ? true : false,
      toTransferId: "",
      availableBalance: "",
      memberDisplay: "",
      maxAmountList: ["100", "500", "1000", "2000", "4000", "5000"],
      transactionProcessModal: false,
      verifyModalShow: false,
    };
  }

  async componentDidMount() {
    this.props?.route?.params?.isContact && this.onSearchUsername();
  }
  onPress_forgot_pin = () => {
    this.setState({ showPinModal: false });
    this.props.navigation.navigate("RecoverPin");
  };

  onSuccEnterPin = async () => {
    this.setState({ showPinModal: false });
    // this.on_transfer();
    this.on_validate_transfer();
  };

  onBackModal = () => {
    this.setState({ showPinModal: false });
  };

  onBackPressed = () => {
    this.props.route.params.onGoBack();
    this.props.navigation.goBack();
  };

  handle_amount = (val) => {
    let value = normalizeDecimalValue(val);
    if (Number(value) <= Number(this.state.availableBalance)) {
      // this.inputRef.setSelection(value?.length, value?.length)
      this.setState({
        amount: value,
      });
    } else {
      // this.inputRef.current.setSelection(
      //   this.state.availableBalance?.length,
      //   this.state.availableBalance?.length
      // )
      this.setState({
        amount: this.state.availableBalance,
      });
    }
  };

  handle_SID = (val) => {
    if (this.state.isUserName) {
      this.setState({ username: val, isUserName: false });
    } else {
      this.setState({ username: val });
    }
  };

  onNextPressed = async () => {
    if (this.state.amount != "" && this.state.isUserName) {
      if (Number(this.state.amount) <= Number(this.state.availableBalance)) {
        Keyboard.dismiss();
        this.setState({ showPinModal: true });
      } else {
        MyToast.popToast(strings.t("amount_greater_then_available_balance"));
      }
    } else {
      MyToast.popToast(strings.t("invalid_data_input"));
    }
  };

  onSearchPress = async () => {
    if (this.state.username != "") {
      this.onSearchUsername();
    } else {
      MyToast.popToast(strings.t("invalid_data_input"));
    }
  };

  onSearchUsername = async () => {
    this.setState({ isLoading: true });
    await transferAPICaller
      .checkMember(MyData.authToken, this.state.username)
      .then((res) => {
        this.setState({
          isLoading: false,
          toTransferId: res.response.member.member_unique_key,
          isUserName: true,
          availableBalance: res.response.self.walletBalance,
          memberDisplay: res.response.member.memberDisplay,
        });
      })
      .catch((err) => {
        if (err.response.status == 400) {
          //console.log(err.response)
          MyToast.popToast(strings.t("no_user_found"));
        } else if (err.response.status == 514) {
          this.props.expiredToken_logout();
        } else {
          MyToast.popToast(err.response.data.msg);
        }
        //console.log(err);
        this.setState({ isLoading: false });
      });
  };
  on_validate_transfer = async () => {
    if (MyData.country_code == "THB") {
      await this.checkUserVerification();
    } else {
      await this.on_transfer();
    }
  };
  checkUserVerification = async () => {
    this.setState({
      isLoading: true,
    });
    await bankAPICaller
      .checkUserVerification(MyData.authToken)
      .then((res) => {
        this.setState({
          isLoading: false,
        });
        this.props.navigation.navigate("TransactionOTP", {
          isTransfer: true,
          transferAmount: this.state.amount,
          transferId: this.state.toTransferId,
          onGoBack: () => this.props.route.params.onGoBack(),
        });
      })
      .catch((err) => {
        if (err.response.status == "514") {
          this.props.expiredToken_logout();
        } else if (err.response.status == "302") {
          this.setState({ verifyModalShow: true });
        } else {
          MyToast.popToast(err.response.data.msg);
        }
      })
      .finally(() => {
        this.setState({
          isLoading: false,
        });
      });
  };
  on_transfer = async () => {
    if (this.state.amount > 0) {
      this.setState({
        isLoading: true,
        transactionProcessModal: Platform.OS === "ios" ? false : true,
      });
      await transferAPICaller
        .transferMoney(
          MyData.authToken,
          this.state.toTransferId,
          this.state.amount
        )
        .then((res) => {
          this.setState({
            isLoading: false,
            transactionProcessModal: false,
          });
          this.props.navigation.navigate("PaymentSuccessScreen", {
            isSuccess: true,
            transferMessage: res.msg,
            transferAmount: res.response.amount,
            onGoBack: () => this.props.route.params.onGoBack(),
          });
          // if (MyData.country_code == "THB") {
          //   this.props.navigation.navigate("TransactionOTP", {
          //     isSuccess: true,
          //     transferMessage: res?.msg,
          //     transferAmount: res?.response?.amount,
          //     onGoBack: () => this.props.route.params.onGoBack(),
          //   });
          // } else {
          //   this.props.navigation.navigate("PaymentSuccessScreen", {
          //     isSuccess: true,
          //     transferMessage: res.msg,
          //     transferAmount: res.response.amount,
          //     onGoBack: () => this.props.route.params.onGoBack(),
          //   });
          // }
        })
        .catch((err) => {
          this.setState({
            isLoading: false,
            transactionProcessModal: false,
          });

          if (err?.status == 514) {
            this.props.expiredToken_logout();
          } else {
            this.props.navigation.navigate("PaymentSuccessScreen", {
              isSuccess: false,
              transferMessage: err?.message,
              onGoBack: () => this.props.route.params.onGoBack(),
            });
          }
        });
    } else MyToast.popToast("Amount should be greater than 0");
  };
  renderMaxAmountButton() {
    return this.state.maxAmountList.map((item, index) => (
      <TouchableOpacity
        key={index}
        onPress={() => {
          if (Number(this.state.availableBalance) >= Number(item))
            this.setState({ amount: `${item}.00` });
          else return;
        }}
        style={styles.maxButton}
      >
        <Text style={styles.maxButtonLabel}>{item}</Text>
      </TouchableOpacity>
    ));
  }
  async onPressQRScanner() {
    const res = await check_camera_permission();
    if (res)
      // this.props.navigation.navigate("TransferCamera", {
      //   onGoBack: (data) => {},
      // });
      this.props.navigation.dispatch(StackActions.replace("TransferCamera"));
  }

  onVerificationSuccess = async () => {
    this.onCloseModal();
    await this.on_transfer();
  };
  onCloseModal() {
    this.setState({ verifyModalShow: false });
  }
  onFocus = () => {
    // this.inputRef.current.setSelection(
    //   this.state.amount?.length,
    //   this.state.amount?.length
    // )
  };
  render() {
    return (
      <View style={styles.container}>
        <EnterTransactionPinModal
          isShow={this.state.showPinModal}
          onPressForgotPin={() => this.onPress_forgot_pin()}
          onSuccEnterPin={() => this.onSuccEnterPin()}
          onBackModal={() => this.onBackModal()}
        />
        {this.state.transactionProcessModal && (
          <RewardModal
            isShow={this.state.transactionProcessModal}
            source={require("../../Resources/LottieIcons/processing.json")}
            amount={strings.t("transaction_process")}
          />
        )}
        {this.state.verifyModalShow && (
          <RequestOTPModal
            isShow={this.state.verifyModalShow}
            onSuccessVerificaion={() => this.onVerificationSuccess()}
            onCloseModal={() => this.onCloseModal()}
          />
        )}
        <MainFlowHeaderContainer
          title={strings.t("transfer")}
          onBackPress={() => this.onBackPressed()}
        >
          <View
            style={{
              flex: 1,
              paddingHorizontal: HeightDimen(20),
              marginTop: HeightDimen(20),
            }}
          >
            <RecoverTextInput
              title={strings.t("username_text")}
              placeholder={strings.t("username_text")}
              top={true}
              value={this.state.username}
              bottom={true}
              onChangeText={this.handle_SID}
              iconPress={() => this.onPressQRScanner()}
              iconSrc={require("../../Resources/Images/Footer/icon_qr.png")}
            />
            {!this.state.isUserName && (
              <View
                style={{
                  marginTop: HeightDimen(30),
                }}
              >
                <GradientTextButton
                  label={strings.t("search")}
                  gradColor={["#ffee8d", "#f7c56e"]}
                  onPress={() => this.onSearchUsername()}
                  size={"100%"}
                  isLoading={this.state.isLoading}
                />
              </View>
            )}

            {this.state.isUserName && (
              <View>
                <View style={styles.availableCashContainer}>
                  <Text style={styles.cashLabel}>
                    {strings.t("phone_number")}:
                  </Text>
                  <Text style={styles.availableCashAmountLabel}>
                    {`  ${this.state.memberDisplay}`}
                  </Text>
                </View>

                <View style={styles.availableCashContainer}>
                  <Text style={styles.availableCashLabel}>
                    {strings.t("available_transfer_balance_RM") +
                      `(${MyData.country_code})`}
                  </Text>
                  <Text style={styles.availableCashAmountLabel}>
                    {`${Number(this.state.availableBalance).toFixed(2)}`}
                  </Text>
                </View>

                {/* <RecoverTextInput
                  title={strings.t('transfer_RM}
                  placeholder={"0.00"}
                  top={true}
                  bottom={true}
                  isNumber={true}
                  onChangeText={this.handle_amount}
                  value={this.state.amount}
                  isMax
                  onMaxPress={() =>
                    this.setState({ amount: this.state.availableBalance })
                  }
                /> */}
                <View style={styles.cashContainer}>
                  <Text style={styles.cashLabel}>
                    {strings.t("transfer")} ({MyData.country_code})
                  </Text>
                  <TouchableOpacity
                    onPress={() => {
                      this.setState({ amount: this.state.availableBalance });
                    }}
                  >
                    <Text style={styles.cashMaxButton}>{strings.t("max")}</Text>
                  </TouchableOpacity>
                </View>
                <View style={styles.textInputContainer}>
                  <TextInput
                    ref={(ref) => {
                      this.inputRef = ref;
                    }}
                    placeholder="0.00"
                    keyboardType="numeric"
                    placeholderTextColor={SecondaryColor}
                    style={styles.textInput}
                    onChangeText={this.handle_amount}
                    value={this.state.amount}
                    // onFocus={this.onFocus}
                  />
                </View>
                <View style={styles.maxButtonContainer}>
                  {this.renderMaxAmountButton()}
                </View>
              </View>
            )}

            {this.state.isUserName && (
              <View
                style={{
                  width: "100%",
                  alignItems: "center",
                  marginTop: HeightDimen(40),
                  justifyContent: "center",
                  flexDirection: "row",
                }}
              >
                <GradientTextButton
                  label={strings.t("confirm")}
                  gradColor={["#ffee8d", "#f7c56e"]}
                  onPress={() => this.onNextPressed()}
                  isLoading={this.state.isLoading}
                  size={"50%"}
                />
                <GradientTextButton
                  label={strings.t("cancel")}
                  gradColor={["#5a5a5a", "#2c2c2c"]}
                  onPress={() => this.onBackPressed()}
                  size={"50%"}
                  marginLeft={HeightDimen(10)}
                  blackBtn
                />
              </View>
            )}
          </View>
        </MainFlowHeaderContainer>
      </View>
    );
  }
}
const styles = {
  container: {
    flex: 1,
    backgroundColor: "white",
  },
  innerContainer: {
    flex: 1,
    paddingBottom: HeightDimen(30),
  },

  maxButtonContainer: {
    flexDirection: "row",
    marginTop: HeightDimen(30),
    alignItems: "center",
    justifyContent: "center",
  },
  maxButton: {
    backgroundColor: SecondaryColor,
    padding: HeightDimen(5),
    borderRadius: HeightDimen(5),
    marginRight: HeightDimen(5),
  },
  maxButtonLabel: {
    color: "white",
    fontSize: fontDimen(12),
    textAlign: "center",
  },
  cashContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: HeightDimen(10),
  },
  cashLabel: {
    fontSize: fontDimen(12),
    fontWeight: "bold",
  },
  cashMaxButton: {
    color: SecondaryColor,
    fontSize: fontDimen(16),
    fontWeight: "bold",
  },
  textInputContainer: {
    marginTop: HeightDimen(10),
    alignItems: "center",
    justifyContent: "center",
  },
  textInput: {
    fontSize: fontDimen(30),
    color: SecondaryColor,
    borderBottomColor: SecondaryColor,
    borderBottomWidth: HeightDimen(2),
    textAlign: "center",
  },

  availableCashContainer: {
    flexDirection: "row",
    marginTop: HeightDimen(10),
  },
  availableCashLabel: {
    fontSize: fontDimen(12),
    flex: 1,
    fontWeight: "bold",
  },
  availableCashAmountLabel: {
    fontSize: fontDimen(12),
  },
};
export default TransferManualScreen;
