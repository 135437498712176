import React from "react";
import {
  View,
  Image,
  TouchableOpacity,
  Platform,
  ImageBackground,
} from "react-native";
import Text from "../Text/MyText";
import { MainColor, FontThirdColor } from "../../UI/Color";
import { WidthDimen, HeightDimen, fontDimen } from "../../UI/Dimensions";

const GameBackgroundHeaderContainer = (props) => {
  const {
    onBackPress,
    title,
    toShow,
    toShowImage,
    toShowText,
    toShowPress,
    toShowImageUrl,
    children,
    landscape,
    hideBackButton,
    source,
  } = props;

  return (
    <ImageBackground
      source={source}
      style={{
        width: "100%",
        flex: 1,
      }}
    >
      <View
        style={{
          height: landscape
            ? Platform.OS === "ios"
              ? HeightDimen(15)
              : HeightDimen(15)
            : Platform.OS === "ios"
            ? HeightDimen(35)
            : HeightDimen(25),
        }}
      ></View>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <View
          style={{
            width: "100%",
            position: "absolute",
            alignItems: "center",
          }}
        >
          <Text
            style={{
              fontSize: fontDimen(16),
              color: FontThirdColor,
            }}
          >
            {title}
          </Text>
        </View>
        <View
          style={{
            height: HeightDimen(55),
            justifyContent: "center",
            paddingLeft: WidthDimen(20),
          }}
        >
          {!hideBackButton && (
            <TouchableOpacity
              style={{
                width: HeightDimen(50),
                height: "100%",
                justifyContent: "center",
              }}
              onPress={onBackPress}
            >
              <Image
                source={require("../../Resources/Images/icon_back.png")}
                style={{
                  width: "auto",
                  height: HeightDimen(15),
                  aspectRatio: 19 / 13,
                  resizeMode: "contain",
                }}
              />
            </TouchableOpacity>
          )}
        </View>
        {toShow ? (
          <View
            style={{
              flex: 1,
              alignItems: "flex-end",
            }}
          >
            {!hideBackButton && (
              <TouchableOpacity onPress={toShowPress}>
                <Text
                  style={{
                    fontSize: fontDimen(13),
                    color: FontThirdColor,
                    marginRight: WidthDimen(20),
                  }}
                >
                  {toShowText}
                </Text>
              </TouchableOpacity>
            )}
          </View>
        ) : toShowImage ? (
          <View
            style={{
              flex: 1,
              alignItems: "flex-end",
            }}
          >
            <TouchableOpacity
              onPress={toShowPress}
              style={{
                borderWidth: HeightDimen(1),
                borderColor: "white",
                borderRadius: 9999,
                padding: HeightDimen(5),
                marginRight: landscape ? HeightDimen(50) : HeightDimen(20),
              }}
            >
              <Image
                source={toShowImageUrl}
                style={{
                  width: "auto",
                  height: HeightDimen(15),
                  aspectRatio: 1 / 1,
                  resizeMode: "contain",
                  tintColor: "white",
                }}
              />
            </TouchableOpacity>
          </View>
        ) : (
          <></>
        )}
      </View>

      {/* <TouchableWithoutFeedback onPress={Keyboard.dismiss} accessible={false}> */}
      <View
        style={{
          width: "100%",
          paddingTop: HeightDimen(15),

          borderTopLeftRadius: HeightDimen(20),
          borderTopRightRadius: HeightDimen(20),
          flex: 1,
        }}
      >
        {children}
      </View>

      {/* </TouchableWithoutFeedback> */}
    </ImageBackground>
  );
};

export { GameBackgroundHeaderContainer };
