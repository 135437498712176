import axios from 'axios'
import { apiUrls } from './APIurl'

// import ImgToBase64 from 'react-native-image-base64';

import storageData from '../Data/StorageData'
import { Platform } from 'react-native'
import { getBrowserUniqueId, getDeviceInfo, getFcmToken } from '../Utils/Common'
import MyData from '../Data/MyData'
import AsyncStorage from '@react-native-async-storage/async-storage'

class APICaller {
  signUp = async (
    name,
    pass,
    phone,
    code,
    recomender,
    countryId,
    fullname,
    otpUniqueKey,
    phoneOtp
  ) => {
    const deviceInfo = await getDeviceInfo()
    return new Promise((resolve, reject) => {
      axios
        .post(`${MyData.api_url}${apiUrls.register}`, {
          data: {
            username: name,
            password: pass,
            confirmPassword: pass,
            countryCode: code,
            phoneNumber: phone,
            refUsername: recomender,
            countryId: countryId,
            fullname: fullname,
            otpUniqueKey: otpUniqueKey,
            phoneOtp: phoneOtp,
            deviceInfo: {
              id: getBrowserUniqueId(),
              platform: navigator.platform,
            },
            language: storageData.get_selected_language(),
          },
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  sendOTP = async (token, otp) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${MyData.api_url}${apiUrls.otp}`, {
          data: {
            tokenKey: token,
            otpCode: otp,
            language: storageData.get_selected_language(),
          },
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  resendOTP = async (token, number) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${MyData.api_url}${apiUrls.resendOtp}`, {
          data: {
            tokenKey: token,
            phoneNumber: number,
            language: storageData.get_selected_language(),
          },
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  login = async (name, pass, verification = '') => {
    const deviceInfo = await getDeviceInfo()
    const fcmToken = await getFcmToken()
    let countryCode = await AsyncStorage.getItem('countryCode')
    if (countryCode) MyData.country_code = countryCode
    return new Promise((resolve, reject) => {
      axios
        .post(`${MyData.api_url}${apiUrls.login}`, {
          data: {
            username: name,
            password: pass,
            language: storageData.get_selected_language(),
            authSession: storageData.saved_authSession,
            deviceInfo: {
              ...deviceInfo,
              id: getBrowserUniqueId(),
              platform: navigator.platform,
            },
            verificationCode: verification,
            version: '4.0.1',
            push_noti_token: fcmToken,
            countryCode: MyData.country_code,
          },
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  recoverPassword = async (phone, code, username, otpPrefix = '') => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${MyData.api_url}${apiUrls.recoverPassword}`, {
          data: {
            phone: phone,
            countryCode: code,
            language: storageData.get_selected_language(),
            username: username,
            otpPrefix: otpPrefix,
          },
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  recoverUsername = async (phone, code, otp) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${MyData.api_url}${apiUrls.recoverUsername}`, {
          data: {
            phoneNumber: phone,
            countryCode: code,
            otp: otp,
          },
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  change_password = (token, otp, oldpass, newpass, newconfirmpass) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${MyData.api_url}${apiUrls.changePassword}`, {
          data: {
            userToken: token,
            passwordOtp: otp,
            oldPassword: oldpass,
            newPassword: newpass,
            newConfirmPassword: newconfirmpass,
            language: storageData.get_selected_language(),
          },
        })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  change_nickname = (authToken, newNickName) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.changeNickname}`,
          {
            data: {
              nickName: newNickName,
              language: storageData.get_selected_language(),
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  verification = async (otp) => {
    const deviceInfo = await getDeviceInfo()
    return new Promise((resolve, reject) => {
      axios
        .post(`${MyData.api_url}${apiUrls.bind}`, {
          data: {
            tokenKey: MyData.userToken,
            otpCode: otp,
            deviceInfo: { id: getBrowserUniqueId(), platform: Platform.OS },
            version: deviceInfo.version,
          },
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  GetOTP = (otpPrefix) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${MyData.api_url}${apiUrls.otpBind}`, {
          data: {
            tokenKey: MyData.userToken,
            otpPrefix: otpPrefix,
          },
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  change_passwordNoAuth = (token, otp, oldpass, newpass, newconfirmpass) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${MyData.api_url}${apiUrls.changePasswordNoAuth}`, {
          data: {
            userToken: token,
            passwordOtp: otp,
            oldPassword: oldpass,
            newPassword: newpass,
            newConfirmPassword: newconfirmpass,
            language: storageData.get_selected_language(),
          },
        })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  getWebURL = async () => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${MyData.api_url}${apiUrls.URL}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  SendRegisterOTP = (authToken, phoneNo, code, otpPrefix = '') => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.sendRegisterOtp}`,
          {
            data: {
              phone: phoneNo,
              countryCode: code,
              otpPrefix: otpPrefix,
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  getApiURL = async () => {
    return new Promise((resolve, reject) => {
      axios
        .get(`https://m54321.b-cdn.net/luckytownconfig.json`, {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            accept: 'application/json',
          },
          withCredentials: true,
        })

        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  changeLanguage = (authToken, lang) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.changeLanguage}`,
          {
            data: {
              language: lang,
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  change_phone = (authToken, phoneNo, password, code) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.changePhone}`,
          {
            data: {
              phone: phoneNo,
              password: password,
              countryCode: code,
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  change_phone_otp = (authToken, phoneNo, password, code) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.changePhoneOtp}`,
          {
            data: {
              phone: phoneNo,
              password: password,
              countryCode: code,
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  verify_change_phone = (authToken, phoneNo, password, otp, code) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.verifyChangePhone}`,
          {
            data: {
              phone: phoneNo,
              countryCode: code,
              password: password,
              otp: otp,
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  verify_Login_token = async (username, token) => {
    const deviceInfo = await getDeviceInfo()
    const fcmToken = await getFcmToken()
    return new Promise((resolve, reject) => {
      axios
        .post(`${MyData.api_url}${apiUrls.verifyLoginToken}`, {
          data: {
            username: username,
            token: token,
            language: storageData.get_selected_language(),
            deviceInfo: {
              ...deviceInfo,
              id: getBrowserUniqueId(),
              platform: navigator.platform,
            },
            version: '4.0.1',
            push_noti_token: fcmToken,
          },
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  multi_account_Login = async (username, password) => {
    const deviceInfo = await getDeviceInfo()
    const fcmToken = await getFcmToken()
    return new Promise((resolve, reject) => {
      axios
        .post(`${MyData.api_url}${apiUrls.multiAccountLogin}`, {
          data: {
            username: username,
            password: password,
            language: storageData.get_selected_language(),
            authSession: storageData.saved_authSession,
            deviceInfo: {
              ...deviceInfo,
              id: getBrowserUniqueId(),
              platform: navigator.platform,
            },
            version: '4.0.1',
            push_noti_token: fcmToken,
          },
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  GetForgotPasswordOTP = (phoneNo, code, otpPrefix = '') => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${MyData.api_url}${apiUrls.forgotPasswordOtp}`, {
          data: {
            phoneNumber: phoneNo,
            countryCode: code,
            otpPrefix: otpPrefix,
          },
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  validateUser = (username) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${MyData.api_url}${apiUrls.validateUsername}`, {
          data: {
            username: username,
          },
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  onGetDomainURL = async (shortUrl) => {
    debugger

    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.getUsernameByLink}`,
          {
            data: { url: shortUrl },
          },
          {
            headers: {
              Authorization: MyData.authToken,
            },
          }
        )
        .then((response) => {
          resolve(response?.data?.response?.link)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  getCountryListNoAuth = async (authToken) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${MyData.api_url}${apiUrls.countryListNoAuth}`, {
          data: {},
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  getListOfCoupons = (authToken) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.couponList}`,
          {},
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  onRedeemCoupon = (authToken, couponCode, productId) => {
    console.log('@PARAMS', couponCode, productId)
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.redeemCoupon}`,
          {
            data: {
              couponCode: couponCode,
              productId,
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  checkUsername = async (username) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${MyData.api_url}${apiUrls.checkUsername}`, {
          data: {
            username: username,
            language: storageData.get_selected_language(),
          },
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  getTransactionOTP = () => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.getOtp}`,
          {},
          {
            headers: {
              Authorization: MyData.authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  verifyTransactionOTP = (otp) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.verifyOtp}`,
          {
            data: {
              otp: otp,
            },
          },
          {
            headers: {
              Authorization: MyData.authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  getS3Languages = async () => {
    return new Promise((resolve, reject) => {
      axios
        .get(`https://SunCity-game.b-cdn.net/lky_language.json`, {
          headers: { 'Cache-Control': 'no-cache', Pragma: 'no-cache' },
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  markFirstTimeLogin = () => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.markFirstTimeLogin}`,
          {},
          {
            headers: {
              Authorization: MyData.authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  getGiftWebURL = async () => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${MyData.api_url}${apiUrls.getLink}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  onPullOutCredit = (authToken) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.pullOutAllCredit}`,
          {
            data: {},
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  onGenerateGoogleAuthQrcode = (authToken) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.generateGoogleAuthQrcode}`,
          {
            data: {},
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  onSaveGoogleAuthSecret = (authToken, secret, code) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.saveGoogleAuthSecret}`,
          {
            data: {
              googleAuthSecret: secret,
              verificationCode: code,
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  onRemoveGoogleAuthSecret = (authToken, verficationCode) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.removeGoogleAuthSecret}`,
          {
            data: { verificationCode: verficationCode },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  getLkyRedmeeWebURL = async () => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${MyData.api_url}${apiUrls.getLkyRedmeeLink}`, {
          headers: {
            Authorization: MyData.authToken,
          },
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  onGetRealnameVerficationStatus = async () => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${MyData.api_url}${apiUrls.getRealnameVerificationStatus}`, {
          headers: {
            Authorization: MyData.authToken,
          },
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  onRequestRealNameCertification = (bankId, fullName) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.requestRealNameCertification}`,
          {
            data: { bankId: bankId, fullName: fullName },
          },
          {
            headers: {
              Authorization: MyData.authToken,
            },
          }
        )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  onCheckWalletPin = async (pin) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.checkWalletPin}`,
          {
            data: { pin: pin, language: 'en' },
          },
          {
            headers: {
              Authorization: MyData.authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  requestRegisterOtp = async (phoneNo, code, otpPrefix = '') => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${MyData.api_url}${apiUrls.requestRegisterOtp}`, {
          data: {
            phone: phoneNo,
            countryCode: code,
            otpPrefix: otpPrefix,
            language: storageData.get_selected_language(),
          },
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  verifyRegisterOTP = (uniqueKey, otpCode, otpPrefix = '') => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${MyData.api_url}${apiUrls.verifyRegisterOtp}`, {
          data: {
            otpUniqueKey: uniqueKey,
            otpCode: otpCode,
            otpPrefix: otpPrefix,
          },
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}

const aPICaller = new APICaller()
export default aPICaller
