import React from 'react'
import { TouchableOpacity, ActivityIndicator } from 'react-native'
import Text from '../Text/MyText'

import { SecondaryColor, FontMainColor } from '../../UI/Color'
import { HeightDimen, fontDimen } from '../../UI/Dimensions'
import { LinearGradient } from 'expo-linear-gradient'

const GradientTextButton = (props) => {
  const {
    onPress,
    label,
    size,
    whiteBtn,
    isLoading,
    blackBtn,
    marginLeft,
    isDisable,
    gradColor,
  } = props

  return (
    <LinearGradient
      colors={gradColor?.length > 0 ? gradColor : [SecondaryColor]}
      style={{
        width: size,
        borderRadius: HeightDimen(10),
        height: HeightDimen(45),
        marginLeft: marginLeft || 0,
      }}
    >
      <TouchableOpacity
        onPress={onPress}
        disabled={isLoading || isDisable}
        style={{
          width: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          height: HeightDimen(45),
        }}
      >
        {isLoading ? (
          <ActivityIndicator
            size="small"
            color={whiteBtn ? SecondaryColor : FontMainColor}
          />
        ) : (
          <>
            <Text
              style={{
                color: whiteBtn || blackBtn ? SecondaryColor : FontMainColor,
                fontSize: fontDimen(15),
              }}
            >
              {label}
            </Text>
          </>
        )}
      </TouchableOpacity>
    </LinearGradient>
  )
}

export { GradientTextButton }
