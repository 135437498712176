import React, { useEffect, useState } from 'react'
import { View, Animated, Modal, Text, Easing } from 'react-native'
import LottieView from 'lottie-react-native'
import { fontDimen, HeightDimen, WidthDimen } from '../../UI/Dimensions'
import { TextButton } from '../../Component/Button/TextButton'
import { strings } from '../../Language/Language'
import { FontSecondaryColor, MainColor, SecondaryColor } from '../../UI/Color'

export const RewardModal = (props) => {
  const { isShow, source, message, onConfirmPress, buttonText, amount } = props
  const [progress, setProgress] = useState(new Animated.Value(0))
  useEffect(() => {
    Animated.timing(progress, {
      toValue: 1,
      duration: 10000,
      easing: Easing.linear,
      useNativeDriver: true,
    }).start()
  }, [])
  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={isShow}
      statusBarTranslucent
    >
      <View
        style={{
          flex: 1,
          backgroundColor: 'rgba(0,0,0,0.5)',
          justifyContent: 'center',
        }}
      >
        <View
          style={{
            flex: 0.4,
            backgroundColor: MainColor,
            padding: HeightDimen(15),
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: HeightDimen(15),
            marginHorizontal: HeightDimen(40),
          }}
        >
          <LottieView
            autoPlay
            progress={progress}
            style={{ height: HeightDimen(150), alignSelf: 'center' }}
            source={source}
            loop
          />
          {amount ? (
            <Text
              style={{
                textAlign: 'center',
                fontSize: fontDimen(20),
                color: FontSecondaryColor,
                fontWeight: 'bold',
                marginTop: HeightDimen(10),
              }}
            >
              {amount}
            </Text>
          ) : (
            <View />
          )}
          {buttonText && (
            <View
              style={{
                marginTop: HeightDimen(30),
                justifyContent: 'center',
                flexDirection: 'row',
              }}
            >
              <TextButton
                onPress={onConfirmPress}
                label={buttonText}
                size={2.5}
              />
            </View>
          )}
        </View>
      </View>
    </Modal>
  )
}
