import React, { Component } from 'react'
import { View } from 'react-native'
import { HeightDimen } from '../../UI/Dimensions'
import { LoginFlowHeaderContainer } from '../../Component/Header/LoginFlowHeader'
import { strings } from '../../Language/Language'
import { SettingCard } from '../../Component/Card/SettingCard'

class ForgotUIDPasswordScreen extends Component {
  onBackPressed = () => {
    this.props.navigation.goBack()
  }
  onForgetPassword = () => {
    this.props.navigation.navigate('Recover')
  }
  onForgetUsername = () => {
    this.props.navigation.navigate('RecoverUsername')
  }

  render() {
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: 'white',
        }}
      >
        <LoginFlowHeaderContainer
          onBackPress={() => this.onBackPressed()}
          mainText={strings.t('forgot_id_password')}
        >
          <View
            style={{
              flex: 1,
              paddingHorizontal: HeightDimen(20),
            }}
          >
            <SettingCard
              label={strings.t('forgotPassword_text')}
              onPress={() => this.onForgetPassword()}
            />
            <View style={{ height: HeightDimen(3) }}></View>
            <SettingCard
              label={strings.t('forgotUsername_text')}
              onPress={() => this.onForgetUsername()}
            />
          </View>
        </LoginFlowHeaderContainer>
      </View>
    )
  }
}

export default ForgotUIDPasswordScreen
