import React, { Component } from 'react'
import {
  View,
  ActivityIndicator,
  FlatList,
  TouchableOpacity,
  Image,
  Text,
} from 'react-native'
import aPICaller from '../../API/APICaller'
import { MainFlowHeaderContainerNoFeedback } from '../../Component/Header/MainFlowHeader'
import MyToast from '../../Component/Toast/MyToast'
import { strings } from '../../Language/Language'
import {
  FontMainColor,
  FontSecondaryColor,
  MainColor,
  SecondaryColor,
} from '../../UI/Color'
import { fontDimen, HeightDimen } from '../../UI/Dimensions'

class CountrySignUpScreen extends Component {
  state = {
    isLoading: '',
    countryList: [],
  }
  async componentDidMount() {
    await this.getCountryList()
  }
  onBackPressed = () => {
    this.props.navigation.goBack()
  }

  getCountryList = async () => {
    this.setState({ isLoading: true })
    await aPICaller
      .getCountryListNoAuth()
      .then((res) => {
        this.setState({ countryList: res.response.list })
      })
      .catch((err) => {
        MyToast.popToast(err.response.data.msg)
      })
      .finally(() => {
        this.setState({ isLoading: false })
      })
  }

  onNextPressed = async (id) => {
    if (this.props.isFromInfo)
      this.props.navigation.navigate('SignUps', {
        countryId: id,
      })
    else
      this.props.navigation.navigate('SignUp', {
        countryId: id,
      })
  }

  render() {
    return (
      <View style={styles.container}>
        <MainFlowHeaderContainerNoFeedback
          title={strings.t('signUp_text')}
          onBackPress={() => this.onBackPressed()}
        >
          <View style={styles.innerContainer}>
            {!this.state.isLoading ? (
              <FlatList
                style={{
                  flex: 1,
                }}
                contentContainerStyle={{ alignItems: 'center' }}
                numColumns={3}
                data={this.state.countryList}
                renderItem={({ item, index }) => (
                  <TouchableOpacity
                    onPress={() => this.onNextPressed(item?.country_id)}
                    disabled={this.state.isLoading}
                    style={styles.button}
                  >
                    <Image
                      style={styles.Image}
                      source={{ uri: item.icon_url }}
                    />
                    <Text style={styles.label}>{item.name}</Text>
                  </TouchableOpacity>
                )}
                keyExtractor={(item) => String(item.id)}
              />
            ) : (
              <ActivityIndicator size="small" color={MainColor} />
            )}
          </View>
        </MainFlowHeaderContainerNoFeedback>
      </View>
    )
  }
}
const styles = {
  container: {
    flex: 1,
    backgroundColor: 'white',
  },
  innerContainer: {
    flex: 1,
    paddingHorizontal: HeightDimen(20),
    paddingTop: HeightDimen(20),
  },
  button: {
    margin: HeightDimen(10),
    borderColor: SecondaryColor,
    alignItems: 'center',
  },
  Image: {
    width: 'auto',
    height: HeightDimen(80),
    aspectRatio: 1 / 1,
    borderRadius: HeightDimen(9999),
  },
  label: {
    fontSize: fontDimen(14),
    color: 'white',
    backgroundColor: SecondaryColor,
    padding: HeightDimen(5),
    borderRadius: HeightDimen(5),
  },
}
export default CountrySignUpScreen
