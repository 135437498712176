import React, { Component } from 'react'
import { View } from 'react-native'
import { strings } from '../../Language/Language'
import { MainFlowHeaderContainerNoFeedback } from '../../Component/Header/MainFlowHeader'
import { TextButton } from '../../Component'
import { HeightDimen } from '../../UI/Dimensions'

import walletAPICaller from '../../API/WalletAPICaller'
import MyData from '../../Data/MyData'
import MyToast from '../../Component/Toast/MyToast'
import { TopUpModal } from '../../Component/Modal/NewDesign/TopUpModal'

class BannerDetailScreen extends Component {
  state = {
    url: this.props?.route?.params?.bannerUrl,
    couponCode: '',
    topUpMethods: [],
    showTopupModal: false,
    isLoading: false,
  }

  async componentDidMount() {
    let routeParams = this.props?.route?.params
    if (routeParams?.coupon_detail_url) {
      await this.getTopupMethod()
      this.setState({
        url: routeParams?.coupon_detail_url,
        couponCode: routeParams?.coupon_code,
      })
    }
  }
  getTopupMethod = async () => {
    this.setState({
      isLoading: true,
    })
    await walletAPICaller
      .getTopupMethods(MyData.authToken)
      .then((res) => {
        this.setState({
          isLoading: false,
          topUpMethods: res.response.list,
        })
      })
      .catch((err) => {
        if (err.response.status == 514) {
        } else {
          MyToast.popToast(err.response.data.msg)
        }
        this.setState({
          isLoading: false,
        })
      })
  }

  onBackPressed = () => {
    // this.props.route.params.onGoBack();
    this.props.navigation.goBack()
  }
  _onPressInstantTopUp = () => {
    this.showTopupModal(false)
    this.props.navigation.navigate('InstantTopUp', {
      backToRedeem: true,
      onGoBack: (data) => {},
    })
  }

  _onPressUsdtTopUp = () => {
    this.showTopupModal(false)
    this.props.navigation.navigate('UsdtTopUp', {
      backToRedeem: true,
      onGoBack: (data) => {},
    })
  }

  _onPressBankTopUp = () => {
    this.showTopupModal(false)
    this.props.navigation.navigate('TopUp', {
      backToRedeem: true,
      onGoBack: (data) => {},
    })
  }

  showTopupModal(isShow) {
    this.setState({ showTopupModal: isShow })
  }
  _onCheckTopUp = async () => {
    this.setState({
      isLoading: true,
    })
    await walletAPICaller
      .onCheckTopUp(MyData.authToken)
      .then(async (res) => {
        if (res.status == 200) {
          MyData.coupon_code = this.state.couponCode
          this.showTopupModal(true)
        } else if (res.status == 201) {
          const data = res?.data?.response?.info
          if (data?.wallet_country == '1') MyData.wallet_country = 'MYR'
          else if (data?.wallet_country == '2') MyData.wallet_country = 'THB'
          this.props.navigation.navigate('TopUpDetailsScreen', {
            bankList: [],
            selectedTopUpDetails: res.data.response.info,
            onGoBack: (data) => {},
          })
        }
      })
      .catch((err) => {
        if (err?.response?.status == '514') {
        } else {
          MyToast.popToast(err.response.data.msg)
        }
      })
      .finally(() => {
        this.setState({
          isLoading: false,
        })
      })
  }

  render() {
    return (
      <View
        style={{
          flex: 1,
          //   backgroundColor: "white",
        }}
      >
        <MainFlowHeaderContainerNoFeedback
          title={strings.t('details')}
          onBackPress={() => this.onBackPressed()}
        >
          <View
            style={{
              flex: 1,
            }}
          >
            <iframe
              src={this.state.url}
              height={'100%'}
              width={'100%'}
              frameBorder="0"
            />
          </View>
          {this?.state?.couponCode ? (
            <View
              style={{
                marginHorizontal: HeightDimen(15),
                marginBottom: HeightDimen(10),
              }}
            >
              <TextButton
                onPress={() => {
                  if (this.props?.route?.params?.coupon_redirect_url)
                    this.props.navigation.navigate('BonusDetail', {
                      bannerUrl: this.props?.route?.params?.coupon_redirect_url,
                    })
                  else this._onCheckTopUp()
                }}
                label={strings.t('redeem_coupon')}
                size={5}
                isLoading={this.state.isLoading}
              />
            </View>
          ) : (
            <View />
          )}
          {this.state.topUpMethods?.length > 0 && (
            <TopUpModal
              isShow={this.state.showTopupModal}
              topupList={this.state.topUpMethods}
              onPressInstantTopUp={() => this._onPressInstantTopUp()}
              onPressBankTopUp={() => this._onPressBankTopUp()}
              onPressUsdtTopUp={() => this._onPressUsdtTopUp()}
              onBackPress={() => this.showTopupModal(false)}
            />
          )}
        </MainFlowHeaderContainerNoFeedback>
      </View>
    )
  }
}

export default BannerDetailScreen
