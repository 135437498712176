import React, { Component } from 'react'
import { View, ActivityIndicator } from 'react-native'
import { MainColor } from '../../UI/Color'
import { HeightDimen } from '../../UI/Dimensions'
import { strings } from '../../Language/Language'
import MyData from '../../Data/MyData'
import { MainFlowHeaderContainerNoFeedback } from '../../Component/Header/MainFlowHeader'
import aPICaller from '../../API/APICaller'

class CustomerServiceScreen extends Component {
  state = {
    url: '',
    isLoading: false,
  }

  async componentDidMount() {
    // logScreenViewAnalytics(screenNames.CustomerService);
    this.setState({ isLoading: true })
    await aPICaller
      .getWebURL()
      .then((res) => {
        this.setState({
          url: res.response.url,
        })
      })
      .catch((err) => {
        if (err.response.status == 514) {
          this.props.expiredToken_logout()
        }
      })
      .finally(() => {
        this.setState({ isLoading: false })
      })
  }

  onBackPressed = () => {
    this.props.route.params.onGoBack()
    this.props.navigation.goBack()
  }

  render() {
    const { member_username, last_login_ip } =
      MyData.temp_json_object.response.user

    return (
      <View
        style={{
          flex: 1,
          //   backgroundColor: "white",
        }}
      >
        <MainFlowHeaderContainerNoFeedback
          title={strings.t('customer_service')}
          onBackPress={() => this.onBackPressed()}
        >
          <View
            style={{
              flex: 1,
              // marginTop: HeightDimen(30),
            }}
          >
            {this.state.isLoading ? (
              <View
                style={{
                  marginTop: HeightDimen(30),
                }}
              >
                <ActivityIndicator size="small" color={MainColor} />
              </View>
            ) : (
              // <WebView
              //   source={{
              //     uri: `${this.state.url}/Support/live/${encodeURIComponent(
              //       member_username
              //     )}/${last_login_ip}/${MyData?.country_id}`,
              //   }}
              //   style={{
              //     flex: 1,
              //   }}
              // />
              <iframe
                src={`${this.state.url}/Support/live/${encodeURIComponent(
                  member_username
                )}/${last_login_ip}/${MyData?.country_id}`}
                height={'100%'}
                width={'100%'}
                frameBorder="0"
              />
            )}
          </View>
        </MainFlowHeaderContainerNoFeedback>
      </View>
    )
  }
}

export default CustomerServiceScreen
