import React, { Component } from 'react'
import { View, FlatList, ActivityIndicator } from 'react-native'
import { HeightDimen } from '../../UI/Dimensions'
import { NoBackHeaderContainer } from '../../Component/Header/NoBackHeader'
import { strings } from '../../Language/Language'
import { MainColor } from '../../UI/Color'
import { SearchBar } from '../../Component/SearchBar/SearchBar'
import { SettingCard } from '../../Component/Card/SettingCard'
import storageData from '../../Data/StorageData'
import { resetNavigationStack } from '../../Utils/Common'

class RecoverUsersScreen extends Component {
  state = {
    userList: [],
    isLoading: false,
    tempUserList: [],
  }

  componentDidMount() {
    const allUsers = this.props.route.params.userList
    this.setState({ userList: allUsers, tempUserList: allUsers })
  }

  onSearch = (search) => {
    const { userList } = this.state
    let filterUsers = userList.filter((o) => {
      return o.member_username
        .toUpperCase()
        .replace(/ /g, '')
        .includes(search.toUpperCase().replace(/ /g, ''))
    })
    this.setState({ tempUserList: filterUsers })
  }
  async onPress_forgotUsername(name) {
    //this.props.navigation.navigate("login", )

    await storageData.update_username_add_password(name, '')
    resetNavigationStack(this.props.navigation, 'Login')
  }
  render() {
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: 'white',
        }}
      >
        <NoBackHeaderContainer title={strings.t('recover_username')}>
          <View
            style={{
              flex: 1,
              backgroundColor: 'white',
            }}
          >
            <View
              style={{
                paddingHorizontal: HeightDimen(20),
                marginBottom: HeightDimen(10),
              }}
            >
              <SearchBar
                placeholder={'Search user'}
                onChangeText={(val) => this.onSearch(val)}
              />
            </View>
            {this.state.isLoading ? (
              <View
                style={{
                  marginTop: HeightDimen(15),
                }}
              >
                <ActivityIndicator size="small" color={MainColor} />
              </View>
            ) : (
              <FlatList
                refreshing={this.state.isLoading}
                data={this.state.tempUserList}
                contentContainerStyle={{
                  paddingHorizontal: HeightDimen(20),
                  paddingVertical: HeightDimen(15),
                }}
                renderItem={({ item, index }) => (
                  <View>
                    <SettingCard
                      label={item.member_username}
                      onPress={() =>
                        this.onPress_forgotUsername(item.member_username)
                      }
                    />
                    <View style={{ height: HeightDimen(3) }} />
                  </View>
                )}
                keyExtractor={(item) => String(item.id)}
              />
            )}
          </View>
        </NoBackHeaderContainer>
      </View>
    )
  }
}

export default RecoverUsersScreen
