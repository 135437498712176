import React, { Component } from "react";
import { View, TouchableOpacity, Image, Text } from "react-native";
import { FontMainColor, SecondaryColor } from "../../../UI/Color";
import { fontDimen, HeightDimen } from "../../../UI/Dimensions";
import { LinearGradient } from "expo-linear-gradient";

const GradientProfileCard = (props) => {
  const {
    src,
    label,
    hideShowNext,
    onPress,

    disabled,

    wantSecondaryLabel,
    customLabelStyle,

    colors,
    marginTop,
    roundImage,
  } = props;

  return (
    <LinearGradient
      colors={colors?.length > 0 ? colors : ["#fffaa3", "#cc8c2b"]}
      style={{
        borderRadius: HeightDimen(10),
        marginTop: marginTop || 8,
      }}
    >
      <TouchableOpacity
        onPress={onPress}
        style={styles.container}
        disabled={disabled}
      >
        <View style={roundImage && styles.imageContainer}>
          <Image source={src} style={styles.image} />
        </View>
        <View style={styles.labelContainer}>
          <Text
            style={[
              {
                ...styles.label,
                color: wantSecondaryLabel ? SecondaryColor : "white",
              },
              customLabelStyle,
            ]}
          >
            {label}
          </Text>
        </View>
        {!hideShowNext ? (
          <></>
        ) : (
          <Image
            source={require("../../../Resources/Images/icon_nextP.png")}
            style={styles.arrowImage}
          />
        )}
      </TouchableOpacity>
    </LinearGradient>
  );
};
const styles = {
  container: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    padding: HeightDimen(10),
  },
  imageContainer: {
    width: "auto",
    height: HeightDimen(40),
    aspectRatio: 1 / 1,
    borderRadius: 9999,
    borderWidth: HeightDimen(3),
    borderColor: SecondaryColor,
    backgroundColor: "white",

    overflow: "hidden",
    justifyContent: "center",
    alignItems: "center",
  },
  image: {
    width: "auto",
    height: HeightDimen(35),
    aspectRatio: 1 / 1,
    resizeMode: "contain",
  },
  labelContainer: {
    flex: 1,
    marginLeft: HeightDimen(15),
    marginRight: HeightDimen(15),
  },
  label: {
    fontSize: fontDimen(15),
    color: FontMainColor,
    //  marginTop: HeightDimen(5),
    fontWeight: "bold",
  },
  arrowImage: {
    width: "auto",
    height: HeightDimen(20),
    aspectRatio: 16 / 27,
    resizeMode: "contain",
    tintColor: "white",
  },
};

export { GradientProfileCard };
