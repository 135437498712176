import React, { Component } from "react";
import { View, Modal, Keyboard } from "react-native";
import { HeightDimen, WidthDimen } from "../../UI/Dimensions";
import { strings } from "../../Language/Language";
import MyData from "../../Data/MyData";
import {
  AnalyticEvents,
  logMessageAnalytics,
  logScreenViewAnalytics,
  screenNames,
} from "../../Utils/Analytics";
import { PhoneTextInput } from "../Input/PhoneTextInput";
import { LoginFlowHeaderContainer } from "../Header/LoginFlowHeader";
import { TextButton } from "../Button/TextButton";
import { OTPCard } from "../Card/OTPCard";
import NumberPad from "../NumberPad/NumberPad";
import aPICaller from "../../API/APICaller";
import MyToast from "../Toast/MyToast";
import { phoneRegex } from "../../Utils/Common";

class RequestOTPModal extends Component {
  state = {
    OTP: "",
    isLoading: false,
    phoneNumber: MyData?.temp_json_object?.response?.user?.member_phone,
    showNumberPad: true,
    code: MyData?.temp_json_object?.response?.user?.member_phone_country,
    existingCode: "",
  };

  componentDidMount() {
    logScreenViewAnalytics(screenNames.requestOtpVerification);
    this.keyboardDidShowListener = Keyboard.addListener(
      "keyboardDidShow",
      this._keyboardDidShow
    );
    this.keyboardDidHideListener = Keyboard.addListener(
      "keyboardDidHide",
      this._keyboardDidHide
    );
  }

  componentWillUnmount() {
    this.keyboardDidShowListener.remove();
    this.keyboardDidHideListener.remove();
  }

  handle_phoneNumber = (phone) => {
    this.setState({ phoneNumber: phone });
  };

  _keyboardDidShow = () => {
    this.setState({
      showNumberPad: false,
    });
  };

  _keyboardDidHide = () => {
    this.setState({
      showNumberPad: true,
    });
  };

  render_otp_input_box = () => {
    return (
      <View
        style={{
          justifyContent: "center",
          flexDirection: "row",
          marginBottom: HeightDimen(40),
        }}
      >
        <OTPCard
          value={this.state.OTP}
          num={0}
          onPress={() => this.hide_keyboard()}
          enablePress={!this.state.showNumberPad}
        />
        <OTPCard
          marginLeft={WidthDimen(10)}
          value={this.state.OTP}
          num={1}
          onPress={() => this.hide_keyboard()}
          enablePress={!this.state.showNumberPad}
        />
        <OTPCard
          marginLeft={WidthDimen(10)}
          value={this.state.OTP}
          num={2}
          onPress={() => this.hide_keyboard()}
          enablePress={!this.state.showNumberPad}
        />
        <OTPCard
          marginLeft={WidthDimen(10)}
          value={this.state.OTP}
          num={3}
          onPress={() => this.hide_keyboard()}
          enablePress={!this.state.showNumberPad}
        />
        <OTPCard
          marginLeft={WidthDimen(10)}
          value={this.state.OTP}
          num={4}
          onPress={() => this.hide_keyboard()}
          enablePress={!this.state.showNumberPad}
        />
        <OTPCard
          marginLeft={WidthDimen(10)}
          value={this.state.OTP}
          num={5}
          onPress={() => this.hide_keyboard()}
          enablePress={!this.state.showNumberPad}
        />
      </View>
    );
  };

  onResendOTP = async () => {
    if (this.state.phoneNumber) {
      await aPICaller
        .SendRegisterOTP(
          MyData.authToken,
          this.state.phoneNumber,
          this.state.code
        )
        .then((res) => {
          logMessageAnalytics(
            AnalyticEvents.verificationOTPSendRegisterOTPSuccess
          );
          MyToast.popToast(strings.otp_code_resended);
        })
        .catch((err) => {
          logMessageAnalytics(
            AnalyticEvents.verificationOTPSendRegisterOTPFailure,
            err.response.data.msg
          );

          MyToast.popToast(err.response.data.msg);
        });
    } else {
      logMessageAnalytics(
        AnalyticEvents.verificationOTPSendRegisterOTPFailure,
        strings.please_enter_phone_number
      );
      MyToast.popToast(strings.please_enter_phone_number);
    }
  };

  onEnterOTP = (num) => {
    let temp_OTP = this.state.OTP;

    if (num === "X") {
      if (temp_OTP.length > 0) {
        temp_OTP = temp_OTP.slice(0, -1);
        this.setState({ OTP: temp_OTP });
      }
    } else {
      if (temp_OTP.length < 6) {
        temp_OTP = temp_OTP + num;
        this.setState({ OTP: temp_OTP });
      }
    }
  };
  onNextPressed = async () => {
    this.setState({ isLoading: true });
    await aPICaller
      .sendOTP(MyData.userToken, this.state.OTP)
      .then(async (res) => {
        logMessageAnalytics(AnalyticEvents.verificationOTPSendOTPSuccess, {
          OTP: this.state.OTP,
        });
        MyData.setUp(res);
        this.props.onSuccessVerificaion();
      })
      .catch((err) => {
        logMessageAnalytics(
          AnalyticEvents.verificationOTPSendOTPFailure,
          strings.invalid_otp_code
        );
        MyToast.popToast(strings.invalid_otp_code);
        this.setState({ isLoading: false });
      });
  };
  onSelectCode(code) {
    this.setState({
      code: code,
    });
  }
  render() {
    const { isShow, onCloseModal } = this.props;
    return (
      <Modal
        animationType="slide"
        transparent={true}
        visible={isShow}
        statusBarTranslucent
      >
        <LoginFlowHeaderContainer
          onBackPress={() => onCloseModal()}
          mainText={strings.request_otp}
          subText={strings.please_enter_otp}
        >
          <View
            style={{
              flex: 1,
            }}
          >
            <View
              style={{
                marginLeft: HeightDimen(20),
                marginRight: HeightDimen(20),
                marginTop: HeightDimen(20),
              }}
            >
              <PhoneTextInput
                placeholder={strings.phone_number}
                bottom={true}
                top={true}
                existingCode={this.state.code}
                value={this.state.phoneNumber}
                marginBottom={HeightDimen(20)}
                onOtpPress={() => this.onResendOTP()}
                onSelectCodePress={(val) => this.onSelectCode(val)}
                isNumber={true}
                onChangeText={this.handle_phoneNumber}
                isCounter
                isValidation={this.state.phoneNumber ? false : true}
              />
            </View>
            <this.render_otp_input_box />
            <View
              style={{
                width: "100%",
                paddingLeft: HeightDimen(30),
                paddingRight: HeightDimen(30),
              }}
            >
              <TextButton
                label={strings.proceed}
                size={5}
                onPress={() => this.onNextPressed()}
                isLoading={this.state.isLoading}
              />
            </View>
          </View>
          {this.state.showNumberPad && (
            <NumberPad onPressSelect={this.onEnterOTP} />
          )}
        </LoginFlowHeaderContainer>
      </Modal>
    );
  }
}

export { RequestOTPModal };
