import React, { Component } from "react";
import {
  View,
  Image,
  TouchableOpacity,
  Platform,
  TouchableWithoutFeedback,
  Keyboard,
  ImageBackground,
} from "react-native";
import Text from "../../Text/MyText";

import {
  MainColor,
  SecondaryColor,
  FontMainColor,
  FontSecondaryColor,
  FontThirdColor,
  HeaderColor,
  EN_Font,
} from "../../../UI/Color";
import {
  WidthDimen,
  HeightDimen,
  fontDimen,
  GetWidth,
  GetHeight,
} from "../../../UI/Dimensions";
import { GradientHeader } from "./GradientHeader";

const NoBackHeader = (props) => {
  const { title, yellowBackground, withIcon, iconSRC, iconOnPressed } = props;

  return (
    <View
      style={{
        width: "100%",
        backgroundColor: MainColor,
      }}
    >
      <View
        style={{
          height: Platform.OS === "ios" ? HeightDimen(35) : HeightDimen(25),
        }}
      ></View>
      <View
        style={{
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          height: HeightDimen(55),
        }}
      >
        <Text
          style={{
            fontSize: fontDimen(16),
            color: FontThirdColor,
          }}
        >
          {title}
        </Text>
        {withIcon ? (
          <View
            style={{
              width: "100%",
              position: "absolute",
              alignItems: "flex-end",
              paddingRight: HeightDimen(15),
            }}
          >
            <TouchableOpacity onPress={iconOnPressed}>
              <Image
                source={iconSRC}
                style={{
                  width: "auto",
                  height: HeightDimen(15),
                  aspectRatio: 19 / 13,
                  resizeMode: "contain",
                  tintColor: "white",
                }}
              />
            </TouchableOpacity>
          </View>
        ) : (
          <></>
        )}
      </View>
      <View
        style={{
          width: "100%",
          height: HeightDimen(30),
          backgroundColor: "white",
          borderTopLeftRadius: HeightDimen(20),
          borderTopRightRadius: HeightDimen(20),
          overflow: "hidden",
        }}
      >
        {yellowBackground ? (
          <View
            style={{
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(237, 181, 61, 0.1)",
            }}
          ></View>
        ) : (
          <></>
        )}
      </View>
    </View>
  );
};

const NoBackHeaderContainer = (props) => {
  const {
    title,
    yellowBackground,
    withIcon,
    iconSRC,
    iconOnPressed,
    children,
  } = props;

  return (
    <View
      style={{
        width: "100%",
        backgroundColor: SecondaryColor,
        flex: 1,
      }}
    >
      <View
        style={{
          height: Platform.OS === "ios" ? HeightDimen(35) : HeightDimen(25),
          backgroundColor: HeaderColor,
        }}
      ></View>
      <View
        style={{
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          height: HeightDimen(55),
        }}
      >
        <GradientHeader />
        <Text
          style={{
            fontSize: fontDimen(16),
            color: FontThirdColor,
            fontFamily: EN_Font,
          }}
        >
          {title}
        </Text>
        {withIcon ? (
          <View
            style={{
              width: "100%",
              position: "absolute",
              alignItems: "flex-end",
              paddingRight: HeightDimen(15),
            }}
          >
            <TouchableOpacity onPress={iconOnPressed}>
              <Image
                source={iconSRC}
                style={{
                  width: "auto",
                  height: HeightDimen(15),
                  aspectRatio: 19 / 13,
                  resizeMode: "contain",
                  tintColor: "white",
                }}
              />
            </TouchableOpacity>
          </View>
        ) : (
          <></>
        )}
      </View>
      {/* <TouchableWithoutFeedback onPress={Keyboard.dismiss} accessible={false}> */}
      <View
        style={{
          width: "100%",

          backgroundColor: "white",

          flex: 1,
          //paddingTop: HeightDimen(30),
        }}
      >
        <ImageBackground
          source={require("../../../Resources/Images/bg.png")}
          style={styles.backgroundImage}
        >
          {children}
        </ImageBackground>
        {/* {yellowBackground ? (
                        <View
                            style={{
                                width: '100%',
                                height: '100%',
                                backgroundColor: 'rgba(237, 181, 61, 0.1)'
                            }}
                        >

                        </View>
                    ) : (
                        <></>
                    )} */}
      </View>
      {/* </TouchableWithoutFeedback> */}
    </View>
  );
};

const NoBackHeaderContainerNoFeedback = (props) => {
  const {
    title,
    yellowBackground,
    withIcon,
    iconSRC,
    iconOnPressed,
    children,
    notiCounter,
  } = props;

  return (
    <View
      style={{
        width: "100%",
        backgroundColor: MainColor,
        flex: 1,
      }}
    >
      <View
        style={{
          height: Platform.OS === "ios" ? HeightDimen(35) : HeightDimen(25),
        }}
      ></View>
      <View
        style={{
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          height: HeightDimen(55),
        }}
      >
        <Text
          style={{
            fontSize: fontDimen(16),
            color: FontThirdColor,
          }}
        >
          {title}
        </Text>
        {withIcon ? (
          <View
            style={{
              width: "100%",
              position: "absolute",
              alignItems: "flex-end",
              paddingRight: HeightDimen(15),
            }}
          >
            <TouchableOpacity onPress={iconOnPressed}>
              <Image
                source={iconSRC}
                style={{
                  width: "auto",
                  height: HeightDimen(22),
                  aspectRatio: 19 / 11,
                  resizeMode: "contain",
                  tintColor: "white",
                }}
              />
              {notiCounter > 0 && (
                <View
                  style={{
                    height: 15,
                    width: 15,
                    borderRadius: 9,
                    backgroundColor: "#DB1920",
                    position: "absolute",
                    top: -8,
                    right: 0,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Text style={{ color: "white", fontSize: 8 }}>
                    {notiCounter}
                  </Text>
                </View>
              )}
            </TouchableOpacity>
          </View>
        ) : (
          <></>
        )}
      </View>

      <View
        style={{
          width: "100%",

          backgroundColor: "white",
          borderTopLeftRadius: HeightDimen(20),
          borderTopRightRadius: HeightDimen(20),
          overflow: "hidden",
          flex: 1,
          paddingTop: HeightDimen(30),
        }}
      >
        <ImageBackground
          source={require("../../../Resources/Images/bg.png")}
          style={styles.backgroundImage}
        >
          {children}
        </ImageBackground>

        {/* {yellowBackground ? (
                        <View
                            style={{
                                width: '100%',
                                height: '100%',
                                backgroundColor: 'rgba(237, 181, 61, 0.1)'
                            }}
                        >

                        </View>
                    ) : (
                        <></>
                    )} */}
      </View>
    </View>
  );
};
const styles = {
  backgroundImage: {
    width: "100%",

    flex: 1,
  },
};
export { NoBackHeader, NoBackHeaderContainer, NoBackHeaderContainerNoFeedback };
