import React, { Component } from 'react'
import { View, Image, ActivityIndicator, ScrollView } from 'react-native'
import Text from '../../Component/Text/MyText'
import { MainColor, SecondaryColor } from '../../UI/Color'
import { HeightDimen, fontDimen } from '../../UI/Dimensions'
import { MainFlowHeaderContainerNoFeedback } from '../../Component/Header/MainFlowHeader'
import { RecoverTextInput } from '../../Component/Input/RecoverTextInput'
import { TextButton } from '../../Component/Button/TextButton'
import { UploadTopUpModal } from '../../Component/Modal/UploadTopUpModal'
import { ConfirmUploadTopUpModal } from '../../Component/Modal/ConfirmUploadTopUpModal'
import MyToast from '../../Component/Toast/MyToast'

import walletAPICaller from '../../API/WalletAPICaller'
import MyData from '../../Data/MyData'
import { strings } from '../../Language/Language'
import bankAPICaller from '../../API/BankAPICaller'
import { DisplayInput } from '../../Component/Input/DisplayInput'
import * as ImagePicker from 'expo-image-picker'

import { SuccessModal } from '../../Component/Modal/SuccessModal'

const delay = (ms) => new Promise((res) => setTimeout(res, ms))

class QRTopUpScreen extends Component {
  state = {
    show_upload_modal: false,
    show_confirm_upload: false,
    imgUrl: '',
    isLoading: false,
    fullName: '',
    amount: '',
    dateTime: '',
    companyName: '',
    qrUrl: '',
    topUpBankId: '',
    isSuccess: false,
    isConfirmModal: false,
    transferMessage: '',
    promo_code: MyData.coupon_code,
    base64Image: '',
  }

  async componentDidMount() {
    this.get_topup_QR_Pay()
  }

  get_topup_QR_Pay = async () => {
    this.setState({ isLoading: true })
    await bankAPICaller
      .getQRTopup(MyData.authToken)
      .then((res) => {
        const baseModel = res.response.bank
        console.log(baseModel)
        this.setState({
          dateTime: baseModel.topup_bank_created_datetime,
          companyName: baseModel.topup_bank_account_name,
          qrUrl: baseModel.topup_bank_qrcode_url,
          topUpBankId: baseModel.topup_bank_id,
        })
      })
      .catch((err) => {
        if (err.response.status == 514) {
          this.props.expiredToken_logout()
        } else {
          MyToast.popToast(err.response.data.msg)
        }
        //console.log(err);
      })
      .finally(() => {
        this.setState({ isLoading: false })
      })
  }

  onBackPressed = () => {
    if (this.props.route.params?.backToRedeem) {
      this.props.navigation.pop(2)
      return
    }
    this.props.route.params?.onGoBack()
    this.props.navigation?.goBack()
  }

  handle_amount = (val) => {
    this.setState({ amount: val })
  }
  handle_Name = (val) => {
    this.setState({ fullName: val })
  }
  onNextPressed = () => {
    if (this.state.amount != '') {
      this.setState({ show_upload_modal: true })
    } else {
      MyToast.popToast(strings.t('invalid_data_input'))
    }
  }

  onCancel_modal = () => {
    this.setState({ show_upload_modal: false })
  }

  onPress_Camera = async () => {
    this.setState({ show_upload_modal: false })

    let result = await ImagePicker.launchCameraAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
      base64: true,
    })
    debugger
    console.log(result)

    if (!result.canceled) {
      this.setState({
        imgUrl: result?.assets[0]?.uri,
        base64Image: result?.assets[0]?.base64,
        show_confirm_upload: true,
      })
    }
  }

  refresh = (data) => {
    this.setState({
      imgUrl: data,
      show_confirm_upload: true,
    })
  }

  onPress_local = async () => {
    this.setState({
      show_upload_modal: false,
    })
    await delay(500)

    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
      base64: true,
    })

    if (!result.canceled) {
      this.setState({
        imgUrl: result?.assets[0]?.uri,
        base64Image: result?.assets[0]?.base64,
        show_confirm_upload: true,
      })
    }
  }

  onCancel_confirm_modal = () => {
    this.setState({ show_confirm_upload: false })
  }

  onComformUpload = async () => {
    this.setState({ isLoading: true, show_confirm_upload: false })
    await walletAPICaller
      .uploadReceiptTopUp(this.state.base64Image, MyData.authToken)
      .then((res) => {
        this.onUploadTopUpAll(res)
      })
      .catch((err) => {
        this.setState({ isLoading: false })
        if (err.response.status == 514) {
          this.props.expiredToken_logout()
        } else {
          MyToast.popToast(err.response.data.msg)
        }
      })
  }

  onUploadTopUpAll = async (img) => {
    await walletAPICaller
      .uploadTopUpValue(
        MyData.authToken,
        this.state.amount,
        img,
        this.state.topUpBankId,
        this.state.fullName,
        this.state.promo_code
      )
      .then((res) => {
        this.setState({
          isLoading: false,
          isConfirmModal: true,
          isSuccess: true,
          transferMessage: res?.msg,
        })
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        })
        if (err.status == 514) {
          this.props.expiredToken_logout()
        } else {
          this.setState({
            isLoading: false,
            isConfirmModal: true,
            isSuccess: false,
            transferMessage: err?.message,
          })

          MyToast.popToast(err?.message)
        }
      })
  }

  render() {
    return (
      <View style={styles.container}>
        <UploadTopUpModal
          isShow={this.state.show_upload_modal}
          onPressCamera={() => this.onPress_Camera()}
          onPressLocal={() => this.onPress_local()}
          onBackPress={() => this.onCancel_modal()}
        />

        <ConfirmUploadTopUpModal
          isShow={this.state.show_confirm_upload}
          onBackPress={() => this.onCancel_confirm_modal()}
          imgUrl={this.state.imgUrl}
          onConfirmPress={() => this.onComformUpload()}
        />
        {this.state.isConfirmModal && (
          <SuccessModal
            isShow={this.state.isConfirmModal}
            isSuccess={this.state.isSuccess}
            message={this.state.transferMessage}
            onConfirmPress={() => {
              if (this.state.isSuccess) {
                this.props.route.params?.onGoBack()
                this.props.navigation?.goBack()
              }
              this.setState({ isConfirmModal: false })
            }}
          />
        )}

        <MainFlowHeaderContainerNoFeedback
          title={strings.t('qr_topup_reciept')}
          onBackPress={() => this.onBackPressed()}
        >
          <ScrollView contentContainerStyle={{ flex: 1 }}>
            <View style={styles.innerContainer}>
              {this.state.isLoading ? (
                <ActivityIndicator
                  size="small"
                  color={MainColor}
                  style={{ marginTop: HeightDimen(20) }}
                />
              ) : (
                <>
                  <View style={styles.qrContainer}>
                    <Image
                      source={{ uri: this.state.qrUrl }}
                      style={styles.qrImage}
                      resizeMode="contain"
                    />
                  </View>

                  <RecoverTextInput
                    title={strings.t('fullname')}
                    placeholder={strings.t('fullname')}
                    onChangeText={this.handle_Name}
                    value={this.state.fullName}
                    top={true}
                  />
                  <View style={{ height: HeightDimen(5) }}></View>
                  <DisplayInput
                    title={strings.t('account_name')}
                    value={this.state.companyName}
                  />
                  {/* <RecoverTextInput
                    title={strings.t('date}
                    isEdit
                    isNumber={true}
                    onChangeText={this.handle_amount}
                    value={this.state.dateTime}
                  /> */}
                  {/* <View style={{ height: HeightDimen(5) }}></View> */}
                  <RecoverTextInput
                    title={`${strings.t('amount_RM')} (${MyData.country_code})`}
                    placeholder={'0.00'}
                    isNumber={true}
                    onChangeText={this.handle_amount}
                    value={this.state.amount}
                    // isMax
                    bottom={true}
                    onMaxPress={() => {
                      this.setState({
                        amount: `${4999}`,
                      })
                    }}
                  />
                  <View
                    style={{
                      marginVertical: HeightDimen(10),
                    }}
                  >
                    <Text style={styles.label}>
                      <Text style={{ fontWeight: 'bold' }}>
                        {strings.t('minimum_top_up')}
                      </Text>
                      {`${MyData.country_code} ${
                        MyData.country_code == 'MYR' ? '20' : '50'
                      }`}
                    </Text>
                    {/* <Text style={styles.label}>
                      <Text style={{ fontWeight: "bold" }}>
                        {strings.t('maximum_top_up}
                      </Text>
                      {"MYR 4999"}
                    </Text> */}
                  </View>
                  <View style={{ flex: 1, justifyContent: 'flex-end' }}>
                    <TextButton
                      label={strings.t('upload_receipt')}
                      size={5}
                      onPress={() => {
                        this.onNextPressed()
                      }}
                      isLoading={this.state.isLoading}
                    />
                  </View>
                </>
              )}
            </View>
          </ScrollView>
        </MainFlowHeaderContainerNoFeedback>
      </View>
    )
  }
}
const styles = {
  container: {
    flex: 1,
    backgroundColor: 'white',
  },
  innerContainer: {
    flex: 1,
    paddingHorizontal: HeightDimen(20),
    marginBottom: HeightDimen(20),
    paddingTop: HeightDimen(10),
  },
  label: {
    fontSize: fontDimen(13),
    color: MainColor,
  },
  qrContainer: {
    alignItems: 'center',
    marginVertical: HeightDimen(10),
  },
  qrImage: {
    width: 'auto',
    height: HeightDimen(200),
    aspectRatio: 1 / 1,
  },
}
export default QRTopUpScreen
