class OTPRouteData {

    isFlow_Login = false;
    isFlow_SignUp = false;

    set_flow_login = () => {
        this.isFlow_Login = true;
        this.isFlow_SignUp = false;
    }

    set_flow_signup = () => {
        this.isFlow_Login = false;
        this.isFlow_SignUp = true;
    }

    set_clear_all = () => {
        this.isFlow_Login = false;
        this.isFlow_SignUp = false;
    }

}

export default new OTPRouteData();