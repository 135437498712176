import React, { Component, useState, useEffect } from 'react'
import {
  Text,
  StyleSheet,
  FlatList,
  View,
  Image,
  Modal,
  TouchableOpacity,
  TouchableWithoutFeedback,
  ScrollView,
} from 'react-native'

import MyData from '../../Data/MyData'

import { SecondaryColor } from '../../UI/Color'
import { fontDimen, HeightDimen, WidthDimen } from '../../UI/Dimensions'

const ModalGamesList = (props) => {
  const [gamesList, setGamesList] = useState(null)
  const [isVisible, setIsVisible] = useState(props.isVisible)

  const sampleGames = [
    {
      desc: 'AAAA',
    },
    {
      desc: 'BBBB',
    },
    {
      desc: 'CCCC',
    },
    {
      desc: 'DDDD',
    },
  ]

  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={props?.isVisible}
      statusBarTranslucent
      onRequestClose={() => {
        props?.onCloseModal()
      }}
    >
      <TouchableWithoutFeedback
        onPress={() => props?.onCloseModal()}
        style={{ flex: 1 }}
      >
        <View
          style={{
            flex: 1,
            justifyContent: 'flex-end',
            backgroundColor: 'rgba(0,0,0,0.5)',
          }}
        >
          <View
            style={{
              backgroundColor: 'white',
              borderTopLeftRadius: 20,
              borderTopRightRadius: 20,
            }}
          >
            <Text
              style={{
                textAlign: 'center',
                fontSize: fontDimen(18),
                marginVertical: HeightDimen(10),
                fontWeight: 'bold',
                color: 'black',
              }}
            >
              Product List
            </Text>
            <View
              style={{
                paddingVertical: HeightDimen(20),
                paddingHorizontal: HeightDimen(10),
              }}
            >
              <FlatList
                data={props?.data || MyData.games_list}
                keyExtractor={(item) => item.product_id}
                style={{ maxHeight: 300 }}
                renderItem={({ item }) => {
                  return (
                    <TouchableOpacity
                      onPress={() => props?.onPress(item, props?.coupon_code)}
                    >
                      <Text style={{ marginVertical: HeightDimen(12) }}>
                        {item?.product_name || item.product_desc}
                      </Text>
                    </TouchableOpacity>
                  )
                }}
              />
            </View>
          </View>
        </View>
      </TouchableWithoutFeedback>
    </Modal>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: HeightDimen(8),
  },
  couponContainer: {
    minHeight: HeightDimen(80),
    padding: HeightDimen(8),
    justifyContent: 'space-between',
    flexDirection: 'row',
    borderWidth: 1,
    borderColor: SecondaryColor,
    marginBottom: HeightDimen(12),
  },
  itemDetailsContainer: {
    padding: HeightDimen(20),
    flexGrow: 1,
    // alignItems: 'center'
  },
  titleText: {
    fontWeight: 'bold',
    color: 'white',
    fontSize: fontDimen(20),
  },
  subTitleText: {
    color: 'white',
    fontSize: fontDimen(16),
  },
  textLabel: {
    color: 'white',
    fontWeight: '500',
    marginBottom: 8,
  },
  textValue: {
    color: 'white',
    fontWeight: 'bold',
  },
  activeTab: {
    backgroundColor: `rgb(122, 122, 122)`,
    height: HeightDimen(30),
    width: WidthDimen(80),
    alignItems: 'center',
    justifyContent: 'center',
  },
  inActiveTab: {
    backgroundColor: `rgb(79, 79, 79)`,
    height: HeightDimen(30),
    width: WidthDimen(80),
    alignItems: 'center',
    justifyContent: 'center',
  },
})

export default ModalGamesList
