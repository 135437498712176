import React, { Component } from 'react'
import { View, TouchableOpacity, Image, ImageBackground } from 'react-native'
import { GILROY_BOLD, MainColor, PrimaryColor } from '../../../UI/Color'
import { fontDimen, HeightDimen } from '../../../UI/Dimensions'
import Text from '../../Text/MyText'

const ImageTextCard = (props) => {
  const { src, name, onPress, disabled, isLoading } = props

  return (
    <View style={styles.container}>
      <ImageBackground
        source={require('../../../Resources/Images/Profile/icon_bg.png')}
        resizeMode="contain"
        style={styles.backgroundImage}
      >
        <TouchableOpacity
          style={styles.innerContainer}
          disabled={isLoading ? isLoading : disabled}
          onPress={onPress}
        >
          <Image source={src} style={styles.image} />
        </TouchableOpacity>
      </ImageBackground>
      {name && <Text style={styles.label}>{name}</Text>}
      {disabled && <View style={styles.disabled} />}
    </View>
  )
}
const styles = {
  backgroundImage: {
    height: HeightDimen(70),
    //width: "100%",
    //aspectRatio: 1 / 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    width: '24%',
  },
  innerContainer: {
    height: '100%',
    borderRadius: HeightDimen(10),
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  image: {
    width: HeightDimen(50),
    height: HeightDimen(50),
    resizeMode: 'contain',
  },
  label: {
    color: MainColor,
    fontSize: fontDimen(10),
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: HeightDimen(3),
    fontFamily: GILROY_BOLD,
    paddingBottom: HeightDimen(3),
    paddingHorizontal: HeightDimen(3),
  },
  disabled: {
    width: '100%',
    height: '100%',
    backgroundColor: 'black',
    position: 'absolute',
    opacity: 0.3,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: HeightDimen(10),
  },
}
export { ImageTextCard }
