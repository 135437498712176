import React, { Component } from 'react'
import { View, Image } from 'react-native'
import Text from '../Text/MyText'
import {
  SecondaryColor,
  FontMainColor,
  FontSecondaryColor,
} from '../../UI/Color'
import { HeightDimen, fontDimen } from '../../UI/Dimensions'

const OrderHistoryCard = (props) => {
  const { title, message, price, status, source } = props

  return (
    <View
      style={{
        width: '100%',
        height: HeightDimen(70),
        backgroundColor: '#fef6eb',
        borderRadius: HeightDimen(10),
        marginBottom: HeightDimen(10),
        flexDirection: 'row',
        padding: HeightDimen(10),
      }}
    >
      <Image
        source={
          source
            ? source
            : require('../../Resources/Images/Wallet/icon_interest.png')
        }
        style={{
          width: 'auto',
          height: 'auto',
          aspectRatio: 1 / 1,
          resizeMode: 'contain',
          margin: -HeightDimen(1),
          borderRadius: HeightDimen(2),
        }}
      />
      <View
        style={{
          flex: 1,
          justifyContent: 'center',
          marginRight: HeightDimen(10),
          marginLeft: HeightDimen(10),
        }}
      >
        <Text
          numberOfLines={1}
          style={{
            fontSize: fontDimen(14),
            color: FontMainColor,
            marginBottom: HeightDimen(5),
          }}
        >
          {title}
        </Text>
        <Text
          style={{
            fontSize: fontDimen(10),
            color: FontSecondaryColor,
          }}
        >
          {message}
        </Text>
      </View>
      <View
        style={{
          justifyContent: 'center',
          alignItems: 'flex-end',
        }}
      >
        <Text
          style={{
            fontSize: fontDimen(15),
            color: SecondaryColor,
            marginBottom: HeightDimen(5),
          }}
        >
          {price}
        </Text>

        <Text
          style={{
            fontSize: fontDimen(13),
            color: FontMainColor,
          }}
        >
          {status}
        </Text>
      </View>
    </View>
  )
}

export { OrderHistoryCard }
