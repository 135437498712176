import React, { Component } from 'react'
import {
  View,
  FlatList,
  TouchableOpacity,
  ActivityIndicator,
} from 'react-native'

import Text from '../../Component/Text/MyText'

import { FontMainColor } from '../../UI/Color'
import { HeightDimen, fontDimen } from '../../UI/Dimensions'

import { MainFlowHeaderContainerNoFeedback } from '../../Component/Header/MainFlowHeader'
import { DateInput } from '../../Component/Input/DateInput'
import { TextButton } from '../../Component/Button/TextButton'
import MyData from '../../Data/MyData'
import { OrderHistoryCard } from '../../Component/Card/OrderHistoryCard'
import MyToast from '../../Component/Toast/MyToast'
import { strings } from '../../Language/Language'
import coinCaller from '../../API/CoinCaller'
import { WebDatePicker } from '../../Component/Modal/WebDatePicker'

class CoinHistoryScreen extends Component {
  state = {
    fromDate: '',
    toDate: '',
    isLoading: false,
    coinHistoryList: [],
  }

  async componentDidMount() {
    var date = new Date().getDate()
    var month = new Date().getMonth() + 1
    var year = new Date().getFullYear()

    var final_date =
      year +
      '-' +
      (month < 10 ? '0' : '') +
      month +
      '-' +
      (date < 10 ? '0' : '') +
      date
    this.setState({
      fromDate: final_date,
      toDate: final_date,
    })
  }

  getHistory = async () => {
    this.setState({ isLoading: true })
    await coinCaller
      .getCoinHistory(MyData.authToken, this.state.fromDate, this.state.toDate)
      .then((res) => {
        if (res.response.transactions.length == 0) {
          this.setState({
            isLoading: false,
          })
          MyToast.popToast(strings.t('no_transaction_for_dates'))
        } else {
          this.setState({
            isLoading: false,
            coinHistoryList: res.response.transactions,
          })
        }
        //console.log(res.response.transactions);
      })
      .catch((err) => {
        this.setState({ isLoading: false })
        if (err.response.status == '514') {
          this.props.expiredToken_logout()
        } else {
          MyToast.popToast(err.response.data.msg)
        }
      })
  }

  onBackPressed = () => {
    this.props.navigation.goBack()
  }

  set_fromDate = (data) => {
    this.setState({ fromDate: data })
  }

  set_toDate = (data) => {
    this.setState({ toDate: data })
  }

  render() {
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: 'white',
        }}
      >
        <MainFlowHeaderContainerNoFeedback
          title={strings.t('coin_transaction')}
          onBackPress={() => this.onBackPressed()}
        >
          <View
            style={{
              flex: 1,
              paddingLeft: HeightDimen(30),
              paddingRight: HeightDimen(30),
            }}
          >
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <View
                style={{
                  flex: 1,
                }}
              >
                <WebDatePicker
                  label={strings.t('from_date')}
                  data={this.state.fromDate}
                  setDate={this.set_fromDate}
                />
              </View>
              <Text
                style={{
                  fontSize: fontDimen(15),
                  color: FontMainColor,
                  fontWeight: 'bold',
                  marginLeft: HeightDimen(10),
                  marginRight: HeightDimen(10),
                }}
              >
                {strings.t('to')}
              </Text>
              <View
                style={{
                  flex: 1,
                }}
              >
                <WebDatePicker
                  label={strings.t('to_date')}
                  data={this.state.toDate}
                  setDate={this.set_toDate}
                />
              </View>
              <View
                style={{
                  marginLeft: HeightDimen(10),
                }}
              >
                <TextButton
                  size={1}
                  label={strings.t('go')}
                  onPress={() => this.getHistory()}
                  isLoading={this.state.isLoading}
                />
              </View>
            </View>
            <View
              style={{
                marginTop: HeightDimen(20),
                marginLeft: -HeightDimen(10),
                marginRight: -HeightDimen(10),
                flex: 1,
              }}
            >
              <FlatList
                numColumns={1}
                data={this.state.coinHistoryList}
                renderItem={({ item, index }) => (
                  <OrderHistoryCard
                    title={item.transaction_remark}
                    message={item.transaction_created_datetime}
                    price={item.transaction_amount}
                    status={item.transaction_status}
                  />
                )}
                keyExtractor={(item) => String(item.transaction_id)}
              />
            </View>
          </View>
        </MainFlowHeaderContainerNoFeedback>
      </View>
    )
  }
}

export default CoinHistoryScreen
