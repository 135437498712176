import React, { Component } from 'react'
import {
  View,
  TouchableOpacity,
  StatusBar,
  Modal,
  StyleSheet,
  Image,
  ScrollView,
} from 'react-native'
import Text from '../../Component/Text/MyText'
import { FontSecondaryColor, SecondaryColor } from '../../UI/Color'
import { WidthDimen, HeightDimen, fontDimen } from '../../UI/Dimensions'
import { LoginFlowHeaderContainer } from '../../Component/Header/LoginFlowHeader'
import { NormalTextInput } from '../../Component/Input/NormalTextInput'
import PasswordInput from '../../Component/Input/PasswordInput'
import { TextButton } from '../../Component/Button/TextButton'
import APICaller from '../../API/APICaller'
import Mydata from '../../Data/MyData'
import OTPRouteData from '../../Data/OTPRouteData'
import MyToast from '../../Component/Toast/MyToast'
import { strings } from '../../Language/Language'
import storageData from '../../Data/StorageData'
import { TextSelection } from '../../Component/Selection/TextSelection'
import { getSelectedLanguage } from '../../Utils/Common'

class LoginScreen extends Component {
  state = {
    username: '',
    password: '',
    isLoading: false,

    isLanguageModal: false,
    selection: 1,
    selectedLangImage: '',
  }

  componentDidMount() {
    //logScreenViewAnalytics(screenNames.Login);
    debugger
    if (storageData.selected_language == 'en') {
      this.setState({
        selection: 1,
        selectedLangImage: require('../../Resources/Images/US.png'),
      })
    } else if (storageData.selected_language == 'ch') {
      this.setState({
        selection: 2,
        selectedLangImage: require('../../Resources/Images/china.png'),
      })
    } else if (storageData.selected_language == 'bm') {
      this.setState({
        selection: 3,
        selectedLangImage: require('../../Resources/Images/malaysia.png'),
      })
    } else if (storageData.selected_language == 'th') {
      this.setState({
        selection: 4,
        selectedLangImage: require('../../Resources/Images/thailand.png'),
      })
    } else if (storageData.selected_language == 'vt') {
      this.setState({
        selection: 5,
        selectedLangImage: require('../../Resources/Images/vietnam.png'),
      })
    } else if (storageData.selected_language == 'in') {
      this.setState({
        selection: 6,
        selectedLangImage: require('../../Resources/Images/indonesia.png'),
      })
    } else if (storageData.selected_language == 'my') {
      this.setState({
        selection: 7,
        selectedLangImage: require('../../Resources/Images/myanmar.png'),
      })
    } else if (storageData.selected_language == 'kh') {
      this.setState({
        selection: 8,
        selectedLangImage: require('../../Resources/Images/cambodia.png'),
      })
    }
    this.setState({
      username: storageData.saved_userName,
      password: storageData.saved_password,
    })

    //console.log(DeviceInfo.getUniqueId())
  }

  onForgetPassword = () => {
    //logMessageAnalytics(AnalyticEvents.LoginUIDPasswordClick);
    this.props.navigation.navigate('ForgotUIDPassword')
  }

  onSignUp = () => {
    // logMessageAnalytics(AnalyticEvents.LoginQRSignUpClick);
    this.props.navigation.navigate('SignUp')
    //this.props.navigation.navigate("QRCode");
  }
  change_selection = (id) => {
    if (this.state.selection != id) {
      this.setState({ selection: id })
      if (id == 1) {
        storageData.set_language('en')

        this.setState({
          selectedLangImage: require('../../Resources/Images/US.png'),
        })
      } else if (id == 2) {
        storageData.set_language('ch')

        this.setState({
          selectedLangImage: require('../../Resources/Images/china.png'),
        })
      } else if (id == 3) {
        storageData.set_language('bm')

        this.setState({
          selectedLangImage: require('../../Resources/Images/malaysia.png'),
        })
      } else if (id == 4) {
        storageData.set_language('th')

        this.setState({
          selectedLangImage: require('../../Resources/Images/thailand.png'),
        })
      } else if (id == 5) {
        storageData.set_language('vt')

        this.setState({
          selectedLangImage: require('../../Resources/Images/vietnam.png'),
        })
      } else if (id == 6) {
        storageData.set_language('id')

        this.setState({
          selectedLangImage: require('../../Resources/Images/indonesia.png'),
        })
      } else if (id == 7) {
        storageData.set_language('my')

        this.setState({
          selectedLangImage: require('../../Resources/Images/myanmar.png'),
        })
      } else if (id == 8) {
        storageData.set_language('kh')

        this.setState({
          selectedLangImage: require('../../Resources/Images/cambodia.png'),
        })
      }

      this.setState({})
    }
    this.setState({ isLanguageModal: false })
  }

  renderLanguageModal = () => {
    return (
      <Modal
        animationType="slide"
        transparent={true}
        visible={this.state.isLanguageModal}
        statusBarTranslucent
        onRequestClose={() => {
          this.setState({ isLanguageModal: false })
        }}
      >
        <View
          style={{
            flex: 1,
            justifyContent: 'flex-end',
            backgroundColor: 'rgba(0,0,0,0.5)',
          }}
        >
          <View
            style={{
              backgroundColor: 'white',
              borderTopLeftRadius: 20,
              borderTopRightRadius: 20,
            }}
          >
            <Text
              style={{
                textAlign: 'center',
                fontSize: fontDimen(18),
                marginVertical: HeightDimen(10),
                fontWeight: 'bold',
              }}
            >
              Language
            </Text>
            <View
              style={{
                paddingVertical: HeightDimen(20),
                paddingHorizontal: HeightDimen(10),
              }}
            >
              <TextSelection
                label={strings.t('eng_text')}
                id={1}
                current_selection={this.state.selection}
                onPress={() => this.change_selection(1)}
              />
              <View style={{ height: HeightDimen(3) }}></View>
              <TextSelection
                label={strings.t('ch_text')}
                id={2}
                current_selection={this.state.selection}
                onPress={() => this.change_selection(2)}
              />
              <View style={{ height: HeightDimen(3) }}></View>
              <TextSelection
                label={strings.t('bahasa_melayu_text')}
                id={3}
                current_selection={this.state.selection}
                onPress={() => this.change_selection(3)}
              />
              <View style={{ height: HeightDimen(3) }}></View>
              <TextSelection
                label={strings.t('thai_text')}
                id={4}
                current_selection={this.state.selection}
                onPress={() => this.change_selection(4)}
              />
              <View style={{ height: HeightDimen(3) }}></View>
              <TextSelection
                label={strings.t('vt_text')}
                id={5}
                current_selection={this.state.selection}
                onPress={() => this.change_selection(5)}
              />
              <View style={{ height: HeightDimen(3) }}></View>
              <TextSelection
                label={strings.t('indonsia_text')}
                id={6}
                current_selection={this.state.selection}
                onPress={() => this.change_selection(6)}
              />
              <View style={{ height: HeightDimen(3) }}></View>
              <TextSelection
                label={strings.t('myanmar_text')}
                id={7}
                current_selection={this.state.selection}
                onPress={() => this.change_selection(7)}
              />
              <View style={{ height: HeightDimen(3) }}></View>
              <TextSelection
                label={strings.t('cambodia_text')}
                id={7}
                current_selection={this.state.selection}
                onPress={() => this.change_selection(8)}
              />
            </View>
          </View>
        </View>
      </Modal>
    )
  }

  onLogin = async () => {
    const { username, password } = this.state
    if (username != '' && password != '') {
      this.setState({ isLoading: true })
      await APICaller.login(username, password)
        .then(async (res) => {
          // logMessageAnalytics(AnalyticEvents.LoginSuccess, {
          //   Username: username,
          // })
          // await this.props.isReady_PushNotification()
          this.setState({ isLoading: false })
          storageData.set_username_add_password(
            username,
            password,
            res.response.authSession,
            res.response.user.type
          )
          Mydata.popupImage = res?.response?.popUpImage || []
          Mydata.setUp(res)
          //  MyData.setS3CountryURL();
          await storageData.store_multi_account_data(
            res.response.user.member_username,
            password,
            res.response.user.member_avatar_url,
            res.response.user.member_login_token
          )
          // if (res.response.user.wallet_pin_status == 'false') {
          //   if (res.response.user.member_directline === '1')
          //     this.props.isLogin_Ready()
          //   else this.props.isLogin_Ready_set_pin()
          // } else {
          //   Mydata.setUserPin(res.response.user.wallet_pin)
          //   this.props.isLogin_Ready()
          // }
          this.props.isLogin_Ready()
          // console.log(res.response.user.member_unique_key);
        })
        .catch((err) => {
          // logEventWithErrorMsg(
          //   AnalyticEvents.LoginFailure,
          //   this.state.username,
          //   err?.response?.data?.msg
          // )
          this.setState({ isLoading: false })
          if (err?.response?.status == 302) {
            //console.log(err.response.data.response.userPhone)
            OTPRouteData.set_flow_login()
            Mydata.userToken = err.response?.data?.response?.userToken
            Mydata.authToken = err?.response?.data?.response?.authToken
            this.props.navigation.navigate('OTP', {
              phoneNumber: err?.response?.data?.response?.userPhone,
              code: err?.response?.data?.response?.countryCode,
            })
          } else if (err?.response?.status == 303) {
            Mydata.userToken = err?.response?.data?.response?.userToken
            this.props.navigation.navigate('Verification', {
              phoneNumber: err.response?.data?.response?.userPhone,
              username: this.state.username,
              password: this.state.password,
            })
          } else if (err?.response?.status == 300) {
            Mydata.maintainance_json = err?.response?.data?.response
            this.props.navigation.navigate('Maintenance')
          } else if (err.response.status == 304) {
            this.props.navigation.navigate('GoogleAuthVerification', {
              username: this.state.username,
              password: this.state.password,
            })
          } else {
            MyToast.popToast(err?.response?.data?.msg)
          }
        })
    }
    // this.props.isLogin_Ready();
  }

  handle_username = (username_txt) => {
    this.setState({ username: username_txt })
  }

  handle_password = (password_txt) => {
    this.setState({ password: password_txt })
  }

  render() {
    return (
      <View
        style={{
          flex: 1,
        }}
      >
        <StatusBar translucent backgroundColor="transparent" />

        <LoginFlowHeaderContainer
          onBackPress={() => this.onLogin()}
          mainText={strings.t('login_text')}
          subText={strings.t('welcom_text')}
          hideBack={true}
        >
          <ScrollView
            style={{
              flex: 1,
              paddingLeft: HeightDimen(30),
              paddingRight: HeightDimen(30),
              paddingBottom: HeightDimen(30),
            }}
            contentContainerStyle={{ flexGrow: 1 }}
          >
            <View
              style={{
                flex: 1,
                justifyContent: 'center',
              }}
            >
              <NormalTextInput
                placeholder={strings.t('username_text')}
                marginBottom={HeightDimen(20)}
                onChangeText={this.handle_username}
                type={'username'}
                value={this.state.username}
              />
              <PasswordInput
                placeholder={strings.t('password_text')}
                marginBottom={HeightDimen(20)}
                onChangeText={this.handle_password}
                value={this.state.password}
              />

              <View style={{ flexDirection: 'row', width: '100%' }}>
                <TouchableOpacity
                  onPress={() => this.onForgetPassword()}
                  disabled={this.state.isLoading}
                >
                  <Text
                    style={{
                      fontSize: fontDimen(15),
                      color: FontSecondaryColor,
                    }}
                  >
                    {strings.t('forgot_id_password')} |
                  </Text>
                </TouchableOpacity>

                <TouchableOpacity
                  onPress={() => this.props.navigation.navigate('ContactUs')}
                  disabled={this.state.isLoading}
                  style={{
                    flexDirection: 'row',
                    flex: 1,
                    marginLeft: 5,
                  }}
                >
                  <Image
                    source={require('../../Resources/Images/support.png')}
                    style={{
                      height: 25,
                      resizeMode: 'contain',
                      width: 30,
                      tintColor: SecondaryColor,
                    }}
                  />
                  <Text
                    style={{
                      fontSize: fontDimen(15),
                      color: FontSecondaryColor,
                      width: '90%',
                      marginLeft: 5,
                    }}
                  >
                    {strings.t('contactUs')}
                  </Text>
                </TouchableOpacity>
              </View>

              <View
                style={{
                  flexDirection: 'row',
                  marginTop: HeightDimen(40),
                  alignItems: 'center',
                }}
              >
                <TextButton
                  label={strings.t('login_text')}
                  size={2.5}
                  onPress={() => this.onLogin()}
                  isLoading={this.state.isLoading}
                />
                <TouchableOpacity
                  onPress={() => this.onSignUp()}
                  style={{
                    marginLeft: WidthDimen(30),
                  }}
                  disabled={this.state.isLoading}
                >
                  <Text
                    style={{
                      fontSize: fontDimen(15),
                      color: FontSecondaryColor,
                    }}
                  >
                    {strings.t('signUp_text')}
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
            <View
              style={{
                height: HeightDimen(100),

                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  // logMessageAnalytics(AnalyticEvents.LoginLanguageChangeClick)
                  this.setState({ isLanguageModal: true })
                }}
              >
                {this.state.selectedLangImage ? (
                  <Image
                    source={this.state.selectedLangImage}
                    style={{
                      aspectRatio: 1 / 1,
                      height: HeightDimen(50),
                      resizeMode: 'contain',
                    }}
                  />
                ) : (
                  <></>
                )}
              </TouchableOpacity>
            </View>
          </ScrollView>
        </LoginFlowHeaderContainer>
        {this.renderLanguageModal()}
      </View>
    )
  }
}
const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 22,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalView: {
    margin: 20,
    backgroundColor: 'white',
    borderRadius: 20,
    padding: 10,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    flex: 0.9,
    width: '95%',
  },
})
export default LoginScreen
