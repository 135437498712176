import React, { Component } from 'react'
import {
  TouchableOpacity,
  Image,
  FlatList,
  ActivityIndicator,
  Text,
} from 'react-native'

import { MainColor, SecondaryColor } from '../../UI/Color'
import { WidthDimen, HeightDimen, fontDimen } from '../../UI/Dimensions'

const PickTopUpModal = (props) => {
  const onPressSelect = (index) => {
    props.onPress(index)
  }
  const { list, isLoading, selectedBank } = props
  return (
    <>
      {isLoading && <ActivityIndicator size="small" color={MainColor} />}
      <FlatList
        data={list}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
        horizontal={true}
        style={{
          flexGrow: 0,
          marginVertical: HeightDimen(7),
        }}
        renderItem={({ item, index }) => (
          <TouchableOpacity
            style={{
              ...styles.bankContainer,
              backgroundColor:
                item.topup_bank_name == selectedBank
                  ? SecondaryColor
                  : '#ededed',
            }}
            onPress={() => {
              onPressSelect(index)
            }}
          >
            <Image
              source={{ uri: item.topup_bank_icon_url }}
              style={{
                ...styles.bankImage,
              }}
            />
            <Text
              numberOfLines={2}
              style={{
                ...styles.Banklabel,

                color:
                  item.topup_bank_name == selectedBank ? 'white' : MainColor,
              }}
            >
              {item.topup_bank_name}
            </Text>
          </TouchableOpacity>
        )}
        keyExtractor={(item) => String(item.topup_bank_name)}
      />
    </>
  )
}

const styles = {
  bankContainer: {
    height: HeightDimen(85),
    padding: HeightDimen(5),
    borderRadius: HeightDimen(10),
    alignItems: 'center',
    justifyContent: 'center',
    width: HeightDimen(100),
    backgroundColor: SecondaryColor,
    marginRight: HeightDimen(7),
    borderWidth: HeightDimen(2),
    borderColor: SecondaryColor,
  },
  bankImage: {
    width: 'auto',
    height: HeightDimen(40),
    aspectRatio: 1.5 / 1,
    resizeMode: 'contain',
  },
  Banklabel: {
    color: MainColor,
    fontSize: fontDimen(10),
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: HeightDimen(3),
  },
}
export { PickTopUpModal }
