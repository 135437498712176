import React, { Component } from "react";
import { View, TouchableOpacity, Image, Platform } from "react-native";
import {
  FontMainColor,
  FontSecondaryColor,
  SecondaryColor,
} from "../../../UI/Color";
import { fontDimen, HeightDimen, WidthDimen } from "../../../UI/Dimensions";
import Text from "../../Text/MyText";

const ProfileCard = (props) => {
  const {
    top,
    bottom,
    src,
    label,
    hideShowNext,
    onPress,
    wantGray,
    disabled,
    coupon,
    wantSecondaryLabel,
    customLabelStyle,
    customContainerStyle,
  } = props;

  return (
    <TouchableOpacity
      onPress={onPress}
      style={styles.container}
      disabled={disabled}
    >
      <View
        style={[
          {
            ...styles.imageContainer,
            backgroundColor: wantGray ? "gray" : SecondaryColor,
          },
          customContainerStyle,
        ]}
      >
        <Image
          source={src}
          style={[
            styles.image,
            { tintColor: customLabelStyle ? customLabelStyle.color : "white" },
          ]}
        />
      </View>
      <View style={styles.labelContainer}>
        <Text
          style={[
            {
              ...styles.label,
              color: wantSecondaryLabel ? SecondaryColor : FontSecondaryColor,
            },
            customLabelStyle,
          ]}
        >
          {label}
        </Text>
      </View>
      {/* {hideShowNext ? (
        <></>
      ) : (
        <Image
          source={require("../../../Resources/Images/icon_nextP.png")}
          style={styles.arrowImage}
        />
      )} */}
      {/* {coupon && <Image
        source={require("../../../Resources/Images/check.png")}
        style={styles.image}
      />} */}
    </TouchableOpacity>
  );
};
const styles = {
  container: {
    width: "100%",
    height: HeightDimen(50),
    flexDirection: "row",
    alignItems: "center",
  },
  imageContainer: {
    height: HeightDimen(45),
    borderRadius: HeightDimen(5),
    alignItems: "center",
    justifyContent: "center",
    marginTop: HeightDimen(8),
    paddingHorizontal: HeightDimen(10),
  },
  image: {
    width: "auto",
    height: HeightDimen(25),
    aspectRatio: 1 / 1,
    resizeMode: "contain",
    tintColor: "green",
  },
  labelContainer: {
    flex: 1,
    marginLeft: HeightDimen(15),
    marginRight: HeightDimen(15),
  },
  label: {
    fontSize: fontDimen(15),
    color: FontMainColor,
    marginTop: HeightDimen(5),
    fontWeight: "bold",
  },
  arrowImage: {
    width: "auto",
    height: HeightDimen(20),
    aspectRatio: 16 / 27,
    resizeMode: "contain",
  },
};

export { ProfileCard };
