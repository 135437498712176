import React from "react";
import {
  Box,
  useDisclose,
  IconButton,
  Stagger,
  Center,
  NativeBaseProvider,
  Icon,
} from "native-base";
import { TouchableOpacity, Image } from "react-native";
import { MainColor, SecondaryColor } from "../../UI/Color";
import { HeightDimen, WidthDimen } from "../../UI/Dimensions";
import { useState } from "react";
import { useEffect } from "react";
///import { MaterialCommunityIcons } from "@expo/vector-icons"
export const StaggerIconButton = props => {
  const { isOpen, onToggle } = useDisclose();
  const [staggerList, setStaggerList] = useState([
    {
      image: require("../../Resources/Images/back-arrow.png"),
      onPress: () => props.onBackPress(),
    },
    {
      image: require("../../Resources/Images/close-cross.png"),
      onPress: () => props.onClosePress(),
    },
  ]);

  useEffect(() => {
    if (props?.list?.length > 0) setStaggerList(props.list);
  }, []);
  return (
    <Box
      style={{
        position: "absolute",
        bottom: HeightDimen(10),
        right: HeightDimen(10),
      }}>
      <Stagger
        visible={isOpen}
        initial={{
          opacity: 0,
          scale: 0,
          translateY: 34,
        }}
        animate={{
          translateY: 0,
          scale: 1,
          opacity: 1,
          transition: {
            type: "spring",
            mass: 0.8,
            stagger: {
              offset: 30,
              reverse: true,
            },
          },
        }}
        exit={{
          translateY: 34,
          scale: 0.5,
          opacity: 0,
          transition: {
            duration: 100,
            stagger: {
              offset: 30,
              reverse: true,
            },
          },
        }}>
        {staggerList.map((item, index) => (
          <TouchableOpacity
            key={index}
            style={styles.buttonContainer}
            onPress={() => {
              item.onPress();
              onToggle();
            }}>
            <Image source={item.image} style={styles.image} />
          </TouchableOpacity>
        ))}
      </Stagger>

      <TouchableOpacity
        onPress={onToggle}
        style={[
          {
            ...styles.buttonContainer,
            backgroundColor: SecondaryColor,
            height: 60,
            width: 60,
            borderRadius: 30,
          },
          { borderWidth: 2, borderColor: SecondaryColor, elevation: 5 },
        ]}>
        <Image
          source={
            isOpen
              ? require("../../Resources/Images/two-arrows-in-angle-pointing-down.png")
              : require("../../Resources/Images/up-arrows-angles-couple.png")
          }
          style={styles.image}
        />
      </TouchableOpacity>
    </Box>
  );
};
const styles = {
  buttonContainer: {
    height: 50,
    width: 50,
    borderRadius: 25,
    backgroundColor: MainColor,
    marginBottom: 5,
    justifyContent: "center",
  },
  image: {
    width: "auto",
    height: HeightDimen(25),
    resizeMode: "contain",
    tintColor: "white",
  },
};
