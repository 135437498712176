import React, { Component } from 'react'
import { View, TouchableOpacity, Image } from 'react-native'
import Text from '../Text/MyText'

import {
  MainColor,
  SecondaryColor,
  FontMainColor,
  FontSecondaryColor,
  FontThirdColor,
  PrimaryColor,
} from '../../UI/Color'
import {
  WidthDimen,
  HeightDimen,
  fontDimen,
  GetWidth,
  GetHeight,
} from '../../UI/Dimensions'

const TextSelection = (props) => {
  const { top, bottom, label, onPress, id, current_selection } = props

  return (
    <TouchableOpacity
      onPress={onPress}
      style={{
        width: '100%',
        height: HeightDimen(50),
        backgroundColor: PrimaryColor,
        borderTopRightRadius: top ? HeightDimen(10) : 0,
        borderTopLeftRadius: top ? HeightDimen(10) : 0,

        borderBottomRightRadius: bottom ? HeightDimen(10) : 0,
        borderBottomLeftRadius: bottom ? HeightDimen(10) : 0,

        flexDirection: 'row',
        padding: HeightDimen(15),
        alignItems: 'center',
      }}
    >
      <View
        style={{
          flex: 1,
          marginLeft: HeightDimen(15),
          marginRight: HeightDimen(15),
        }}
      >
        <Text
          style={{
            fontSize: fontDimen(15),
            color: FontMainColor,
          }}
        >
          {label}
        </Text>
      </View>
      {id == current_selection ? (
        <View
          style={{
            width: 'auto',
            height: HeightDimen(25),
            aspectRatio: 1 / 1,
            borderRadius: 9999,
            backgroundColor: SecondaryColor,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Image
            source={require('../../Resources/Images/icon_tick.png')}
            style={{
              width: 'auto',
              height: HeightDimen(10),
              aspectRatio: 1 / 1,
              tintColor: 'white',
            }}
          />
        </View>
      ) : (
        <View
          style={{
            width: 'auto',
            height: HeightDimen(25),
            aspectRatio: 1 / 1,
            borderRadius: 9999,
            borderWidth: HeightDimen(1),
          }}
        ></View>
      )}
      {/* {hideShowNext ? (
                <></>
            ) : (
                <Image
                    source={require('../../Resources/Images/icon_nextP.png')}
                    style={{
                        width: 'auto',
                        height: HeightDimen(20),
                        aspectRatio: 16 / 27,
                        resizeMode: 'contain'
                    }}
                />
            )} */}
    </TouchableOpacity>
  )
}

export { TextSelection }
