import React, { Component } from "react";
import {
  View,
  Image,
  TouchableOpacity,
  Keyboard,
  TouchableWithoutFeedback,
  Platform,
} from "react-native";
import Text from "../Text/MyText";

import {
  MainColor,
  SecondaryColor,
  FontMainColor,
  FontSecondaryColor,
  FontThirdColor,
} from "../../UI/Color";
import {
  WidthDimen,
  HeightDimen,
  fontDimen,
  GetWidth,
  GetHeight,
} from "../../UI/Dimensions";

const LaunchGameHeaderContainer = (props) => {
  const {
    onBackPress,
    title,
    toShow,
    toShowImage,
    toShowText,
    toShowPress,
    toShowImageUrl,
    children,
    landscape,
  } = props;

  return (
    <View
      style={{
        width: "100%",
        flex: 1,
        backgroundColor: MainColor,
      }}
    >
      <View
        style={{
          height: landscape
            ? Platform.OS === "ios"
              ? HeightDimen(15)
              : HeightDimen(15)
            : Platform.OS === "ios"
            ? HeightDimen(30)
            : HeightDimen(30),
        }}
      ></View>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <View
          style={{
            width: "100%",
            position: "absolute",
            alignItems: "center",
          }}
        >
          <Text
            style={{
              fontSize: fontDimen(13),
              color: FontThirdColor,
            }}
          >
            {title}
          </Text>
        </View>
        <View
          style={{
            height: HeightDimen(40),
            justifyContent: "center",
            paddingLeft: WidthDimen(20),
          }}
        >
          <TouchableOpacity
            style={{
              width: HeightDimen(50),
              height: "100%",
              justifyContent: "center",
            }}
            onPress={onBackPress}
          >
            <Image
              source={require("../../Resources/Images/icon_back.png")}
              style={{
                width: "auto",
                height: HeightDimen(10),
                aspectRatio: 19 / 13,
                resizeMode: "contain",
              }}
            />
          </TouchableOpacity>
        </View>
        {toShow ? (
          <View
            style={{
              flex: 1,
              alignItems: "flex-end",
            }}
          >
            <TouchableOpacity onPress={toShowPress}>
              <Text
                style={{
                  fontSize: fontDimen(13),
                  color: FontThirdColor,
                  marginRight: WidthDimen(20),
                }}
              >
                {toShowText}
              </Text>
            </TouchableOpacity>
          </View>
        ) : toShowImage ? (
          <View
            style={{
              flex: 1,
              alignItems: "flex-end",
            }}
          >
            <TouchableOpacity
              onPress={toShowPress}
              style={{
                borderWidth: HeightDimen(1),
                borderColor: "white",
                borderRadius: 9999,
                padding: HeightDimen(5),
                marginRight: landscape ? HeightDimen(50) : HeightDimen(20),
              }}
            >
              <Image
                source={toShowImageUrl}
                style={{
                  width: "auto",
                  height: HeightDimen(10),
                  aspectRatio: 1 / 1,
                  resizeMode: "contain",
                  tintColor: "white",
                }}
              />
            </TouchableOpacity>
          </View>
        ) : (
          <></>
        )}
      </View>

      <View
        style={{
          width: "100%",
          paddingTop: HeightDimen(30),
          backgroundColor: "white",
          borderTopLeftRadius: HeightDimen(20),
          borderTopRightRadius: HeightDimen(20),
          flex: 1,
        }}
      >
        {children}
      </View>
    </View>
  );
};

export {LaunchGameHeaderContainer};
