import axios from 'axios'
import { apiUrls } from './APIurl'
import storageData from '../Data/StorageData'
import MyData from '../Data/MyData'

class LeaderBoardCaller {
  getLeaderBoard = async (authToken) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.getLeaderboard}`,
          {
            data: {
              language: storageData.get_selected_language(),
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  getLeaderBoardRank = async (authToken, id) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.getLeaderboardRank}`,
          {
            data: {
              leaderboardId: id,
              language: storageData.get_selected_language(),
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  getXe88GameList = async (authToken) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.getXe88GameList}`,
          {
            data: {
              memberUniqueKey: MyData.userToken,
              amountTransfer: '0',
              gameType: 'all',
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  getPlayTechGameList = async (authToken) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.getPlayTechGameList}`,
          {
            data: {
              memberUniqueKey: MyData.userToken,
              amountTransfer: '0',
              gameType: 'all',
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  getVPowerGameList = async (authToken) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.getVPowerGameList}`,
          {
            data: {
              memberUniqueKey: MyData.userToken,
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  getJokerGameList = async (authToken, productId) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.getJokerGameList}`,
          {
            data: {
              memberUniqueKey: MyData.userToken,
              productId: productId,
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  launchXe88Game = async (authToken, id, productId) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.launchXe88Game}`,
          {
            data: {
              memberUniqueKey: MyData.userToken,
              gameId: id,
              productId: productId,
              originUrl: '',
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  launchPlayTechGame = async (authToken, id, productId) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.launchPlayTechGame}`,
          {
            data: {
              memberUniqueKey: MyData.userToken,
              gameId: id,
              productId: productId,
              originUrl: '',
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  launchVpowerGame = async (authToken, id) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.launchVpowerGame}`,
          {
            data: {
              memberUniqueKey: MyData.userToken,
              gameId: id,
              originUrl: '',
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  launchJokerGame = async (authToken, code, productId) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.launchJokerGame}`,
          {
            data: {
              memberUniqueKey: MyData.userToken,
              code: code,
              productId: productId,
              originUrl: '',
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  getKingMakerGameList = async (authToken, productId) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.getKingMakerGameList}`,
          {
            data: {
              productId: productId,
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  launchKingMakerGame = async (authToken, code, productId) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.launchKingMakerGame}`,
          {
            data: {
              gameCode: code,
              productId: productId,
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  getSpadeGameGameList = async (authToken, productId) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.getSpadeGameGameList}`,
          {
            data: {
              productId: productId,
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  launchSpadeGame = async (authToken, code, productId) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.launchSpadeGame}`,
          {
            data: {
              code: code,
              productId: productId,
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  getSexyPragmaticGamelist = async (authToken, productId) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.getSexyPragmaticGameList}`,
          {
            data: {
              productId: productId,
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  launchSexyPragmaticGame = async (authToken, code, category, productId) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.launchSexyPragmaticGame}`,
          {
            data: {
              gameCategory: category,
              gameCode: code,
              productId: productId,
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  getSexyRedTigerGameList = async (authToken, productId) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.getSexyTigerGameList}`,
          {
            data: {
              productId: productId,
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  launchSexyRedTigerGame = async (authToken, code, category, productId) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.launchSexyTigerGame}`,
          {
            data: {
              gameCategory: category,
              gameCode: code,
              productId: productId,
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  getSexyPGgameList = async (authToken, productId) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.getSexyPGGameList}`,
          {
            data: {
              productId: productId,
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  launchSexyPGGame = async (authToken, code, category, productId) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.launchSexyPGGame}`,
          {
            data: {
              gameCategory: category,
              gameCode: code,
              productId: productId,
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  getSexyJiliGameList = async (authToken, productId) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.getSexyJiliGameList}`,
          {
            data: {
              productId: productId,
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  launchSexyJiliGame = async (authToken, code, category, productId) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.launchSexyJiliGame}`,
          {
            data: {
              gameCategory: category,
              gameCode: code,
              productId: productId,
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  getPragmaticGameList = async (authToken) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.getPragmaticGameList}`,
          {
            data: {
              memberUniqueKey: MyData.userToken,
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  launchPragmaticGame = async (authToken, id, productId) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.launchPragmaticGame}`,
          {
            data: {
              memberUniqueKey: MyData.userToken,
              gameId: id,
              productId: productId,
              originUrl: '',
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  getPlaytechSlotGameList = async (authToken) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.getPlaytechSlotGameList}`,
          {
            data: {
              memberUniqueKey: MyData.userToken,
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  launchPlaytechSlotGame = async (authToken, id, productId) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.launchPlaytechSlotGame}`,
          {
            data: {
              memberUniqueKey: MyData.userToken,
              gameId: id,
              productId: productId,
              originUrl: '',
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  getFunHouseGameList = async (authToken, productId) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.getFunHouseGameList}`,
          {
            data: {
              memberUniqueKey: MyData.userToken,
              productId: productId,
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  launchFunHouseGame = async (authToken, id, productId) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.launchFunHouseGame}`,
          {
            data: {
              memberUniqueKey: MyData.userToken,
              gameId: id,
              productId: productId,
              originUrl: '',
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  get100PlusGameList = async (authToken, productId) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.get100PlusGameList}`,
          {
            data: {
              productId: productId,
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  launch100PlusGame = async (authToken, id, productId) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.launch100PlusGame}`,
          {
            data: {
              gameId: id,
              productId: productId,
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  getPGSGameList = async (authToken, productId) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.getPGSGameList}`,
          {
            data: {
              productId: productId,
              memberUniqueKey: MyData.userToken,
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  launchPGSGame = async (authToken, id, productId) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.launchPGSGame}`,
          {
            data: {
              gameId: id,
              productId: productId,
              memberUniqueKey: MyData.userToken,
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  getKingKongGameList = async (authToken) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.getKingKongGameList}`,
          {
            data: {
              memberUniqueKey: MyData.userToken,
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  launchKingKongGame = async (authToken, code) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.launchKingKongGame}`,
          {
            data: {
              gameCode: code,
              originUrl: '',
              memberUniqueKey: MyData.userToken,
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  getCrowdPlayGameList = async (authToken) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.getCrowdPlayGameList}`,
          {
            data: {
              memberUniqueKey: MyData.userToken,
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  launchCrowdPlayGame = async (authToken, id) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.launchCrowdPlayGame}`,
          {
            data: {
              gameId: id,
              originUrl: '',
              memberUniqueKey: MyData.userToken,
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  getNextSpinGameList = async (authToken) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.getNextSpinGameList}`,
          {
            data: {
              memberUniqueKey: MyData.userToken,
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  launchNextSpinGame = async (authToken, id) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.launchNextSpinGame}`,
          {
            data: {
              gameId: id,
              originUrl: '',
              memberUniqueKey: MyData.userToken,
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  getAESlotGameList = async (authToken) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.getAESlotGameList}`,
          {
            data: {
              memberUniqueKey: MyData.userToken,
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  launchAESlotGame = async (authToken, id) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.launchAESlotGame}`,
          {
            data: {
              gameId: id,
              originUrl: '',
              memberUniqueKey: MyData.userToken,
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  getBannerList = async (authToken) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.bannerList}`,
          {
            data: {
              country: MyData.country_id,
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  getJDBFishGameList = async (authToken, productId) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.getJDBFishGameList}`,
          {
            data: {
              memberUniqueKey: MyData.userToken,
              productId: productId,
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  launchJDBFishGame = async (authToken, code, productId) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.launchJDBFishGame}`,
          {
            data: {
              gameCode: code,
              memberUniqueKey: MyData.userToken,
              productId: productId,
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  getSpadeFishGameList = async (authToken, product_id) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.getSpadeFishGameList}`,
          {
            data: {
              productId: product_id,
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  launchSpadeFishGame = async (authToken, code, id) => {
    const data = {
      gameCode: code,
      productId: id,
    }
    console.log(data)
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.launchSpadeFishGame}`,
          {
            data: {
              gameCode: code,
              productId: id,
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  getYLFishGameList = async (authToken, productId) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.getYLFishGameList}`,
          {
            data: {
              memberUniqueKey: MyData.userToken,
              productId: productId,
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  launchYLFishGame = async (authToken, code, productId) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.launchYLFishGame}`,
          {
            data: {
              gameCode: code,
              memberUniqueKey: MyData.userToken,
              productId: productId,
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  closeGame = async (authToken, id) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.funchouseCloeGame}`,
          {
            data: {
              productId: id,
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  getAeSexyGameList = async (authToken, productId) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.getAeSexyGameList}`,
          {
            data: {
              memberUniqueKey: MyData.userToken,
              productId: productId,
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  launchAeSexyGame = async (authToken, code, productId, category) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.launchAeSexyGame}`,
          {
            data: {
              gameCode: code,
              memberUniqueKey: MyData.userToken,
              productId: productId,
              gameCategory: category,
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  getLKYSlotGameList = async (authToken, productId) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.getLKYSlotGameList}`,
          {
            data: {
              //memberUniqueKey: MyData.userToken,
              productId: productId,
              language: storageData.get_selected_language(),
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  launchLKYSlotGame = async (authToken, gameId, productId) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.launchLKYSlotGame}`,
          {
            data: {
              gameId: gameId,
              // memberUniqueKey: MyData.userToken,
              productId: productId,
              originUrl: '',
              language: storageData.get_selected_language(),
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  getLKYPointTransaction = async (authToken) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.lkpointTransaction}`,
          {
            data: {},
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}

const leaderBoardCaller = new LeaderBoardCaller()
export default leaderBoardCaller
