import React, { Component } from 'react'
import { View } from 'react-native'
import { HeightDimen } from '../../UI/Dimensions'
import { LoginFlowHeaderContainer } from '../../Component/Header/LoginFlowHeader'
import { TextButton } from '../../Component/Button/TextButton'
import { RecoverTextInput } from '../../Component/Input/RecoverTextInput'
import MyData from '../../Data/MyData'
import MyToast from '../../Component/Toast/MyToast'
import { strings } from '../../Language/Language'
import pinAPICaller from '../../API/PinAPICaller'
import storageData from '../../Data/StorageData'

class CreatePinVerificationScreen extends Component {
  state = {
    new_pin: '',
    confirm_pin: '',
    pageRedirect: this.props?.route?.params?.page || '',
    isLoading: false,
  }

  handle_new_pass = (value) => {
    if (this.state.new_pin.length <= 6 && value.length <= 6) {
      this.setState({ new_pin: value })
    } else {
      MyToast.popToast(strings.t('pin_max_six'))
    }
  }

  handle_confirm_pass = (value) => {
    if (this.state.confirm_pin.length <= 6 && value.length <= 6) {
      this.setState({ confirm_pin: value })
    } else {
      MyToast.popToast(strings.t('pin_max_six'))
    }
  }

  onBackPressed = () => {
    this.props.navigation.goBack()
  }

  onNextPressed = async () => {
    if (this.state.new_pin.length == 6 && this.state.confirm_pin.length == 6) {
      if (this.state.new_pin == this.state.confirm_pin) {
        this.setState({ isLoading: true })
        await pinAPICaller
          .setPin(MyData.authToken, this.state.new_pin, this.state.confirm_pin)
          .then((res) => {
            MyData.setUp(res)
            this.setState({ isLoading: false })
            MyData.setUserPin(this.state.OTP)
            storageData.set_auth_session(res?.response?.authSession)
            MyToast.popToast(res?.msg)
            if (this.state.pageRedirect)
              this.props.navigation.replace(this.state.pageRedirect, {
                onGoBack: (data) => {
                  this.refresh(data)
                },
              })
            else this.onBackPressed()
          })
          .catch((err) => {
            console.log(err)
            this.setState({ isLoading: false })
            MyToast.popToast(err.response.data.msg)
          })
      } else {
        MyToast.popToast(strings.t('new_pin_not_match'))
      }
    } else {
      MyToast.popToast(strings.t('invalid_data_input'))
    }
  }

  refresh = (data) => {
    this.props?.route?.params.onGoBack()
    this.props?.navigation?.goBack()
  }

  render() {
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: 'white',
        }}
      >
        <LoginFlowHeaderContainer
          onBackPress={() => this.onBackPressed()}
          mainText={strings.t('create_pin')}
          subText={strings.t('creat_pin_description')}
        >
          <View
            style={{
              flex: 1,
              paddingLeft: HeightDimen(30),
              paddingRight: HeightDimen(30),
              paddingBottom: HeightDimen(30),
            }}
          >
            <View
              style={{
                marginTop: HeightDimen(40),
              }}
            >
              <RecoverTextInput
                title={strings.t('new_pin')}
                placeholder={strings.t('new_pin')}
                isSecure={true}
                isNumber={true}
                top={true}
                onChangeText={this.handle_new_pass}
                value={this.state.new_pin}
              />

              <View style={{ height: HeightDimen(3) }}></View>
              <RecoverTextInput
                title={strings.t('confirm_new_pin')}
                placeholder={strings.t('confirm_new_pin')}
                bottom={true}
                isSecure={true}
                isNumber={true}
                onChangeText={this.handle_confirm_pass}
                value={this.state.confirm_pin}
              />
            </View>

            <View
              style={{
                width: '100%',
                alignItems: 'center',
                marginTop: HeightDimen(40),
              }}
            >
              <TextButton
                label={strings.t('confirm')}
                size={5}
                onPress={() => this.onNextPressed()}
                isLoading={this.state.isLoading}
              />
            </View>
          </View>
        </LoginFlowHeaderContainer>
      </View>
    )
  }
}

export default CreatePinVerificationScreen
