import React, { Component } from 'react'
import { ActivityIndicator, View } from 'react-native'
import { strings } from '../../Language/Language'

import { MainFlowHeaderContainerNoFeedback } from '../../Component/Header/MainFlowHeader'
import MyData from '../../Data/MyData'
import aPICaller from '../../API/APICaller'
import { HeightDimen } from '../../UI/Dimensions'
import { MainColor } from '../../UI/Color'

class LuckyCoinRedmeeScreen extends Component {
  state = {
    URL: '',
    isLoading: true,
  }
  onBackPressed = () => {
    this.props.navigation.goBack()
  }
  async componentDidMount() {
    await this.get_lky_redmee_url()
  }
  get_lky_redmee_url = async () => {
    await aPICaller
      .getLkyRedmeeWebURL()
      .then((res) => {
        this.setState({ URL: res?.response?.url })
      })
      .catch((err) => {
        if (err.response.status == 514) {
          this.props.expiredToken_logout()
        } else {
          MyToast.popToast(err.response.data.msg)
        }
      })
      .finally(() => {
        this.setState({ isLoading: false })
      })
  }
  render() {
    return (
      <View
        style={{
          flex: 1,
        }}
      >
        <MainFlowHeaderContainerNoFeedback
          title={strings.t('redeem')}
          onBackPress={() => this.onBackPressed()}
        >
          <View
            style={{
              flex: 1,
            }}
          >
            {this.state.isLoading ? (
              <View
                style={{
                  marginTop: HeightDimen(30),
                }}
              >
                <ActivityIndicator size="small" color={MainColor} />
              </View>
            ) : (
              <iframe
                src={this.state.URL}
                height={'100%'}
                width={'100%'}
                frameBorder="0"
              />
            )}
          </View>
        </MainFlowHeaderContainerNoFeedback>
      </View>
    )
  }
}

export default LuckyCoinRedmeeScreen
