import React, { Component } from 'react';
import { View, TouchableOpacity, Modal, Image } from 'react-native';
import Text from '../Text/MyText';

import { MainColor, SecondaryColor, FontMainColor, FontSecondaryColor, FontThirdColor } from '../../UI/Color';
import { WidthDimen, HeightDimen, fontDimen, GetWidth, GetHeight } from '../../UI/Dimensions';

import { ImageButton } from '../../Component/Button/ImageButton';
import { TextButton } from '../../Component/Button/TextButton';
import { strings } from '../../Language/Language';

const ConfirmUploadTopUpModal = (props) => {
    const { 
        isShow,
    //    onPressInstantTopUp,
    //    onPressBankTopUp,
        onConfirmPress,
        onBackPress,
        imgUrl,
    } = props;

    
    return (
        
            <Modal
                animationType="slide"
                transparent={true}    
                visible = {isShow}
                statusBarTranslucent
            > 
                <View 
                    style={{
                        flex: 1, 
                        backgroundColor: 'rgba(0,0,0,0.5)',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                   
                    <View
                        style={{
                            backgroundColor: 'white',
                            padding: HeightDimen(25)
                        }}
                    >
                        <Text
                            style={{
                                fontSize: fontDimen(20),
                                color: FontMainColor,
                                textAlign: 'center',
                                fontWeight: 'bold'
                            }}
                        >{strings.upload_bank_receipt}
                        </Text>
                        <Text
                            style={{
                                fontSize: fontDimen(15),
                                color: FontMainColor,
                                textAlign: 'center',
                        
                            }}
                        >{strings.confirm_to_upload_this_photo}
                        </Text>
                        
                        <View
                            style={{
                                height: HeightDimen(200),
                                marginTop: HeightDimen(20),
                                marginBottom: HeightDimen(20)
                            }}
                        >
                            <Image
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    resizeMode: 'contain'
                                }}
                                source={{uri: imgUrl}}
                            />
                        </View>
                        <View
                            style={{
                               
                                justifyContent: 'center',
                                flexDirection: 'row'
                            }}
                        >
                            <TextButton
                                onPress={onBackPress}
                                label={strings.cancel}
                                size={2.5}
                            />
                            <View
                                style={{
                                    marginLeft: WidthDimen(10)
                                }}
                            >
                                <TextButton
                                    onPress={onConfirmPress}
                                    label={strings.confirm}
                                    blackBtn={true}
                                    size={2.5}
                                />
                            </View>
                        </View>
                    </View>

                    
                </View>
            </Modal>
        
    )
  


}

export { ConfirmUploadTopUpModal };