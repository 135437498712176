import React, { Component, useEffect, useState } from 'react'
import { View, Image, TouchableOpacity, TextInput } from 'react-native'
import Text from '../Text/MyText'

import { FontMainColor, FontSecondaryColor, MainColor } from '../../UI/Color'
import { HeightDimen, fontDimen } from '../../UI/Dimensions'
import { CountryCodeModal } from '../Modal/CountryCodeModal'
import { countryCodes } from '../../Utils/Common'
import { CounterButton } from '../Button/CounterButton'

const PhoneTextInput = (props) => {
  const [selectedCode, setSelectedCode] = useState({
    id: 1,
    name: 'Malaysia',
    code: '+60',
    icon_url: require('../../Resources/Images/malaysia.png'),
  })

  const [countryCodeList, setCountryCodeList] = useState(countryCodes)
  const [isCountryModal, setIsCountryModal] = useState(false)
  useEffect(() => {
    if (props.existingCode) {
      const existingCode = countryCodeList.find((item) => {
        return item.code === props.existingCode
      })
      if (existingCode) setSelectedCode(existingCode)
    }
  })
  const {
    placeholder,
    onChangeText,
    top,
    bottom,
    isSecure,
    isNumber,
    value,
    isEdit,
    onSelectCodePress,
    marginBottom,
    isValidation,
    onOtpPress,
    isCounter,
    isDisabled,
  } = props

  return (
    <View
      style={{
        width: '100%',
        height: HeightDimen(50),
        backgroundColor: '#EFF2F4',
        borderTopRightRadius: top ? HeightDimen(10) : 0,
        borderTopLeftRadius: top ? HeightDimen(10) : 0,
        borderBottomRightRadius: bottom ? HeightDimen(10) : 0,
        borderBottomLeftRadius: bottom ? HeightDimen(10) : 0,
        flexDirection: 'row',
        alignItems: 'center',
        padding: HeightDimen(15),
        marginBottom: marginBottom || 0,
      }}
    >
      <TouchableOpacity
        disabled={isDisabled}
        onPress={() => setIsCountryModal(true)}
        style={{
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <Image
          source={selectedCode.icon_url}
          style={{ height: 15, width: 15 }}
        />
        <Text
          style={{
            fontSize: fontDimen(15),
            color: MainColor,
            marginHorizontal: HeightDimen(10),
          }}
        >
          {selectedCode.code}
        </Text>
        <Image
          source={require('../../Resources/Images/icon-down.png')}
          style={{
            height: 10,
            width: 10,
            tintColor: FontSecondaryColor,
            marginRight: HeightDimen(7),
          }}
        />
      </TouchableOpacity>

      <View
        style={{
          flex: 1,
        }}
      >
        <TextInput
          editable={isEdit}
          placeholderTextColor={'#C7C7CD'}
          placeholder={placeholder}
          onChangeText={onChangeText}
          secureTextEntry={isSecure ? true : false}
          textContentType={'oneTimeCode'}
          keyboardType={isNumber ? 'decimal-pad' : 'default'}
          style={{
            fontSize: fontDimen(15),
            // height: Platform.OS == 'android' ? HeightDimen(50) : HeightDimen(30)
            color: FontMainColor,
            height: HeightDimen(50),
            outlineStyle: 'none',
          }}
          value={value}
        />
      </View>
      {isCounter && (
        <CounterButton
          value={value}
          onPress={onOtpPress}
          isValidation={isValidation}
        />
      )}
      <CountryCodeModal
        isShow={isCountryModal}
        list={countryCodeList}
        isPhoneNo
        onBackPress={() => {
          setIsCountryModal(false)
        }}
        onPress={(val) => {
          setIsCountryModal(false)
          setSelectedCode(val)
          onSelectCodePress(val.code)
        }}
        onCloseModal={() => {
          setIsCountryModal(false)
        }}
      />
    </View>
  )
}

export { PhoneTextInput }
