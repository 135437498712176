import React, { useEffect, useImperativeHandle, useState } from 'react'
import {
  Keyboard,
  Modal,
  Text,
  TextInput,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from 'react-native'
import transferAPICaller from '../../API/TransferAPICaller'
import MyData from '../../Data/MyData'
import { strings } from '../../Language/Language'
import { FontMainColor, SecondaryColor } from '../../UI/Color'
import { fontDimen, HeightDimen } from '../../UI/Dimensions'
import { resetNavigationStack } from '../../Utils/Common'
import { TextButton } from '../Button/TextButton'
import { RecoverTextInput } from '../Input/RecoverTextInput'
import MyToast from '../Toast/MyToast'
import { EnterTransactionPinModal } from './EnterTransactionPinModal'
import { RewardModal } from './RewardSuccessModal'
import { AnalyticEvents, logMessageAnalytics } from '../../Utils/Analytics'
const TransferBalanceModal = React.forwardRef((props, ref) => {
  const {
    isShow,
    closeModalPress,
    transferConfirmPress,
    onSucessTransfer,
    expiredTokenLogout,
    navigation,
  } = props
  const [amount, setAmount] = useState('')
  const [showPinModal, setShowPinModal] = useState(false)
  const [username, setUsername] = useState('')
  const [isUserName, setIsUserName] = useState(false)
  const [toTransferId, setToTransferId] = useState('')
  const [availableBalance, setAvailableBalance] = useState('')
  const [memberDisplay, setMemberDisplay] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [transactionProcessModal, setTransactionProcessModal] = useState(false)

  let maxAmountList = ['100', '500', '1000', '2000', '4000', '5000']

  useImperativeHandle(ref, () => ({
    on_transfer: on_transfer,
  }))

  const renderMaxAmountButton = () => {
    return maxAmountList.map((item, index) => (
      <TouchableOpacity
        key={index}
        onPress={() => {
          handle_amount(item)
        }}
        style={styles.maxButton}
      >
        <Text style={styles.maxButtonLabel}>{item}</Text>
      </TouchableOpacity>
    ))
  }

  const onNextPressed = async () => {
    if (amount != '' && isUserName) {
      Keyboard.dismiss()
      ///  transferConfirmPress();
      setShowPinModal(true)
    } else {
      MyToast.popToast(strings.invalid_data_input)
    }
  }

  const handle_SID = (val) => {
    if (isUserName) {
      setIsUserName(false)
    }
    setUsername(val)
  }
  const handle_amount = (val) => {
    if (Number(val) <= Number(availableBalance)) {
      const converted = val.replace(/\D/g, '')
      const final = Number(converted).toLocaleString()
      setAmount(final)
    }
  }
  const onSearchPress = async () => {
    if (username != '') {
      onSearchUsername()
    } else {
      MyToast.popToast(strings.invalid_data_input)
    }
  }
  const onPress_forgot_pin = () => {
    //this.setState({ showPinModal: false, isTransferBalanceModalShow: false });
    setShowPinModal(false)
    closeModalPress()
    navigation.navigate('RecoverPin')
  }

  const onSuccEnterPin = () => {
    // this.setState({ showPinModal: false });
    setShowPinModal(false)
    // closeModalPress();
    on_transfer()
  }

  const onBackModal = () => {
    // this.setState({ showPinModal: false });
    setShowPinModal(false)
  }

  const onSearchUsername = async () => {
    setIsLoading(true)
    await transferAPICaller
      .checkMember(MyData.authToken, username)
      .then((res) => {
        setToTransferId(res.response.member.member_unique_key)
        setIsUserName(true)
        setAvailableBalance(res.response.self.walletBalance)
        setMemberDisplay(res.response.member.memberDisplay)
      })
      .catch((err) => {
        if (err.response.status == 400) {
          MyToast.popToast(strings.no_user_found)
        } else if (err.response.status == 514) {
          expiredTokenLogout()
        } else {
          MyToast.popToast(err.response.data.msg)
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }
  const on_transfer = async () => {
    setIsLoading(true)
    setTransactionProcessModal(true)
    await transferAPICaller
      .transferMoney(MyData.authToken, toTransferId, amount)
      .then((res) => {
        logMessageAnalytics(AnalyticEvents.ProfileTransferBalanceSuccess)
        setTransactionProcessModal(false)
        onSucessTransfer({
          isSuccess: true,
          transferMessage: res.msg,
          transferAmount: res.response.amount,
        })

        navigation.navigate('PaymentSuccessScreen', {
          isSuccess: true,
          transferMessage: res.msg,
          transferAmount: res.response.amount,
          // onGoBack: () => navigation.goBack(),
        })
      })
      .catch((err) => {
        logMessageAnalytics(
          AnalyticEvents.ProfileTransferBalanceFailure,
          err.message
        )

        if (err?.status == 514) {
          expiredTokenLogout()
        } else {
          onSucessTransfer({
            isSuccess: false,
            transferMessage: err.message,
            transferAmount: '',
          })
          navigation.navigate('PaymentSuccessScreen', {
            isSuccess: false,
            transferMessage: err.message,
            //transferAmount: "",
            // onGoBack: () => navigation.goBack(),
          })
        }
      })
      .finally(() => {
        setIsLoading(false)
        setTransactionProcessModal(false)
      })
  }

  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={isShow}
      statusBarTranslucent
    >
      <EnterTransactionPinModal
        isShow={showPinModal}
        onPressForgotPin={() => onPress_forgot_pin()}
        onSuccEnterPin={() => onSuccEnterPin()}
        onBackModal={() => onBackModal()}
      />
      {transactionProcessModal && (
        <RewardModal
          isShow={transactionProcessModal}
          source={require('../../Resources/LottieIcons/processing.json')}
          amount={strings.transaction_process}
        />
      )}
      {/* <TouchableWithoutFeedback onPress={Keyboard.dismiss} accessible={false}> */}
      <View style={styles.container}>
        <View style={styles.innerContainer}>
          <Text style={styles.titleLabel}>{strings.transfer}</Text>
          <RecoverTextInput
            title={strings.username_text}
            placeholder={strings.username_text}
            top={true}
            value={username}
            bottom={true}
            onChangeText={handle_SID}
          />
          {!isUserName && (
            <View style={styles.buttonContainer}>
              <TextButton
                label={strings.search}
                size={2.5}
                onPress={() => onSearchPress()}
                isLoading={isLoading}
              />
              <View style={{ marginLeft: HeightDimen(10) }}>
                <TextButton
                  label={strings.cancel}
                  size={2.5}
                  blackBtn
                  onPress={closeModalPress}
                />
              </View>
            </View>
          )}
          {isUserName && (
            <View>
              <View style={styles.availableCashContainer}>
                <Text style={styles.cashLabel}>{strings.phone_number}:</Text>
                <Text style={styles.availableCashAmountLabel}>
                  {memberDisplay}
                </Text>
              </View>

              <View style={styles.availableCashContainer}>
                <Text style={styles.availableCashLabel}>
                  {strings.available_transfer_balance_RM} ({MyData.country_code}
                  )
                </Text>
                <Text style={styles.availableCashAmountLabel}>
                  {`${Number(availableBalance).toFixed(2)}`}
                </Text>
              </View>
              <View style={styles.cashContainer}>
                <Text style={styles.cashLabel}>
                  {strings.transfer} ({MyData.country_code})
                </Text>
                <TouchableOpacity
                  onPress={() => {
                    setAmount(availableBalance)
                  }}
                >
                  <Text style={styles.cashMaxButton}>{strings.max}</Text>
                </TouchableOpacity>
              </View>
              <View style={styles.textInputContainer}>
                <TextInput
                  placeholder="0.00"
                  keyboardType="numeric"
                  placeholderTextColor={SecondaryColor}
                  style={styles.textInput}
                  onChangeText={handle_amount}
                  value={amount}
                />
              </View>
              <View style={styles.maxButtonContainer}>
                {renderMaxAmountButton()}
              </View>
            </View>
          )}

          {isUserName && (
            <View style={styles.buttonContainer}>
              <TextButton
                label={strings.confirm}
                size={2.5}
                onPress={() => onNextPressed()}
                isLoading={isLoading}
              />
              <View style={{ marginLeft: HeightDimen(10) }}>
                <TextButton
                  label={strings.cancel}
                  size={2.5}
                  blackBtn
                  onPress={closeModalPress}
                />
              </View>
            </View>
          )}
        </View>
      </View>
      {/* </TouchableWithoutFeedback> */}
    </Modal>
  )
})
const styles = {
  container: {
    flex: 1,
    backgroundColor: 'rgba(0,0,0,0.5)',
    justifyContent: 'center',
  },
  innerContainer: {
    padding: HeightDimen(20),
    marginHorizontal: HeightDimen(20),
    marginTop: HeightDimen(20),
    backgroundColor: 'white',
    borderRadius: HeightDimen(10),
  },

  maxButtonContainer: {
    flexDirection: 'row',
    marginTop: HeightDimen(30),
    alignItems: 'center',
    justifyContent: 'center',
  },
  maxButton: {
    backgroundColor: SecondaryColor,
    padding: HeightDimen(5),
    borderRadius: HeightDimen(5),
    marginRight: HeightDimen(5),
  },
  maxButtonLabel: {
    color: 'white',
    fontSize: fontDimen(12),
    textAlign: 'center',
  },
  cashContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: HeightDimen(10),
  },
  cashLabel: {
    fontSize: fontDimen(12),
    fontWeight: 'bold',
  },
  cashMaxButton: {
    color: SecondaryColor,
    fontSize: fontDimen(16),
    fontWeight: 'bold',
  },
  textInputContainer: {
    marginTop: HeightDimen(10),
    alignItems: 'center',
    justifyContent: 'center',
  },
  textInput: {
    fontSize: fontDimen(30),
    color: SecondaryColor,
    borderBottomColor: SecondaryColor,
    borderBottomWidth: HeightDimen(2),
    textAlign: 'center',
  },

  availableCashContainer: {
    flexDirection: 'row',
    marginTop: HeightDimen(10),
  },
  availableCashLabel: {
    fontSize: fontDimen(12),
    flex: 1,
    fontWeight: 'bold',
  },
  availableCashAmountLabel: {
    fontSize: fontDimen(12),
  },
  buttonContainer: {
    width: '100%',
    alignItems: 'center',
    marginTop: HeightDimen(40),
    justifyContent: 'center',
    flexDirection: 'row',
  },
  titleLabel: {
    textAlign: 'center',
    fontSize: fontDimen(20),
    color: FontMainColor,
    marginBottom: HeightDimen(20),
  },
}
export { TransferBalanceModal }
