import React, { Component } from 'react'
import { View, TouchableOpacity, Image } from 'react-native'
import Text from '../../Component/Text/MyText'
import { MainColor, FontThirdColor } from '../../UI/Color'
import { WidthDimen, HeightDimen, fontDimen } from '../../UI/Dimensions'
import { Camera, CameraType } from 'expo-camera'
import { MainFlowHeaderContainer } from '../../Component/Header/MainFlowHeader'
import MyData from '../../Data/MyData'
import { strings } from '../../Language/Language'
import { isMobile } from '../../Utils/Common'

class CameraScreen extends Component {
  state = {
    isImageReady: false,
    imgUrl: '',
    isMobile: isMobile ? true : false,
  }

  onBackPressed = () => {
    this.props.navigation.goBack()
  }

  takePicture = async () => {
    if (this.camera) {
      const options = { quality: 1, base64: true }
      const data = await this.camera.takePictureAsync(options)
      this.setState({
        imgUrl: data.uri,
        isImageReady: true,
      })
    }
  }

  retakePicture = () => {
    this.setState({
      isImageReady: false,
      imgUrl: '',
    })
  }

  onUsePicture = () => {
    this.props.route.params.onGoBack(this.state.imgUrl)
    this.props.navigation.goBack()
  }

  render() {
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: 'white',
        }}
      >
        <MainFlowHeaderContainer
          title={strings.take_photo}
          onBackPress={() => this.onBackPressed()}
          toShow={this.state.isImageReady}
          toShowText={strings.retake}
          toShowPress={() => this.retakePicture()}
        >
          <View
            style={{
              flex: 1,
              justifyContent: 'flex-end',
            }}
          >
            <View
              style={{
                flex: 1,
                //borderTopLeftRadius: HeightDimen(20),
                // borderTopRightRadius: HeightDimen(20),
                overflow: 'hidden',
              }}
            >
              {this.state.isImageReady ? (
                <View
                  style={{
                    flex: 1,
                  }}
                >
                  <Image
                    style={{
                      width: '100%',
                      height: '100%',
                    }}
                    source={{ uri: this.state.imgUrl }}
                  />
                </View>
              ) : (
                <View
                  style={{
                    flex: 1,
                  }}
                >
                  <Camera
                    ref={(ref) => {
                      this.camera = ref
                    }}
                    style={{
                      flex: 1,
                    }}
                    type={
                      this.state.isMobile ? CameraType.back : CameraType.front
                    }
                    captureAudio={false}
                    onBarCodeScanned={({ barcodes }) => {
                      if (
                        barcodes[0].data != null &&
                        barcodes[0].data[0] != '{'
                      ) {
                        //console.log(barcodes[0].data);
                        MyData.receommender_name = barcodes[0].data
                        if (this.props.isFromInfo)
                          this.props.navigation.navigate('SignUps')
                        else this.props.navigation.navigate('SignUp')
                      }
                    }}
                  />

                  <View
                    style={{
                      width: '100%',
                      position: 'absolute',
                      bottom: HeightDimen(70),
                      alignItems: 'center',
                    }}
                  >
                    <TouchableOpacity
                      onPress={() => this.takePicture()}
                      style={{
                        width: 'auto',
                        height: HeightDimen(60),
                        aspectRatio: 1 / 1,
                        backgroundColor: 'white',
                        borderRadius: 9999,
                      }}
                    ></TouchableOpacity>
                  </View>
                </View>
              )}
            </View>
            <View
              style={{
                height: HeightDimen(65),
                backgroundColor: MainColor,
                alignItems: 'flex-end',
                justifyContent: 'center',
              }}
            >
              {!this.state.isImageReady ? (
                <></>
              ) : (
                <TouchableOpacity onPress={() => this.onUsePicture()}>
                  <Text
                    style={{
                      fontSize: fontDimen(13),
                      color: FontThirdColor,
                      marginRight: WidthDimen(20),
                    }}
                  >
                    {strings.use_photo}
                  </Text>
                </TouchableOpacity>
              )}
            </View>
          </View>
        </MainFlowHeaderContainer>
      </View>
    )
  }
}

export default CameraScreen
