import React, { Component } from 'react'
import {
  View,
  Image,
  TouchableOpacity,
  TextInput,
  Platform,
} from 'react-native'
import Text from '../Text/MyText'

import {
  MainColor,
  SecondaryColor,
  FontMainColor,
  FontSecondaryColor,
  FontThirdColor,
} from '../../UI/Color'
import {
  WidthDimen,
  HeightDimen,
  fontDimen,
  GetWidth,
  GetHeight,
} from '../../UI/Dimensions'
import MIcon from 'react-native-vector-icons/MaterialIcons'

class PasswordInput extends Component {
  state = {
    ishide: true,
  }

  onPress_show_hide_password = () => {
    if (this.state.ishide) {
      this.setState({ ishide: false })
    } else {
      this.setState({ ishide: true })
    }
  }

  render() {
    const {
      placeholder,
      onChangeText,
      marginBottom,
      value,
      isVerified,
      isError,
    } = this.props

    return (
      <View
        style={{
          width: '100%',
          height: HeightDimen(50),
          backgroundColor: '#EFF2F4',
          borderRadius: HeightDimen(10),
          flexDirection: 'row',
          alignItems: 'center',
          padding: HeightDimen(15),
          marginBottom: marginBottom || 0,
        }}
      >
        <Image
          source={require('../../Resources/Images/icon_password.png')}
          style={{
            width: 'auto',
            height: HeightDimen(15),
            aspectRatio: 1 / 1,
            resizeMode: 'contain',
            marginRight: WidthDimen(10),
          }}
        />
        <View
          style={{
            flex: 1,
          }}
        >
          <TextInput
            placeholder={placeholder}
            placeholderTextColor={'#C7C7CD'}
            onChangeText={onChangeText}
            secureTextEntry={this.state.ishide}
            textContentType={'oneTimeCode'}
            value={value}
            style={{
              fontSize: fontDimen(15),
              // height: Platform.OS == 'android' ? HeightDimen(50) : HeightDimen(30)
              color: FontMainColor,
              height: HeightDimen(50),
              outlineStyle: 'none',
            }}
          />
        </View>
        {isVerified && (
          <View
            style={{
              marginRight: 5,
              height: HeightDimen(50),
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <MIcon name={'check-circle-outline'} size={20} color={'green'} />
          </View>
        )}
        {isError && (
          <View
            style={{
              marginRight: 5,
              height: HeightDimen(50),
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <MIcon name={'close'} size={20} color={'red'} />
          </View>
        )}
        <TouchableOpacity onPress={() => this.onPress_show_hide_password()}>
          <Image
            source={
              this.state.ishide
                ? require('../../Resources/Images/hide-eye.png')
                : require('../../Resources/Images/show-eye.png')
            }
            style={{
              width: 'auto',
              height: HeightDimen(22),
              aspectRatio: 1 / 1,
              resizeMode: 'contain',
              marginLeft: WidthDimen(10),
              tintColor: FontSecondaryColor,
            }}
          />
        </TouchableOpacity>
      </View>
    )
  }
}

export default PasswordInput
