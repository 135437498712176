import React, { Component } from "react";
import { View, Modal, Image, Text, TouchableOpacity } from "react-native";
import { fontDimen, HeightDimen } from "../../UI/Dimensions";
import { LoginFlowHeaderContainer } from "../../Component/Header/LoginFlowHeader";
import { TextButton } from "../../Component/Button/TextButton";
import APICaller from "../../API/APICaller";
import Mydata from "../../Data/MyData";
import MyToast from "../../Component/Toast/MyToast";
import { strings } from "../../Language/Language";
import { RecoverTextInput } from "../../Component/Input/RecoverTextInput";
import { getDeviceInfo, resetNavigationStack } from "../../Utils/Common";
import storageData from "../../Data/StorageData";
import { FontSecondaryColor, SecondaryColor } from "../../UI/Color";
import {
  AnalyticEvents,
  eventMessages,
  logEventWithErrorMsg,
  logEventWithScreenName,
  logMessageAnalytics,
  logScreenViewAnalytics,
  screenNames,
} from "../../Utils/Analytics";
import { NormalTextInput } from "../../Component";
import OTPRouteData from "../../Data/OTPRouteData";

class GoogleAuthVerificationScreen extends Component {
  state = {
    isLoading: false,
    username: "",
    password: "",
    code: "",
    isSwitchAccount: this.props?.route?.params?.isSwitchAccount || false,
  };

  async componentDidMount() {
    logScreenViewAnalytics(screenNames.GoogleVerificationCode);
    this.setState({
      username: this.props?.route?.params?.username,
      password: this.props?.route?.params?.password,
    });
  }
  handle_otp = otp => {
    this.setState({ otp: otp });
  };

  onBackPressed = () => {
    logEventWithScreenName(
      eventMessages.BackButton,
      screenNames.GoogleVerificationCode
    );
    this.props.navigation.goBack();
  };

  onConfirmVerification = async () => {
    if (this.state.code) {
      this.setState({ isLoading: true });
      await APICaller.login(
        this.state.username,
        this.state.password,
        this.state.code
      )
        .then(async res => {
          logMessageAnalytics(AnalyticEvents.GoogleVerificationCodeSuccess);
          storageData.set_username_add_password(
            this.state.username,
            this.state.password,
            res.response.authSession,
            res.response.user.type
          );
          Mydata.popupImage = res?.response?.popUpImage;
          Mydata.setUp(res);

          if (res.response.user.pin_status == "false") {
            if (res.response.user.member_directline === "1")
              this.props.isLogin_Ready();
            else this.props.isLogin_Ready_set_pin();
          } else {
            Mydata.setUserPin(res.response.user.wallet_pin);
            if (this.state.isSwitchAccount) {
              await storageData.store_multi_account_data(
                this.state.username,
                this.state.password,
                res?.response?.user?.member_avatar_url,
                res?.response?.user?.member_login_token
              );
              resetNavigationStack(this.props.navigation, "Home");
            } else this.props.isLogin_Ready();
          }
        })
        .catch(err => {
          logEventWithErrorMsg(
            AnalyticEvents.GoogleVerificationCodeFailure,
            this.state.username,
            err.response.data.msg
          );
          this.setState({ isLoading: false });
          if (err.response.status == 302) {
            //console.log(err.response.data.response.userPhone)
            OTPRouteData.set_flow_login();
            Mydata.userToken = err.response.data.response.userToken;
            Mydata.authToken = err.response.data.response.authToken;
            this.props.navigation.navigate("OTP", {
              phoneNumber: err.response.data.response.userPhone,
              code: err.response.data.response.countryCode,
            });
          } else if (err.response.status == 303) {
            Mydata.userToken = err.response.data.response.userToken;
            this.props.navigation.navigate("Verification", {
              phoneNumber: err.response.data.response.userPhone,
              username: this.state.username,
              password: this.state.password,
            });
          } else if (err.response.status == 300) {
            Mydata.maintainance_json = err.response.data.response;
            this.props.navigation.navigate("Maintenance");
          } else {
            MyToast.popToast(err.response.data.msg);
          }
        })
        .finally(() => {
          this.setState({ isLoading: false });
        });
    } else {
      MyToast.popToast(strings.enter_otp);
    }
  };

  render() {
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: "white",
        }}>
        <LoginFlowHeaderContainer
          onBackPress={() => this.onBackPressed()}
          mainText={strings.google_auth}
          subText={strings.register_google_auth_verification_msg}>
          <View
            style={{
              flex: 1,
              paddingLeft: HeightDimen(30),
              paddingRight: HeightDimen(30),
              paddingBottom: HeightDimen(30),
            }}>
            <View
              style={{
                marginTop: HeightDimen(40),
              }}>
              <NormalTextInput
                placeholder={strings.enter_verification_code}
                hideImage
                isNumber
                value={this.state.code}
                onChangeText={val => this.setState({ code: val })}
              />
            </View>

            <View
              style={{
                width: "100%",
                alignItems: "center",
                marginTop: HeightDimen(20),
              }}>
              <TextButton
                label={strings.confirm}
                size={5}
                onPress={() => this.onConfirmVerification()}
                isLoading={this.state.isLoading}
              />
            </View>
          </View>
        </LoginFlowHeaderContainer>
      </View>
    );
  }
}

export default GoogleAuthVerificationScreen;
