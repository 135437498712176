import React, { useEffect, useMemo, useState } from 'react'
import ReactDatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
const MonthDateModal = (props) => {
  const { isShow, label, onClose, rawDate } = props
  const [date, setDate] = useState(rawDate || new Date())

  useEffect(() => {
    debugger
    if (rawDate) setDate(rawDate)
  }, [rawDate])

  const set_date = (date) => {
    debugger
    if (!date) return
    let day = date.getDate()
    let month = date.getMonth() + 1
    let year = date.getFullYear()

    let end_date = new Date(date.getFullYear(), date.getMonth() + 1, 0)
    let end_day = end_date.getDate()

    let returnDate =
      year +
      '-' +
      (month < 10 ? '0' : '') +
      month +
      '-' +
      (day < 10 ? '0' : '') +
      day
    let returnEndDate =
      year +
      '-' +
      (month < 10 ? '0' : '') +
      month +
      '-' +
      (end_day < 10 ? '0' : '') +
      end_day
    let labelDate = (month < 10 ? '0' : '') + month + '-' + year
    setDate(date)
    onClose(returnDate, returnEndDate, labelDate, date)
  }

  return (
    <ReactDatePicker
      selected={date}
      onChange={set_date}
      dateFormat="MM-yyyy"
      showMonthYearPicker
      style={{ backgroundColor: 'red' }}
    />
  )
}

export default MonthDateModal
