import React, { Component } from 'react'
import { View, TouchableOpacity, Image } from 'react-native'
import Text from '../../../Text/MyText'

import {
  MainColor,
  SecondaryColor,
  FontMainColor,
  FontSecondaryColor,
  GILROY_NORMAL,
} from '../../../../UI/Color'
import { HeightDimen, fontDimen } from '../../../../UI/Dimensions'

const AllWalletHistoryCard = (props) => {
  const {
    onPress,
    title,
    date,
    bet,
    win,
    url,
    disabled,
    source,
    amountColor,
    statusColor,
  } = props

  return (
    <TouchableOpacity
      disabled={disabled}
      onPress={onPress}
      style={{
        width: '100%',
        marginVertical: HeightDimen(10),
        flexDirection: 'row',
      }}
    >
      {url && (
        <View
          style={{
            width: 'auto',
            height: HeightDimen(45),
            aspectRatio: 1 / 1,
            borderRadius: 9999,
            borderWidth: HeightDimen(2),
            borderColor: SecondaryColor,
            backgroundColor: 'white',
            zIndex: 99,
            overflow: 'hidden',
            justifyContent: 'center',
            alignItems: 'center',
            marginRight: HeightDimen(10),
          }}
        >
          <Image
            style={{
              width: 'auto',
              height: HeightDimen(45),
              aspectRatio: 1 / 1,
              resizeMode: 'contain',
            }}
            source={source ? source : { uri: url }}
          />
        </View>
      )}
      <View
        style={{
          flex: 1,
          justifyContent: 'center',
          //  marginLeft: HeightDimen(10),
        }}
      >
        <Text
          numberOfLines={1}
          style={{
            fontSize: fontDimen(14),
            color: FontMainColor,
            marginBottom: HeightDimen(5),
            fontFamily: GILROY_NORMAL,
          }}
        >
          {title}
        </Text>
        <Text
          style={{
            fontSize: fontDimen(10),
            color: amountColor || FontSecondaryColor,
            fontFamily: GILROY_NORMAL,
          }}
        >
          {bet}
        </Text>
      </View>
      <View
        style={{
          justifyContent: 'center',
          alignItems: 'flex-end',
        }}
      >
        <Text
          style={{
            fontSize: fontDimen(12),
            color: FontMainColor,
            marginBottom: HeightDimen(5),
            fontFamily: GILROY_NORMAL,
          }}
        >
          {date}
        </Text>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Text
            style={{
              fontSize: fontDimen(12),
              color: statusColor || FontSecondaryColor,
              fontFamily: GILROY_NORMAL,
            }}
          >
            {win}
          </Text>
        </View>
      </View>
    </TouchableOpacity>
  )
}

export { AllWalletHistoryCard }
