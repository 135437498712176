import React, { Component } from "react";
import { View } from "react-native";
import Text from "../../Component/Text/MyText";
import { FontMainColor } from "../../UI/Color";
import { HeightDimen, fontDimen } from "../../UI/Dimensions";
import { MainFlowHeaderContainer } from "../../Component/Header/MainFlowHeader";
import { strings } from "../../Language/Language";
import { getDeviceInfo } from "../../Utils/Common";
import { RecoverTextInput } from "../../Component/Input/RecoverTextInput";
import { LoginFlowHeaderContainer } from "../../Component/Header/LoginFlowHeader";

class AboutPhoneScreen extends Component {
  state = {
    deviceInfo: "",
    model: "",
    deviceId: "",
    uniqueId: "",
  };

  async componentDidMount() {
    const deviceInfoData = await getDeviceInfo();
    const deviceName = deviceInfoData.deviceName;
    const model = deviceInfoData.model;
    const deviceId = deviceInfoData.deviceId;
    const uniqueId = deviceInfoData.uniqueId;

    this.setState({
      deviceName,
      model,
      deviceId,
      uniqueId,
    });
  }

  onBackPressed = () => {
    this.props.navigation.goBack();
  };

  render() {
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: "white",
        }}
      >
        <LoginFlowHeaderContainer
          onBackPress={() => this.onBackPressed()}
          mainText={strings.about_phone}
        >
          <View
            style={{
              flex: 1,
              paddingLeft: HeightDimen(30),
              paddingRight: HeightDimen(30),
            }}
          >
            <RecoverTextInput
              title={strings.device_name}
              placeholder={strings.device_name}
              top={true}
              isEdit={false}
              value={this.state.deviceName}
            />
            <View style={{ height: HeightDimen(3) }}></View>
            <RecoverTextInput
              title={strings.model}
              placeholder={strings.device_info}
              isEdit={false}
              value={this.state.model}
            />

            <View style={{ height: HeightDimen(3) }}></View>
            <RecoverTextInput
              title={strings.device_id}
              placeholder={strings.device_info}
              isEdit={false}
              value={this.state.deviceId}
            />
            <View style={{ height: HeightDimen(3) }}></View>
            <RecoverTextInput
              title={strings.unique_id}
              placeholder={strings.phone_number}
              bottom={true}
              isEdit={false}
              value={this.state.uniqueId}
            />
          </View>
        </LoginFlowHeaderContainer>
      </View>
    );
  }
}

export default AboutPhoneScreen;
