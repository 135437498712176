import React, { Component, useState, useEffect } from 'react'
import {
  Text,
  StyleSheet,
  FlatList,
  View,
  Image,
  ScrollView,
} from 'react-native'

import { MainFlowHeaderContainerNoFeedback } from '../../Component/Header/MainFlowHeader'
import MyToast from '../../Component/Toast/MyToast'
import MyData from '../../Data/MyData'
import { strings } from '../../Language/Language'
import { SecondaryColor } from '../../UI/Color'
import { fontDimen, HeightDimen, WidthDimen } from '../../UI/Dimensions'
import ModalGamesList from './ModalGamesList'
import APICaller from '../../API/APICaller'
import { TopUpModal } from '../../Component/Modal/NewDesign/TopUpModal'
import walletAPICaller from '../../API/WalletAPICaller'
import bankAPICaller from '../../API/BankAPICaller'
import storageData from '../../Data/StorageData'

const CouponVipScreen = (props) => {
  const [couponsList, setCouponsList] = useState(null)
  const [item, setItem] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isModalVisible, setModalIsVisible] = useState(false)
  const [showTopupModal, setShowTopUpModal] = useState(false)
  const [topUpMethods, setTopUpMethods] = useState([])
  const [isQRTopUpDisabled, setQRTopUPDisabled] = useState(false)
  const selectedLang = storageData.get_selected_language()

  const tableData = {
    tableHeader: ['VIP1', 'VIP2', 'VIP3'],
    tableData: [
      [
        '累计存款:\n10,000\n\n流水要求:\n50,000',
        '累计存款:\n100,000\n\n流水要求:\n500,000',
        '累计存款:\n300,000\n\n流水要求:\n1,500,000',
      ],
    ],
  }

  const tableData2 = {
    tableHeader: ['VIP4', 'VIP5', 'VIP6'],
    tableData: [
      [
        '累计存款:\n1,000,000\n\n流水要求:\n5,000,000',
        '累计存款:\n3,000,000\n\n流水要求:\n15,000,000',
        '累计存款:\n10,000,000\n\n流水要求:\n15,000,000',
      ],
    ],
  }

  const tableData3 = {
    tableHeader: ['', 'VIP1', 'VIP2', 'VIP3', 'VIP4', 'VIP5', 'VIP6'],
    tableData: [
      ['晋升奖励', '88', '188', '388', '1888', '3888', '8888'],
      ['生日礼金', '88', '188', '388', '588', '1888', '5888'],
      ['日提款次数', '5', '5', '10', '10', '20', '20'],
      ['每月双俸禄', '18', '38', '68', '108', '168', '238'],
    ],
  }

  const tableData_EN = {
    tableHeader: ['VIP1', 'VIP2', 'VIP3'],
    tableData: [
      [
        `${strings.t('vip_require_turnover')}\n400,000`,
        `${strings.t('vip_require_turnover')}\n800,000`,
        `${strings.t('vip_require_turnover')}\n4,000,000`,
      ],
    ],
  }
  const tableData_myr_EN = {
    tableHeader: ['VIP1', 'VIP2', 'VIP3'],
    tableData: [
      [
        `${strings.t('vip_accumulated_deposit')}\n10,000 \n\n${strings.t(
          'vip_require_turnover'
        )}\n50,000\n`,
        `${strings.t('vip_accumulated_deposit')}\n100,000 \n\n${strings.t(
          'vip_require_turnover'
        )}\n500,000\n`,
        `${strings.t('vip_accumulated_deposit')}\n300,000 \n\n${strings.t(
          'vip_require_turnover'
        )}\n1,500,000\n`,
      ],
    ],
  }
  const tableData2_EN = {
    tableHeader: ['VIP4', 'VIP5', 'VIP6'],
    tableData: [
      [
        `${strings.t('vip_require_turnover')}\n8,000,000`,
        `${strings.t('vip_require_turnover')}\n40,000,000`,
        `${strings.t('vip_require_turnover')}\n80,000,000`,
      ],
    ],
  }
  const tableData2_myr_EN = {
    tableHeader: ['VIP4', 'VIP5', 'VIP6'],
    tableData: [
      [
        `${strings.t('vip_accumulated_deposit')}\n1,000,000 \n\n${strings.t(
          'vip_require_turnover'
        )}\n5,000,000\n`,
        `${strings.t('vip_accumulated_deposit')}\n3,000,000 \n\n${strings.t(
          'vip_require_turnover'
        )}\n15,000,000\n`,
        `${strings.t('vip_accumulated_deposit')}\n10,000,000 \n\n${strings.t(
          'vip_require_turnover'
        )}\n50,000,000\n`,
      ],
    ],
  }

  const tableData3_EN = {
    tableHeader: ['', 'VIP1', 'VIP2', 'VIP3', 'VIP4', 'VIP5', 'VIP6'],
    // tableData: [
    //   [
    //     strings.t('promoted_bonus,
    //     "-",
    //     "64",
    //     "304",
    //     `224(${strings.t('weekly})`,
    //     `2304(${strings.t('weekly})`,
    //     `4704(${strings.t('weekly})`,
    //   ],
    //   [strings.t('birthday_bonus, "64", "64", "304", "544", "1024", "4704"],
    //   [strings.t('withdraw_limit, "5", "5", "10", "10", "20", "20"],
    //   [strings.t('monthly_bonus, "180", "380", "680", "1080", "1680", "2380"],
    // ],
    tableData: [
      [
        strings.t('bonus_monthly'),
        '-',
        '68',
        '308',
        `228(${strings.t('weekly')})`,
        `2288(${strings.t('weekly')})`,
        `4688(${strings.t('weekly')})`,
      ],
      [strings.t('birthday_bonus'), '68', '68', '308', '538', '1088', '4688'],
      [strings.t('withdraw_per_day'), '3', '3', '5', '5', '10', '20'],
      [strings.t('exclusive_domain'), '-', '✔', '✔', '✔', '✔', '✔'],
      [strings.t('deposit_priority'), '-', '-', '-', '✔', '✔', '✔'],
      [strings.t('festival_gift'), '-', '-', '-', '-', '✔', '✔'],
      [strings.t('exclusive_customer_service'), '-', '-', '-', '-', '✔', '✔'],
      [strings.t('free_travel'), '-', '-', '-', '-', '-', '✔'],
    ],
  }
  const tableData3_myr_EN = {
    tableHeader: ['', 'VIP1', 'VIP2', 'VIP3', 'VIP4', 'VIP5', 'VIP6'],
    tableData: [
      [
        strings.t('bonus_monthly'),
        '-',
        '8',
        '38',
        `28(${strings.t('weekly')})`,
        `288(${strings.t('weekly')})`,
        `588(${strings.t('weekly')})`,
      ],
      [strings.t('birthday_bonus'), '8', '8', '38', '68', '128', '588'],
      [strings.t('withdraw_per_day'), '3', '3', '5', '5', '10', '20'],
      [strings.t('exclusive_domain'), '-', '✔', '✔', '✔', '✔', '✔'],
      [strings.t('deposit_priority'), '-', '-', '-', '✔', '✔', '✔'],
      [strings.t('festival_gift'), '-', '-', '-', '-', '✔', '✔'],
      [strings.t('exclusive_customer_service'), '-', '-', '-', '-', '✔', '✔'],
      [strings.t('free_travel'), '-', '-', '-', '-', '-', '✔'],
    ],
  }
  useEffect(() => {
    console.log('lang', storageData.get_selected_language())

    setItem(props.route.params)
    getTopupMethod()
  }, [])

  const _getQRTopUpStatus = async () => {
    await bankAPICaller
      .getQRTopup(MyData.authToken)
      .then((res) => {
        setQRTopUPDisabled(false)
      })
      .catch((err) => {
        if (err?.response?.status == 400) {
          setQRTopUPDisabled(true)
        }
      })
  }

  const _onCheckTopUp = async (product, coupon_code) => {
    setModalIsVisible(false)
    await walletAPICaller
      .onCheckTopUp(MyData.authToken)
      .then(async (res) => {
        if (res.status == 200) {
          await _getQRTopUpStatus()
          _onRedeemCoupon(product, coupon_code)
        } else if (res.status == 201) {
          const data = res?.data?.response?.info
          if (data?.wallet_country == '1') MyData.wallet_country = 'MYR'
          else if (data?.wallet_country == '2') MyData.wallet_country = 'THB'
          props.navigation.navigate('TopUpDetailsScreen', {
            bankList: [],
            selectedTopUpDetails: res.data.response.info,
            onGoBack: (data) => {},
          })
        }
      })
      .catch((err) => {
        console.log('@CHECKTOPUPERROR', err.message)
        if (err?.response?.status == '514') {
        } else {
          MyToast.popToast(err.response.data.msg)
        }
      })
  }

  const getTopupMethod = async () => {
    await walletAPICaller
      .getTopupMethods(MyData.authToken)
      .then((res) => {
        setTopUpMethods(res.response.list)
      })
      .catch((err) => {
        if (err.response.status == 514) {
        } else {
          MyToast.popToast(err.response.data.msg)
        }
      })
  }

  const onBackPressed = () => {
    props.navigation.goBack()
  }

  const _onRedeemCoupon = async (product, coupon_code) => {
    setModalIsVisible(false)

    await APICaller.onRedeemCoupon(
      MyData.authToken,
      coupon_code,
      product?.product_id
    )
      .then((res) => {
        console.log('@REDEEM RES', res)
        MyData.coupon_code = coupon_code
        setShowTopUpModal(true)
      })
      .catch((e) => {
        console.log(e.message)
        MyToast.popToast('Error: ' + e.message)
      })
  }

  const _onPressInstantTopUp = () => {
    setShowTopUpModal(false)
    props.navigation.navigate('InstantTopUp', {
      backToRedeem: true,
      onGoBack: (data) => {},
    })
  }

  const _onPressUsdtTopUp = () => {
    setShowTopUpModal(false)
    props.navigation.navigate('UsdtTopUp', {
      backToRedeem: true,
      onGoBack: (data) => {},
    })
  }

  const _onPressBankTopUp = () => {
    setShowTopUpModal(false)
    props.navigation.navigate('TopUp', {
      backToRedeem: true,
      onGoBack: (data) => {},
    })
  }

  const _onPressQRTopUp = () => {
    setShowTopUpModal(false)
    props.navigation.navigate('QRTopup', {
      backToRedeem: true,
      onGoBack: (data) => {},
    })
  }

  const renderTable1 = () => {
    const { Table, Row, Rows } = require('react-native-table-component')
    const selectedTableData =
      MyData.country_code == 'THB' ? tableData_EN : tableData_myr_EN
    return (
      <Table borderStyle={{ borderWidth: 2, borderColor: '#c8e1ff' }}>
        <Row
          data={tableData.tableHeader}
          textStyle={{
            color: 'white',
            fontWeight: 'bold',
            textAlign: 'center',
          }}
        />
        <Rows
          data={selectedTableData.tableData}
          textStyle={{ color: 'white', textAlign: 'center' }}
        />
      </Table>
    )
  }

  const renderTable2 = () => {
    const { Table, Row, Rows } = require('react-native-table-component')
    const selectedTableData =
      MyData.country_code == 'THB' ? tableData2_EN : tableData2_myr_EN
    return (
      <Table borderStyle={{ borderWidth: 2, borderColor: '#c8e1ff' }}>
        <Row
          data={tableData2.tableHeader}
          textStyle={{
            color: 'white',
            fontWeight: 'bold',
            textAlign: 'center',
          }}
        />
        <Rows
          data={selectedTableData.tableData}
          textStyle={{ color: 'white', textAlign: 'center' }}
        />
      </Table>
    )
  }

  const renderTable3 = () => {
    const { Table, Row, Rows } = require('react-native-table-component')
    const selectedTableData =
      MyData.country_code == 'THB' ? tableData3_EN : tableData3_myr_EN
    return (
      <Table borderStyle={{ borderWidth: 2, borderColor: '#c8e1ff' }}>
        <Row
          data={tableData3.tableHeader}
          textStyle={{
            color: 'white',
            fontWeight: 'bold',
            textAlign: 'center',
          }}
        />
        <Rows
          data={selectedTableData.tableData}
          textStyle={{ color: 'white', textAlign: 'center' }}
        />
      </Table>
    )
  }

  return (
    <MainFlowHeaderContainerNoFeedback
      title={strings.t('vip_coupon')}
      onBackPress={() => onBackPressed()}
      bgImage={require('../../Resources/Images/Coupon/coupon_bg.png')}
    >
      <ScrollView contentContainerStyle={styles.itemDetailsContainer}>
        <Text style={styles.titleText}>{strings.t('luckytownviplabel')}</Text>
        <View style={{ height: HeightDimen(10) }} />
        <Text
          style={{
            ...styles.subTitleText,
            color: 'rgb(241,160,60)',
            marginTop: HeightDimen(4),
          }}
        >
          {strings.t('vip_activity_label') + ':'}
        </Text>
        <Text style={{ ...styles.subTitleText, marginTop: HeightDimen(4) }}>
          {MyData.country_code == 'THB'
            ? strings.t('vip_activity_1')
            : strings.t('vip_myr_activity_1')}
        </Text>

        <Text style={{ ...styles.subTitleText, marginTop: HeightDimen(4) }}>
          {MyData.country_code == 'THB'
            ? strings.t('vip_activity_2')
            : strings.t('vip_myr_activity_2')}
        </Text>
        {/* CHART HERE */}
        <View style={{ height: HeightDimen(10) }} />
        <Text
          style={{
            ...styles.subTitleText,
            color: 'rgb(241,160,60)',
            marginTop: HeightDimen(4),
          }}
        >
          {strings.t('vip_promoted_conditions') + ':'}
        </Text>

        {renderTable1()}
        {renderTable2()}
        <View style={{ height: HeightDimen(10) }} />
        <Text
          style={{
            ...styles.subTitleText,
            color: 'rgb(241,160,60)',
            marginTop: HeightDimen(4),
          }}
        >
          {strings.t('vip_member_bonus') + ':'}
        </Text>
        {renderTable3()}
        <View style={{ height: HeightDimen(10) }} />
        <Text
          style={{
            ...styles.subTitleText,
            color: 'red',
            marginTop: HeightDimen(4),
          }}
        >
          {strings.t('vip_toa')}
        </Text>
        {MyData.country_code == 'THB' ? (
          <>
            <Text style={{ ...styles.subTitleText, marginTop: HeightDimen(4) }}>
              {strings.t('vip_policy_1')}
            </Text>
            <Text style={{ ...styles.subTitleText, marginTop: HeightDimen(4) }}>
              {strings.t('vip_policy_2')}
            </Text>
            <Text style={{ ...styles.subTitleText, marginTop: HeightDimen(4) }}>
              {strings.t('vip_policy_3')}
            </Text>
            <Text style={{ ...styles.subTitleText, marginTop: HeightDimen(4) }}>
              {strings.t('vip_policy_4')}
            </Text>
            <Text style={{ ...styles.subTitleText, marginTop: HeightDimen(4) }}>
              {strings.t('vip_policy_5')}
            </Text>
            <Text style={{ ...styles.subTitleText, marginTop: HeightDimen(4) }}>
              {strings.t('vip_policy_6')}
            </Text>
            <Text style={{ ...styles.subTitleText, marginTop: HeightDimen(4) }}>
              {strings.t('vip_policy_7')}
            </Text>
            <Text style={{ ...styles.subTitleText, marginTop: HeightDimen(4) }}>
              {strings.t('vip_policy_8')}
            </Text>
            <Text style={{ ...styles.subTitleText, marginTop: HeightDimen(4) }}>
              {strings.t('vip_policy_9')}
            </Text>
            <Text style={{ ...styles.subTitleText, marginTop: HeightDimen(4) }}>
              {strings.t('vip_policy_10')}
            </Text>
          </>
        ) : (
          <Text style={{ ...styles.subTitleText, marginTop: HeightDimen(4) }}>
            {strings.t('vip_myr_policy')}
          </Text>
        )}
        <View style={{ height: HeightDimen(24) }} />
        {/* DISABLED REDEEM BUTTON */}
        {/* <TouchableOpacity style={{
          alignSelf: 'center',
          padding: HeightDimen(12),
          backgroundColor: SecondaryColor,
          borderRadius: HeightDimen(6)
        }}
          onPress={() => setModalIsVisible(true)}
        >
          <Text style={{ ...styles.subTitleText }}>{strings.t('redeem_coupon}</Text>
        </TouchableOpacity> */}
        {/* EXTRA SPACE */}
        <View style={{ height: HeightDimen(150) }} />
      </ScrollView>
      <ModalGamesList
        onPress={_onCheckTopUp}
        data={item?.couponAppliedProducts}
        coupon_code={item?.coupon_code}
        isVisible={isModalVisible}
        onCloseModal={() => setModalIsVisible(false)}
      />
      {topUpMethods?.length > 0 && (
        <TopUpModal
          isShow={showTopupModal}
          noWithraw
          topupList={topUpMethods}
          isDisabledQRTopup={isQRTopUpDisabled}
          onPressInstantTopUp={() => _onPressInstantTopUp()}
          onPressBankTopUp={() => _onPressBankTopUp()}
          onPressUsdtTopUp={() => _onPressUsdtTopUp()}
          onBackPress={() => setShowTopUpModal(false)}
          onPressQRTopup={() => _onPressQRTopUp()}
        />
      )}
    </MainFlowHeaderContainerNoFeedback>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: HeightDimen(8),
  },
  couponContainer: {
    minHeight: HeightDimen(80),
    padding: HeightDimen(8),
    justifyContent: 'space-between',
    flexDirection: 'row',
    borderWidth: 1,
    borderColor: SecondaryColor,
    marginBottom: HeightDimen(12),
  },
  itemDetailsContainer: {
    padding: HeightDimen(20),
    flexGrow: 1,
    // alignItems: 'center'
  },
  titleText: {
    fontWeight: 'bold',
    color: `rgb(185, 146, 48)`,
    fontSize: fontDimen(20),
  },
  subTitleText: {
    color: 'white',
    fontSize: fontDimen(16),
  },
  textLabel: {
    color: 'white',
    fontWeight: '500',
    marginBottom: 8,
  },
  textValue: {
    color: 'white',
    fontWeight: 'bold',
  },
  activeTab: {
    backgroundColor: `rgb(122, 122, 122)`,
    height: HeightDimen(30),
    width: WidthDimen(80),
    alignItems: 'center',
    justifyContent: 'center',
  },
  inActiveTab: {
    backgroundColor: `rgb(79, 79, 79)`,
    height: HeightDimen(30),
    width: WidthDimen(80),
    alignItems: 'center',
    justifyContent: 'center',
  },
})

export default CouponVipScreen
