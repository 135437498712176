import React, { Component } from 'react'
import {
  View,
  FlatList,
  SectionList,
  ActivityIndicator,
  Modal,
  Image,
  TouchableOpacity,
} from 'react-native'
import notificationCaller from '../../API/NotificationCaller'
import Text from '../../Component/Text/MyText'
import MyToast from '../../Component/Toast/MyToast'
import MyData from '../../Data/MyData'
import { strings } from '../../Language/Language'
import { fontDimen, HeightDimen } from '../../UI/Dimensions'
import { MainColor, SecondaryColor } from '../../UI/Color'
import { NotificationCard } from '../../Component/Card/NotificationCard'
import { MainFlowHeaderContainerNoFeedback } from '../../Component/Header/MainFlowHeader'
import { CommonActions, StackActions } from '@react-navigation/native'

class NotificationScreen extends Component {
  state = {
    notificationList: [],
    isLoading: false,
  }

  async componentDidMount() {
    this.onGetNotificationList()
  }

  handleFormatResponse = (resp) => {
    //return resp.notifications;
    let newArr = []
    Object.keys(resp.notifications).forEach((i) => {
      const obj = {
        date: i,
        data: resp.notifications[i],
      }
      newArr.push(obj)
    })
    return newArr
  }

  onGetNotificationList = async () => {
    this.setState({ isLoading: true })
    await notificationCaller
      .getNotifications(MyData.authToken, true)
      .then((res) => {
        let newArr = []
        const resp = res.response

        MyData.setNotifCount(res.response?.totalUnreadNotifications)
        const formatResp = this.handleFormatResponse(resp)

        this.setState({
          isLoading: false,
          notificationList: formatResp,
        })
        // this.onNotificationViewed();
      })
      .catch((err) => {
        if (err.response.status == 514) {
          this.props.expiredToken_logout()
        } else {
          MyToast.popToast(err.response.data.msg)
        }
      })
      .finally(() => {
        this.setState({ isLoading: false })
      })
  }

  onNotificationViewed = async () => {
    this.setState({ isLoading: true })
    await notificationCaller
      .markNotificationAsRead(MyData.authToken)
      .then(async (res) => {
        await this.onGetNotificationList()
      })
      .catch((err) => {})
      .finally(() => {
        this.setState({ isLoading: false })
      })
  }

  onBackPressed = () => {
    this.props.route.params.onGoBack()
    this.props.navigation.goBack()
  }

  renderNotifList = () => {
    return (
      <SectionList
        onRefresh={() => this.onGetNotificationList()}
        refreshing={this.state.isLoading}
        sections={this.state.notificationList}
        ListEmptyComponent={() => {
          return (
            <View
              style={{
                alignSelf: 'center',
              }}
            >
              <Text
                style={{
                  fontSize: fontDimen(12),
                }}
              >
                {strings.t('empty_notifications')}
              </Text>
            </View>
          )
        }}
        contentContainerStyle={
          {
            // paddingHorizontal: HeightDimen(20),
          }
        }
        ItemSeparatorComponent={() => (
          <View
            style={{
              width: '95%',
              backgroundColor: SecondaryColor,
              alignSelf: 'center',
              height: 1,
            }}
          />
        )}
        renderSectionHeader={({ section: { date } }) => (
          <NotificationCard cardSection title={date} />
        )}
        renderItem={({ item, index }) => (
          <NotificationCard
            newDesign
            item={item}
            onPress={() => {
              this.props.navigation.navigate('NotificationDetail', {
                notiKey: item.notification_unique_key,
                onGoBack: (data) => {
                  this.onGetNotificationList()
                },
              })
            }}
            title={item.notification_title}
            message={item.notification_message}
            data_time={item.created_datetime}
            image_url={item?.notification_image_url}
            isViewed={item.viewed}
          />
        )}
        keyExtractor={(item) => String(item.notification_id)}
      />
    )
  }

  render() {
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: 'white',
        }}
      >
        <MainFlowHeaderContainerNoFeedback
          title={strings.t('notification')}
          onBackPress={() => this.onBackPressed()}
          toShowText={'Mark All Read'}
          toShow
          toShowPress={() => this.onNotificationViewed()}
        >
          <View
            style={{
              flex: 1,
            }}
          >
            {this.state.isLoading ? (
              <ActivityIndicator
                size="small"
                color={MainColor}
                style={{ marginTop: HeightDimen(20) }}
              />
            ) : (
              this.renderNotifList()
            )}
          </View>
        </MainFlowHeaderContainerNoFeedback>
      </View>
    )
  }
}

export default NotificationScreen
