import React, { Component } from 'react'
import { ActivityIndicator, View, NativeModules } from 'react-native'
import { HeightDimen } from '../../UI/Dimensions'
// import { RNCamera } from 'react-native-camera'
import { MainFlowHeaderContainer } from '../../Component/Header/MainFlowHeader'
import { strings } from '../../Language/Language'
import MyToast from '../../Component/Toast/MyToast'
// import { launchImageLibrary } from 'react-native-image-picker'

import aPICaller from '../../API/APICaller'
import { SecondaryColor } from '../../UI/Color'
import * as ImagePicker from 'expo-image-picker'

import storageData from '../../Data/StorageData'
import { Camera, CameraType } from 'expo-camera'
import { BarCodeScanner } from 'expo-barcode-scanner'
import { isMobile } from '../../Utils/Common'
import MyData from '../../Data/MyData'
import QrScanner from 'qr-scanner'

class TransferCameraScreen extends Component {
  state = {
    isLoading: false,
    isFocused: false,
    isMobile: isMobile ? true : false,
  }
  componentDidMount() {
    this.unsubscribeFocus = this.props.navigation.addListener('focus', () => {
      // this.setState({ isFocused: true });
      storageData.QRScanSuccess = true
    })
    this.unsubscribeBlur = this.props.navigation.addListener('blur', () => {
      // this.setState({ isFocused: false });
      storageData.QRScanSuccess = false
    })
  }
  onBackPressed = () => {
    this.props.navigation.goBack()
  }

  componentWillUnmount() {
    this.unsubscribeFocus()
    this.unsubscribeBlur()
  }

  refresh = (data) => {
    this.props?.route?.params?.onGoBack()
    this.props?.navigation?.goBack()
  }

  onPress_pickFromLibrary = async () => {
    const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync()
    if (status === 'granted') {
      let result = await ImagePicker.launchImageLibraryAsync({
        allowsEditing: false,
        quality: 1,
        base64: false,
      })
      if (!result.canceled) {
        this.read_qr_code(result?.assets[0]?.uri)
      }
    }
  }

  read_qr_code = async (image_path) => {
    this.setState({ isLoading: true })
    // if the user successfully picks an image then we check if the image has a QR code
    if (image_path) {
      try {
        let dataNeeded = await QrScanner.scanImage(image_path)

        if (dataNeeded) {
          var temp = dataNeeded
          var index = temp.indexOf('ref')
          if (index < 0) {
            temp = await aPICaller.onGetDomainURL(temp)
            index = temp.indexOf('ref')
          }
          if (index >= 0) {
            var userName = temp.substring(index + 4)

            if (storageData.QRScanSuccess) {
              this.props.navigation.replace('TransferAuto', {
                value: userName,
                onGoBack: (data) => {
                  this.refresh(data)
                },
              })
            }
          } else {
            MyToast.popToast(strings.t('qr_code_error'))
          }
        } else {
          MyToast.popToast(strings.t('qr_code_error'))
        }
      } catch (error) {
        // if there this no QR code
        MyToast.popToast(strings.t('qr_code_error'))
      } finally {
        this.setState({ isLoading: false })
      }
    }
  }

  onBarCodeRead = async (e) => {
    debugger
    this.setState({ isLoading: true })
    if (e.data != null && e.data != '{') {
      var temp = e.data
      var index = temp.indexOf('ref')

      if (index < 0) {
        temp = await aPICaller.onGetDomainURL(temp)
        index = temp.indexOf('ref')
      }
      if (index >= 0) {
        var userName = temp.substring(index + 4)

        if (storageData.QRScanSuccess) {
          this.props.navigation.replace('TransferAuto', {
            value: userName,
            onGoBack: (data) => {
              this.refresh(data)
            },
          })
        }
      } else {
      }
    }
    this.setState({ isLoading: false })
  }

  render() {
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: 'white',
        }}
      >
        <MainFlowHeaderContainer
          title={strings.t('transfer')}
          onBackPress={() => this.onBackPressed()}
          toShow={true}
          toShowText={strings.t('photo_library')}
          toShowPress={() => this.onPress_pickFromLibrary()}
        >
          <View
            style={{
              flex: 1,
              justifyContent: 'flex-end',
            }}
          >
            <View
              style={{
                flex: 1,

                overflow: 'hidden',
              }}
            >
              <Camera
                ref={(ref) => {
                  this.camera = ref
                }}
                style={{
                  flex: 1,
                }}
                type={this.state.isMobile ? CameraType.back : CameraType.front}
                captureAudio={false}
                // onGoogleVisionBarcodesDetected={({ barcodes }) => {
                //     if(barcodes[0].data != null && barcodes[0].data[0] != '{') {
                //         //console.log(barcodes[0].data);

                //         var temp = barcodes[0].data;
                //         var index = temp.indexOf('ref');
                //         if(index >= 0) {
                //             var userName = temp.substring(index + 4);
                //             this.props.navigation.navigate('TransferAuto', {
                //                 value: userName,
                //                 onGoBack: (data) => {
                //                     this.refresh(data)
                //                 }
                //             });
                //         }
                //     }

                // }}
                barCodeScannerSettings={{
                  barCodeTypes: [BarCodeScanner.Constants.BarCodeType.qr],
                }}
                onBarCodeScanned={this.onBarCodeRead}
              />
              <View
                style={{
                  width: '100%',
                  height: '100%',
                  position: 'absolute',
                }}
              >
                <View
                  style={{
                    flex: 1,
                    backgroundColor: 'rgba(0,0,0,0.5)',
                  }}
                ></View>
                <View
                  style={{
                    width: '100%',
                    flexDirection: 'row',
                  }}
                >
                  <View
                    style={{
                      backgroundColor: 'rgba(0,0,0,0.5)',
                      flex: 1,
                    }}
                  ></View>
                  <View
                    style={{
                      width: 'auto',
                      height: HeightDimen(250),
                      aspectRatio: 1 / 1,
                      borderWidth: HeightDimen(5),
                      borderColor: 'white',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {this.state.isLoading && (
                      <ActivityIndicator size="large" color={SecondaryColor} />
                    )}
                  </View>
                  <View
                    style={{
                      backgroundColor: 'rgba(0,0,0,0.5)',
                      flex: 1,
                    }}
                  ></View>
                </View>
                <View
                  style={{
                    flex: 1,
                    backgroundColor: 'rgba(0,0,0,0.5)',
                  }}
                ></View>
              </View>
            </View>
          </View>
        </MainFlowHeaderContainer>
      </View>
    )
  }
}

export default TransferCameraScreen
