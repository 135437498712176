import React, { Component } from 'react'
import { View, FlatList } from 'react-native'
import Text from '../../../Component/Text/MyText'
import { HeightDimen, fontDimen } from '../../../UI/Dimensions'
import { MainFlowHeaderContainerNoFeedback } from '../../../Component/Header/MainFlowHeader'
import { strings } from '../../../Language/Language'
import { WalletDetailsCard } from '../../../Component/Card/History/WalletDetailsCard'

class AllWalletTransactionAdditionalDetailScreen extends Component {
  state = {
    data: [],
  }

  componentDidMount() {
    let data = this.props.route.params.data
    console.log(data)
    this.setState({
      data: data,
    })
  }

  onBackPressed = () => {
    this.props.navigation.goBack()
  }

  render() {
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: 'white',
        }}
      >
        <MainFlowHeaderContainerNoFeedback
          title={strings.t('details')}
          onBackPress={() => this.onBackPressed()}
        >
          <View
            style={{
              flex: 1,
              paddingTop: HeightDimen(30),
            }}
          >
            <View
              style={{
                flexDirection: 'row',
                backgroundColor: '#EFF2F4',
                height: HeightDimen(50),
                alignItems: 'center',
                // marginTop: -HeightDimen(30),
              }}
            >
              <Text
                style={{
                  fontSize: fontDimen(13),
                  flex: 1,
                  textAlign: 'center',
                }}
              >
                {strings.t('date')}
              </Text>
              <Text
                style={{
                  fontSize: fontDimen(13),
                  flex: 1,
                  textAlign: 'center',
                }}
              >
                {strings.t('LID')}
              </Text>
              <Text
                style={{
                  fontSize: fontDimen(13),
                  flex: 1,
                  textAlign: 'center',
                }}
              >
                {strings.t('product')}
              </Text>
              <Text
                style={{
                  fontSize: fontDimen(13),
                  flex: 1,
                  textAlign: 'center',
                }}
              >
                {strings.t('total_bet')}
              </Text>
              <Text
                style={{
                  fontSize: fontDimen(13),
                  flex: 1,
                  textAlign: 'center',
                }}
              >
                {strings.t('reward')}
              </Text>
            </View>
            <FlatList
              numColumns={1}
              data={this.state.data}
              renderItem={({ item, data }) => <WalletDetailsCard item={item} />}
              keyExtractor={(item) => String(item.commission_date)}
            />
          </View>
        </MainFlowHeaderContainerNoFeedback>
      </View>
    )
  }
}

export default AllWalletTransactionAdditionalDetailScreen
