import React, { Component } from 'react'
import { View, ActivityIndicator } from 'react-native'
import { MainFlowHeaderContainerNoFeedback } from '../../Component/Header/MainFlowHeader'
import { strings } from '../../Language/Language'

class AnouncementDetailScreen extends Component {
  onBackPressed = () => {
    this.props.route.params.onGoBack()
    this.props.navigation.goBack()
  }
  render() {
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: 'white',
        }}
      >
        <MainFlowHeaderContainerNoFeedback
          title={strings.t('anouncement')}
          onBackPress={() => this.onBackPressed()}
        >
          <View style={{ flex: 1 }}>
            <iframe
              src={`${this.props.route.params.Url}`}
              height={'100%'}
              width={'100%'}
              frameBorder="0"
            />
          </View>
        </MainFlowHeaderContainerNoFeedback>
      </View>
    )
  }
}

export default AnouncementDetailScreen
