import React, { Component } from "react";
import { View, Image, TouchableOpacity, TextInput } from "react-native";
import Text from "../Text/MyText";
import { strings } from "../../Language/Language";

import { MainColor, PrimaryColor, SecondaryColor } from "../../UI/Color";
import { HeightDimen, fontDimen } from "../../UI/Dimensions";
import MyData from "../../Data/MyData";
import storageData from "../../Data/StorageData";

class MainFooter extends Component {
  state = {
    image_size: 30,
    selected: 1,
  };

  onSelect = (selection) => {
    if (selection === 3) {
      this.props.onUpdateSelection(selection);
    } else {
      if (this.state.selected !== selection) {
        this.props.onUpdateSelection(selection);
        this.setState({ selected: selection });
      }
    }
  };

  render() {
    const {} = this.props;

    return (
      <View
        style={{
          width: "100%",
          flexDirection: "row",

          backgroundColor: "white",
          shadowColor: "#000",
          shadowOffset: {
            width: 2,
            height: 2,
          },
          shadowOpacity: 0.5,
          shadowRadius: 3.84,

          elevation: 9,
          borderTopWidth: HeightDimen(5),
          borderTopColor: PrimaryColor,
        }}
      >
        <TouchableOpacity
          onPress={() => this.onSelect(1)}
          style={{
            flex: 1,
            alignItems: "center",
            paddingTop: HeightDimen(10),
            paddingBottom: HeightDimen(10),
          }}
        >
          <Image
            source={
              this.state.selected === 1
                ? require("../../Resources/Images/Footer/icon_1_sel.gif")
                : require("../../Resources/Images/Footer/icon_1.png")
            }
            style={{
              width: "auto",
              height: HeightDimen(this.state.image_size),
              aspectRatio: 1 / 1,
              // resizeMode: "contain",
              //  tintColor: this.state.selected === 1 ? SecondaryColor : MainColor,
            }}
          />
          <Text
            style={{
              fontSize: fontDimen(11),
              color: this.state.selected === 1 ? SecondaryColor : MainColor,
            }}
          >
            {strings.t("footer_home")}
          </Text>
        </TouchableOpacity>

        {(MyData.directLine == "0" || storageData.saved_authSession == "") && (
          <TouchableOpacity
            onPress={() => this.onSelect(2)}
            style={{
              flex: 1,
              alignItems: "center",
              paddingTop: HeightDimen(10),
              paddingBottom: HeightDimen(10),
            }}
          >
            <Image
              source={
                this.state.selected == 2
                  ? require("../../Resources/Images/Footer/icon_3_sel.gif")
                  : require("../../Resources/Images/Footer/icon_3.png")
              }
              style={{
                width: "auto",
                height: HeightDimen(this.state.image_size),
                aspectRatio: 1 / 1,
              }}
            />
            <Text
              style={{
                fontSize: fontDimen(11),
                color: this.state.selected === 2 ? SecondaryColor : MainColor,
              }}
            >
              {strings.t("footer_contact")}
            </Text>
          </TouchableOpacity>
        )}
        {(MyData.directLine == "0" || storageData.saved_authSession == "") && (
          <View
            style={{
              flex: 1,
              alignItems: "center",
            }}
          >
            <TouchableOpacity
              onPress={() => this.onSelect(3)}
              style={{
                width: "auto",
                height: HeightDimen(80),
                aspectRatio: 1 / 1,
                overflow: "hidden",

                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
                bottom: HeightDimen(20),
                borderWidth: HeightDimen(3),
                borderColor: SecondaryColor,
                borderRadius: 99999,
              }}
            >
              <Image
                source={require("../../Resources/Images/icon_latest.png")}
                style={{
                  width: "auto",
                  height: HeightDimen(80),

                  aspectRatio: 1 / 1,
                  resizeMode: "contain",
                }}
              />
            </TouchableOpacity>
          </View>
        )}
        <TouchableOpacity
          onPress={() => this.onSelect(4)}
          style={{
            flex: 1,
            alignItems: "center",
            paddingTop: HeightDimen(10),
            paddingBottom: HeightDimen(10),
          }}
        >
          <Image
            source={
              this.state.selected == 4
                ? require("../../Resources/Images/Footer/promotion_sel.gif")
                : require("../../Resources/Images/Footer/promotion.png")
            }
            style={{
              width: "auto",
              height: HeightDimen(this.state.image_size),
              aspectRatio: 1 / 1,
              // resizeMode: "contain",
            }}
          />
          <Text
            style={{
              fontSize: fontDimen(11),
              color: this.state.selected === 4 ? SecondaryColor : MainColor,
            }}
          >
            {strings.t("promotion")}
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => this.onSelect(5)}
          style={{
            flex: 1,
            alignItems: "center",
            paddingTop: HeightDimen(10),
            paddingBottom: HeightDimen(10),
          }}
        >
          <Image
            source={
              this.state.selected == 5
                ? require("../../Resources/Images/Footer/icon_4_sel.gif")
                : require("../../Resources/Images/Footer/icon_4.png")
            }
            style={{
              width: "auto",
              height: HeightDimen(this.state.image_size),
              aspectRatio: 1 / 1,
              /// tintColor: this.state.selected === 5 ? SecondaryColor : MainColor,
            }}
          />
          <Text
            style={{
              fontSize: fontDimen(11),
              color: this.state.selected === 5 ? SecondaryColor : MainColor,
            }}
          >
            {strings.t("footer_me")}
          </Text>
        </TouchableOpacity>
      </View>
    );
  }
}

export default MainFooter;
