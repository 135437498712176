import axios from 'axios'
import { apiUrls } from './APIurl'
import storageData from '../Data/StorageData'
import MyData from '../Data/MyData'

class InterestCaller {
  getInterestAccount = async (authToken) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.getInterestAccount}`,
          {
            data: {
              language: storageData.get_selected_language(),
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  createInterestAccount = async (authToken, depAmount) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.openNewInterestAcctoun}`,
          {
            data: {
              depositAmount: depAmount,
              language: storageData.get_selected_language(),
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  cancelInterestAccount = async (authToken, interestKey) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.cancelInterestAccount}`,
          {
            data: {
              interestAccountKey: interestKey,
              language: storageData.get_selected_language(),
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  partialWithdrawInterestAccount = async (authToken, interestKey, amount) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.partialWithdrawInterestAccount}`,
          {
            data: {
              interestAccountKey: interestKey,
              withdrawAmount: amount,
              language: storageData.get_selected_language(),
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  getInterestHistory = async (authToken, fromDates, toDates) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.getInterestHistory}`,
          {
            data: {
              fromDate: fromDates,
              toDate: toDates,
              language: storageData.get_selected_language(),
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}

const interestCaller = new InterestCaller()
export default interestCaller
