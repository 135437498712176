import React, { Component } from 'react'
import {
  View,
  Image,
  TouchableOpacity,
  TextInput,
  ActivityIndicator,
} from 'react-native'
import Text from '../Text/MyText'
import { FontMainColor, MainColor, SecondaryColor } from '../../UI/Color'
import { WidthDimen, HeightDimen, fontDimen } from '../../UI/Dimensions'
import { CounterButton } from '../Button/CounterButton'
import MIcon from 'react-native-vector-icons/MaterialIcons'

const NormalTextInput = (props) => {
  const {
    placeholder,
    onChangeText,
    type,
    marginBottom,
    hideImage,
    isEmail,
    isNumber,
    value,
    isEdit,
    onOtpPress,
    isCounter,
    isValidation,
    iconSrc,
    iconPress,
    btnLabel,
    isLoading,
    isVerified,
    isError,
    secure,
    textAlign,
    title,
    btnPress,
  } = props

  return (
    <View
      style={{
        width: '100%',
        height: HeightDimen(50),
        backgroundColor: '#EFF2F4',
        borderRadius: HeightDimen(10),
        flexDirection: 'row',
        alignItems: 'center',
        padding: HeightDimen(15),
        marginBottom: marginBottom || 0,
      }}
    >
      {hideImage ? (
        <></>
      ) : (
        <Image
          source={
            type === 'username'
              ? require('../../Resources/Images/icon_user.png')
              : type === 'phone'
              ? require('../../Resources/Images/icon_phone.png')
              : require('../../Resources/Images/icon_email.png')
          }
          style={{
            width: 'auto',
            height: HeightDimen(15),
            aspectRatio: 1 / 1,
            resizeMode: 'contain',
            marginRight: WidthDimen(10),
          }}
        />
      )}
      {title && (
        <Text
          style={{
            fontSize: fontDimen(15),
            color: MainColor,
            marginRight: HeightDimen(10),
          }}
        >
          {title}
        </Text>
      )}
      <View
        style={{
          flex: 1,
        }}
      >
        <TextInput
          editable={isEdit}
          placeholder={placeholder}
          onChangeText={onChangeText}
          value={value}
          placeholderTextColor={'#C7C7CD'}
          textContentType={
            isEmail ? 'emailAddress' : isNumber ? 'telephoneNumber' : 'none'
          }
          keyboardType={
            isEmail ? 'email-address' : isNumber ? 'phone-pad' : 'default'
          }
          style={{
            fontSize: textAlign ? fontDimen(17) : fontDimen(15),
            // height: Platform.OS == 'android' ? HeightDimen(50) : HeightDimen(30)
            color: FontMainColor,
            height: HeightDimen(50),
            textAlign: textAlign,
            letterSpacing: textAlign ? 10 : 0,
            outlineStyle: 'none',
          }}
          secureTextEntry={secure}
        />
      </View>
      {iconSrc && (
        <TouchableOpacity onPress={iconPress}>
          <Image
            source={iconSrc}
            style={{
              width: 'auto',
              height: HeightDimen(25),
              aspectRatio: 1 / 1,
              resizeMode: 'contain',
              marginRight: HeightDimen(10),

              tintColor: SecondaryColor,
            }}
          />
        </TouchableOpacity>
      )}
      {isVerified && (
        <View
          style={{
            marginRight: 5,
            height: HeightDimen(50),
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <MIcon name={'check-circle-outline'} size={20} color={'green'} />
        </View>
      )}
      {isError && (
        <View
          style={{
            marginRight: 5,
            height: HeightDimen(50),
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <MIcon name={'close'} size={20} color={'red'} />
        </View>
      )}
      {btnLabel ? (
        !isLoading ? (
          <TouchableOpacity
            style={{
              backgroundColor: SecondaryColor,
              borderRadius: 5,
              paddingHorizontal: 6,
              justifyContent: 'center',
              height: HeightDimen(30),
              alignItems: 'center',
            }}
            onPress={btnPress}
          >
            <Text style={{ color: MainColor, fontSize: fontDimen(11) }}>
              {btnLabel}
            </Text>
          </TouchableOpacity>
        ) : (
          <ActivityIndicator size={15} color={MainColor} />
        )
      ) : (
        <></>
      )}
      {isCounter && (
        <CounterButton onPress={onOtpPress} isValidation={isValidation} />
      )}
    </View>
  )
}

export { NormalTextInput }
