import React, { Component } from 'react'
import { View, Image, Dimensions } from 'react-native'

import { HeightDimen } from '../../UI/Dimensions'

import { LoginFlowHeaderContainer } from '../../Component/Header/LoginFlowHeader'

import { TextButton } from '../../Component/Button/TextButton'

import { strings } from '../../Language/Language'

class SuccChangeLoginPassword extends Component {
  onBackPressed = () => {
    this.props.route.params && this.props.route.params.onGoBack()
    this.props.navigation.goBack()
  }

  render() {
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: 'white',
        }}
      >
        <LoginFlowHeaderContainer
          onBackPress={() => this.onBackPressed()}
          mainText={strings.t('password_changed')}
          subText={strings.t('congratulations_changed_password')}
        >
          <View
            style={{
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Image
              source={require('../../Resources/Images/succ_tick.png')}
              style={{
                width: 'auto',
                height: HeightDimen(200),
                aspectRatio: 496 / 425,
                resizeMode: 'contain',
              }}
            />
          </View>
          <View
            style={{
              flex: 0.3,
              alignItems: 'center',
              paddingLeft: HeightDimen(30),
              paddingRight: HeightDimen(30),
            }}
          >
            <TextButton
              onPress={() => this.onBackPressed()}
              label={strings.t('back')}
              size={5}
            />
          </View>
        </LoginFlowHeaderContainer>
      </View>
    )
  }
}

export default SuccChangeLoginPassword
