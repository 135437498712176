import React, { Component } from 'react'
import {
  View,
  FlatList,
  TouchableOpacity,
  ActivityIndicator,
  Image,
} from 'react-native'
import Text from '../../Component/Text/MyText'
import { MainColor, SecondaryColor } from '../../UI/Color'
import { HeightDimen, fontDimen } from '../../UI/Dimensions'
import { MainFlowHeaderContainerNoFeedback } from '../../Component/Header/NewDesign/MainFlowHeader'
import MyToast from '../../Component/Toast/MyToast'
import { strings } from '../../Language/Language'
import { DateModal } from '../../Component/Modal/DateModal'
import productAPICaller from '../../API/ProductAPICaller'
import MyData from '../../Data/MyData'
import { AllWalletHistoryCard } from '../../Component/Card/History/NewDesign/AllWalletHistoryCard'
import { Line } from '../../Component/Line/Line'
import { numberWithCommas } from '../../Utils/Common'
import { WebDatePicker } from '../../Component/Modal/WebDatePicker'

class GameDetailsTransactionScreen extends Component {
  state = {
    selectedDate: strings.t('pick_date'),
    showDatePicker: false,

    isGetProductLoading: false,
    isLoading: false,

    toShowArray: [],
    product_id: '',
    product_desc: '',
  }

  async componentDidMount() {
    await this.set_date()
    this.get_GameTransaction(
      this.state.selectedDate,
      this.props.route.params.product_id
    )
    this.setState({
      product_id: this.props.route.params.product_id,
      product_desc: this.props.route.params.producy_desc,
    })
  }

  set_date = async () => {
    let dateToday = new Date()
    let day = dateToday.getDate()
    let month = dateToday.getMonth() + 1
    let returnDate =
      dateToday.getFullYear() +
      '-' +
      (month < 10 ? '0' : '') +
      month +
      '-' +
      (day < 10 ? '0' : '') +
      day
    this.setState({ selectedDate: returnDate })
  }

  onBackPressed = () => {
    this.props.navigation.goBack()
  }

  onToggle_datePicker = () => {
    this.setState({ showDatePicker: !this.state.showDatePicker })
  }

  close_date_modal = (date) => {
    this.setState({
      selectedDate: date,
    })
    this.onToggle_datePicker()
    this.get_GameTransaction(date, this.state.product_id)
  }

  get_GameTransaction = async (date, value) => {
    this.setState({ isLoading: true })
    await productAPICaller
      .allGameTransaction(MyData.authToken, date, value)
      .then((res) => {
        console.log(res)
        let tempArr = res.response.transactions
        console.log(tempArr)
        if (tempArr.length == 0) {
          MyToast.popToast(strings.t('no_transaction_for_dates'))
        }
        this.setState({
          isLoading: false,
          toShowArray: tempArr,
        })
      })
      .catch((err) => {
        console.log(err)
        this.setState({ isLoading: false })
        if (err.response.status == 514) {
          this.props.expiredToken_logout()
        } else {
          MyToast.popToast(err.response.data.msg)
        }
      })
  }

  render() {
    return (
      <View style={styles.container}>
        <MainFlowHeaderContainerNoFeedback
          title={
            strings.t('game_transaction') + ' (' + this.state.product_desc + ')'
          }
          onBackPress={() => this.onBackPressed()}
        >
          {this.state.isGetProductLoading ? (
            <View>
              <ActivityIndicator size="small" color={MainColor} />
            </View>
          ) : (
            <View style={styles.innerContainer}>
              {/* <TouchableOpacity
                disabled={this.state.isLoading}
                onPress={() => this.onToggle_datePicker()}
                style={styles.dateContainer}
              >
                <Text style={styles.date}>{strings.t('date') + ': '}</Text>
                <Text style={styles.dateLabel}>{this.state.selectedDate}</Text>
                <Image
                  style={styles.icon}
                  source={require('../../Resources/Images/icon_down.png')}
                />
              </TouchableOpacity> */}
              <View style={styles.dateContainer}>
                <WebDatePicker
                  label={strings.t('pick_date')}
                  data={this.state.selectedDate}
                  setDate={this.close_date_modal}
                />
              </View>

              {this.state.isLoading ? (
                <ActivityIndicator
                  size="small"
                  color={MainColor}
                  style={{
                    marginTop: HeightDimen(20),
                  }}
                />
              ) : (
                <View style={styles.listContainer}>
                  {this.state.toShowArray.length > 0 && (
                    <FlatList
                      numColumns={1}
                      data={this.state.toShowArray}
                      renderItem={({ item, index }) => (
                        <View>
                          <AllWalletHistoryCard
                            disabled
                            url={item.product_image_url}
                            title={
                              item.transaction_type === 'gameIn'
                                ? strings.t('enter_game')
                                : item.transaction_type === 'gameOut'
                                ? strings.t('exit_game')
                                : item.transaction_remarks
                            }
                            date={item.transaction_created_datetime}
                            bet={
                              item.transaction_type == 'gameIn' ||
                              item.transaction_type == 'gameOut'
                                ? item.product_name
                                : `${strings.t('bet')}: ${numberWithCommas(
                                    Number(
                                      item?.game_transaction_valid_stake
                                    ).toFixed(2)
                                  )}`
                            }
                            win={
                              item.transaction_type == 'gameIn' ||
                              item.transaction_type == 'gameOut'
                                ? `${MyData.country_code} ${numberWithCommas(
                                    Number(
                                      item.transaction_wallet_amount
                                    ).toFixed(2)
                                  )}`
                                : `${strings.t('win_')}: ${numberWithCommas(
                                    Number(
                                      item.game_transaction_winloss
                                    ).toFixed(2)
                                  )}`
                            }
                          />
                          <Line />
                        </View>
                      )}
                      keyExtractor={(item) => String(item.transaction_id)}
                    />
                  )}
                </View>
              )}
            </View>
          )}
        </MainFlowHeaderContainerNoFeedback>
      </View>
    )
  }
}
const styles = {
  container: {
    flex: 1,
    backgroundColor: 'white',
  },
  innerContainer: {
    flex: 1,
    paddingHorizontal: HeightDimen(20),
    marginTop: HeightDimen(10),
  },
  dateContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    width: '40%',
    alignSelf: 'flex-end',
  },
  date: {
    fontSize: fontDimen(12),
    fontWeight: 'bold',
  },
  dateLabel: {
    fontSize: fontDimen(12),
    fontWeight: 'bold',
    color: SecondaryColor,
    marginLeft: HeightDimen(5),
  },
  icon: {
    width: 'auto',
    height: HeightDimen(10),
    aspectRatio: 1 / 1,
    padding: HeightDimen(3),
    marginLeft: HeightDimen(5),
    tintColor: SecondaryColor,
  },
  listContainer: {
    flex: 1,
    marginTop: HeightDimen(20),
  },
}

export default GameDetailsTransactionScreen
