import React, { Component } from 'react'
import {
  View,
  Image,
  TouchableOpacity,
  TextInput,
  Platform,
} from 'react-native'
import Text from '../Text/MyText'

import {
  MainColor,
  SecondaryColor,
  FontMainColor,
  FontSecondaryColor,
  FontThirdColor,
} from '../../UI/Color'
import {
  WidthDimen,
  HeightDimen,
  fontDimen,
  GetWidth,
  GetHeight,
} from '../../UI/Dimensions'
import Clipboard from '@react-native-clipboard/clipboard'
import MyToast from '../Toast/MyToast'
import { strings } from '../../Language/Language'

class DisplayInput extends Component {
  onPressCopy = () => {
    Clipboard.setString(this.props.value)
    MyToast.popToast(strings.copied)
  }

  render() {
    const { title, value, borderRadius, isCopy = true } = this.props

    return (
      <View
        style={{
          width: '100%',
          height: HeightDimen(50),
          backgroundColor: '#EFF2F4',
          flexDirection: 'row',
          alignItems: 'center',
          padding: HeightDimen(15),
          marginBottom: HeightDimen(5),
          borderRadius: borderRadius || 0,
        }}
      >
        <Text
          style={{
            fontSize: fontDimen(15),
            color: MainColor,
            marginRight: HeightDimen(15),
          }}
        >
          {title}
        </Text>
        <Text
          style={{
            flex: 1,
            fontSize: fontDimen(14),
            color: MainColor,
          }}
        >
          {value}
        </Text>
        <View style={{ flexDirection: 'row' }}>
          {isCopy && (
            <TouchableOpacity
              onPress={() => {
                this.onPressCopy()
              }}
            >
              <Image
                style={{
                  height: HeightDimen(15),
                  width: 'auto',
                  aspectRatio: 1 / 1,
                  resizeMode: 'contain',
                }}
                source={require('../../Resources/Images/icon_copy.png')}
              />
            </TouchableOpacity>
          )}
          {this.props.isChangePassword && (
            <TouchableOpacity
              style={{ marginLeft: HeightDimen(10) }}
              onPress={() => {
                this.props.onPress()
              }}
            >
              <Image
                style={{
                  height: HeightDimen(15),
                  width: 'auto',
                  aspectRatio: 1 / 1,
                  resizeMode: 'contain',
                }}
                source={require('../../Resources/Images/lock.png')}
              />
            </TouchableOpacity>
          )}
        </View>
      </View>
    )
  }
}

export { DisplayInput }
