import React, { Component } from 'react'
import {
  View,
  Image,
  TouchableOpacity,
  TextInput,
  Platform,
  Keyboard,
  TouchableWithoutFeedback,
} from 'react-native'
import Text from '../Text/MyText'

import {
  MainColor,
  SecondaryColor,
  FontMainColor,
  FontSecondaryColor,
  FontThirdColor,
} from '../../UI/Color'
import {
  WidthDimen,
  HeightDimen,
  fontDimen,
  GetWidth,
  GetHeight,
} from '../../UI/Dimensions'

const DropdownTextInput = React.forwardRef((props, forwardedRef) => {
  const {
    placeholder,
    onChangeText,
    title,
    top,
    bottom,
    isSecure,
    isNumber,
    value,
    isEdit,
    onBlur,
    ddlLabel,
    ddlPress,
    selection,
    textAlign,
    onFocus,
  } = props

  return (
    <View style={styles.container}>
      <View
        style={{
          flex: 1,
        }}
      >
        <TextInput
          ref={forwardedRef}
          editable={isEdit}
          textContentType={'oneTimeCode'}
          keyboardType={isNumber ? 'decimal-pad' : 'default'}
          placeholderTextColor={'#C7C7CD'}
          placeholder={placeholder}
          value={value}
          onBlur={onBlur}
          onChangeText={onChangeText}
          style={styles.input}
          selection={selection}
          textAlign={textAlign}
          onFocus={onFocus}
        />
      </View>
      <TouchableOpacity onPress={ddlPress} style={styles.button}>
        {/* <Image
          source={require("../../Resources/Images/malaysia.png")}
          style={{ height: 15, width: 15 }}
        /> */}
        <Text style={styles.label}>{ddlLabel}</Text>
        <Image
          source={require('../../Resources/Images/icon-down.png')}
          style={styles.image}
          resizeMode="contain"
        />
      </TouchableOpacity>
    </View>
  )
})
const styles = {
  container: {
    width: '100%',
    height: HeightDimen(50),
    backgroundColor: '#EFF2F4',
    borderRadius: HeightDimen(10),
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: HeightDimen(15),
    borderTopRightRadius: HeightDimen(10),
    borderBottomRightRadius: HeightDimen(10),
  },
  button: {
    backgroundColor: MainColor,
    height: HeightDimen(50),
    width: WidthDimen(70),
    borderTopRightRadius: HeightDimen(10),
    borderBottomRightRadius: HeightDimen(10),
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  label: {
    marginHorizontal: HeightDimen(8),
    color: 'white',
  },
  image: {
    height: HeightDimen(20),
    width: WidthDimen(15),
    tintColor: 'white',
    marginRight: HeightDimen(7),
  },
  input: {
    fontSize: fontDimen(15),
    height: HeightDimen(50),
    outlineStyle: 'none',
  },
}
export { DropdownTextInput }
