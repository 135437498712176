import axios from "axios";
import { apiUrls } from "./APIurl";

import storageData from "../Data/StorageData";
import MyData from "../Data/MyData";

class BankAPICaller {
  getAvailableBank = (authToken) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.getBankList}`,
          {
            data: {
              language: storageData.get_selected_language(),
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  addOrUpdateBankDetails = (authToken, bankID, accNumber, accName) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.addOrUpdateBankDetails}`,
          {
            data: {
              bankId: bankID,
              bankAccount: accNumber,
              bankAccountName: accName,
              language: storageData.get_selected_language(),
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getTopUpBankList = (authToken) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.getTopUpBankList}`,
          {
            data: {
              language: storageData.get_selected_language(),
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getBankAccounts = (authToken) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.bankAccounts}`,
          {
            data: {
              language: storageData.get_selected_language(),
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  createBankAccounts = (authToken, bankId, bankAccount, accountName) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.createBankAccount}`,
          {
            data: {
              bankId: bankId,
              bankAccount: bankAccount,
              bankAccountName: accountName,
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  updateBankAccounts = (authToken, id, bankId, bankAccount, accountName) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.updateBankAccount}`,
          {
            data: {
              id: id,
              bankId: bankId,
              bankAccount: bankAccount,
              bankAccountName: accountName,
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  deleteBankAccounts = (authToken, id) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.deleteBankAccounts}`,
          {
            data: {
              id: id,
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getQRTopup = (authToken) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.getQRTopupBankPay}`,
          {
            data: {
              language: storageData.get_selected_language(),
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  uploadReceiptTopUp = async (
    uri,
    authToken,
    name,
    bankId,
    amount,
    promo_code
  ) => {
    let temp = "";
    return await ImgToBase64.getBase64String(uri)
      .then((base64String) => {
        return new Promise((resolve, reject) => {
          axios
            .post(
              `${MyData.api_url}${apiUrls.requestQRPayTopup}`,
              {
                data: {
                  topUpBankId: bankId,
                  topUpOwnerName: name,
                  topUpAmount: amount,
                  imageUrl: base64String,
                  couponCode: promo_code,
                },
              },
              {
                headers: {
                  Authorization: authToken,
                },
              }
            )
            .then((response) => {
              resolve(response.data.return.imgUrl);
            })
            .catch((error) => {
              reject(error);
            });
        });
      })
      .catch((err) => {
        reject(err);
      });
  };

  getSelectedTopUpBank = async (authToken) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.selectedTopupBank}`,
          {
            data: {},
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  saveSelectedTopupBank = async (authToken, bankId, amount, coupon_code) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.saveSelectedTopupBank}`,
          {
            data: {
              topUpBankId: bankId,
              topUpBankAmount: amount,
              couponCode: coupon_code,
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  cancelSelectedTopupBank = async (authToken, bankId) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.cancelSelectedTopupBank}`,
          { data: {} },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  checkUserVerification = async (authToken) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.checkVerified}`,
          { data: {} },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
}

const bankAPICaller = new BankAPICaller();
export default bankAPICaller;
