import React, { Component } from "react";
import { View, TouchableOpacity, Image } from "react-native";
import Text from "../Text/MyText";

import {
  MainColor,
  SecondaryColor,
  FontMainColor,
  FontSecondaryColor,
  FontThirdColor,
} from "../../UI/Color";
import {
  WidthDimen,
  HeightDimen,
  fontDimen,
  GetWidth,
  GetHeight,
} from "../../UI/Dimensions";

const ImageButton = (props) => {
  const { onPress, label, src, disable } = props;

  return (
    <TouchableOpacity
      onPress={onPress}
      disabled={disable}
      style={{
        width: "100%",
        height: HeightDimen(50),

        alignItems: "center",
        backgroundColor: disable ? "rgba(232, 232, 232, 0.6)" : "white",
        borderRadius: HeightDimen(10),
        flexDirection: "row",
      }}
    >
      <View
        style={{
          flex: 0.35,
          alignItems: "center",
        }}
      >
        <Image
          source={src}
          style={{
            width: "auto",
            height: HeightDimen(30),
            aspectRatio: 1 / 1,
            resizeMode: "contain",
          }}
        />
      </View>
      <View
        style={{
          flex: 1,
          alignItems: "center",
        }}
      >
        <Text
          style={{
            color: FontMainColor,
            fontSize: fontDimen(15),
          }}
        >
          {label}
        </Text>
      </View>
    </TouchableOpacity>
  );
};

export { ImageButton };
