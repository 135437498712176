import { initializeApp } from 'firebase/app'
import { getMessaging, getToken, onMessage } from 'firebase/messaging'
import MyToast from './src/Component/Toast/MyToast'
import AsyncStorage from '@react-native-async-storage/async-storage'

const firebaseConfig = {
  apiKey: 'AIzaSyCpaxiByusrLXLXAzefx3_Xa4k54ktd050',
  authDomain: 'luckytown-staging-c1d8f.firebaseapp.com',
  projectId: 'luckytown-staging-c1d8f',
  storageBucket: 'luckytown-staging-c1d8f.appspot.com',
  messagingSenderId: '922782071288',
  appId: '1:922782071288:web:f88f8f9eabe30445767e0d',
  measurementId: 'G-JKCENV7NR0',
}
const app = initializeApp(firebaseConfig)
const messaging = getMessaging(app)
export const requestUserPermission = async () => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker
      .register('./firebase-messaging-sw.js')
      .then((e) => console.log('Service worker registered', e))
      .catch((e) => console.log('service worker failed', e))
  }
  Notification.requestPermission().then((permission) => {
    if (permission === 'granted') {
      getToken(messaging, {
        vapidKey:
          'BOCU5IIsh4g2l7jluWrgRqybwqltOgx_M_osb_YA-EL-Ku-ytG8ufMlc9nR-JCIkqfGFkYl7FRwAaw46EAGAM-Y',
      })
        .then(async (fcmToken) => {
          if (fcmToken) {
            await AsyncStorage.setItem('fcmToken', fcmToken)
          } else {
            console.log(
              'No registration token available. Request permission to generate one.'
            )
          }
        })
        .catch((err) => {
          console.log('An error occurred while retrieving token. ', err)
        })
    }
  })

  onMessage(messaging, (payload) => {
    MyToast.popToast(payload.notification.body)
  })
}
