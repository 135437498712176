import React, { Component } from 'react'
import {
  View,
  TouchableOpacity,
  Image,
  KeyboardAvoidingView,
  FlatList,
  Platform,
} from 'react-native'
import Text from '../../Component/Text/MyText'
import { FontMainColor, MainColor, SecondaryColor } from '../../UI/Color'
import { HeightDimen, WidthDimen, fontDimen } from '../../UI/Dimensions'
import { MainFlowHeaderContainerNoFeedback } from '../../Component/Header/MainFlowHeader'
import { strings } from '../../Language/Language'
import WithdrawScreen from './WithdrawScreen'
import TopUpScreen from './TopUpScreen'
import WithdrawUSDTScreen from './WithdrawUSDTScreen'
import QRTopUpScreen from './QRTopupScreen'
import UsdtTopUpScreen from './UsdtTopupScreen'
import InstantTopUpScreen from './InstantTopUpScreen'
import walletAPICaller from '../../API/WalletAPICaller'
import MyData from '../../Data/MyData'
import MyToast from '../../Component/Toast/MyToast'
import { ActivityIndicator } from 'react-native'
import { Dimensions } from 'react-native'
const screenWidth = Dimensions.get('window').width

class TopUpWithdrawScreen extends Component {
  state = {
    selection: this.props.route.params.selectionType || 1,
    selectedWithdrawType: '',
    topupMethodList: [],
    withdrawMethodList: [],
    selectedTopupType: '',
    isLoading: false,
    methodPath: '',
    indicatorList: [1],
    scrollPercentage: 20,
    topupMethodCode: '',
    withdrawMethodCode: '',
  }
  constructor(props) {
    super(props)
  }
  async componentDidMount() {
    await this.getTopupMethod()
  }
  getTopupMethod = async () => {
    this.setState({ isLoading: true })
    debugger
    await walletAPICaller
      .getTopupMethods(MyData.authToken)
      .then((res) => {
        let selectTopupType = res.response.topUpMethodListNew.find(
          (x) => x.method_status == 'active'
        )
        let selectWithdrawType = res.response.withdrawMethod.find(
          (x) => x.method_status == 'active'
        )
        let list = res.response.topUpMethodListNew.length / 3
        this.setState({
          topupMethodList: res.response.topUpMethodListNew,
          withdrawMethodList: res.response.withdrawMethod,
          isLoading: false,
          selectedTopupType: selectTopupType?.method_name,
          selectedWithdrawType: selectWithdrawType?.method_name,
          methodPath: selectTopupType?.method_path,
          topupMethodCode: selectTopupType?.method_code,
          withdrawMethodCode: selectWithdrawType?.method_code,
          // indicatorList: list,
        })
      })
      .catch((err) => {
        this.setState({ isLoading: false })
        if (err.response.status == 514) {
          this.props.expiredToken_logout()
        } else {
          MyToast.popToast(err.response.data.msg)
        }
      })
  }
  onSwitch = (val) => {
    if (this.state.selection != val) {
      this.setState({ selection: val })
    }
  }

  onSwitchWithdrawType = (val) => {
    if (this.state.withdrawType != val) {
      this.setState({ withdrawType: val })
    }
  }
  onBackPressed = () => {
    this.props.route.params.onGoBack()
    this.props.navigation.goBack()
  }

  getTopupLanguageCode = (code, name) => {
    let labelToDisplay = ''
    switch (code) {
      case 'topup_instant':
        labelToDisplay = strings.t('instant_top_up')
        break
      case 'topup_manual':
        labelToDisplay = strings.t('top_up_bank_transfer')
        break
      case 'topup_usdt':
        labelToDisplay = strings.t('usdt_top_up')
        break
      case 'withdraw_member':
        labelToDisplay = strings.t('withdraw')
        break
      case 'withdraw_usdt':
        labelToDisplay = strings.t('withdraw_usdt')
        break

      default:
        labelToDisplay = name
        break
    }
    return labelToDisplay
  }
  render() {
    return (
      <MainFlowHeaderContainerNoFeedback
        title={`${strings.t('top_up')}/${strings.t('withdraw')}`}
        onBackPress={() => this.onBackPressed()}
      >
        <View style={styles.switchButtonContainer}>
          <View style={styles.switchButtonInnerContainer}>
            <TouchableOpacity
              style={{
                ...styles.switchButton,
                marginRight: HeightDimen(7),
                backgroundColor:
                  this.state.selection == 1
                    ? SecondaryColor
                    : 'rgba(237, 181, 61, 0.1)',
              }}
              onPress={() => this.onSwitch(1)}
            >
              <Text
                style={{
                  fontSize: fontDimen(13),
                  color: this.state.selection == 1 ? 'white' : FontMainColor,
                }}
              >
                {strings.t('top_up')}
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={{
                ...styles.switchButton,
                backgroundColor:
                  this.state.selection == 2
                    ? SecondaryColor
                    : 'rgba(237, 181, 61, 0.1)',
                marginLeft: HeightDimen(7),
              }}
              onPress={() => this.onSwitch(2)}
            >
              <Text
                style={{
                  fontSize: fontDimen(13),
                  color: this.state.selection == 2 ? 'white' : FontMainColor,
                }}
              >
                {strings.t('withdraw')}
              </Text>
            </TouchableOpacity>
          </View>
        </View>

        {this.state.isLoading ? (
          <View style={{ marginTop: HeightDimen(20) }}>
            <ActivityIndicator size="small" color={MainColor} />
          </View>
        ) : (
          // <KeyboardAvoidingView
          //   behavior={"height"}
          //   style={{ flex: 1 }}
          //   keyboardVerticalOffset={
          //     Platform.OS == "ios" ? 20 : HeightDimen(-200)
          //   }>
          <>
            {this.state.selection == 1 ? (
              <>
                <FlatList
                  data={this.state.topupMethodList}
                  showsHorizontalScrollIndicator={false}
                  showsVerticalScrollIndicator={false}
                  horizontal={true}
                  onScroll={(event) => {
                    // const contentWidth = event.nativeEvent.contentSize.width
                    // const scrollViewWidth =
                    //   event.nativeEvent.layoutMeasurement.width
                    // const scrollOffset = event.nativeEvent.contentOffset.x
                    // let percentage =
                    //   (scrollOffset / (contentWidth - scrollViewWidth)) * 100
                    // if (percentage < 1) percentage = 20
                    // else if (percentage > 100) percentage = 100
                    // this.setState({ scrollPercentage: percentage })
                  }}
                  style={{
                    flexGrow: 0,
                    marginVertical: HeightDimen(7),
                    marginHorizontal: HeightDimen(10),
                  }}
                  renderItem={({ item, index }) => (
                    <TouchableOpacity
                      disabled={item.method_status == 'active' ? false : true}
                      style={{
                        ...styles.container,
                        backgroundColor:
                          item.method_status == 'active'
                            ? item.method_code == this.state.topupMethodCode
                              ? SecondaryColor
                              : 'white'
                            : 'gray',
                      }}
                      onPress={() => {
                        // if (item.method_path)
                        //   this.setState({
                        //     methodPath: "",
                        //   });
                        this.setState({
                          selectedTopupType: item.method_name,
                          topupMethodCode: item.method_code,
                          methodPath: item.method_path,
                        })
                        // setTimeout(() => {
                        //   this.setState({
                        //     selectedTopupType: item.method_name,
                        //     topupMethodCode: item.method_code,
                        //     methodPath: item.method_path,
                        //   });
                        // }, 100);
                      }}
                    >
                      <Image
                        source={{ uri: item?.method_icon }}
                        style={{
                          ...styles.image,
                        }}
                      />
                      <Text
                        style={{
                          ...styles.label,
                          color:
                            item.method_code == this.state.topupMethodCode
                              ? 'white'
                              : MainColor,
                        }}
                      >
                        {this.getTopupLanguageCode(
                          item.method_code,
                          item.method_name
                        )}
                      </Text>
                    </TouchableOpacity>
                  )}
                  keyExtractor={(item) => String(item.topup_bank_name)}
                />

                {this.state.topupMethodCode.toLowerCase() == 'topup_manual' ? (
                  <TopUpScreen {...this.props} />
                ) : this.state.topupMethodCode.toLowerCase() ==
                  'topup_instant' ? (
                  <InstantTopUpScreen
                    {...this.props}
                    path={this.state.methodPath}
                  />
                ) : this.state.topupMethodCode.toLowerCase() ==
                  'topup_instant_tng' ? (
                  <InstantTopUpScreen
                    {...this.props}
                    path={this.state.methodPath}
                  />
                ) : this.state.topupMethodCode.toLowerCase() ==
                  'topup_instant_dn' ? (
                  <InstantTopUpScreen
                    {...this.props}
                    path={this.state.methodPath}
                  />
                ) : this.state.topupMethodCode.toLowerCase() == 'topup_qr' ? (
                  <QRTopUpScreen {...this.props} />
                ) : this.state.topupMethodCode.toLowerCase() == 'topup_usdt' ? (
                  <UsdtTopUpScreen {...this.props} />
                ) : (
                  <></>
                )}
              </>
            ) : (
              <>
                <>
                  <View style={styles.cardButtonContainer}>
                    {this.state.withdrawMethodList.map((item) => {
                      return (
                        <TouchableOpacity
                          disabled={
                            item.method_status == 'active' ? false : true
                          }
                          style={{
                            ...styles.container,
                            backgroundColor:
                              item.method_status == 'active'
                                ? item.method_code ==
                                  this.state.withdrawMethodCode
                                  ? SecondaryColor
                                  : 'white'
                                : 'gray',
                          }}
                          onPress={() => {
                            this.setState({
                              selectedWithdrawType: item.method_name,
                              withdrawMethodCode: item.method_code,
                            })
                          }}
                        >
                          <Image
                            source={{ uri: item.method_icon }}
                            style={{
                              ...styles.image,
                            }}
                          />
                          <Text
                            style={{
                              ...styles.label,
                              color:
                                item.method_code ==
                                this.state.withdrawMethodCode
                                  ? 'white'
                                  : MainColor,
                            }}
                          >
                            {this.getTopupLanguageCode(
                              item.method_code,
                              item.method_name
                            )}
                          </Text>
                        </TouchableOpacity>
                      )
                    })}
                  </View>
                </>

                {this.state.withdrawMethodCode.toLowerCase() ==
                'withdraw_member' ? (
                  <WithdrawScreen {...this.props} />
                ) : this.state.withdrawMethodCode.toLowerCase() ==
                  'withdraw_usdt' ? (
                  <WithdrawUSDTScreen {...this.props} />
                ) : (
                  <></>
                )}
              </>
            )}
          </>
          // </KeyboardAvoidingView>
        )}
      </MainFlowHeaderContainerNoFeedback>
    )
  }
}
const styles = {
  switchButtonContainer: {
    backgroundColor: SecondaryColor,
    paddingHorizontal: HeightDimen(15),
    paddingBottom: HeightDimen(10),
    paddingTop: HeightDimen(5),
    borderBottomEndRadius: HeightDimen(20),
    borderBottomLeftRadius: HeightDimen(20),
  },

  switchButtonInnerContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    backgroundColor: 'white',
    borderRadius: HeightDimen(30),
    height: HeightDimen(50),
    alignItems: 'center',
  },
  switchButton: {
    width: '45%',
    height: HeightDimen(35),
    backgroundColor: SecondaryColor,

    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: HeightDimen(20),
  },
  cardButtonContainer: {
    // flex: 1,
    flexDirection: 'row',
    marginTop: HeightDimen(10),

    // height: HeightDimen(80),
    // flexWrap: "wrap",
    paddingHorizontal: HeightDimen(10),
  },
  container: {
    height: HeightDimen(70),
    padding: HeightDimen(5),
    borderRadius: HeightDimen(10),
    alignItems: 'center',
    justifyContent: 'center',
    width: HeightDimen(100),
    backgroundColor: SecondaryColor,
    marginRight: HeightDimen(7),
    borderWidth: HeightDimen(2),
    borderColor: SecondaryColor,
  },
  image: {
    width: 'auto',
    height: HeightDimen(25),
    aspectRatio: 1 / 1,
    resizeMode: 'contain',
  },
  label: {
    color: MainColor,
    fontSize: fontDimen(10),
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: HeightDimen(3),
  },
}
export default TopUpWithdrawScreen
