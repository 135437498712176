import React, { Component } from "react";
import { View, TouchableOpacity, Image } from "react-native";
import Text from "../Text/MyText";

import {
  MainColor,
  SecondaryColor,
  FontMainColor,
  FontSecondaryColor,
  FontThirdColor,
} from "../../UI/Color";
import {
  WidthDimen,
  HeightDimen,
  fontDimen,
  GetWidth,
  GetHeight,
} from "../../UI/Dimensions";
import { strings } from "../../Language/Language";

const AddNewFriendCard = (props) => {
  const { onPress, name, src } = props;

  return (
    <TouchableOpacity
      onPress={onPress}
      style={{
        width: "100%",
        backgroundColor: "#fef6eb",
        borderRadius: HeightDimen(10),
        flexDirection: "row",
        padding: HeightDimen(8),

        alignItems: "center",
        marginBottom: HeightDimen(15),
      }}
    >
      <View
        style={{
          backgroundColor: "rgba(237, 181, 61, 0.2)",
          padding: HeightDimen(15),
          borderRadius: HeightDimen(10),
        }}
      >
        <Image
          source={src}
          style={{
            width: "auto",
            height: HeightDimen(25),
            aspectRatio: 1 / 1,
            resizeMode: "contain",
          }}
        />
      </View>
      <View
        style={{
          flex: 1,
          marginLeft: HeightDimen(15),
        }}
      >
        <Text
          style={{
            fontSize: fontDimen(15),
            color: FontMainColor,
          }}
        >
          {name}
        </Text>
      </View>
    </TouchableOpacity>
  );
};

export { AddNewFriendCard };
