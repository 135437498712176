// import { captureScreen } from "react-native-view-shot";
//import ImgToBase64 from 'react-native-image-base64'
//import Share from 'react-native-share'
// import DeviceInfo from "react-native-device-info";
import * as DeviceInfo from 'expo-device'
import storageData from '../Data/StorageData'
import { Alert, Linking, Platform } from 'react-native'
import AsyncStorage from '@react-native-async-storage/async-storage'
import MyToast from '../Component/Toast/MyToast'
import { CommonActions } from '@react-navigation/routers'
import { strings } from '../Language/Language'
import MyData from '../Data/MyData'
import * as Sharing from 'expo-sharing'
import { useEffect, useRef } from 'react'
import { CH_Font, EN_Font, TH_Font } from '../UI/Color'

export const takeScreenShot = () => {
  // To capture Screenshota
  captureScreen({
    // Either png or jpg (or webm Android Only), Defaults: png
    format: 'png',
    // Quality 0.0 - 1.0 (only available for jpg)
    quality: 0.8,
  }).then(
    //callback function to get the result URL of the screnshot
    (uri) => {
      ImgToBase64.getBase64String(uri)
        .then(async (base64String) => {
          let urlString = 'data:image/png;base64,' + base64String
          const options = {
            url: urlString,
            type: 'image/png',
          }
          onShare(options)
        })
        .catch((err) => {})
    },
    (error) => console.error('Oops, Something Went Wrong', error)
  )
}

export const onShare = async (options) => {
  try {
    const result = await Sharing.shareAsync(options)
  } catch (error) {
    console.error('Error while sharing:', error.message)
  }
}

export const getFcmToken = async () => {
  let fcmToken = await AsyncStorage.getItem('fcmToken')
  return fcmToken
}

export const getDeviceInfo = async () => {
  // const ipAdress = await DeviceInfo.getIpAddress()
  // const deviceId = await DeviceInfo.getde()
  // // const macAddress = await DeviceInfo.getMacAddress();
  // const userAgent = await DeviceInfo()
  // const model = await DeviceInfo.modelId()
  // const manufacturer = await DeviceInfo.manufacturer()
  // const host = await DeviceInfo.getHost()
  // const hardware = await DeviceInfo.har()
  // const firstTimeInstall = await DeviceInfo.getFirstInstallTime()
  // const deviceName = await DeviceInfo.getDeviceName()
  // const display = await DeviceInfo.getDisplay()
  // const device = await DeviceInfo.getDevice()
  // const carrier = await DeviceInfo.getCarrier()
  // const apiLevel = await DeviceInfo.getApiLevel()
  // const version = await DeviceInfo.getVersion()
  // const uniqueId = await DeviceInfo.getUniqueId()
  const {
    brand,
    designName,
    manufacturer,
    modelId,
    modelName,
    osVersion,
    osName,
    osBuildId,
    platformApiLevel,
    productName,
    osInternalBuildId,
    osBuildFingerprint,
  } = DeviceInfo
  debugger
  return {
    brand,
    designName,
    manufacturer,
    modelId,
    modelName,
    osVersion,
    osName,
    osBuildId,
    platformApiLevel,
    productName,
    osInternalBuildId,
    osBuildFingerprint,
  }
}
export const getSelectedLanguage = () => {
  const lang = storageData.get_selected_language()
  if (lang === 'EN') {
    return strings.t('eng_text')
  } else if (lang === 'BM') {
    return strings.t('bahasa_melayu_text')
  } else if (lang === 'CH') {
    return strings.t('ch_text')
  } else if (lang === 'TH') {
    return strings.t('thai_text')
  } else if (lang === 'VT') {
    return strings.t('vt_text')
  } else if (lang === 'ID') {
    return strings.t('indonsia_text')
  } else if (lang === 'MY') {
    return strings.t('myanmar_text')
  } else if (lang === 'KH') {
    return strings.t('cambodia_text')
  }
}

export const languageSelectedName = (en_name, cn_name) => {
  const lang = storageData.get_selected_language()
  if (lang === 'EN' || lang === 'BM') {
    return en_name
  } else {
    return cn_name
  }
}

export const isValidDeelinkURL = async (title) => {
  let deepLink = ''
  if (title === 'PUSSY') {
    deepLink = 'pussy888://pussy888.com'
  } else if (title === 'LION') {
    deepLink = 'jqk://'
  } else if (title === 'EVO888') {
    deepLink =
      Platform.OS === 'android'
        ? 'evo888android://lobbyevoandroid'
        : 'evo888ios://lobbyevoios'
  } else if (title === '918KISS') {
    deepLink = 'lobbykiss://lobbykiss'
  } else if (title === 'MGC') {
    deepLink = 'wartreeurl://com.zytx.mgc'
  }
  if (deepLink) {
    try {
      const supported = await Linking.canOpenURL(deepLink)
      if (supported) {
        return true
      } else {
        return false
      }
    } catch (error) {
      return false
    }
  } else {
    return false
  }
}

export const handleOrientation = async (isLandscape) => {
  // const appVersion = (await getDeviceInfo()).version
  // if (appVersion >= '2.0.0') {
  //   const MyOrientation = await import('react-native-orientation-locker')
  //   if (isLandscape) await MyOrientation.default.unlockAllOrientations()
  //   else await MyOrientation.default.lockToPortrait()
  // }
}

export const handleVersionCheck = async () => {
  const appVersion = (await getDeviceInfo()).version
  if (appVersion >= '2.0.0') return true
  else return false
}
export const handleNewDesignVersionCheck = async () => {
  const appVersion = (await getDeviceInfo()).version
  if (appVersion >= '3.0.0') return true
  else return false
}
export const handleMutiCurrencyVersionCheck = async () => {
  const appVersion = (await getDeviceInfo()).version
  if (appVersion >= '4.0.0') return true
  else return false
}
export const handleUXMaxVersionCheck = async () => {
  const appVersion = (await getDeviceInfo()).version
  if (appVersion >= '4.0.1') return true
  else return false
}
export const handle4_0_2_Check = async () => {
  const appVersion = (await getDeviceInfo()).version
  if (appVersion >= '4.0.2') return true
  else return false
}
export const handle4_0_4_Check = async () => {
  const appVersion = (await getDeviceInfo()).version
  if (appVersion >= '4.0.4') return true
  else return false
}
export const phoneRegex = (phone) => {
  if (/(^$)|^[1-9][0-9]{0,20}$/.test(phone)) return true
  else return false
}
export const getImageUrl = (name) => {
  // let image = product_images.find((item) => {
  //   return item?.name?.toLowerCase() == name?.toLowerCase()
  // })?.path
  // if (image) return image
  // else
  return ''
}

export const countryCodes = [
  {
    id: 1,
    name: 'Malaysia',
    code: '+60',
    icon_url: require('../Resources/Images/malaysia.png'),
  },
  {
    id: 2,
    name: 'Thailand',
    code: '+66',
    icon_url: require('../Resources/Images/thailand.png'),
  },
  {
    id: 3,
    name: 'Singapore',
    code: '+65',
    icon_url: require('../Resources/Images/singapore.png'),
  },
  {
    id: 4,
    name: 'Cambodia',
    code: '+855',
    icon_url: require('../Resources/Images/cambodia.png'),
  },
  {
    id: 5,
    name: 'Myanmar',
    code: '+95',
    icon_url: require('../Resources/Images/myanmar.png'),
  },
  {
    id: 6,
    name: 'Indonesia',
    code: '+62',
    icon_url: require('../Resources/Images/indonesia.png'),
  },
  {
    id: 7,
    name: 'China',
    code: '+86',
    icon_url: require('../Resources/Images/china.png'),
  },
]
export const resetNavigationStack = (navigation, route) =>
  navigation.dispatch(
    CommonActions.reset({
      index: 0,
      routes: [{ name: route }],
    })
  )
export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
export const letterRegex = (text) => {
  if (/^[\p{L}\p{M}\p{Zs}\p{N}}]{0,50}$/u.test(text)) return true
  else return false
}
export const getCurrentDateFormat = () => {
  const date = new Date().getDate()
  const month = new Date().getMonth() + 1
  const year = new Date().getFullYear()
  const final_date =
    year +
    '-' +
    (month < 10 ? '0' : '') +
    month +
    '-' +
    (date < 10 ? '0' : '') +
    date

  return final_date
}

export const numberRegex = (text) => {
  if (/^\d*$/.test(text)) return true
  else return false
}

export const normalizeDecimalValue = (value) => {
  const defaultValue = '0' + '.' + '0'.repeat(2)
  if (!value) {
    return value === '' ? defaultValue : value
  }
  const onlyNums = value.replace(/[^\d]/g, '')
  const onlyNumsInt = parseInt(onlyNums)
  if (!onlyNumsInt || onlyNumsInt === 0) {
    return defaultValue
  }
  const result = onlyNumsInt / Math.pow(10, 2)
  const resultFixed = Number.parseFloat(String(result)).toFixed(2)
  return resultFixed
}

// export const product_images = [
//   {
//     id: 1,
//     name: 'THREESING',
//     path: require('../Resources/Images/product_images/3SING.png'),
//   },
//   {
//     id: 2,
//     name: 'THREESING-bnw',
//     path: require('../Resources/Images/product_images/3SING-bnw.png'),
//   },
//   {
//     id: 3,
//     name: '28WIN',
//     path: require('../Resources/Images/product_images/28WIN.png'),
//   },
//   {
//     id: 4,
//     name: '28WIN-bnw',
//     path: require('../Resources/Images/product_images/28WIN-bnw.png'),
//   },
//   // {
//   //   id: 5,
//   //   name: "KING888",
//   //   path: require("../Resources/Images/product_images/888KING.png"),
//   // },
//   {
//     id: 6,
//     name: '918KISS',
//     path: require('../Resources/Images/product_images/918KISS.png'),
//   },
//   {
//     id: 7,
//     name: '918KISS-bnw',
//     path: require('../Resources/Images/product_images/918KISS-bnw.png'),
//   },
//   {
//     id: 8,
//     name: 'SEXY-bnw',
//     path: require('../Resources/Images/product_images/AESEXY_bnw.png'),
//   },
//   {
//     id: 9,
//     name: 'SEXY',
//     path: require('../Resources/Images/product_images/AESEXY.png'),
//   },
//   {
//     id: 10,
//     name: 'AESLOT',
//     path: require('../Resources/Images/product_images/AESLOT.png'),
//   },
//   {
//     id: 11,
//     name: 'AG',
//     path: require('../Resources/Images/product_images/AG.png'),
//   },

//   {
//     id: 12,
//     name: 'AG-bnw',
//     path: require('../Resources/Images/product_images/AG-bnw.png'),
//   },
//   {
//     id: 13,
//     name: 'ALLBET',
//     path: require('../Resources/Images/product_images/ALLBET.png'),
//   },
//   {
//     id: 14,
//     name: 'ALLBET-bnw',
//     path: require('../Resources/Images/product_images/ALLBET-bnw.png'),
//   },
//   {
//     id: 15,
//     name: 'OBET',
//     path: require('../Resources/Images/product_images/OBET.png'),
//   },
//   {
//     id: 16,
//     name: 'OBET-bnw',
//     path: require('../Resources/Images/product_images/OBET-bnw.png'),
//   },
//   {
//     id: 17,
//     name: 'BG',
//     path: require('../Resources/Images/product_images/BIGGAMING.png'),
//   },
//   {
//     id: 18,
//     name: 'BG-bnw',
//     path: require('../Resources/Images/product_images/BIGGAMING-bnw.png'),
//   },
//   // {
//   //   id: 19,
//   //   name: "CARD-bnw",
//   //   path: require("../Resources/Images/product_images/CARD-bnw.png"),
//   // },
//   {
//     id: 20,
//     name: 'CF',
//     path: require('../Resources/Images/product_images/CF.png'),
//   },
//   // {
//   //   id: 21,
//   //   name: "CQ9",
//   //   path: require("../Resources/Images/product_images/CQ9.png"),
//   // },
//   // {
//   //   id: 22,
//   //   name: "CQ9-bnw",
//   //   path: require("../Resources/Images/product_images/CQ9-bnw.png"),
//   // },

//   // {
//   //   id: 23,
//   //   name: "CROWDPLAY",
//   //   path: require("../Resources/Images/product_images/CROWDPLAY.png"),
//   // },
//   // {
//   //   id: 24,
//   //   name: "CROWDPLAY-bnw",
//   //   path: require("../Resources/Images/product_images/CROWDPLAY-bnw.png"),
//   // },
//   {
//     id: 25,
//     name: 'DREAMGAMING',
//     path: require('../Resources/Images/product_images/DREAMGAMING.png'),
//   },
//   {
//     id: 26,
//     name: 'DREAMGAMING-bnw',
//     path: require('../Resources/Images/product_images/DREAMGAMING-bnw.png'),
//   },
//   {
//     id: 27,
//     name: 'EVO888',
//     path: require('../Resources/Images/product_images/EVO888.png'),
//   },
//   {
//     id: 28,
//     name: 'EVO888-bnw',
//     path: require('../Resources/Images/product_images/EVO888-bnw.png'),
//   },
//   {
//     id: 29,
//     name: 'EVOLUTION',
//     path: require('../Resources/Images/product_images/EVOLUTION.png'),
//   },
//   {
//     id: 30,
//     name: 'EVOLUTION-bnw',
//     path: require('../Resources/Images/product_images/EVOLUTION-bnw.png'),
//   },
//   {
//     id: 31,
//     name: 'FUNHOUSE',
//     path: require('../Resources/Images/product_images/FUNHOUSE.png'),
//   },
//   {
//     id: 32,
//     name: 'FUNHOUSE-bnw',
//     path: require('../Resources/Images/product_images/FUNHOUSE-bnw.png'),
//   },
//   // {
//   //   id: 33,
//   //   name: "GAMEPLAY",
//   //   path: require("../Resources/Images/product_images/GAMEPLAY.png"),
//   // },

//   // {
//   //   id: 34,
//   //   name: "GAMEPLAY-bnw",
//   //   path: require("../Resources/Images/product_images/GAMEPLAY-bnw.png"),
//   // },
//   // {
//   //   id: 35,
//   //   name: "GIOCOPLUS",
//   //   path: require("../Resources/Images/product_images/GIOCOPLUS.png"),
//   // },
//   // {
//   //   id: 36,
//   //   name: "GIOCOPLUS-bnw",
//   //   path: require("../Resources/Images/product_images/GIOCOPLUS-bnw.png"),
//   // },
//   // {
//   //   id: 37,
//   //   name: "HABANERO",
//   //   path: require("../Resources/Images/product_images/HABANERO.png"),
//   // },
//   // {
//   //   id: 38,
//   //   name: "HABANERO-bnw",
//   //   path: require("../Resources/Images/product_images/HABANERO-bnw.png"),
//   // },
//   {
//     id: 39,
//     name: 'IBC',
//     path: require('../Resources/Images/product_images/IBC.png'),
//   },
//   {
//     id: 40,
//     name: 'IBC-bnw',
//     path: require('../Resources/Images/product_images/IBC-bnw.png'),
//   },
//   {
//     id: 41,
//     name: 'JDBFISH',
//     path: require('../Resources/Images/product_images/JDB.png'),
//   },
//   {
//     id: 42,
//     name: 'JDBFISH-bnw',
//     path: require('../Resources/Images/product_images/JDB-bnw.png'),
//   },
//   {
//     id: 43,
//     name: 'JILI',
//     path: require('../Resources/Images/product_images/JILI.png'),
//   },
//   {
//     id: 44,
//     name: 'JILI-bnw',
//     path: require('../Resources/Images/product_images/JILI-bnw.png'),
//   },

//   {
//     id: 45,
//     name: 'JOKER-',
//     path: require('../Resources/Images/product_images/JOKER.png'),
//   },
//   {
//     id: 46,
//     name: 'JOKER--bnw',
//     path: require('../Resources/Images/product_images/JOKER-bnw.png'),
//   },
//   {
//     id: 47,
//     name: 'KING',
//     path: require('../Resources/Images/product_images/KING855.png'),
//   },
//   {
//     id: 48,
//     name: 'KING-bnw',
//     path: require('../Resources/Images/product_images/KING855-bnw.png'),
//   },
//   {
//     id: 49,
//     name: 'KINGKONG',
//     path: require('../Resources/Images/product_images/KINGKONG.png'),
//   },
//   {
//     id: 50,
//     name: 'KINGKONG-bnw',
//     path: require('../Resources/Images/product_images/KINGKONG-bnw.png'),
//   },
//   {
//     id: 51,
//     name: 'KINGMAKER-bnw',
//     path: require('../Resources/Images/product_images/KINGMAKER-bnw.png'),
//   },
//   {
//     id: 52,
//     name: 'LION',
//     path: require('../Resources/Images/product_images/LION.png'),
//   },
//   {
//     id: 53,
//     name: 'LION-bnw',
//     path: require('../Resources/Images/product_images/LION-bnw.png'),
//   },
//   {
//     id: 54,
//     name: 'LIVE22',
//     path: require('../Resources/Images/product_images/LIVE22.png'),
//   },
//   {
//     id: 55,
//     name: 'LIVE22-bnw',
//     path: require('../Resources/Images/product_images/LIVE22-bnw.png'),
//   },
//   {
//     id: 56,
//     name: 'LUCKY365',
//     path: require('../Resources/Images/product_images/LUCKY365.png'),
//   },
//   {
//     id: 57,
//     name: 'LUCKY365-bnw',
//     path: require('../Resources/Images/product_images/LUCKY365-bnw.png'),
//   },
//   {
//     id: 58,
//     name: 'M8B',
//     path: require('../Resources/Images/product_images/M8B.png'),
//   },
//   {
//     id: 59,
//     name: 'M8B-bnw',
//     path: require('../Resources/Images/product_images/M8B-bnw.png'),
//   },
//   {
//     id: 60,
//     name: 'MEGA',
//     path: require('../Resources/Images/product_images/MEGA.png'),
//   },
//   {
//     id: 61,
//     name: 'MEGA-bnw',
//     path: require('../Resources/Images/product_images/MEGA-bnw.png'),
//   },
//   // {
//   //   id: 62,
//   //   name: "MGC",
//   //   path: require("../Resources/Images/product_images/MGC.png"),
//   // },
//   // {
//   //   id: 63,
//   //   name: "MGC-bnw",
//   //   path: require("../Resources/Images/product_images/MGC-bnw.png"),
//   // },
//   // {
//   //   id: 64,
//   //   name: "NEXTSPIN",
//   //   path: require("../Resources/Images/product_images/NEXTSPIN.png"),
//   // },
//   {
//     id: 65,
//     name: 'PG',
//     path: require('../Resources/Images/product_images/PG.png'),
//   },
//   {
//     id: 66,
//     name: 'PG-bnw',
//     path: require('../Resources/Images/product_images/PG-bnw.png'),
//   },

//   {
//     id: 67,
//     name: 'PLAYTECHLIVE',
//     path: require('../Resources/Images/product_images/PLAYTECHLIVE.png'),
//   },
//   {
//     id: 69,
//     name: 'PLAYTECHLIVE-bnw',
//     path: require('../Resources/Images/product_images/PLAYTECHLIVE-bnw.png'),
//   },
//   {
//     id: 68,
//     name: 'PLAYTECHSLOT-bnw',
//     path: require('../Resources/Images/product_images/PLAYTECHSLOT-bnw.png'),
//   },

//   {
//     id: 70,
//     name: 'PLAYTECHSLOT',
//     path: require('../Resources/Images/product_images/PLAYTECHSLOT.png'),
//   },
//   {
//     id: 71,
//     name: 'PLAYTECHSLOT-bnw',
//     path: require('../Resources/Images/product_images/PLAYTECHSLOT-bnw.png'),
//   },
//   {
//     id: 72,
//     name: 'PRAGMATICPLAY--bnw',
//     path: require('../Resources/Images/product_images/PRAGMATICPLAY-bnw.png'),
//   },
//   {
//     id: 73,
//     name: 'PRAGMATICPLAY-',
//     path: require('../Resources/Images/product_images/PRAGMATICPLAY.png'),
//   },
//   // {
//   //   id: 74,
//   //   name: "PRETTY",
//   //   path: require("../Resources/Images/product_images/PRETTY.png"),
//   // },
//   // {
//   //   id: 75,
//   //   name: "PRETTY-bnw",
//   //   path: require("../Resources/Images/product_images/PRETTY-bnw.png"),
//   // },
//   // {
//   //   id: 76,
//   //   name: "PUSSY",
//   //   path: require("../Resources/Images/product_images/PUSSY.png"),
//   // },
//   // {
//   //   id: 77,
//   //   name: "PUSSY-bnw",
//   //   path: require("../Resources/Images/product_images/PUSSY-bnw.png"),
//   // },

//   {
//     id: 78,
//     name: 'REDTIGER',
//     path: require('../Resources/Images/product_images/REDTIGER.png'),
//   },
//   {
//     id: 79,
//     name: 'REDTIGER-bwn',
//     path: require('../Resources/Images/product_images/REDTIGER-bwn.png'),
//   },
//   // {
//   //   id: 80,
//   //   name: "S128",
//   //   path: require("../Resources/Images/product_images/S128.png"),
//   // },
//   // {
//   //   id: 81,
//   //   name: "S128-bnw",
//   //   path: require("../Resources/Images/product_images/S128-bnw.png"),
//   // },
//   // {
//   //   id: 82,
//   //   name: "SAGAMING",
//   //   path: require("../Resources/Images/product_images/SAGAMINGBET.png"),
//   // },
//   // {
//   //   id: 83,
//   //   name: "SAGAMING-bnw",
//   //   path: require("../Resources/Images/product_images/SAGAMINGBET-bnw.png"),
//   // },
//   {
//     id: 84,
//     name: 'SBOSPORT',
//     path: require('../Resources/Images/product_images/SBO.png'),
//   },
//   {
//     id: 85,
//     name: 'SBOSPORT-bnw',
//     path: require('../Resources/Images/product_images/SBO-bnw.png'),
//   },
//   {
//     id: 86,
//     name: 'SBOVIRTUAL',
//     path: require('../Resources/Images/product_images/SBOVIRTUAL.png'),
//   },
//   {
//     id: 87,
//     name: 'SBOVIRTUAL-bnw',
//     path: require('../Resources/Images/product_images/SBOVIRTUAL-bnw.png'),
//   },
//   // {
//   //   id: 88,
//   //   name: "SEXYBACCARAT",
//   //   path: require("../Resources/Images/product_images/SEXYBACCARAT.png"),
//   // },

//   // {
//   //   id: 89,
//   //   name: "SEXYBACCARAT-bnw",
//   //   path: require("../Resources/Images/product_images/SEXYBACCARAT-bnw.png"),
//   // },
//   {
//     id: 90,
//     name: 'SPADEFISH',
//     path: require('../Resources/Images/product_images/SPADEFISH.png'),
//   },
//   {
//     id: 91,
//     name: 'SPADEFISH-bnw',
//     path: require('../Resources/Images/product_images/SPADEFISH-bnw.png'),
//   },
//   // {
//   //   id: 92,
//   //   name: "SPADEGAMING",
//   //   path: require("../Resources/Images/product_images/SPADEGAMING.png"),
//   // },
//   // {
//   //   id: 93,
//   //   name: "SPADEGAMING-bnw",
//   //   path: require("../Resources/Images/product_images/SPADEGAMING-bnw.png"),
//   // },
//   {
//     id: 94,
//     name: 'SV388',
//     path: require('../Resources/Images/product_images/SV388.png'),
//   },
//   {
//     id: 95,
//     name: 'SV388-bnw',
//     path: require('../Resources/Images/product_images/SV388-bnw.png'),
//   },
//   {
//     id: 96,
//     name: 'UNITEDGAMING',
//     path: require('../Resources/Images/product_images/UNITEDGAMING.png'),
//   },
//   {
//     id: 97,
//     name: 'UNITEDGAMING-bnw',
//     path: require('../Resources/Images/product_images/UNITEDGAMING-bnw.png'),
//   },

//   {
//     id: 98,
//     name: 'VPOWER',
//     path: require('../Resources/Images/product_images/VPOWER.png'),
//   },
//   {
//     id: 99,
//     name: 'VPOWER-bnw',
//     path: require('../Resources/Images/product_images/VPOWER-bnw.png'),
//   },
//   // {
//   //   id: 100,
//   //   name: "WMC",
//   //   path: require("../Resources/Images/product_images/WMC.png"),
//   // },
//   // {
//   //   id: 101,
//   //   name: "WMC-bnw",
//   //   path: require("../Resources/Images/product_images/WMC-bnw.png"),
//   // },
//   // {
//   //   id: 102,
//   //   name: "WORLDMATCH",
//   //   path: require("../Resources/Images/product_images/WORLDMATCH.png"),
//   // },

//   // {
//   //   id: 103,
//   //   name: "WORLDMATCH-bnw",
//   //   path: require("../Resources/Images/product_images/WORLDMATCH-bnw.png"),
//   // },
//   {
//     id: 104,
//     name: 'WS',
//     path: require('../Resources/Images/product_images/WS.png'),
//   },
//   {
//     id: 105,
//     name: 'WS-bnw',
//     path: require('../Resources/Images/product_images/WS-bnw.png'),
//   },
//   {
//     id: 106,
//     name: 'XE88',
//     path: require('../Resources/Images/product_images/XE88.png'),
//   },
//   {
//     id: 107,
//     name: 'XE88-bnw',
//     path: require('../Resources/Images/product_images/XE88-bnw.png'),
//   },
//   {
//     id: 108,
//     name: 'YLFISH',
//     path: require('../Resources/Images/product_images/YLFISH.png'),
//   },
//   {
//     id: 109,
//     name: 'YLFISH-bnw',
//     path: require('../Resources/Images/product_images/YLFISH-bnw.png'),
//   },
//   {
//     id: 110,
//     name: 'LBOX',
//     path: require('../Resources/Images/product_images/28WIN.png'),
//   },
//   {
//     id: 111,
//     name: 'LBOX-bnw',
//     path: require('../Resources/Images/product_images/28WIN-bnw.png'),
//   },
//   {
//     id: 112,
//     name: 'KINGMAKER',
//     path: require('../Resources/Images/product_images/KINGMAKER.png'),
//   },
//   {
//     id: 113,
//     name: 'AESLOT-bnw',
//     path: require('../Resources/Images/product_images/AESLOT-bnw.png'),
//   },
//   // {
//   //   id: 113,
//   //   name: "NEXTSPIN-bnw",
//   //   path: require("../Resources/Images/product_images/NEXTSPIN-bnw.png"),
//   // },
//   {
//     id: 114,
//     name: 'CF-bnw',
//     path: require('../Resources/Images/product_images/CF-bnw.png'),
//   },

//   {
//     id: 115,
//     name: 'DRAGOONSOFT',
//     path: require('../Resources/Images/product_images/DRAGONSOFT.png'),
//   },
//   {
//     id: 116,
//     name: 'DRAGOONSOFT-bnw',
//     path: require('../Resources/Images/product_images/DRAGONSOFT-bnw.png'),
//   },
//   {
//     id: 117,
//     name: 'we',
//     path: require('../Resources/Images/product_images/WE.png'),
//   },
//   {
//     id: 118,
//     name: 'WE-bnw',
//     path: require('../Resources/Images/product_images/WE-bnw.png'),
//   },
//   // {
//   //   id: 119,
//   //   name: "WM",
//   //   path: require("../Resources/Images/product_images/WM.png"),
//   // },
//   // {
//   //   id: 120,
//   //   name: "WM-bnw",
//   //   path: require("../Resources/Images/product_images/WM-bnw.png"),
//   // },
//   {
//     id: 121,
//     name: 'VIVO',
//     path: require('../Resources/Images/product_images/VIVO.png'),
//   },
//   {
//     id: 122,
//     name: 'VIVO-bnw',
//     path: require('../Resources/Images/product_images/VIVO-bnw.png'),
//   },
//   {
//     id: 123,
//     name: 'ADVANTPLAY',
//     path: require('../Resources/Images/product_images/ADVANTPLAY.png'),
//   },
//   {
//     id: 124,
//     name: 'ADVANTPLAY-bnw',
//     path: require('../Resources/Images/product_images/ADVANTPLAY-bnw.png'),
//   },
//   {
//     id: 124,
//     name: 'ACE333',
//     path: require('../Resources/Images/product_images/ACE333.png'),
//   },
//   {
//     id: 125,
//     name: 'ACE333-bnw',
//     path: require('../Resources/Images/product_images/ACE333-bnw.png'),
//   },
// ]
export const getVIPLevelLogo = () => {
  let logo = ''
  if (MyData.member_vip_level == '1')
    logo = require('../Resources/Images/vip/VIP1-level.png')
  else if (MyData.member_vip_level == '2')
    logo = require('../Resources/Images/vip/VIP2-level.png')
  else if (MyData.member_vip_level == '3')
    logo = require('../Resources/Images/vip/VIP3-level.png')
  else if (MyData.member_vip_level == '4')
    logo = require('../Resources/Images/vip/VIP4-level.png')
  else if (MyData.member_vip_level == '5')
    logo = require('../Resources/Images/vip/VIP5-level.png')
  else if (MyData.member_vip_level == '6')
    logo = require('../Resources/Images/vip/VIP6-level.png')

  return logo
}
export const generateRandom = (length) => {
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
  const charactersLength = characters.length
  let counter = 0
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
    counter += 1
  }
  return result
}
export const isMobile =
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  )
export const getBrowserUniqueId = () => {
  let screen_info = window.screen
  let uid = navigator.mimeTypes.length + ''

  uid = uid + navigator.userAgent.replace(/\D+/g, '')
  uid = uid + navigator.plugins.length
  uid = uid + screen_info.height || ''
  uid = uid + screen_info.width || ''
  uid = uid + screen_info.pixelDepth || ''

  return uid
}
export const debounce = (func, timeout = 300) => {
  let timer
  return (...args) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      func.apply(this, args)
    }, timeout)
  }
}
export const alphaNumericRegex = (value) => {
  var regularExpression = /^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9]+$/
  var valid = regularExpression.test(value)
  return valid
}
export const getFontByLanguage = () => {
  const lang = storageData.get_selected_language()
  if (lang === 'CH') return CH_Font
  else if (lang === 'TH') return TH_Font
  else return EN_Font
}
