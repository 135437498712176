import React, { Component } from 'react'
import { View, TouchableOpacity, Image } from 'react-native'
import Text from '../Text/MyText'
import { SecondaryColor, FontMainColor, PrimaryColor } from '../../UI/Color'
import { HeightDimen, fontDimen } from '../../UI/Dimensions'

const ProfileCard = (props) => {
  const { top, bottom, src, label, hideShowNext, onPress, marginBottom } = props

  return (
    <TouchableOpacity
      disabled={hideShowNext}
      onPress={onPress}
      style={{
        width: '100%',
        height: HeightDimen(50),
        backgroundColor: PrimaryColor,
        borderTopRightRadius: top ? HeightDimen(10) : 0,
        borderTopLeftRadius: top ? HeightDimen(10) : 0,
        borderBottomRightRadius: bottom ? HeightDimen(10) : 0,
        borderBottomLeftRadius: bottom ? HeightDimen(10) : 0,
        flexDirection: 'row',
        padding: HeightDimen(15),
        alignItems: 'center',
        marginBottom: marginBottom || 0,
      }}
    >
      <Image
        source={src}
        style={{
          width: 'auto',
          height: HeightDimen(20),
          aspectRatio: 1 / 1,
          resizeMode: 'contain',
          tintColor: SecondaryColor,
        }}
      />
      <View
        style={{
          flex: 1,
          marginLeft: HeightDimen(15),
          marginRight: HeightDimen(15),
        }}
      >
        <Text
          style={{
            fontSize: fontDimen(15),
            color: FontMainColor,
          }}
        >
          {label}
        </Text>
      </View>
      {hideShowNext ? (
        <></>
      ) : (
        <Image
          source={require('../../Resources/Images/icon_nextP.png')}
          style={{
            width: 'auto',
            height: HeightDimen(20),
            aspectRatio: 16 / 27,
            resizeMode: 'contain',
          }}
        />
      )}
    </TouchableOpacity>
  )
}

export { ProfileCard }
