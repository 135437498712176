import React, { Component } from 'react'
import { View } from 'react-native'
import { HeightDimen } from '../../UI/Dimensions'
import { LoginFlowHeaderContainer } from '../../Component/Header/LoginFlowHeader'
import { TextButton } from '../../Component/Button/TextButton'
import MyToast from '../../Component/Toast/MyToast'
import { strings } from '../../Language/Language'
import pinAPICaller from '../../API/PinAPICaller'
import MyData from '../../Data/MyData'
import { PhoneTextInput } from '../../Component/Input/PhoneTextInput'
import { phoneRegex } from '../../Utils/Common'

class RecoverPinScreen extends Component {
  state = {
    isLoading: false,
    phone: '',
    code: '+60',
  }

  handle_phone = (phone) => {
    this.setState({ phone: phone })
  }

  onBackPressed = () => {
    this.props.navigation.goBack()
  }

  onNextPressed = async () => {
    if (this.state.phone != '') {
      this.setState({ isLoading: true })

      await pinAPICaller
        .recoverPin(this.state.phone, this.state.code, MyData.authToken)
        .then((res) => {
          this.setState({ isLoading: false })
          this.props.navigation.navigate('ChangePinOTP', {
            onGoBack: (data) => {
              this.refresh(data)
            },
          })
        })
        .catch((err) => {
          MyToast.popToast(err.response.data.msg)
          this.setState({ isLoading: false })
        })
    } else {
      MyToast.popToast(strings.t('invalid_data_input'))
    }
  }

  refresh = (data) => {
    this.props.navigation.goBack()
  }
  onSelectCode(code) {
    this.setState({
      code: code,
    })
  }
  render() {
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: 'white',
        }}
      >
        <LoginFlowHeaderContainer
          onBackPress={() => this.onBackPressed()}
          mainText={strings.t('recover_transaction_pin')}
          subText={strings.t('please_enter_phone_number_pin')}
        >
          <View
            style={{
              flex: 1,
              paddingLeft: HeightDimen(30),
              paddingRight: HeightDimen(30),
              paddingBottom: HeightDimen(30),
            }}
          >
            <View
              style={{
                marginTop: HeightDimen(40),
              }}
            >
              <PhoneTextInput
                placeholder={strings.t('phone_number')}
                onSelectCodePress={(val) => this.onSelectCode(val)}
                bottom={true}
                top={true}
                value={this.state.phone}
                isNumber={true}
                onChangeText={this.handle_phone}
              />
            </View>

            <View
              style={{
                width: '100%',
                alignItems: 'center',
                marginTop: HeightDimen(40),
              }}
            >
              <TextButton
                label={strings.t('next')}
                size={5}
                onPress={() => this.onNextPressed()}
                isLoading={this.state.isLoading}
              />
            </View>
          </View>
        </LoginFlowHeaderContainer>
      </View>
    )
  }
}

export default RecoverPinScreen
