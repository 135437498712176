import React, { Component } from 'react';
import { View, Image, TouchableOpacity, TextInput, Platform } from 'react-native';
import Text from '../Text/MyText';

import { MainColor, SecondaryColor, FontMainColor, FontSecondaryColor, FontThirdColor } from '../../UI/Color';
import { WidthDimen, HeightDimen, fontDimen, GetWidth, GetHeight } from '../../UI/Dimensions';
import { DateModal } from '../Modal/DateModal';

class DateInput extends Component {

    state = {
        showDateModal: false,
        date: '',
    }

    componentDidMount() {
        
        var date = new Date().getDate();
        var month = new Date().getMonth() + 1;
        var year = new Date().getFullYear();
        
        var final_date = year + '-' + (month < 10 ? '0' : '') + month + '-' + (date < 10 ? '0' : '') + date;
        this.setState({
            date: final_date
        })
    }

    close_date_modal = (data) => {
        this.setState({
            date: data
        });
        this.props.setDate(data);
        this.setState({ showDateModal: false });
    }

    open_date_modal = () => {
        this.setState({ showDateModal: true });
    }

  
    render() {
        const {
            label
        } = this.props;

        return(
            <View>
                <DateModal
                    isShow={this.state.showDateModal}
                    label={label}
                    onClose={this.close_date_modal}
                />
                <TouchableOpacity
                    onPress={() => this.open_date_modal()}
                    style={{
                        width: '100%',
                        height: HeightDimen(50),
                        borderWidth: HeightDimen(2),
                        borderColor: SecondaryColor,
                        borderRadius: HeightDimen(10),
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <Text
                        style={{
                            fontSize: fontDimen(12),
                            color: FontMainColor,
                        }}
                    >
                        {this.state.date}
                    </Text>
                    
                </TouchableOpacity>
            </View>
        )
    }
}

export { DateInput }; 