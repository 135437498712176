import axios from 'axios'
import { apiUrls } from './APIurl'
import storageData from '../Data/StorageData'
import MyData from '../Data/MyData'

class CurrencyExchangeCaller {
  currencyPreview = async (authToken, from, to, amount) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.currencyExchangePreview}`,
          {
            data: {
              from: from,
              to: to,
              amount: amount,
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  currencyExchange = async (authToken, from, to, amount) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.currencyExchange}`,
          {
            data: {
              from: from,
              to: to,
              amount: amount,
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  getCurrencyList = async (authToken) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.currencyList}`,
          {
            data: {},
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}

const currencyExchangeCaller = new CurrencyExchangeCaller()
export default currencyExchangeCaller
