export const AnalyticEvents = {
  screenView: "Page View",
  LoginSuccess: "Login: Login button success",
  LoginFailure: "Login: Login button failed",
  SignUpSuccess: "SignUp: signup button success",
  SignUpFailure: "SignUp: signup button failed",
  UserAuthenticateSuccess: "Splash Screen: User Authentication Success",
  UserAuthenticateFailure: "Splash Screen:User Authentication Failed",
  BannerSuccess: "Dashboard: Banner Success",
  BannerFailure: "Dashboard: Banner Failed",
  WalletBalanceSuccess: "Dashboard: Wallet Balance Success",
  WalletBalanceFailure: "Dashboard: Wallet Balance Failed",
  WalletBalanceSuccess: "Dashboard: Wallet Balance Success",
  WalletBalanceFailure: "Dashboard: Wallet Balance Failed",
  CountNotificationSuccess: "Dashboard: Notification Count Success",
  CountNotificationFailure: "Dashboard: Notification Count Failure",
  TopupMethodSuccess: "Dashboard: Topup Method Success",
  TopupMethodFailure: "Dashboard:Topup Method Failure",
  DashboardTopupClick: "Dashboard: Topup Click",
  DashboardCreateInterestClick: "Dashboard: Topup Click",
  DashboardAnouncementClick: "Dashboard: Anouncement Click",
  DashboardLeatherboardClick: "Dashboard: Leatherboard Click",
  DashboardGamesClick: "Dashboard: Games Click",
  ProductsSuccess: "Dashboard: Game Products Success",
  ProductsFailure: "Dashboard:  Game Products Failed",
  AvailableCreditSuccess: "Game Detail: Available Credit Success",
  AvailableCreditFailure: "Game Detail:  Available Credit Failed",
  CloseGameSuccess: "Game Detail: Close Game Success",
  CloseGameFailure: "Game Detail:  Close Game Failed",
  LaunchGameSuccess: "Game Detail: Launch Game Success",
  LaunchGameFailure: "Game Detail: Launch Game Failed",
  BonusChipsSuccess: "Game Detail: Bonus Chips Success",
  BonusChipsFailure: "Game Detail:  Bonus Chips Failed",
  DashboardGameClick: "Dashboard: Game Click",
  GameDetailCloseGameClick: "Game Detail: Close Game",
  GameDetailLaunchGame: "Game Detail: Launch",
  GameDetailTopupClick: "Game Detail: Topup Click",
  GameDetailGameHistory: "Game Detail: Game History",
  GameDetailBankTopupClick: "Game Detail: BankTopup Click",
  GameDetailInstantTopupClick: "Game Detail: Instant Topup Click",
  GameDetailRedeemClick: "Game Detail: Redeem Coupon Click",
  GameDetailQRTopupClick: "Game Detail:QR Topup Click",
  GameDetailUSDTClick: "Game Detail: USDT Click",
  GameDetailTopupCloseModal: "Game Detail:Close Topup Modal Click",
  GameDetailGameHistory: "Game Detail: Game Transaction History Click",
  GameDetailDeepLinkGameLaunchSuccess:
    "Game Detail:Deeplink Game Launch Success",
  GameDetailDeepLinkGameLaunchFailure:
    "Game Detail:Deeplink Game Launch Failed",
  GameDetailMegaTransferBalanceSuccess:
    "Game Detail:Mega Game Transfer Success",
  GameDetailMegaTransferBalanceFailed: "Game Detail:Mega Game Transfer Failed",
  GameDetailGameChangePasswordSuccess: "Game Detail: Password Change Success",
  GameDetailGameChangePasswordFailure: "Game Detail: Password Change Failed",
  GameDetailPullOutCreditClick: "Game Detail: Pull Out Credit Click",
  GameDetailPullOutCreditFailed: "Game Detail:Pull Out Credit Failed",
  GameDetailPullOutCreditSuccess: "Game Detail: Pull Out Credit Success",
  FunhouseGameSuccess: "Funhouse Detail: Games Success",
  FunhouseGameFailure: "Funhouse Detail:  Games Failed",
  FunhouseLaunchSuccess: "Funhouse Detail: Games Launch Success",
  FunhouseLaunchFailure: "Funhouse Detail:  Games Launch Failed",
  Xe88GameSuccess: "Xe88 Detail: Games Success",
  Xe88GameFailure: "Xe88 Detail:  Games Failed",
  Xe88LaunchSuccess: "Xe88 Detail: Games Launch Success",
  Xe88LaunchFailure: "Xe88 Detail:  Games Launch Failed",
  VpowerGameSuccess: "Vpower Detail: Games Success",
  VpowerGameFailure: "Vpower Detail:  Games Failed",
  VpowerLaunchSuccess: "Vpower Detail: Games Launch Success",
  VpowerLaunchFailure: "Vpower Detail:  Games Launch Failed",
  PragmaticGameSuccess: "Pragmatic Detail: Games Success",
  PragmaticGameFailure: "Pragmatic Detail:  Games Failed",
  PragmaticLaunchSuccess: "Pragmatic Detail: Games Launch Success",
  PragmaticLaunchFailure: "Pragmatic Detail:  Games Launch Failed",
  Pluss100GameSuccess: "100 Plus Detail: Games Success",
  Pluss100GameFailure: "100 Plus Detail:  Games Failed",
  Pluss100LaunchSuccess: "100 Plus Detail: Games Launch Success",
  Pluss100LaunchFailure: "100 Plus Detail:  Games Launch Failed",
  PlaytechGameSuccess: "Playtech Detail: Games Success",
  PlaytechGameFailure: "Playtech Detail:  Games Failed",
  PlaytechLaunchSuccess: "Playtech Detail: Games Launch Success",
  PlaytechLaunchFailure: "Playtech Detail:  Games Launch Failed",
  Pluss100LaunchSuccess: "100 Plus Detail: Games Launch Success",
  Pluss100LaunchFailure: "100 Plus Detail:  Games Launch Failed",
  PGSGameSuccess: "PGS Detail: Games Success",
  PGSGameFailure: "PGS Detail:  Games Failed",
  PGSLaunchSuccess: "PGS Detail: Games Launch Success",
  PGSLaunchFailure: "PGS Detail:  Games Launch Failed",
  JokerGameSuccess: "Joker Detail: Games Success",
  JokerGameFailure: "Joker Detail:  Games Failed",
  JokerLaunchSuccess: "Joker Detail: Games Launch Success",
  JokerLaunchFailure: "Joker Detail:  Games Launch Failed",
  FriendsSuccess: "Friend: Friends Success",
  FriendsFailure: "Reiend: Friends Failed",
  AddFriendCancelClick: "Friend: Add Friend Cancel Click",
  AddFriendClick: "Friend: Add Friend Click",
  AddFriendsSuccess: "Friend: Add Friend Success",
  AddFriendsFailure: "Feiend: Add Friend Failed",
  EditFriendClick: "Friend: Edit Friend Click",
  EditFriendCancelClick: "Friend: Edit Friend Cancel Click",
  EditFriendsSuccess: "Friend: Edit Friend Success",
  EditFriendsFailure: "Friend: Edit Friend Failed",
  TransferFriendClick: "Friend: Transfer Friend Click",
  ChatFriendClick: "Friend: Start Chat",
  DeleteFriendClick: "Friend: Delete Friend Click",
  FriendCustomerServiceClick: "Friend: Customer Service Click",
  DeleteFriendsSuccess: "Friend: Delete Friend Success",
  DeleteFriendsFailure: "Friend: Delete Friend Failed",
  DeleteFriendCancelClick: "Friend: Delete Friend Cancel Click",
  TransferFriendSearchSuccess: "Friend: Transfer Friend Search Success",
  TransferFriendSearchFailure: "Feiend: Transfer Friend Search Failed",
  FriendSearchFailure: "Friend:  Friend Search Failed",
  FriendSearchSuccess: "Friend:  Friend Search Success",
  WalletTransactionClick: "History: Wallet Transaction Click",
  GameTransactionClick: "History: Game Transaction Click",
  WalletTransactionsSuccess: "Wallet Transactions: Wallet Transactions Success",
  WalletTransactionsFailure: "Wallet Transactions: Wallet Transaction Failed",
  WalletTransactionsDateFilter: "Wallet Transactions: Date Filter",
  WalletTransactionsTypeFilter: "Wallet Transactions: Type Filter",
  WalletTransactionClick: "Wallet Transactions: Transaction Click",
  WalletTransactionAdditionalDetailClick:
    "Wallet Transactions: Additional Detail Transaction Click",
  GameTransactionsSuccess: "Game Transactions: Game Transactions Success",
  GameTransactionsFailure: "Game Transactions: Game Transaction Failed",
  GameTransactionsDateFilter: "Game Transactions: Date Filter",
  GameTransactionsTypeFilter: "Game Transactions: Type Filter",
  GameTransactionClick: "Game Transactions: Transaction Click",
  GameTransactionsProductSuccess:
    "Game Transactions: Game Transactions Product Success",
  GameTransactionsProductFailure:
    "Game Transactions: Game Transaction Product Failed",
  QRSaveClick: "QR: Save Click",
  QRTransferClick: "QR: Transfer Click",
  QRCameraTransferFailure: "QR: Tranfer Camera Permission Failed ",
  QRShareClick: "QR: Share Click",
  QRCopyLinkClick: "QR: Copy Link Click",
  QRModalCloseClick: "QR: Modal Close Click",
  ProfileHelpDeskClick: "Profile: Helpdesk Click",
  ProfileTopupWithdrawClick: "Profile: Topup/Withdraw Click",
  ProfileTransferClick: "Profile: Transfer Click",
  ProfileCurrencyExchangeClick: "Profile: Currency Exchange Click",
  ProfileBankAccountClick: "Profile: Bank Account Click",
  ProfileVIPClick: "Profile: VIP Click",
  ProfilePromotionClick: "Profile: Promotion Click",
  ProfileSettingClick: "Profile: Settings Click",
  ProfileQRClick: "Profile: QR Click",
  ProfileLucktCoinClick: "Profile: LuckyTown Coin Click",
  ProfileAvatarClick: "Profile: Change Avatar Click",
  ProfileWalletTabClick: "Profile: Wallet Transaction Tab Click",
  ProfileGameTabClick: "Profile: Game Transaction Tab Click",
  ProfileOrdeTabrClick: "Profile:Order Tansaction Tab Click",
  ProfileMoreClick: "Profile:More Tab Click",
  ProfileWalletTransactionClick: "Profile: Wallet Transaction Click",
  ProfileGameTransactionClick: "Profile: Game Transaction Click",
  ProfileOrderTransactionClick: "Profile: Order Transaction Click",
  ProfileTopupTransactionClick: "Profile: Topup Detail Click",
  ProfileWithdrawHistoryTransactionClick:
    "Profile: Withdraw History Transaction Click",
  ProfileLatestTransactionFailure: "Profile: Latest Transaction Failed",
  ProfileLatestTransactionSuccess: "Profile:  Latest Transaction Success",
  ProfileDataSuccess: "Profile: Data Success",
  ProfileDataFailure: "Profile: Data Failed",
  ProfileWalletBalanceFailure: "Profile: Wallet Balance Failed",
  ProfileWalletBalanceSuccess: "Profile:  Wallet Balance Success",
  ProfileAvatarChangeSuccess: "Profile:  Avatar Change Success",
  ProfileAvatarChangeFailure: "Profile:  Avatar Change Failed",
  QRModalTransferClick: "QR: Modal Transfer Click",
  ProfileInstantTopupClick: "Profile: Instant Click",
  ProfileBankTopupClick: "Profile: Bank Topup Click",
  ProfileUsdtTopupClick: "Profile: USDT Topup Click",
  ProfileWithdrawUsdtClick: "Profile: Withdraw USDT Click",
  ProfileQRTopupClick: "Profile: QR Topup Click",
  ProfilePromotionTopupClick: "Profile: Promotion Topup Click",
  ProfileTopupWithdrawModalClick: "Profile: Topup/Withdraw Modal Click",
  ProfileTopupWithdrawModalCloseClick:
    "Profile: Topup/Withdraw Modal Close Click",
  ProfileInstantTopupClick: "Profile: Instant Click",
  ProfileBankTopupClick: "Profile: Bank Topup Click",
  AddBankSuccess: "Add Bank: Account Created Success",
  AddBankFailure: "Add bank: Account Created Failed",
  AddBankTransactionPinModalOpen: "Add Bank: Transaction Pin Modal Open",
  AddBankTransactionPinModalClose: "Add Bank: Transaction Pin Modal Close",
  AddBankTransactionPinSuccess: "Add Bank: Transaction Pin Success",
  AddBankSelectBank: "Add Bank: Bank Selected",
  AddBankForgotTransactionPin: "Add Bank: Forgot Transaction Pin",
  AddBankSelectBankModalCloseClick: "Add Bank: Select Bank Modal Close Click",
  AddBankSelectBankModalOpenClick: "Add Bank: Select Bank Modal Open Click",
  EditBankSuccess: "Edit Bank: Account Created Success",
  EditBankFailure: "Edit bank: Account Created Failed",
  EditBankDeleteSuccess: "Edit Bank: Account Deleted Success",
  EditBankDeleteFailure: "Edit bank: Account Deleted Failed",
  EditBankTransactionPinModalOpen: "Edit Bank: Transaction Pin Modal Open",
  EditBankTransactionPinModalClose: "Edit Bank: Transaction Pin Modal Close",
  EditBankTransactionPinSuccess: "Edit Bank: Transaction Pin Success",
  EditBankSelectBank: "Edit Bank: Bank Selected",
  EditBankForgotTransactionPin: "Edit Bank: Forgot Transaction Pin",
  EditBankSelectBankModalCloseClick: "Edit Bank: Select Bank Modal Close Click",
  EditBankSelectBankModalOpenClick: "Edit Bank: Select Bank Modal Open Click",
  InstantTopupGetUrlSuccess: "Instant Topup: Web URL Success",
  InstantTopupGetUrlFailure: "Instant Topup:Web URL Failed",
  QRTopupSuccess: "QR Topup: Bank Pay Success",
  QRTopupFailure: "QR Topup: Bank Pay Failed",
  QRTopupUploadImageModalOpen: "QR Topup: Upload Image Modal Open",
  QRTopupUploadImageModalClose: "QR Topup: Upload Image Modal Close",
  QRTopupCameraOpenClick: "QR Topup: Camera Open Click",
  QRTopupCameraCloseClick: "QR Topup: Camera Close Click",
  QRTopupLaunchLocalGallery: "QR Topup: Launch Local Gallary",
  QRTopupUploadImageSuccess: "QR Topup: Upload Image Success",
  QRTopupUploadImageFailure: "QR Topup: Upload Image Failed",
  QRTopupUploadTopupSuccess: "QR Topup: Upload Topup Success",
  QRTopupUploadTopupFailure: "QR Topup: Upload Topup Failed",
  QRTopupConfirmUploadClick: "QR Topup: Confirm Upload Click",
  QRTopupCancelUploadClick: "QR Topup: Cancel Upload Click",
  TopupBankAccountsFailure: "Topup Bank: Account Load Failed",
  TopupBankAccountsSuccess: "Topup Bank: Account Load Success",
  TopupBankUploadImageModalOpen: "Topup Bank: Upload Image Modal Open",
  TopupBankUploadImageModalClose: "Topup Bank: Upload Image Modal Close",
  TopupBankCameraOpenClick: "Topup Bank: Camera Open Click",
  TopupBankCameraCloseClick: "Topup Bank: Camera Close Click",
  TopupBankLaunchLocalGallery: "Topup Bank: Launch Local Gallary",
  TopupBankUploadImageSuccess: "Topup Bank: Upload Image Success",
  TopupBankUploadImageFailure: "Topup Bank: Upload Image Failed",
  TopupBankUploadTopupSuccess: "Topup Bank: Upload Topup Success",
  TopupBankUploadTopupFailure: "Topup Bank: Upload Topup Failed",
  TopupBankConfirmUploadClick: "Topup Bank: Confirm Upload Click",
  TopupBankCancelUploadClick: "Topup Bank: Cancel Upload Click",
  QrTransferBalanceSuccess: "QR Transfer: Balance Transfer Success",
  QRTransferBalanceFailure: "QR Transfer: Balance Transfer Failed",
  QrTransferBalanceSearchSuccess: "QR Transfer: Search User Success",
  QRTransferBalanceSearchFailure: "QR Transfer: Search User Failed",
  QRTransferBalanceSearchClick: "QR Transfer:Search User Click",
  QRTransferBalanceRecoverPinClick: "QR Transfer:Recover Pin Click",
  QRTransferBalancePinSuccess: "QR Transfer:Transaction Pin Success",
  QRTransferBalancePinModalOpen: "QR Transfer: Transaction Pin Modal Open",
  QRTransferBalancePinModalClose: "QR Transfer: Transaction Pin Modal Close",
  ManualTransferBalanceSuccess: "Manual Transfer: Balance Transfer Success",
  ManualTransferBalanceFailure: "Manual Transfer: Balance Transfer Failed",
  ManualTransferBalanceSearchSuccess: "Manual Transfer: Search User Success",
  ManualTransferBalanceSearchFailure: "Manual Transfer: Search User Failed",
  ManualTransferBalanceSearchClick: "Manual Transfer:Search User Click",
  ManualTransferBalanceRecoverPinClick: "Manual Transfer:Recover Pin Click",
  ManualTransferBalancePinSuccess: "Manual Transfer:Transaction Pin Success",
  ManualTransferBalancePinModalOpen:
    "Manual Transfer: Transaction Pin Modal Open",
  ManualTransferBalancePinModalClose:
    "Manual Transfer: Transaction Pin Modal Close",
  LibraryLaunchQRTransferScanSuccess:
    "Transfer QR Scan: Library Launch QR Read Success",
  LibraryLaunchQRTransferScanFailure:
    "Transfer QR Scan: Library Launch QR Read Failed",
  LibraryLaunchQRTransferScanCick: "Transfer QR Scan: Library Launch Click",
  QRTransferScanBarcodeReadSuccess: "Transfer QR Scan: Barcode Read Success",
  QRTransferScanBarcodeReadFailure: "Transfer QR Scan: Barcode Read Failed",
  TopupUSDTCurrencyPlatfomSuccess: "Topup USDT: Currency Platform Success",
  TopupUSDTCurrencyPlatfomFailure: "Topup USDT: Currency Platform Failed",
  TopupUSDTPreviewCurrencySuccess: "Topup USDT: Currency Preview Success",
  TopupUSDTPreviewCurrencyFailure: "Topup USDT: Currency Preview Failed",
  WithdrawUSDTPreviewCurrencySuccess: "Withdraw USDT: Currency Preview Success",
  WithdrawUSDTPreviewCurrencyFailure: "Withdraw USDT: Currency Preview Failed",
  TopupUSDTCurrencyPlatfomSelect: "Topup USDT: Currency Platform Select",
  TopupUSDTCurrencyPlatfomPreviewClick:
    "Topup USDT: Currency Platform Preview Click",
  WithdrawUSDTCurrencyPlatfomPreviewClick:
    "Withdraw USDT: Currency Platform Preview Click",
  TopupUSDTReceiptRequestUSDTSuccess:
    "Topup USDT Receipt: Request USDT Success",
  TopupUSDTReceiptRequestUSDTFailure: "Topup USDT Receipt: Request USDT Failed",
  TopupUSDTReceipCopyClick: "Topup USDT Receipt: Copy Click",
  TopupUSDTReceipRequestUSDTClick: "Topup USDT Receipt: Request USDT Click",
  WithdrawBankListSuccess: "Withdraw: Bank List Success",
  WithdrawBankListFailure: "Withdraw: Bank List Failed",
  WithdrawBankAccountSuccess: "Withdraw: Bank Account Success",
  WithdrawBankAccountFailure: "Withdraw: Bank Account Failed",
  WithdrawRecoverPinClick: "Withdraw :Recover Pin Click",
  WithdrawPinSuccess: "Withdraw :Transaction Pin Success",
  WithdrawSuccess: "Withdraw: Success",
  WithdrawFailure: "Withdraw: Failed",
  WithdrawPinModalOpen: "Withdraw: Transaction Pin Modal Open",
  WithdrawModalClose: "Withdraw: Transaction Pin Modal Close",
  WithdrawPreviewUSDTSuccess: "Withdraw: Preview USDT Success",
  WithdrawPreviewUSDTFailure: "Withdraw: Preview USDT Failed",
  WithdrawBankAccountSelect: "Withdraw: Bank Account Select",
  TopupHistorySuccess: "Topup Withdraw History: Topup History Success",
  TopupHistoryFailure: "Topup Withdraw History:  Topup History Failed",
  WithdrawHistorySuccess: "Topup Withdraw History:Withdraw History Success",
  WithdrawHistoryFailure: "Topup Withdraw History: Withdraw History Failed",
  TopupHistoryClick: "Topup Withdraw History: Topup History Tab Click",
  WithdrawHistoryClick: "Topup Withdraw History: Withdraw History Tab Click",
  TopupHistoryDetailClick: "Topup Withdraw History: Topup History Detail Click",
  WithdrawHistoryDetailClick:
    "Topup Withdraw History: Withdraw History Detail Click",
  WithdrawTransactionCancelConfirmClick:
    "Withdraw Transaction Detail: Withdraw Transaction Confirm Click",
  WithdrawTransactionCancelModalOpen:
    "Withdraw Transaction Detail: Withdraw Transaction Cancel Modal Open",
  WithdrawTransactionCancelModalClose:
    "Withdraw Transaction Detail: Withdraw Transaction Cancel Modal Close",
  WithdrawTransactionCancelSuccess:
    "Withdraw Transaction Detail: Withdraw Transaction Cancel Success",
  WithdrawTransactionCancelFailure:
    "Withdraw Transaction Detail: Withdraw Transaction Cancel Failed",
  TopupTransactionCancelConfirmClick:
    "Topup Transaction Detail: Topup Cancel Confirm Click",
  TopupTransactionCancelSuccess:
    "Topup Transaction Detail: Topup Transaction Cancel Success",
  TopupTransactionCancelFailure:
    "Topup Transaction Detail: Topup Transaction Cancel Failed",
  TopupTransactionCancelModalOpen:
    "Topup Transaction Detail: Topup Transaction Cancel Modal Open",
  TopupTransactionCancelModalClose:
    "Topup Transaction Detail: Topup Transaction Cancel Modal Close",
  ChangeLoginPasswordClick: "Change Login Password: Change Password Click",
  ChangeLoginPasswordSuccess: "Change Login Password: Password Change Success",
  ChangeLoginPasswordFailure: "Change Login Password: Password Change Failed",
  ChangeNicknameClick: "Change Nickname: Nickname Click",
  ChangeNicknameSuccess: "Change Nickname: Nickname Change Success",
  ChangeNicknameFailure: "Change Nickname: Nickname Change Failed",
  ChangePhoneNoClick: "Change PhoneNo: PhoneNo Click",
  ChangePhoneNoSuccess: "Change PhoneNo: PhoneNo Change Success",
  ChangePhoneNoFailure: "Change PhoneNo: PhoneNo Change Failed",
  ChangePhoneNoVerifySuccess:
    "Change PhoneNo Verify: PhoneNo Change Verify Success",
  ChangePhoneNoVerifyFailure:
    "Change PhoneNo Verify: PhoneNo Change Verify Failed",
  TransactionPinClick: " Transaction Pin:  Transaction Pin Click",
  TransactionPinSuccess: "Transaction Pin: Pin Change Success",
  TransactionPinFailure: "Transaction Pin: Pin Change Failed",
  SettingChangePasswordClick: "Settings: Change Password Click",
  SettingPaymentClick: "Settings: Change Payment Click",
  SettingNicknameClick: "Settings: Change Nickname Click",
  SettingPhonNoClick: "Settings: Change PhonNo Click",
  SettingSwitchAccountClick: "Settings: Switch Account Click",
  SettingLanguageClick: "Settings: Change Language Click",
  SettingAboutPhoneClick: "Settings: About Phone Click",
  SettingAboutClick: "Settings:About Click",
  SettingLogoutClick: "Settings:Logout Click",
  SettingChangeAppIconClick: "Settings:Change App Icon Click",
  SettingSwitchAccountModalOpen: "Settings:Switch Account Login Modal Open ",
  SettingSwitchAccountModalClose: "Settings:Switch Account Login Modal Close ",
  SettingSwitchAccount: "Settings:Switch Account",
  SettingAddMultiAccount: "Settings:Add Multi Account Click",
  SettingSwitchAccountSuccess: "Settings: Switch Account Success",
  SettingSwitchAccounDeletedtSuccess:
    "Settings: Switch Account Deleted Success",
  SettingSwitchAccounDeleteClick: "Settings: Switch Account Deleted Click",
  SettingSwitchAccountFailure: "Settings: Switch Account Failed",
  MutiAccountSuccess: "Multi Account: Account Added Success",
  MutiAccountFailure: "Multi Account: Account Added Failed",
  LanguageSelect: "Language: Selected Language",
  InterestAccountSuccess: "Interest: Interest Accounts Success",
  InterestAccountFailure: "Interest: Interest Accounts Failed",
  InterestDetailClick: "Interest: Detail Click",
  InterestCreateClick: "Interest: Create Interest Account",
  InterestHistoryClick: "Interest: Interest History Click",
  CreateInterestSuccess: "Create Interest: Interest Added Success",
  CreateInterestFailure: "Create Interest: Interest Added Failed",
  CreateInterestTransactionPinModalOpen:
    "Create Interest: Transaction Pin Modal Open ",
  CreateInterestTransactionPinModalClose:
    "Create Interest:Transaction Pin Modal Close ",
  CreateInterestTransactionPinSuccess:
    "Create Interest: Transaction Pin Success ",
  CreateInterestRecoverPinSuccess: "Create Interest: Recover Pin Click ",
  InterestDetailRemoveSuccess: "Interest Detail: Interest Remove Success",
  InterestDetailRemoveFailure: "Interest Detail: Interest Remove Failed",
  InterestDetailTransactionPinModalOpen:
    "Interest Detail: Transaction Pin Modal Open ",
  InterestDetailTransactionPinModalClose:
    "Interest Detail:Transaction Pin Modal Close ",
  InterestDetailTransactionPinSuccess:
    "Interest Detail: Transaction Pin Success ",
  InterestDetailRecoverPinClick: "Interest Detail: Recover Pin Click ",
  InterestDetailPartialWithdrawClick:
    "Interest Detail: Partial Withdraw Click ",
  InterestDetailRemoveAccountModalOpen:
    "Interest Detail:Remove Interest Account Modal Open",
  InterestDetailRemoveAccountModalClose:
    "Interest Detail: Remove Interest Account Close ",
  InterestHistorySuccess: "Interest History: Get History Success",
  InterestHistoryFailure: "Interest History: Get History Failed",
  PartialWithdrawAccountSuccess: "Partial Withdraw: Account Success",
  PartialWithdrawAccountFailure: "Partial Withdraw: Account Failed",
  PartialWithdrawTransactionPinModalOpen:
    "Partial Withdraw: Transaction Pin Modal Open ",
  PartialWithdrawTransactionPinModalClose:
    "Partial Withdraw :Transaction Pin Modal Close ",
  PartialWithdrawTransactionPinSuccess:
    "Partial Withdraw: Transaction Pin Success ",
  PartialWithdrawRecoverPinClick: "Partial Withdraw: Recover Pin Click ",
  LoginUIDPasswordClick: "Login: UID Password Click",
  LoginQRSignUpClick: "Login:QR Sign up Click",
  LoginButtonClick: "Login: Button Click",
  LoginLanguageChangeClick: "Login:Language Change Click",
  LoginLanguageChangeSelect: "Login:Language Select",
  QRSignupQRCodeClick: "QR Sign Up: QR Code Click",
  QRSignupButtonClick: "QR Sign Up: Confirm Button Click",
  CountrySignupButtonClick: "Country Sign Up: Confirm Button Click",
  SignupLacalGalleryBarcodeScanClick:
    "Sign Up Barcode Scan: Local Gallary Barcode Scan Click",
  SignupLacalGalleryBarcodeScanSuccess:
    "Sign Up Barcode Scan: Local Gallary Barcode Scan Success",
  SignupLacalGalleryBarcodeScanFailure:
    "Sign Up Barcode Scan: Local Gallary Barcode Scan Failed",
  SignupBarcodeScanSuccess: "Sign Up Barcode Scan: Barcode Scan Success",
  SignupBarcodeScanFailure: "Sign Up Barcode Scan:  Barcode Scan Failed",
  SignUpLoginClick: "Sign Up: Login Click",
  SignUpSuccess: "Sign Up: Success",
  SignUpFailure: "Sign Up: Failure",
  VerificationGetOTPSuccess: "Verification: Get OTP Success",
  VerificationGetOTPFailure: "Verification: Get OTP Failure",
  VerificationOTPBindSuccess: "Verification: OTP Bind Success",
  VerificationOTPBindFailure: "Verification:  OTP Bind Failure",
  ChangeAccountPasswordNoAuthSuccess:
    "Change Account Password: No Auth Password Change Success",
  ChangeAccountPasswordNoAuthFailure:
    "Change Account Password: No Auth Password Change Failed",
  ChangeAccountPasswordSendOTPSuccess:
    "Change Account Password: Send OTP Success",
  ChangeAccountPasswordSendOTPFailure:
    "Change Account Password: Send OTP  Failed",
  ContactUsWebURLSuccess: "Contact US: Web URL Success",
  ContactUsWebURLFailure: "Contact US: Web URL Failed",
  ForgotUIDPasswordRecoverUserClick: "Forgot UID Password: Recover Users Click",
  ForgotUIDPasswordRecoverUsernameClick:
    "Forgot UID Password: Recover Username Click",
  RecoverUsernameSuccess: "Recover Username: Success",
  RecoverUsernameFailure: "Recover Username: Failure",
  RecoverUsernameForgotPasswordOTPSuccess:
    "Recover Username:Forgot Password OTP Success",
  RecoverUsernameForgotPasswordOTPFailure:
    "Recover Username:Forgot Password OTP Failed",
  RecoverUserClick: "Recover Users: User Click",
  OTPSendRegisterOTPSuccess: "OTP: Send Register OTP Success",
  OTPSendRegisterOTPFailure: "OTP: Send Register OTP Failed",
  OTPSendOTPSuccess: "OTP: Send OTP Success",
  OTPSendOTPFailure: "OTP: Send OTP Failed",
  RecoverPasswordSuccess: "Recover Password: Password Recover Success",
  RecoverPasswordFailure: "Recover Password: Password Recover Failed",
  ChangePinOTPWalletOTPSuccess: "Change Pin OTP: Wallet OTP Success",
  ChangePinOTPWalletOTPFailure: "Change Pin OTP: Wallet OTP Failed",
  ChangePinOTPSuccess: "Change Pin OTP: Change OTP Success",
  ChangePinOTPFailure: "Change Pin OTP: Change OTP Failed",
  CreatePinConfirmClick: "Create PIN: Confirm Click",
  CreateSecondPINSetPinSuccess: "Create Second PIN: Set Pin Success",
  CreateSecondPINSetPinFailed: "Create Second PIN: Set Pin Failed",
  RecoverPinSuccess: "Recover PIN:  Success",
  RecoverPinFailed: "Recover  PIN:  Failed",
  S3ServerURLSuccess: "Splash Screen: S3 Server URL Success",
  S3ServerURLFailure: "Splash Screen: S3 Server URL Failed",
  UpdateAppModalOpen: "Update App Modal: Open",
  UpdateAppModalUpdateClick: "Update App Modal: Update Click",
  AppUpdatedSuccess: "App Updated:Success",
  CodePushCheckingForUpdate: "Code Push: Checking For Update",
  CodePushDownloadPackage: "Code Push: Downloading Package ",
  CodePushAwaitingUserAction: "Code Push: Awaiting User Action",
  CodePushInstallingupdate: "Code Push: Installing Update.",
  CodePushAppUpdated: "Code Push: App Update Success",
  CodePushUpdatedCancel: "Code Push: Update cancelled by User.",
  CodePushUpdatedInstallAndAppliedOnRestart:
    "Code Push: Update installed and will be applied on restart..",
  CodePushUnknownError: "Code Push: An unknown error occurred.",
  NotificationSuccess: "Notifications: Success.",
  NotificationFailure: "Notifications: Failed.",
  NotificationViewSuccess: "Notifications: Viewed Success.",
  NotificationViewFailure: "Notifications: Viewed Failed.",

  JDBFishLaunchSuccess: "JDBFish Detail: Games Launch Success",
  JDBFishLaunchFailure: "JDBFish Detail:  Games Launch Failed",
  JDBFishGameSuccess: "JDBFish Detail: Games Success",
  JDBFishGameFailure: "JDBFish Detail:  Games Failed",
  SpadeFishLaunchSuccess: "SpadeFish Detail: Games Launch Success",
  SpadeFishLaunchFailure: "SpadeFish Detail:  Games Launch Failed",
  SpadeFishGameSuccess: "SpadeFish Detail: Games Success",
  SpadeFishGameFailure: "SpadeFish Detail:  Games Failed",
  YLFishLaunchSuccess: "YLFish Detail: Games Launch Success",
  YLFishLaunchFailure: "YLFish Detail:  Games Launch Failed",
  YLFishGameSuccess: "YLFish Detail: Games Success",
  YLFishGameFailure: "YLFish Detail:  Games Failed",
  CouponRedeemCode: `Coupon: On Click Redeem`,
  CouponBankTopup: `Coupon: Bank Topup Click`,
  CouponInstantTopUp: `Coupon: Instant Topup Click`,
  CouponQRTopUp: `Coupon: QR Topup Click`,
  CouponUsdtTopUp: `Coupon: Usdt Topup Click`,
  AvatarUpdateSuccess: "Avatar: Update Success",
  AvatarUpdateFailure: "Avatar:  Update Failed",
  ProfileTransferBalanceSuccess: "Profile: Transfer Balance Success",
  ProfileTransferBalanceFailure: "Profile: Transfer Balance Failed",
  KingKingGameSuccess: "KingKing Detail: Games Success",
  KingKingGameFailure: "KingKing Detail:  Games Failed",
  KingKingLaunchSuccess: "KingKing Detail: Games Launch Success",
  KingKingLaunchFailure: "KingKing Detail:  Games Launch Failed",
  NextSpinGameSuccess: "NextSpin Detail: Games Success",
  NextSpinGameFailure: "NextSpin Detail:  Games Failed",
  NextSpinLaunchSuccess: "Next Spin Detail: Games Launch Success",
  NextSpinLaunchFailure: "Next Spin Detail:  Games Launch Failed",
  CrowdPlayGameSuccess: " Crowd Play Detail: Games Launch Success",
  CrowdPlayGameFailure: "Crowd Play Detail:  Games Launch Failed",
  CrowdPlayLaunchSuccess: "Crowd Play Detail: Games Launch Success",
  CrowdPlayLaunchFailure: "Crowd Play Detail:  Games Launch Failed",
  AESlotGameSuccess: "AE Slot Detail: Games Success",
  AESlotGameFailure: "AE Slot Detail:  Games Failed",
  AESlotLaunchSuccess: "AE Slot Detail: Games Launch Success",
  AESlotLaunchFailure: "AE Slot Detail:  Games Launch Failed",
  FunhouseCloseGameSuccess: "Funhouse Detail:  Game Close Success",
  FunhouseCloseGameFailure: "Funhouse Detail:  Game Close Failed",
  ChatMessageSuccess: "Chat Messages: Success",
  ChatMessageFailure: "Chat Messages: Failure",
  ChatSendMessageSuccess: "Chat Message: Send Success",
  ChatSendMessageFailure: "Chat Messages: Send Failure",
  CouponFetchListSuccess: "Coupon: Fetch List Success",
  CouponFetchListFailed: "Coupon: Fetch List Failed",
  IPLocationSuccess: "Splash Screen:Ip location Success",
  IPLocationFailure: "Splash Screen:Ip location Failure",
  AESexyGameSuccess: "AE Sexy Detail: Games Success",
  AESexyGameFailure: "AE Sexy Detail:  Games Failed",
  AESexyLaunchSuccess: "AE Sexy Detail: Games Launch Success",
  AESexyLaunchFailure: "AE Sexy Detail:  Games Launch Failed",

  SettingClearCacheClick: "Settings:Clear Cache Click",
  giftURLSuccess: "Gift:Url Success",
  giftURLFailure: "Gift:Url Failure",
  LKYSlotGameSuccess: "LKYSlot Detail: Games Success",
  LKYSlotGameFailure: "LKYSlot Detail:  Games Failed",
  LKYSlotLaunchSuccess: "LKYSlot Detail: Games Launch Success",
  LKYSlotLaunchFailure: "LKYSlot Detail:  Games Launch Failed",
  lkyPointsSuccess: "LKY Points: Success",
  lkyPointsFailure: "LKY Points: Failure",
  bankAccountBankListSuccess: "Bank Account: BankList Success",
  bankAccountBankListFailure: "Bank Account: BankList Failure",
  bankAccountVerificationSuccess: "Bank Account: Verification Success",
  bankAccountVerificationFailure: "Bank Account: Verification Failure",
  bankAccountsListSuccess: "Bank Account: Bank Accounts Success",
  bankAccountsListFailure: "Bank Account: Bank Accounts Failure",
  bankAccountAddBankClick: "Bank Account: Add Bank Account Click",
  bankAccountEditBankClick: "Bank Account: Edit Bank Account Click",
  verificationOTPSendRegisterOTPSuccess:
    "OTP Verification: Send Register OTP Success",
  verificationOTPSendRegisterOTPFailure:
    "OTP Verification: Send Register OTP Failed",
  verificationOTPSendOTPSuccess: "OTP Verification: Send OTP Success",
  verificationOTPSendOTPFailure: "OTP Verification: Send OTP Failed",
  TransactionRequestOTPSuccess: "Transaction Request OTP : Otp Send Success",
  TransactionRequestOTPFailure: "Transaction Request OTP:Otp Send Failed",
  TransactionRequestOtpTransferSuccess:
    "Transaction Request OTP : Transfer Money Success",
  TransactionRequestOTPTransferFailure:
    "Transaction Request OTP:Transfer Money Failed",

  TransactionRequestOtpWithdrawSuccess:
    "Transaction Request OTP : Withdraw Success",
  TransactionRequestOTPWithdrawFailure:
    "Transaction Request OTP:Withdraw Failed",

  TransactionRequestOTPWithdrawPreviewUSDTSuccess:
    "Transaction Request OTP: Preview USDT Success",
  TransactionRequestOTPWithdrawPreviewUSDTFailure:
    "Transaction Request OTP: Preview USDT Failed",
  TransactionRequestOTPVerifyOtpSuccess:
    "Transaction Request OTP: Verify OTP Success",
  TransactionRequestOTPVerifyOtpFailure:
    "Transaction Request OTP: Verify OTP Failed",
  WithdrawRequestUSDTSuccess: "Withdraw USDT Receipt: Request USDT Success",
  WithdrawRequestUSDTFailure: "Withdraw USDT Receipt: Request USDT Failed",
  WithdrawUSDTReceipClick: "Withdraw USDT Receipt: Request USDT Click",
  WithdrawUSDTAvailableBalanceSuccess:
    "Withdraw USDT: Available Balance Success",
  WithdrawUSDTAvailableBalanceFailure:
    "Withdraw USDT: Available Balance Failed",
  redmeeURLSuccess: "Redmee:Url Success",
  redmeeURLFailure: "Redmee:Url Failure",
  GoogleVerificationCodeSuccess: "Google Verification Code: Success",
  GoogleVerificationCodeFailure: "Google Verification Code: Failure",

  GenerateGoogleAuthQRSuccess: "Google Auth Verification: Generate QR Success",
  GenerateGoogleAuthQRFailure: "Google Auth Verification: Generate QR Failure",

  SaveGoogleAuthQRSuccess: "Google Auth Verification: Save QR Success",
  SaveGoogleAuthQRFailure: "Google Auth Verification: Save QR Failure",

  RemoveGoogleAuthQRSuccess: "Google Auth Verification: Remove QR Success",
  RemoveGoogleAuthQRFailure: "Google Auth Verification: Remove QR Failure",
  RealNameVerficationBankAccountSuccess:
    "Real Name Verifcation: Bank Account Success",
  RealNameVerficationBankAccountFailure:
    "Real Name Verifcation: Bank Account Failed",
  RealNameVerficationStatusSuccess: "Real Name Verifcation: Status Success",
  RealNameVerficationStatusFailure: "Real Name Verifcation:Status Failed",

  RequestRealNameCertificationSuccess:
    "Real Name Verifcation: Request Certification Success",
  RequestRealNameCertificationFailure:
    "Real Name Verifcation:Request Certification Failed",

  RealNameVerficationBankAccountSelect:
    "Real Name Verifcation: Bank Account Select",
};

export const eventMessages = {
  BackButton: "Back button ",
};
