import React, { Component } from "react";
import { View, TouchableOpacity, Image } from "react-native";
import Text from "../../Text/MyText";

import {
  MainColor,
  SecondaryColor,
  FontMainColor,
  FontSecondaryColor,
  FontThirdColor,
} from "../../../UI/Color";
import {
  WidthDimen,
  HeightDimen,
  fontDimen,
  GetWidth,
  GetHeight,
} from "../../../UI/Dimensions";
import { strings } from "../../../Language/Language";

const BottomCard = (props) => {
  const { rank, username, amount, image, isSelf } = props;

  return (
    <View
      style={{
        marginTop: rank == 4 ? HeightDimen(60) : HeightDimen(25),
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <View
        style={{
          width: "auto",
          height: HeightDimen(90),
          aspectRatio: 1 / 1,
          borderRadius: 9999,
          borderWidth: HeightDimen(3),
          borderColor: SecondaryColor,
          backgroundColor: "white",
          zIndex: 99,
          overflow: "hidden",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Image
          style={{
            width: "auto",
            height: HeightDimen(90),
            aspectRatio: 1 / 1,
            resizeMode: "contain",
          }}
          source={{ uri: image }}
        />
      </View>
      <View
        style={{
          marginLeft: -HeightDimen(20),
        }}
      >
        <View
          style={{
            width: "60%",
            padding: HeightDimen(5),
            paddingLeft: HeightDimen(20),
            backgroundColor: isSelf ? MainColor : SecondaryColor,
            borderTopLeftRadius: HeightDimen(10),
            borderTopRightRadius: HeightDimen(50),
          }}
        >
          <Text
            style={{
              fontSize: fontDimen(15),
              color: isSelf ? "white" : MainColor,
            }}
          >
            {"Top " + rank}
          </Text>
        </View>
        <View
          style={{
            width: "auto",
            height: HeightDimen(70),
            aspectRatio: 3 / 1,
            backgroundColor: "rgba(237, 181, 61, 0.1)",
            borderTopRightRadius: HeightDimen(10),
            borderBottomLeftRadius: HeightDimen(10),
            borderBottomRightRadius: HeightDimen(10),
            justifyContent: "center",
            paddingLeft: HeightDimen(30),
          }}
        >
          <Text
            style={{
              fontSize: fontDimen(15),
              fontWeight: "bold",
            }}
          >
            {username}
          </Text>
          <Text
            style={{
              fontSize: fontDimen(15),
            }}
          >
            {amount}
          </Text>
        </View>
      </View>
    </View>
  );
};

export { BottomCard };
