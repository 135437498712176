import React, { Component } from "react";
import {
  View,
  TouchableOpacity,
  Modal,
  Image,
  StatusBar,
  Platform,
} from "react-native";
import Text from "../Text/MyText";

import {
  MainColor,
  SecondaryColor,
  FontMainColor,
  FontSecondaryColor,
  FontThirdColor,
} from "../../UI/Color";
import {
  WidthDimen,
  HeightDimen,
  fontDimen,
  GetWidth,
  GetHeight,
} from "../../UI/Dimensions";

import { ImageButton } from "../Button/ImageButton";
import { strings } from "../../Language/Language";
import { OTPCard } from "../Card/OTPCard";
import NumberPad from "../NumberPad/NumberPad";
import MyData from "../../Data/MyData";
import MyToast from "../Toast/MyToast";
import { CounterButton } from "../Button/CounterButton";

class EnterPhoneOtpPinModal extends Component {
  state = {
    OTP: "",
  };

  onEnterOTP = (num) => {
    let temp_OTP = this.state.OTP;
    if (num === "X") {
      if (temp_OTP.length > 0) {
        temp_OTP = temp_OTP.slice(0, -1);
        this.setState({ OTP: temp_OTP });
      }
    } else {
      if (temp_OTP.length < 6) {
        temp_OTP = temp_OTP + num;
        this.setState({ OTP: temp_OTP });
        if (temp_OTP.length == 6) {
          this.setState({ OTP: "" });
          this.props.onSuccEnterPin(temp_OTP);
        }
      }
    }
  };

  onBackPressed = () => {
    this.setState({ OTP: "" });
    this.props.onBackModal();
  };

  render() {
    const { isShow } = this.props;

    return (
      <Modal
        animationType="slide"
        transparent={true}
        visible={isShow}
        statusBarTranslucent
      >
        <View
          style={{
            flex: 1,
            backgroundColor: "rgba(0,0,0,0.5)",
          }}
        >
          <View
            style={{
              height: HeightDimen(60),
              justifyContent: "center",
              marginTop:
                Platform.OS === "ios" ? HeightDimen(40) : HeightDimen(30),
            }}
          >
            <View>
              <TouchableOpacity
                style={{
                  paddingLeft: WidthDimen(20),
                }}
                onPress={() => this.onBackPressed()}
              >
                <Image
                  source={require("../../Resources/Images/icon_back.png")}
                  style={{
                    width: "auto",
                    height: HeightDimen(15),
                    aspectRatio: 19 / 13,
                    resizeMode: "contain",
                  }}
                />
              </TouchableOpacity>
            </View>
          </View>
          <View
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <View
              style={{
                flexWrap: "wrap",
              }}
            >
              <View
                style={{
                  overflow: "hidden",
                  backgroundColor: "white",
                  borderRadius: HeightDimen(10),
                  alignItems: "center",
                }}
              >
                <Text
                  style={{
                    fontSize: fontDimen(20),
                    textAlign: "center",
                    marginBottom: HeightDimen(20),
                    marginTop: HeightDimen(30),
                  }}
                >
                  {strings.enter_otp}
                </Text>
                <this.render_otp_input_box />
                <View style={{ marginVertical: HeightDimen(10) }}>
                  <CounterButton onPress={() => this.props.onPressGetOtp()} />
                </View>
                <NumberPad onPressSelect={this.onEnterOTP} />
              </View>
            </View>
          </View>
        </View>
      </Modal>
    );
  }

  render_otp_input_box = () => {
    return (
      <View
        style={{
          justifyContent: "center",
          flexDirection: "row",

          marginLeft: -HeightDimen(10),
        }}
      >
        <OTPCard value={this.state.OTP} num={0} />
        <OTPCard marginLeft={WidthDimen(10)} value={this.state.OTP} num={1} />
        <OTPCard marginLeft={WidthDimen(10)} value={this.state.OTP} num={2} />
        <OTPCard marginLeft={WidthDimen(10)} value={this.state.OTP} num={3} />
        <OTPCard marginLeft={WidthDimen(10)} value={this.state.OTP} num={4} />
        <OTPCard marginLeft={WidthDimen(10)} value={this.state.OTP} num={5} />
      </View>
    );
  };
}

export { EnterPhoneOtpPinModal };
