import React, { Component } from 'react'
import { View, ActivityIndicator } from 'react-native'
import { MainColor } from '../../UI/Color'
import { HeightDimen } from '../../UI/Dimensions'

class InstantTopUpScreen extends Component {
  state = {
    url: this.props?.path,
    isLoading: false,
  }
  shouldComponentUpdate(props) {
    if (props.path == this.state.url) {
      return false
    }
    this.setState({ url: props.path })
    return true
  }
  onBackPressed = () => {
    if (this.props.route.params?.backToRedeem) {
      this.props.navigation.pop(2)
      return
    }
    this.props.route.params?.onGoBack()
    this.props.navigation?.goBack()
  }

  render() {
    return (
      <View
        style={{
          flex: 1,
        }}
      >
        {this.state.isLoading ? (
          <View
            style={{
              marginTop: HeightDimen(30),
            }}
          >
            <ActivityIndicator size="small" color={MainColor} />
          </View>
        ) : (
          <iframe
            src={this.state.url}
            height={'100%'}
            width={'100%'}
            frameBorder="0"
          />
        )}
      </View>
    )
  }
}

export default InstantTopUpScreen
