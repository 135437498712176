import React, { Component } from "react";
import { View, TouchableOpacity, Image } from "react-native";
import Text from "../../Text/MyText";

import {
  MainColor,
  SecondaryColor,
  FontMainColor,
  FontSecondaryColor,
  FontThirdColor,
} from "../../../UI/Color";
import { WidthDimen, HeightDimen, fontDimen } from "../../../UI/Dimensions";
import { strings } from "../../../Language/Language";

const WalletDetailsCard = (props) => {
  const { item } = props;

  return (
    <View
      style={{
        flexDirection: "row",
        height: HeightDimen(50),
        borderBottomWidth: HeightDimen(2),
        borderColor: "#EFF2F4",
        alignItems: "center",
      }}
    >
      <Text
        style={{
          flex: 1,
          textAlign: "center",
          fontSize: fontDimen(12),
        }}
      >
        {item.commission_date}
      </Text>
      <View
        style={{
          borderWidth: HeightDimen(1),
          height: "100%",
          borderColor: "#EFF2F4",
        }}
      ></View>
      <Text
        style={{
          flex: 1,
          textAlign: "center",
          fontSize: fontDimen(12),
        }}
      >
        {item.member_username}
      </Text>
      <View
        style={{
          borderWidth: HeightDimen(1),
          height: "100%",
          borderColor: "#EFF2F4",
        }}
      ></View>
      <Text
        style={{
          flex: 1,
          textAlign: "center",
          fontSize: fontDimen(12),
        }}
      >
        {item.product_name}
      </Text>
      <View
        style={{
          borderWidth: HeightDimen(1),
          height: "100%",
          borderColor: "#EFF2F4",
        }}
      ></View>
      <Text
        style={{
          flex: 1,
          textAlign: "center",
          fontSize: fontDimen(12),
        }}
      >
        {item.betTotal}
      </Text>
      <View
        style={{
          borderWidth: HeightDimen(1),
          height: "100%",
          borderColor: "#EFF2F4",
        }}
      ></View>
      <Text
        style={{
          flex: 1,
          textAlign: "center",
          fontSize: fontDimen(12),
        }}
      >
        {item.total && Number(item.total).toFixed(2)}
      </Text>
    </View>
  );
};

export { WalletDetailsCard };
