import React, { Component } from "react";
import { View, Image, TouchableOpacity } from "react-native";
import Text from "../Text/MyText";
import { HeightDimen, fontDimen, WidthDimen } from "../../UI/Dimensions";
import { FontMainColor, FontSecondaryColor } from "../../UI/Color";

const BankCard = props => {
  const { title, description, accountNo, onPress, wantIcon, bankLogo } = props;
  return (
    <TouchableOpacity
      onPress={onPress}
      style={{
        width: "100%",
        height: HeightDimen(70),
        backgroundColor: "#fef6eb",
        borderRadius: HeightDimen(10),
        flexDirection: "row",
        padding: HeightDimen(12),
        alignItems: "center",
        marginTop: HeightDimen(10),
      }}>
      <View
        style={{
          flex: 1,
          marginRight: HeightDimen(15),
          flexDirection: "row",
          alignItems: "center",
        }}>
        {bankLogo && (
          <Image
            source={{ uri: bankLogo }}
            style={{
              width: "auto",
              height: HeightDimen(50),
              aspectRatio: 1 / 1,
              resizeMode: "contain",
              marginRight: 10,
              borderRadius: 99999,
            }}
          />
        )}
        <View>
          <Text
            numberOfLines={1}
            style={{
              fontSize: fontDimen(15),
              color: FontMainColor,
              fontWeight: "bold",
            }}>
            {title}
          </Text>
          <Text
            numberOfLines={1}
            style={{
              fontSize: fontDimen(10),
              color: FontSecondaryColor,
            }}>
            {description}
          </Text>
        </View>
      </View>
      <Text
        style={{
          fontSize: fontDimen(15),
          color: FontMainColor,
          marginRight: HeightDimen(15),
        }}>
        {accountNo}
      </Text>

      {wantIcon && (
        <Image
          source={require("../../Resources/Images/icon_nextP.png")}
          style={{
            width: "auto",
            height: HeightDimen(17),
            aspectRatio: 1 / 1,
            resizeMode: "contain",
          }}
        />
      )}
    </TouchableOpacity>
  );
};

export { BankCard };
