import React, { Component } from 'react'
import { View, FlatList, Image } from 'react-native'
import Text from '../../../Component/Text/MyText'
import {
  MainColor,
  SecondaryColor,
  FontMainColor,
  FontSecondaryColor,
} from '../../../UI/Color'
import { WidthDimen, HeightDimen, fontDimen } from '../../../UI/Dimensions'
import { MainFlowHeaderContainerNoFeedback } from '../../../Component/Header/MainFlowHeader'
import { strings } from '../../../Language/Language'
import { TextButton } from '../../../Component/Button/TextButton'

import { numberWithCommas } from '../../../Utils/Common'

class AllWalletTransactionDetailScreen extends Component {
  state = {
    data: [],
    detailsArr: [],
  }

  componentDidMount() {
    let data = this.props.route.params.data
    let temp_arr = []
    let detail = data.jsonClickDetails
    Object.keys(detail.jsonText).map((e, i) => {
      // if (e !== "Rate") {
      temp_arr.push({
        id: i,
        name: e,
        value: detail.jsonText[e],
      })
      // }
    })
    this.setState({
      data: data,
      detailsArr: temp_arr,
    })
  }

  onBackPressed = () => {
    this.props.navigation.goBack()
  }

  onPress_details = () => {
    this.props.navigation.navigate('AllWalletTransactionAdditionalDetail', {
      data: this.state.data.jsonClickDetails.detailJson,
    })
  }

  render() {
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: 'white',
        }}
      >
        <MainFlowHeaderContainerNoFeedback
          title={strings.t('transaction')}
          onBackPress={() => this.onBackPressed()}
        >
          <View
            style={{
              flex: 1,
              alignItems: 'center',
              paddingTop: HeightDimen(30),
            }}
          >
            <Image
              style={{
                width: 'auto',
                height: HeightDimen(60),
                aspectRatio: 1 / 1,
              }}
              source={{
                uri:
                  this.state.data.transaction_type === 'transfer' &&
                  this.state.data.transaction_flow_type === 'out'
                    ? this.state.data.transfer_fromto_member_avatar_url
                    : this.state.data.transaction_type === 'transfer' &&
                      this.state.data.transaction_flow_type === 'in'
                    ? this.state.data.transfer_fromto_member_avatar_url
                    : this.state.data.product_image_url,
              }}
            ></Image>
            <Text
              style={{
                fontSize: fontDimen(16),
                marginTop: HeightDimen(10),
              }}
            >
              {this.state.data.transaction_type === 'transfer'
                ? this.state.data.transfer_fromto_member_name
                : this.state.data.product_name}
            </Text>
            <Text
              style={{
                fontSize: fontDimen(23),
                marginTop: HeightDimen(20),
                textAlign: 'center',
                color:
                  this.state.data.transaction_flow_type === 'out' ||
                  (this.state.data.transaction_type === 'gameOut' &&
                    this.state.data.transaction_amount < 0) ||
                  (this.state.data.transaction_type === 'gameIn' &&
                    this.state.data.transaction_amount < 0) ||
                  this.state.data.transaction_type === 'withdraw'
                    ? 'red'
                    : this.state.data.transaction_type === 'commission'
                    ? SecondaryColor
                    : this.state.data.product_name === 'Interest'
                    ? 'green'
                    : MainColor,
              }}
            >
              {`${numberWithCommas(
                Number(this.state.data.transaction_amount).toFixed(2)
              )} ${this.state.data.transaction_currency}`}
            </Text>
            <View
              style={{
                width: '95%',
                borderBottomWidth: HeightDimen(1),
                marginTop: HeightDimen(15),
              }}
            ></View>
            <Text
              style={{
                fontSize: fontDimen(15),
                marginTop: HeightDimen(10),
                color: FontSecondaryColor,
              }}
            >
              {this.state.data.transaction_number}
            </Text>
            <View
              style={{
                flex: 1,
                width: '90%',
                marginTop: HeightDimen(30),
              }}
            >
              <FlatList
                numColumns={1}
                data={this.state.detailsArr}
                renderItem={({ item, index }) => (
                  <View
                    style={{
                      flexDirection: 'row',
                      marginBottom: HeightDimen(15),
                    }}
                  >
                    <Text
                      style={{
                        fontSize: fontDimen(15),
                        color: FontSecondaryColor,
                        width: WidthDimen(110),
                      }}
                    >
                      {item.name}
                    </Text>
                    <Text
                      style={{
                        fontSize: fontDimen(15),
                        color: FontMainColor,
                        flex: 1,
                      }}
                    >
                      {item.value}
                    </Text>
                  </View>
                )}
                keyExtractor={(item) => String(item.id)}
              />
            </View>

            {this.state.data.jsonClickDetails &&
              this.state.data.jsonClickDetails.detailClick == 'true' && (
                <View
                  style={{
                    alignItems: 'center',
                    marginBottom: HeightDimen(40),
                  }}
                >
                  <TextButton
                    label={strings.t('details')}
                    size={4}
                    onPress={() => this.onPress_details()}
                  />
                </View>
              )}
          </View>
        </MainFlowHeaderContainerNoFeedback>
      </View>
    )
  }
}

export default AllWalletTransactionDetailScreen
