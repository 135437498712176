import React, { Component } from 'react'
import { View, FlatList, ActivityIndicator } from 'react-native'
import Text from '../../Component/Text/MyText'
import { MainColor, FontMainColor } from '../../UI/Color'
import { HeightDimen, fontDimen } from '../../UI/Dimensions'
import { MainFlowHeaderContainerNoFeedback } from '../../Component/Header/MainFlowHeader'
import { ProfileCard } from '../../Component/Card/ProfileCard'
import interestCaller from '../../API/InterestCaller'
import myData from '../../Data/MyData'
import MyToast from '../../Component/Toast/MyToast'
import { InterestAccountCard } from '../../Component/Card/InterestAccountCard'
import { strings } from '../../Language/Language'
import MyData from '../../Data/MyData'

import { numberWithCommas } from '../../Utils/Common'

class InterestScreen extends Component {
  state = {
    isLoading: true,
    interestAccount: [],
  }

  async componentDidMount() {
    await this.getInterestAccount()
  }

  getInterestAccount = async () => {
    await interestCaller
      .getInterestAccount(myData.authToken)
      .then((res) => {
        let tempArr = res.response.interestAccount
        let toShowArr = []
        for (let i = 0; i < tempArr.length; i++) {
          if (tempArr[i].interest_account_status == 'running') {
            toShowArr.push(tempArr[i])
          }
        }
        this.setState({
          isLoading: false,
          interestAccount: toShowArr,
        })
      })
      .catch((err) => {
        if (err.response.status == '514') {
          this.props.expiredToken_logout()
        } else {
          MyToast.popToast(err.response.data.msg)
        }
      })
  }

  onBackPressed = () => {
    this.props.route.params.onGoBack(true)
    this.props.navigation.goBack()
  }

  go_interestDetails = (index) => {
    this.props.navigation.navigate('InterestDetails', {
      value: this.state.interestAccount[index],
      onGoBack: (data) => {
        this.onCancelOrWithdrawSuccess(data)
      },
      onGoBackRefresh: (data) => {
        this.onModifyRefresh(data)
      },
    })
  }

  onModifyRefresh = (data) => {
    if (data) {
      this.setState({
        isLoading: true,
      })
      this.getInterestAccount()
    }
  }

  onCancelOrWithdrawSuccess = (data) => {
    MyToast.popToast(strings.t('interest_account_removed'))
    this.setState({
      isLoading: true,
    })
    this.getInterestAccount()
  }

  go_addInterest = () => {
    this.props.navigation.navigate('CreateInterest', {
      onGoBack: (data) => {
        this.refresh(data)
      },
    })
  }

  refresh = (data) => {
    MyToast.popToast(strings.t('interest_account_created_succ'))
    this.setState({
      isLoading: true,
    })
    this.getInterestAccount()
  }

  onPress_interestHistory = () => {
    this.props.navigation.navigate('InterestHistory')
  }

  render() {
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: 'white',
        }}
      >
        <MainFlowHeaderContainerNoFeedback
          title={strings.t('interest')}
          onBackPress={() => this.onBackPressed()}
          toShowImage={
            MyData.temp_json_object.response.user.member_level == 1
              ? false
              : true
          }
          toShowPress={() => this.go_addInterest()}
          toShowImageUrl={require('../../Resources/Images/icon_plus.png')}
          // toShow={true}
          // toShowText={'Transaction'}
          // toShowPress={() => this.onPress_transaction()}
        >
          <View
            style={{
              flex: 1,
              paddingLeft: HeightDimen(20),
              paddingRight: HeightDimen(20),
              marginTop: HeightDimen(20),
            }}
          >
            {this.state.isLoading ? (
              <View>
                <ActivityIndicator size="small" color={MainColor} />
              </View>
            ) : (
              <View
                style={{
                  flex: 1,
                }}
              >
                {this.state.interestAccount.length == 0 ? (
                  <Text
                    style={{
                      fontSize: fontDimen(15),
                      color: FontMainColor,
                      textAlign: 'center',
                    }}
                  >
                    {strings.t('you_have_no_interest_account')}
                  </Text>
                ) : (
                  <View
                    style={{
                      flex: 1,
                    }}
                  >
                    <ProfileCard
                      src={require('../../Resources/Images/Wallet/icon_history.png')}
                      label={strings.t('interest_history')}
                      onPress={() => this.onPress_interestHistory()}
                    />
                    <FlatList
                      showsHorizontalScrollIndicator={false}
                      numColumns={1}
                      style={{
                        marginTop: HeightDimen(20),
                      }}
                      data={this.state.interestAccount}
                      renderItem={({ item, index }) => (
                        <InterestAccountCard
                          name={
                            strings.t('interest') +
                            ' ' +
                            item.interest_account_id
                          }
                          status={item.interest_account_status}
                          balance={numberWithCommas(
                            Number(item.interest_account_balance).toFixed(2)
                          )}
                          amount={numberWithCommas(
                            Number(item.interest_account_amount).toFixed(2)
                          )}
                          createdDate={item.interest_account_created_datetime}
                          countdownHour={item.countdownHour}
                          onPress={() => this.go_interestDetails(index)}
                        />
                      )}
                      keyExtractor={(item) => String(item.interest_account_id)}
                    />
                  </View>
                )}
              </View>
            )}
          </View>
        </MainFlowHeaderContainerNoFeedback>
      </View>
    )
  }
}

export default InterestScreen
