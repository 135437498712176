import React, { Component } from 'react'
import { View } from 'react-native'
import { HeightDimen } from '../../UI/Dimensions'
import { LoginFlowHeaderContainer } from '../../Component/Header/LoginFlowHeader'
import { TextButton } from '../../Component/Button/TextButton'
import { NormalTextInput } from '../../Component/Input/NormalTextInput'
import APICaller from '../../API/APICaller'
import Mydata from '../../Data/MyData'
import MyToast from '../../Component/Toast/MyToast'
import { strings } from '../../Language/Language'
import { PhoneTextInput } from '../../Component/Input/PhoneTextInput'
import { phoneRegex } from '../../Utils/Common'

class RecoverPasswordScreen extends Component {
  state = {
    // phoneNumber: '******* 3222',
    // email: '*******@gmail.com',
    // selected: true
    // pin: '',
    // confirm_pin: '',
    isLoading: false,
    phone: '',
    username: '',
    code: '+60',
  }

  handle_phone = (phone) => {
    this.setState({ phone: phone })
  }
  handle_username = (username) => {
    this.setState({ username: username })
  }
  onBackPressed = () => {
    this.props.navigation.goBack()
  }

  onNextPressed = async () => {
    if (this.state.phone != '' && this.state.username != '') {
      this.setState({ isLoading: true })
      await APICaller.recoverPassword(
        this.state.phone.trim(),
        this.state.code,
        this.state.username.trim()
      )
        .then((res) => {
          this.setState({ isLoading: false })
          Mydata.userToken = res.response.userToken
          this.props.navigation.navigate('Change', {
            username: this.state.username.trim(),
            phone: this.state.phone.trim(),
            code: this.state.code,
          })
        })
        .catch((err) => {
          MyToast.popToast(strings.t('invalid_phone_number'))
          //console.log(err);
          this.setState({ isLoading: false })
        })
    } else {
      MyToast.popToast(strings.t('please_enter_phone_number'))
    }
  }
  onSelectCode(code) {
    this.setState({
      code: code,
    })
  }
  render() {
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: 'white',
        }}
      >
        <LoginFlowHeaderContainer
          onBackPress={() => this.onBackPressed()}
          mainText={strings.t('recover_password')}
          subText={strings.t('please_enter_phone_number')}
        >
          <View
            style={{
              flex: 1,
              paddingLeft: HeightDimen(30),
              paddingRight: HeightDimen(30),
              paddingBottom: HeightDimen(30),
            }}
          >
            <View
              style={{
                marginTop: HeightDimen(40),
              }}
            >
              <PhoneTextInput
                placeholder={strings.t('phone_number')}
                onSelectCodePress={(val) => this.onSelectCode(val)}
                bottom={true}
                top={true}
                isNumber={true}
                value={this.state.phone}
                onChangeText={this.handle_phone}
                marginBottom={HeightDimen(20)}
              />
              <NormalTextInput
                placeholder={strings.t('username_text')}
                onChangeText={this.handle_username}
                type={'username'}
                value={this.state.username}
              />
            </View>

            <View
              style={{
                width: '100%',
                alignItems: 'center',
                marginTop: HeightDimen(40),
              }}
            >
              <TextButton
                label={strings.t('next')}
                size={5}
                onPress={() => this.onNextPressed()}
                isLoading={this.state.isLoading}
              />
            </View>
          </View>
        </LoginFlowHeaderContainer>
      </View>
    )
  }
}

export default RecoverPasswordScreen
