export const MainColor = "#2A3036";
export const SecondaryColor = "#FBD87E";

export const FontMainColor = "#000000";
export const FontSecondaryColor = "#7A7A7A";
export const FontThirdColor = "#FFFFFF";
export const PrimaryColor = "#E0E0E0";
export const LightYellow = "#ffe58b";
export const HeaderColor = "#ffee8d";

//fonts
export const EN_Font = "Race-Sport";
export const TH_Font = "Pattaya";
export const CH_Font = "FZDaHei-B02S";

export const GILROY_BOLD = "Gilroy-ExtraBold";
export const GILROY_NORMAL = "Gilroy-SemiBold";
