import axios from 'axios'
import { apiUrls } from './APIurl'
import storageData from '../Data/StorageData'
import MyData from '../Data/MyData'

class CoinCaller {
  getCoinHistory = async (authToken, fromDates, toDates) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.getCoinHistory}`,
          {
            data: {
              fromDate: fromDates,
              toDate: toDates,
              language: storageData.get_selected_language(),
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}

const coinCaller = new CoinCaller()
export default coinCaller
