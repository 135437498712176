import React, { Component } from 'react'
import { View, Text, Image, StyleSheet, ActivityIndicator } from 'react-native'
import { strings } from '../../Language/Language'
import { MainFlowHeaderContainerNoFeedback } from '../../Component/Header/MainFlowHeader'
import { MainColor, SecondaryColor } from '../../UI/Color'
import { fontDimen, HeightDimen } from '../../UI/Dimensions'
import MyData from '../../Data/MyData'

import notificationCaller from '../../API/NotificationCaller'

class NotificationDetailScreen extends Component {
  state = {
    isLoading: false,
    notification: {},
  }
  async componentDidMount() {
    await this.getNotificationDetail()
  }
  getNotificationDetail = async () => {
    this.setState({
      isLoading: true,
    })
    await notificationCaller
      .notificationDetail(MyData.authToken, this.props.route.params.notiKey)
      .then((res) => {
        this.setState({ notification: res?.response?.notification })
      })
      .catch((err) => {
        if (err.response.status == 514) {
          this.props.expiredToken_logout()
        } else {
          MyToast.popToast(err.response.data.msg)
        }
      })
      .finally(() => {
        this.setState({
          isLoading: false,
        })
      })
  }

  onBackPressed = () => {
    this.props.route.params.onGoBack()
    this.props.navigation.goBack()
  }

  render() {
    return (
      <MainFlowHeaderContainerNoFeedback
        title={strings.t('notification_detail')}
        onBackPress={() => this.onBackPressed()}
      >
        <View
          style={{
            flex: 1,
          }}
        >
          <View style={styles.banner}>
            <View style={{ marginTop: HeightDimen(20) }}>
              <Text
                style={{
                  ...styles.label,
                  color: 'white',
                  fontSize: fontDimen(16),
                }}
              >
                {`${strings.t('notification')} ${
                  this.state.notification?.notification_type || ''
                }`}
              </Text>
            </View>
            <Image
              source={{
                uri: this.state.notification?.notification_image_url,
              }}
              style={{ ...styles.bannerLogo }}
            />
          </View>
          {!this.state.isLoading ? (
            <>
              <View style={styles.notificationHeader}>
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <View style={{ marginLeft: HeightDimen(10) }}>
                    <Text
                      style={{
                        color: 'white',
                        fontSize: fontDimen(17),
                        fontWeight: 'bold',
                      }}
                    >
                      {this.state.notification?.notification_title}
                    </Text>
                    <Text
                      style={{
                        color: 'white',
                        fontSize: fontDimen(14),
                        marginTop: HeightDimen(5),
                      }}
                    >
                      {this.state.notification?.created_datetime}
                    </Text>
                  </View>
                </View>
              </View>
              <View
                style={{
                  flex: 1,
                }}
              >
                <Text
                  style={{
                    fontSize: fontDimen(15),
                    fontWeight: 'bold',
                    marginTop: 10,
                    marginLeft: 10,
                  }}
                >
                  {this.state.notification?.notification_message}
                </Text>
              </View>
            </>
          ) : (
            <View
              style={{
                flex: 1,
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <ActivityIndicator size="small" color={SecondaryColor} />
            </View>
          )}
        </View>
      </MainFlowHeaderContainerNoFeedback>
    )
  }
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },

  label: {
    fontSize: fontDimen(14),
    color: MainColor,
    fontWeight: 'bold',
  },

  banner: {
    height: 120,
    backgroundColor: SecondaryColor,
    paddingHorizontal: HeightDimen(20),
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  bannerLogo: {
    height: 70,
    resizeMode: 'contain',
    width: 'auto',
    aspectRatio: 1 / 1,
  },
  notificationHeader: {
    backgroundColor: MainColor,
    marginTop: -40,
    padding: HeightDimen(15),
    marginHorizontal: HeightDimen(20),
    borderRadius: HeightDimen(5),
    elevation: 2,
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: HeightDimen(15),
    justifyContent: 'space-between',
  },
})
export default NotificationDetailScreen
