import React, { Component } from 'react'
import {
  View,
  TouchableOpacity,
  Image,
  ActivityIndicator,
  BackHandler,
  Text,
  ScrollView,
} from 'react-native'

import { HeightDimen, fontDimen } from '../../UI/Dimensions'
import { strings } from '../../Language/Language'
import {
  MainFlowHeaderContainer,
  MainFlowHeaderContainerNoFeedback,
} from '../../Component/Header/MainFlowHeader'
import { ConfirmCancelModal } from '../../Component/Modal/ConfirmCancelModal'
import { FontSecondaryColor, MainColor, SecondaryColor } from '../../UI/Color'
import walletAPICaller from '../../API/WalletAPICaller'
import MyData from '../../Data/MyData'
import { numberWithCommas, takeScreenShot } from '../../Utils/Common'
import MyToast from '../../Component/Toast/MyToast'

class WithdrawHistoryDetailsScreen extends Component {
  state = {
    bankDetails: '',
    selectedDetails: '',
    showCancelModal: false,
    isLoading: false,
    status: '',
    toShowCancel: false,
    toRefresh: false,
    counter: '',
    isCoundownLeft: false,
  }

  constructor(props) {
    super(props)
    this.handleBackButtonClick = this.handleBackButtonClick.bind(this)
  }

  componentDidMount() {
    BackHandler.addEventListener(
      'hardwareBackPress',
      this.handleBackButtonClick
    )
    var bankList = this.props.route.params.bankList
    var selectedTopUpDetails = this.props.route.params.selectedTopUpDetails
    console.log(selectedTopUpDetails)
    this.setState({
      selectedDetails: selectedTopUpDetails,
      status: selectedTopUpDetails.withdraw_status,
      toShowCancel:
        selectedTopUpDetails.withdraw_status == 'pending' ? true : false,
    })

    var bank_details = []
    var tempTopUpDetails = []
    if (selectedTopUpDetails != null) {
      tempTopUpDetails = selectedTopUpDetails

      for (let i = 0; i < bankList.length; i++) {
        if (
          bankList[i].topup_bank_id == selectedTopUpDetails.withdraw_bank_id
        ) {
          bank_details = bankList[i]
          this.setState({ bankDetails: bank_details })
          break
        }
      }
    }
    this.checkWithdraw()
  }

  componentWillUnmount() {
    BackHandler.removeEventListener(
      'hardwareBackPress',
      this.handleBackButtonClick
    )
    clearInterval(this.countdown)
  }

  handleBackButtonClick() {
    this.onBackPressed()
    return true
  }

  startTimer(duration) {
    var timer = duration,
      minutes,
      seconds
    this.countdown = setInterval(() => {
      minutes = parseInt(timer / 60, 10)
      seconds = parseInt(timer % 60, 10)

      minutes = minutes < 10 ? '0' + minutes : minutes
      seconds = seconds < 10 ? '0' + seconds : seconds
      this.setState({
        counter: minutes + ':' + seconds,
      })
      if (--timer < 0) {
        clearInterval(this.countdown)
        this.setState({
          isCoundownLeft: false,
        })
      }
    }, 1000)
  }
  checkWithdraw = async () => {
    await walletAPICaller
      .onCheckWithdraw(MyData.authToken)
      .then((res) => {
        const baseModel = res?.data?.response?.info
        if (baseModel?.countdown > 0) {
          this.setState({ isCoundownLeft: true })
          this.startTimer(baseModel?.countdown)
        }
      })
      .catch((err) => {
        if (err.response.status == '514') {
          this.onExpireTokenLogout()
        } else {
          MyToast.popToast(err.response.data.msg)
        }
      })
  }
  onBackPressed = () => {
    this.props.route.params.onGoBack(this.state.toRefresh)
    this.props.navigation.goBack()
  }

  renderStatusIcon(status) {
    let backgroundColor = SecondaryColor
    let iconName = require('../../Resources/Images/Profile/icon_setting.png')
    if (status.toLowerCase() == 'rejected') {
      backgroundColor = '#ff4c4c'
      iconName = require('../../Resources/Images/close-cross.png')
    } else if (status.toLowerCase() == 'approve') {
      backgroundColor = 'green'
      iconName = require('../../Resources/Images/icon_tick.png')
    } else if (status.toLowerCase() == 'pending') {
      iconName = require('../../Resources/Images/clock-regular.png')
    }
    return (
      <View
        style={{
          ...styles.statusIconInnerContainer,
          backgroundColor: backgroundColor,
        }}
      >
        <Image
          source={iconName}
          style={{
            height: HeightDimen(40),
            aspectRatio: 1 / 1,
            resizeMode: 'contain',
            tintColor: 'white',
          }}
        />
      </View>
    )
  }
  renderTitleDescription(title, desc, isBackground) {
    return (
      <View
        style={{
          justifyContent: 'space-between',
          flexDirection: 'row',
          marginBottom: HeightDimen(10),
          flex: 1,
        }}
      >
        <Text
          style={{
            fontSize: fontDimen(15),
            color: FontSecondaryColor,
            flex: 0.6,
          }}
        >
          {title}
        </Text>
        {isBackground ? (
          <Text
            style={{
              fontSize: fontDimen(15),
              color: 'black',
              backgroundColor: SecondaryColor,
              padding: 5,
              textAlign: 'right',
              borderRadius: 5,
              width: 60,
              textAlign: 'center',
            }}
          >
            {desc ? desc : 'N/A'}
          </Text>
        ) : (
          <Text
            style={{
              fontSize: fontDimen(15),
              color: 'black',
              textAlign: 'right',
              flex: 1,
            }}
          >
            {desc ? desc : 'N/A'}
          </Text>
        )}
      </View>
    )
  }
  onConfirm = async () => {
    this.toggle_cancal_modal()
    this.setState({ isLoading: true })
    //console.log(this.state.selectedDetails.transaction_id)
    await walletAPICaller
      .onCancelWithdraw(
        MyData.authToken,
        this.state.selectedDetails.transaction_id
      )
      .then((res) => {
        this.setState({
          isLoading: false,
          status: 'rejected',
          toShowCancel: false,
          toRefresh: true,
        })
        console.log(res)
      })
      .catch((err) => {
        this.setState({ isLoading: false })
        if (err.response.status == 514) {
          this.props.expiredToken_logout()
        } else {
          MyToast.popToast(err.response.data.msg)
        }
      })
  }

  toggle_cancal_modal = () => {
    this.setState({ showCancelModal: !this.state.showCancelModal })
  }

  getTopupStatus(status) {
    if (status.toLowerCase().includes('pending')) return strings.t('pending')
    else if (status.toLowerCase().includes('process'))
      return strings.t('in_inspection')
    else if (status.toLowerCase().includes('rejected'))
      return strings.t('rejected')
    else if (status.toLowerCase().includes('hold')) return strings.t('hold')
    else return strings.t('success')
  }
  render() {
    return (
      <View style={styles.container}>
        <ConfirmCancelModal
          isShow={this.state.showCancelModal}
          textResource={strings.t('confirm_to_remove_withdraw')}
          onConfirmPress={() => this.onConfirm()}
          onBackPress={() => {
            this.toggle_cancal_modal()
          }}
        />

        <MainFlowHeaderContainerNoFeedback
          title={strings.t('withdraw_details')}
          onBackPress={() => this.onBackPressed()}
          bgImage={require('../../Resources/Images/reciept_background.png')}
        >
          <ScrollView
            style={{ flex: 1 }}
            bounces={false}
            // contentContainerStyle={{
            //   flexGrow: 1,
            //   justifyContent: "center",
            // }}
          >
            <View style={styles.innerContainer}>
              <View style={styles.recieptContainer}>
                <View style={styles.statusIconContainer}>
                  {this.renderStatusIcon(this.state.status)}
                </View>
                <View
                  style={{
                    alignItems: 'center',
                    marginBottom: HeightDimen(30),
                    marginTop: HeightDimen(50),
                  }}
                >
                  <Text
                    style={{
                      fontWeight: 'bold',
                      color: MainColor,
                      fontSize: fontDimen(22),
                    }}
                  >
                    {strings.t('withdraw')}{' '}
                    {this.getTopupStatus(this.state.status)}
                  </Text>
                  <Text
                    style={{
                      color: FontSecondaryColor,
                      fontSize: fontDimen(16),
                      marginTop: HeightDimen(10),
                    }}
                  >
                    {strings.t('total_amount')}
                  </Text>
                  <View
                    style={{
                      flexDirection: 'row',
                      alignItems: 'flex-end',
                    }}
                  >
                    <Text
                      style={{
                        fontSize: fontDimen(13),
                        color: 'black',
                        marginBottom: HeightDimen(5),
                      }}
                    >
                      {MyData.country_code}
                    </Text>
                    <Text
                      style={{
                        fontSize: fontDimen(30),
                        color: 'black',
                        marginLeft: HeightDimen(8),
                        fontWeight: 'bold',
                      }}
                    >
                      {numberWithCommas(
                        Number(
                          this.state.selectedDetails.transaction_amount
                        ).toFixed(2)
                      )}
                    </Text>
                  </View>
                </View>
                {this.renderTitleDescription(
                  strings.t('transaaction_no'),
                  `${this.state.selectedDetails.transaction_number}`
                )}
                {this.renderTitleDescription(
                  strings.t('bank'),
                  this.state.selectedDetails.withdrawBank
                )}
                {this.renderTitleDescription(
                  strings.t('account_name'),
                  this.state.selectedDetails.withdrawBankName
                )}
                {this.renderTitleDescription(
                  strings.t('account_no'),
                  this.state.selectedDetails.withdrawBankNo
                )}
                {this.renderTitleDescription(
                  strings.t('date'),
                  this.state.selectedDetails.transaction_created_datetime
                )}
                {this.renderTitleDescription(
                  strings.t('remarks'),
                  this.state.selectedDetails.transaction_remark
                )}
                {this.state.isCoundownLeft ? (
                  this.renderTitleDescription(
                    strings.t('timer'),
                    this.state.counter,
                    true
                  )
                ) : (
                  <></>
                )}

                {this.state.isCoundownLeft && (
                  <Text
                    style={{
                      fontSize: fontDimen(16),
                      fontWeight: 'bold',
                      textAlign: 'center',
                      marginVertical: HeightDimen(10),
                      color: 'red',
                    }}
                  >
                    {strings.t('withdraw_duration_text')}
                  </Text>
                )}
              </View>

              <TouchableOpacity
                onPress={() => takeScreenShot()}
                style={styles.button}
              >
                <Image
                  source={require('../../Resources/Images/send.png')}
                  style={{
                    height: HeightDimen(20),
                    resizeMode: 'contain',
                    width: 20,
                  }}
                />
                <Text style={{ marginLeft: HeightDimen(10) }}>
                  {strings.t('share')}
                </Text>
              </TouchableOpacity>
              {!this.state.isCoundownLeft && (
                <TouchableOpacity
                  onPress={() =>
                    this.props.navigation.navigate('CustomerService', {
                      onGoBack: (data) => {},
                    })
                  }
                  style={styles.button}
                >
                  <Text style={{ marginLeft: HeightDimen(10) }}>
                    {strings.t('customer_service')}
                  </Text>
                </TouchableOpacity>
              )}
              {this.state.toShowCancel && (
                <TouchableOpacity
                  onPress={() => {
                    this.toggle_cancal_modal()
                  }}
                  style={{ ...styles.button, backgroundColor: MainColor }}
                >
                  {!this.state.isLoading ? (
                    <Text
                      style={{ marginLeft: HeightDimen(10), color: 'white' }}
                    >
                      {strings.t('cancel')}
                    </Text>
                  ) : (
                    <ActivityIndicator size="small" color={'white'} />
                  )}
                </TouchableOpacity>
              )}
            </View>
          </ScrollView>
        </MainFlowHeaderContainerNoFeedback>
      </View>
    )
  }
}
const styles = {
  container: {
    flex: 1,
    backgroundColor: 'white',
  },
  innerContainer: {
    flex: 1,
    paddingHorizontal: HeightDimen(5),
    justifyContent: 'center',
    marginTop: HeightDimen(90),
  },
  statusIconContainer: {
    position: 'absolute',
    alignItems: 'center',
    alignSelf: 'center',
    top: -30,
  },
  statusIconInnerContainer: {
    height: 80,
    width: 80,
    borderRadius: 40,
    backgroundColor: SecondaryColor,
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 5,
    borderColor: 'white',
  },
  button: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 7,
    marginBottom: HeightDimen(10),
    marginHorizontal: HeightDimen(10),
    backgroundColor: 'white',
    height: HeightDimen(50),
  },
  recieptContainer: {
    padding: HeightDimen(10),
    backgroundColor: 'white',
    marginHorizontal: HeightDimen(10),
    borderRadius: HeightDimen(10),
    marginBottom: HeightDimen(10),
  },
}
export default WithdrawHistoryDetailsScreen
