import React, { Component } from "react";
import { View, FlatList, ActivityIndicator } from "react-native";
import { MainColor } from "../../UI/Color";
import { HeightDimen } from "../../UI/Dimensions";
import anouncementCaller from "../../API/AnouncementAPICaller";
import MyData from "../../Data/MyData";
import { AnouncementCard } from "../../Component/Card/AnouncementCard";
import MyToast from "../../Component/Toast/MyToast";

class PromotionScreen extends Component {
  state = {
    promotionList: [],
    isLoading: true,
    isModalShow: false,
  };

  async componentDidMount() {
    this.get_Promotion_list();
  }

  get_Promotion_list = async () => {
    await anouncementCaller
      .getPromotionList(MyData.authToken)
      .then(res => {
        this.setState({
          promotionList: res.response.list,
          isLoading: false,
        });
      })
      .catch(err => {
        if (err.response.status == 514) {
          this.props.expiredToken_logout();
        } else {
          MyToast.popToast(err.response.data.msg);
        }
      });
  };
  onBackPressed = () => {
    this.props.navigation.goBack();
  };
  on_Refresh = () => {
    this.setState({ isLoading: true });
    this.get_Promotion_list();
  };

  render() {
    return (
      <View
        style={{
          flex: 1,
        }}>
        {this.state.isLoading ? (
          <View>
            <ActivityIndicator size="small" color={MainColor} />
          </View>
        ) : (
          <FlatList
            onRefresh={() => this.on_Refresh()}
            refreshing={this.state.isLoading}
            data={this.state.promotionList}
            contentContainerStyle={{ paddingHorizontal: HeightDimen(15) }}
            keyExtractor={key => key.promo_id}
            renderItem={({ item }) => {
              return (
                <AnouncementCard
                  onPress={() => {
                    this.props.navigation.navigate("PromotionDetail", {
                      promoDetail: item,
                      onGoBack: data => {
                        this.on_Refresh(data);
                      },
                    });
                  }}
                  thumbnail={item.promo_image_url}
                />
              );
            }}
          />
        )}
      </View>
    );
  }
}

export default PromotionScreen;
