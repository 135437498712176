import React, { Component } from "react";
import { View, Image, TextInput, Platform } from "react-native";
import { SecondaryColor } from "../../UI/Color";
import { HeightDimen } from "../../UI/Dimensions";

const Line = (props) => {
  const { marginTop, wantGray } = props;

  return (
    <View
      style={{
        borderColor: wantGray ? "gray" : SecondaryColor,
        borderWidth: HeightDimen(0.5),
        marginTop: marginTop || 0,
      }}
    ></View>
  );
};

export { Line };
