import React, { Component } from 'react'
import { View } from 'react-native'

import { WidthDimen, HeightDimen } from '../../UI/Dimensions'

import { NoBackHeaderContainer } from '../../Component/Header/NoBackHeader'

import { strings } from '../../Language/Language'

import { ProfileCard } from '../../Component/Card/ProfileCard'
// import {
//   AnalyticEvents,
//   logMessageAnalytics,
//   logScreenViewAnalytics,
//   screenNames,
// } from "../../Utils/Analytics";

class HistoryScreen extends Component {
  state = {}

  componentDidMount() {
    // logScreenViewAnalytics(screenNames.History);
  }
  onPress_WalletTransaction = () => {
    // logMessageAnalytics(AnalyticEvents.WalletTransactionClick);
    this.props.navigation.navigate('AllWalletTransaction')
  }

  onPress_GameTransaction = () => {
    // logMessageAnalytics(AnalyticEvents.GameTransactionClick);
    this.props.navigation.navigate('AllGameTransaction')
  }

  render() {
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: 'white',
        }}
      >
        <NoBackHeaderContainer title={strings.t('history_text')}>
          <View
            style={{
              paddingTop: HeightDimen(20),
              paddingLeft: HeightDimen(30),
              paddingRight: HeightDimen(30),
            }}
          >
            <ProfileCard
              top={true}
              src={require('../../Resources/Images/Wallet/icon_history.png')}
              label={strings.t('wallet_transaction')}
              onPress={() => this.onPress_WalletTransaction()}
            />
            <View style={{ height: HeightDimen(3) }}></View>
            <ProfileCard
              bottom={true}
              src={require('../../Resources/Images/Wallet/icon_history.png')}
              label={strings.t('game_transaction')}
              onPress={() => this.onPress_GameTransaction()}
            />
          </View>
        </NoBackHeaderContainer>
      </View>
    )
  }
}

export default HistoryScreen
