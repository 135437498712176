import React, { Component } from 'react'
import { View, TouchableOpacity, Modal, Image, FlatList } from 'react-native'
import Text from '../Text/MyText'

import { HeightDimen, fontDimen } from '../../UI/Dimensions'
import { ProfileCard } from '../Card/NewDesign/ProfileCard'
import { Line } from '../Line/Line'
import { TextButton } from '../Button/TextButton'
import { strings } from '../../Language/Language'

const CountryCodeModal = (props) => {
  const { isShow, list, onPress, onCloseModal, isPhoneNo, onBackPress } = props

  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={isShow}
      statusBarTranslucent
      onRequestClose={onCloseModal}
    >
      <View
        style={{
          flex: 1,
          justifyContent: 'center',
          backgroundColor: 'rgba(0,0,0,0.5)',
          alignItems: 'center',
        }}
      >
        <View
          style={{
            justifyContent: 'center',
            padding: HeightDimen(30),
            marginHorizontal: HeightDimen(30),
            backgroundColor: 'white',
            borderRadius: HeightDimen(10),
          }}
        >
          <Text
            style={{
              textAlign: 'center',
              fontSize: fontDimen(18),
              marginVertical: HeightDimen(10),
              fontWeight: 'bold',
            }}
          >
            {strings.t('select_country_code')}
          </Text>

          <FlatList
            style={{
              flexGrow: 0,
              marginTop: HeightDimen(10),
              padding: HeightDimen(10),
            }}
            numColumns={1}
            data={list}
            renderItem={({ item, index }) => (
              <View>
                <TouchableOpacity
                  onPress={() => onPress(item)}
                  style={{ marginTop: HeightDimen(10), marginBottom: 10 }}
                >
                  <View
                    style={{
                      backgroundColor: 'white',

                      flexDirection: 'row',
                    }}
                  >
                    <Image
                      source={
                        isPhoneNo ? item.icon_url : { uri: item.icon_url }
                      }
                      style={{
                        height: HeightDimen(25),
                        aspectRatio: 1 / 1,
                        width: 'auto',
                      }}
                    />

                    <Text
                      style={{
                        fontSize: fontDimen(15),
                        marginLeft: HeightDimen(15),
                        color: 'gray',
                      }}
                    >
                      {`${item.name}  ${item.code}`}
                    </Text>
                  </View>
                </TouchableOpacity>
                {list.length - 1 != index ? <Line wantGray /> : <View />}
              </View>
            )}
            keyExtractor={(item, index) => String(index)}
          />
          <View
            style={{
              width: '100%',
              alignItems: 'center',
              marginTop: HeightDimen(20),
            }}
          >
            <TextButton
              label={strings.t('cancel')}
              size={2.5}
              onPress={onBackPress}
              blackBtn
            />
          </View>
        </View>
      </View>
    </Modal>
  )
}

export { CountryCodeModal }
