import React, { Component } from 'react'
import { View, Keyboard, ActivityIndicator } from 'react-native'
import Text from '../../Component/Text/MyText'
import { MainColor } from '../../UI/Color'
import { HeightDimen, fontDimen } from '../../UI/Dimensions'
import { MainFlowHeaderContainer } from '../../Component/Header/MainFlowHeader'
import { RecoverTextInput } from '../../Component/Input/RecoverTextInput'
import { TextButton } from '../../Component/Button/TextButton'
import MyToast from '../../Component/Toast/MyToast'
import MyData from '../../Data/MyData'
import { strings } from '../../Language/Language'
import { EnterTransactionPinModal } from '../../Component/Modal/EnterTransactionPinModal'
import transferAPICaller from '../../API/TransferAPICaller'
import { SuccessModal } from '../../Component/Modal/SuccessModal'
import { RewardModal } from '../../Component/Modal/RewardSuccessModal'

import { StackActions } from '@react-navigation/native'
import bankAPICaller from '../../API/BankAPICaller'
import { RequestOTPModal } from '../../Component'
import { normalizeDecimalValue } from '../../Utils/Common'

class TransferAutoScreen extends Component {
  state = {
    amount: '',
    isLoading: false,
    showPinModal: false,
    isCheckingLoading: true,
    transferToName: '',
    isUserName: false,
    toTransferId: '',
    availableBalance: '',
    memberDisplay: '',
    isSuccess: false,
    isConfirmModal: false,
    transferAmount: '',
    transferMessage: '',
    transationProcessModal: false,
    verifyModalShow: false,
  }
  constructor(props) {
    super(props)
    this.inputRef = React.createRef()
  }
  async componentDidMount() {
    this.setState({ transferToName: this.props.route.params.value })
    this.onSearchUsername(this.props.route.params.value)
  }

  onSearchUsername = async (username) => {
    await transferAPICaller
      .checkMember(MyData.authToken, username)
      .then((res) => {
        //console.log(res)
        this.setState({
          isCheckingLoading: false,
          toTransferId: res.response.member.member_unique_key,
          isUserName: true,
          availableBalance: res.response.self.walletBalance,
          memberDisplay: res.response.member.memberDisplay,
        })
      })
      .catch((err) => {
        if (err.response.status == 400) {
          MyToast.popToast(strings.t('no_user_found'))
        } else if (err.response.status == 514) {
          this.props.expiredToken_logout()
        } else {
          MyToast.popToast(err.response.data.msg)
        }
        //console.log(err);
        this.setState({ isCheckingLoading: false })
      })
  }

  onPress_forgot_pin = () => {
    this.setState({ showPinModal: false })
    this.props.navigation.navigate('RecoverPin')
  }

  onSuccEnterPin = async () => {
    this.setState({ showPinModal: false })
    this.on_validate_transfer()
  }

  onBackModal = () => {
    this.setState({ showPinModal: false })
  }

  onBackPressed = () => {
    // this.props.navigation.dispatch(StackActions.pop(2));
    this.props.navigation.goBack()
    this.props.navigation.goBack()
  }

  handle_amount = (val) => {
    //  const convertVal = val.replace(/\D/g, "");
    //if (Number(val) <= Number(this.state.availableBalance)) {
    // const converted = val.replace(/\D/g, "");
    //   const final = Number(convertVal).toLocaleString();
    ///   this.setState({ amount: val });
    //}

    let value = normalizeDecimalValue(val)
    if (Number(value) <= Number(this.state.availableBalance)) {
      //this.inputRef.current.setSelection(value?.length, value?.length)
      this.setState({
        amount: value,
      })
    } else {
      // this.inputRef.current.setSelection(
      //   this.state.availableBalance?.length,
      //   this.state.availableBalance?.length
      // )
      this.setState({
        amount: this.state.availableBalance,
      })
    }
  }

  onNextPressed = async () => {
    if (this.state.amount != '') {
      if (Number(this.state.amount) <= Number(this.state.availableBalance)) {
        Keyboard.dismiss()
        this.setState({ showPinModal: true })
      } else {
        MyToast.popToast(strings.t('amount_greater_then_available_balance'))
      }
    } else {
      MyToast.popToast(strings.t('invalid_data_input'))
    }
  }
  on_validate_transfer = async () => {
    if (MyData.country_code == 'THB') {
      await this.checkUserVerification()
    } else {
      await this.on_transfer()
    }
  }
  on_transfer = async () => {
    if (this.state.amount > 0) {
      this.setState({
        isLoading: true,
        transationProcessModal: Platform.OS === 'ios' ? false : true,
      })
      await transferAPICaller
        .transferMoney(
          MyData.authToken,
          this.state.toTransferId,
          this.state.amount
        )
        .then((res) => {
          this.setState({
            isLoading: false,
            transationProcessModal: false,
          })

          this.props.navigation.navigate('PaymentSuccessScreen', {
            isSuccess: true,
            transferMessage: res.msg,
            transferAmount: res.response.amount,
            onGoBack: () => this.props.route.params.onGoBack(),
          })
          //console.log(res);
          // this.props.route.params.onGoBack();
          // this.props.navigation.goBack();
        })
        .catch((err) => {
          this.setState({
            isLoading: false,
            transationProcessModal: false,
          })
          if (err?.status == 514) {
            this.props.expiredToken_logout()
          } else {
            this.props.navigation.navigate('PaymentSuccessScreen', {
              isSuccess: false,
              transferMessage: err?.message,
            })
          }
          //console.log(err);
        })
    } else MyToast.popToast('Amount should be greater than 0')
  }
  checkUserVerification = async () => {
    this.setState({
      isLoading: true,
    })
    await bankAPICaller
      .checkUserVerification(MyData.authToken)
      .then((res) => {
        this.setState({
          isLoading: false,
        })
        this.props.navigation.navigate('TransactionOTP', {
          isTransfer: true,
          transferAmount: this.state.amount,
          transferId: this.state.toTransferId,
        })
      })
      .catch((err) => {
        if (err.response.status == '514') {
          this.props.expiredToken_logout()
        } else if (err.response.status == '302') {
          this.setState({ verifyModalShow: true })
        } else {
          MyToast.popToast(err.response.data.msg)
        }
      })
      .finally(() => {
        this.setState({
          isLoading: false,
        })
      })
  }
  async onVerificationSuccess() {
    this.onCloseModal()
    await this.on_transfer()
  }
  onCloseModal() {
    this.setState({ verifyModalShow: false })
  }
  onFocus = () => {
    this.inputRef.current.setSelection(
      this.state.amount?.length,
      this.state.amount?.length
    )
  }
  render() {
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: 'white',
        }}
      >
        <EnterTransactionPinModal
          isShow={this.state.showPinModal}
          onPressForgotPin={() => this.onPress_forgot_pin()}
          onSuccEnterPin={() => this.onSuccEnterPin()}
          onBackModal={() => this.onBackModal()}
        />
        {this.state.transationProcessModal && (
          <RewardModal
            isShow={this.state.transationProcessModal}
            source={require('../../Resources/LottieIcons/processing.json')}
            amount={strings.t('transaction_process')}
          />
        )}
        {this.state.verifyModalShow && (
          <RequestOTPModal
            isShow={this.state.verifyModalShow}
            onSuccessVerificaion={() => this.onVerificationSuccess()}
            onCloseModal={() => this.onCloseModal()}
          />
        )}
        <MainFlowHeaderContainer
          title={strings.t('transfer')}
          onBackPress={() => this.onBackPressed()}
        >
          <View
            style={{
              flex: 1,
              paddingHorizontal: HeightDimen(30),
              paddingTop: HeightDimen(30),
            }}
          >
            {this.state.isCheckingLoading ? (
              <View>
                <ActivityIndicator size="small" color={MainColor} />
              </View>
            ) : (
              <View>
                {this.state.isUserName ? (
                  <View>
                    <View
                      style={
                        {
                          //  marginTop: HeightDimen(40),
                        }
                      }
                    >
                      <Text
                        style={{
                          fontSize: fontDimen(15),
                          marginBottom: HeightDimen(20),
                        }}
                      >
                        {strings.t('transfer_to') +
                          this.state.transferToName +
                          ' (' +
                          this.state.memberDisplay +
                          ')'}
                      </Text>
                      <RecoverTextInput
                        ref={this.inputRef}
                        title={`${strings.t('transfer_RM')} (${
                          MyData.country_code
                        })`}
                        placeholder={'0.00'}
                        top={true}
                        bottom={true}
                        isNumber={true}
                        onChangeText={this.handle_amount}
                        value={this.state.amount}
                        isMax
                        onMaxPress={() =>
                          this.setState({ amount: this.state.availableBalance })
                        }
                        onFocus={this.onFocus}
                      />
                    </View>
                    <Text
                      style={{
                        fontSize: fontDimen(15),
                        marginTop: HeightDimen(10),
                      }}
                    >
                      {strings.t('available_transfer_balance_RM') +
                        ' ' +
                        MyData.country_code +
                        this.state.availableBalance}
                    </Text>
                    <View
                      style={{
                        width: '100%',
                        alignItems: 'center',
                        marginTop: HeightDimen(40),
                      }}
                    >
                      <TextButton
                        label={strings.t('next')}
                        size={5}
                        onPress={() => this.onNextPressed()}
                        isLoading={this.state.isLoading}
                      />
                    </View>
                  </View>
                ) : (
                  <View>
                    <Text
                      style={{
                        fontSize: fontDimen(15),
                        textAlign: 'center',
                      }}
                    >
                      {strings.t('no_user_found')}
                    </Text>
                  </View>
                )}
              </View>
            )}
          </View>
        </MainFlowHeaderContainer>
      </View>
    )
  }
}

export default TransferAutoScreen
