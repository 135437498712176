import React, { Component } from 'react'
import {
  View,
  FlatList,
  TouchableOpacity,
  ActivityIndicator,
} from 'react-native'
import Text from '../../Component/Text/MyText'
import { MainColor, SecondaryColor, FontMainColor } from '../../UI/Color'
import { HeightDimen, fontDimen } from '../../UI/Dimensions'
import { MainFlowHeaderContainerNoFeedback } from '../../Component/Header/MainFlowHeader'
import walletAPICaller from '../../API/WalletAPICaller'
import MyData from '../../Data/MyData'
import { OrderHistoryCard } from '../../Component/Card/OrderHistoryCard'
import MyToast from '../../Component/Toast/MyToast'
import { strings } from '../../Language/Language'
import BankAPICaller from '../../API/BankAPICaller'

class TransactionScreen extends Component {
  state = {
    selection: 1,
    fromDate: '',
    toDate: '',
    topUpHistoryList: [],
    withdrawHistoryList: [],
    walletTransactionList: [],
    isLoading: true,
    isWalletTransactionLoading: false,

    bankList: [],
    showTopUpHistoryDetaisl: false,
    showWithdrawHistoryDetaisl: false,
    selectedTopUpHistoryDetailIndex: 0,
    selectedWithdrawHistoryDetailsIndex: 0,
  }

  async componentDidMount() {
    // var date = new Date().getDate();
    // var month = new Date().getMonth() + 1;
    // var year = new Date().getFullYear();

    // var final_date = year + '-' + (month < 10 ? '0' : '') + month + '-' + (date < 10 ? '0' : '') + date;
    // this.setState({
    //     fromDate: final_date,
    //     toDate: final_date
    // });
    await this.get_bankList()
    this.getTopUpHistory()
  }

  get_bankList = async () => {
    await BankAPICaller.getTopUpBankList(MyData.authToken)
      .then((res) => {
        this.setState({ bankList: res.response.topUpBankList })
      })
      .catch((err) => {
        if (err.response.status == 514) {
          this.props.expiredToken_logout()
        } else {
          MyToast.popToast(err.response.data.msg)
        }
        console.log(err)
      })
  }

  getTopUpHistory = async () => {
    this.setState({ isLoading: true })
    await walletAPICaller
      .getTopUpHistory(MyData.authToken)
      .then(async (res) => {
        this.setState({
          // isLoading: false,
          topUpHistoryList: res.response.topUpHistory,
        })
        //console.log(res.response.topUpHistory)
        this.getWithdrawHistory()
      })
      .catch((err) => {
        console.log(err)
        if (err.response.status == 514) {
          this.props.expiredToken_logout()
        } else {
          MyToast.popToast(err.response.data.msg)
        }
      })
  }

  getWithdrawHistory = async () => {
    await walletAPICaller
      .getWithdrawHistory(MyData.authToken)
      .then((res) => {
        this.setState({
          isLoading: false,
          withdrawHistoryList: res.response.withdrawHistory,
          //topUpHistoryList: res.response.topUpHistory
        })
      })
      .catch((err) => {
        console.log(err)
        if (err.response.status == 514) {
          this.props.expiredToken_logout()
        } else {
          MyToast.popToast(err.response.data.msg)
        }
      })
  }

  // getWalletTransaction = async () => {
  //     this.setState({ isWalletTransactionLoading: true })
  //     await walletAPICaller.getWalletTransaction(MyData.authToken, this.state.fromDate, this.state.toDate).then(res => {
  //         //console.log(res.response.transactions)
  //         if(res.response.transactions.length == 0) {
  //             this.setState({
  //                 isWalletTransactionLoading: false,
  //             })
  //             MyToast.popToast(strings.t('no_transaction_for_dates)
  //         } else {
  //             this.setState({
  //                 isWalletTransactionLoading: false,
  //                 walletTransactionList: res.response.transactions
  //             })
  //         }

  //     }).catch(err => {
  //         //console.log(err);
  //         if(err.response.status == 514) {
  //             this.props.expiredToken_logout();
  //         }
  //     });
  // }

  // go_wallet_transaction = async () => {
  //     if(this.state.fromDate != '' && this.state.toDate != '') {
  //         this.getWalletTransaction()
  //     } else {
  //         MyToast.popToast(strings.t('please_enter_date)
  //     }
  // }

  onBackPressed = () => {
    this.props.route.params.onGoBack()
    this.props.navigation.goBack()
  }

  onSwitch = (val) => {
    if (this.state.selection != val) {
      if (val == 1) {
      } else if (val == 2) {
      }
      this.setState({ selection: val })
    }
  }

  set_fromDate = (data) => {
    this.setState({ fromDate: data })
  }

  set_toDate = (data) => {
    this.setState({ toDate: data })
  }

  onPress_TopUpHistoryDetails = (index) => {
    this.setState({ selectedTopUpHistoryDetailIndex: index })
    this.props.navigation.navigate('TopUpDetailsScreen', {
      bankList: this.state.bankList,
      selectedTopUpDetails: this.state.topUpHistoryList[index],
      onGoBack: (data) => {
        this.refresh(data)
      },
    })
  }

  onPress_WithdrawHistoryDetails = (index) => {
    this.setState({ selectedWithdrawHistoryDetailsIndex: index })
    this.props.navigation.navigate('WithdrawHistoryDetailsScreen', {
      bankList: this.state.bankList,
      selectedTopUpDetails: this.state.withdrawHistoryList[index],
      onGoBack: (data) => {
        this.refresh(data)
      },
    })
  }

  refresh = (data) => {
    if (data == true) {
      this.getTopUpHistory()
    }
  }
  onGetTopUpWithrawStatus = (tranStatus) => {
    const status = tranStatus?.toLowerCase()
    if (status == 'rejected') return strings.t('rejected')
    if (status == 'hold') return strings.t('hold')
    if (status == 'pending') return strings.t('pending')
    if (status == 'approve') return strings.t('approved')
    if (status == 'process') return strings.t('processing')
    return status
  }

  render() {
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: 'white',
        }}
      >
        <MainFlowHeaderContainerNoFeedback
          title={strings.t('order_history')}
          onBackPress={() => this.onBackPressed()}
        >
          <View
            style={{
              flex: 1,
              paddingTop: HeightDimen(30),
              paddingHorizontal: HeightDimen(30),
            }}
          >
            {this.state.isLoading ? (
              <View>
                <ActivityIndicator size="small" color={MainColor} />
              </View>
            ) : (
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'center',
                }}
              >
                <TouchableOpacity
                  style={{
                    width: 'auto',
                    height: HeightDimen(40),
                    aspectRatio: 3.5 / 1,
                    backgroundColor:
                      this.state.selection == 1
                        ? SecondaryColor
                        : 'rgba(237, 181, 61, 0.1)',
                    marginRight: HeightDimen(10),
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  onPress={() => this.onSwitch(1)}
                >
                  <Text
                    style={{
                      fontSize: fontDimen(13),
                      color:
                        this.state.selection == 1 ? 'white' : FontMainColor,
                    }}
                  >
                    {strings.t('withdraw_history')}
                  </Text>
                </TouchableOpacity>

                <TouchableOpacity
                  style={{
                    width: 'auto',
                    height: HeightDimen(40),
                    aspectRatio: 3.5 / 1,
                    backgroundColor:
                      this.state.selection == 2
                        ? SecondaryColor
                        : 'rgba(237, 181, 61, 0.1)',
                    marginLeft: HeightDimen(10),
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  onPress={() => this.onSwitch(2)}
                >
                  <Text
                    style={{
                      fontSize: fontDimen(13),
                      color:
                        this.state.selection == 2 ? 'white' : FontMainColor,
                    }}
                  >
                    {strings.t('top_up_history')}
                  </Text>
                </TouchableOpacity>
              </View>
            )}

            {/* { this.state.selection == 1 && !this.state.isLoading && 
                                <View
                                    style={{
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        marginTop: HeightDimen(20)
                                    }}
                                >   
                                    <View
                                        style={{
                                            flex: 1
                                        }}
                                    >
                                        <DateInput
                                            label={strings.t('from_date}
                                            data={this.state.fromDate}
                                            setDate={this.set_fromDate}
                                        />
                                    </View>
                                    <Text
                                        style={{
                                            fontSize: fontDimen(15),
                                            color: FontMainColor,
                                            fontWeight: 'bold',
                                            marginLeft: HeightDimen(10),
                                            marginRight: HeightDimen(10)
                                        }}
                                    >
                                        {strings.t('to}
                                    </Text>
                                    <View
                                        style={{
                                            flex: 1
                                        }}
                                    >
                                        <DateInput
                                            label={strings.t('to_date}
                                            data={this.state.toDate}
                                            setDate={this.set_toDate}
                                        />
                                    </View>
                                    <View
                                        style={{
                                            marginLeft: HeightDimen(10)
                                        }}
                                    >
                                        <TextButton
                                            size={1}
                                            label={strings.t('go}
                                            onPress={() => this.go_wallet_transaction()}
                                            isLoading={this.state.isWalletTransactionLoading}
                                        />
                                    </View>
                                </View>
                            } */}
            {this.state.selection == 2 && !this.state.isLoading && (
              <View
                style={{
                  marginTop: HeightDimen(20),
                  marginLeft: -HeightDimen(10),
                  marginRight: -HeightDimen(10),
                  flex: 1,
                }}
              >
                <FlatList
                  numColumns={1}
                  data={this.state.topUpHistoryList}
                  renderItem={({ item, index }) => (
                    <TouchableOpacity
                      onPress={() => this.onPress_TopUpHistoryDetails(index)}
                    >
                      <OrderHistoryCard
                        title={strings.t('top_up')}
                        message={item.topup_datetime}
                        price={Number(item.topup_amount).toFixed(2)}
                        status={this.onGetTopUpWithrawStatus(item.topup_status)}
                      />
                    </TouchableOpacity>
                  )}
                  keyExtractor={(item) => String(item.topup_id)}
                />
              </View>
            )}
            {this.state.selection == 1 && !this.state.isLoading && (
              <View
                style={{
                  marginTop: HeightDimen(20),
                  marginLeft: -HeightDimen(10),
                  marginRight: -HeightDimen(10),
                  flex: 1,
                }}
              >
                <FlatList
                  numColumns={1}
                  data={this.state.withdrawHistoryList}
                  renderItem={({ item, index }) => (
                    <TouchableOpacity
                      onPress={() => this.onPress_WithdrawHistoryDetails(index)}
                    >
                      <OrderHistoryCard
                        title={strings.t('withdraw')}
                        message={item.transaction_created_datetime}
                        price={Number(item.transaction_amount).toFixed(2)}
                        status={this.onGetTopUpWithrawStatus(
                          item.withdraw_status
                        )}
                      />
                    </TouchableOpacity>
                  )}
                  keyExtractor={(item) => String(item.transaction_id)}
                />
              </View>
            )}
          </View>
        </MainFlowHeaderContainerNoFeedback>
      </View>
    )
  }
}

export default TransactionScreen
