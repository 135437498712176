import React from 'react'
import {
  View,
  FlatList,
  Modal,
  Text,
  TouchableOpacity,
  Image,
  Platform,
} from 'react-native'
import { fontDimen, HeightDimen, WidthDimen } from '../../UI/Dimensions'
import { TextButton } from '../../Component/Button/TextButton'
import { strings } from '../../Language/Language'
import { FontMainColor, SecondaryColor } from '../../UI/Color'
import { FriendCard } from '../Card/FriendCard'
import MyData from '../../Data/MyData'

const MultiAccountModal = (props) => {
  const {
    isShow,
    isLoading,
    loginUserList,
    onAddAccountPress,
    onDeleteUserPress,
    onSelectLoginPress,
    onCloseModalPress,
  } = props

  return (
    <Modal
      animationType="fade"
      transparent={true}
      visible={isShow}
      statusBarTranslucent
    >
      <View
        style={{
          flex: 1,
          backgroundColor: 'rgba(0,0,0,0.5)',
          alignItems: 'center',
        }}
      >
        <View
          style={{
            flex: 1,
            justifyContent: 'center',
          }}
        >
          <View
            style={{
              backgroundColor: 'white',
              marginHorizontal: HeightDimen(15),
              flex: 0.7,
              borderRadius: HeightDimen(10),
              paddingVertical: HeightDimen(15),
            }}
          >
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <TouchableOpacity
                onPress={onCloseModalPress}
                style={{
                  backgroundColor: 'gray',
                  width: HeightDimen(35),
                  height: HeightDimen(35),
                  marginLeft: HeightDimen(15),
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: HeightDimen(22),
                }}
              >
                <Image
                  source={require('../../Resources/Images/close-cross.png')}
                  style={{
                    width: 'auto',
                    height: HeightDimen(15),
                    aspectRatio: 1 / 1,
                    resizeMode: 'contain',
                    tintColor: 'white',
                  }}
                />
              </TouchableOpacity>
              <Text
                style={{
                  flex: 1,
                  fontSize: fontDimen(18),
                  color: FontMainColor,
                  fontWeight: 'bold',
                  textAlign: 'center',
                  marginRight: HeightDimen(10),
                }}
              >
                {strings.t('add_account')}
              </Text>
            </View>
            <FlatList
              data={loginUserList}
              contentContainerStyle={{
                flex: 1,
                paddingHorizontal: HeightDimen(20),
                paddingVertical: HeightDimen(15),
              }}
              renderItem={({ item, index }) => (
                <FriendCard
                  disabled={
                    item.username == MyData.member_username ? true : false
                  }
                  hideShowNext
                  onDeletePress={() => {
                    onDeleteUserPress(item.username, item.token)
                  }}
                  onPress={() => {
                    onSelectLoginPress(item.username, item.password, item.token)
                  }}
                  name={item.username}
                  image={item.avatar}
                  top={index == 0 ? true : false}
                  bottom={index == loginUserList.length - 1 ? true : false}
                />
              )}
              ListEmptyComponent={
                <View
                  style={{
                    flex: 1,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Image
                    style={{
                      height: HeightDimen(60),
                      width: 'auto',
                      aspectRatio: 1 / 1,
                      tintColor: SecondaryColor,
                    }}
                    source={require('../../Resources/Images/no-user.png')}
                  />
                  <Text
                    style={{
                      fontSize: fontDimen(18),
                      color: FontMainColor,
                      fontWeight: 'bold',
                      marginTop: HeightDimen(15),
                    }}
                  >
                    {strings.t('no_account_found')}
                  </Text>
                </View>
              }
              keyExtractor={(item, index) => String(index)}
            />
            <View style={{ alignSelf: 'center' }}>
              <TextButton
                label={strings.t('add_another_account')}
                size={4}
                onPress={onAddAccountPress}
                isLoading={isLoading}
              />
            </View>
          </View>
        </View>
      </View>
    </Modal>
  )
}

export { MultiAccountModal }
