import axios from "axios";
import { apiUrls } from "./APIurl";

import storageData from "../Data/StorageData";
import MyData from "../Data/MyData";

class TransferAPICaller {
  checkMember = async (authToken, memberUser) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${MyData.api_url}${apiUrls.checkMember}`,
          {
            data: {
              memberUsername: memberUser,
              language: storageData.get_selected_language(),
            },
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  // transferMoney = async (authToken, key, value) => {
  //   return new Promise((resolve, reject) => {
  //     axios
  //       .post(
  //         `${MyData.api_url}${apiUrls.transferMember}`,
  //         {
  //           data: {
  //             targetUniqueKey: key,
  //             amount: value,
  //             currency: MyData.country_code,
  //             language: storageData.get_selected_language(),
  //           },
  //         },
  //         {
  //           headers: {
  //             Authorization: authToken,
  //           },
  //         }
  //       )
  //       .then((response) => {
  //         resolve(response.data);
  //       })
  //       .catch((error) => {
  //         reject(error);
  //       });
  //   });
  // };

  transferMoney = async (authToken, key, value) => {
    const payload = {
      data: {
        targetUniqueKey: key,
        amount: value,
        currency: MyData.country_code,
        pin: MyData.userPin,
        language: storageData.get_selected_language(),
      },
    };
    return new Promise((resolve, reject) => {
      fetch(`${MyData.api_url}${apiUrls.transferMember}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: authToken,
        },
        body: JSON.stringify(payload),
      })
        .then(async (response) => {
          if (response.status == 200) {
            const res = await response.json();
            resolve(res);
          } else {
            const error = await response.json();

            reject({ message: error.msg, status: response.status });
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
}

const transferAPICaller = new TransferAPICaller();
export default transferAPICaller;
