import React, { Component } from 'react'
import {
  View,
  TouchableOpacity,
  Modal,
  Image,
  StatusBar,
  Platform,
  FlatList,
} from 'react-native'
import Text from '../Text/MyText'

import {
  MainColor,
  SecondaryColor,
  FontMainColor,
  FontSecondaryColor,
  FontThirdColor,
} from '../../UI/Color'
import {
  WidthDimen,
  HeightDimen,
  fontDimen,
  GetWidth,
  GetHeight,
} from '../../UI/Dimensions'

import { ImageButton } from '../../Component/Button/ImageButton'
import { strings } from '../../Language/Language'

class BottomSelectionModal extends React.Component {
  onSelect = (index) => {
    this.props.onSelect(index)
  }

  render() {
    const { isShow, filterList } = this.props

    return (
      <Modal
        animationType="slide"
        transparent={true}
        visible={isShow}
        statusBarTranslucent
      >
        <StatusBar translucent backgroundColor="transparent" />
        <View
          style={{
            flex: 1,
            backgroundColor: 'rgba(0,0,0,0.5)',
            justifyContent: 'flex-end',
          }}
        >
          <View
            style={{
              backgroundColor: 'white',
            }}
          >
            <View
              style={{
                height: HeightDimen(50),
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Text
                style={{
                  fontSize: fontDimen(15),
                  fontWeight: 'bold',
                }}
              >
                {strings.t('type')}
              </Text>
            </View>
            <FlatList
              style={{
                flexGrow: 0,
                height: HeightDimen(320),
              }}
              numColumns={1}
              data={filterList}
              renderItem={({ item, index }) => (
                <TouchableOpacity
                  onPress={() => this.onSelect(index)}
                  style={{
                    alignItems: 'center',
                  }}
                >
                  <View
                    style={{
                      backgroundColor: 'white',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: HeightDimen(50),
                    }}
                  >
                    <Text
                      style={{
                        fontSize: fontDimen(15),
                      }}
                    >
                      {item.name}
                    </Text>
                  </View>
                  <View
                    style={{
                      width: '95%',
                      height: HeightDimen(1),
                      backgroundColor: '#EFF2F4',
                    }}
                  ></View>
                </TouchableOpacity>
              )}
              keyExtractor={(item) => String(item.id)}
            />
          </View>
        </View>
      </Modal>
    )
  }
}

export { BottomSelectionModal }
