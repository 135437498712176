import React, { Component } from 'react'
import {
  View,
  TouchableOpacity,
  Modal,
  Image,
  StatusBar,
  Platform,
} from 'react-native'
import MyData from '../../../Data/MyData'
import { strings } from '../../../Language/Language'
import { HeightDimen } from '../../../UI/Dimensions'
import { TextButton } from '../../Button/TextButton'

import { ProfileCard } from '../../Card/NewDesign/ProfileCard'
import { Line } from '../../Line/Line'

const TopUpModal = (props) => {
  const {
    topUpOnly,
    isShow,
    onPressInstantTopUp,
    onPressBankTopUp,
    onBackPress,
    onPressUsdtTopUp,
    onPressWithdraw,
    onPressQRTopup,
    onPressRedeem,
    onBackOpenModal,
    noWithraw,
    topupList,
    isDisabledQRTopup,
    onPressPromotionTopUp,
    onPressWithdrawUsdt,
  } = props
  const topupButtonDisable = (name) => {
    const data = topupList.find((item) => {
      return item?.code === name
    })
    return data?.value
  }
  console.log(topupList)
  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={isShow}
      statusBarTranslucent
    >
      <StatusBar translucent backgroundColor="transparent" />
      <View
        style={{
          flex: 1,
          backgroundColor: 'rgba(0,0,0,0.5)',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <View
          style={{
            justifyContent: 'center',
            padding: HeightDimen(20),
            marginHorizontal: HeightDimen(15),
            backgroundColor: 'white',
            borderRadius: HeightDimen(10),
          }}
        >
          {onPressInstantTopUp && (
            <>
              <ProfileCard
                src={require('../../../Resources/Images/Wallet/icon_instant.png')}
                label={strings.t('instant_top_up')}
                hideShowNext
                onPress={onPressInstantTopUp}
                disabled={
                  topupButtonDisable('topup_instant') === 'active'
                    ? false
                    : true
                }
                wantGray={
                  topupButtonDisable('topup_instant') === 'active'
                    ? false
                    : true
                }
              />
              <Line
                marginTop={HeightDimen(8)}
                wantGray={
                  topupButtonDisable('topup_instant') === 'active'
                    ? false
                    : true
                }
              />
            </>
          )}
          {onPressBankTopUp && (
            <>
              <ProfileCard
                src={require('../../../Resources/Images/Wallet/icon_instant.png')}
                label={strings.t('top_up_bank_transfer')}
                hideShowNext
                onPress={onPressBankTopUp}
                disabled={
                  topupButtonDisable('topup_manual') === 'active' ? false : true
                }
                wantGray={
                  topupButtonDisable('topup_manual') === 'active' ? false : true
                }
              />
              <Line
                marginTop={HeightDimen(8)}
                wantGray={
                  topupButtonDisable('topup_manual') === 'active' ? false : true
                }
              />
            </>
          )}
          {onPressPromotionTopUp && (
            <>
              <ProfileCard
                src={require('../../../Resources/Images/Profile/icon_promotion.png')}
                label={`${strings.t('top_up')}(${strings.t('promotion')})`}
                hideShowNext
                onPress={onPressPromotionTopUp}
                disabled={
                  topupButtonDisable('topup_promotion') === 'active'
                    ? false
                    : true
                }
                wantGray={
                  topupButtonDisable('topup_promotion') === 'active'
                    ? false
                    : true
                }
              />
              <Line
                marginTop={HeightDimen(8)}
                wantGray={
                  topupButtonDisable('topup_promotion') === 'active'
                    ? false
                    : true
                }
              />
            </>
          )}
          {onPressUsdtTopUp && (
            <>
              <ProfileCard
                label={strings.t('usdt_top_up')}
                onPress={onPressUsdtTopUp}
                disabled={
                  topupButtonDisable('topup_usdt') === 'active' ? false : true
                }
                src={require('../../../Resources/Images/tether-usdt.png')}
                wantGray={
                  topupButtonDisable('topup_usdt') === 'active' ? false : true
                }
              />
              <Line
                marginTop={HeightDimen(8)}
                wantGray={
                  topupButtonDisable('topup_usdt') === 'active' ? false : true
                }
              />
            </>
          )}
          {onPressQRTopup && (
            <>
              <ProfileCard
                label={strings.t('qr_topup')}
                onPress={onPressQRTopup}
                disabled={
                  topupButtonDisable('topup_qr') === 'active' ? false : true
                }
                wantGray={
                  topupButtonDisable('topup_qr') === 'active' ? false : true
                }
                src={require('../../../Resources/Images/Footer/icon_qr.png')}
              />
              <Line
                marginTop={HeightDimen(8)}
                wantGray={
                  topupButtonDisable('topup_qr') === 'active' ? false : true
                }
              />
            </>
          )}

          {onPressWithdraw && (
            <ProfileCard
              label={strings.t('withdraw')}
              onPress={onPressWithdraw}
              customContainerStyle={styles.withdrawContainer}
              customLabelStyle={styles.withdrawLabel}
              src={require('../../../Resources/Images/Profile/icon_withdraw.png')}
            />
          )}

          {onPressWithdrawUsdt && (
            <View
              style={{
                borderColor: 'green',
                borderWidth: HeightDimen(0.5),
                marginTop: HeightDimen(8),
              }}
            />
          )}
          {onPressWithdrawUsdt && (
            <>
              <ProfileCard
                label={strings.t('withdraw_usdt')}
                onPress={onPressWithdrawUsdt}
                // disabled={
                //   topupButtonDisable("withdraw_usdt") === "active"
                //     ? false
                //     : true
                // }
                customContainerStyle={styles.withdrawContainer}
                customLabelStyle={styles.withdrawLabel}
                src={require('../../../Resources/Images/tether-usdt.png')}
              />
            </>
          )}

          <View
            style={{
              width: '100%',
              alignItems: 'center',
              marginTop: HeightDimen(30),
            }}
          >
            <TextButton
              label={strings.t('cancel')}
              size={2.5}
              onPress={onBackPress}
              blackBtn
            />
          </View>
        </View>
      </View>
    </Modal>
  )
}
const styles = {
  withdrawContainer: {
    backgroundColor: 'rgba(0,128,0,.15)',
  },
  withdrawLabel: {
    color: 'green',
  },
}
export { TopUpModal }
