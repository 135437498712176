import React, { Component } from "react";
import { View } from "react-native";
import { HeightDimen } from "../../UI/Dimensions";
import { LoginFlowHeaderContainer } from "../../Component/Header/LoginFlowHeader";
import { TextButton } from "../../Component/Button/TextButton";
import { RecoverTextInput } from "../../Component/Input/RecoverTextInput";
import MyData from "../../Data/MyData";
import MyToast from "../../Component/Toast/MyToast";
import { strings } from "../../Language/Language";
import pinAPICaller from "../../API/PinAPICaller";
import { GradientTextButton } from "../../Component";

class ChangePinScreen extends Component {
  state = {
    old_pass: "",
    new_pass: "",
    confirm_pass: "",

    text: "something something something something something somethings omethingsome thingso mething ",
    isLoading: false,
  };

  handle_old_pass = (old_pass_txt) => {
    if (this.state.old_pass.length <= 6 && old_pass_txt.length <= 6) {
      this.setState({ old_pass: old_pass_txt });
    } else {
      MyToast.popToast(strings.t("pin_max_six"));
    }
  };

  handle_new_pass = (new_pass_txt) => {
    if (this.state.new_pass.length <= 6 && new_pass_txt.length <= 6) {
      this.setState({ new_pass: new_pass_txt });
    } else {
      MyToast.popToast(strings.t("pin_max_six"));
    }
  };

  handle_confirm_pass = (confirm_pass_txt) => {
    if (this.state.confirm_pass.length <= 6 && confirm_pass_txt.length <= 6) {
      this.setState({ confirm_pass: confirm_pass_txt });
    } else {
      MyToast.popToast(strings.t("pin_max_six"));
    }
  };

  onBackPressed = () => {
    this.props.navigation.goBack();
  };

  onNextPressed = async () => {
    if (
      this.state.old_pass.length == 6 &&
      this.state.new_pass.length == 6 &&
      this.state.confirm_pass.length == 6
    ) {
      if (this.state.new_pass == this.state.confirm_pass) {
        this.setState({ isLoading: true });

        await pinAPICaller
          .changePin(
            MyData.userToken,
            "",
            this.state.old_pass,
            this.state.new_pass,
            this.state.confirm_pass
          )
          .then((res) => {
            this.setState({ isLoading: false });
            if (res?.status == 203) {
              MyToast.popToast(strings.t("old_pin_not_match"));
            } else if (res?.status == 200) {
              MyData.setUserPin(this.state.new_pass);
              this.props.navigation.navigate("SuccChangePin", {
                onGoBack: (data) => {
                  this.refresh(data);
                },
              });
            }
          })
          .catch((err) => {
            //console.log(err);

            this.setState({ isLoading: false });

            MyToast.popToast(err.response.data.msg);
          });
      } else {
        MyToast.popToast(strings.t("new_pin_not_match"));
      }
    } else {
      MyToast.popToast(strings.t("invalid_data_input"));
    }
  };

  refresh = (data) => {
    this.props.navigation.goBack();
  };

  render() {
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: "white",
        }}
      >
        <LoginFlowHeaderContainer
          onBackPress={() => this.onBackPressed()}
          mainText={strings.t("change_pin")}
          subText={strings.t("select_one_of_the_cre")}
        >
          <View
            style={{
              flex: 1,
              paddingLeft: HeightDimen(30),
              paddingRight: HeightDimen(30),
              paddingBottom: HeightDimen(30),
            }}
          >
            <View
              style={{
                marginTop: HeightDimen(40),
              }}
            >
              <RecoverTextInput
                title={strings.t("old_pin")}
                placeholder={strings.t("old_pin")}
                top={true}
                isSecure={true}
                isNumber={true}
                onChangeText={this.handle_old_pass}
                value={this.state.old_pass}
              />
              <View style={{ height: HeightDimen(3) }}></View>
              <RecoverTextInput
                title={strings.t("new_pin")}
                placeholder={strings.t("new_pin")}
                isSecure={true}
                isNumber={true}
                onChangeText={this.handle_new_pass}
                value={this.state.new_pass}
              />
              <View style={{ height: HeightDimen(3) }}></View>
              <RecoverTextInput
                title={strings.t("confirm_new_pin")}
                placeholder={strings.t("confirm_new_pin")}
                bottom={true}
                isSecure={true}
                isNumber={true}
                onChangeText={this.handle_confirm_pass}
                value={this.state.confirm_pass}
              />
            </View>

            <View
              style={{
                width: "100%",
                alignItems: "center",
                marginTop: HeightDimen(40),
              }}
            >
              <GradientTextButton
                label={strings.t("next")}
                gradColor={["#ffee8d", "#f7c56e"]}
                onPress={() => this.onNextPressed()}
                size={"100%"}
                isLoading={this.state.isLoading}
              />
            </View>
            {/* <View 
                            style={{
                                marginTop: HeightDimen(10)
                            }}
                        >
                            <Text
                                style={{
                                    color: FontSecondaryColor,
                                    fontSize: fontDimen(15),
                                    marginTop: HeightDimen(10)
                                }}
                            >{this.state.text}</Text>
                        </View> */}
          </View>
        </LoginFlowHeaderContainer>
      </View>
    );
  }
}

export default ChangePinScreen;
