import AsyncStorage from '@react-native-async-storage/async-storage'
import PasswordInput from '../Component/Input/PasswordInput'
import { strings } from '../Language/Language'

class StorageData {
  selected_language = 'en'
  saved_userName = ''
  saved_password = ''
  saved_authSession = ''
  userType = ''
  LoginList = []
  api_url = ''
  s3_url = ''
  user_api_url = ''
  QRScanSuccess = true
  get_selected_language() {
    return this.selected_language.toUpperCase()
  }

  get_language = async () => {
    let temp_language = await AsyncStorage.getItem('language')
    if (temp_language != null) {
      this.selected_language = temp_language
    }
  }

  get_username_add_password = async () => {
    let temp_username = await AsyncStorage.getItem('username')
    let temp_password = await AsyncStorage.getItem('password')
    let temp_userType = await AsyncStorage.getItem('userType')
    let temp_authSession = await AsyncStorage.getItem('authSession')
    if (temp_username != null) this.saved_userName = temp_username
    if (temp_password != null) this.saved_password = temp_password
    if (temp_authSession != null) this.saved_authSession = temp_authSession
    if (temp_userType != null) this.userType = temp_userType
  }

  set_language = async (language_code) => {
    this.selected_language = language_code
    try {
      strings.locale = language_code
      await AsyncStorage.setItem('language', language_code)
    } catch (e) {
      //console.log(e);
    }
  }

  set_username_add_password = async (
    user,
    pass,
    authSession,
    userType = 'member'
  ) => {
    this.saved_userName = user
    this.saved_password = pass
    this.userType = userType
    this.saved_authSession = authSession
    try {
      await AsyncStorage.setItem('username', user)
      await AsyncStorage.setItem('password', pass)
      await AsyncStorage.setItem('userType', userType)
      await AsyncStorage.setItem('authSession', authSession)
    } catch (e) {
      //console.log(e);
    }
  }

  set_auth_session = async (authSession) => {
    this.saved_authSession = authSession
    try {
      await AsyncStorage.setItem('authSession', authSession)
    } catch (e) {
      //console.log(e);
    }
  }
  update_username_add_password = async (user, pass) => {
    this.saved_userName = user
    this.saved_password = pass

    try {
      await AsyncStorage.setItem('username', user)
      await AsyncStorage.setItem('password', pass)
    } catch (e) {
      //console.log(e);
    }
  }

  store_multi_account_data = async (username, password, avatar, token) => {
    try {
      let existingList = []
      let data = await AsyncStorage.getItem('multiLoginList')
      if (data != null) {
        existingList = JSON.parse(data)
        const isUserExist = existingList.find((item) => {
          return item.username === username
        })
        if (isUserExist) {
          existingList = existingList.filter((item) => {
            return item.username !== username
          })
        }
      }
      const obj = {
        username: username,
        password: password,
        avatar: avatar,
        token: token,
      }
      existingList.push(obj)
      await this.updateMulitLogin(existingList)
    } catch (e) {
      console.log(e)
    }
  }

  get_multiLogin_List = async () => {
    let UserList = await AsyncStorage.getItem('multiLoginList')
    if (UserList != null) {
      this.LoginList = JSON.parse(UserList)
    } else {
      this.LoginList = []
    }
  }

  updateMulitLogin = async (userList) => {
    try {
      this.LoginList = userList
      await AsyncStorage.setItem('multiLoginList', JSON.stringify(userList))
    } catch (e) {
      //console.log(e);
    }
  }
  clear_storage = async () => {
    try {
      await AsyncStorage.setItem('authSession', '')
    } catch (e) {
      //console.log(e);
    }
    this.saved_authSession = ''
  }
  clear_app_storage = async () => {
    try {
      await AsyncStorage.clear()
    } catch (e) {
      //console.log(e);
    }
  }
  set_api_url = async (api_url) => {
    try {
      AsyncStorage.setItem('api_url', JSON.stringify(api_url))
    } catch (e) {
      //console.log(e);
    }
  }
  get_api_url = async () => {
    try {
      let temp_api_url = await AsyncStorage.getItem('api_url')
      if (temp_api_url != null) {
        let s3_api_url = JSON.parse(temp_api_url)
        this.api_url = s3_api_url?.api_url
        this.s3_url = s3_api_url?.s3_url
        //  this.user_api_url = s3_api_url?.user_api_url;
      }
    } catch (e) {
      //console.log(e);
    }
  }
}

const storageData = new StorageData()
export default storageData
