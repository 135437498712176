import React, { Component } from 'react'
import { View, TouchableOpacity, Image } from 'react-native'
import Text from '../Text/MyText'
import Checkbox from 'expo-checkbox'
import { fontDimen, HeightDimen } from '../../UI/Dimensions'

const AgreeCheckBox = (props) => {
  const { onCheck, value, label } = props

  return (
    <View
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        marginLeft: HeightDimen(-7),
      }}
    >
      <Checkbox value={value} onValueChange={(newValue) => onCheck(newValue)} />
      <Text
        style={{
          fontSize: fontDimen(15),
          color: 'black',
          marginLeft: HeightDimen(5),
        }}
      >
        {label}
      </Text>
    </View>
  )
}

export { AgreeCheckBox }
