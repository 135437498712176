import React from "react";

import { HeightDimen } from "../../../UI/Dimensions";
import { LinearGradient } from "expo-linear-gradient";

const GradientHeader = (props) => {
  const { colors } = props;

  return (
    <LinearGradient
      colors={colors?.length > 0 ? colors : ["#ffee8d", "#f7c56e"]}
      style={{
        width: "100%",
        height: HeightDimen(70),
        alignItems: "center",
        justifyContent: "center",
        paddingTop: HeightDimen(20),
        position: "absolute",
      }}
    />
  );
};

export { GradientHeader };
