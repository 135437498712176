import React, { Component } from 'react'
import {
  View,
  TouchableOpacity,
  Image,
  ActivityIndicator,
  ScrollView,
  TextInput,
  FlatList,
  Platform,
} from 'react-native'
import Text from '../../Component/Text/MyText'
import {
  FontSecondaryColor,
  MainColor,
  PrimaryColor,
  SecondaryColor,
} from '../../UI/Color'
import { HeightDimen, fontDimen } from '../../UI/Dimensions'
import { MainFlowHeaderContainerNoFeedback } from '../../Component/Header/MainFlowHeader'
import { RecoverTextInput } from '../../Component/Input/RecoverTextInput'
import { TextButton } from '../../Component/Button/TextButton'
import { UploadTopUpModal } from '../../Component/Modal/UploadTopUpModal'
import MyToast from '../../Component/Toast/MyToast'
import { check_camera_permission } from '../../Permission/Permission'
import walletAPICaller from '../../API/WalletAPICaller'
import MyData from '../../Data/MyData'
import { strings } from '../../Language/Language'
import bankAPICaller from '../../API/BankAPICaller'
import { PickTopUpModal } from '../../Component/Modal/PickTopUpBankModal'
import { AgreeCheckBox } from '../../Component/CheckBox/AgreeCheckBox'
import * as ImagePicker from 'expo-image-picker'

import { SuccessModal } from '../../Component/Modal/SuccessModal'
import { ConfirmCancelModal } from '../../Component/Modal/ConfirmCancelModal'
import MIcon from 'react-native-vector-icons/MaterialIcons'
import Clipboard from '@react-native-clipboard/clipboard'
import { normalizeDecimalValue } from '../../Utils/Common'

const delay = (ms) => new Promise((res) => setTimeout(res, ms))

const NumberBullets = ({ num }) => {
  return (
    <View
      style={{
        borderRadius: 12,
        height: HeightDimen(25),
        width: HeightDimen(25),
        backgroundColor: 'white',
        justifyContent: 'center',
        alignItems: 'center',
        borderWidth: HeightDimen(1),
        borderColor: MainColor,
        marginBottom: HeightDimen(5),
      }}
    >
      <Text style={{ fontSize: fontDimen(12), fontWeight: 'bold' }}>{num}</Text>
    </View>
  )
}
class TopUpScreen extends Component {
  state = {
    amount: '',
    show_upload_modal: false,
    show_confirm_upload: false,
    imgUrl: '',
    isLoading: false,
    selectedBank: strings.t('select_bank'),
    selectedBankId: '',
    isGetBankList: true,
    showPickModal: false,
    bankList: [],
    accName: '',
    accNo: '',
    accImg: '',
    isCheck: false,
    isSuccess: false,
    isConfirmModal: false,
    transferMessage: '',
    reference: '',
    promo_code: MyData.coupon_code,
    isSessionTopupLoading: false,
    isSessionExist: false,
    isShowCancelSessionModal: false,
    base64Image: [],
    isAmountSubmit: false,
    remarks: '',
    imageListUrl: [],
    isPromoEdit: MyData.coupon_code ? false : true,
  }
  constructor(props) {
    super(props)

    this.inputRef = React.createRef()
  }
  async componentDidMount() {
    await this.get_selected_topup_bank()
  }
  get_selected_topup_bank = async () => {
    this.setState({ isGetBankList: true })
    await bankAPICaller
      .getSelectedTopUpBank(MyData.authToken)
      .then((res) => {
        if (res?.response?.bank != null) {
          const baseModel = res?.response?.bank
          this.setState({
            isSessionExist: true,
            selectedBankId: baseModel?.topup_bank_id,
            selectedBank: baseModel?.topup_bank_name,
            accName: baseModel?.topup_bank_account_name,
            accNo: baseModel?.topup_bank_account_number,
            accImg: baseModel?.topup_bank_icon_url,
            remarks: baseModel?.topup_bank_remarks,
            amount: res?.response?.amount,
            promo_code: res?.response?.coupon,
          })
        } else {
          this.setState({ isSessionExist: false })
        }
      })
      .catch((err) => {
        if (err.response.status == 514) {
          this.props.expiredToken_logout()
        } else {
          MyToast.popToast(err.response.data.msg)
        }
        //console.log(err);
      })
      .finally(() => {
        this.setState({ isTopupLoading: false })
        this.get_bankList()
      })
  }
  save_selected_topup_bank = async (id) => {
    this.setState({ isSessionTopupLoading: true })
    await bankAPICaller
      .saveSelectedTopupBank(
        MyData.authToken,
        this.state.selectedBankId,
        this.state.amount,
        MyData.coupon_code
      )
      .then((res) => {
        this.setState({ isSessionExist: true })
      })
      .catch((err) => {
        if (err.response.status == 514) {
          this.props.expiredToken_logout()
        } else {
          MyToast.popToast(err.response.data.msg)
        }
      })
      .finally(() => {
        this.setState({ isSessionTopupLoading: false })
      })
  }
  cancel_selected_topup_bank = async () => {
    this.setState({ isSessionTopupLoading: true })
    await bankAPICaller
      .cancelSelectedTopupBank(MyData.authToken)
      .then((res) => {
        this.setState({
          isShowCancelSessionModal: false,
          isSessionTopupLoading: false,
          isSessionExist: false,
          selectedBankId: '',
          selectedBank: 'Select Bank',
          accName: '',
          accNo: '',
          accImg: '',
          isAmountSubmit: false,
          amount: '',
          imgUrl: '',
          remarks: '',
          imageListUrl: [],
          base64Image: [],
        })
      })
      .catch((err) => {
        if (err.response.status == 514) {
          this.props.expiredToken_logout()
        } else {
          MyToast.popToast(err.response.data.msg)
        }
      })
      .finally(() => {
        this.setState({ isSessionTopupLoading: false })
      })
  }
  get_bankList = async () => {
    await bankAPICaller
      .getTopUpBankList(MyData.authToken)
      .then((res) => {
        this.setState({
          isGetBankList: false,
          bankList: res.response.topUpBankList,
          reference: res.response.reference,
        })
      })
      .catch((err) => {
        if (err.response.status == 514) {
          this.props.expiredToken_logout()
        } else {
          MyToast.popToast(err.response.data.msg)
        }
        //console.log(err);
      })
  }

  hide_show_pickBank = () => {
    this.setState({ showPickModal: !this.state.showPickModal })
  }

  onSelectBank = async (index) => {
    // await this.save_selected_topup_bank(
    //   this.state.bankList[index].topup_bank_id
    // );
    this.setState({
      selectedBankId: this.state.bankList[index].topup_bank_id,
      selectedBank: this.state.bankList[index].topup_bank_name,
      accName: this.state.bankList[index].topup_bank_account_name,
      accNo: this.state.bankList[index].topup_bank_account_number,
      accImg: this.state.bankList[index].topup_bank_icon_url,
      remarks: this.state.bankList[index]?.topup_bank_remarks,
    })

    this.hide_show_pickBank()
  }

  onBackPressed = () => {
    if (this.props.route.params?.backToRedeem) {
      this.props.navigation.pop(2)
      return
    }
    this.props.route.params?.onGoBack()
    this.props.navigation?.goBack()
  }

  handle_amount = (val) => {
    let value = normalizeDecimalValue(val)
    this.setState({ amount: value })
    // this.inputRef.current.setSelection(value?.length, value?.length)
  }
  handle_promo = (val) => {
    this.setState({ promo_code: val })
  }
  onNextPressed = () => {
    if (this.state.amount != '') {
      this.setState({ show_upload_modal: true })
    } else {
      MyToast.popToast(strings.t('invalid_data_input'))
    }
  }

  onCancel_modal = () => {
    this.setState({ show_upload_modal: false })
  }

  onPress_Camera = async () => {
    this.setState({ show_upload_modal: false })

    let result = await ImagePicker.launchCameraAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
      base64: true,
    })
    if (!result.canceled) {
      this.setImageList(result)
    }
  }

  setImageList(model) {
    const baseModel = model?.assets.map((item) => item?.uri)
    const base64List = model?.assets.map((item) => item.base64)
    let newList = [...this.state.imageListUrl, ...baseModel]
    let newBase64List = [...this.state.base64Image, ...base64List]

    this.setState({
      show_confirm_upload: true,
      imageListUrl: newList,
      base64Image: newBase64List,
    })
  }

  refresh = (data) => {
    this.setState({
      imgUrl: data,
      show_confirm_upload: true,
    })
  }

  renderNumbering = () => {}
  onPress_local = async () => {
    this.setState({
      show_upload_modal: false,
    })
    await delay(500)
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
      base64: true,
    })
    if (!result.canceled) {
      this.setImageList(result)
    }
  }

  onCancel_confirm_modal = () => {
    this.setState({ show_confirm_upload: false })
  }

  onComformUpload = async (base64List) => {
    this.setState({ isLoading: true, show_confirm_upload: false })

    await walletAPICaller
      .uploadMultiReceiptTopUp(this.state.base64Image, MyData.authToken)
      .then((res) => {
        if (res?.return?.imgUrlArr)
          this.onUploadTopUpAll(res?.return?.imgUrlArr)
      })
      .catch((err) => {
        //   console.log(err);
        if (err.response.status == 514) {
          this.props.expiredToken_logout()
        } else {
          MyToast.popToast(err.response.data.msg)
        }
      })
  }

  onUploadTopUpAll = async (img) => {
    await walletAPICaller
      .uploadTopUpValue(
        MyData.authToken,
        this.state.amount,
        img,
        this.state.selectedBankId,
        MyData.member_username,
        this.state.promo_code
      )
      .then((res) => {
        this.setState({
          isLoading: false,
          isConfirmModal: true,
          isSuccess: true,
          transferMessage: strings.t('request_topup_success'),
        })
        //this.props.route.params.onGoBack(true);
        //  this.props.navigation.goBack();
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        })
        if (err?.status == 514) {
          this.props.expiredToken_logout()
        } else {
          this.setState({
            isLoading: false,
            isConfirmModal: true,
            isSuccess: false,
            transferMessage: err?.message,
          })

          MyToast.popToast(err?.message)
        }
      })
  }
  onCheckPromoValid = async () => {
    if (!this.state.amount) {
      MyToast.popToast(strings.t('invalid_data_input'))
      return
    }
    this.setState({ isLoading: true })
    await walletAPICaller
      .checkPromoValidity(MyData.authToken, this.state.promo_code)
      .then((res) => {
        MyToast.popToast(res.data.msg)
        this.onNextPressed()
      })
      .catch((err) => {
        if (err.response.status == 514) {
          this.props.expiredToken_logout()
        } else {
          MyToast.popToast(err.response.data.msg)
        }
      })
      .finally(() => {
        this.setState({ isLoading: false })
      })
  }

  //testing button function
  onTestShow = () => {
    this.setState({ show_confirm_upload: true })
  }

  checkAgree = (val) => {
    this.setState({ isCheck: val })
  }
  onPressCopy = (value) => {
    Clipboard.setString(value)
    MyToast.popToast(strings.t('copied'))
  }
  renderTitleAndDescription(title, description, copyIcon) {
    return (
      <View
        style={{
          flex: 1,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: HeightDimen(6),
          }}
        >
          <Text
            style={{
              color: FontSecondaryColor,
              marginRight: HeightDimen(10),
              fontSize: fontDimen(14),
            }}
          >
            {title}
          </Text>
          <Text
            style={{
              color: MainColor,

              fontSize: fontDimen(15),
            }}
          >
            {description}
          </Text>
        </View>
        {copyIcon && (
          <TouchableOpacity onPress={() => this.onPressCopy(description)}>
            <Image
              style={{
                height: HeightDimen(20),
                width: 'auto',
                aspectRatio: 1 / 1,
                resizeMode: 'contain',
                tintColor: SecondaryColor,
              }}
              source={require('../../Resources/Images/icon_copy.png')}
            />
          </TouchableOpacity>
        )}
      </View>
    )
  }
  onFocus = () => {
    // this.inputRef.current.setSelection(
    //   this.state.amount?.length,
    //   this.state.amount?.length
    // )
  }

  renderAmountTopup() {
    return (
      <View>
        <View style={styles.infoContainer}>
          <MIcon name="info-outline" color={'#e7999f'} size={20} />
          <Text
            style={{
              ...styles.cashLabel,
              fontSize: fontDimen(14),
              color: '#e7999f',
              marginLeft: HeightDimen(10),
              flex: 1,
            }}
          >
            {strings.t('topup_info')}
          </Text>
        </View>
        <Text
          style={{
            ...styles.cashLabel,
            fontSize: fontDimen(14),
            marginTop: HeightDimen(5),
          }}
        >
          {strings.t('select_deposit_method')}
        </Text>
        <PickTopUpModal
          list={this.state.bankList}
          onPress={this.onSelectBank}
          selectedBank={this.state.selectedBank}
          isLoading={this.state.isSessionTopupLoading}
        />

        <View style={styles.cashContainer}>
          <Text style={styles.cashLabel}>
            {strings.t('amount_RM')} ({MyData.country_code})
          </Text>
          <TouchableOpacity
            onPress={() => {
              this.setState({
                amount: `${20000}`,
              })
            }}
          >
            <Text style={styles.cashMaxButton}>{strings.t('max')}</Text>
          </TouchableOpacity>
        </View>
        <View style={styles.textInputContainer}>
          <TextInput
            ref={this.inputRef}
            placeholder="0.00"
            keyboardType="numeric"
            placeholderTextColor={SecondaryColor}
            style={styles.textInput}
            onChangeText={this.handle_amount}
            value={this.state.amount}
            onFocus={this.onFocus}
          />
        </View>
        {/* <View style={{ height: HeightDimen(20) }}></View>
        <RecoverTextInput
          title={strings.t('promo_code}
          placeholder={"lucky"}
          onChangeText={this.handle_promo}
          value={this.state.promo_code}
          bottom={true}
          top={true}
          isEdit={this.state.isPromoEdit}
        /> */}
        <View
          style={{
            marginTop: HeightDimen(30),
          }}
        >
          <Text
            style={{
              fontSize: fontDimen(13),
              color: MainColor,
            }}
          >
            <Text style={{ fontWeight: 'bold' }}>
              {strings.t('minimum_top_up')}
            </Text>
            {`${MyData.country_code} ${
              MyData.country_code == 'MYR' ? '20' : '50'
            }`}
          </Text>
          <Text
            style={{
              fontSize: fontDimen(13),
              color: MainColor,
            }}
          >
            <Text style={{ fontWeight: 'bold' }}>
              {strings.t('maximum_top_up')}
            </Text>
            {`${MyData.country_code} ${
              MyData.country_code == 'MYR' ? '20,000' : ' 200,000'
            }`}
          </Text>

          <Text
            style={{
              fontSize: fontDimen(13),
              color: MainColor,
              marginBottom: HeightDimen(10),
            }}
          >
            <Text
              style={{
                fontWeight: 'bold',
              }}
            >
              {strings.t('top_up_left_times')}
            </Text>
            {strings.t('top_up_unlimited')}
          </Text>
          <Text
            style={{
              fontSize: fontDimen(13),
              color: '#e7999f',
              fontWeight: 'bold',
            }}
          >
            {strings.t('topup_promotion_intro')}
          </Text>
        </View>
      </View>
    )
  }
  renderAmountNextButton = () => {
    if (
      ((MyData.country_code == 'MYR' && Number(this.state.amount) >= 20) ||
        (MyData.country_code == 'THB' && Number(this.state.amount) >= 50)) &&
      this.state.selectedBankId != ''
    )
      return false
    return true
  }
  renderBankTopup() {
    return (
      <View style={{ marginTop: HeightDimen(15) }}>
        <View style={styles.bulletCoontainer}>
          <NumberBullets num={1} />
          <Text style={styles.bulletText}>{strings.t('topupRemarks')}</Text>
          <NumberBullets num={2} />
          <Text style={styles.bulletText}>{strings.t('top_up_policy')}</Text>
          <NumberBullets num={3} />
          <Text style={styles.bulletText}>{strings.t('withdraw_policy')}</Text>
          <NumberBullets num={4} />
          <Text style={styles.bulletText}>
            {strings.t('fraud_prevention') +
              '\n' +
              strings.t('fraud_prevention_desc')}
          </Text>
          <NumberBullets num={5} />
          <Text style={styles.bulletText}>
            {strings.t('top_up_disclaimer')}
          </Text>
        </View>
        <View style={{ marginTop: HeightDimen(10) }}>
          <TextButton
            label={strings.t('next')}
            size={5}
            onPress={async () => {
              await this.save_selected_topup_bank()
            }}
            isDisable={this.renderAmountNextButton()}
            isLoading={this.state.isSessionTopupLoading}
          />
        </View>
      </View>
    )
  }

  renderTopupDetail() {
    return (
      <View>
        <View style={styles.infoContainer}>
          <MIcon name="info-outline" color={'#e7999f'} size={20} />
          <Text
            style={{
              ...styles.cashLabel,
              fontSize: fontDimen(14),
              color: '#e7999f',
              marginLeft: HeightDimen(10),
              flex: 1,
            }}
          >
            {strings.t('topup_info')}
          </Text>
        </View>
        <View style={styles.bulletCoontainer}>
          {this.renderTitleAndDescription(
            strings.t('account_no'),
            this.state.accNo,
            true
          )}

          {this.renderTitleAndDescription(
            strings.t('referral'),
            this.state.reference
          )}
        </View>
        <View style={styles.bulletCoontainer}>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <View
              style={{
                borderWidth: 3,
                borderColor: SecondaryColor,
                height: 70,
                width: 70,
                borderRadius: 35,
                alignItems: 'center',
                justifyContent: 'center',
                overflow: 'hidden',
              }}
            >
              <Image source={{ uri: this.state.accImg }} style={styles.image} />
            </View>
            <View style={{ flex: 1, marginLeft: HeightDimen(10) }}>
              <Text
                style={{
                  color: MainColor,
                  fontWeight: 'bold',
                }}
              >
                {this.state.selectedBank}
              </Text>
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <Text
                  style={{
                    color: FontSecondaryColor,
                    fontSize: fontDimen(12),
                  }}
                >
                  {this.state.accName}
                </Text>
                <TouchableOpacity
                  onPress={() => this.onPressCopy(this.state.accName)}
                >
                  <Image
                    style={{
                      ...styles.image,
                      height: HeightDimen(20),
                      tintColor: SecondaryColor,
                    }}
                    source={require('../../Resources/Images/icon_copy.png')}
                  />
                </TouchableOpacity>
              </View>
              <Text
                style={{
                  color: FontSecondaryColor,
                  fontSize: fontDimen(12),
                  marginTop: HeightDimen(10),
                }}
              >
                {this.state.remarks}
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.bulletCoontainer}>
          {this.renderTitleAndDescription(
            strings.t('amount_RM'),
            `${MyData.country_code} ${this.state.amount} `
          )}
          {this.renderTitleAndDescription(
            strings.t('promo_code'),
            this.state.promo_code || 'N/A'
          )}
          <Text
            style={{
              color: FontSecondaryColor,
              fontSize: fontDimen(14),
            }}
          >
            {strings.t('receipt')}
          </Text>

          <FlatList
            showsHorizontalScrollIndicator={false}
            showsVerticalScrollIndicator={false}
            horizontal={true}
            data={this.state.imageListUrl}
            style={{
              flexGrow: 0,
            }}
            contentContainerStyle={styles.imageUploadContainer}
            renderItem={({ item, index }) => (
              <>
                <View
                  style={{
                    borderWidth: 3,
                    borderColor: SecondaryColor,
                    height: 70,
                    width: 70,
                    borderRadius: 35,
                    marginLeft: HeightDimen(10),
                    alignItems: 'center',
                    justifyContent: 'center',
                    overflow: 'hidden',
                  }}
                  onPress={() => this.onNextPressed()}
                >
                  <Image source={{ uri: item }} style={styles.image} />
                </View>
                <TouchableOpacity
                  style={{
                    backgroundColor: MainColor,
                    height: 25,
                    width: 25,
                    borderRadius: 12,
                    position: 'absolute',
                    right: 5,
                    top: -5,

                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  onPress={() => {
                    let index = this.state.imageListUrl.findIndex(
                      (myItem) => myItem == item
                    )
                    if (index >= 0) {
                      this.state.imageListUrl.splice(index, 1)
                      this.state.base64Image.splice(index, 1)
                      this.setState({
                        imageListUrl: this.state.imageListUrl,
                        base64Image: this.state.base64Image,
                      })
                    }
                  }}
                >
                  <Image
                    source={require('../../Resources/Images/close-cross.png')}
                    style={{
                      tintColor: 'white',
                      resizeMode: 'contain',
                      height: 10,
                    }}
                  />
                </TouchableOpacity>
              </>
            )}
            ListEmptyComponent={
              <TouchableOpacity
                style={{ alignItems: 'center' }}
                onPress={() => this.onNextPressed()}
              >
                <Image
                  source={require('../../Resources/Images/add-image.png')}
                  style={{
                    height: 40,
                    width: 'auto',
                    aspectRatio: 1 / 1,
                    tintColor: SecondaryColor,
                  }}
                />
                <Text>{strings.t('please_upload_reciept')}</Text>
              </TouchableOpacity>
            }
            keyExtractor={(item, index) => String(index)}
          />
          {this.state.imageListUrl.length > 0 && (
            <TouchableOpacity
              style={{
                flex: 1,
                alignItems: 'center',
                backgroundColor: PrimaryColor,
                paddingVertical: 5,
              }}
              onPress={() => this.onNextPressed()}
            >
              <Text style={styles.cashMaxButton}>Add More</Text>
            </TouchableOpacity>
          )}
        </View>
        <View
          style={{
            marginTop: HeightDimen(30),
          }}
        >
          <TextButton
            label={strings.t('next')}
            size={5}
            onPress={() => {
              this.onComformUpload()
            }}
            isLoading={this.state.isLoading}
            isDisable={this.state.imageListUrl.length > 0 ? false : true}
          />
        </View>

        {this.state.isSessionExist && (
          <View
            style={{
              marginTop: HeightDimen(10),
            }}
          >
            <TextButton
              label={strings.t('cancel')}
              blackBtn
              size={5}
              onPress={() => {
                this.setState({ isShowCancelSessionModal: true })
              }}
            />
          </View>
        )}
      </View>
    )
  }

  render() {
    return (
      <ScrollView
        style={styles.container}
        ref={(scrolRef) => (this.scrolRef = scrolRef)}
      >
        <UploadTopUpModal
          isShow={this.state.show_upload_modal}
          onPressCamera={() => this.onPress_Camera()}
          onPressLocal={() => this.onPress_local()}
          onBackPress={() => this.onCancel_modal()}
        />
        <ConfirmCancelModal
          isShow={this.state.isShowCancelSessionModal}
          textResource={strings.t('confrim_to_remove_top_up')}
          isLoading={this.state.isSessionTopupLoading}
          onConfirmPress={() => this.cancel_selected_topup_bank()}
          onBackPress={() => {
            this.setState({ isShowCancelSessionModal: false })
          }}
        />

        {this.state.isConfirmModal && (
          <SuccessModal
            isShow={this.state.isConfirmModal}
            isSuccess={this.state.isSuccess}
            message={this.state.transferMessage}
            isLoading={this.state.isSessionTopupLoading}
            onConfirmPress={async () => {
              if (this.state.isSuccess) {
                await this.cancel_selected_topup_bank()
                this.props.route.params?.onGoBack()
                this.props.navigation?.goBack()
              }
              this.setState({ isConfirmModal: false })
            }}
          />
        )}
        <View style={styles.innerContainer}>
          {this.state.isGetBankList ? (
            <View>
              <ActivityIndicator
                size="small"
                color={MainColor}
                style={{ marginTop: HeightDimen(20) }}
              />
            </View>
          ) : !this.state.isSessionExist ? (
            <>
              {this.renderAmountTopup()}
              {this.renderBankTopup()}
            </>
          ) : (
            this.renderTopupDetail()
          )}
        </View>
      </ScrollView>
    )
  }
}
const styles = {
  container: {
    flex: 1,
    backgroundColor: 'white',
  },
  innerContainer: {
    flex: 1,
    paddingHorizontal: HeightDimen(15),
    marginBottom: HeightDimen(20),
  },

  maxButtonContainer: {
    flexDirection: 'row',
    marginTop: HeightDimen(30),
    alignItems: 'center',
    justifyContent: 'center',
  },
  maxButton: {
    backgroundColor: SecondaryColor,
    padding: HeightDimen(5),
    borderRadius: HeightDimen(5),
    marginRight: HeightDimen(5),
  },
  maxButtonLabel: {
    color: 'white',
    fontSize: fontDimen(12),
    textAlign: 'center',
  },
  cashContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: HeightDimen(10),
  },
  cashLabel: {
    fontSize: fontDimen(12),
    fontWeight: 'bold',
  },
  cashMaxButton: {
    color: SecondaryColor,
    fontSize: fontDimen(16),
    fontWeight: 'bold',
  },

  textInputContainer: {
    marginTop: HeightDimen(10),
    alignItems: 'center',
    justifyContent: 'center',
  },
  textInput: {
    fontSize: fontDimen(30),
    color: SecondaryColor,
    borderBottomColor: SecondaryColor,
    borderBottomWidth: HeightDimen(2),
    textAlign: 'center',
  },
  bulletText: {
    fontSize: fontDimen(13),
    color: MainColor,
    marginBottom: HeightDimen(10),
    fontWeight: 'bold',
  },
  bulletCoontainer: {
    backgroundColor: 'white',
    borderRadius: HeightDimen(10),
    padding: HeightDimen(10),
    marginTop: HeightDimen(10),
    marginBottom: HeightDimen(10),
    // shadowColor: "#000",
    // shadowOffset: {
    //   width: 0,
    //   height: 100,
    // },
    // shadowOpacity: 100,
    // shadowRadius: 100,
    elevation: Platform.OS == 'ios' ? 0 : 1,
  },
  bankCard: {
    flexDirection: 'row',
    backgroundColor: 'white',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: HeightDimen(10),
    borderRadius: HeightDimen(10),
    // shadowColor: "#000",
    // shadowOffset: {
    //   width: 0,
    //   height: 1,
    // },
    // shadowOpacity: 1,
    // shadowRadius: 1,

    elevation: Platform.OS == 'ios' ? 0 : 1,
  },
  infoContainer: {
    backgroundColor: '#ffe7ea',
    marginTop: HeightDimen(10),
    padding: HeightDimen(10),
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    borderRadius: HeightDimen(10),
    elevation: Platform.OS == 'ios' ? 0 : 1,
  },
  imageUploadContainer: {
    height: 120,
    backgroundColor: PrimaryColor,
    borderStyle: 'dotted',
    borderRadius: 2,
    borderWidth: 3,
    borderColor: SecondaryColor,
    marginTop: HeightDimen(10),
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
    //width: "100%",
  },
  image: {
    width: 'auto',
    height: HeightDimen(80),
    aspectRatio: 1 / 1,
    resizeMode: 'contain',
  },
}
export default TopUpScreen
