import React, { Component } from 'react'
import { View, FlatList, ActivityIndicator, Image } from 'react-native'
import { TopCard } from '../../Component/Card/LeaderBoard/TopCard'
import { MainFlowHeaderContainerNoFeedback } from '../../Component/Header/MainFlowHeader'
import { strings } from '../../Language/Language'
import { MainColor, SecondaryColor } from '../../UI/Color'
import { HeightDimen } from '../../UI/Dimensions'
import { BottomCard } from '../../Component/Card/LeaderBoard/BottomCard'
import leaderBoardCaller from '../../API/LeaderBoardCaller'
import MyData from '../../Data/MyData'
import MyToast from '../../Component/Toast/MyToast'

class LeaderBoardScreen extends Component {
  state = {
    topBoardArr: [],
    isLoading: true,
    leaderboard_id: 0,
    selfInfo: {},
  }

  async componentDidMount() {
    let temp = this.props.route.params.value
    this.setState({
      leaderboard_id: temp,
    })
    this.get_leader_board_rank(temp)
  }

  get_leader_board_rank = async (id) => {
    await leaderBoardCaller
      .getLeaderBoardRank(MyData.authToken, id)
      .then((res) => {
        this.setState({
          topBoardArr: res.response.ranks,
          selfInfo: res.response.self,
          isLoading: false,
        })
        // for(let i = 0; i < res.response.ranks.length; i++) {
        //     console.log(res.response.ranks[i].rank_place);
        // }
        console.log(res.response.ranks[0])
      })
      .catch((err) => {
        // console.log(err);
        if (err.response.status == 514) {
          this.props.expiredToken_logout()
        } else {
          MyToast.popToast(err.response.data.msg)
        }
      })
  }

  onPressLeaderBoard = (index) => {
    this.props.navigation.navigate('LeaderBoard')
  }

  onBackPressed = () => {
    this.props.navigation.goBack()
  }

  render() {
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: 'white',
        }}
      >
        <MainFlowHeaderContainerNoFeedback
          title={strings.t('leaderboard')}
          onBackPress={() => this.onBackPressed()}
        >
          <View
            style={{
              flex: 1,
              // borderRadius: HeightDimen(20),
              overflow: 'hidden',
              //marginTop: -HeightDimen(30),
            }}
          >
            {this.state.isLoading ? (
              <View
                style={{
                  marginTop: HeightDimen(20),
                }}
              >
                <ActivityIndicator size="small" color={MainColor} />
              </View>
            ) : (
              <View>
                <FlatList
                  ListHeaderComponent={() => (
                    <View>
                      <View
                        style={{
                          backgroundColor: SecondaryColor,
                          width: '100%',
                          height: HeightDimen(150),
                        }}
                      >
                        <Image
                          source={{
                            uri: this.state.topBoardArr[0]
                              .leaderboard_thumbnail_url,
                          }}
                          resizeMode="stretch"
                          style={{
                            width: '100%',
                            height: HeightDimen(150),
                          }}
                        />
                      </View>
                      {this.state.selfInfo && (
                        <BottomCard
                          rank={this.state.selfInfo.rank_place}
                          username={MyData.member_username}
                          amount={this.state.selfInfo.rank_amount}
                          image={this.state.selfInfo.member_avatar_url}
                          isSelf
                        />
                      )}
                    </View>
                  )}
                  contentContainerStyle={{
                    paddingBottom: HeightDimen(30),
                  }}
                  showsHorizontalScrollIndicator={false}
                  showsVerticalScrollIndicator={false}
                  data={this.state.topBoardArr}
                  renderItem={({ item, index }) => (
                    <BottomCard
                      rank={item.rank_place}
                      username={item.member_username}
                      amount={item.rank_amount}
                      image={item.member_avatar_url}
                    />
                  )}
                  keyExtractor={(item) => String(item.rank_id)}
                />
              </View>
            )}
          </View>
        </MainFlowHeaderContainerNoFeedback>
      </View>
    )
  }
}

export default LeaderBoardScreen
