import React, { Component } from 'react'
import { View, TouchableOpacity, Image } from 'react-native'
import MyData from '../../Data/MyData'
import { strings } from '../../Language/Language'
import {
  FontMainColor,
  MainColor,
  PrimaryColor,
  SecondaryColor,
} from '../../UI/Color'
import { fontDimen, HeightDimen } from '../../UI/Dimensions'
import Text from '../Text/MyText'

const InterestAccountCard = (props) => {
  const { balance, amount, name, onPress, status, createdDate, countdownHour } =
    props

  // var dateArray = createdDate.split(' ');
  // var year = dateArray[0].split('-');
  // var time = dateArray[1].split(':');
  // var createDate = new Date(year[0], year[1] - 1, year[2], time[0], time[1], time[2])
  // var currentData = new Date()

  // var seconds = Math.floor((currentData - (createDate))/1000);
  // var minutes = Math.floor(seconds/60);
  // var hours = Math.floor(minutes/60);
  // var days = Math.floor(hours/24);

  //console.log(createdDate)
  //console.log(createDate)

  var hours = Math.round(countdownHour / 60)

  return (
    <>
      {status == 'running' ? (
        <View
          style={{
            marginBottom: HeightDimen(30),
          }}
        >
          <Text
            style={{
              fontSize: fontDimen(15),
              color: FontMainColor,
            }}
          >
            {name}
          </Text>

          <TouchableOpacity
            onPress={onPress}
            style={{
              flexDirection: 'row',
              width: '100%',
              height: HeightDimen(120),
              padding: HeightDimen(20),
              backgroundColor: PrimaryColor,
              marginTop: HeightDimen(10),
              alignItems: 'center',
            }}
          >
            <View
              style={{
                flex: 1,
              }}
            >
              <Text
                style={{
                  fontSize: fontDimen(25),
                  color: SecondaryColor,
                  fontWeight: 'bold',
                }}
              >
                {MyData.country_code}
                {amount}
              </Text>
              <Text
                style={{
                  fontSize: fontDimen(13),
                  color: SecondaryColor,
                  fontWeight: 'bold',
                  flex: 1,
                }}
              >
                {strings.balance}: {MyData.country_code} {balance}
              </Text>
            </View>
            <Image
              source={require('../../Resources/Images/icon_nextP.png')}
              style={{
                width: 'auto',
                height: HeightDimen(20),
                aspectRatio: 16 / 27,
                resizeMode: 'contain',
              }}
            />
            <View
              style={{
                position: 'absolute',
                bottom: HeightDimen(10),
                right: HeightDimen(30),
                flexDirection: 'row',
              }}
            >
              <Image
                source={require('../../Resources/Images/icon_nextP.png')}
                style={{
                  width: 'auto',
                  height: 'auto',
                  aspectRatio: 1 / 1,
                  resizeMode: 'contain',
                }}
              />
              <Text
                style={{
                  fontSize: fontDimen(11),
                  color: SecondaryColor,
                  marginLeft: HeightDimen(5),
                }}
              >
                {hours + ' ' + strings.hours}
              </Text>
            </View>
          </TouchableOpacity>
        </View>
      ) : (
        <></>
      )}
    </>
  )
}

export { InterestAccountCard }
