import React, { Component } from "react";
import {
  View,
  FlatList,
  TouchableOpacity,
  ActivityIndicator,
  Dimensions,
  ScrollView,
  Image,
} from "react-native";
import Text from "../../Component/Text/MyText";
import { MainColor, SecondaryColor } from "../../UI/Color";
import { HeightDimen, fontDimen } from "../../UI/Dimensions";
import productAPICaller from "../../API/ProductAPICaller";
import MyData from "../../Data/MyData";
import { ProductCard } from "../../Component/Card/ProductCard";
import { strings } from "../../Language/Language";
import { InGameModal } from "../../Component/Modal/InGameModal";
import MyToast from "../../Component/Toast/MyToast";
// import {
//   AnalyticEvents,
//   logEventWithGameName,
//   logMessageAnalytics,
// } from "../../Utils/Analytics";
import { product_images } from "../../Utils/Common";

import storageData from "../../Data/StorageData";
import ProductsData from "../../Data/ProductsData";
import { LinearGradient } from "expo-linear-gradient";

class DiscoverScreen extends Component {
  state = {
    isLoadingProduct: true,
    productArr: [],
    categoryArr: [],
    selected: "",
    toShowProduct: [],
    inGame: false,
    inGameID: "",
    showInGameModal: false,
    currentPage: 0,
  };

  async componentDidMount() {
    debugger;
    if (MyData?.games_list?.length > 0) {
      this.prepareGameList(MyData.games_list, ProductsData.productCategory);
    } else {
      this.get_game();
    }
  }

  prepareGameList = (products, category) => {
    // let temp_categoryArr = [];
    // temp_categoryArr.push(strings.all);
    // for (item in products) {
    //   if (temp_categoryArr.indexOf(products[item].product_category_group) < 0) {
    //     temp_categoryArr.push(products[item].product_category_group);
    //   }
    // }
    debugger;
    let temp_array = products.filter(
      (item) => item.product_category?.toLowerCase() != "fishing"
    );
    let to_show_array = [];
    // if (MyData.directLine === "0") {
    //   to_show_array.push({
    //     product_id: -11,
    //     product_status: "active",
    //     image_url:
    //       "https://gostrengths.com/wp-content/uploads/2012/03/Goal-Setting.jpg",
    //   });
    // }
    for (let item in temp_array) {
      if (temp_array[item].inGameStatus == "inGame") {
        this.setState({
          inGame: true,
          inGameID: temp_array[item].product_id,
        });
      }

      to_show_array.push(temp_array[item]);
    }
    let inGameProdIndex = to_show_array.findIndex(
      (item) => item.inGameStatus == "inGame"
    );
    if (inGameProdIndex >= 0) {
      to_show_array.unshift(to_show_array.splice(inGameProdIndex, 1)[0]);
    }

    let filterComingSoonGames = to_show_array?.filter((item) => {
      return (
        item.product_status != "active" &&
        item.product_status !== "maintenance" &&
        (item?.product_maintenance_scheduled !== "0" ||
          item?.product_maintenance_scheduled !== "1")
      );
    });

    ProductsData.setComingSoonGames(filterComingSoonGames);
    debugger;
    this.setState({
      productArr: products,
      isLoadingProduct: false,
      categoryArr: category,
      selected: category[0],
      toShowProduct: to_show_array,
    });
  };
  get_game = async () => {
    this.setState({ isLoadingProduct: true, inGame: false, inGameID: "" });
    await productAPICaller
      .getProductList(MyData.authToken)
      .then((res) => {
        debugger;
        MyData.games_list = res?.response.products;
        ProductsData.productCategory = res?.response?.productCategory;

        console.log(res?.response.products);

        if (this?.props?.update_wallet_balance)
          this?.props?.update_wallet_balance(res?.response);
        this.prepareGameList(MyData.games_list, ProductsData.productCategory);
        //  this.setState({ categoryArr: res?.response?.productCategory });
        // let temp_categoryArr = [];
        // temp_categoryArr.push(strings.all);
        // for (item in res.response.products) {
        //   if (
        //     temp_categoryArr.indexOf(
        //       res.response.products[item].product_category_group
        //     ) < 0
        //   ) {
        //     temp_categoryArr.push(
        //       res.response.products[item].product_category_group
        //     );
        //   }
        // }
        // let temp_array = res.response.products.filter(
        //   item => item.product_category_group?.toLowerCase() != "fishing"
        // );
        // let to_show_array = [];
        // // if (MyData.directLine === "0") {
        // //   to_show_array.push({
        // //     product_id: -11,
        // //     product_status: "active",
        // //     image_url:
        // //       "https://gostrengths.com/wp-content/uploads/2012/03/Goal-Setting.jpg",
        // //   });
        // // }
        // for (item in temp_array) {
        //   if (temp_array[item].inGameStatus == "inGame") {
        //     this.setState({
        //       inGame: true,
        //       inGameID: temp_array[item].product_id,
        //     });
        //   }

        //   to_show_array.push(temp_array[item]);
        // }
        // let inGameProdIndex = to_show_array.findIndex(
        //   item => item.inGameStatus == "inGame"
        // );
        // if (inGameProdIndex >= 0) {
        //   to_show_array.unshift(to_show_array.splice(inGameProdIndex, 1)[0]);
        // }

        // let filterComingSoonGames = to_show_array?.filter(item => {
        //   return (
        //     item.product_status != "active" &&
        //     item.product_status !== "maintenance" &&
        //     (item?.product_maintenance_scheduled !== "0" ||
        //       item?.product_maintenance_scheduled !== "1")
        //   );
        // });

        // ProductsData.setComingSoonGames(filterComingSoonGames);
        // if (this?.props?.update_wallet_balance)
        //   this?.props?.update_wallet_balance(res?.response);
        // this.setState({
        //   productArr: res.response.products,
        //   isLoadingProduct: false,
        //   categoryArr: temp_categoryArr,
        //   selected: temp_categoryArr[0],
        //   toShowProduct: to_show_array,
        // });
      })
      .catch((err) => {
        console.log(err);

        if (err.response.status == 514) {
          this.props.expiredToken_logout();
        } else {
          MyToast.popToast(err.response.data.msg);
        }
      });
  };
  onPress_Discover = (index) => {
    // // logEventWithGameName(
    // //   AnalyticEvents.DashboardGameClick,
    // //   this.state.toShowProduct[index].product_name
    // );
    if (this.state.toShowProduct[index].product_id == -11) {
      this.props.update_selection(2);
    } else {
      if (MyData.temp_json_object.response.user.member_level == 1) {
        MyToast.popToast(strings.t("cant_start_game"));
      } else {
        if (this.state.inGame) {
          if (
            this.state.toShowProduct[index].product_id == this.state.inGameID
          ) {
            this.props.navigation.navigate("GameDetails", {
              value: this.state.toShowProduct[index],
              isInGame: true,
              onGoBack: (data) => {
                this.refresh(data);
              },
            });
          } else {
            this.show_hide_inGameModal();
          }
        } else {
          let gameProduct = this.state.toShowProduct[index];
          // if (
          //   gameProduct.product_status != "active" &&
          //   gameProduct.product_status !== "maintenance" &&
          //   (gameProduct?.product_maintenance_scheduled !== "0" ||
          //     gameProduct?.product_maintenance_scheduled !== "1")
          // ) {
          //   this.setState({
          //     selectedProduct: gameProduct,
          //     showCommingSoonGameModal: true,
          //   });
          // } else
          this.props.navigation.navigate("GameDetails", {
            value: gameProduct,
            isInGame: false,
            onGoBack: (data) => {
              this.refresh(data);
            },
          });
        }
      }
    }
  };

  select_cat = (items, pageIndex) => {
    let temp_array = this.filterProducts(items);
    let to_show_array = [];
    // if (items == strings.all) {
    //   to_show_array.push({
    //     product_id: -11,
    //     product_status: "active",
    //     image_url:
    //       "https://gostrengths.com/wp-content/uploads/2012/03/Goal-Setting.jpg",
    //   });
    // }
    if (items.name !== this.state.selected.name) {
      // for (item in temp_array) {
      //   if (
      //     temp_array[item].product_category_group == items ||
      //     items == strings.all
      //   ) {
      //     to_show_array.push(temp_array[item]);
      //   }
      // }

      let inGameProdIndex = temp_array.findIndex(
        (item) => item.inGameStatus == "inGame"
      );
      if (inGameProdIndex >= 0) {
        temp_array.unshift(temp_array.splice(inGameProdIndex, 1)[0]);
      }

      this.setState({
        selected: items,
        toShowProduct: temp_array,
        currentPage: pageIndex,
      });
    }
  };

  show_hide_inGameModal = () => {
    this.setState({ showInGameModal: !this.state.showInGameModal });
  };

  refresh = (data) => {
    this.get_game();
  };

  on_Refresh = () => {
    this.setState({ isLoadingProduct: true });
    this.get_game();
  };

  filterLeatherBoard = () => {
    //  return this.state.toShowProduct;
    if (MyData.directLine === "1") {
      return this.state.toShowProduct.filter((item) => {
        return item.product_id != -11;
      });
    } else {
      return this.state.toShowProduct;
    }
  };
  getImageUrl = (name) => {
    // let image = product_images.find((item) => {
    //   return item?.name?.toLowerCase() == name?.toLowerCase()
    // })?.path
    // if (image) return image
    // else
    return "";
  };
  onCategoryPress = (item, index) => {
    this.select_cat(item, index);
    this.scrolRef.scrollTo({
      x: index * Dimensions.get("screen").width,
      y: 0,
      animated: true,
    });
  };
  onScroll = (event) => {
    if (!event) return;
    const xOffset = event.nativeEvent.contentOffset.x + 10;
    const currentPage = Math.floor(xOffset / Dimensions.get("screen").width);
    if (this.state.currentPage !== currentPage) {
      this.setState({
        currentPage,
      });
    }
  };

  onMomentumScrollEnd = async (event) => {
    if (!event) return;
    const xOffset = event.nativeEvent.contentOffset.x + 10;
    const currentPage = Math.floor(xOffset / Dimensions.get("screen").width);
    if (this.state.currentPage === currentPage) {
      this?.props?.update_category(currentPage);
      this.select_cat(this.state.categoryArr[currentPage], currentPage);
    }
  };

  filterProducts(items) {
    return this.filterProductsByCategory(items, this.state.productArr);
  }
  filterProductsByCategory(cat, productList) {
    let to_show_array = [];
    debugger;
    if (cat?.name == strings.t("all")) {
      to_show_array = productList.filter(
        (item) => item.product_category?.toLowerCase() != "fishing"
      );
    } else {
      to_show_array = productList.filter(
        (item) => item.product_category_group == cat?.name
      );
    }
    return to_show_array;
  }

  render() {
    return (
      <View style={styles.container}>
        <InGameModal
          isShow={this.state.showInGameModal}
          onBackPressed={() => this.show_hide_inGameModal()}
        />
        {this.state.isLoadingProduct ? (
          <View>
            <ActivityIndicator size="small" color={MainColor} />
          </View>
        ) : (
          <View style={styles.container}>
            <FlatList
              showsHorizontalScrollIndicator={false}
              showsVerticalScrollIndicator={false}
              horizontal={true}
              data={this.state.categoryArr}
              style={{
                flexGrow: 0,
              }}
              renderItem={({ item, index }) => (
                // <TouchableOpacity
                //   onPress={() => {
                //     this?.props?.update_category(index)
                //     this.onCategoryPress(item, index)
                //   }}
                //   style={{
                //     ...styles.categoryContainer,
                //     backgroundColor:
                //       this.state.selected.name == item?.name
                //         ? SecondaryColor
                //         : MainColor,
                //     marginLeft: index == 0 ? HeightDimen(10) : 0,
                //     flexDirection: 'row',
                //     alignItems: 'center',
                //   }}
                // >
                //   <Image
                //     source={{ uri: item?.icon }}
                //     style={{
                //       height: HeightDimen(20),
                //       width: HeightDimen(25),
                //       tintColor:
                //         this.state.selected.name == item?.name
                //           ? MainColor
                //           : 'white',
                //     }}
                //   />
                //   <Text
                //     style={{
                //       fontSize: fontDimen(12),
                //       marginLeft: HeightDimen(5),
                //       color:
                //         this.state.selected.name == item?.name
                //           ? MainColor
                //           : 'white',
                //     }}
                //   >
                //     {item?.name}
                //   </Text>
                // </TouchableOpacity>

                <LinearGradient
                  colors={
                    this.state.selected.name == item?.name
                      ? ["#ffee8d", "#f7c56e"]
                      : ["#5a5a5a", "#2c2c2c"]
                  }
                  style={{
                    ...styles.categoryContainer,
                    marginLeft: index == 0 ? HeightDimen(10) : 0,
                  }}
                >
                  <TouchableOpacity
                    onPress={() => {
                      this?.props?.update_category(index);
                      this.onCategoryPress(item, index);
                    }}
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Image
                      source={{ uri: item?.icon }}
                      style={{
                        height: HeightDimen(20),
                        width: HeightDimen(25),
                        marginBottom: 2,
                        tintColor:
                          this.state.selected.name == item?.name
                            ? MainColor
                            : "white",
                      }}
                    />
                    <Text
                      style={{
                        fontSize: fontDimen(12),
                        marginLeft: HeightDimen(5),
                        marginBottom: 2,
                        color:
                          this.state.selected.name == item?.name
                            ? MainColor
                            : "white",
                      }}
                    >
                      {item?.name}
                    </Text>
                  </TouchableOpacity>
                </LinearGradient>
              )}
              keyExtractor={(item) => String(item?.name)}
            />
            <FlatList
              numColumns={3}
              scrollEnabled={true}
              data={this.state.toShowProduct}
              style={{
                flex: 1,
                marginTop: this.props.scrollEnabled
                  ? HeightDimen(8)
                  : HeightDimen(15),
                padding: HeightDimen(3),
                paddingHorizontal: MyData.isMobile ? 0 : HeightDimen(25),
              }}
              contentContainerStyle={{
                width: "100%",
              }}
              onRefresh={() => this.on_Refresh()}
              refreshing={this.state.isLoadingProduct}
              renderItem={({ item, index }) => (
                <ProductCard
                  onPress={() => {
                    if (storageData.saved_authSession != "") {
                      this.onPress_Discover(index);
                    } else {
                      this.props.onAuth_Change();
                    }
                  }}
                  disable={item.product_status == "active" ? false : true}
                  inGame={item.product_id == this.state.inGameID ? true : false}
                  image_url={item?.product_image_url}
                  product_id={item?.product_id}
                  product_image={
                    item?.product_coin_entitled == "false"
                      ? this.getImageUrl(item?.product_name)
                      : ""
                  }
                  product_image_black_and_white={
                    item?.product_coin_entitled == "false"
                      ? this.getImageUrl(`${item?.product_name}-bnw`)
                      : ""
                  }
                  leader_image={`${MyData.s3_url}/banner/%E5%A5%96%E6%9D%AF.jpg`}
                  status={item?.product_status}
                  image_url_black_and_white={item?.product_image_url_grey}
                  date_from={item?.product_maintenance_scheduled_from}
                  date_to={item?.product_maintenance_scheduled_to}
                  maintenance_scheduled={item?.product_maintenance_scheduled}
                  product_name={item?.product_language_name}
                  product_coin_entitled={item?.product_coin_entitled}
                />
              )}
              ListFooterComponent={() => {
                return (
                  <View
                    style={{
                      flex: 1,
                      height:
                        this.state.toShowProduct.length <= 8
                          ? HeightDimen(250)
                          : HeightDimen(100),
                    }}
                  ></View>
                );
              }}
              keyExtractor={(item) => String(item.product_id)}
            />
            {/* <ScrollView
              ref={(scrolRef) => (this.scrolRef = scrolRef)}
              horizontal={true}
              pagingEnabled={true}
              //snapToAlignment="start"
              //  decelerationRate={"fast"}
              onScroll={this.onScroll}
              onMomentumScrollEnd={this.onMomentumScrollEnd}
              scrollEventThrottle={10}
              showsHorizontalScrollIndicator={true}
            >
              {this.state?.categoryArr?.map((item, index) => (
                <View
                  key={index}
                  style={{
                    flex: 1,
                    width: responsiveScreenWidth(100),
                  }}
                >
                  {this.state.selected == item ? (
                    <FlatList
                      numColumns={4}
                      scrollEnabled={false}
                      data={this.state.toShowProduct}
                      style={{
                        flex: 1,
                        marginTop: this.props.scrollEnabled
                          ? HeightDimen(8)
                          : HeightDimen(15),
                        padding: HeightDimen(3),
                      }}
                      contentContainerStyle={{
                        width: '100%',
                      }}
                      onRefresh={() => this.on_Refresh()}
                      refreshing={this.state.isLoadingProduct}
                      renderItem={({ item, index }) => (
                        <ProductCard
                          onPress={() => {
                            if (storageData.saved_authSession != '') {
                              this.onPress_Discover(index)
                            } else {
                              this.props.onAuth_Change()
                            }
                          }}
                          disable={
                            item.product_status == 'active' ? false : true
                          }
                          inGame={
                            item.product_id == this.state.inGameID
                              ? true
                              : false
                          }
                          image_url={item?.product_image_url}
                          product_id={item?.product_id}
                          product_image={
                            item?.product_coin_entitled == 'false'
                              ? this.getImageUrl(item?.product_name)
                              : ''
                          }
                          product_image_black_and_white={
                            item?.product_coin_entitled == 'false'
                              ? this.getImageUrl(`${item?.product_name}-bnw`)
                              : ''
                          }
                          leader_image={`${MyData.s3_url}/banner/%E5%A5%96%E6%9D%AF.jpg`}
                          status={item?.product_status}
                          image_url_black_and_white={
                            item?.product_image_url_grey
                          }
                          date_from={item?.product_maintenance_scheduled_from}
                          date_to={item?.product_maintenance_scheduled_to}
                          maintenance_scheduled={
                            item?.product_maintenance_scheduled
                          }
                          product_name={item?.product_language_name}
                          product_coin_entitled={item?.product_coin_entitled}
                        />
                      )}
                      ListFooterComponent={() => {
                        return (
                          <View
                            style={{
                              flex: 1,
                              height:
                                this.state.toShowProduct.length <= 8
                                  ? HeightDimen(250)
                                  : HeightDimen(100),
                            }}
                          ></View>
                        )
                      }}
                      keyExtractor={(item) => String(item.product_id)}
                    />
                  ) : (
                    <View
                      style={{
                        marginTop: HeightDimen(30),
                      }}
                    >
                      <ActivityIndicator size="small" color={MainColor} />
                    </View>
                  )}
                </View>
              ))}
            </ScrollView> */}
          </View>
          // </View>
        )}
      </View>
    );
  }
}
const styles = {
  container: {
    flex: 1,
  },
  categoryContainer: {
    paddingLeft: HeightDimen(10),
    paddingRight: HeightDimen(10),
    paddingTop: HeightDimen(5),
    paddingBottom: HeightDimen(5),
    backgroundColor: MainColor,
    marginRight: HeightDimen(10),
    borderRadius: HeightDimen(9999),
  },
};
export default DiscoverScreen;
